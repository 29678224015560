@import "app/assets/common/bootstrap-media-mixins";

.team-information-actions {
   .verticle-section-title {
      display: none;
   }

   @include media-breakpoint-down(xs) {
      .kt-portlet {
         border-radius: 10px;
         border: 1px solid #e1e1e1;
         box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
      }

      .kt-portlet .kt-portlet__body {
         padding-top: 6px;
      }

      .kt-portlet .kt-portlet__head {
         display: none;
      }

      .verticle-section-title {
         display: inline;
         font-size: 18px;
         font-family: "TT Norms";
         font-weight: 500;
         color: #232323;
         margin-bottom: 10px;
      }

      .action-button {
         min-width: 120px;
      }
   }

   .action-button {
      display: block;
      button {
         width: 100%;
      }
   }
}
