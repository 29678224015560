.request-details-step-progress {
   // margin-top: 40px;
   .approval-steps-list {
      .kt-portlet .kt-portlet__body {
         padding: 25px 25px 25px 15px;
      }
      .status:not(.approval-required) {
         display: block;
         padding: 5px 0;
         position: relative;
         &.hide {
            display: none;
         }
         .status-line {
            position: absolute;
            left: 0px;
            width: 6px;
            top: 0;
            background-color: rgb(202, 202, 202);
            height: 100%;
            &.done {
               background-color: #2f8aca;
            }
            &.current {
               &:before {
                  content: "";
                  background-color: #2f8aca;
                  position: absolute;
                  left: 0px;
                  top: 0;
                  width: 6px;
                  height: 50%;
               }
               &:after {
                  content: "";
                  width: 12px;
                  height: 12px;
                  position: absolute;
                  border-radius: 50%;
                  left: 0;
                  top: 50%;
                  margin-top: -6px;
                  margin-left: -3px;
                  background-color: #2f8aca;
               }
            }
            &.hide {
            }
            &.last {
               &:after {
                  top: auto;
                  bottom: 0;
               }
            }
         }
         .status-text {
            margin-left: 25px;
            display: inline-block;
            font-size: 15px;
            vertical-align: top;
            font-weight: 500;
         }
         &:first-child {
            padding-top: 0;
            .status-line {
               border-radius: 4px 4px 0 0;
               top: 4px;
               // height: calc(100% - 10px);
               // bottom: 0;
            }
         }
         &:last-child {
            padding-bottom: 0;
            .status-line {
               border-radius: 0 0 4px 4px;
               height: calc(100% - 6px);
               //bottom:6px;
               // height: calc(100% - 10px);
               // bottom: 0;
               &.done {
                  height: calc(100% - 6px);
                  &:after {
                     content: "";
                     width: 12px;
                     height: 12px;
                     position: absolute;
                     border-radius: 50%;
                     left: 0;
                     bottom: 0;
                     margin-top: -6px;
                     margin-left: -3px;
                     background-color: #2f8aca;
                  }
               }
            }
         }
      }
      .approval-required {
         display: block;
         padding: 5px 0;
         position: relative;
         .status-line {
            position: absolute;
            left: 0px;
            width: 6px;
            top: 0;
            background-color: rgb(202, 202, 202);
            height: 100%;
            &.done {
               background-color: #2f8aca;
            }
            &.current {
               &:before {
                  content: "";
                  background-color: #2f8aca;
                  position: absolute;
                  left: 0px;
                  top: 0;
                  width: 6px;
                  height: 15px;
               }
               &:after {
                  content: "";
                  width: 12px;
                  height: 12px;
                  position: absolute;
                  border-radius: 50%;
                  left: 0;
                  top: 15px;
                  margin-top: -6px;
                  margin-left: -3px;
                  background-color: #2f8aca;
               }
            }
            &.hide {
            }
         }
         .status-text {
            margin-left: 25px;
            display: inline-block;
            font-size: 15px;
            vertical-align: top;
            font-weight: 500;
         }
         .workflow-approval-steps {
            margin-top: 10px;
            margin-left: 35px;
            position: relative;
            &:before {
               content: "";
               position: absolute;
               left: -10px;
               width: 3px;
               top: 0;
               bottom: 0;
               background-color: rgb(245, 245, 245);
               border-radius: 1px;
            }
            .workflow-name {
               // margin-left: 35px;
               font-weight: 600;
               opacity: 0.8;
               margin-bottom: 8px;
            }
            .form-group {
               margin-bottom: 0;
               // margin-left: 35px;

               .wf-step {
                  position: relative;
                  // &:before {
                  //     content:"";
                  //     position: absolute;
                  //     left: 4px;
                  //     width: 1px;
                  //     top:23px;
                  //     bottom:0;
                  //     background-color: rgb(233, 233, 233);
                  //     border-radius: 1px;
                  // }
                  .expand-icon {
                     position: absolute;
                     height: 20px;
                     width: 20px;
                     left: -6px;
                     top: 0px;
                     transition: all 0.3s;
                     cursor: pointer;
                     opacity: 0.7;
                     vertical-align: middle;
                     text-align: center;
                     i {
                     }
                  }
                  .form-check {
                     margin-left: 15px;
                     margin-top: 7px;
                     cursor: default;
                     // margin-left: 25px;
                     // margin-top: 5px;
                     label,
                     input {
                        cursor: default;
                     }
                  }
                  .people {
                     margin-top: 5px;
                     margin-left: 14px;
                     background-color: #f1f1f1;
                     border-radius: 4px;
                     padding: 5px;
                     .user {
                        .pic {
                           .profile-picture-placeholder {
                              span {
                                 line-height: 20px;
                                 font-size: 7px;
                                 vertical-align: top;
                                 font-weight: 500;
                              }
                           }
                        }
                     }
                  }
                  &.open {
                     .expand-icon {
                        transform: rotate(90deg);
                     }
                  }
               }
            }
         }
      }
   }
}
