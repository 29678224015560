.recommendations-suggestions {
   table.table {
      width: 100%;
      margin-top: 15px;

      th {
         background-color: var(--background---table-header);
      }

      .long-text-column {
         max-width: 100%;
         white-space: wrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .nowrap {
         white-space: nowrap;
      }
   }
}
