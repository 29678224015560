.notification-panel {
    width: 500px !important;
    .no-results {
        text-align: center;
    }
    .kt-notification__item {
        > div {
            width: 100%;
            position: relative;
        }
        .notification-header {
            .notification-title {
                font-size: 14px;
                line-height: 16px;
                font-variant: small-caps;
                color: #979797;
                font-weight: 600;
                margin-bottom: 6px;
            }
            .notification-date {
                margin-bottom: 10px;
                i {
                    margin-right: 5px;
                    opacity: 0.5;
                }
            }
        }
        .message {
            color:#4d4d4d;
            cursor: default;
            span {
                cursor: pointer;
                color:rgb(97, 97, 212);
                font-weight: 600;
            }
        }
        .actions {
            margin-top: 10px;
            text-align: right;
            .action-button {
                display: inline-block;
                cursor: pointer;
                font-size: 14px;
                line-height: 16px;
                text-align: right;
                letter-spacing: 0.222727px;
                color: #414141;
                font-weight: 600;
                opacity: 0.5;
                margin-left: 15px;
                transition: all 0.3s;
                &.link {
                    background-color: rgb(21, 138, 83);
                    // opacity: 0.7;
                    color:white;
                    height: 32px !important;
                }
                &.decline {
                    background-color: rgb(204, 76, 74);
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    .tab-content {
       position: relative;
    }
    .clear-all {
       font-weight: 600;
       text-align: right;
       position: absolute;
       top:-18px;
       right:30px;
       z-index: 20;
       color:#a3a3a3;
    }
}