@import "app/assets/common/bootstrap-media-mixins";

.team-information-page-users-webpart {
   width: 100%;

   @include media-breakpoint-down(xs) {
      .kt-portlet {
         border-radius: 10px;
         border: 1px solid #e1e1e1;
         box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
      }

      .kt-portlet .kt-portlet__head {
         display: none;
      }

      .kt-portlet .kt-portlet__body {
         padding-top: 6px;
      }
      .verticle-section-title {
         display: inline !important;
         font-size: 18px;
         font-family: "TT Norms";
         font-weight: 500;
         color: #232323;
         margin-bottom: 10px;
      }
   }

   .verticle-section-title {
      display: none;
   }

   .main-container {
      --main-gap: 1rem;
      display: flex;
      flex-direction: column;
      gap: var(--main-gap);

      //report width override
      .lifecycle-report {
         .lifecycle-report-wrapper {
            padding: 0;
         }
      }

      .normal-webpart-user-list {
         @include media-breakpoint-down(xs) {
            display: none;
         }
      }

      .vertical-webpart-user-list {
         display: none;
         @include media-breakpoint-down(xs) {
            display: block;
         }
      }

      .insights-report {
         &.no-results {
            display: none;
         }

         .list-of-members {
            position: relative;
            .title-right {
               position: absolute;
               top: -4px;
               right: 0;
               z-index: 1;
            }
         }
         .custom-header {
            display: flex;
            margin-bottom: 25px;
            .title-left {
               flex: 1;
               .top {
                  display: flex;
                  align-items: center;
                  margin-right: 130px;
                  // margin-bottom: 10px;
                  .title {
                     color: #007989;
                     font-size: 18px;
                     font-weight: 500;
                  }
                  .count {
                     background-color: rgba(0, 0, 255, 0.398);
                     border-radius: 4px;
                     margin-left: 8px;
                     font-size: 12px;
                     font-weight: 500;
                     padding: 3px 10px;
                     color: white;
                     line-height: 16px;
                     height: 21px;
                  }
               }
            }
         }
      }
   }
}
