@import "app/assets/common/bootstrap-media-mixins";
.request-list {
   .icon-td {
      text-align: center;
      @include media-breakpoint-down(sm) {
         display: inline-block;
      }
      .icon-holder {
         img {
            height: 22px;
            width: 23px;
         }
      }
   }
   .list-filter {
      .filter-title {
         display: inline-block;
         margin-right: 10px;
         font-weight: 600;
      }
      .form-dropdown {
         display: inline-block;
         width: 250px;
         margin-bottom: 20px;
      }
   }
   .td-logo {
      width: 25px;
      @include media-breakpoint-down(sm) {
         display: inline-block;
         width: auto;
      }
      .request-logo {
         width: 25px;
         height: 25px;
         background: #e5f7f9;
         text-transform: uppercase;
         text-align: center;
         line-height: 25px;
         color: #0c95a6;
         font-size: 12px;
         border-radius: 4px;
         display: block;
         font-weight: 700;
         @include media-breakpoint-down(sm) {
            display: inline-block;
         }
         &.color-green {
            color: #1bc5bd;
            background-color: #c9f7f5;
         }
         &.color-red {
            color: #f64e60;
            background-color: #ffe2e5;
         }
         &.color-orange {
            color: #f68c4e;
            background-color: #ffefe2;
         }
         &.color-blue {
            color: #3699ff;
            background-color: #e1f0ff;
         }
         &.color-purple {
            color: #d736ff;
            background-color: #fbe1ff;
         }
         &.color-yellow {
            color: #ffa800;
            background-color: #fff4de;
         }
         &.color-gray {
            color: #cecece;
            background-color: #fff4de;
         }
         &.with-image {
            .image-background {
               width: 100%;
               height: 100%;
               background-size: cover;
               background-position: center center;
               border-radius: 4px;
            }
         }
      }
   }
}
