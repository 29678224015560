.guest-review-workflow {
   .report-column {
      .lifecycle-report {
         padding: 25px;
      }
   }

   .approval-portlet {
      .kt-portlet__head {
         background-color: #007989;
         .kt-portlet__head-label {
            i {
               color: white;
            }
            .kt-portlet__head-title {
               color: white !important;
            }
         }
      }
      .kt-portlet__body {
         display: flex;
         justify-content: center;
         flex-direction: row;

         .body-wrapper {
            max-width: 800px;
            width: 800px;
            // margin: 0 auto;
            .approval-decision {
               margin-top: 30px;
            }
            .approval-submit {
               margin-top: 30px;
               button {
                  font-weight: 500;
                  font-size: 14px;
               }
            }
            .approval-decision {
               margin-top: 30px;
            }
            .approval-comment {
               padding: 15px 15px 15px 15px;
               border-radius: 4px;
               background-color: #f3f3f3;
               font-weight: 500;
               white-space: pre-wrap;
            }
            .comment {
               margin-top: 15px;
               margin-bottom: 10px;
               span {
                  font-weight: 500;
                  color: #718690;
               }
               .reject-required-comment {
                  font-size: 12px;
                  font-weight: 400;
                  color: #d16e6e;
               }
            }
            .approval-buttons {
               padding-top: 10px;
               .approval-row {
                  display: flex;
                  > div {
                     flex: 1;
                     &:first-child {
                        margin-right: 15px;
                        margin-left: 2px;
                     }
                     &:last-child {
                        margin-left: 15px;
                        margin-right: 2px;
                     }
                  }
                  .btn {
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     // margin-bottom: 10px;
                     font-weight: 500;
                     opacity: 0.8;

                     &.selected {
                        opacity: 1;
                        &.btn-approve {
                           box-shadow: 0 0 0px 2px #447e49 !important;
                        }
                        &.btn-extend {
                           box-shadow: 0 0 0px 2px #5264a2 !important;
                        }
                     }
                     .animated-loader {
                        zoom: 0.9;
                        margin-right: 0.5rem;
                     }
                     i {
                        position: relative;
                        bottom: -1px;
                     }
                  }
                  .help-text {
                     font-size: 12px;
                     text-align: center;
                     font-weight: 500;
                  }
               }
            }
         }
      }
   }
   .lifecycle-workspace-details {
      .kt-portlet {
         height: calc(100% - 20px);
      }
   }
   .admin-options {
      .wrapper {
         display: flex;
         align-items: center;
         padding: 8px 25px;
         justify-content: flex-end;
         > div {
            font-weight: 500;
            margin-right: 10px;
         }
         > a {
            .btn {
               padding: 0.25rem 1rem;
            }
         }
      }
   }
   .stats-box {
      .top-area {
         height: 100px;
         // margin-bottom: -100px;
         position: relative;
         z-index: 2;
         display: flex;
         justify-content: space-between;
         padding: 16px 20px;
         align-items: center;
         .icon {
            width: 48px;
            height: 48px;
            border-radius: 6px;
            background-color: rgba(215, 233, 236, 0.5);
            line-height: 54px;
            text-align: center;
            margin-right: 16px;
            i {
               color: #007989;
               font-size: 20px;
            }
         }
         .right-side {
            .count {
               font-size: 24px;
               color: #26323a;
               font-weight: 500;
               text-align: right;
            }
            .title {
               color: #718690;
               font-size: 14px;
               font-weight: 500;
               text-align: right;
            }
            .description {
               color: #7186909c;
               font-size: 11px;
               font-weight: 500;
               text-align: right;
            }
         }
      }
   }
   .history-top {
      font-weight: 400;
   }
   .no-action {
      .comment {
         margin-top: 10px;
         .title {
            font-size: 12px;
            font-weight: 600;
         }
         .text {
            font-weight: 400;
         }
      }
   }
   .portlet-alert > .alert {
      align-items: center;
   }
}

.kt-container {
   .archival-workflow {
      .admin-options {
         display: none;
      }
   }
}
