.team-information-workspace-details {
   .bottom-stats-title {
      color: #26323a;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
   }
   .bottom-stats {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      grid-gap: 15px;

      .stat {
         flex: 1;
         .stat-title {
            color: #718690;
            font-weight: 500;
            margin-bottom: 0px;
         }
         .stat-content {
            color: #26323a;
            font-weight: 500;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            &.profile-stat {
               display: flex;
               align-items: center;
               gap: 0.25rem;
               margin-top: 0.25rem;
            }
            .created-with-icon {
               i {
                  font-size: 14px;
                  color: rgba(0, 0, 255, 0.453);
               }
               img {
                  width: 16px;
               }
               margin-right: 8px;
            }
            .created-with-type {
               flex-grow: 1;
            }
         }
      }
   }
}
