.portlet-alert {
   p {
      margin: 0;
   }
}
.form-guest-naming-policy {
   .info-right {
      .portlet-alert {
         margin-left: 0px;
         margin-right: 46px;
      }
      .alert {
         margin: 0;
      }
   }

   .guest-fix-editor {
      &.disabled {
         opacity: 0.4;
      }

      .fix-item {
         position: relative;
         padding: 10px 0;
         &:before {
            position: absolute;
            content: "";
            border-bottom: 1px solid #d8d8db;
            left: 1px;
            right: 1px;
            bottom: 0;
         }
         &:last-child {
            &:before {
               display: none;
            }
         }
         .fix-left {
            padding-left: 50px;
            position: relative;
            .count {
               position: absolute;
               left: 15px;
               top: 8px;
               font-size: 12px;
               //margin-top: -11px;
               width: 24px;
               height: 24px;
               min-width: 24px;
               display: inline-block;
               background-color: #8585ff;
               color: white;
               font-weight: 700;
               line-height: 23px;
               padding-left: 0;
               padding-right: 0;
               text-align: center;
               border-radius: 5px;
            }
            .fix-type {
            }
            .dd-wrapper .dd-header {
               transition: all 0.5s;
            }
         }
         .fix-right {
            position: relative;
            padding-right: 60px;
            .remove {
               position: absolute;
               right: 15px;
               top: 50%;
               margin-top: -9px;
               opacity: 0.3;
               svg {
                  height: 18px;
                  width: 18px;
                  color: gray;
               }
               svg {
                  position: relative;
                  top: calc(50% - 9px);
                  height: 18px;
                  width: 18px;
                  color: gray;
               }
            }
            .red-outline {
               .form-control {
                  border: 1px solid red !important;
               }
            }
         }
         &:hover {
            .remove {
               opacity: 0.7;
               cursor: pointer;
            }
         }
         &.new-mode {
            .fix-left {
               .count {
                  background-color: #e2e2e2;
               }
            }
         }
      }
   }
}
