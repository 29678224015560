.lifecycle-listing {
   .lifecycle-report {
      background-color: transparent;
      .lifecycle-report-wrapper {
         background-color: white;
         border-radius: 5px;
         padding: 0 15px;
      }
      .fav-title-area {
         margin-top: 15px;
      }
      .lifecycle-report-filters {
         background-color: white;
         padding-top: 15px;
      }
      .lifecycle-report-list-table {
         background-color: white;
      }
      .lifecycle-report-list-cards {
         background-color: white;
      }
      .lifecycle-report-favorites {
         background-color: white;
      }
      .lifecycle-report-paging {
         background-color: white;
         margin-bottom: 15px;
      }
   }
}
.guest-insights-bulk-resend-invite-guest-modal {
   .modal-content {
      .modal-body {
         .resend-modal-body {
            strong {
               color: #48465b;
            }
         }
      }
   }
}
