.mananged-metadata-picker {
   .tree-loader {
      margin: 0px 0;
      text-align: center;
      min-height: 46px;
   }

   .picker-tree {
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      .node-tree {
         padding: 10px 0px 10px 10px;
         position: relative;
         &:after {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.05);
            z-index: 3;
            top: 0;
            bottom: 100%;
            left: 0;
            right: 0;
            transition: all 0.3s;
            opacity: 0;
         }
      }
      .node-wrapper {
         position: relative;

         .loading {
            position: absolute;
            left: -11px;
            top: 4px;
            background-color: white;
            .animated-loader {
               width: 16px;
               height: 16px;
               margin-left: 20px;
            }
         }
         .node {
            padding-left: 7px;
            padding-right: 7px;
            padding: 2px 7px;
            .node-expand-icon {
               position: absolute;
               left: -6px;
               height: 20px;
               width: 15px;
               text-align: center;
               top: 0px;
               line-height: 26px;
               opacity: 0.4;
               transition: 0.3s;
               cursor: pointer;
            }

            .node-icon-title {
               padding: 1px 8px 1px 3px;
               transition: all 0.3s;
               display: inline-block;
               .node-title {
                  display: inline-block;
                  color: black;
                  font-weight: 400;
                  transition: all 0.3s;
               }
               .node-icon-image {
                  display: inline-block;
                  cursor: pointer;
                  width: 20px;
                  text-align: center;
                  margin-right: 4px;
                  opacity: 0.8;
                  .profile-picture {
                     position: relative;
                     top: -1px;
                     .kt-media.kt-media--xs span {
                        width: 18px;
                        height: 18px;
                        line-height: 16px;
                     }
                  }
                  i {
                     font-size: 17px;
                     position: relative;
                     top: 2px;
                  }
               }
            }
            &.node-termset {
               .node-icon-image {
                  opacity: 0.5;
               }
            }
            &:hover:not(.not-available) {
               .node-title {
                  color: rgb(77, 77, 163);
                  cursor: pointer;
               }
            }
            &.selected {
               .node-icon-title {
                  background-color: rgba(0, 0, 255, 0.1);
                  border-radius: 4px;
                  .node-title {
                     font-weight: 500;
                  }
                  .node-icon-image {
                     i {
                        color: black;
                     }
                  }
               }
            }
            &.child-selected {
               .node-icon-title {
                  .node-title {
                     font-weight: 500;
                  }
               }
            }
            &.not-available {
               .node-icon-image {
                  cursor: default;
                  opacity: 0.7;
               }
               .node-title {
                  opacity: 0.7;
               }
            }
         }

         &.open {
            > .node {
               .node-expand-icon {
                  top: 3px;
                  left: -5px;
                  transform: rotate(90deg);
               }
            }
         }

         > ul {
            > li {
               margin-left: 17px;
               position: relative;
               &:before {
                  content: "";
                  opacity: 0.1;
                  left: 0;
                  width: 1px;
                  background-color: grey;
                  position: absolute;
                  left: -17px;
                  height: calc(100% + 3px);
                  top: -3px;
               }
               &:last-child {
                  &:before {
                     height: 14px;
                  }
                  &:after {
                     // content: "";
                     // opacity: 0.1;
                     // position: absolute;
                     // background-color: grey;
                     // left: -12px;
                     // height: 1px;
                     // width: 3px;
                     // top: 11px;
                  }
               }
            }
         }
      }

      .root-node > .node-wrapper {
         border-bottom: 1px solid rgba(70, 38, 38, 0.05);
         padding-left: 5px;
         > .node {
            .node-title {
               opacity: 0.6;
            }
            .node-expand-icon {
               margin-left: 5px;
            }
            &:hover {
               .node-title {
                  color: black;
                  cursor: default;
               }
               .node-icon-image {
                  cursor: default;
               }
            }
         }
         &:last-child {
            border-bottom: none;
         }
      }

      .node-wrapper {
         &.above-or-is-starting-node {
            padding-left: 0 !important;
            > .node {
               display: none;
            }
            > ul {
               > li {
                  margin-left: 0;
               }
            }
         }
      }
   }
   .picker-input-box {
      position: relative;
      z-index: 5;
      .picker-input-wrapper {
         .picker-input {
            display: flex;
            margin-top: 6px;
            vertical-align: top;
            input {
               width: 100%;
               // padding: 6px 6px 6px 6px;
               border-color: transparent;
               border-width: 0;
               outline: none;
               box-shadow: none;
            }
         }
         .selected-items {
            min-height: 44px;
            padding: 6px 10px 10px 10px;
            border-bottom: 1px solid rgba(128, 128, 128, 0.254);
            width: 100%;
            display: block;
            .term {
               display: inline-flex;
               background-color: rgb(238, 238, 238);
               padding: 2px 30px 2px 10px;
               justify-content: center;
               vertical-align: middle;
               border-radius: 3px;
               position: relative;
               margin-top: 4px;
               margin-right: 10px;
               align-items: center;
               justify-content: center;
               vertical-align: top;
               .node-icon {
                  font-size: 15px;
                  position: absolute;
                  left: 5px;
                  top: 50%;
                  margin-top: -10px;
                  display: none;
               }
               .node-name {
                  font-weight: 500;
                  color: #495057;
               }
               .node-remove {
                  position: absolute;
                  padding: 0 7.5px;
                  height: 100%;
                  vertical-align: middle;
                  top: 0;
                  right: 0;
                  // margin-top: -9px;
                  opacity: 0.3;
                  transition: all 0.3s;
                  cursor: pointer;

                  border-radius: 0 3px 3px 0;
                  i {
                     font-size: 12px;
                     position: relative;
                     top: 13%;
                     margin-top: -9px;
                  }
                  &:hover {
                     opacity: 0.6;
                     background-color: rgb(196, 196, 196);
                     color: black;
                  }
               }
            }
            &.can-add-more {
               cursor: text;
            }
         }
      }

      .picker-input-search {
         position: absolute;
         left: 0;
         /* right: 0; */
         width: 100%;
         background-color: white;
         z-index: 7;

         ul {
            margin-bottom: 0;
            border-bottom: 1px solid #d6d6d6;
            box-shadow: 0 4px 5px -5px #00000057;
            border-radius: 0 0 3px 3px;
            &.results-hidden {
               border-bottom: 0px solid #d6d6d6;
               box-shadow: none;
            }
            li {
               padding: 5px 8px;
               // height: 34px;
               // line-height: 33px;
               // word-wrap: none;
               position: relative;
               display: flex;
               &:hover {
                  cursor: pointer;
                  background: rgba(61, 160, 162, 0.1);
               }
               &.selected {
                  background: rgba(61, 160, 162, 0.1);
                  cursor: default;
               }
               .node-icon {
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 2px;
                  margin-left: 3px;
                  margin-right: 2px;
                  flex: 25px 0;
                  i {
                     font-size: 17px;
                  }
               }
               .details {
                  flex: 1;
                  .name {
                     display: block;
                     font-weight: 500;
                  }
                  .description {
                     display: block;
                     text-overflow: ellipsis;
                     overflow: hidden;
                     font-size: 12px;
                     font-weight: 400;
                     opacity: 0.6;
                  }
                  .parent {
                     font-size: 12px;
                     font-weight: 400;
                     opacity: 0.6;
                  }
               }

               &.no-results {
                  padding-top: 5px;
                  text-align: center;
               }
               &.loader {
                  padding-top: 14px;
                  text-align: center;
               }
               .principal-type {
                  position: absolute;
                  // top: 6px;
                  left: 28px;
                  top: 23px;
                  //right: -4px;
                  width: 11px;
                  height: 11px;
                  background-color: #0abb87;
                  font-size: 9px;
                  color: white;
                  line-height: 11px;
                  margin: 0 auto;
                  text-align: center;
                  border-radius: 50%;
                  border: 1px solid white;
                  box-sizing: content-box;
                  z-index: 93;
                  &.type-group {
                     background-color: rgb(121, 121, 231);
                  }
               }
            }
         }
      }

      &:focus,
      &:focus-within {
         border-color: #5bbfc1 !important;
      }
   }

   .input-picker {
      position: relative;
      .launch-tree-modal {
         position: absolute;
         right: 0;
         width: 48px;
         text-align: center;
         display: flex;
         align-items: center;
         justify-content: center;
         top: 0;
         bottom: 0;
         z-index: 5;
         transition: all 0.3s;
         border-radius: 0 3px 3px 0;
         i {
            font-size: 19px;
            color: #007989;
            transition: all 0.3s;
         }
         &:hover {
            cursor: pointer;
            background-color: #007989;
            i {
               color: white;
            }
         }
      }
      .picker-input-box {
         // border: 1px solid #d6d6d6;
         border: 1px solid #d6d6d6;

         border-radius: 4px;
         .picker-input-wrapper {
            .selected-items {
               border-bottom: 0px solid transparent;
               padding-right: 40px !important;
            }
         }
         .picker-input-search {
            left: -1px;
            right: -1px;
            top: calc(100% - 1px);
            width: calc(100% + 2px);
            ul {
               border: 1px solid #5bbfc1;
               &.results-hidden {
                  border: 0px solid transparent !important;
               }
            }
         }
      }
   }

   &.dropdown-open {
      .picker-input-box {
         z-index: 6;
      }
      .node-tree {
         &:after {
            bottom: 0%;
            opacity: 1;
         }
      }
   }

   .button-actions {
      text-align: right;
      .done-button {
         i {
            font-size: 12px;
            margin-right: 10px;
         }
         cursor: pointer;
         display: inline-block;
         font-weight: 500;
         background: #007989;
         border: 2px solid #007989;
         border-radius: 4px;
         margin-left: 15px;
         padding: 0.65rem 0.85rem;
         color: #ffffff;
         transition: all 0.3s;
         margin-top: 10px;
         &:not(.disabled):hover {
            background: #0395a8;
            border: 2px solid #0395a8;
         }
         &.disabled {
            background: #9ccbd1;
            border: 2px solid #9ccbd1;
         }
      }
   }
}

.tax-tree-modal {
   .modal-content {
      padding: 15px;
   }
}
.tax-tree-modal-bg {
   z-index: 1071 !important;
}
.tax-tree-modal {
   z-index: 1072 !important;
}
