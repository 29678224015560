.orchestry-admin-etl-crawl-stats {
   .region-select {
      max-width: 50%;
      margin-bottom: 20px;
   }
   table {
      // th {
      //    /* Something you can count on */
      //    height: 140px;
      //    white-space: nowrap;
      // }
      // table-layout: fixed;

      th {
         span {
            word-break: break-word;
            white-space: normal !important;
            font-size: 11px;
         }
      }

      .tenant-title {
         font-weight: 500;
      }
      .tenant-guid {
         font-size: 10px;
      }
      // th > div {
      //    transform:
      //         /* Magic Numbers */ translate(25px, 51px) /* 45 is really 360 - 45 */ rotate(315deg);
      //    width: 30px;
      // }
      // th > div > span {
      //    border-bottom: 1px solid #ccc;
      //    padding: 5px 10px;
      // }
      // th,
      // td {
      //    padding: 0 !important;
      // }
   }
}

.orchestry-etl-timeline-tooltip {
   .title {
      font-weight: 600;
      padding: 1px 4px;
      padding-left: 20px;
      background-color: black;
      color: white;
   }
   .property {
      display: flex;
      > div {
         padding: 1px 4px;
         &:first-child {
            width: 150px;
            padding-left: 20px;
         }
         &:last-child {
            padding-right: 20px;
         }
      }
   }
}
