.orchestry-svg-icon {
   .selectable {
      cursor: pointer;
      display: flex;
      line-height: 14px;
      img {
         width: 14px;
         height: 14px;
      }
   }

   &[data-size="xs"] {
      line-height: 14px;
      img {
         width: 14px;
         height: 14px;
      }
   }

   &[data-size="sm"] {
      line-height: 16px;
      img {
         width: 16px;
         height: 16px;
      }
   }

   &[data-size="md"] {
      line-height: 18px;
      img {
         width: 18px;
         height: 18px;
      }
   }

   &[data-size="lg"] {
      line-height: 24px;
      img {
         width: 24px;
         height: 24px;
      }
   }

   &[data-size="xl"] {
      line-height: 32px;
      img {
         width: 32px;
         height: 32px;
      }
   }

   &[data-size="xxl"] {
      line-height: 40px;
      img {
         width: 40px;
         height: 40px;
      }
   }
}
