.root {
   &[data-variant="wizard"] {
      color: var(--mantine-color-text-secondary-0);
      border-radius: var(--orchestry-border-radius);
      &[data-active="true"] {
         background-color: var(--mantine-color-primary-1);
         color: var(--mantine-color-text-primary-0);
      }
   }
}
