.library-document-template-details {
   .progress {
      margin-top: 15px;
   }
   .right-col {
      padding-top: 30px;
   }
   h3 {
      margin-top: 20px;
      margin-bottom: 20px;
   }

   .at-search {
      margin-bottom: 20px;
   }
   .at-count {
      margin-bottom: 10px;
   }
   .at-items {
      background-color: #f7f7f7;
      border-radius: 4px;
      .selected-items {
         padding: 0 15px;
      }
      .selected-items li {
         font-weight: 600;
         padding: 12px 0 11px 0;
      }
      .no-templates {
         // padding: 15px 0;
         .help-text {
            margin-bottom: 10px;
         }
      }
   }
   .add-document-category {
      // padding-top:10px;
      color: #0abb87;
      cursor: pointer;
      margin-top: 10px;
   }

   .no-upload-yet {
      border: 1px dashed #b5b5b5;
      background-color: white;
      box-shadow: 0px 20px 30px rgba(0, 69, 117, 0.1);
      padding: 40px 25px 40px 25px;
      outline: none !important;
      cursor: no-drop;
      text-align: center;
      i {
         color: #ffa530;
         width: 28px;
         height: 28px;
         margin: 0 auto;
         font-size: 28px;
      }
      .no-text {
         padding-top: 15px;
         text-align: center;
         font-size: 14px;
         color: #b5b5b5;
      }
   }
   .right-col {
      margin-left: 20px;
   }

   .form-label {
      .add-document-category {
         display: inline;
      }
   }

   .document-upload-complete {
      .file-icon {
         border: 1px solid #b5b5b5;
         border-radius: 5px;
         background-color: white;
         box-shadow: 0px 20px 20px rgba(0, 69, 117, 0.1);
         padding: 40px 0 40px 0;
         outline: none !important;
         cursor: pointer;
         .doc-icon {
            font-size: 32px;
            text-align: center;
         }
      }
      .file-name {
         margin: 15px 0 0 0;
         > div {
            display: inline-block;
            margin-right: 10px;
            .doc-icon {
               font-size: 15px;
            }
         }

         .fn {
            font-weight: 500;
            font-size: 15px;
         }
      }
   }
}
