.orchestry-pagination {
   .results-per-page {
      display: flex;
      gap: 8px;
      align-items: center;
   }
   .pagination-items {
      display: flex;
      gap: 12px;
      align-items: center;
   }

   &[data-variant="table"] {
      border: 1px solid var(--mantine-color-gray-2);
      border-top: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
   }
}
