.mananged-metadata-value {
   .metadata-name {
      word-wrap: break-word;
   }

   .path-seperator {
      margin: 0 10px;
      i {
         font-size: 12px;
         opacity: 0.5;
      }
   }
}
