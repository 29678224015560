@import "app/assets/common/bootstrap-media-mixins";

.toolbar {
   width: 100%;
   display: flex;
   background-color: rgba(238, 241, 245, 0.75);
   padding: 0.5rem 0.75rem;
   min-height: 35px;
   i {
      &:hover {
         color: #3da0a2;
      }
      cursor: pointer;
      padding: 0.1rem 0.3rem;
      border-radius: 4px;
      font-size: 12px;
      padding-bottom: 2px;
      box-sizing: border-box;
   }

   .textMarkupContainer {
      display: flex;
      gap: 0.3rem;
      margin-left: 140px;
      align-items: baseline;
      &.no-image {
         margin-left: 0;
      }
      &.hidden {
         display: none;
      }
   }

   .no-text-button {
      position: relative;
   }

   .active {
      position: relative;
      &::after {
         position: absolute;
         content: "";
         background: #3da0a2;
         height: 2px;
         bottom: 0;
         left: 50%;
         width: 70%;
         margin-left: -35%;
      }
      color: black;
   }
}

.form-markdown-add-link-callout{
   padding: 1rem;
   .link-input{
      display: flex;
      gap: 1rem;
      input {
         width: 250px;
      }
   }

}

.ms-Layer{
   z-index: 1000;
}

.linkSelected {
   box-shadow: 0 0 0 1px #3da0a2;
}
