.guest-status-icon-default-styles {
   // padding: 3px 12px;
   // border-radius: 5px;
   // font-weight: 500;
   // margin-right: 10px;
   // min-width: 134px;
   // text-align: center;

   &.guest-status-active {
      background-color: #557248;
      color: #557248;
   }

   &.guest-status-inactive {
      background-color: #b36909;
      // border: 1px solid #aee8bf;
      color: #b36909;
   }

   &.guest-status-deleted {
      background-color: #827940;
      // border: 1px solid #f3eab1;
      color: #827940;
   }

   &.guest-status-pendingapproval {
      background-color: #3a3a3a8c;
      // border: 1px solid rgba(210, 203, 255, 0.88);
      color: #3a3a3aa7;
   }

   &.guest-status-pendingacceptance {
      background-color: #4b8a85;
      color: #4b8a85;
   }
}
