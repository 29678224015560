@import "app/assets/common/bootstrap-media-mixins";
.library-template-list {
   .wp-type {
      position: relative;
      padding-left: 35px;
      .icon-holder {
         position: absolute;
         display: block;
         left: 0;
         top: 50%;
         margin-top: -12px;
      }
   }
}
