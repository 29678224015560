.team-information-activity {
   padding: 12px 20px;
   border-radius: 6px;
   background-color: #fde5e5;
   max-height: 68px;
   display: flex;
   align-items: center;
   min-width: 272px;

   .activity-context {
      display: flex;
      align-items: center;

      .icon {
         width: 55px;
         .icon-square {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            background-color: #648021bf;
            text-align: center;
            line-height: 48px;
            box-shadow: inset 0px 1px 0px #0000001f;
            i {
               font-size: 22px;
               color: white;
            }
            .loader {
               padding-top: 4px;
               .animated-loader {
                  border-top-color: transparent !important;
               }
            }
         }
      }
      .activity-title {
         font-weight: 500;
         flex: 1;
         padding-right: 12px;
         margin-right: 12px;
         border-right: 1px solid rgba(0, 0, 0, 0.05);
         .title {
            color: #9f2727;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 0px;
         }
         .status {
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            color: #26323a;
         }
      }
      .activity-stats {
         ul,
         li {
            margin: 0;
            padding: 0;
            list-style-type: none;
         }
         .title {
            color: #718690;
            font-weight: 500;
            margin-bottom: 0px;
         }
         .stat-value {
            color: #26323a;
            font-weight: 500;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
         }
      }
   }
   &.status-extended {
      background-color: #bce8e3;
      .activity-context {
         .icon {
            .icon-square {
               background-color: #4b8a85;
            }
         }
         .activity-title {
            .title {
               color: #4b8a85;
            }
         }
      }
   }
   &.status-active {
      background-color: rgba(147, 191, 45, 0.15);
      .activity-context {
         .icon {
            .icon-square {
               background-color: #648021bf;
            }
         }
         .activity-title {
            .title {
               color: #648021;
            }
         }
      }
   }
   &.status-inactive {
      background-color: #e4cfb5;
      .activity-context {
         .icon {
            .icon-square {
               background-color: #b36909;
            }
         }
         .activity-title {
            .title {
               color: #b36909;
            }
         }
      }
   }
   &.status-pendingapproval {
      background-color: rgba(210, 203, 255, 0.88);
      .activity-context {
         .icon {
            .icon-square {
               background-color: rgba(135, 125, 201, 0.88);
            }
         }
      }
   }
   &.status-deleted {
      background-color: #f3eab1;
      .activity-context {
         .icon {
            .icon-square {
               background-color: #d8cc7c;
            }
         }
      }
   }
   &.status-failed {
      background-color: #f5000030;
      .activity-context {
         .icon {
            .icon-square {
               background-color: #d54949;
            }
         }
      }
   }
   &.status-done {
      background-color: rgba(7, 109, 44, 0.2);
      .activity-context {
         .icon {
            .icon-square {
               background-color: #8ca391;
            }
         }
         .activity-title {
            .title {
               color: #065f1c;
            }
         }
      }
   }
}
