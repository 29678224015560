@import "_metronic/_assets/sass/breakpoints";

.team-provisioning-review-workflow {
   padding: 20px 20px;
   background-color: white;

   .admin-options,
   .kt-portlet__head {
      border: 1px solid rgba(0, 0, 0, 0.1);
   }
   .kt-portlet__body {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top: 0p solid transparent !important;
      border-radius: 0 0 4px 4px !important;
   }

   .kt-portlet__head {
      &:after {
         display: none;
      }
   }

   .stats-box {
      .kt-portlet {
         border: 1px solid rgba(0, 0, 0, 0.1);
      }
   }
}