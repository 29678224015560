@import "app/assets/common/bootstrap-media-mixins";
.step-select-workspace {
   @include media-breakpoint-between(lg, xl) {
      // max-width: 800px;
      margin: 0 auto;
   }
   @include media-breakpoint-up(xl) {
      // max-width: 1050px;
      margin: 0 auto;
   }
   .ScrollbarsCustom {
      @include media-breakpoint-up(md) {
         max-height: calc(100vh - 220px);
         min-height: calc(100vh - 220px);
      }
      @include media-breakpoint-down(sm) {
         max-height: calc(100vh - 220px);
         min-height: calc(100vh - 220px);
      }
      .ScrollbarsCustom-Wrapper {
         right: 0;
      }
   }
   .sub-header {
      border-bottom: 1px solid #e1e7ee;
      padding-bottom: 20px;
      margin-bottom: 35px;
      position: relative;
      @include media-breakpoint-down(sm) {
         margin-bottom: 20px;
         padding-bottom: 10px;
      }
      .filter-button {
         background: #ffffff;
         border: 2px solid #efefef;
         //box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         display: inline-block;
         vertical-align: top;
         height: 40px;
         padding: 8px 12px;
         box-sizing: border-box;
         position: relative;
         cursor: pointer;

         &:hover {
            border: 2px solid #d1d1d1;
         }

         .custom-filter-button {
            width: 17px;
            height: 17px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: 4px;
            margin-right: 10px;
            @include media-breakpoint-down(sm) {
               margin-right: 0px;
            }
            > div {
               position: absolute;
               height: 1px;
               left: 0;
               right: 0;
               background-color: #26323a;
               &.line1 {
                  top: 0;
               }
               &.line2 {
                  top: 4px;
                  left: 2px;
                  right: 2px;
               }
               &.line3 {
                  top: 8px;
                  left: 4px;
                  right: 4px;
               }
               &.line4 {
                  top: 12px;
                  left: 6px;
                  right: 6px;
               }
            }
         }
         span {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #26323a;
            @include media-breakpoint-down(sm) {
               display: none;
            }
         }
      }
      .filter-dropdown-wrapper {
         display: inline-block;
         position: relative;
         &.hidden {
            display: none;
         }
         @include media-breakpoint-down(sm) {
            margin-right: 10px;
         }
      }
      .filter-dropdown {
         position: absolute;
         z-index: 99;
         top: 42px;
         // left:-150px;
         left: 0;
         // margin-left: 20px;
         width: 300px;
         background: #ffffff;
         box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
         border-radius: 4px;
         .form-group {
            margin-bottom: 8px;
            .form-check-label {
               font-weight: 500;
               margin-left: 20px;
               color: #718690;
               line-height: 23px;
            }
            input:checked + label {
               color: #26323a;
            }
         }
         .checkboxes {
            padding: 0px 20px 10px 20px;
         }
         .filter-search {
            background: #ffffff;
            border: 2px solid #efefef;
            //box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            display: block;
            vertical-align: top;
            height: 32px;
            padding: 5px 7px;
            box-sizing: border-box;
            margin: 16px 16px 16px 16px;

            .icon {
               display: inline-block;
               i {
                  font-size: 15px;
                  color: #9da9ae;
                  position: relative;
                  top: -1px;
               }
            }
            input {
               display: inline-block;
               // margin:0;
               // padding: 0;
               padding-left: 10px;
               border: 0px solid transparent;
               outline: none;
               position: relative;
               top: -2px;
               font-size: 13px;
               font-weight: 500;
               line-height: 20px;
               &::placeholder {
                  /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: #9da9ae;
                  font-weight: 500;
                  opacity: 1; /* Firefox */
               }

               &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #9da9ae;
                  font-weight: 500;
               }

               &::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: #9da9ae;
                  font-weight: 500;
               }
            }
         }
         .filter-footer {
            background: #f4f5f7;
            border-radius: 4px;
            margin: 12px 8px 8px 8px;
            padding: 8px;
            .btn {
               padding: 0.45rem 0.65rem;
            }
            .cancel {
               color: #007989;
               font-weight: 600;
            }
            .apply {
               background: #007989;
               border-radius: 4px;
               color: white;
               font-weight: 600;
               float: right;
            }
         }
      }
      .search {
         background: #ffffff;
         border: 2px solid #efefef;
         //box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         display: inline-block;
         vertical-align: top;
         height: 40px;
         padding: 8px 12px;
         box-sizing: border-box;
         margin-left: 10px;
         min-width: 260px;
         @include media-breakpoint-down(sm) {
            margin-left: 0px;
         }
         .icon {
            display: inline-block;
            i {
               font-size: 15px;
               color: #9da9ae;
            }
         }
         input {
            display: inline-block;
            margin: 0;
            padding: 0;
            padding-left: 10px;
            border: 0px solid transparent;
            outline: none;
            position: relative;
            top: -1px;
            font-size: 13px;
            font-weight: 500;
            &::placeholder {
               /* Chrome, Firefox, Opera, Safari 10.1+ */
               color: #9da9ae;
               opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
               /* Internet Explorer 10-11 */
               color: #9da9ae;
            }

            &::-ms-input-placeholder {
               /* Microsoft Edge */
               color: #9da9ae;
            }
         }
      }
      .sort {
         float: right;
         font-size: 13px;
         font-weight: 600;
         padding: 0px 0px;
         @include media-breakpoint-down(sm) {
            float: none;
            margin-top: 10px;
         }
         .sort-by {
            color: #718690;
            padding-right: 4px;
         }
         .dropdown {
            display: inline-block;
            .form-dropdown {
               .form-control {
                  display: inline-block;
                  transition: all 0.3s;
                  min-width: 128px !important;
                  .arrow {
                     width: 20px;
                     display: inline-block;
                     svg {
                        top: calc(50% - 2px);
                     }
                  }
               }
               .dd-header {
                  font-weight: 600;
                  min-width: 100px;
               }
               .dd-list {
                  border-color: transparent;
                  transition: all 0.3s;
                  &.show {
                     border-color: #ced4da;
                  }
               }
               .dd-header {
               }
               &.closed {
                  .form-control {
                     border-color: transparent;
                  }
               }
            }
         }

         // .dd-header {
         //     color: #26323A;
         // }
         // i {
         //     color: #26323A;
         //     font-size:8px;
         //     margin-left: 5px;
         // }
      }
      .applied-tags {
         padding-top: 20px;
         font-weight: 500;
         font-size: 13px;
         line-height: 16px;
         color: #26323a;
         .label,
         .tags,
         .clear {
            display: inline-block;
         }
         .tags {
            padding: 0 5px;
            .tag {
               display: inline-block;
               background: rgba(0, 121, 137, 0.1);
               border-radius: 4px;
               padding: 6px 30px 6px 10px;
               color: #0c95a6;
               position: relative;
               margin-left: 8px;
               i {
                  position: absolute;
                  right: 8px;
                  top: 50%;
                  margin-top: -6px;
                  z-index: 2;
                  cursor: pointer;
               }
            }
         }
         .clear {
            color: #007989;
            padding-left: 10px;
            cursor: pointer;
            &:hover {
               color: #026977;
            }
         }
      }
   }
   .workflows-row {
      padding: 0em;
      &.row > div {
         margin-bottom: 30px;
         display: flex;
         width: 100%;
         @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
         }
      }
      .workspace {
         display: flex;
         gap: 1rem;
         flex-direction: column;
         position: relative;
         padding: 4px 4px 15px 4px;
         box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
         width: 100%;
         cursor: pointer;
         border: 2px solid transparent;
         transition: all 0.3s;
         border-radius: 8px;
         background-color: white;
         .editable {
            cursor: pointer;
            user-select: none;
         }
         @include media-breakpoint-up(md) {
         }
         @include media-breakpoint-down(sm) {
            padding-bottom: 15px;
            min-height: 100px;
            max-width: 80vw;
         }
         .preview-image {
            width: 100%;
            height: 140px;
            // border:2px solid #B5B5B5;
            border-radius: 5px;
            position: relative;
            background-color: white;
            @include media-breakpoint-down(sm) {
               height: 110px;
               margin-bottom: 5px;
            }
            > .image {
               height: 100%;
               width: 100%;
               background-size: cover;
               background-position: center;
               border-radius: 3px;
               border: 1px solid #c1c1c1;
            }
            .missing-image {
               position: absolute;
               width: 30px;
               height: 30px;
               opacity: 0.3;
               top: 50%;
               margin-top: -40px;
               left: 50%;
               margin-left: -40px;
               svg {
                  width: 80px;
                  height: 80px;
               }
            }
            .select-area {
               position: absolute;
               opacity: 0;
               transition: all 0.25s;
               top: 0;
               left: 0;
               height: 100%;
               width: 100%;
               z-index: 2;

               border-radius: 2px;
               text-align: center;
               @include media-breakpoint-up(md) {
                  background-color: rgba(0, 0, 0, 0.507);
               }
               > div {
                  height: 38px;
                  margin-top: -22px;
                  top: 50%;
                  position: relative;
                  opacity: 1;
                  @include media-breakpoint-down(md) {
                     height: 40px;
                  }
               }
               @include media-breakpoint-down(sm) {
                  > div {
                     display: none;
                  }
               }
            }
            @include media-breakpoint-up(md) {
               &:hover {
                  .select-area {
                     opacity: 1;
                  }
               }
            }
            .workspace-type {
               display: flex;
               align-items: center;
               position: absolute;
               left: 10px;
               bottom: 10px;
               background: #ffffff;
               box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
               border-radius: 8px;
               padding: 8px;
               white-space: nowrap;
               transition: all 0.5s;

               .text {
                  font-weight: 600;
                  padding-left: 10px;
                  position: relative;
                  top: 1px;
                  padding-right: 4px;
                  white-space: nowrap;
               }

               &.team-title-style {
                  color: #4b53bc;
                  border: 2px solid #4b53bc;
                  border-radius: 10px;
               }
               &.viva-title-style {
                  color: #1f71d0;
                  border: 2px solid #1f71d0;
                  border-radius: 10px;
               }

               &.sharepoint-title-style {
                  color: #03898d;
                  border: 2px solid #03898d;
                  border-radius: 10px;
               }

               .template-type-icon {
                  width: 23px;
                  height: 22px;
               }
            }
         }
         .title {
            font-size: 18px;
            font-weight: 600;
            color: #414141;
            padding: 0 15px;
            @include media-breakpoint-down(sm) {
               font-size: 21px;
            }
         }
         .description {
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.222727px;
            color: #718690;
            overflow: hidden;
            padding: 0 15px;
            .workspace {
               margin: 0;
               padding: 0;
            }
            .editor {
               padding: 0;
            }
         }
         .more-info {
            position: relative;
            & > i,
            & > p {
               pointer-events: none;
            }
            .link {
               position: absolute;
               width: 100%;
               inset: 0;
            }
            width: fit-content;
            max-width: calc(100% - 30px);
            display: inline-flex;
            z-index: 10;
            align-items: center;
            justify-content: flex-start;
            gap: 0.35rem;
            color: #3da0a2;
            border-radius: 6px;
            padding: 4px 8px;
            cursor: pointer;
            overflow: hidden;
            margin: 0px 15px;
            margin-top: 5px;
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
            border: 1px solid #3da0a2;
            transition: box-shadow 0.2s ease-in-out;
            user-select: none;
            p {
               margin: 0 0 1px 0;
               font-weight: 400;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
            }
            .link-icon {
               font-size: 12px;
               font-weight: 600;
            }
            i {
               font-size: 16px;
               &.link-icon {
                  color: #9ccbd1;
               }
            }
            &:hover {
               box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
            }
         }
         .features {
            // position: absolute;
            bottom: 20px;
            max-height: 30px;
            overflow: hidden;
            padding: 0 15px;
            margin-top: 15px;
            @include media-breakpoint-down(sm) {
               position: relative;
               bottom: auto;
            }
            > ul {
               li {
                  display: inline-block;
                  vertical-align: top;
                  background: rgba(181, 181, 181, 0.2);
                  mix-blend-mode: normal;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  text-align: center;
                  line-height: 30px;
                  margin-right: 8px;
                  svg {
                     text-align: center;
                     vertical-align: center;
                     height: 14px;
                     width: 14px;
                     color: #676767;
                  }
                  i {
                     text-align: center;
                     vertical-align: center;
                     // height: 14px;
                     // width: 14px;
                     color: #676767;
                     font-size: 18px;
                     line-height: 30px;
                  }
                  &.workspace-type {
                     .template-type-icon {
                        width: 16px;
                        height: 14px;
                     }
                  }
               }
            }
         }

         &:hover {
            // border: 2px solid #d7dee6;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
         }

         &.selected {
            border: 2px solid #007989;
            cursor: default;
         }
      }
      .no-results {
         margin: 50px auto;
      }
   }

   .loading-skeleton {
      .sub-header {
         .filter-dropdown-wrapper {
            .filter-button {
               > * {
                  opacity: 0;
               }
               // opacity: 0;
               &:hover {
                  border: 2px solid #efefef;
                  cursor: default;
               }
            }
         }
         .search {
            > * {
               opacity: 0;
            }
            input {
               cursor: default;
            }
         }
         .sort {
            display: none;
         }
      }
      .workflows-row {
         .workspace {
            .preview-image {
               background-color: #f1f1f1;
            }
            .title {
               display: block;
               margin: 0 15px;
               border-radius: 5px;
               width: calc(100% - 30px);
               height: 20px;
               background-color: #f7f7f7;
            }
            .description {
               display: block;
               border-radius: 5px;
               margin: 0 15px;
               width: calc(100% - 30px);
               height: 50px;
               margin-top: 15px;
               background-color: #f7f7f7;
            }
            &:hover {
               border-color: transparent;
               cursor: default;
            }
         }
      }
      .animated-wave {
         overflow: hidden;
         position: relative;
         &:before {
            content: "";
            display: block;
            position: absolute;
            left: -150px;
            top: 0;
            height: 100%;
            width: 150px;
            background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
            animation: load 2s cubic-bezier(0.4, 0, 0.2, 1);
            animation-iteration-count: infinite;
         }
         @keyframes load {
            from {
               left: -150px;
            }
            to {
               left: 100%;
            }
         }
      }
   }
}
