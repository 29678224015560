@font-face {
  font-family: "orchestry-icon-font";
  src: url('orchestry-icon-font.eot?t=1737478813100'); /* IE9*/
  src: url('orchestry-icon-font.eot?t=1737478813100#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("orchestry-icon-font.woff2?t=1737478813100") format("woff2"),
  url("orchestry-icon-font.woff?t=1737478813100") format("woff"),
  url('orchestry-icon-font.ttf?t=1737478813100') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('orchestry-icon-font.svg?t=1737478813100#orchestry-icon-font') format('svg'); /* iOS 4.1- */
}

[class^="orchicon-"], [class*=" orchicon-"] {
  font-family: 'orchestry-icon-font' !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root {
--orchicon-activity: "\ea01";
--orchicon-AI: "\ea02";
--orchicon-alert-circle-lg: "\ea03";
--orchicon-alert-circle-md: "\ea04";
--orchicon-alert-circle-sm: "\ea05";
--orchicon-alert-circle-xl: "\ea06";
--orchicon-alert-circle-xs: "\ea07";
--orchicon-alert-circle: "\ea08";
--orchicon-alert-octagon: "\ea09";
--orchicon-alert-triangle: "\ea0a";
--orchicon-align-top-arrow-01: "\ea0b";
--orchicon-archive: "\ea0c";
--orchicon-arrow-circle-broken-up: "\ea0d";
--orchicon-arrow-down: "\ea0e";
--orchicon-arrow-right: "\ea0f";
--orchicon-arrow-up: "\ea10";
--orchicon-arrows-down-lg: "\ea11";
--orchicon-arrows-down-md: "\ea12";
--orchicon-arrows-down-sm: "\ea13";
--orchicon-arrows-down-xl: "\ea14";
--orchicon-arrows-down-xs: "\ea15";
--orchicon-arrows-up-lg: "\ea16";
--orchicon-arrows-up-md: "\ea17";
--orchicon-arrows-up-sm: "\ea18";
--orchicon-arrows-up-xl: "\ea19";
--orchicon-arrows-up-xs: "\ea1a";
--orchicon-at-sign: "\ea1b";
--orchicon-Automation: "\ea1c";
--orchicon-award-03-light: "\ea1d";
--orchicon-award-03: "\ea1e";
--orchicon-award-05: "\ea1f";
--orchicon-bar-chart-07: "\ea20";
--orchicon-bell-off-02: "\ea21";
--orchicon-bell-ringing-02: "\ea22";
--orchicon-bookmark-check: "\ea23";
--orchicon-bookmark: "\ea24";
--orchicon-box: "\ea25";
--orchicon-building-06: "\ea26";
--orchicon-calendar-alert: "\ea27";
--orchicon-calendar-check-02: "\ea28";
--orchicon-calendar-date: "\ea29";
--orchicon-calendar-heart-01: "\ea2a";
--orchicon-calendar: "\ea2b";
--orchicon-check-circle-2-lg: "\ea2c";
--orchicon-check-circle-2-md: "\ea2d";
--orchicon-check-circle-2-sm: "\ea2e";
--orchicon-check-circle-2-xl: "\ea2f";
--orchicon-check-circle-2-xs: "\ea30";
--orchicon-check-circle-broken: "\ea31";
--orchicon-check-circle: "\ea32";
--orchicon-check-done-02: "\ea33";
--orchicon-check-heart: "\ea34";
--orchicon-check-lg: "\ea35";
--orchicon-check-md: "\ea36";
--orchicon-check-sm: "\ea37";
--orchicon-check-square: "\ea38";
--orchicon-check-verified-01: "\ea39";
--orchicon-check-verified-02: "\ea3a";
--orchicon-check-verified-03: "\ea3b";
--orchicon-check-xl: "\ea3c";
--orchicon-check-xs: "\ea3d";
--orchicon-check: "\ea3e";
--orchicon-chevron-down: "\ea3f";
--orchicon-chevron-left-pagination: "\ea40";
--orchicon-chevron-left: "\ea41";
--orchicon-chevron-right-pagination: "\ea42";
--orchicon-chevron-up: "\ea43";
--orchicon-chevrons-up-down: "\ea44";
--orchicon-circle-slashed: "\ea45";
--orchicon-circle: "\ea46";
--orchicon-clock-snooze: "\ea47";
--orchicon-cloud-off: "\ea48";
--orchicon-code-browser: "\ea49";
--orchicon-codesandbox: "\ea4a";
--orchicon-confetti: "\ea4b";
--orchicon-copy-05: "\ea4c";
--orchicon-crown: "\ea4d";
--orchicon-currency-dollar: "\ea4e";
--orchicon-data: "\ea4f";
--orchicon-database-01-lg: "\ea50";
--orchicon-database-01-md: "\ea51";
--orchicon-database-01-sm: "\ea52";
--orchicon-database-01-xl: "\ea53";
--orchicon-database-01-xs: "\ea54";
--orchicon-database-01: "\ea55";
--orchicon-database-03: "\ea56";
--orchicon-database: "\ea57";
--orchicon-download-02: "\ea58";
--orchicon-edit-01: "\ea59";
--orchicon-edit-02: "\ea5a";
--orchicon-edit-03: "\ea5b";
--orchicon-external-link-lg: "\ea5c";
--orchicon-external-link-md: "\ea5d";
--orchicon-external-link-sm: "\ea5e";
--orchicon-external-link-xl: "\ea5f";
--orchicon-external-link-xs: "\ea60";
--orchicon-external-link: "\ea61";
--orchicon-eye-off: "\ea62";
--orchicon-eye: "\ea63";
--orchicon-file-attachment-04-lg: "\ea64";
--orchicon-file-attachment-04-md: "\ea65";
--orchicon-file-attachment-04-sm: "\ea66";
--orchicon-file-attachment-04-xl: "\ea67";
--orchicon-file-attachment-04-xs: "\ea68";
--orchicon-file-attachment-04: "\ea69";
--orchicon-file-check-02: "\ea6a";
--orchicon-file-heart-02: "\ea6b";
--orchicon-file-minus-2: "\ea6c";
--orchicon-file-plus-01: "\ea6d";
--orchicon-file-version-history: "\ea6e";
--orchicon-files: "\ea6f";
--orchicon-filter-funnel-02: "\ea70";
--orchicon-flip-backward: "\ea71";
--orchicon-folder-open: "\ea72";
--orchicon-folder: "\ea73";
--orchicon-git-branch-01: "\ea74";
--orchicon-guest-lg: "\ea75";
--orchicon-guest-md: "\ea76";
--orchicon-guest-sm: "\ea77";
--orchicon-guest-xl: "\ea78";
--orchicon-guest-xs: "\ea79";
--orchicon-guest: "\ea7a";
--orchicon-heart-hexagon: "\ea7b";
--orchicon-hearts: "\ea7c";
--orchicon-help-circle: "\ea7d";
--orchicon-help-hexagon-lg: "\ea7e";
--orchicon-help-hexagon-md: "\ea7f";
--orchicon-help-hexagon-sm: "\ea80";
--orchicon-help-hexagon-xl: "\ea81";
--orchicon-help-hexagon-xs: "\ea82";
--orchicon-help-hexagon: "\ea83";
--orchicon-help-octagon: "\ea84";
--orchicon-hourglass-03: "\ea85";
--orchicon-Icon: "\ea86";
--orchicon-info-circle: "\ea87";
--orchicon-info-octagon: "\ea88";
--orchicon-info-square: "\ea89";
--orchicon-info: "\ea8a";
--orchicon-key-02: "\ea8b";
--orchicon-keyboard-02: "\ea8c";
--orchicon-layout-template: "\ea8d";
--orchicon-layout: "\ea8e";
--orchicon-library-lg: "\ea8f";
--orchicon-library-md: "\ea90";
--orchicon-library-sm: "\ea91";
--orchicon-library-xl: "\ea92";
--orchicon-library-xs: "\ea93";
--orchicon-life-buoy-01: "\ea94";
--orchicon-lightbulb-05: "\ea95";
--orchicon-lightning-01-lg: "\ea96";
--orchicon-lightning-01-md: "\ea97";
--orchicon-lightning-01-sm: "\ea98";
--orchicon-lightning-01-xl: "\ea99";
--orchicon-lightning-01-xs: "\ea9a";
--orchicon-lightning-01: "\ea9b";
--orchicon-lightning-02: "\ea9c";
--orchicon-line-chart-up-01: "\ea9d";
--orchicon-link-2-off: "\ea9e";
--orchicon-link-external-02-lg: "\ea9f";
--orchicon-link-external-02-md: "\eaa0";
--orchicon-link-external-02-sm: "\eaa1";
--orchicon-link-external-02-xl: "\eaa2";
--orchicon-link-external-02-xs: "\eaa3";
--orchicon-link: "\eaa4";
--orchicon-list-checks: "\eaa5";
--orchicon-list: "\eaa6";
--orchicon-loading-02: "\eaa7";
--orchicon-lock-01-lg: "\eaa8";
--orchicon-lock-01-md: "\eaa9";
--orchicon-lock-01-sm: "\eaaa";
--orchicon-lock-01-xl: "\eaab";
--orchicon-lock-01-xs: "\eaac";
--orchicon-lock-01: "\eaad";
--orchicon-lock-unlocked-01-lg: "\eaae";
--orchicon-lock-unlocked-01-md: "\eaaf";
--orchicon-lock-unlocked-01-sm: "\eab0";
--orchicon-lock-unlocked-01-xl: "\eab1";
--orchicon-lock-unlocked-01-xs: "\eab2";
--orchicon-Logomark: "\eab3";
--orchicon-mail-01: "\eab4";
--orchicon-member: "\eab5";
--orchicon-message-chat-circle-lg: "\eab6";
--orchicon-message-chat-circle-md: "\eab7";
--orchicon-message-chat-circle-sm: "\eab8";
--orchicon-message-chat-circle-xl: "\eab9";
--orchicon-message-chat-circle-xs: "\eaba";
--orchicon-message-chat-circle: "\eabb";
--orchicon-message-chat-square: "\eabc";
--orchicon-message-circle-01: "\eabd";
--orchicon-message-notification-circle: "\eabe";
--orchicon-message-plus-circle: "\eabf";
--orchicon-message-square-02: "\eac0";
--orchicon-message-text-circle-01: "\eac1";
--orchicon-message-x-circle: "\eac2";
--orchicon-minus-circle-lg: "\eac3";
--orchicon-minus-circle-md: "\eac4";
--orchicon-minus-circle-sm: "\eac5";
--orchicon-minus-circle-xl: "\eac6";
--orchicon-minus-circle-xs: "\eac7";
--orchicon-more-horizontal: "\eac8";
--orchicon-mouse-pointer-2: "\eac9";
--orchicon-notification-box: "\eaca";
--orchicon-owner: "\eacb";
--orchicon-package-plus: "\eacc";
--orchicon-pencil-line: "\eacd";
--orchicon-percent: "\eace";
--orchicon-perspective-02: "\eacf";
--orchicon-play-circle: "\ead0";
--orchicon-play-square: "\ead1";
--orchicon-play: "\ead2";
--orchicon-plus-circle: "\ead3";
--orchicon-plus-square: "\ead4";
--orchicon-plus: "\ead5";
--orchicon-refresh-ccw-01: "\ead6";
--orchicon-refresh-ccw-02: "\ead7";
--orchicon-refresh-ccw: "\ead8";
--orchicon-refresh-cs-lg: "\ead9";
--orchicon-refresh-cs-md: "\eada";
--orchicon-refresh-cs-sm: "\eadb";
--orchicon-refresh-cs-xl: "\eadc";
--orchicon-refresh-cs-xs: "\eadd";
--orchicon-refresh-cw-04: "\eade";
--orchicon-refresh-cw-lg: "\eadf";
--orchicon-refresh-cw-md: "\eae0";
--orchicon-refresh-cw-sm: "\eae1";
--orchicon-refresh-cw-xl: "\eae2";
--orchicon-refresh-cw-xs: "\eae3";
--orchicon-repeat-04: "\eae4";
--orchicon-review-process: "\eae5";
--orchicon-rocket-02: "\eae6";
--orchicon-save-01: "\eae7";
--orchicon-save-02: "\eae8";
--orchicon-scales-01: "\eae9";
--orchicon-scan: "\eaea";
--orchicon-search-md: "\eaeb";
--orchicon-search: "\eaec";
--orchicon-send-01: "\eaed";
--orchicon-settings-01: "\eaee";
--orchicon-settings-04: "\eaef";
--orchicon-settings-2: "\eaf0";
--orchicon-settings: "\eaf1";
--orchicon-share-06: "\eaf2";
--orchicon-shield-check: "\eaf3";
--orchicon-shield-tick-lg: "\eaf4";
--orchicon-shield-tick-md: "\eaf5";
--orchicon-shield-tick-sm: "\eaf6";
--orchicon-shield-tick-xl: "\eaf7";
--orchicon-shield-tick-xs: "\eaf8";
--orchicon-shield-tick: "\eaf9";
--orchicon-single-most-recent: "\eafa";
--orchicon-slash: "\eafb";
--orchicon-sort-desc: "\eafc";
--orchicon-sprawl: "\eafd";
--orchicon-star-01: "\eafe";
--orchicon-star-02: "\eaff";
--orchicon-star-05-lg: "\eb00";
--orchicon-star-05-md: "\eb01";
--orchicon-star-05-sm: "\eb02";
--orchicon-star-05-xl: "\eb03";
--orchicon-star-05-xs: "\eb04";
--orchicon-star-05: "\eb05";
--orchicon-table: "\eb06";
--orchicon-tag-01: "\eb07";
--orchicon-tag-03: "\eb08";
--orchicon-thumbs-down: "\eb09";
--orchicon-thumbs-up-lg: "\eb0a";
--orchicon-thumbs-up-md: "\eb0b";
--orchicon-thumbs-up-sm: "\eb0c";
--orchicon-thumbs-up-xl: "\eb0d";
--orchicon-thumbs-up-xs: "\eb0e";
--orchicon-thumbs-up: "\eb0f";
--orchicon-Tick: "\eb10";
--orchicon-Tilde: "\eb11";
--orchicon-timer-off: "\eb12";
--orchicon-timer: "\eb13";
--orchicon-tool-01: "\eb14";
--orchicon-tool-02: "\eb15";
--orchicon-trash-01: "\eb16";
--orchicon-trash-02: "\eb17";
--orchicon-trophy-01: "\eb18";
--orchicon-underline-01: "\eb19";
--orchicon-unlink: "\eb1a";
--orchicon-upload-02: "\eb1b";
--orchicon-upload-03: "\eb1c";
--orchicon-upload-04: "\eb1d";
--orchicon-upload-cloud: "\eb1e";
--orchicon-user-03: "\eb1f";
--orchicon-user-check-01: "\eb20";
--orchicon-user-edit: "\eb21";
--orchicon-user-left-01: "\eb22";
--orchicon-user-plus-01: "\eb23";
--orchicon-user-square: "\eb24";
--orchicon-user-target: "\eb25";
--orchicon-users-01: "\eb26";
--orchicon-users-check-lg: "\eb27";
--orchicon-users-check-md: "\eb28";
--orchicon-users-check-sm: "\eb29";
--orchicon-users-check-xl: "\eb2a";
--orchicon-users-check-xs: "\eb2b";
--orchicon-users-check: "\eb2c";
--orchicon-users-edit: "\eb2d";
--orchicon-users-plus: "\eb2e";
--orchicon-visible-in-search: "\eb2f";
--orchicon-workspace-review: "\eb30";
--orchicon-x-circle: "\eb31";
--orchicon-x-close-lg: "\eb32";
--orchicon-x-close-md: "\eb33";
--orchicon-x-close-sm: "\eb34";
--orchicon-x-close-xl: "\eb35";
--orchicon-x-close-xs: "\eb36";
--orchicon-x-close: "\eb37";
--orchicon-x-lg: "\eb38";
--orchicon-x-md: "\eb39";
--orchicon-x-sm: "\eb3a";
--orchicon-x-square: "\eb3b";
--orchicon-x-xl: "\eb3c";
--orchicon-x-xs: "\eb3d";
--orchicon-x: "\eb3e";
--orchicon-zap: "\eb3f";
}
.orchicon-activity:before { content: var(--orchicon-activity); }
.orchicon-AI:before { content: var(--orchicon-AI); }
.orchicon-alert-circle-lg:before { content: var(--orchicon-alert-circle-lg); }
.orchicon-alert-circle-md:before { content: var(--orchicon-alert-circle-md); }
.orchicon-alert-circle-sm:before { content: var(--orchicon-alert-circle-sm); }
.orchicon-alert-circle-xl:before { content: var(--orchicon-alert-circle-xl); }
.orchicon-alert-circle-xs:before { content: var(--orchicon-alert-circle-xs); }
.orchicon-alert-circle:before { content: var(--orchicon-alert-circle); }
.orchicon-alert-octagon:before { content: var(--orchicon-alert-octagon); }
.orchicon-alert-triangle:before { content: var(--orchicon-alert-triangle); }
.orchicon-align-top-arrow-01:before { content: var(--orchicon-align-top-arrow-01); }
.orchicon-archive:before { content: var(--orchicon-archive); }
.orchicon-arrow-circle-broken-up:before { content: var(--orchicon-arrow-circle-broken-up); }
.orchicon-arrow-down:before { content: var(--orchicon-arrow-down); }
.orchicon-arrow-right:before { content: var(--orchicon-arrow-right); }
.orchicon-arrow-up:before { content: var(--orchicon-arrow-up); }
.orchicon-arrows-down-lg:before { content: var(--orchicon-arrows-down-lg); }
.orchicon-arrows-down-md:before { content: var(--orchicon-arrows-down-md); }
.orchicon-arrows-down-sm:before { content: var(--orchicon-arrows-down-sm); }
.orchicon-arrows-down-xl:before { content: var(--orchicon-arrows-down-xl); }
.orchicon-arrows-down-xs:before { content: var(--orchicon-arrows-down-xs); }
.orchicon-arrows-up-lg:before { content: var(--orchicon-arrows-up-lg); }
.orchicon-arrows-up-md:before { content: var(--orchicon-arrows-up-md); }
.orchicon-arrows-up-sm:before { content: var(--orchicon-arrows-up-sm); }
.orchicon-arrows-up-xl:before { content: var(--orchicon-arrows-up-xl); }
.orchicon-arrows-up-xs:before { content: var(--orchicon-arrows-up-xs); }
.orchicon-at-sign:before { content: var(--orchicon-at-sign); }
.orchicon-Automation:before { content: var(--orchicon-Automation); }
.orchicon-award-03-light:before { content: var(--orchicon-award-03-light); }
.orchicon-award-03:before { content: var(--orchicon-award-03); }
.orchicon-award-05:before { content: var(--orchicon-award-05); }
.orchicon-bar-chart-07:before { content: var(--orchicon-bar-chart-07); }
.orchicon-bell-off-02:before { content: var(--orchicon-bell-off-02); }
.orchicon-bell-ringing-02:before { content: var(--orchicon-bell-ringing-02); }
.orchicon-bookmark-check:before { content: var(--orchicon-bookmark-check); }
.orchicon-bookmark:before { content: var(--orchicon-bookmark); }
.orchicon-box:before { content: var(--orchicon-box); }
.orchicon-building-06:before { content: var(--orchicon-building-06); }
.orchicon-calendar-alert:before { content: var(--orchicon-calendar-alert); }
.orchicon-calendar-check-02:before { content: var(--orchicon-calendar-check-02); }
.orchicon-calendar-date:before { content: var(--orchicon-calendar-date); }
.orchicon-calendar-heart-01:before { content: var(--orchicon-calendar-heart-01); }
.orchicon-calendar:before { content: var(--orchicon-calendar); }
.orchicon-check-circle-2-lg:before { content: var(--orchicon-check-circle-2-lg); }
.orchicon-check-circle-2-md:before { content: var(--orchicon-check-circle-2-md); }
.orchicon-check-circle-2-sm:before { content: var(--orchicon-check-circle-2-sm); }
.orchicon-check-circle-2-xl:before { content: var(--orchicon-check-circle-2-xl); }
.orchicon-check-circle-2-xs:before { content: var(--orchicon-check-circle-2-xs); }
.orchicon-check-circle-broken:before { content: var(--orchicon-check-circle-broken); }
.orchicon-check-circle:before { content: var(--orchicon-check-circle); }
.orchicon-check-done-02:before { content: var(--orchicon-check-done-02); }
.orchicon-check-heart:before { content: var(--orchicon-check-heart); }
.orchicon-check-lg:before { content: var(--orchicon-check-lg); }
.orchicon-check-md:before { content: var(--orchicon-check-md); }
.orchicon-check-sm:before { content: var(--orchicon-check-sm); }
.orchicon-check-square:before { content: var(--orchicon-check-square); }
.orchicon-check-verified-01:before { content: var(--orchicon-check-verified-01); }
.orchicon-check-verified-02:before { content: var(--orchicon-check-verified-02); }
.orchicon-check-verified-03:before { content: var(--orchicon-check-verified-03); }
.orchicon-check-xl:before { content: var(--orchicon-check-xl); }
.orchicon-check-xs:before { content: var(--orchicon-check-xs); }
.orchicon-check:before { content: var(--orchicon-check); }
.orchicon-chevron-down:before { content: var(--orchicon-chevron-down); }
.orchicon-chevron-left-pagination:before { content: var(--orchicon-chevron-left-pagination); }
.orchicon-chevron-left:before { content: var(--orchicon-chevron-left); }
.orchicon-chevron-right-pagination:before { content: var(--orchicon-chevron-right-pagination); }
.orchicon-chevron-up:before { content: var(--orchicon-chevron-up); }
.orchicon-chevrons-up-down:before { content: var(--orchicon-chevrons-up-down); }
.orchicon-circle-slashed:before { content: var(--orchicon-circle-slashed); }
.orchicon-circle:before { content: var(--orchicon-circle); }
.orchicon-clock-snooze:before { content: var(--orchicon-clock-snooze); }
.orchicon-cloud-off:before { content: var(--orchicon-cloud-off); }
.orchicon-code-browser:before { content: var(--orchicon-code-browser); }
.orchicon-codesandbox:before { content: var(--orchicon-codesandbox); }
.orchicon-confetti:before { content: var(--orchicon-confetti); }
.orchicon-copy-05:before { content: var(--orchicon-copy-05); }
.orchicon-crown:before { content: var(--orchicon-crown); }
.orchicon-currency-dollar:before { content: var(--orchicon-currency-dollar); }
.orchicon-data:before { content: var(--orchicon-data); }
.orchicon-database-01-lg:before { content: var(--orchicon-database-01-lg); }
.orchicon-database-01-md:before { content: var(--orchicon-database-01-md); }
.orchicon-database-01-sm:before { content: var(--orchicon-database-01-sm); }
.orchicon-database-01-xl:before { content: var(--orchicon-database-01-xl); }
.orchicon-database-01-xs:before { content: var(--orchicon-database-01-xs); }
.orchicon-database-01:before { content: var(--orchicon-database-01); }
.orchicon-database-03:before { content: var(--orchicon-database-03); }
.orchicon-database:before { content: var(--orchicon-database); }
.orchicon-download-02:before { content: var(--orchicon-download-02); }
.orchicon-edit-01:before { content: var(--orchicon-edit-01); }
.orchicon-edit-02:before { content: var(--orchicon-edit-02); }
.orchicon-edit-03:before { content: var(--orchicon-edit-03); }
.orchicon-external-link-lg:before { content: var(--orchicon-external-link-lg); }
.orchicon-external-link-md:before { content: var(--orchicon-external-link-md); }
.orchicon-external-link-sm:before { content: var(--orchicon-external-link-sm); }
.orchicon-external-link-xl:before { content: var(--orchicon-external-link-xl); }
.orchicon-external-link-xs:before { content: var(--orchicon-external-link-xs); }
.orchicon-external-link:before { content: var(--orchicon-external-link); }
.orchicon-eye-off:before { content: var(--orchicon-eye-off); }
.orchicon-eye:before { content: var(--orchicon-eye); }
.orchicon-file-attachment-04-lg:before { content: var(--orchicon-file-attachment-04-lg); }
.orchicon-file-attachment-04-md:before { content: var(--orchicon-file-attachment-04-md); }
.orchicon-file-attachment-04-sm:before { content: var(--orchicon-file-attachment-04-sm); }
.orchicon-file-attachment-04-xl:before { content: var(--orchicon-file-attachment-04-xl); }
.orchicon-file-attachment-04-xs:before { content: var(--orchicon-file-attachment-04-xs); }
.orchicon-file-attachment-04:before { content: var(--orchicon-file-attachment-04); }
.orchicon-file-check-02:before { content: var(--orchicon-file-check-02); }
.orchicon-file-heart-02:before { content: var(--orchicon-file-heart-02); }
.orchicon-file-minus-2:before { content: var(--orchicon-file-minus-2); }
.orchicon-file-plus-01:before { content: var(--orchicon-file-plus-01); }
.orchicon-file-version-history:before { content: var(--orchicon-file-version-history); }
.orchicon-files:before { content: var(--orchicon-files); }
.orchicon-filter-funnel-02:before { content: var(--orchicon-filter-funnel-02); }
.orchicon-flip-backward:before { content: var(--orchicon-flip-backward); }
.orchicon-folder-open:before { content: var(--orchicon-folder-open); }
.orchicon-folder:before { content: var(--orchicon-folder); }
.orchicon-git-branch-01:before { content: var(--orchicon-git-branch-01); }
.orchicon-guest-lg:before { content: var(--orchicon-guest-lg); }
.orchicon-guest-md:before { content: var(--orchicon-guest-md); }
.orchicon-guest-sm:before { content: var(--orchicon-guest-sm); }
.orchicon-guest-xl:before { content: var(--orchicon-guest-xl); }
.orchicon-guest-xs:before { content: var(--orchicon-guest-xs); }
.orchicon-guest:before { content: var(--orchicon-guest); }
.orchicon-heart-hexagon:before { content: var(--orchicon-heart-hexagon); }
.orchicon-hearts:before { content: var(--orchicon-hearts); }
.orchicon-help-circle:before { content: var(--orchicon-help-circle); }
.orchicon-help-hexagon-lg:before { content: var(--orchicon-help-hexagon-lg); }
.orchicon-help-hexagon-md:before { content: var(--orchicon-help-hexagon-md); }
.orchicon-help-hexagon-sm:before { content: var(--orchicon-help-hexagon-sm); }
.orchicon-help-hexagon-xl:before { content: var(--orchicon-help-hexagon-xl); }
.orchicon-help-hexagon-xs:before { content: var(--orchicon-help-hexagon-xs); }
.orchicon-help-hexagon:before { content: var(--orchicon-help-hexagon); }
.orchicon-help-octagon:before { content: var(--orchicon-help-octagon); }
.orchicon-hourglass-03:before { content: var(--orchicon-hourglass-03); }
.orchicon-Icon:before { content: var(--orchicon-Icon); }
.orchicon-info-circle:before { content: var(--orchicon-info-circle); }
.orchicon-info-octagon:before { content: var(--orchicon-info-octagon); }
.orchicon-info-square:before { content: var(--orchicon-info-square); }
.orchicon-info:before { content: var(--orchicon-info); }
.orchicon-key-02:before { content: var(--orchicon-key-02); }
.orchicon-keyboard-02:before { content: var(--orchicon-keyboard-02); }
.orchicon-layout-template:before { content: var(--orchicon-layout-template); }
.orchicon-layout:before { content: var(--orchicon-layout); }
.orchicon-library-lg:before { content: var(--orchicon-library-lg); }
.orchicon-library-md:before { content: var(--orchicon-library-md); }
.orchicon-library-sm:before { content: var(--orchicon-library-sm); }
.orchicon-library-xl:before { content: var(--orchicon-library-xl); }
.orchicon-library-xs:before { content: var(--orchicon-library-xs); }
.orchicon-life-buoy-01:before { content: var(--orchicon-life-buoy-01); }
.orchicon-lightbulb-05:before { content: var(--orchicon-lightbulb-05); }
.orchicon-lightning-01-lg:before { content: var(--orchicon-lightning-01-lg); }
.orchicon-lightning-01-md:before { content: var(--orchicon-lightning-01-md); }
.orchicon-lightning-01-sm:before { content: var(--orchicon-lightning-01-sm); }
.orchicon-lightning-01-xl:before { content: var(--orchicon-lightning-01-xl); }
.orchicon-lightning-01-xs:before { content: var(--orchicon-lightning-01-xs); }
.orchicon-lightning-01:before { content: var(--orchicon-lightning-01); }
.orchicon-lightning-02:before { content: var(--orchicon-lightning-02); }
.orchicon-line-chart-up-01:before { content: var(--orchicon-line-chart-up-01); }
.orchicon-link-2-off:before { content: var(--orchicon-link-2-off); }
.orchicon-link-external-02-lg:before { content: var(--orchicon-link-external-02-lg); }
.orchicon-link-external-02-md:before { content: var(--orchicon-link-external-02-md); }
.orchicon-link-external-02-sm:before { content: var(--orchicon-link-external-02-sm); }
.orchicon-link-external-02-xl:before { content: var(--orchicon-link-external-02-xl); }
.orchicon-link-external-02-xs:before { content: var(--orchicon-link-external-02-xs); }
.orchicon-link:before { content: var(--orchicon-link); }
.orchicon-list-checks:before { content: var(--orchicon-list-checks); }
.orchicon-list:before { content: var(--orchicon-list); }
.orchicon-loading-02:before { content: var(--orchicon-loading-02); }
.orchicon-lock-01-lg:before { content: var(--orchicon-lock-01-lg); }
.orchicon-lock-01-md:before { content: var(--orchicon-lock-01-md); }
.orchicon-lock-01-sm:before { content: var(--orchicon-lock-01-sm); }
.orchicon-lock-01-xl:before { content: var(--orchicon-lock-01-xl); }
.orchicon-lock-01-xs:before { content: var(--orchicon-lock-01-xs); }
.orchicon-lock-01:before { content: var(--orchicon-lock-01); }
.orchicon-lock-unlocked-01-lg:before { content: var(--orchicon-lock-unlocked-01-lg); }
.orchicon-lock-unlocked-01-md:before { content: var(--orchicon-lock-unlocked-01-md); }
.orchicon-lock-unlocked-01-sm:before { content: var(--orchicon-lock-unlocked-01-sm); }
.orchicon-lock-unlocked-01-xl:before { content: var(--orchicon-lock-unlocked-01-xl); }
.orchicon-lock-unlocked-01-xs:before { content: var(--orchicon-lock-unlocked-01-xs); }
.orchicon-Logomark:before { content: var(--orchicon-Logomark); }
.orchicon-mail-01:before { content: var(--orchicon-mail-01); }
.orchicon-member:before { content: var(--orchicon-member); }
.orchicon-message-chat-circle-lg:before { content: var(--orchicon-message-chat-circle-lg); }
.orchicon-message-chat-circle-md:before { content: var(--orchicon-message-chat-circle-md); }
.orchicon-message-chat-circle-sm:before { content: var(--orchicon-message-chat-circle-sm); }
.orchicon-message-chat-circle-xl:before { content: var(--orchicon-message-chat-circle-xl); }
.orchicon-message-chat-circle-xs:before { content: var(--orchicon-message-chat-circle-xs); }
.orchicon-message-chat-circle:before { content: var(--orchicon-message-chat-circle); }
.orchicon-message-chat-square:before { content: var(--orchicon-message-chat-square); }
.orchicon-message-circle-01:before { content: var(--orchicon-message-circle-01); }
.orchicon-message-notification-circle:before { content: var(--orchicon-message-notification-circle); }
.orchicon-message-plus-circle:before { content: var(--orchicon-message-plus-circle); }
.orchicon-message-square-02:before { content: var(--orchicon-message-square-02); }
.orchicon-message-text-circle-01:before { content: var(--orchicon-message-text-circle-01); }
.orchicon-message-x-circle:before { content: var(--orchicon-message-x-circle); }
.orchicon-minus-circle-lg:before { content: var(--orchicon-minus-circle-lg); }
.orchicon-minus-circle-md:before { content: var(--orchicon-minus-circle-md); }
.orchicon-minus-circle-sm:before { content: var(--orchicon-minus-circle-sm); }
.orchicon-minus-circle-xl:before { content: var(--orchicon-minus-circle-xl); }
.orchicon-minus-circle-xs:before { content: var(--orchicon-minus-circle-xs); }
.orchicon-more-horizontal:before { content: var(--orchicon-more-horizontal); }
.orchicon-mouse-pointer-2:before { content: var(--orchicon-mouse-pointer-2); }
.orchicon-notification-box:before { content: var(--orchicon-notification-box); }
.orchicon-owner:before { content: var(--orchicon-owner); }
.orchicon-package-plus:before { content: var(--orchicon-package-plus); }
.orchicon-pencil-line:before { content: var(--orchicon-pencil-line); }
.orchicon-percent:before { content: var(--orchicon-percent); }
.orchicon-perspective-02:before { content: var(--orchicon-perspective-02); }
.orchicon-play-circle:before { content: var(--orchicon-play-circle); }
.orchicon-play-square:before { content: var(--orchicon-play-square); }
.orchicon-play:before { content: var(--orchicon-play); }
.orchicon-plus-circle:before { content: var(--orchicon-plus-circle); }
.orchicon-plus-square:before { content: var(--orchicon-plus-square); }
.orchicon-plus:before { content: var(--orchicon-plus); }
.orchicon-refresh-ccw-01:before { content: var(--orchicon-refresh-ccw-01); }
.orchicon-refresh-ccw-02:before { content: var(--orchicon-refresh-ccw-02); }
.orchicon-refresh-ccw:before { content: var(--orchicon-refresh-ccw); }
.orchicon-refresh-cs-lg:before { content: var(--orchicon-refresh-cs-lg); }
.orchicon-refresh-cs-md:before { content: var(--orchicon-refresh-cs-md); }
.orchicon-refresh-cs-sm:before { content: var(--orchicon-refresh-cs-sm); }
.orchicon-refresh-cs-xl:before { content: var(--orchicon-refresh-cs-xl); }
.orchicon-refresh-cs-xs:before { content: var(--orchicon-refresh-cs-xs); }
.orchicon-refresh-cw-04:before { content: var(--orchicon-refresh-cw-04); }
.orchicon-refresh-cw-lg:before { content: var(--orchicon-refresh-cw-lg); }
.orchicon-refresh-cw-md:before { content: var(--orchicon-refresh-cw-md); }
.orchicon-refresh-cw-sm:before { content: var(--orchicon-refresh-cw-sm); }
.orchicon-refresh-cw-xl:before { content: var(--orchicon-refresh-cw-xl); }
.orchicon-refresh-cw-xs:before { content: var(--orchicon-refresh-cw-xs); }
.orchicon-repeat-04:before { content: var(--orchicon-repeat-04); }
.orchicon-review-process:before { content: var(--orchicon-review-process); }
.orchicon-rocket-02:before { content: var(--orchicon-rocket-02); }
.orchicon-save-01:before { content: var(--orchicon-save-01); }
.orchicon-save-02:before { content: var(--orchicon-save-02); }
.orchicon-scales-01:before { content: var(--orchicon-scales-01); }
.orchicon-scan:before { content: var(--orchicon-scan); }
.orchicon-search-md:before { content: var(--orchicon-search-md); }
.orchicon-search:before { content: var(--orchicon-search); }
.orchicon-send-01:before { content: var(--orchicon-send-01); }
.orchicon-settings-01:before { content: var(--orchicon-settings-01); }
.orchicon-settings-04:before { content: var(--orchicon-settings-04); }
.orchicon-settings-2:before { content: var(--orchicon-settings-2); }
.orchicon-settings:before { content: var(--orchicon-settings); }
.orchicon-share-06:before { content: var(--orchicon-share-06); }
.orchicon-shield-check:before { content: var(--orchicon-shield-check); }
.orchicon-shield-tick-lg:before { content: var(--orchicon-shield-tick-lg); }
.orchicon-shield-tick-md:before { content: var(--orchicon-shield-tick-md); }
.orchicon-shield-tick-sm:before { content: var(--orchicon-shield-tick-sm); }
.orchicon-shield-tick-xl:before { content: var(--orchicon-shield-tick-xl); }
.orchicon-shield-tick-xs:before { content: var(--orchicon-shield-tick-xs); }
.orchicon-shield-tick:before { content: var(--orchicon-shield-tick); }
.orchicon-single-most-recent:before { content: var(--orchicon-single-most-recent); }
.orchicon-slash:before { content: var(--orchicon-slash); }
.orchicon-sort-desc:before { content: var(--orchicon-sort-desc); }
.orchicon-sprawl:before { content: var(--orchicon-sprawl); }
.orchicon-star-01:before { content: var(--orchicon-star-01); }
.orchicon-star-02:before { content: var(--orchicon-star-02); }
.orchicon-star-05-lg:before { content: var(--orchicon-star-05-lg); }
.orchicon-star-05-md:before { content: var(--orchicon-star-05-md); }
.orchicon-star-05-sm:before { content: var(--orchicon-star-05-sm); }
.orchicon-star-05-xl:before { content: var(--orchicon-star-05-xl); }
.orchicon-star-05-xs:before { content: var(--orchicon-star-05-xs); }
.orchicon-star-05:before { content: var(--orchicon-star-05); }
.orchicon-table:before { content: var(--orchicon-table); }
.orchicon-tag-01:before { content: var(--orchicon-tag-01); }
.orchicon-tag-03:before { content: var(--orchicon-tag-03); }
.orchicon-thumbs-down:before { content: var(--orchicon-thumbs-down); }
.orchicon-thumbs-up-lg:before { content: var(--orchicon-thumbs-up-lg); }
.orchicon-thumbs-up-md:before { content: var(--orchicon-thumbs-up-md); }
.orchicon-thumbs-up-sm:before { content: var(--orchicon-thumbs-up-sm); }
.orchicon-thumbs-up-xl:before { content: var(--orchicon-thumbs-up-xl); }
.orchicon-thumbs-up-xs:before { content: var(--orchicon-thumbs-up-xs); }
.orchicon-thumbs-up:before { content: var(--orchicon-thumbs-up); }
.orchicon-Tick:before { content: var(--orchicon-Tick); }
.orchicon-Tilde:before { content: var(--orchicon-Tilde); }
.orchicon-timer-off:before { content: var(--orchicon-timer-off); }
.orchicon-timer:before { content: var(--orchicon-timer); }
.orchicon-tool-01:before { content: var(--orchicon-tool-01); }
.orchicon-tool-02:before { content: var(--orchicon-tool-02); }
.orchicon-trash-01:before { content: var(--orchicon-trash-01); }
.orchicon-trash-02:before { content: var(--orchicon-trash-02); }
.orchicon-trophy-01:before { content: var(--orchicon-trophy-01); }
.orchicon-underline-01:before { content: var(--orchicon-underline-01); }
.orchicon-unlink:before { content: var(--orchicon-unlink); }
.orchicon-upload-02:before { content: var(--orchicon-upload-02); }
.orchicon-upload-03:before { content: var(--orchicon-upload-03); }
.orchicon-upload-04:before { content: var(--orchicon-upload-04); }
.orchicon-upload-cloud:before { content: var(--orchicon-upload-cloud); }
.orchicon-user-03:before { content: var(--orchicon-user-03); }
.orchicon-user-check-01:before { content: var(--orchicon-user-check-01); }
.orchicon-user-edit:before { content: var(--orchicon-user-edit); }
.orchicon-user-left-01:before { content: var(--orchicon-user-left-01); }
.orchicon-user-plus-01:before { content: var(--orchicon-user-plus-01); }
.orchicon-user-square:before { content: var(--orchicon-user-square); }
.orchicon-user-target:before { content: var(--orchicon-user-target); }
.orchicon-users-01:before { content: var(--orchicon-users-01); }
.orchicon-users-check-lg:before { content: var(--orchicon-users-check-lg); }
.orchicon-users-check-md:before { content: var(--orchicon-users-check-md); }
.orchicon-users-check-sm:before { content: var(--orchicon-users-check-sm); }
.orchicon-users-check-xl:before { content: var(--orchicon-users-check-xl); }
.orchicon-users-check-xs:before { content: var(--orchicon-users-check-xs); }
.orchicon-users-check:before { content: var(--orchicon-users-check); }
.orchicon-users-edit:before { content: var(--orchicon-users-edit); }
.orchicon-users-plus:before { content: var(--orchicon-users-plus); }
.orchicon-visible-in-search:before { content: var(--orchicon-visible-in-search); }
.orchicon-workspace-review:before { content: var(--orchicon-workspace-review); }
.orchicon-x-circle:before { content: var(--orchicon-x-circle); }
.orchicon-x-close-lg:before { content: var(--orchicon-x-close-lg); }
.orchicon-x-close-md:before { content: var(--orchicon-x-close-md); }
.orchicon-x-close-sm:before { content: var(--orchicon-x-close-sm); }
.orchicon-x-close-xl:before { content: var(--orchicon-x-close-xl); }
.orchicon-x-close-xs:before { content: var(--orchicon-x-close-xs); }
.orchicon-x-close:before { content: var(--orchicon-x-close); }
.orchicon-x-lg:before { content: var(--orchicon-x-lg); }
.orchicon-x-md:before { content: var(--orchicon-x-md); }
.orchicon-x-sm:before { content: var(--orchicon-x-sm); }
.orchicon-x-square:before { content: var(--orchicon-x-square); }
.orchicon-x-xl:before { content: var(--orchicon-x-xl); }
.orchicon-x-xs:before { content: var(--orchicon-x-xs); }
.orchicon-x:before { content: var(--orchicon-x); }
.orchicon-zap:before { content: var(--orchicon-zap); }


