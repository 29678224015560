.orchestry-query-context {
   .results {
      white-space: nowrap;
   }
   .query-context-applied-filters-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 4px;
      row-gap: 8px;
      .category-label:not(:first-of-type) {
         margin-left: 12px;
      }
      [data-variant="clearall"] {
         margin-left: 8px;
      }
   }
}
