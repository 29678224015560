.installer-wizard {
   margin: auto;
   max-width: 1500px;
   width: 100%;
   font-weight: 400;
   .orch-logo {
      display: inline-block;
      margin-right: 15px;
      .logo {
         margin: 0 auto;
         width: 40px;
      }
   }

   .dynamic-scrollbar {
      padding-top: 2rem;
      padding-bottom: 2rem;
   }

   .console-wrapper {
      .dynamic-scrollbar {
         padding-top: 0;
         padding-bottom: 0;
      }
   }

   .portlet-alert .alert {
      border-color: transparent !important;
   }
   .kt-portlet__head {
      border-bottom: 1px solid #ebedf2 !important;
      &:after {
         display: none;
      }
      .kt-portlet__head-title {
         line-height: 31px;
      }
   }
   .kt-wizard-v2 {
      .kt-wizard-v2__nav-items {
         .kt-wizard-v2__nav-item {
            padding: 8px 12px !important;
            &::after {
               display: none;
            }
            .kt-wizard-v2__nav-body {
               align-items: center;

               .kt-wizard-v2__nav-icon {
                  width: 18px;
                  line-height: 0;
                  color: #444444;
                  i {
                     font-size: 15px;
                     color: #444444;
                  }
               }
               .kt-wizard-v2__nav-label {
                  line-height: 26px;
                  .kt-wizard-v2__nav-label-title {
                     font-size: 14px !important;
                     font-weight: 500 !important;
                  }
               }
            }
         }
         .kt-wizard-v2__nav-item[data-ktwizard-state="pending"] {
            cursor: default !important;
            .kt-wizard-v2__nav-body {
               cursor: default;
            }
         }
      }
      .kt-wizard-v2__aside {
         padding: 1rem 1rem 1rem 1rem;
         flex: 0 0 240px;
         .kt-wizard-v2__nav-label {
            .kt-wizard-v2__nav-label-desc {
               font-weight: 500;
               color: #b9b9b9;
            }
         }
      }
      .kt-wizard-v2__wrapper .kt-form {
         padding: 2.5rem 2.5rem 2.5rem;
      }
      .kt-wizard-v2__content {
         min-height: 301px;
         border-bottom: none !important;
         .kt-heading {
            margin-top: 0;
         }
      }
      .kt-form {
         width: 100% !important;
         min-height: 600px;
      }
   }
   .installer-step-lifecycle {
      .orchestry-image {
         text-align: right;
         img {
            width: 80% !important;
            margin-top: 30px;
         }
      }
   }

   .installer-step-install {
      .install-complete {
         .consent-granted {
            font-size: 21px;
         }
      }
      p {
         font-size: 14px;
         font-weight: 500;
         color: #718690;
      }
      .btn-success {
         font-size: 14px;
         font-weight: 600;
      }
      .task-title,
      .sub-task-title {
         font-weight: 500;
         margin-left: 7px;
      }
      .cosmos-db-setup {
         padding: 0 1rem 1rem 1rem;
         border: 1px solid #d6d6d6;
         border-radius: 4px;
         margin-bottom: 30px;
         .cosmos-title {
            font-weight: 600;
            margin-bottom: 15px;
            margin-top: 1rem;
         }
         .right {
            padding-left: 10px;
         }
      }
   }

   .installer-step-region {
      .dropdown-wrapper {
         position: relative;
         .waiting-animation {
            position: absolute;
            top: 50%;
            margin-top: -9px;
            right: 40px;
            z-index: 999;
         }
      }
   }
   .installer-step-information {
      .react-tel-input {
         font-family: inherit !important;
         font-size: 13px !important;
         .form-control {
            font-size: 13px !important;
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            max-width: 100%;
         }
         .flag-dropdown {
            border: 1px solid #e1e7ee;
         }
      }
      .versions {
         margin-bottom: 40px;
         .install-item-small {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            margin-top: 20px;
            background-color: #f4f6f9;
            padding: 10px;
            border-radius: 4px;
            .status-icon {
               width: 19px;
               height: 24px;
               border-radius: 4px;
               text-align: center;
               display: flex;
               align-items: center;
               i {
                  font-size: 16;
                  margin: 0 0 0 auto;
                  opacity: 0.7;
               }
            }
            .right {
               padding-left: 15px;
               .version-highlight {
                  background-color: rgb(186, 186, 247);
                  padding: 2px 5px;
                  font-size: 11px;
                  color: white;
                  border-radius: 3px;
                  &.red {
                     background-color: rgb(216, 140, 140);
                  }
                  &.green {
                     background-color: rgb(121, 204, 104);
                  }
               }
               .raquo {
                  font-size: 17px;
                  line-height: 12px;
                  font-weight: 500;
                  opacity: 0.7;
               }
               .title {
                  font-size: 13px;
                  font-weight: 500;
                  color: #646c9a;
                  margin-bottom: 5px;
               }
               .description {
                  font-size: 12px;
                  font-weight: 400;
                  height: 16px;
               }
               .service-pill {
                  font-size: 9px;
                  font-weight: 400;
                  background-color: rgb(169, 169, 169);
                  color: white;
                  padding: 3px 4px 4px 4px;
                  border-radius: 2px;
                  position: relative;
                  top: -1px;
                  margin-left: 5px;
               }
            }
         }
      }
   }

   .installer-step-login-permissions {
      p {
         font-size: 14px;
         font-weight: 500;
         color: #718690;
      }
   }
   .installer-step-confirm {
      .btn-success {
         font-size: 14px;
         font-weight: 500;
      }
      .install-team-message {
         font-size: 14px;
         font-weight: 500;
         color: #718690;
         margin-bottom: 15px;
      }
      .kt-wizard-v2__review-title {
         color: #718690;
         font-size: 16px !important;
      }
      .install-checklist {
         padding-left: 20px;
         font-size: 14px;
         font-weight: 500;
         color: #718690;
         margin-bottom: 15px;
         list-style: none;
         li {
            position: relative;
            margin-bottom: 5px;
         }
         li:before {
            left: -20px;
            content: "";
            font-size: 14px;
            position: absolute;
            z-index: 1;
            top: 5px;
            color: rgb(4, 153, 66);
            transform: rotate(-45deg) scale(0.8);
            border-bottom: solid 3px rgb(4, 153, 66);
            border-left: solid 3px rgb(4, 153, 66);
            background: none;
            height: 8px;
            width: 12px;
         }
      }
      .screen {
         img {
            max-width: 100%;
            border-radius: 5px;
         }
      }
      .install-team-message,
      .screen {
         padding-right: 20px;
      }
   }
   .kt-wizard-v2__wrapper {
      position: relative;
      padding-bottom: 30px;
      .action-wrapper {
         position: absolute;
         bottom: 2.5rem;
         left: 2.5rem;
         right: 2.5rem;
      }
   }
   .consent-granted {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      i {
         font-size: 22px;
         position: relative;
         top: 2px;
         margin-right: 5px;
      }
      p {
         margin-left: 30px;
      }
   }
   .admin-check-loading {
      padding: 25px;
      text-align: center;
   }
   .not-global-admin {
      padding: 25px;
   }
   .subtitle-button,
   .use-orch-button {
      font-size: 14px;
      font-weight: 500;
   }
}
