@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Regular.woff2') format('woff2'), url('TTNorms-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-ExtraBoldItalic.woff2') format('woff2'),
        url('TTNorms-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-MediumItalic.woff2') format('woff2'),
        url('TTNorms-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Heavy.woff2') format('woff2'),
        url('TTNorms-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Thin.woff2') format('woff2'),
        url('TTNorms-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-ExtraLightItalic.woff2') format('woff2'),
        url('TTNorms-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Black.woff2') format('woff2'),
        url('TTNorms-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Bold.woff2') format('woff2'),
        url('TTNorms-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-ExtraBold.woff2') format('woff2'),
        url('TTNorms-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-LightItalic.woff2') format('woff2'),
        url('TTNorms-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Medium.woff2') format('woff2'),
        url('TTNorms-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-HeavyItalic.woff2') format('woff2'),
        url('TTNorms-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-BoldItalic.woff2') format('woff2'),
        url('TTNorms-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Italic.woff2') format('woff2'),
        url('TTNorms-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-ThinItalic.woff2') format('woff2'),
        url('TTNorms-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-ExtraLight.woff2') format('woff2'),
        url('TTNorms-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-Light.woff2') format('woff2'),
        url('TTNorms-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    font-variant-ligatures: no-common-ligatures;
    src: url('TTNorms-BlackItalic.woff2') format('woff2'),
        url('TTNorms-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

