$container-gap: 1.25rem;

.step-card-guest-request-notification-format {
   // > .row {
   //    margin-left: 6%;
   //    margin-right: 6%;
   // }
   .notifications-not-required {
      display: flex;
      flex-direction: column;
      gap: $container-gap;
      .description-text {
         .alert-content {
            margin: auto;
         }
         display: flex;
         align-items: center;
         gap: 1rem;
         p {
            font-size: 14px;
            margin: 0;
         }
         .notification-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            min-width: 64px;
            min-height: 64px;
            background-color: #d7e9ec;
            border-radius: 5px;
            i {
               background-color: white;
               font-size: 32px;
               color: #007989;
               border-radius: 50%;
            }
         }
      }
   }
}
