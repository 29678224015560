
.form-picklist {
   display: flex;
   width:100%;
   margin-bottom: 15px;
   .list-col {
      flex:1;
      
      .title {
         font-size:15px;
         font-weight: 500;
         margin-bottom: 5px;
         color:black;
         // margin-left:15px;
         i {
            margin-right: 10px;
            font-size:13px;
            opacity: 0.6;
         }
         &.shown {
            color:rgb(70, 131, 70);
         }
         &.hidden {
            color:rgb(179, 71, 71);
         }
      }
      .content {
         border: 1px solid #e0e0e0;
         border-radius: 4px;
         padding: 5px;
         background-color: #f9f9f9;
         .pick-item {
            padding: 5px 10px;
            font-weight: 500;
            cursor: pointer;
            border-radius: 4px;
            transition: all 0.3s;
            color:black;
            &:hover {
               background-color: rgb(228, 228, 228);
            }
            &.selected {
               background-color: #3da0a29c;
               color:white;
            }
         }
      }
   }
   .commands {
      width:50px;
      text-align: center;
      margin-top: 30px;
      > div {
         margin: 10px auto;
         width:25px;
         height: 25px; 
         background-color: #8a8abb;
         border-radius: 3px;
         line-height: 25px;
         transition: all 0.3s;
         cursor: pointer;
         i {
            color: white;
         }
         &:hover {
            background-color: #6f6fa3;
         }
         &.disabled {
            cursor: default;
            opacity: 0.3;
            &:hover {
               background-color: #8a8abb;
            }
         }
      }
   }
   .left {

   }
   .right {

   }
}