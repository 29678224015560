@import "app/assets/common/bootstrap-media-mixins";

.table-list {
   //  margin-top: 20px;
   transition: all 0.3s;
   &.no-portlet {
      margin-top: 0;
   }
   .table-list-table {
      @include media-breakpoint-down(sm) {
         display: block;
      }
      thead {
         @include media-breakpoint-down(sm) {
            display: none;
         }

         tr {
            th {
               white-space: nowrap;
               border: 0px solid transparent !important;
               font-size: 14px;
               font-weight: 600;
               vertical-align: top;
               @include media-breakpoint-between(xs, sm) {
                  padding: 6px;
               }
               .info-circle {
                  // top:3px;
                  position: relative;
                  left: 7px;
               }

               &.th-action {
                  width: 150px;
                  text-align: right;
                  @include media-breakpoint-between(xs, sm) {
                     width: 110px;
                  }
               }

               .icon-grey {
                  margin-left: 5px;
               }

               > div {
                  // box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                  padding: 12px 12px 12px 0;
                  font-weight: 500;
                  vertical-align: top;
                  position: relative;
                  cursor: pointer;
                  > span {
                     vertical-align: top;
                     color: #26323a;
                     cursor: pointer;
                  }
                  .sort {
                     opacity: 0.3;
                     margin-left: 8px;
                     vertical-align: middle;
                     position: relative;
                     width: 12px;
                     transition: all 0.3s;
                     /*position: absolute;*/
                     // right:0;
                     /*top: 50%;*/
                     height: 0;
                     display: inline-block;

                     .asc {
                        position: absolute;
                        // top:-18px;
                        top: -12px;
                        line-height: 10px;
                        color: #9da9ae;
                        &:hover {
                           color: rgb(127, 138, 143);
                        }
                     }
                     .desc {
                        position: absolute;
                        // bottom:-18px;
                        bottom: -10px;
                        line-height: 10px;
                        color: #9da9ae;
                        &:hover {
                           color: rgb(127, 138, 143);
                        }
                     }
                     &.current-sort {
                        opacity: 1;
                        .asc,
                        .desc {
                           color: #b8c3c7;

                           &.current-direction {
                              color: rgb(114, 114, 241);
                           }
                        }
                     }
                  }
                  &:hover {
                     .sort {
                        opacity: 1;
                     }
                  }
               }

               &.sort-disabled {
                  > div > span {
                     cursor: default;
                  }
               }

            }
         }
      }
      tbody {
         @include media-breakpoint-down(sm) {
            display: block;
         }
         tr {
            &.loader {
               &:hover {
                  background-color: white !important;
               }
            }

            @include media-breakpoint-down(sm) {
               display: block;
               position: relative;
               min-height: 50px;
               padding-top: 50px;
               border-bottom: 1px solid rgba(0, 0, 0, 0.09);

               &:not(.no-results) {
                  &:after {
                     content: "";
                     font-size: 14px;
                     position: absolute;
                     z-index: 2;
                     top: 25px;
                     margin-top: -6px;
                     right: 1rem;
                     color: #424242;
                     transform: rotate(-45deg) scale(0.8);
                     border-bottom: solid 3px #424242;
                     border-left: solid 3px #424242;
                     background: none;
                     height: 10px;
                     width: 10px;
                     opacity: 0.5;
                     transition: all 0.2s;
                  }
               }

               &.no-results {
                  background-color: white !important;
                  &:hover {
                     background-color: white !important;
                  }
               }
               // &:before {
               //     content: "";
               //     width: 1px;
               //     position: absolute;
               //     top: 62px;
               //     bottom: 20px;
               //     left: 99px;
               //     background-color: #f1f1f1;
               // }
            }
            transition: all 0.3s;
            td {
               // border-top: 2px solid rgba(233, 233, 233, 0.5);
               border-bottom: 0px solid transparent !important;
               font-size: 14px;
               transition: all 0.3s;
               vertical-align: middle;

               &.td-bold {
                  font-weight: 500;
               }

               &.td-type {
                  img {
                     margin-right: 10px;
                  }
               }

               &.td-loader {
                  padding-top: 40px;
                  font-weight: 600;
                  color: grey;
                  .animated-loader {
                     margin-bottom: 10px;
                  }
                  &:hover {
                     background-color: none !important;
                  }
               }

               &.td-bool-icon {
                  font-size:18px;
                  opacity: 0.5;
                  .icon-is-true {
                     i { color: green;}
                  }
                  .icon-is-false {
                     i { color: gray;}
                  }
               }

               &.td-icon-text {
                  padding-left: 45px;
                  position: relative;
                  svg {
                     width: 25px;
                     height: 17px;
                     margin-top: -9px;
                     // margin-right: 10px;
                     position: absolute;
                     top: 50%;
                     left: 6px;
                     opacity: 0.5;
                     @include media-breakpoint-down(sm) {
                        display: inline-block;
                     }
                  }
                  img {
                     position: absolute;
                     top: 50%;
                     left: 0;
                     max-width: 30px;
                     max-height: 30px;
                     display: block;
                     @include media-breakpoint-down(sm) {
                        display: inline-block;
                     }
                  }
                  @include media-breakpoint-down(sm) {
                     // text-align: center;
                  }
               }

               &.td-action {
                  text-align: right;
                  @include media-breakpoint-up(md) {
                     opacity: 0.7;
                     min-width: 90px;
                  }
                  @include media-breakpoint-down(sm) {
                     opacity: 1;
                     padding-bottom: 20px !important;
                     // width:100px;
                  }
                  .delete {
                     // margin-left: 15px;
                     @include media-breakpoint-down(sm) {
                        margin-left: 7px;
                     }
                     svg {
                        // color:#D56664;
                     }
                  }
                  .edit svg {
                     // color: #3D4E70;
                  }

                  svg {
                     @include media-breakpoint-down(sm) {
                        height: 18px;
                        width: 18px;
                        margin: 12px;
                     }
                  }
               }

               &.td-la-icon,
               &.td-doc-icon {
                  svg {
                     width: 22px;
                     height: 17px;
                     margin-right: 10px;
                     position: relative;
                     top: 2px;
                  }
                  .icon-holder {
                  }
                  img {
                     max-width: 22px;
                     max-height: 22px;
                     display: inline;
                     @include media-breakpoint-down(sm) {
                        display: inline-block;
                     }
                  }
                  i {
                     font-size: 20px;
                     opacity: 0.6;
                  }
                  .doc-icon {
                     text-align: center;
                  }
               }

               &.no-results-td {
                  padding-top: 30px;
                  &:hover {
                     background-color: white !important;
                  }
               }

               .sub-value {
                  font-size: 11px;
                  font-weight: 400;
                  color:#212529;
               }
               
               .mobile-title {
                  display: none;
               }

               .title-link {
                  color: #56689a;
               }

               @include media-breakpoint-down(sm) {
                  display: none;
                  border-top: 0px solid transparent;

                  .mobile-title {
                     display: block;
                  }
                  &.td-action,
                  &.is-mobile-title {
                     .mobile-title {
                        display: none;
                     }
                  }
                  &.is-mobile-title {
                     display: block;
                     font-size: 16px;
                     position: absolute;
                     left: 0;
                     top: 0;
                     width: 100%;
                     height: 50px;
                     white-space: nowrap;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     padding-right: 40px;
                     line-height: 34px;
                  }
                  &:not(.is-mobile-title) {
                     // background-color: yellow;
                     position: relative;
                     margin-left: 100px;
                     vertical-align: top;
                     text-align: left !important;
                     // min-height: 50px;
                     padding: 0.75em 0.75rem;
                     > * {
                        vertical-align: top;
                     }
                     .mobile-title {
                        display: inline-block;
                        margin-left: -100px;
                        // padding: .75rem;
                        // text-transform: uppercase;
                        font-size: 12px;
                        opacity: 0.5;
                        font-weight: bold;
                        width: 100px;
                        padding-right: 0.75rem;
                        // float:left;
                     }
                     &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: -90px;
                        right: 10px;
                        height: 1px;
                        background-color: rgba(0, 0, 0, 0.03);
                     }
                     &:last-child {
                        &:after {
                           display: none;
                        }
                     }
                  }

                  &.no-results-td {
                     display: block;
                     border-top: 1px solid rgba(0, 0, 0, 0.082);
                     margin: 0;
                     text-align: center !important;
                  }
               }
            }
            @include media-breakpoint-up(md) {
               &:not(.no-results):not(.loader):hover {
                  // background-color: white;
                  // box-shadow: 0px 20px 30px rgba(0, 69, 117, 0.1);
                  > td {
                     //background-color: rgb(246, 246, 246);
                     // border-top:2px solid transparent;
                     &:first-child {
                        // border-radius: 5px 0 0 5px;
                     }
                     &:last-child {
                        // border-radius: 0 5px 5px 0;
                     }
                  }
                  > .td-action {
                     opacity: 1;
                  }
               }
            }

            @include media-breakpoint-down(sm) {
               &.mobile-open {
                  background-color: white;
                  border-radius: 5px;
                  margin-bottom: 20px;
                  padding-top: 55px;
                  border-bottom: 0px solid transparent;
                  &:after {
                     transform: rotate(-225deg) scale(0.8);
                     margin-top: -4px;
                  }
                  td {
                     display: block;
                     &.is-mobile-title {
                        &:after {
                           content: "";
                           position: absolute;
                           height: 1px;
                           left: 10px;
                           right: 10px;
                           top: 49px;
                           background-color: rgba(0, 0, 0, 0.07);
                        }
                     }
                  }
               }
            }

            &.add-row {
               .add-col-item {
                  .btn {
                     height: 1rem;
                     width: 2rem;
                     justify-content: flex-start;
                  }
                  font-weight: 500;
                  cursor: pointer;
               }
            }
         }
      }
   }
}
.paging {
   .kt-pagination__toolbar {
      select {
         padding-left: 2px !important;
      }
   }
}

.kt-subheader__main {
   .searchBox {
      @include media-breakpoint-down(sm) {
         display: block;
      }
      input {
         margin-left: 15px;
         height: 34px;
         padding: 8px 40px 8px 13px;
         background: #f9f9f9;
         @include media-breakpoint-down(sm) {
            margin-left: 0;
            width: 100%;
         }
      }
      .icon-search {
         left: -32px;
         top: 5px;
         @include media-breakpoint-down(sm) {
            left: auto;
         }
      }
      .icon-delete {
         left: -32px;
         top: 5px;
      }
   }
}
