.home-admin {
   .count-row {
      align-items: center;
      flex-wrap: nowrap;
   }
   .request-list {
      .table-list {
         min-height: 439px;
      }
   }
   .ob-links-list {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
         margin: 0;
         padding: 16px 44px 16px 64px;
         // margin-left: 30px;
         font-size: 14px;
         font-weight: 500;
         color: #007989;
         position: relative;
         // margin-bottom: 12px;
         border-bottom: 1px solid #f2f4f7;
         &:last-child {
            border-bottom: 0px solid #f2f4f7;
            // margin-bottom: 0;
         }
         &:hover {
            color: #016572;
            i.list-type {
               background-color: rgba(19, 167, 157, 0.25);
            }
         }
         a {
            color: #56689a;
         }
         i.list-type {
            position: absolute;
            height: 32px;
            width: 32px;
            left: 16px;
            top: 50%;
            margin-top: -16px;
            line-height: 32px;
            text-align: center;
            font-size: 17px;
            color: #13a79d;
            background: rgba(19, 167, 157, 0.15);
            border-radius: 5px;
            transition: all 0.3s;
         }
         i.arrow {
            position: absolute;
            right: 24px;
            line-height: 12px;
            font-size: 12px;
            color: #a3b4bc;
            top: 50%;
            margin-top: -6px;
         }
      }
   }
}
