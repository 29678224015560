@import "app/assets/common/bootstrap-media-mixins";
.library-template-tabs {
   .nav-tabs.nav-tabs-line .nav-item {
      // margin-bottom: -5px;
      @include media-breakpoint-up(md) {
         margin-right: 25px;
      }
      a {
         i {
            position: relative;
            top: -2px;
            font-size: 14px;
            opacity: 0.5;
         }
         span {
            font-size: 14px;
         }
      }
   }
   .right-col {
      padding-top: 30px;
   }
   .standalone-checkbox {
      background-color: whitesmoke;
      padding: 0.75rem;
      padding-right: 3rem;
      border-radius: 0.25rem;
      display: inline-block;
      font-weight: 600;
      position: relative;
      .form-check {
         label {
            margin-left: 10px;
            color: #676767;
            cursor: pointer;
         }
         label:before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 18px;
            height: 18px;
            // background-color: rgb(66, 223, 149);
            // border:1px solid #28B976;
            background-color: white;
            border: 1px solid #99a1a7;
            border-radius: 0.25rem;
            left: 0;
            top: 1px;
         }
         input:checked + label:before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 18px;
            height: 18px;
            // background-color: #28B976;
            background-color: white;
            border-radius: 0.25rem;
            left: 0;
            top: 1px;
         }
         input:checked + label:after {
            // content: '\2714';
            content: "";
            font-size: 14px;
            position: absolute;
            z-index: 2;
            top: 5px;
            left: 3px;
            color: #99a1a7;
            transform: rotate(-45deg) scale(0.8);
            // border-bottom: solid 3px white;
            // border-left: solid 3px white;
            border-bottom: solid 3px #c2c2c2;
            border-left: solid 3px #c2c2c2;
            background: none;
            height: 8px;
            width: 13px;
         }
      }
      .info-circle {
         position: absolute;
         right: 0.75rem;
         top: 50%;
         margin-top: -9px;
         // top:1rem;
      }
   }
   .configuration-tab,
   .security-tab {
      h2 {
         border-bottom: 1px solid #e5e5e7;
         margin-bottom: 15px;
         padding-bottom: 15px;
         font-size: 22px;
         line-height: 26px;
         letter-spacing: 0.222727px;
         color: #414141;
         font-weight: 600;
         padding-top: 30px;
      }
      .row:first-child {
         h2 {
            padding-top: 0;
         }
      }
      h3 {
         font-size: 18px;
         line-height: 21px;
         letter-spacing: 0.222727px;
         color: #767677;
         font-weight: 600;
         margin-bottom: 15px;
      }
      .tg-right {
         @include media-breakpoint-down(md) {
            h3 {
               margin-top: 30px;
            }
         }
      }
      .form-group {
         margin-bottom: 0.5rem;
         .form-check {
            // display: inline;
         }
      }
      .form-group-input {
         margin-top: 25px;

         @include media-breakpoint-up(md) {
            max-width: 50%;
         }

         input {
            @include media-breakpoint-up(md) {
               width: 50%;
            }
         }
      }
   }

   .living-librarytemplate-container {
      margin-top: 20px;
   }

   #librarytemplate-new-tabs-tabpane-security {
      .form-group {
         .form-check {
            display: inline-block;
         }
      }
   }
   .kt-portlet {
      .kt-portlet__body {
         @include media-breakpoint-up(md) {
            padding-top: 3px;
         }
         .tab-content {
            > div {
               .button-custom {
                  margin-top: 20px;
               }
            }
         }
      }
   }
}
