.recommendation-priority-metric-skeleton {
   display: flex !important;
   align-items: center;
   gap: 24px;
   flex-direction: row !important;
   border: 1px var(--border) solid !important;

   .left-section {
      display: flex;
      flex-direction: column;
      align-items: right;

      .circles {
         position: relative;

         .white-circle {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 36.17px;
            height: 36.17px;
            transform: translate(-56%, -19%);
            border-radius: 50%;
            background-color: var(--white);
            margin: "auto";
         }
      }
   }
   .right-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      .group {
         display: flex;
         gap: 8px;
         align-items: center;
      }
   }
}
