.directory-add-exists {
   display: inline;
   .add-existing-button {
      display: inline;
   }
}

.directory-add-existing-modal {
   .modal-dialog {
      min-width: 600px;
      .modal-header {
         border: none !important;
         padding: 2rem 2.5rem 0.75rem 2.5rem;
         .modal-title {
            font-size: 1.2em !important;
            font-weight: 600 !important;
         }
         .close {
            padding: 0rem 0.75rem 1.25rem 1.25rem;
         }
      }
      .top-section {
         padding: 0.5rem 2.5rem 1.65rem 2.5rem;
         border-bottom: 1px solid #e1e7ee;
         // margin-bottom: 10px;
         .search {
            width: 100%;
            .search-box {
               background: #ffffff;
               border: 1px solid #e1e7ee;
               box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
               border-radius: 5px;
               padding: 8px 12px;
               position: relative;
               display: flex;
               align-items: center;
               height: 40px;
               overflow: hidden;
               input {
                  border: none;
                  width: 100%;
                  outline: none;
                  font-weight: 500;
               }
               .loading {
                  padding: 0 10px;
               }
               i {
                  color: #9da9ae;
                  // position: absolute;
                  // top:50%;
                  // right:6px;
                  // margin-top: -10px;
                  font-size: 22px;
                  -moz-transform: scale(-1, 1);
                  -webkit-transform: scale(-1, 1);
                  -o-transform: scale(-1, 1);
                  -ms-transform: scale(-1, 1);
                  transform: scale(-1, 1);
               }
            }
         }
      }
      .bottom-section {
         padding: 0.5rem 2.5rem 1.65rem 2.5rem;
         .suggest-title {
            font-weight: 500;
            font-size: 12px;
            color: #718690;
         }
         .item-results {
            .item {
               display: flex;
               width: 100%;
               padding-top: 10px;
               padding-bottom: 10px;
               align-items: center;
               border-bottom: 1px solid rgb(238, 238, 238);
               &.already-added {
                  // opacity: 0.7;
               }
               > * {
                  padding-right: 12px;
                  &:last-child {
                     padding-right: 0;
                  }
               }
               .profile-icon {
                  .profile-image {
                     background: #ffffff;
                     border: 1.11111px solid #e1e7ee;
                     width: 40px;
                     height: 40px;
                     background-size: cover;
                     background-position: center center;
                     border-radius: 50%;
                  }
               }
               .title {
                  padding-right: 20px;
                  flex: 1;
                  .name {
                     font-style: normal;
                     font-weight: bold;
                     font-size: 14px;
                     line-height: 20px;
                     color: #26323a;
                  }
                  .url {
                     font-style: italic;
                     font-weight: 500;
                     font-size: 12px;
                     line-height: 20px;
                     color: #718690;
                  }
               }
               .description {
                  width: 175px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: #718690;
               }
               .type {
                  img {
                     width: 22px;
                  }
               }
               .actions {
                  .check {
                     height: 27px;
                     width: 27px;
                     border-radius: 50%;
                     // border:2px solid rgb(64, 211, 113);
                     background-color: white;
                     color: rgb(64, 211, 113);
                     text-align: center;
                     line-height: 26px;
                  }
                  .add {
                     height: 27px;
                     width: 27px;
                     border-radius: 50%;
                     text-align: center;
                     line-height: 28px;
                     background-color: rgb(241, 241, 241);
                     cursor: pointer;
                     transition: all 0.3s;
                     &:hover {
                        background-color: rgb(230, 230, 230);
                     }
                  }
               }
            }
         }
      }
      .no-results {
         margin: 30px auto 10px auto;
         max-width: 300px;
         .icon {
            zoom: 0.7;
         }
      }
   }
}

.directory-add-modal-backdrop {
   z-index: 1060 !important;
}
.directory-add-form-modal {
   z-index: 1070 !important;
   .ScrollbarsCustom .ScrollbarsCustom-TrackY {
      right: -6px !important;
   }
   .content {
      .logo {
         text-align: center;
         .profile-picture {
            margin: 0 auto;
         }
      }
      .form-label {
         font-weight: 500;
      }
   }
   .modal-footer {
      .buttons {
         display: flex;
      }
      .save {
         background: #007989;
         border-radius: 4px;
         color: white;
         font-size: 12px;
         font-weight: 500;
         padding: 10px 20px;
         border: 0px solid transparent;
         cursor: pointer;
         i {
            margin-right: 10px;
         }
         &.no-valid {
            cursor: default;
            background: #dadada;
            border-radius: 4px;
            color: rgb(82, 82, 82);
            font-size: 12px;
         }
      }
      .cancel {
         background: #ffffff;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         font-family: TT Norms;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 16px;
         padding: 10px 16px;
         position: relative;
         height: 38px;
         margin-left: 8px;
         transition: all 0.3s;
         margin-right: 10px;
         cursor: pointer;
      }
   }
}

.Toastify {
   z-index: 2000;
}
