.orchestry-person-pill {
   display: flex;
   gap: 12px;
   align-items: center;
   cursor: default;
   border-radius: 8px;
   border: 1px solid var(--Border, #e5e7eb);
   padding: 6px 8px;

   .label {
      color: var(--mantine-color-text-tertiary-0);
      line-height: 1;
      font-family: Lexend;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
   }

   .person {
      margin-right: 10px;
      border-radius: 200px;
      width: 20px;
      height: 20px;
   }
}
