.orchestry_data_table__paper {
   background-color: var(--mantine-color-white);
   color: var(--mantine-color-text-primary-0);
   font-size: 12px;
   font-weight: 300;
   line-height: 18px;
   border: 1px solid var(--border);
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
   box-shadow: none;
   --mrt-row-hover-background-color: var(--mantine-color-gray-0);
}

// .orchestry_data_table__table_container {
//    max-height: 650px;
// }

.orchestry_data_table__table_container.orchestry_data_table__table_container_no_rows {
   height: 250px;
}

.orchestry_data_table__table_container::-webkit-scrollbar {
   background-color: transparent;
   width: 8px;
   height: 8px;
}

.orchestry_data_table__table_container::-webkit-scrollbar-thumb {
   background: var(--mantine-color-gray-3);
   border-radius: 10px;
}

.orchestry_data_table__table_head_cell {
   border-bottom: 1px solid var(--border);
   background-color: var(--mantine-color-gray-0);
   color: var(--mantine-color-text-primary-0);
   font-size: 14px;
   font-weight: 500;
   line-height: 18px;
   padding: 8px 16px;
   height: 39px;

   button {
      &:hover {
         background-color: var(--mantine-color-gray-2);
      }
   }
}
:global .mrt-table-head-multi-sort-indicator {
   padding-right: 4px;
   .mantine-Indicator-indicator {
      color: var(--mantine-color-gray-5);
   }
}

:global .mrt-table-head-sort-button {
   font-weight: 900;
}

.orchestry_data_table__table_body_row {
   border-bottom: 1px solid var(--border);
}

.orchestry_data_table__table_body_cell {
   overflow: visible !important;
}
