@import "_metronic/_assets/sass/breakpoints";

.directory-favorites {
   .custom-button {
      background: #ffffff;
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      font-family: TT Norms;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding: 10px 16px;
      position: relative;
      height: 38px;
      margin-left: 8px;
      transition: all 0.3s;
      &:hover {
         background: #f6f6f6;
      }
   }
   .fav-title-area {
      display: flex;
      margin: 20px 0;
      transition: all 0.3s;
      @include kt-mobile {
         position: fixed;
         top: 0;
         right: 0;
         z-index: 1999;
         align-items: center;
         height: 72px;
      }
      @include kt-mobile {
         justify-content: flex-end;
         margin: 0;
      }
      h3 {
         flex: 1;
         font-size: 24px;
         display: inline-block;
         word-spacing: nowrap;
         line-height: 38px;
         margin-bottom: 0;

         @include kt-mobile {
            height: 0;
            overflow: hidden;
            color: white;
            line-height: 72px;
            padding-left: 20px;
         }
      }
      .custom-button {
         cursor: pointer;
         @include kt-mobile {
            display: none;
         }
      }
      .fav-mobile-button {
         display: none;
         font-size: 30px;
         text-align: right;
         padding: 10px 20px;
         // background-color: blue;
         position: relative;
         transition: all 0.3s;
         cursor: pointer;

         @include kt-mobile {
            display: block;
            // height: 72px;
         }

         i.la-star {
            position: absolute;
            top: 16px;
            right: 8px;
            z-index: 4;
            color: #fab32f;
            transition: all 0.3s;
            &.star-shadow {
               color: #f2f4f7;
               z-index: 3;
               font-size: 41px;
               top: 10px;
               right: 3px;
            }
         }
         i.fa-bars {
            opacity: 0.3;
            transition: all 0.3s;
         }
      }
      @include kt-mobile {
         &.mobile-open {
            left: 0;
            height: 72px;
            background-color: #188381;

            h3 {
               height: 72px;
            }

            .fav-mobile-button {
               i.la-star {
                  color: #fab32f;
                  &.star-shadow {
                     color: #188381;
                  }
               }

               i.fa-bars {
                  color: white;
               }
            }

            &.fav-only-title-area {
               display: none;
            }
         }
      }
   }
   .fav-wrapper {
      background: #f2f4f7;
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      transition: all 0.3s;
      @include kt-mobile {
         position: fixed;
         top: 72px;
         right: 0;
         bottom: 100%;
         overflow: hidden;
         z-index: 1000;
         display: block;
         background-color: white;
         border: 0px solid transparent;
         width: 100%;
         border-radius: 0;
         box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
      }
      .fav-wrapper-content {
         padding: 16px;
      }

      &.fav-only-wrapper {
         background: #ffffff;
         border: none;

         .fav-wrapper-content {
            padding: 0;
         }
      }

      .item-drag-wrapper {
         padding: 5px 0;
      }
      .fav-item {
         background: #ffffff;
         border: 1px solid #dde2e7;
         box-shadow: inset 0px -2px 2px rgba(221, 226, 231, 0.05);
         border-radius: 4px;
         display: flex;
         margin-bottom: 8px;
         position: relative;
         &:last-child {
            margin-bottom: 0;
         }
         .fav-content {
            padding: 18px 18px;
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            .fav-icon {
               // .profile-image {
               //    height: 40px;
               //    width: 40px;
               //    background: #FFFFFF;
               //    background-size: cover;
               //    border-radius: 50%;
               //    background-position: center;
               //    border: 1.11111px solid #DBDBDB;
               // }
               .profile-picture {
                  background: #ffffff;
                  border: 1px solid #e1e7ee;
                  width: 40px;
                  height: 40px;
                  box-sizing: content-box;
                  background-size: cover;
                  background-position: center center;
                  border-radius: 4px;
                  line-height: 44px;
                  .img {
                     top: 0;
                     left: 0;
                     width: 100%;
                     height: 100%;
                  }
               }
            }
            .fav-details {
               padding-left: 15px;
               padding-right: 15px;
               flex: 1;
               .fav-title {
                  font-family: TT Norms;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 20px;
                  color: #26323a;
                  margin-bottom: 4px;
               }
               .fav-type {
                  color: #718690;
                  font-size: 12px;
                  font-weight: 500;
                  margin-top: 3px;
                  // img {
                  //    width:20px;
                  //    margin-right: 8px;
                  // }

                  display: flex;
                  align-items: center;
                  .cat-icon {
                     margin-right: 5px;
                     width: 16px;
                     min-width: 16px;
                     img {
                        width: 16px;
                        max-width: 100%;
                     }
                  }
                  .cat-title {
                     font-size: 12px;
                     line-height: 15px;
                  }
               }
            }
            .fav-menu {
               // width:36px;
               // position: relative;
               .dots-button {
                  // because dots are literally 3 dots turned sideways, they overflow the page and cause a horizontal scroll in a webpart.
                  // This is a fix for that.
                  overflow: hidden;

                  width: 24px;
                  height: 22px;
                  position: absolute;
                  top: 50%;
                  margin-top: -12px;
                  right: 2px;
                  line-height: 0px;
                  text-align: center;
                  cursor: pointer;
                  transform: rotate(90deg);
                  span {
                     font-size: 36px;
                  }
               }

               .fav-menu-dd {
                  position: absolute;
                  z-index: 99;
                  right: 0;
                  top: 70%;
                  background-color: white;
                  padding: 3px;
                  background: #ffffff;
                  box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
                  border-radius: 4px;
                  font-weight: 500;
                  opacity: 0;
                  overflow: hidden;
                  max-height: 0;
                  transition: all 0.3s;
                  .fav-menu-item {
                     padding: 6px 10px;
                     border-radius: 3px;
                     background-color: white;
                     transition: all 0.3s;
                     cursor: pointer;
                     &:hover {
                        background-color: rgb(241, 241, 241);
                     }
                     i {
                        margin-right: 10px;
                        width: 13px;
                        text-align: center;
                     }
                  }
               }

               &.menu-open {
                  .fav-menu-dd {
                     max-height: 130px;
                     opacity: 1;
                  }
               }
            }
         }

         .fav-drag-and-drop {
            width: 40px;
            border-right: 1px solid #dde2e7;
            position: relative;
            cursor: grab;
            // &:hover {
            //    background-color: rgb(238, 238, 238);
            // }
            .grip {
               top: 50%;
               margin-top: -8px;
               left: 50%;
               margin-left: -5px;
               // background-color: yellow;
               position: absolute;
               height: 17px;
               width: 10px;
               line-height: 3px;
               overflow: hidden;
               .grip-dot {
                  width: 3px;
                  height: 3px;
                  background: #9da9ae;
                  border-radius: 50%;
                  display: block;
                  position: absolute;
                  line-height: 3px;
                  &:nth-child(1) {
                     top: 0;
                     left: 0;
                  }
                  &:nth-child(2) {
                     top: 0;
                     right: 0;
                  }
                  &:nth-child(3) {
                     top: calc(50% - 1.5px);
                     left: 0;
                  }
                  &:nth-child(4) {
                     top: calc(50% - 1.5px);
                     right: 0;
                  }
                  &:nth-child(5) {
                     bottom: 0;
                     left: 0;
                  }
                  &:nth-child(6) {
                     bottom: 0;
                     right: 0;
                  }
               }
            }
         }
      }
      .no-results {
         .no-results-panel {
            .icon {
               zoom: 0.7;
               background-color: white;
               i {
                  color: rgb(199, 199, 199);
               }
            }
         }
      }
      @include kt-mobile {
         &.mobile-open {
            bottom: 0;
            overflow-y: auto;

            &.fav-only-wrapper {
               top: 0;

               .fav-wrapper-content {
                  padding: 0;
               }
            }
         }
      }
   }
   @include kt-mobile {
      &.fixed {
         .fav-mobile-button {
            margin-top: -12px;
            i.la-star {
               color: #fab32f;
               &.star-shadow {
                  color: #188381;
               }
            }
            i.fa-bars {
               color: white;
            }
         }
      }
   }
   .loading-skeleton {
      .items {
         .item {
            display: flex;
            padding: 15px 20px 15px 20px;
            border-radius: 5px;
            width: 100%;
            align-items: center;
            // border-bottom: 1px solid #EEF1F5;
            margin-bottom: 10px;
            background-color: #ffffffd4;
            > div {
               // padding: 0 0px 0 20px;
            }
            .pic {
               .circle {
                  height: 42px;
                  width: 42px;
                  border-radius: 50%;
                  background: rgba(238, 241, 245, 0.75);
               }
            }
            .title {
               margin-left: 15px;
               // width:300px;
               flex: 1;
               .title-box {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 21px;
               }
            }
            .property {
               flex: 1;
               .property-box {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 21px;
               }
            }
         }
      }
      .animated-wave {
         overflow: hidden;
         position: relative;
         &:before {
            content: "";
            display: block;
            position: absolute;
            left: -150px;
            top: 0;
            height: 100%;
            width: 150px;
            background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
            animation: load 2s cubic-bezier(0.4, 0, 0.2, 1);
            animation-iteration-count: infinite;
         }
         @keyframes load {
            from {
               left: -150px;
            }
            to {
               left: 100%;
            }
         }
      }
   }
}

.directory-favorites {
   .modal-dialog {
      min-width: 400px;
      .modal-header {
         border: none !important;
         padding: 2rem 1.25rem 0.75rem 1.25rem;
         .modal-title {
            font-size: 1.2em !important;
            font-weight: 600 !important;
         }
         .close {
            padding: 0rem 0.75rem 1.25rem 1.25rem;
         }
      }
      .modal-footer {
         border: none !important;
         padding: 0.75rem 1.25rem 2rem 1.25rem;
         .btn-secondary {
            background: #ffffff;
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            font-weight: 500;
         }
         .btn-primary {
            background: #007989;
            border-radius: 4px;
            font-weight: 500;
            border: 1px solid #007989 !important;
            &:active {
               background: #00606d;
            }
         }
      }
   }
}
