.root {
   &[data-variant="default"],
   &[data-variant="default-searchbox"] {
      .wrapper {
         width: 320px;
      }

      .input {
         color: var(--mantine-color-text-primary-0);
         height: 40px;
         box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
         border-radius: 8px;
         border: 1px #e5e7eb solid;
         font-weight: 400;
         font-size: 14px;
      }
   }

   &[data-variant="default-searchbox"] {
      .section {
         font-size: 16px;
         cursor: pointer;
         margin-right: 5px;
      }
   }
}
