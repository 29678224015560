.insights-provisioned {
   .loading-wrapper {
      text-align: center;
   }
   .counts {
      .ob-vertical-stat-count-large {
         margin-bottom: 24px;
         .count {
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 56px;
            letter-spacing: -2px;
            color: #26323a;
            margin-bottom: 4px;
         }
         .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #718690;
         }
      }
      .ob-vertical-stat-count {
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         display: flex;
         align-items: center;
         padding: 16px 24px 16px 20px;
         margin-bottom: 8px;
         > div:last-child {
            margin-left: auto;
         }
         &:last-child {
            margin-bottom: 0;
         }
         .logo {
            margin-right: 16px;
            img {
               width: 23px;
               height: 22px;
            }
         }
         .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            font-feature-settings: "liga" off;
            color: #718690;
         }
         .count {
            font-weight: bold;
            font-size: 24px;
            line-height: 20px;
            letter-spacing: -2px;
            font-feature-settings: "liga" off;
            color: #26323a;
         }
      }
   }
}
