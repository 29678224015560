.form-validation {
   .required-astrix {
      color: red;
      font-size: 17px;
      line-height: 15px;
   }
   .validation-errors {
      padding-top: 10px;
      display: inline-block;
      .validation-box {
         padding: 10px 15px;
         border: 1px solid #ff8080;
         background-color: #fff4f4;
         border-radius: 3px;
         position: relative;
         box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
         .arrow {
            position: absolute;
            left: 10%;
            top: -6px;
            border-left: 1px solid #ff8080;
            border-top: 1px solid #ff8080;
            transform: rotate(45deg);
            width: 10px;
            height: 10px;
            background-color: #fff4f4;
         }
         .items {
            .error-item {
               color: #af0909;
               font-weight: 400;
               padding: 5px 0 0px 0;
               &:first-child {
                  padding-top: 0;
               }
            }
         }
      }
   }
   .info {
      .validation-errors {
         .validation-box {
            border: 1px solid #dbf126;
            background-color: #fefff4;
            .arrow {
               border-top: 1px solid #dbf126;
               border-left: 1px solid #dbf126;
               background-color: #fefff4;
            }
            .items {
               .error-item {
                  color: #afa409;
               }
            }
         }
      }
   }
   .hide-validator {
      display: none;
   }
}

.form-validator-rollup {
   display: inline-block;
   background-color: rgb(255, 227, 151);
   padding: 2px 7px;
   color: rgb(148, 125, 63);
   box-shadow: 0px 0px 0px 1px rgba(226, 192, 100, 0.638);
   border-radius: 5px;
   margin-left: 10px;
   position: relative;
   font-weight: 500;
   line-height: 14px;
   i {
      color: rgb(163, 139, 73);
      opacity: 1;
      font-size: 10px;
      margin-right: 4px;
   }
   span {
      font-weight: 600;
   }
   .errors {
      position: absolute;
      top: calc(100% + 5px);
      background-color: white;
      z-index: 9;
      width: 250px;
      padding: 15px 15px 15px 30px;
      box-shadow: 0 2px 2px #00000012;
      border-radius: 4px;
      border: 1px solid #b6b6b6;
      left: -15px;
      display: none;
      margin-bottom: 0;
      font-weight: 500;
      .error {
         font-weight: 500;
      }
   }
   &:hover {
      .errors {
         display: block;
      }
   }

   &.top {
      .errors {
         top: auto;
         bottom: calc(100% + 5px);
      }
   }
}
