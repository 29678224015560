.portlet-alert {
   background-color: white;
   border-radius: 4px;

   .alert {
      .alert-content {
         .alert-title {
            display: block;
            font-size: 15px;
            font-weight: 600;
         }
         .alert-text {
            font-size: 14px;
         }
      }
      .alert-icon {
         // align-items:flex-start;
         padding: 0 1.75rem 0 0;
         &.top {
            align-items: flex-start;
         }
      }
   }
   &.type-warning {
      .alert {
         border-color: #ffb822;
         .alert-title,
         .alert-text {
            color: #da9a12;
         }
      }
   }
   &.type-success {
      .alert {
         border-color: #0abb87;
      }
   }
   &.type-danger {
      .alert {
         border-color: #fd397a;
      }
   }
   &.size-small {
      .alert {
         padding: 0.5rem 0.75rem;
         .alert-icon {
            padding: 0 0.75rem 0 0;
            i {
               font-size: 14px;
            }
         }
         .alert-content {
            .alert-title {
               font-size: 13px;
            }
            .alert-text {
               font-size: 13px;
            }
         }
      }
   }
   &.size-xsmall {
      .alert {
         padding: 0.5rem 0.75rem;
         align-items: center;
         margin: 0 0 10px 0;
         .alert-icon {
            padding: 0 0.75rem 0 0;
            i {
               font-size: 14px;
            }
         }
         .alert-content {
            .alert-title {
               font-size: 11px;
            }
            .alert-text {
               font-size: 11px;
            }
         }
      }
   }
   &.no-outline {
      .alert {
         border: none;
      }
   }
}
