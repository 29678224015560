.step-card-actions {
   // margin-top: -20px;
   .action-row {
      // padding-left: 6%;
      // padding-right: 6%;
      display: flex;
      padding: 0 !important;

      .left-nav {
         // padding-right: 30px;
         background-color: #f2f4f7;
         padding: 16px;
         border-radius: 5px 0 0 5px;
         border-right: 1px solid #e1e7ee;
         .nav-item {
            background-color: white;
            border: 1px solid #e1e7ee;
            border-radius: 5px;
            display: flex;
            padding: 6px 12px;
            align-items: center;
            margin-bottom: 8px;
            width: 220px;
            transition: all 0.3s;
            position: relative;
            &:last-child {
               margin-bottom: 0;
            }
            .icon {
               width: 28px;
               img {
                  width: 18px;
               }
               i {
                  font-size: 15px;
                  color: #718690;
               }
            }
            .text {
               font-size: 12px;
               color: #718690;
               font-weight: 500;
               transition: all 0.3s;
               flex: 1;
            }
            &:hover {
               background-color: #00798915;
               cursor: pointer;
            }
            &.selected {
               background-color: #007989;
               .text {
                  color: white;
               }
            }
            .form-validator-rollup {
            }
         }
      }
      .right-content-area {
         padding: 40px 40px;
         flex-grow: 1;
         .form-office-asset-picker {
            margin-top: 25px;
         }
      }
   }
   .action-select {
      margin-bottom: 30px;
   }
   .property-row {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 15px;
      .left-side {
         width: 80px;

         // margin-left: 12px;
         color: #414141;
         cursor: pointer;
         font-size: 14px;
         font-weight: 500;
      }
      > div:last-child {
         flex: 1;
      }
      &.permissions {
         margin-left: 28px;
      }
   }
   .naming-policy-selector {
      padding-bottom: 30px;
      margin-left: 28px;
   }

   .user-picker {
      .validator-collapse {
         position: relative;
         top: -25px;
      }
   }

   // .form-orch-toggle {
   //    > label {
   //       padding-left: calc(1rem + 43px) !important;
   //       // margin-left: 0 !important;
   //       > div {
   //          zoom: 0.8;
   //       }
   //    }
   //    .form-control.form-toggle-control {
   //       span {
   //          font-weight: 500;
   //       }
   //    }
   // }

   .naming-preview-format {
      margin-top: 10px;
   }

   .webhook-tab {
      .sub-title {
         // margin-left: 12px;
         color: #414141;
         cursor: pointer;
         font-size: 14px;
         font-weight: 500;
      }
      button {
         border: 0px solid transparent;
         background-color: #0abb87;
         border-color: #0abb87;
         color: white;
         font-weight: 500;
         border-radius: 4px;
         padding: 4px 10px;
         margin-bottom: 5px;
         margin-top: 10px;
         &.clipboard-button {
            i {
               margin-right: 5px;
            }
         }
      }
      .webhook-example {
         background-color: #f5f5f5;
         padding: 10px;
         border-radius: 4px;
         border: 1px solid #e2e2e2;
         code {
            background-color: #f5f5f5;
            .key {
               color: rgb(65, 65, 160);
            }
         }
      }
      .url-input {
         button {
            display: flex;
            justify-content: center;
            i {
               margin-right: 6px;
               font-size: 16px;
               position: relative;
               top: 1px;
            }
            .animated-loader {
               margin-right: 6px;
               height: 16px;
               width: 16px;
            }
         }
      }
      .webhook-area {
         margin-bottom: 35px;
      }

      .client-secret-area {
         margin-bottom: 25px;
         .sub-title {
            margin-bottom: 15px;
         }
         .form-group {
            margin-bottom: 0;
         }
         .client-secret-enabled {
            p {
               font-weight: 400;
            }
         }
      }
   }
}
