@import "app/assets/common/bootstrap-media-mixins";
@import "_metronic/_assets/sass/breakpoints";

.request-wizard {
   display: flex;
   font-family: "TT Norms";
   font-variant-ligatures: none;
   background-color: white;
   border-radius: 10px;
   padding: 25px 0 0 25px;

   &.request-wizard-sharepoint {
      padding: 0;
   }
   &.add-padding {
      padding: 25px;
   }

   @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-top: 15px;
   }

   input,
   textarea,
   select,
   option {
      font-variant-ligatures: none !important;
   }
   .left-side {
      flex: 0 200px;
      padding-bottom: 40px;
      @include media-breakpoint-down(sm) {
         display: none;
      }
      .progress-navigation {
         @include media-breakpoint-down(sm) {
         }
         > ul {
            > li {
               @include kt-media-above(md) {
               }
               @include kt-media-below(md) {
               }
               .wrapper {
                  border-left: 2px solid transparent;
                  padding: 10px 25px 10px 60px;
                  position: relative;
                  transition: all 0.3s;
                  &:before {
                     content: "";
                     position: absolute;
                     left: 30px;
                     width: 1px;
                     background-color: #ebedf1;
                     top: 0;
                     bottom: 0;
                     transition: all 0.3s;
                  }
                  .circle {
                     position: absolute;
                     width: 10px;
                     height: 10px;
                     left: 26px;
                     top: 50%;
                     margin-top: -5px;
                     background: #ffffff;
                     border: 2px solid #ebedf1;
                     border-radius: 50%;
                     transition: all 0.3s;
                  }
                  .check {
                     opacity: 0;
                     transition: all 0.3s;
                     position: absolute;
                     width: 18px;
                     height: 18px;
                     left: 22px;
                     top: 50%;
                     margin-top: -10px;
                     background: #007989;
                     border-radius: 50%;
                     text-align: center;
                     line-height: 14px;
                     svg {
                        color: white;
                        width: 8px;
                        height: 8px;
                        position: relative;
                        top: 1px;
                     }
                  }
                  .title {
                     color: rgba(38, 50, 58, 0.65);
                     font-weight: 600;
                     font-size: 12px;
                     transition: all 0.3s;
                     position: relative;
                     display: inline-block;
                     vertical-align: top;
                     &:before {
                        content: "";
                        position: absolute;
                        z-index: 2;
                        height: 2px;
                        background-color: rgba(128, 128, 128, 0.411);
                        left: 0;
                        right: 0;
                        bottom: 0px;
                        opacity: 0;
                        transition: all 0.3s;
                     }
                  }

                  &:hover {
                     .title {
                     }
                     .circle {
                     }
                     .check {
                     }
                  }
               }

               &:after {
                  @include media-breakpoint-up(md) {
                  }
                  @include media-breakpoint-down(sm) {
                  }
               }
               &:first-child {
                  .wrapper {
                     &:before {
                        top: 50%;
                     }
                  }
               }
               &:last-child {
                  .wrapper {
                     &:before {
                        bottom: 50%;
                     }
                  }
               }

               &.done {
                  .wrapper {
                     .circle {
                     }
                     .check {
                        opacity: 1;
                     }
                     &:hover {
                        .title {
                        }
                        .circle {
                        }
                        .check {
                        }
                        .check {
                           svg {
                           }
                        }
                     }
                  }
               }

               &.current {
                  .wrapper {
                     border-left: 2px solid #007989;
                     background: linear-gradient(90deg, rgba(0, 121, 137, 0.1) 0%, rgba(0, 121, 137, 0) 100%);
                     .circle {
                        border-color: #007989;
                     }
                     .title {
                        color: #26323a;
                     }
                     &:hover {
                        .title {
                        }
                        .circle {
                        }
                        .check {
                        }
                     }
                  }
               }

               &.can-progress:not(.current) {
                  .wrapper {
                     cursor: pointer;
                     &:hover {
                        .title {
                           &:before {
                              opacity: 1;
                           }
                        }
                        .circle {
                        }
                        .check {
                        }
                     }
                  }
               }
            }
         }
      }
      .get-help {
         background: linear-gradient(90deg, rgba(0, 121, 136, 0.1) 0%, rgba(0, 121, 136, 0) 100%);
         border-radius: 8px;
         padding: 12px 40px 12px 20px;
         margin-top: 300px;
         .text {
            color: #007988;
            font-weight: 400;
         }
         .link {
            margin-top: 15px;
            color: #007988;
            font-weight: 400;
            text-decoration: underline;
         }
      }
      .your-workspace {
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 8px;
         padding: 6px;
         margin-top: 40px;
         // display: flex;
         align-items: center;

         .selected-workspace-thumb {
            // background: #007989;
            // box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
            background: rgba(238, 241, 245, 0.75);
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 8px;
            // display: flex;
            // flex:0 250px;
            align-content: flex-start;
            padding-bottom: 1px;
            .title {
               // flex:1;
               align-self: center;
               font-size: 13px;
               font-weight: 500;
               // color: #ffffff;
               color: #26323a;
               padding: 5px 10px 0 10px;
               // @include media-breakpoint-down(sm) {
               //     font-size:21px;
               // }
            }
            .preview-image {
               // flex:0 128px;
               margin: 6px;
               width: calc(100% - 12px);
               height: 75px;
               border: 0px solid #b5b5b5;
               //box-shadow: 0px 5px 6px rgba(88, 88, 88, 0.38);
               border-radius: 7px;
               //margin-bottom: 15px;
               position: relative;
               background-color: white;
               // @include media-breakpoint-down(sm) {
               //     height: 110px;
               // }
               .missing-image {
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  opacity: 0.3;
                  top: 50%;
                  margin-top: -20px;
                  left: 50%;
                  margin-left: -20px;
                  svg {
                     width: 40px;
                     height: 40px;
                  }
               }
               > .image {
                  height: 100%;
                  width: 100%;
                  background-size: cover;
                  background-position: center;
                  border-radius: 7px;
               }
               .type {
                  position: absolute;
                  background-color: white;
                  bottom: 5px;
                  padding: 2px 3px;
                  border-radius: 4px;
                  left: 5px;
                  display: flex;
                  .icon {
                     margin-top: -0.5px;
                     img {
                        width: 18px;
                     }
                  }
                  .text-wrapper {
                     .text {
                        font-size: 11px;
                        font-weight: 600;
                        line-height: 19px;
                        padding-left: 5px;
                        padding-right: 5px;
                        white-space: nowrap;
                     }
                     overflow: hidden;
                     max-width: 0;
                     transition: all 0.3s;
                  }
                  &:hover {
                     .text-wrapper {
                        max-width: 100%;
                     }
                  }
               }
            }
         }
         .workspace-title {
            // flex:1;
            padding-left: 0px;
            h3 {
               font-size: 14px;
               font-weight: 600;
               color: #414141;
               padding: 0 5px;
               margin-bottom: 0px;
               margin-top: 5px;
            }
            .description {
               font-size: 12px;
               // line-height: 19px;
               letter-spacing: 0.222727px;
               // color: #414141;
               color: #696969;
               font-weight: 500;
               padding: 0 5px;
               .editor {
                  padding: 0;
                  display: -webkit-box;
                  overflow: hidden;
                  -webkit-line-clamp: 4;
                  -webkit-box-orient: vertical;
               }
               // margin-bottom: 10px;
            }
         }
         // @include media-breakpoint-down(sm) {
         //     margin-bottom: 30px;
         // }
      }
   }
   .right-side {
      flex: 1;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px;
      position: relative;
      @include media-breakpoint-down(sm) {
         padding-left: 20px;
         padding-right: 20px;
         padding-bottom: 20px;
      }
      .bottom-area,
      .top-area {
         .wrapper {
            display: flex;
            @include media-breakpoint-down(sm) {
               display: block;
               margin-bottom: 20px;
            }
            .step-title {
               flex: 1;
               margin-bottom: 25px;

               @include media-breakpoint-down(sm) {
                  margin-bottom: 15px;
               }
               .text {
                  font-weight: 700;
                  font-size: 27px;
                  line-height: 32px;
                  color: #26323a;
                  margin-bottom: 3px;
                  @include media-breakpoint-down(sm) {
                     font-size: 20px;
                  }
               }
               .sub-text {
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 16px;
                  color: #718690;
               }
            }

            .right {
               flex: 1;
               align-items: end;
               text-align: right;
               font-weight: 600;
               font-size: 12px;
               line-height: 16px;
               @include media-breakpoint-down(sm) {
                  align-items: start;
                  text-align: left;
               }
               &.hide-as-bottom-button-visible {
                  opacity: 0;
               }
               .language-selector {
                  display: inline-block;
               }
               .previous {
                  display: inline-block;
                  @include media-breakpoint-down(sm) {
                     width: 45%;
                  }
                  .back-button {
                     display: inline-block;
                     color: #596a73;
                     padding: 0.65rem 0.85rem;
                     border-radius: 4px;
                     border: 2px solid #edf0f2;
                     cursor: pointer;
                     transition: all 0.3s;
                     background-color: white;
                     &:hover {
                        border: 2px solid rgb(218, 218, 218);
                     }
                     svg {
                        top: 0;
                     }
                     &:hover {
                        border: 2px solid rgba(89, 106, 115, 0.596);
                     }
                     @include media-breakpoint-between(md, lg) {
                     }

                     @include media-breakpoint-down(sm) {
                        width: 100%;
                        text-align: center;
                     }
                  }
               }
               .next {
                  display: inline-block;
                  @include media-breakpoint-down(sm) {
                     width: 45%;
                     margin-left: 10%;
                  }
                  .continue-button {
                     i {
                        font-size: 12px;
                        margin-right: 10px;
                     }
                     cursor: pointer;
                     display: inline-block;
                     font-weight: 500;
                     background: #007989;
                     border: 2px solid #007989;
                     border-radius: 4px;
                     margin-left: 15px;
                     padding: 0.65rem 0.85rem;
                     color: #ffffff;
                     transition: all 0.3s;
                     &:not(.disabled):hover {
                        background: #0395a8;
                        border: 2px solid #0395a8;
                     }
                     &.disabled {
                        background: #9ccbd1;
                        border: 2px solid #9ccbd1;
                        cursor: default;
                     }
                     @include media-breakpoint-down(sm) {
                        width: 100%;
                        margin-left: 0;
                        text-align: center;
                     }
                  }
               }
            }
         }
      }
      .bottom-area {
         border-top: 1px solid #e1e7ee;
         padding-top: 30px;
         margin-top: 30px;
         @include media-breakpoint-down(sm) {
            padding-top: 2px;
            margin-top: 15px;
            .wrapper {
               margin-bottom: 0;
            }
         }
      }

      .step-content-all {
         .step-container {
            opacity: 0;
            display: none;
            @include media-breakpoint-down(sm) {
               // padding-bottom: 80px;
            }
            &.current {
               opacity: 1;
               display: block;
            }
            &.done {
               opacity: 0;
               display: none;
            }
         }
      }

      &:before {
         // width:0;
      }
      // &.animate-next-step-1 {
      //     &:before{
      //         content:"";
      //         position: absolute;
      //         top:0;
      //         right:0;
      //         z-index: 99;
      //         height: 100%;
      //         background-color: white;
      //         //transition: all 5s;
      //         animation-duration: 0.3s;
      //         animation-timing-function: ease-out;
      //         animation-name: animatein;
      //     }
      // }
      // &.animate-next-step-2 {
      //     &:before{
      //         content:"";
      //         position: absolute;
      //         top:0;
      //         left:0;
      //         z-index: 99;
      //         height: 100%;
      //         width: 0;
      //         background-color: white;
      //         //transition: all 5s;
      //         width: 100%;
      //         animation-duration: 0.3s;
      //         animation-timing-function: ease-out;
      //         animation-name: animateout;
      //     }
      // }
      // @keyframes animatein {
      //     from {
      //     //   transform: translateX(0%);
      //     width: 0%;
      //     }

      //     to {
      //       //transform: translateX(100%);
      //       width: 100%;
      //     }
      //   }
      //   @keyframes animateout {
      //     from {
      //     //   transform: translateX(0%);
      //         width: 100%;
      //     }

      //     to {
      //       //transform: translateX(100%);
      //         width: 0%;
      //     }
      //   }
   }
}

.RequestWizardInsideOrchestry {
}

.request-wizard-body {
   background-color: white !important;
   border-radius: 5px;
   // .kt-wrapper {
   //    padding-top: 65px !important;
   // }
   // #kt_subheader {
   //    height: 0px;
   //    min-height: 0;
   //    overflow: hidden;
   // }

   .modal {
      font-family: "TT Norms";
      font-variant-ligatures: none;
   }
   #root {
      min-height: 100%;
   }
   .request-wizard {
   }
}

.continue-warning-list {
   margin: 0;
   padding-left: 15px;
   li {
   }
}
