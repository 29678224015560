/* The switch - the box around the slider */
.form-toggle {
   position: relative;
   display: inline-block;
   width: 56px;
   height: 24px;

   /* Hide default HTML checkbox */
   .switch input {
      opacity: 0;
      width: 0;
      height: 0;
   }
   > input {
      opacity: 0;
   }

   /* The slider */
   .slider {
      display: block;
      margin-bottom: 0;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-color: #8A8A8A;
      background-color: #d0d0d0;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      // box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.22);

      > div {
         font-size: 12px;
         color: white;
         position: absolute;
         top: 3px;
         // font-weight: 600;

         &.checkbox-true {
            opacity: 0;
            left: 10px;
            text-align: left;
            transition: all 0.3s;
         }
         &.checkbox-false {
            opacity: 1;
            right: 10px;
            text-align: right;
            transition: all 0.3s;
         }
      }
   }

   .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
   }

   input:checked + .slider {
      background-color: #5867dd;
      > div {
         &.checkbox-false {
            opacity: 0;
         }
         &.checkbox-true {
            opacity: 1;
         }
      }
   }

   input:focus + .slider {
      box-shadow: 0 0 1px #28b976;
   }

   input:checked + .slider:before {
      -webkit-transform: translateX(32px);
      -ms-transform: translateX(32px);
      transform: translateX(32px);
   }

   /* Rounded sliders */
   .slider.round {
      border-radius: 12px;
   }

   .slider.round:before {
      border-radius: 50%;
   }

   &.theme-orchestry-teal {
      input:checked + .slider {
         background-color: #3da0a2;
      }
   }

   &.theme-small-green {
      width: 38px;
      height: 20px;
      .checkbox-true,
      .checkbox-false {
         display: none;
      }
      input:checked + .slider {
         background-color: #a1cd3a;
      }
      .slider:before {
         position: absolute;
         content: "";
         height: 16px;
         width: 16px;
         left: 2px;
         bottom: 2px;
         background-color: white;
         -webkit-transition: 0.4s;
         transition: 0.4s;
         // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      }

      input:checked + .slider:before {
         -webkit-transform: translateX(18px);
         -ms-transform: translateX(18px);
         transform: translateX(18px);
      }
   }
}
