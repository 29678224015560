.notification-content {
    display: flex;
    align-items: center;
    // justify-content: center;
    .nc-loader {
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        text-align: center;
        padding-right: 10px;
        height: 32px;
        flex-basis: 50px;
        flex-grow: 0;     /* do not grow   - initial value: 0 */
        flex-shrink: 0; 
    }
    .nc-success {
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        text-align: center;
        padding-right: 10px;
        height: 32px;
        flex-basis: 50px;
        flex-grow: 0;     /* do not grow   - initial value: 0 */
        flex-shrink: 0; 
        height: 1.2em;
        svg {
            width: 1.2em;
            height: 1.2em;
        }
    }
    .nc-error {
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        text-align: center;
        padding-right: 10px;
        height: 32px;
        flex-basis: 50px;
        flex-grow: 0;     /* do not grow   - initial value: 0 */
        flex-shrink: 0; 
        height: 1.2em;
        svg {
            width: 1.2em;
            height: 1.2em;
            color:red;
        }
    }
    .nc-text {
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.222727px;
        font-family: 'TT Norms';
        color: #414141;
        vertical-align: middle;
        padding: 6px 0px 6px 0;
    }
}