.installer-step-welcome {
   min-height: 500px;
   p {
      font-size: 14px;
      font-weight: 500;
      color: #718690;
   }
   .portlet-alert {
      max-width: 75%;
   }
}
