.health-check-review-teams-page {
   display: flex;
   flex-direction: column;
   padding: 72px;

   & * {
      font-family: var(--mantine-font-family) !important;
   }

   .orchestry,
   .title {
      align-items: center;
      display: flex;
   }

   .title {
      margin-top: 64px;

      .party-popper {
         width: 24px;
         height: 24px;
      }
   }

   .orchestry {
      .logo {
         width: 32px;
         height: 32px;
      }
   }

   .preview-image {
      max-width: 800px;
   }

   .button {
      max-width: fit-content;
   }
}
