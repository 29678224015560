@use "~partials/breakpoint-variables" as bp;

.recommendations-page {
   label {
      margin-bottom: 0;
   }
   & * {
      font-family: var(--mantine-font-family) !important;
   }

   display: flex;
   flex-direction: column;

   .orchestry-pagination,
   .recommendation-share-suggestion,
   .orchestry-query-context {
      margin-top: 24px;
   }

   .page-header {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .filter-row {
         align-items: center;
         display: grid;
         grid-template-columns: 1fr 1fr;
         justify-content: space-between;

         @media (max-width: bp.$orch-breakpoint-tablet) {
            row-gap: 24px;
         }

         .left-side {
            display: flex;
            @media (max-width: bp.$orch-breakpoint-tablet) {
               justify-content: center;
            }
         }

         .right-side {
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 8px;
            width: 100%;
            @media (max-width: bp.$orch-breakpoint-tablet) {
               justify-content: center;
               [data-variant="default-searchbox"] {
                  width: 100%;
               }
            }
         }
      }

      .stats-header {
         display: grid;
         gap: 8px;
         grid-template-columns: calc(1fr / 3) calc(1fr / 6) calc(1fr / 6) calc(1fr / 3);
         width: 100%;
      }

      @media (max-width: bp.$orch-breakpoint-desktop) {
         .filter-row {
            grid-template-columns: 1fr 1fr;
         }

         .stats-header {
            grid-template-columns: 1fr 1fr;
         }
      }

      @media (max-width: bp.$orch-breakpoint-tablet) {
         .filter-row {
            grid-template-columns: 1fr;
         }

         .stats-header {
            grid-template-columns: 1fr;
         }
      }
   }
}
