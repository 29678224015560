.root {
   //all buttons

   /* default variant */
   &[data-variant] {
      display: inline-flex;
      justify-content: center;
      font-family: var(--mantine-font-family);
      font-size: var(--mantine-font-size-xs);
      font-weight: 400;
      border-radius: var(--mantine-radius-default);
      transition: all 0.3s;
      padding: 8px 14px;
      box-shadow: var(--mantine-shadow-xs);
   }

   //size variants
   &[data-size="sm"] {
      padding: 8px 14px;
      height: 34px;
      .inner {
         .section {
            i {
               font-size: 16px;
            }
            img {
               width: 16px;
               height: 16px;
            }
         }
      }
   }

   &[data-size="md"] {
      height: 40px;
      padding: 10px 16px;
      font-size: var(--mantine-font-size-sm) !important;
      .inner {
         .section {
            i {
               font-size: 20px;
            }
            img {
               width: 20px;
               height: 20px;
            }
         }
      }
   }

   &[data-icon-size="sm"] {
      .inner {
         .section {
            i {
               font-size: 16px;
            }
            img {
               width: 16px;
               height: 16px;
            }
         }
      }
   }

   //icon specific configurations
   &[data-icon-type="svg"],
   &[data-icon-type="font"] {
      .inner {
         display: flex;
         gap: 8px;
         .section {
            margin: 0;
         }
      }
   }

   &[data-icon-type="svg"] {
      .inner {
         .section {
            img[data-svg="active"] {
               display: none;
            }
         }
      }
      &:hover {
         .inner {
            .section {
               img[data-svg="base"] {
                  display: none;
               }
               img[data-svg="active"] {
                  display: block;
               }
            }
         }
      }
   }

   //colour variants
   &[data-variant="primary"] {
      color: var(--mantine-color-primary-0);
      background: var(--mantine-color-primary-7);
      &:hover {
         background: var(--mantine-color-primary-8);
         color: var(--mantine-color-primary-0);
      }
   }

   &[data-variant="danger"] {
      background-color: var(--mantine-color-error-7);
      color: var(--mantine-color-error-0);

      &:hover {
         background: var(--mantine-color-error-8);
         color: var(--mantine-color-error-0);
      }

      &[data-disabled="true"] {
         background: var(--mantine-color-error-2);
         color: var(--mantine-color-error-0);
         cursor: not-allowed;
         &:hover {
            background: var(--mantine-color-error-3);
            color: var(--mantine-color-error-1);
         }
      }
   }

   &[data-variant="secondary-color"] {
      color: var(--mantine-color-primary-9);
      background: var(--mantine-color-primary-0);
      &:hover {
         color: var(--mantine-color-primary-9);
         background: var(--mantine-color-primary-1);
      }

      &[data-disabled="true"] {
         color: var(--mantine-color-primary-3);
         background: var(--mantine-color-primary-0);
         cursor: not-allowed;
         &:hover {
            color: var(--mantine-color-primary-3);
            background: var(--mantine-color-primary-0);
         }
      }
   }

   &[data-variant="logo"] {
      padding: 8px 14px !important;
      color: var(--mantine-color-primary-0);
      background: var(--mantine-color-primary-8);

      &:hover {
         color: var(--mantine-color-primary-9);
         background: var(--mantine-color-primary-0);
      }
   }

   &[data-variant="inverted-primary"] {
      padding: 8px 14px !important;
      color: var(--mantine-color-primary-9);
      background: var(--mantine-color-primary-0);

      &:hover {
         color: var(--mantine-color-primary-0);
         background: var(--mantine-color-primary-8);
      }
   }

   &[data-variant="gray"] {
      padding: 8px 14px !important;
      color: var(--mantine-color-gray-7);
      border-color: var(--border);
      background: var(--mantine-color-gray-0);
      &:hover {
         color: var(--mantine-color-gray-1);
         background: var(--mantine-color-gray-5);
      }
   }

   &[data-variant="filter"] {
      padding: 10px 16px;
      height: 100%;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      background: var(--white);
      border: 1px solid var(--border);
      color: var(--mantine-color-text-tertiary-0);
      transition: all 300ms ease-out;

      .label {
         display: inline-flex;
         gap: 8px;
         height: auto;
      }

      p[data-count] {
         color: var(--mantine-color-gray-9);
      }

      &:hover {
         color: var(--mantine-color-primary-0);
         background: var(--mantine-color-primary-8);
         p[data-count] {
            transition: all 300ms ease-out;
            color: var(--mantine-color-primary-9);
         }
      }
   }

   &[data-variant="tertiary"] {
      color: var(--mantine-color-primary-8);
      background: var(--mantine-color-white);

      &:hover {
         color: var(--mantine-color-primary-8);
         background: var(--mantine-color-white);
      }
   }

   &[data-variant="secondary-gray"] {
      background-color: var(--mantine-color-white);
      color: var(--mantine-color-text-tertiary-0);
      border: 1px solid var(--Border, #e5e7eb);

      &[data-disabled-style="true"] {
         color: var(--mantine-color-gray-3);
         background-color: var(--mantine-color-white);
         border: 1px solid var(--Border, #e5e7eb);
      }
      &:hover:not([data-disabled-style="true"]) {
         background: var(--mantine-color-gray-0);
         color: var(--mantine-color-text-tertiary-0);
         border: 1px solid var(--Border, #e5e7eb);
      }
   }
   &[data-variant="grey-light"] {
      color: var(--mantine-color-text-secondary-0);
      border: 1px solid var(--mantine-color-gray-1);
      border-color: var(--mantine-color-gray-1);
      background: var(--mantine-color-gray-1);
      &:hover {
         color: var(--mantine-color-text-secondary-0);
         border: 1px solid var(--mantine-color-gray-2);
         border-color: var(--mantine-color-gray-2);
         background-color: var(--mantine-color-gray-2);
      }
   }

   &[data-variant="link-gray"] {
      color: var(--mantine-color-gray-6);
      background-color: var(--mantine-color-white);
      box-shadow: none;
      border: none;

      &:hover {
         color: var(--mantine-color-gray-6);
         background-color: var(--mantine-color-white);
         box-shadow: none;
         border: none;
      }
   }

   &[data-variant="link-primary-9"] {
      color: var(--mantine-color-primary-9);
      background-color: var(--mantine-color-white);
      box-shadow: none;
      border: none;
      padding: 0;
      .label {
         text-decoration: underline;
      }

      &:hover {
         color: var(--mantine-color-primary-9);
         background-color: var(--mantine-color-white);
         box-shadow: none;
         border: none;
      }
   }

   &[data-variant="white"] {
      color: var(--mantine-color-gray-7);
      background-color: var(--mantine-color-white);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border-color: var(--border);

      &:hover {
         color: var(--mantine-color-gray-7);
         background-color: var(--mantine-color-gray-1);
         box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
   }
}
