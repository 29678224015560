.form-naming-policy {
   .info-right {
      .portlet-alert {
         margin-left: 0px;
         margin-right: 46px;
      }
      .alert {
         margin: 0;
      }
   }
   .fix-editor {
      &.disabled {
         opacity: 0.4;
      }

      .fix-item {
         position: relative;
         padding: 5px 0;
         margin: 0;
         &:before {
            position: absolute;
            content: "";
            left: 15px;
            right: 15px;
            bottom: 0;
         }
         &:last-child {
            padding-bottom: 0;
            &:before {
               display: none;
            }
         }
         .dd-header {
            padding: 0.45rem 0.65rem;
            height: calc(1.1em + 1.3rem + 2px);
         }
         .fix-left {
            padding-left: 0;
            position: relative;
            .count {
               display: inline-block;
               display: none;
               position: absolute;
               left: 15px;
               top: 8px;
               font-size: 12px;
               //margin-top: -11px;
               width: 24px;
               height: 24px;
               min-width: 24px;
               background-color: #8585ff;
               color: white;
               font-weight: 700;
               line-height: 23px;
               padding-left: 0;
               padding-right: 0;
               text-align: center;
               border-radius: 5px;
            }
            .fix-type {
            }
            .dd-wrapper .dd-header {
               transition: all 0.5s;
            }
         }
         .fix-right {
            position: relative;
            padding-right: 47px;
            input {
               padding: 0.45rem 0.65rem;
               height: calc(1.1em + 1.3rem + 2px);
            }
            .remove {
               position: absolute;
               right: 0px;
               top: 0px;
               opacity: 0.7;
               background-color: rgb(230, 230, 230);
               width: 33px;
               height: 33px;
               border-radius: 5px;
               text-align: center;
               transition: all 0.3s;
               svg {
                  position: relative;
                  top: calc(50% - 9px);
                  height: 18px;
                  width: 18px;
                  color: rgb(255, 255, 255);
               }
            }
            .red-outline {
               .form-control {
                  border: 1px solid red !important;
               }
            }
         }
         &:hover {
            .remove {
               opacity: 0.7;
               background-color: rgb(237, 116, 116);
               cursor: pointer;
            }
         }
         &.new-mode {
            .fix-left {
               .count {
                  background-color: #e2e2e2;
               }
            }
            .fix-right {
               .form-control {
                  background-color: #f1f1f1;
                  border: none;
                  height: calc(1.1em + 1.3rem + 2px);
               }
               .remove {
                  opacity: 1;
                  background-color: #f1f1f1 !important;
                  cursor: default;
               }
            }
         }
      }
   }
}
