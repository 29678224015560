.step-card-select-template {
   // > .row {
   //    margin-left: 6%;
   //    margin-right: 6%;
   // }
   .form-group {
      margin-bottom: 0;
   }
   .template-selectors {
      .description {
         .col {
            > div {
               margin: 15px 10px 0px 10px;
               font-weight: 400;
               color: #718690;
               display: flex;
               justify-content: center;
            }
         }
      }
   }

   .second-step {
      margin-top: 20px;
      .form-group {
         margin-bottom: 0;
      }
      .title {
         display: flex;
         align-items: center;
         gap: 0.5rem;
      }
   }

   .use-overlay {
      &:before {
         margin-bottom: -4px;
      }
   }

   #ws_deletion {
      .form-control {
         border: none;
         // padding-bottom: 0 !important;
      }
      .form-group {
         margin-bottom: 0 !important;
      }
      label {
         margin-bottom: 0 !important;
      }
   }
}
