.orchestry-admin-reports {
   table {
      background-color: white !important;
      border-radius: 6px;
      > tbody {
         > tr > td {
            &:first-child {
               font-size: 11px;
            }
         }
      }
   }
}
