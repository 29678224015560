.lifecycle-guest-settings {
   .kt-portlet__body {
      > h5 {
         color: #383838;
      }
      .ptext {
         font-weight: 400;
         color: #383838;
      }
      .nav-item {
         a {
            font-weight: 500;
            color: #383838;
         }
         .form-validator-rollup {
            border-radius: 2px;
            i {
               font-size: 10px;
            }
            > span {
               font-size: 11px;
            }
         }
      }
   }
}
