@import "_metronic/_assets/sass/breakpoints";

.chrome-request-notifications, .queues-button {
   display: flex;
   .admin-notification {
      max-width: 0;
      overflow: hidden;
      height: 33px;
      transition: all 0.3s;
      &.open-button {
         max-width: 50px;
      }
      .notification-panel-toggler {
         background: #ffffff;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         width: 32px;
         text-align: center;
         line-height: 32px;
         margin-right: 10px;
         cursor: pointer;
         transition: all 0.3s;
         position: relative;
         &:hover {
            border: 1px solid #007989;
         }
         .kt-header__topbar-item {
            top: 0;
            margin-top: 0 !important;
            position: static;
         }
         .kt-header__topbar-icon {
            height: 30px;
            width: 32px;
            display: block;
            cursor: pointer;
            transition: all 0.3s;
            border-radius: 4px;
            .count {
               top: -1px;
               right: auto;
               left: 17px;
            }
            i {
               color: #718690;
               font-size: 13px;
               transform: scale(-1, 1);
            }
            .kt-pulse__ring {
               width: 32px;
               height: 32px;
               top: 0;
            }
         }
      }
   }

   .queues-button {
      max-width: 0;
      height: 33px;
      transition: all 0.3s;
      .dropdown-menu {
         // margin-top: 20px;
         .kt-head {
            border-radius: 4px 4px 0 0;
         }
      }
      &.open-button {
         max-width: 50px;
      }
      .kt-header__topbar-icon {
         padding-bottom: 8px;
         display: block;
         .kt-svg-icon {
            background: #ffffff;
            border: 1px solid #e1e7ee;
            // box-shadow: inset 0px -2px 2px rgb(225 231 238 / 25%);
            border-radius: 5px;
            width: 26px;
            text-align: center;
            line-height: 20px;
            margin-right: 10px;
            cursor: pointer;
            transition: all 0.3s;
            position: relative;
            height: 32px;
            width: 32px;
            display: block;
            cursor: pointer;
            transition: all 0.3s;
            border-radius: 4px;
            &:hover {
               border: 1px solid #007989;
            }
            line-height: 5px;

            svg {
               border: 0px solid transparent;
               &:hover {
                  border: 0px solid transparent;
               }
               position: relative;
               top: 5px;
               // right: -1px;
               margin: 0 auto;
               line-height: 5px;
               height: 19px;
               width: 19px;
            }
         }
      }
   }

   .notifications {
      background: #ffffff;
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      width: 32px;
      text-align: center;
      line-height: 32px;
      // margin-right: 10px;
      cursor: pointer;
      transition: all 0.3s;
      position: relative;
      z-index: 2000;
      &:hover {
         border: 1px solid #007989;
      }
      i {
         color: #718690;
      }
      .notification-indicator {
         width: 4px;
         height: 4px;
         border-radius: 50%;
         position: absolute;
         right: 5px;
         top: 5px;
         background: #ff0000;
         animation: pulse 2s cubic-bezier(0.4, 0, 0.2, 1);
         animation-iteration-count: infinite;
      }
      @keyframes pulse {
         from {
            opacity: 1;
         }
         to {
            opacity: 0.2;
         }
      }
   }
}
#request-notifications {
   top: 15px !important;
   left: auto !important;
   max-width: 70%;
   // box-shadow: black;
   @include kt-desktop-xxl {
      width: 50%;
   }
   @include kt-desktop-xl {
      width: 60%;
   }
   @include kt-desktop {
      width: 70%;
   }
   @include kt-tablet-and-mobile {
      width: 95%;
      max-width: 95%;
      position: fixed !important;
      left: 0 !important;
   }
   .popover-header {
      padding: 20px 25px 20px 30px;
      color: #26323a;
      font-size: 16px;
      font-weight: 600;
      box-shadow: 0px 1.40831px 2.12963px rgba(191, 200, 212, 0.034102), 0px 3.56171px 5.386px rgba(191, 200, 212, 0.048808), 0px 7.26556px 10.9869px rgba(191, 200, 212, 0.061192),
         0px 14.9657px 22.631px rgba(191, 200, 212, 0.075898);
   }
   .popover-body {
      padding: 0;
      .request-container {
         border-bottom: 1px solid #e1e7ee;
         padding: 15px 25px 15px 30px;
         &:last-child {
            border-bottom: 0px solid transparent;
         }
      }
   }
}
#notification-backdrop {
   // top:35px !important;
   &.show {
      // opacity: 0.3;
   }
}
