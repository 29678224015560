.root {
   .wrapper {
      &[data-variant="user-select"] {
         .input {
            border-color: #e5e7eb;
         }
         .section {
            &[data-position="right"] {
               color: var(--mantine-color-gray-7);
               font-size: 20px;
               i {
                  cursor: pointer;
               }
            }
         }
      }
   }
}
