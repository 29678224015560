@import "app/assets/common/bootstrap-media-mixins";
.request-details {
   font-weight: 400;
   h6 {
      margin-bottom: 20px;
   }
   h5 {
      margin: 20px 0 20px 0;
   }

   .form-group.form-group-xs {
      align-items: center;
   }

   @include media-breakpoint-down(sm) {
      .kt-portlet__body {
         > .row {
            display: block;
         }
         .col {
            display: block;
         }
      }
   }
   .current-status {
      .request-status {
         zoom: 1.5;
         font-weight: 600;
      }
      ul {
         margin: 0;
         padding: 10px 0 20px 20px;
         li {
            margin: 0;
            padding: 0;
         }
      }
      .view-log {
         margin-top: 6px;
         margin-left: 1px;
         font-size: 14px;
         font-weight: 500;
         color: #56689a;
         cursor: pointer;
         i {
            font-size: 19px;
            position: relative;
            top: 3px;
            margin-right: 10px;
         }
      }
   }

   .provision-link {
      color: blue;
      cursor: pointer;
   }

   .comment {
      margin-bottom: 20px;
   }

   .channels-portlet {
      .private {
         background-color: #da9393;
         color: white;
         font-size: 12px;
         border-radius: 2px;
         padding: 0 4px;
         font-weight: 500;
      }
   }
   .request-details-metadata {
      .metadata-table-field-title {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
}
