.team-information-logo {
   position: relative;
   .skeleton-logo {
      .fake-box {
         border-radius: 6px;
      }
   }
   padding-right: 20px;
   .profile-picture {
      border: 1px solid #e1e7ee;
      border-radius: 4px;
      &.size-lg {
         border-radius: 6px;
         &.two-letter {
            span {
               font-size: 18px;
            }
         }
      }
   }
}
