.error-boundary {
   .error-header {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;

      .meteor {
         width: 176px;
      }

      .main-header {
         color: #f14a32;
      }
      .main-subheader {
         color: #f35d48;
      }
   }
   .error-message {
      padding-left: 2.5rem;
      .error-message-header {
         color: #f7998b;
      }
      .error-message-body {
         color: #6a6a6a;
      }
   }
}
