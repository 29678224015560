.tenant-details-partner-tab {
   .unlink-action {
   }
   .td-action {
      text-align: left !important;
   }
   .inline-input-save-button {
      display: flex;
      button {
         margin-left: 10px;
         i {
            margin-left: 6px;
         }
      }
   }
   .inline-connected-status {
      display: flex;
      align-items: center;
      i {
         font-size: 18px;
         color: green;
      }
      span {
         color: #222;
         margin-left: 10px;
      }
   }
}
