.progress-report {
   background-color: white;
   .progress-title {
   }
   .progress-subtitle {
      margin-bottom: 10px;
   }

   .progress-fullbar {
      .progress-spinner {
         margin: 0 auto;
         text-align: center;
         .animated-loader {
            display: block;
         }
      }
      .progress.m-progress-lg {
         height: 42px;
         display: flex;
         width: 100%;
         padding: 4px;
         border: 1px solid #e1e7ee;
         border-radius: 8px;
         background-color: white !important;
         .progress-wrapper {
            border-radius: 4px;
            height: 100%;
            transition: all 0.3s;
            background: linear-gradient(270deg, #007988 0%, #40af8c 51.56%, #a1cc3a 100%);
         }
         .progress-bar {
            height: 100%;
            width: 100%;
            border-radius: 4px;
            background-image: linear-gradient(
               -45deg,
               rgba(255, 255, 255, 0.15) 25%,
               transparent 25%,
               transparent 50%,
               rgba(255, 255, 255, 0.15) 50%,
               rgba(255, 255, 255, 0.15) 75%,
               transparent 75%,
               transparent
            );
            background-color: transparent !important;
            .fa {
               font-size: 17px;
            }
         }
         .progress-left {
            flex: 1;
         }
         .progress-right {
            padding-left: 8px;
            padding-right: 2px;
            .progress-spinner {
               position: relative;
               top: 50%;
               margin-top: -12px;
               // right: 2px;
            }
         }
      }
      .sub-progress-bar {
         // background-color: grey;
         padding-top: 10px;
         .progress {
            border: 1px solid #e1e7ee;
            border-radius: 8px;
            padding: 4px;
            background-color: white !important;
            height: 20px;
            .progress-wrapper {
               border-radius: 4px;
               height: 100%;
               background: #a1cc3a;
               transition: all 0.3s;
            }
            .progress-bar {
               width: 100%;
               height: 100%;
               border-radius: 4px;
               background-image: linear-gradient(
                  -45deg,
                  rgba(255, 255, 255, 0.15) 25%,
                  transparent 25%,
                  transparent 50%,
                  rgba(255, 255, 255, 0.15) 50%,
                  rgba(255, 255, 255, 0.15) 75%,
                  transparent 75%,
                  transparent
               );
            }
         }
      }
      &.main-bar-hidden {
         .task-title {
            margin-bottom: 10px;
            font-weight: 500;
         }
         .sub-progress-bar {
            border: 1px solid #e1e7ee;
            border-radius: 4px;
            padding: 8px;
            background-color: rgb(247, 247, 247) !important;
            .sub-task-title {
               font-size: 11px;
               font-weight: 500;
               margin-bottom: 5px;
            }
         }
      }
   }
   .progress-concole-toggle {
      padding: 10px 0;
      cursor: pointer;
      i {
         margin-right: 10px;
         transition: linear 0.3s;
      }
      &.open {
         i {
            transform: rotate(90deg);
         }
      }
   }
   .progress-console {
      background-color: #3e3e3e;
      border-radius: 4px;
      .console-wrapper {
         padding: 10px 0 10px 10px;
         font-size: 0.87em;
         .ScrollbarsCustom-TrackY {
            top: 0px !important;
            height: 100% !important;
         }
         .console-line {
            color: white;
            font-family: Monaco, monospace;
            position: relative;
            .cl-date-time {
               position: absolute;
               position: top;
               opacity: 0.6;
            }
            .right-side {
               //padding-left: 68px;
               padding-left: 5em;
               .cl-message {
                  &.type-message {
                  }
                  &.type-warning {
                     color: rgb(255, 230, 0);
                  }
                  &.type-error {
                     color: rgb(250, 73, 73);
                  }
                  &.type-complete {
                     color: rgb(3, 197, 3);
                  }
               }
               .cl-record-type {
               }
               .cl-activity {
                  color: #bcbcfd;
                  i {
                     opacity: 0.5;
                     font-size: 10px;
                  }
               }
               .cl-status {
                  display: none;
               }
            }
         }
      }
   }
   .only-console {
         margin: -15px 0 -15px 0;
         .progress-console {
         .console-wrapper {
            // padding: 0;
         }
      }
   }
}
