.workspace-bulk-action-remediate-modal {
   table {
      width: 100%;
      table-layout: fixed;
      th {
         border-bottom: 2px solid rgba(128, 128, 128, 0.05);
         padding: 8px;
         &:first-child {
         }
         &:last-child {
            // width: 30%;
         }
         &.th-actions {
            width: 84px;
         }
         &.th-protected {
            width: 84px;
            text-align: center;
            .info {
               margin-left: 6px;
            }
         }
      }
      td {
         border-bottom: 1px solid rgba(128, 128, 128, 0.05);
         padding: 8px;
         font-weight: 500;
         &:first-child {
            border-right: 1px solid rgba(128, 128, 128, 0.05);
            padding-left: 10px;
         }
         &:last-child {
            border-left: 1px solid rgba(128, 128, 128, 0.05);
            // width: 20%;
         }
      }
      tr {
         &:last-child {
            td {
               border-bottom: none;
            }
         }
         &.cant-force {
            td {
               > div {
                  opacity: 0.7;
               }
               background-color: rgba(128, 128, 128, 0.04);
            }
         }
         &.no-policy {
            td {
               // > div {
               //    opacity: 0.5;
               // }
               // background-color: rgba(55, 55, 55, 0.05);
            }
         }
         &.item-to-be-updated {
            td {
               position: relative;
               background-color: rgba(0, 0, 255, 0.06);
               border-bottom: 1px solid #e6e3fd;
               &::before {
                  content: "";
                  position: absolute;
                  top: -1px;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background-color: #e6e3fd;
               }
            }
         }
         &.workspace-ineligible {
            td {
               .dropdowns {
                  opacity: 0.6;
               }
               background-color: rgba(128, 128, 128, 0.06);
            }
         }

         .dropdowns {
            .excluded-from-archival {
               opacity: 0.6;
            }
            .dd-wrapper {
               .header-text {
                  position: absolute;
                  left: 0.65rem;
                  right: 1.65rem;
               }
            }
         }
      }
      .title-area {
         display: flex;
         align-items: center;
         .ta-icon {
            .profile-picture {
               border-radius: 4px;
               height: 20px;
               width: 20px;
            }
            padding-right: 10px;
         }
         .m365-icon {
            img {
               position: relative;
               top: -1px;
               height: 12px;
               width: 12px;
            }
            padding-right: 10px;
         }
         .ta-title {
         }
         .ta-title-and-url {
            flex: 1;
            min-width: 0;
            .ta-title {
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
            }
            .ta-url {
               font-size: 10px;
               opacity: 0.7;
               font-weight: 400;
               display: none;
               a {
                  color: #646c9a;
               }
            }
         }
      }
      .toggle-protection {
         text-align: center;
         &.clone-preview {
            .slider {
               box-shadow: 0px 0px 3px 0px blue;
            }
         }
      }

      .action {
         display: flex;
         align-items: center;
         .a-left {
            line-height: 13px;
            i {
               font-size: 16px;
            }
            i.fa-circle {
               color: rgba(255, 0, 0, 0.652);
            }
            i.fa-check-circle {
               color: rgba(0, 128, 0, 0.761);
            }
            padding-right: 10px;
            padding-left: 5px;
         }
         .a-right {
            .a-title {
               font-weight: 500;
            }
            .a-description {
               font-weight: 400;
               font-size: 11px;
               opacity: 0.7;
            }
         }
      }
      .actions {
         display: flex;
         align-items: center;
         .btn {
            // padding-left:2px;
            // padding-right:2px;
            padding: 3px 7px;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            text-align: center;
            i {
               padding: 0;
            }
         }
         .clone {
            // opacity: 0.5;
            padding-right: 6px;
            .btn {
               background-color: rgba(0, 0, 255, 0.5);
               i {
                  color: white;
                  font-size: 13px;
               }
               &.disabled {
                  cursor: default;
                  opacity: 0.4;
               }
            }
         }
         .undo {
            // opacity: 0.5;
            .btn {
               background-color: rgba(228, 91, 18, 0.5);
               i {
                  color: white;
                  font-size: 13px;
               }
               &.disabled {
                  cursor: default;
                  opacity: 0.4;
               }
            }
         }
      }
      .dropdowns {
         display: flex;
         align-items: center;
         > div:first-child {
            flex: 1;
            margin-right: 11px;
         }
         .btn {
            // padding-left:2px;
            // padding-right:2px;
            padding: 3px 7px;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            text-align: center;
            i {
               padding: 0;
            }
         }

         &.clone-preview {
            // background-color: black;
            .no-selection-allowed,
            .dd-wrapper .dd-header {
               // outline: 2px solid blue;
               box-shadow: 0px 0px 3px 0px blue;
            }
         }
         .no-selection-allowed {
            display: flex;
            align-items: center;
            background-color: #f7f8fa;
            &.disabled {
               background-color: #f7f8fa;
            }
            .no-dd-title {
               flex: 1;
            }
            .no-dd-info {
               position: relative;
               > div {
                  position: relative;
                  right: -5px;
               }
            }
            .info {
            }
         }
      }
   }
   button:disabled {
      cursor: default;
   }
   .completed {
      .completed-box {
         text-align: center;
         padding: 20px 0 50px 0;
         i {
            font-size: 65px;
            opacity: 0.5;
         }
         .done-text {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 10px;
         }
         a {
            font-weight: 500;
         }
      }
   }
   .processing-card {
      padding: 20px 0 50px 0;
      .loader-text {
         // font-size: 20px;
         // font-weight: 500;
      }
   }
   &.lifecycle-report-bulk-action-modal-restore {
      .modal-dialog {
         max-width: 90%;
         .modal-header {
            padding: 0.5rem 1.25rem;
         }
         .modal-body {
            padding: 0.5rem 0rem 0.5rem 1.25rem;
            .remediation-header-table {
               position: absolute;
               z-index: 2;
               width: calc(100% - 2.5rem);
               background-color: white;
               th {
                  // vertical-align: bottom;
                  border-right: 1px solid #f7f7f7;
                  border-bottom: 0px solid transparent;
               }
            }
            .remediation-steps {
               height: 115px;
               background-color: rgb(245, 244, 244);
               border-bottom: 1px solid #cfcfcf;
               td {
                  border-right: 1px solid #ededed;
                  vertical-align: top;
                  position: relative;
                  &:last-child {
                     border-right: none;
                  }
                  &.step-done {
                     background-color: rgb(229, 229, 229);
                     > * {
                        opacity: 0.5;
                     }
                     .navigate {
                        display: none;
                     }
                  }
                  &.step-waiting {
                     background-color: rgb(229, 229, 229);
                     > * {
                        opacity: 0.5;
                     }
                     .navigate {
                        display: none;
                     }
                  }
                  .execute-archivals {
                     span {
                        background-color: rgba(255, 255, 255, 0.182);
                        margin-left: 4px;
                        padding: 2px 4px;
                        border-radius: 2px;
                     }
                  }
               }
               h6 {
                  font-size: 14px;
                  color: black;
               }
               .help-text {
                  font-size: 12px;
                  margin-bottom: 0px;
               }
               .action-top {
                  opacity: 0.7;
                  ul {
                     margin: 0;
                     padding: 0;
                     margin-left: 3px;
                     .action {
                        display: block;
                        // padding: 0.1rem 0.5rem;
                        margin-bottom: 0px;
                        color: #5867dd;
                        transition: all 0.3s;
                        &:first-child {
                           display: inline-block;
                        }
                        &:hover {
                           color: #26349e;
                           cursor: pointer;
                        }
                        i {
                           font-size: 10px;
                           margin-right: 0px;
                           width: 20px;
                        }
                        span {
                           font-size: 11px;
                        }
                        &:last-child {
                           margin-bottom: 0;
                        }
                        .animated-loader {
                           zoom: 0.5;
                           margin-right: 23px;
                        }
                     }
                  }
               }
               .step-current {
                  position: relative;
               }
               .right-align-button {
                  position: absolute;
                  bottom: 8px;
                  // left: 8px;
                  right: 8px;
                  text-align: right;
                  .skeleton-loader-common {
                     display: inline-block;
                     .fake-box {
                        background-color: #d3d3d3bf;
                     }
                  }
               }
               button {
                  font-size: 12px;
                  padding: 0.35rem 1rem;
               }
               .navigate {
                  .left,
                  .right {
                     background-color: #f5f4f4;
                     height: 26px;
                     width: 20px;
                     // text-align: center;
                     border-radius: 2px;
                     font-size: 16px;
                     line-height: 28px;
                     z-index: 1;
                     margin-top: -13px;
                     i {
                        opacity: 0.5;
                     }
                     &:hover {
                        i {
                           opacity: 1;
                        }
                        cursor: pointer;
                     }
                  }
                  .left {
                     position: absolute;
                     left: -18px;
                     top: 50%;
                     text-align: right;
                     padding-right: 6px;
                     box-shadow: -2px 1px 2px 0px rgba(0, 0, 0, 0.085);
                  }
                  .right {
                     position: absolute;
                     right: -18px;
                     top: 50%;
                     padding-left: 6px;
                     box-shadow: 2px 1px 2px 0px rgba(0, 0, 0, 0.085);
                  }
               }
            }
            .remediation-scroll {
               padding-top: 181px;
               position: relative;
               height: calc(90vh - 40px);
               overflow-y: scroll;
               td {
                  border-left: 1px solid rgba(128, 128, 128, 0.05);
                  background-color: whitesmoke;
               }
            }

            .td-remediation-workspace-name {
               width: auto;
               overflow: hidden;
               position: relative;
               .title-area {
               }
            }
            .td-remediation-workspace-actions {
               width: 100px;
               .remove-workspace {
                  font-size: 12px;
                  padding: 0.35rem 1rem;
                  background-color: #dd5858;
                  border-color: #dd5858;
                  color: #ffffff;
                  outline: none !important;
                  vertical-align: middle;
                  display: inline-block;
                  line-height: 1.5;
                  border-radius: 0.25rem;
                  display: flex;
                  transition: all 0.3s;
                  opacity: 0.8;
                  i {
                     margin-right: 6px;
                     line-height: 19px;
                  }
                  &:hover {
                     background-color: #c63e3e;
                     border-color: #b63737;
                     cursor: pointer;
                  }
               }
            }

            .td-remediation-group-status {
               width: 120px;
            }
            .td-remediation-site-status {
               width: 120px;
            }
            .td-remediation-group-status,
            .td-remediation-site-status {
               .lds-ellipsis {
                  display: inline-block;
                  position: relative;
                  width: 80px;
                  height: 18px;
               }
               .lds-ellipsis div {
                  position: absolute;
                  top: 8px;
                  width: 9px;
                  height: 9px;
                  border-radius: 50%;
                  background: #fff;
                  animation-timing-function: cubic-bezier(0, 1, 1, 0);
               }
               .lds-ellipsis div:nth-child(1) {
                  left: 8px;
                  animation: lds-ellipsis1 0.6s infinite;
               }
               .lds-ellipsis div:nth-child(2) {
                  left: 8px;
                  animation: lds-ellipsis2 0.6s infinite;
               }
               .lds-ellipsis div:nth-child(3) {
                  left: 32px;
                  animation: lds-ellipsis2 0.6s infinite;
               }
               .lds-ellipsis div:nth-child(4) {
                  left: 56px;
                  animation: lds-ellipsis3 0.6s infinite;
               }
               @keyframes lds-ellipsis1 {
                  0% {
                     transform: scale(0);
                  }
                  100% {
                     transform: scale(1);
                  }
               }
               @keyframes lds-ellipsis3 {
                  0% {
                     transform: scale(1);
                  }
                  100% {
                     transform: scale(0);
                  }
               }
               @keyframes lds-ellipsis2 {
                  0% {
                     transform: translate(0, 0);
                  }
                  100% {
                     transform: translate(24px, 0);
                  }
               }
            }

            .td-remediation-archival-date {
               width: 120px;
               text-align: center;
            }
            .td-remediation-restoreable {
               text-align: center;
               width: 120px;
               i {
                  font-size: 28px;
               }
               .fa-hexagon-check {
                  color: rgb(63, 158, 63);
               }
               .fa-hexagon-xmark {
                  color: rgb(252, 205, 205);
               }
               .fa-hexagon {
                  color: rgb(208, 97, 255);
                  opacity: 0.5;
               }
            }
            .td-remediation-policy {
               width: 180px;

               .dropdowns {
                  // overflow: hidden;
                  .form-control,
                  .form-dropdown {
                     margin-right: 0;
                  }
                  .no-dd-title {
                     white-space: nowrap;
                     overflow: hidden;
                     text-overflow: ellipsis;
                  }
                  .disabled {
                     opacity: 0.6;
                  }
               }
            }
            .td-remediation-policy-action {
               width: 90px;
            }
            .td-remediation-archival-eligibility {
               width: 120px;
               .archival-eligible,
               .archival-ineligible {
                  display: block;
                  padding: 6px 5px;
                  font-size: 12px;
                  font-weight: 500;
                  border-radius: 4px;
                  color: #3a3a3ae9;
                  width: 100%;
                  text-align: center;
                  i,
                  svg {
                     margin-right: 6px;
                  }
               }
               .archival-eligible {
                  background-color: #9ecf9e;
                  i,
                  svg {
                     margin-right: 6px;
                     color: #116e11;
                  }
               }
               .archival-ineligible {
                  background-color: #ff9e9e;
                  i,
                  svg {
                     margin-right: 6px;
                     color: #9e1e1e;
                  }
               }
            }
            .td-remediation-archival-status {
               width: 180px;
               // padding-right: 10px;
               .ws-archival-status {
                  padding: 6px 12px;
                  border-radius: 5px;
                  // background: rgba(181, 181, 181, 0.1);
                  font-size: 12px;
                  font-weight: 500;
                  margin-right: 10px;
                  min-width: 130px;
                  text-align: center;
                  &.lc-stats-active {
                     background-color: #aee8bf;
                     // border: 1px solid #aee8bf;
                     color: #557248;
                  }
                  &.lc-stats-rejected,
                  &.lc-stats-failed {
                     background-color: #f5000030;
                     // border: 1px solid #f5000030;
                     color: #d54949;
                  }
                  &.lc-stats-deleted,
                  &.lc-stats-done {
                     background-color: #f3eab1;
                     // border: 1px solid #f3eab1;
                     color: #827940;
                  }
                  &.lc-stats-readytoarchive {
                     background-color: #9ee9bd;
                     // border: 1px solid #f3eab1;
                     color: rgb(23, 90, 25);
                  }
                  &.lc-stats-processing,
                  &.lc-stats-pendingapproval {
                     background-color: rgba(210, 203, 255, 0.88);
                     // border: 1px solid rgba(210, 203, 255, 0.88);
                     color: #3a3a3ae9;
                  }
               }
            }
            .td-remediation-archival-actions {
               width: 66px;
               text-align: center;
               .btn {
                  padding: 3px 7px;
                  height: 30px;
                  width: 30px;
                  border-radius: 5px;
                  text-align: center;
                  line-height: 20px;
                  i {
                     color: white;
                     font-size: 13px;
                     padding: 0;
                  }
                  &.remove-workspace {
                     background-color: #dd5858;
                     opacity: 0.8;
                     &:hover {
                        opacity: 1;
                     }
                  }
               }
            }

            .td-remediation-archival-statistics {
               width: 130px;
               .stat-loading {
                  padding: 4px;
               }
               .count-table-button {
                  padding: 4px;
                  border-radius: 4px;
                  margin: 0 5px;
                  transition: all 0.3s;
                  cursor: pointer;
                  position: relative;
                  &:hover {
                     background-color: #dfe3ff;
                  }
                  .counts-table {
                     transition: all 0.3s;

                     td {
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        line-height: 12px;
                        font-size: 10px;
                        &:first-child {
                           opacity: 0.7;
                           width: 50px;
                        }
                        &.red {
                           color: red;
                           font-weight: 600;
                        }
                     }
                  }
                  .missing-alert {
                     position: absolute;
                     right: 6px;
                     color: red;
                     // background-color: white;
                     font-size: 16px;
                     top: 50%;
                     line-height: 18px;
                     margin-top: -12px;
                     padding: 3px 6px;
                     border-radius: 2px;
                  }
               }
               .load-stats-for-item {
                  text-align: center;

                  i {
                     color: rgba(128, 128, 128, 0.486);
                     font-size: 20px;
                  }
                  &:hover {
                     cursor: pointer;
                     i {
                        color: rgba(128, 128, 128, 0.913);
                     }
                  }
               }
            }

            .td-remediation-archival-execution-update-status {
               width: 90px;
            }
            .td-remediation-archival-execution-executed {
               width: 90px;
            }
            .td-remediation-archival-execution-update-status,
            .td-remediation-archival-execution-executed {
               text-align: center;
               i {
                  font-size: 20px;
               }
               .fa-circle {
                  color: gray;
               }
               .fa-circle-check {
                  color: rgb(84, 156, 84);
               }
               .animated-loader.al-light {
                  border-color: #ededed;
                  border-top-color: #3498db;
                  zoom: 0.85;
               }
            }

            .remediation-step-3 {
               .td-remediation-policy {
                  width: 320px;
               }
            }

            .remediation-body-table {
               .td-remediation-archival-statistics {
                  padding: 0;
               }
               .no-policy {
                  td {
                     background-color: rgb(241, 241, 241);
                  }
               }
               .form-dropdown .dd-header {
                  cursor: default;
               }
               .remediation-step-1 {
                  .td-remediation-workspace-actions,
                  .td-remediation-workspace-name,
                  .td-remediation-archival-statistics {
                     background-color: white;
                     .ta-url {
                        display: block;
                     }
                  }
                  .td-remediation-workspace-name {
                     .ta-title {
                        overflow: visible;
                        white-space: normal;
                     }
                  }
                  .no-policy {
                     .td-remediation-workspace-actions,
                     .td-remediation-workspace-name,
                     .td-remediation-archival-statistics {
                        background-color: rgb(246, 246, 246);
                     }
                  }
               }
               .remediation-step-2 {
                  .td-remediation-group-status,
                  .td-remediation-site-status,
                  .td-remediation-archival-date,
                  .td-remediation-restoreable {
                     background-color: white;
                  }
                  .no-policy {
                     .td-remediation-group-status,
                     .td-remediation-site-status,
                     .td-remediation-archival-date,
                     .td-remediation-restoreable {
                        background-color: rgb(246, 246, 246);
                     }
                  }
               }
               .remediation-step-3 {
                  .td-remediation-policy,
                  .td-remediation-policy-action {
                     background-color: white;
                  }
                  .no-policy {
                     .td-remediation-policy,
                     .td-remediation-policy-action {
                        background-color: rgb(246, 246, 246);
                     }
                  }

                  .form-dropdown .dd-header {
                     cursor: pointer;
                  }
               }
               .remediation-step-4 {
                  .td-remediation-archival-statistics,
                  .td-remediation-archival-status,
                  .td-remediation-archival-actions,
                  .td-remediation-archival-eligibility,
                  .td-remediation-archival-execution-update-status,
                  .td-remediation-archival-execution-executed {
                     background-color: white;
                  }
                  .no-policy {
                     .td-remediation-archival-statistics,
                     .td-remediation-archival-status,
                     .td-remediation-archival-actions,
                     .td-remediation-archival-eligibility,
                     .td-remediation-archival-execution-update-status,
                     .td-remediation-archival-execution-executed {
                        background-color: rgb(246, 246, 246);
                     }
                  }
               }
            }

            .remediation-status {
               display: block;
               padding: 6px 5px;
               font-size: 12px;
               font-weight: 500;
               // height: 30px;
               border-radius: 4px;
               color: #3a3a3ae9;
               width: 100%;
               text-align: center;
               &.status-color-notfound {
                  background-color: rgb(255, 207, 207);
               }
               &.status-color-notteamorteamsite {
                  background-color: rgb(199, 199, 255);
               }
               &.status-color-active {
                  background-color: rgb(154, 241, 182);
               }
               &.status-color-loading {
                  background-color: rgb(255, 238, 206);
               }
               &.status-color-couldnotrestore {
                  background-color: rgb(255, 214, 255);
               }
               &.status-color-foundinrecyclebin {
                  background-color: rgb(255, 203, 179);
               }
            }
         }
      }
   }
}

.remediate-previous-stats-modal {
   z-index: 1070 !important;

   .policy-information {
      width: 100%;
      td {
         &:first-child {
            color: gray;
            width: 30%;
         }
      }
   }
   h6 {
      margin-top: 20px;
   }
   .library-information {
      width: 100%;
      border-top: 1px solid rgb(227, 227, 227);
      tr {
         &:first-child {
            td {
               padding-top: 10px;
            }
         }
         &:last-child {
            td {
               padding-bottom: 10px;
            }
         }
         &:nth-child(odd) {
            background-color: #f5f5f5;
         }
      }
      td {
         padding: 1px 5px;
         vertical-align: text-top;
         &:first-child {
            color: gray;
            width: 30%;
         }
         ul {
            padding: 0;
            margin: 0;
            padding-left: 15px;
         }
         &.url {
            font-size: 11px;
         }
      }
   }
}
.remediate-previous-stats-modal-bg {
   z-index: 1060 !important;
}
