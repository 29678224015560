@import "app/assets/common/bootstrap-media-mixins";
.lifecycle-tabs {
   .skeleton-nav-item {
      margin: 15px 30px 15px 0;
   }
   .nav-tabs.nav-tabs-line .nav-item {
      // margin-bottom: -5px;
      @include media-breakpoint-up(md) {
         margin-right: 25px;
      }
      a {
         i {
            position: relative;
            top: -2px;
            font-size: 14px;
            opacity: 0.5;
         }
         span {
            font-size: 14px;
            font-weight: 500;
            opacity: 0.7;
            transition: all 0.3s;
         }
         &:hover,
         &.active {
            span {
               opacity: 1;
            }
         }
         .error-count {
            display: inline-block;
            background-color: rgb(255, 227, 151);
            padding: 1px 7px;
            color: rgb(163, 139, 73);
            border-radius: 5px;
            margin-left: 10px;
            position: relative;
            i {
               color: rgb(163, 139, 73);
               opacity: 1;
               font-size: 10px;
            }
            .errors {
               position: absolute;
               top: calc(100% + 5px);
               background-color: white;
               z-index: 9;
               width: 250px;
               padding: 15px 15px 15px 30px;
               box-shadow: 0 2px 2px #00000012;
               border: 1px solid #e6e6e6;
               left: -15px;
               display: none;
               .error {
               }
            }
            &:hover {
               .errors {
                  display: block;
               }
            }
         }
      }
   }

   .standalone-checkbox {
      background-color: whitesmoke;
      padding: 0.75rem;
      padding-right: 3rem;
      border-radius: 0.25rem;
      // display: inline-block;
      font-weight: 600;
      position: relative;
      .form-toggle-control,
      .form-orch-toggle {
         margin-bottom: 0;
      }
      .form-check {
         label {
            margin-left: 10px;
            color: #676767;
            cursor: pointer;
         }
         label:before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 18px;
            height: 18px;
            // background-color: rgb(66, 223, 149);
            // border:1px solid #28B976;
            background-color: white;
            border: 1px solid #99a1a7;
            border-radius: 0.25rem;
            left: 0;
            top: 1px;
         }
         input:checked + label:before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 18px;
            height: 18px;
            // background-color: #28B976;
            background-color: white;
            border-radius: 0.25rem;
            left: 0;
            top: 1px;
         }
         input:checked + label:after {
            // content: '\2714';
            content: "";
            font-size: 14px;
            position: absolute;
            z-index: 2;
            top: 5px;
            left: 3px;
            color: #99a1a7;
            transform: rotate(-45deg) scale(0.8);
            // border-bottom: solid 3px white;
            // border-left: solid 3px white;
            border-bottom: solid 3px #c2c2c2;
            border-left: solid 3px #c2c2c2;
            background: none;
            height: 8px;
            width: 13px;
         }
      }
      .info-circle {
         position: absolute;
         right: 0.75rem;
         top: 50%;
         margin-top: -9px;
         // top:1rem;
      }
   }
   .form-orch-toggle .form-control.form-toggle-control {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: calc(1rem + 55px);
      border: 0px solid transparent;
      background-color: transparent;
      .form-toggle {
         left: 0;
      }
      span {
         font-weight: 500;
      }
   }
   h2 {
      border-bottom: 1px solid #e5e5e7;
      margin-bottom: 15px;
      padding-bottom: 5px;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.222727px;
      color: #414141;
      font-weight: 600;
      padding-top: 30px;
   }
   .row:first-child {
      h2 {
         padding-top: 0;
      }
   }
   h3 {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.222727px;
      color: #767677;
      font-weight: 500;
      margin-bottom: 10px;
   }
   .tg-right {
      @include media-breakpoint-down(md) {
         h3 {
            margin-top: 30px;
         }
      }
   }

   .form-group-input {
      margin-top: 15px;

      @include media-breakpoint-up(md) {
         max-width: 50%;
      }

      input {
         @include media-breakpoint-up(md) {
            width: 50%;
         }
      }
   }
   h5 {
      font-weight: 600;
   }

   #template-new-tabs-tabpane-security {
      .form-group {
         .form-check {
            // display: inline-block;
         }
         &.radio-green {
            .form-check {
               margin-bottom: 10px;
            }
            margin-bottom: 0;
         }
      }
   }
   .kt-portlet {
      .kt-portlet__body {
         @include media-breakpoint-up(md) {
            padding-top: 3px;
         }
         .tab-content {
            // padding-left: 10px;
            > div {
               .button-custom {
                  margin-top: 20px;
               }
            }
         }
      }
   }

   .lock-alert {
      font-size: 13px;
      p {
         margin-top: 4px;
         font-weight: 300;
         margin-bottom: 5px;
      }
      ul {
         margin: 0;
         padding-left: 15px;
         // list-style: none;
         li {
            padding: 0;
            // list-style: none;
         }
      }
   }

   &.is-locked {
      .tab-content {
         position: relative;
         &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 998;
            background-color: rgba(255, 255, 255, 0.5);
         }
      }
   }
}
