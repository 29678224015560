.naming-policy-details {
   .naming-loading {
      text-align: center;
      margin-top: 50px;
   }

   .at-search {
      margin-bottom: 20px;
   }
   .at-count {
      margin-bottom: 10px;
   }
   .at-items {
      background-color: white;
      // padding: 0 15px;
      border-radius: 4px;
      .selected-items li {
         font-weight: 600;
         padding: 12px 0 11px 0;
      }
      .no-templates {
         padding: 15px 0;
         .help-text {
            margin-bottom: 10px;
         }
      }
   }

   .no-results {
      // margin: 0 0 25px 0;
   }

   .title-check {
      label {
         font-size: 14px;
         font-weight: 500;
      }
   }

   .delimiter-options {
      margin-top: 20px;
      margin-bottom: 20px;
      .form-group {
         margin-bottom: 10px;
         .form-label {
            font-weight: 500;
         }
      }
   }
   .fix-editor {
      &.disabled {
         opacity: 0.4;
      }

      .fix-item {
         position: relative;
         padding: 10px 0;
         &:before {
            position: absolute;
            content: "";
            border-bottom: 1px solid #d8d8db;
            left: 15px;
            right: 15px;
            bottom: 0;
         }
         &:last-child {
            &:before {
               display: none;
            }
         }
         .fix-left {
            padding-left: 50px;
            position: relative;
            .count {
               position: absolute;
               left: 15px;
               top: 8px;
               font-size: 12px;
               //margin-top: -11px;
               width: 24px;
               height: 24px;
               min-width: 24px;
               display: inline-block;
               background-color: #8585ff;
               color: white;
               font-weight: 700;
               line-height: 23px;
               padding-left: 0;
               padding-right: 0;
               text-align: center;
               border-radius: 5px;
            }
            .fix-type {
            }
            .dd-wrapper .dd-header {
               transition: all 0.5s;
            }
         }
         .fix-right {
            position: relative;
            padding-right: 60px;
            .remove {
               position: absolute;
               right: 15px;
               top: 50%;
               margin-top: -9px;
               opacity: 0.3;
               svg {
                  height: 18px;
                  width: 18px;
                  color: gray;
               }
            }
            .string-value {
            }
            .red-outline {
               .form-control {
                  border: 1px solid red !important;
               }
            }
         }
         &:hover {
            .remove {
               opacity: 0.7;
               cursor: pointer;
            }
         }
         &.editable-item {
            // .fix-left {
            //    &:not(:hover){
            //          .dd-wrapper:not(.open) {
            //             .dd-header {
            //                background-color: transparent;
            //                border:1px solid transparent;
            //                .arrow {
            //                      opacity: 0;
            //                }
            //             }
            //          }
            //    }
            // }
            // .fix-right {
            //    &:not(:hover){
            //          .dd-wrapper {
            //             .dd-header {
            //                background-color: transparent;
            //                border:1px solid transparent;
            //                .arrow {
            //                      opacity: 0;
            //                }
            //             }
            //          }
            //          input:not(:focus) {
            //             background-color: transparent;
            //             border:1px solid transparent;
            //          }
            //    }
            // }
         }
      }
   }
   .callout {
      background-color: #fff7d0;
      border-left: 4px solid transparent;
      border-left-color: #ffe564;
      padding: 1rem 1rem 1rem calc(1rem - 4px);
      // margin-left: -1rem;
      margin-bottom: 2rem;
      p {
         margin-bottom: 10px;
      }
      .example {
         font-weight: 600;
         font-size: 16px;
         .chev {
            opacity: 0.5;
         }
         .fix {
            color: rgb(102, 122, 168);
            font-weight: 400;
         }
      }
   }
   .toggle {
      vertical-align: top;
      > label.form-label {
         font-size: 14px;
         font-weight: 500;
         padding: 1px 0 0 10px;
         margin-bottom: 0;
         // line-height: 20px;
      }
      > * {
         vertical-align: top;
      }
   }
   .add-checkbox-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      > * {
         flex: 1;
      }
      .form-group {
         margin: 0;
      }
      .info-right {
         .portlet-alert {
            margin-left: 10px;
            margin-right: 50px;
         }
         .alert {
            margin: 0;
         }
      }
   }
   .counter-options {
      .counter-description {
         font-weight: 500;
         font-size: 14px;
         margin-bottom: 15px;
      }
      .table-list-table {
         // margin: 0 -0.75rem;
         margin-bottom: 25px;
         th {
            padding-left: 0;
         }
         td.no-results-td {
            padding-top: 15px;
         }
      }
   }
   .counter-alert {
      .alert {
         margin-bottom: 0;
      }
   }
   .counter-value-editor {
      display: flex;
      align-items: center;
      input,
      div {
         flex: 1;
      }
      div {
         padding-left: 20px;
      }
   }
   .counter-title-col {
      .name {
         font-weight: 500;
      }
      .description {
         font-size: 11px;
         font-weight: 500;
         opacity: 0.6;
      }
   }
   .character-limit {
      display: flex;
      flex-direction: column;
      .character-limit-description {
         font-weight: 500;
         font-size: 14px;
         margin-bottom: 15px;
      }
      .check-group {
         display: flex;
         align-items: center;
         label {
            margin: 0;
         }
         .form-label {
            padding-left: 10px;
         }
      }
      .character-number-group {
         display: flex;
         align-items: center;
         .info-tooltip-wrapper {
            font-size: 12px;
            margin-left: 0.75rem;
            margin-bottom: 6.5px;
         }
      }
   }
}
