.document-template-preview {

    .loader.preview {
        text-align: center;
        .animated-loader{
            margin: 50px auto;
        }
    }


    .thumbnail {
        border:1px solid rgb(206, 206, 206);
        border-radius: 5px;
        overflow: hidden;
        img {
                max-width: 100%;
        }
    }
    .no-thumbnail {
        
        .large-preview-icon {
            border:1px solid rgb(206, 206, 206);
            border-radius: 5px;
            position: relative;
            background-color: rgba(0, 0, 255, 0.0509804);

            .doc-icon {
                text-align: center;
                padding: 20px 0;
                font-size: 60px;
                // background-color: rgba(0, 0, 255, 0.050980392156862744);
                i {
                    color:rgba(0, 0, 0, 0.212);
                }
                img[src*=".svg"]{
                    max-height: 50px;
                }
            }
            .no-preview {
                // position: absolute;
                margin-left:10px;
                margin-bottom: 10px;
                margin-right:50px;
                font-size: 13px;
            }
        }
    }

    .file-type-icon-width-text {
        margin-top: 10px;
        font-size:15px;
        .doc-icon {
            display: inline-block;
            i {
                margin-right: 10px;
                font-size:20px;
                position: relative;
                bottom: -1px;
            }
            img {
                max-width: 20px;
                max-height: 18px;
                margin-right: 10px;
            }
            img[src*=".svg"] {
                width: 20px;
                height: 18px;
            }
        }
        span {
            color:#434349;
        }
        .type-image {
            position: relative;
            top:-1px;
        }
    }
    .file-name {
        font-size:16px;
        font-weight: 600;
        margin-top: 10px;
        color:#434349;
    }
    .download-button {
      position: absolute;
      bottom: 10px;
      right: 60px;
      font-size: 16px;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 38px;
      border-radius: 6px;
      color: white;
      background-color: white;
      box-shadow: 0px 0px 2px 2px #0000002b;
      cursor: pointer;
      i {
         color:rgb(102, 102, 200);
      }
    }
    .remove-button-wrapper {
        position: relative;
        .remove-button {
            position: absolute;
            bottom: 10px;
            right:10px;
            font-size:16px;
            width:35px;
            height: 35px;
            text-align: center;
            line-height: 38px;
            border-radius: 6px;
            color: white;
            background-color: white;
            box-shadow: 0px 0px 2px 2px #0000002b;
            cursor: pointer;
            i {
                
                color: rgb(209, 105, 105);
            }
        }
    }
}