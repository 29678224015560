.health-check-growth-donut-chart-tile {
   .mantine-DonutChart-label {
      font-size: 20px;
      font-weight: 500;
   }

   svg {
      height: 180px;
   }
}
