.template-tab-configuration {
   h2 {
      margin-bottom: 5px;
   }
   .content-well {
      //  border:1px solid rgb(228, 228, 228);
      // background-color: whitesmoke;
      border-radius: 4px;
      // padding: 15px;
      margin-bottom: 40px;
      .disable-text {
         margin-bottom: 15px;
      }
   }
   .subheader {
      padding-bottom: 15px;
      font-weight: 500;
      color: #414141;
   }
   .min-users-control {
      display: flex;
      > div {
         flex: 1 1;
         &.min-users-left {
            padding-right: 10px;
         }
         &.min-users-right {
            padding-left: 10px;
         }
      }
   }
   .extra-margin-beteen-md-6 {
      > .col-md-6 {
         &:first-child {
            padding-right: 20px;
         }
         &:last-child {
            padding-left: 20px;
         }
      }
   }
   .checkbox-green {
      margin-bottom: 0.5rem;
   }
   label {
      a {
         margin-left: 6px;
         i {
            font-size: 10px;
         }
      }
   }
   .hr {
      hr {
      }
   }
   .metadata-config-dropdown {
      margin-bottom: 1rem;
   }

   .viva-naming-warning {
      margin-top: 1rem;
   }
}
