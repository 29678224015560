.orchestry-plus-circle {
   background: var(--mantine-color-primary-8) !important;
   display: flex;
   padding: 2px 8px;
   justify-content: center;
   align-items: center;
   border-radius: 16px;
   width: 20px;
   height: 20px;
   transition: color 200ms ease-in;
}
