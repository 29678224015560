.chrome-request-workspace {
   transition: all 0.3s;
   padding-left: 10px;
   max-width: 300px;
   overflow: hidden;

   &.hide-button {
      max-width: 0;
      padding-left: 0;
   }
   .request-button {
      background: #007989;
      border-radius: 4px;
      line-height: 32px;
      font-weight: 500;
      padding: 0 15px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
         background: #006b79;
      }
      span {
         color: white;
         white-space: nowrap;
      }
   }
   .workspace-request-fixed-modal {
      background-color: white;
      border-radius: 2px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      // bottom:100%;
      bottom: 0;
      z-index: 1000;
      transition: all 0.3s;
      // overflow: hidden;
      // &.open {
      //    bottom: 0;
      // }
      .close-button {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 30px;
         border-radius: 3px;
         text-align: center;
         transition: all 0.3s;
         cursor: pointer;
         z-index: 1001;
         &:hover {
            background-color: rgb(241, 241, 241);
         }
         i {
            margin: 0 auto;
            font-size: 20px;
            line-height: 31px;
            color: #718690;
         }
      }
      .ScrollbarsCustom-TrackY {
         top: 50px !important;
         height: calc(100% - 70px) !important;
      }
   }
}
