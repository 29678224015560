@import "app/assets/common/bootstrap-media-mixins";

.team-information-user-summary {
   .verticle-section-title {
      display: none;
   }

   @include media-breakpoint-down(xs) {
      .kt-portlet {
         border-radius: 10px;
         border: 1px solid #e1e1e1;
         box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
      }

      .kt-portlet .kt-portlet__head {
         display: none;
      }

      .kt-portlet .kt-portlet__body {
         padding-top: 6px;
      }

      .verticle-section-title {
         display: inline;
         font-size: 18px;
         font-family: "TT Norms";
         font-weight: 500;
         color: #232323;
         margin-bottom: 10px;
      }
   }

   .count-toolbar {
      display: flex;
      align-items: center;
      .full-count {
         background-color: rgba(0, 0, 255, 0.398);
         border-radius: 4px;
         margin-left: 8px;
         font-size: 12px;
         font-weight: 500;
         padding: 3px 10px;
         .number {
            color: white;
         }
      }
   }
   .summary-bars {
      .info-bar {
         display: flex;
         align-items: center;
         margin-bottom: 15px;
         &:last-child {
            margin-bottom: 0;
         }
         .icon {
            width: 40px;
            height: 40px;
            background-color: blue;
            text-align: center;
            background-color: #ffe9ee;
            border-radius: 4px;
            i {
               font-size: 16px;
               line-height: 40px;
            }
         }
         .right-side {
            flex: 1;
            margin-left: 15px;
            .top {
               display: flex;
               width: 100%;
               .title {
                  flex: 1;
                  // min-width: 100%;
                  font-size: 14px;
                  font-weight: 500;
                  color: #242424;
               }
               .percentage {
                  font-size: 14px;
                  color: #8083a3;
               }
               .count {
                  font-size: 14px;
                  font-weight: 600;
                  border-left: 1px solid #f5f5fa;
                  padding-left: 10px;
                  margin-left: 10px;
                  color: #242424;
               }
            }
            .bottom {
               padding-top: 6px;
               .full-bar {
                  height: 6px;
                  border-radius: 4px;
                  .percentage-bar {
                     background-color: #fe93a8;
                     height: 6px;
                     border-radius: 4px;
                  }
               }
            }
         }
         &.owners {
            .icon {
               background-color: #ffe9ee;
               color: #fe93a8;
            }
            .right-side {
               .bottom {
                  .full-bar {
                     background-color: #ffe9ee;
                     .percentage-bar {
                        background-color: #fe93a8;
                     }
                  }
               }
            }
         }
         &.members {
            .icon {
               background-color: #fdede6;
               color: #ef8354;
            }
            .right-side {
               .bottom {
                  .full-bar {
                     background-color: #fdede6;
                     .percentage-bar {
                        background-color: #ef8354;
                     }
                  }
               }
            }
         }
         &.guests {
            .icon {
               background-color: #f4f9e7;
               color: #a1cd3a;
            }
            .right-side {
               .bottom {
                  .full-bar {
                     background-color: #f4f9e7;
                     .percentage-bar {
                        background-color: #a1cd3a;
                     }
                  }
               }
            }
         }
      }
   }
}
