.library-template-browser-modal {
   z-index: 1040 !important;

   .modal-90w {
      width: 80%;
      max-width: none !important;
      .modal-title {
         i {
            margin-right: 10px;
            font-size: 20px;
            position: relative;
            bottom: -3px;
            opacity: 0.7;
            color: rgb(10, 187, 135);
         }
      }
      .modal-body {
         background: #f2f3f8;
         .kt-widget19__wrapper {
            margin-bottom: 0;
         }
         .search-interface {
            .interface-top {
               padding-bottom: 25px;
               .searchBox {
                  position: relative;
                  input {
                     width: 100%;
                  }
                  .icon-search {
                     position: absolute;
                     // top:0;
                     right: 10px;
                     left: auto;
                  }
               }
               .form-dropdown {
                  height: 44px;
                  .form-control {
                     height: 44px;
                     .dd-header-title {
                        height: 44px;
                        line-height: 26px;
                     }
                  }
                  .dd-list {
                     z-index: 999;
                  }
               }
            }
            .kt-portlet__body:not(.kt-portlet__body--unfill) {
               position: relative;
               padding: 15px;
               padding-bottom: 80px;
            }
            .preview-image {
               background-size: cover;
               background-position: center;
               border-radius: 5px 5px 0 0;
            }
            .kt-widget__footer {
               display: flex;
               align-items: center;
               position: absolute;
               bottom: 15px;
               .btn {
                  display: inline-block;
                  padding: 0.65rem 1rem;
                  width: auto !important;
                  margin-right: 15px;
                  font-size: 12px;
                  &:last-child {
                     margin-right: 0;
                  }
                  i {
                     font-size: 1.1em;
                  }
               }
            }
         }
      }
   }
   .already-installed {
      width: 0px;
      height: 0px;
      border-left: 60px solid transparent;
      border-right: 60px solid transparent;
      border-bottom: 60px solid #3369a7;
      transform: rotate(45deg);
      // border-radius: 5px;
      right: -29px;
      top: -9px;
      position: absolute;
      z-index: 99;
      cursor: default;
      &:before {
         content: "Installed";
         text-align: center;
         color: white;
         position: absolute;
         left: -27px;
         top: 36px;
         text-transform: uppercase;
         font-weight: 600;
         font-size: 11px;
      }
      // &:after {
      //     content: "\f00c";
      //     font-family: "Font Awesome 5 Free";
      //     font-weight: 900;
      //     color:white;
      // }
   }
   .no-results {
      text-align: center;
      margin: auto;
      padding: 200px;
   }

   .loader {
      margin: 50px 0;
      text-align: center;
      flex: 1;
      > div {
         margin: 0 auto;
      }
   }
}

.new-template-copy-name-modal-bg {
   z-index: 1050 !important;
}

.new-template-copy-name-modal-contents {
   .form-validation {
      .form-elements {
         display: flex;
         gap: 1rem;
         .name-input {
            flex-grow: 1;
         }
         .copying-button-with-loader {
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            gap: 0.6rem;
            flex-shrink: 0;
            cursor: default;
            &:hover {
               background-color: #0abb87 !important;
               cursor: default;
               border: 0px solid none;
            }
            .animated-loader {
               zoom: 0.7;
            }
         }
         .name-add-button {
            display: flex !important;
            align-items: center;
         }
      }
   }
}

.details-modal {
   .details-box-modal {
      .kt-portlet__body {
         padding-bottom: 5px;
      }
      .kt-portlet {
         margin-bottom: 0;
      }
      .kt-widget19__pic {
         background-position: center center;
      }

      .kt-widget.kt-widget--user-profile-2 .kt-widget__footer {
         display: flex;
         align-items: center;
         text-align: right;
         .btn {
            padding: 0.7rem 1rem;
            // padding-right: 1.5rem !important;
            display: inline-block;
            width: auto !important;
            margin-left: 15px;
         }
      }
      .kt-widget__section {
         margin-bottom: 15px;
         padding: 0 !important;
         margin-top: 0;
      }
      h3 {
         margin-bottom: 15px;
      }
      h4 {
         font-size: 1.1rem;
         color: #48465b;
         font-weight: 600;
      }
      hr {
         margin: 20px 0;
      }
      .features {
         .feature-item {
            padding: 7px 0;
            position: relative;
            display: flex;
            align-items: center;
            svg {
               position: absolute;
               top: 50%;
               margin-top: -6px;
            }
            i {
               //   position: absolute;
               //   top:50%;
               //   margin-top: -9px;
               font-size: 18px;
               background-color: rgb(231, 231, 231);
               border-radius: 50%;
               height: 26px;
               width: 26px;
               line-height: 26px;
               text-align: center;
            }
            .feat-title {
               margin-left: 15px;
               font-weight: 500;
            }
         }
      }
      .channels {
         .channel-item {
            padding: 7px 0;
            position: relative;
            display: flex;
            align-items: center;
            .profile-picture {
               //   position: absolute;
               //   top:50%;
               //   margin-top: -12px;
               span {
                  line-height: 26px;
               }
            }
            .channel-title {
               flex: 1;
               margin-left: 15px;
               font-weight: 500;
            }
         }
      }
      .thumbnails {
         display: flex;
         padding: 5px;
         .thumb {
            margin: 5px;
            min-height: 75px;
            flex-grow: 1;
            background-position: center center;
            background-size: cover;
            border-radius: 3px;
            box-shadow: inset 0 0 0px 2px #00000014;
            transition: all 0.5s;
            cursor: pointer;
            &:hover {
               box-shadow: inset 0 0 0px 5px #00000075;
            }
         }
      }
   }
   .already-installed {
      width: 0px;
      height: 0px;
      border-left: 60px solid transparent;
      border-right: 60px solid transparent;
      border-bottom: 60px solid #3369a7;
      transform: rotate(45deg);
      right: -39px;
      top: -9px;
      position: absolute;
      z-index: 1;
      &:before {
         content: "Installed";
         text-align: center;
         color: white;
         position: absolute;
         right: -28px;
         top: 37px;
         text-transform: uppercase;
         font-weight: 600;
         font-size: 11px;
      }
      // &:after {
      //     content: "\f00c";
      //     font-family: "Font Awesome 5 Free";
      //     font-weight: 900;
      //     color:white;
      // }
   }
}
.library-template-browser-modal,
.details-modal {
   .carousel {
      .carousel-item {
      }
   }

   .preview-image {
   }

   .button-with-icon {
      display: flex !important;
      align-items: center;
   }
}
.new-template-copy-name-modal-contents {
}
