.installer-step-access-code {
   .enter-access-code {
      padding-top: 0px;
      .title {
         font-size: 16px;
         font-style: normal;
         font-weight: 500;
         margin-bottom: 16px;
         color: #222;
         &.select-license-type {
            margin-top: 30px;
         }
      }
      .login-box {
         width: 350px;
         position: relative;
         input {
            width: 350px;
            padding: 4px 12px;
            font-weight: 400;
            line-height: 24px;
            border: 1px solid #e5e7eb;
            border-radius: 4px;
            outline: none;
            color: #111928;
            font-size: 14px;
         }
         .icon {
            position: absolute;
            right: 10px;
            top: 6px;
            font-size: 16px;
            &.yes {
               color: rgba(0, 128, 0, 0.719);
            }
            &.no {
               color: rgba(255, 0, 0, 0.794);
            }
         }
      }
      .access-code-provided-by {
         font-size: 12px;
         font-weight: 500;
         padding-top: 5px;
         color: #595959;
         span {
            font-weight: 500;
         }
      }
   }
   .license-type-selection {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      .license-type {
         cursor: pointer;
         display: flex;
         width: 359.333px;
         padding: 24px 24px 85px 24px;
         flex-direction: column;
         gap: 15px;
         align-items: center;
         align-self: stretch;
         border-radius: 16px;
         border: 1px solid var(--Border, #e5e7eb);
         background: var(--White, #fff);
         box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
         position: relative;
         .skeleton-loader-common {
            width: 100%;
         }
         .license-type-image {
            width: 100%;
            .image {
               width: 100%;
               height: 200px;
               background-size: cover;
               background-position: center;
               border-radius: 8px;
            }
         }
         .license-type-title {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            color: var(#111928);
            font-feature-settings: "liga" off;
            /* Text lg/Regular */
            font-family: Lexend;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 155.556% */
         }
         .license-type-description {
            color: var(--Gray-500, #6b7280);
            font-feature-settings: "liga" off;
            /* Text sm/Light */
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 142.857% */
         }
         .license-type-button {
            position: absolute;
            height: 40px;
            left: 24px;
            right: 24px;
            bottom: 24px;
            display: flex;
            align-self: stretch;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--Primary-50, #e6f2f3);
            background: var(--Primary-50, #e6f2f3);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--Primary-900, #007988);
            text-align: center;
            font-feature-settings: "liga" off;
            /* Text sm/Regular */
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            &:hover {
               background-color: #3394a0;
               color: #f2f9fa;
               border-color: #3394a0;
               cursor: pointer;
            }
         }

         &.disabled {
            cursor: default;
            opacity: 0.5;
            border-color: #3394a0;
            .license-type-button {
               background-color: #3394a0;
               color: #f2f9fa;
               border-color: #3394a0;
               &:hover {
                  cursor: default;
               }
            }
         }

         &.selected {
            opacity: 1;
            border-color: #3394a0;
            .license-type-button {
               background-color: #3394a0;
               color: #f2f9fa;
               border-color: #3394a0;
            }
         }
      }
   }
}
