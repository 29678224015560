@import "app/assets/common/bootstrap-media-mixins";
.lifecycle-details {
   // background-color: white;
   // border-radius: 5px;
   // padding: 0 40px 40px 40px;
   h3 {
      color: #26323a;
      margin-bottom: 20px;
   }

   .tab-content {
      background-color: transparent;
      > div {
         .button-custom {
            margin-top: 20px;
         }
      }
   }

   .nav-portlet {
      .kt-portlet__body {
         padding: 0 25px;
         .nav {
            width: 100%;
         }
         .nav-tabs.nav-tabs-line {
            margin-bottom: 0;
            .nav-item {
               @include media-breakpoint-up(md) {
                  margin-right: 25px;
               }
               a {
                  i {
                     position: relative;
                     top: -2px;
                     font-size: 14px;
                     opacity: 0.5;
                  }
                  span {
                     font-size: 14px;
                     font-weight: 500;
                     opacity: 0.7;
                     transition: all 0.3s;
                  }
                  &:hover,
                  &.active {
                     span {
                        opacity: 1;
                     }
                  }
               }
            }
         }
      }

      .last-crawl {
         margin-left: auto;

         .last-crawled-inputgroup {
            margin-top: 3px;

            .input-group-append:hover {
               cursor: pointer;
            }
            .input-group-append.disabled:hover {
               cursor: auto;
            }
            .input-group-append.toggled:hover {
               cursor: auto;
            }

            .input-group-text {
               margin-top: 2px;
               height: calc(1.5em + 1.3rem - 2px);
               color: #595d6e;
               background-color: #e2e5ec;

               .disabled {
                  color: #cccdd2;
               }

               .toggled {
                  color: #5867dd;
               }
               .toggled:hover {
                  cursor: auto;
               }

               i {
                  color: #595d6e;
               }
            }

            .input-group-append {
               margin-left: -3px;

               .info-tooltip-wrapper {
                  &:first-child {
                     .input-group-text {
                        margin-left: 0;
                        border-radius: 0 !important;
                     }
                  }
                  &:last-child {
                     .input-group-text {
                        margin-left: 0;
                        border-radius: 0 4px 4px 0;
                     }
                  }
                  .input-group-text {
                     transition: all 0.3s;
                     cursor: pointer;
                     &:hover {
                        background-color: #e2e5ec !important;
                     }
                  }
               }
            }
         }
         .last-crawled-textbox {
            width: 200px;
            height: calc(1.5em + 1.3rem - 2px);
            margin-top: 2px;
            border-color: #e2e5ec;

            .disabled {
               border-color: #e2e5ec;
            }
         }
      }
   }

   .box-with-border {
      background-color: white;
      border: 1px solid #e1e7ee;
      border-radius: 5px;
   }
}

.workspace-history-modal {
   .history-portlet {
      .crawl-history {
         .crawl-date {
            font-weight: 500;
            margin-bottom: 5px;
            color: #26323a;
         }
         .crawl-plans {
            margin-left: -10px;
            margin-bottom: 10px;
            .plan {
               display: inline-block;
               padding: 4px 10px;
               background-color: rgb(231, 231, 231);
               border-radius: 4px;
               margin-left: 10px;
               margin-bottom: 10px;
               color: black;
               font-weight: 500;
            }
         }
      }
   }
}
