.settings-naming-conventions {
   .area {
      padding-top: 20px;
   }
   //  h3 {
   //      margin-top: 30px;
   //      margin-bottom: 15px;
   //      font-size: 20px !important;
   //      &:first-child {
   //          margin-top: 0;
   //      }
   //  }
   .delimiter-options {
      margin-top: 20px;
      margin-bottom: 20px;
   }
   .fix-editor {
      &.disabled {
         opacity: 0.4;
      }

      .fix-item {
         position: relative;
         padding: 10px 0;
         &:before {
            position: absolute;
            content: "";
            border-bottom: 1px solid #d8d8db;
            left: 15px;
            right: 15px;
            bottom: 0;
         }
         &:last-child {
            &:before {
               display: none;
            }
         }
         .fix-left {
            padding-left: 50px;
            position: relative;
            .count {
               position: absolute;
               left: 15px;
               top: 50%;
               font-size: 12px;
               font-weight: 600;
               opacity: 0.6;
               margin-top: -10px;
            }
            .fix-type {
            }
            .dd-wrapper .dd-header {
               transition: all 0.5s;
            }
         }
         .fix-right {
            position: relative;
            padding-right: 60px;
            .remove {
               position: absolute;
               right: 15px;
               top: 50%;
               margin-top: -9px;
               opacity: 0;
               svg {
                  height: 18px;
                  width: 18px;
                  color: gray;
               }
            }
            .string-value {
            }
         }
         &:hover {
            .remove {
               opacity: 0.5;
               cursor: pointer;
            }
         }
         &.editable-item {
            .fix-left {
               &:not(:hover) {
                  .dd-wrapper:not(.open) {
                     .dd-header {
                        background-color: transparent;
                        border: 1px solid transparent;
                        .arrow {
                           opacity: 0;
                        }
                     }
                  }
               }
            }
            .fix-right {
               &:not(:hover) {
                  .dd-wrapper {
                     .dd-header {
                        background-color: transparent;
                        border: 1px solid transparent;
                        .arrow {
                           opacity: 0;
                        }
                     }
                  }
                  input:not(:focus) {
                     background-color: transparent;
                     border: 1px solid transparent;
                  }
               }
            }
         }
      }
   }
   .blocked-words-form {
      .blocked-words {
         .react-tagsinput {
            padding-top: 10px;
            padding-left: 10px;
            padding-bottom: 5px;
            padding-right: 10px;
            transition: all 0.3s;
            border-radius: 5px;
            border: 1px solid #ced4da;
            &.react-tagsinput--focused {
               border: 1px solid #28b976;
               box-sizing: border-box;
               box-shadow: 0px 3px 3px rgba(40, 185, 118, 0.269968);
               border-radius: 5px;
            }
            .react-tagsinput-input {
               font-family: "TT Norms";
               font-size: 14px;
               font-weight: 500;
            }
            .react-tagsinput-tag {
               background: rgba(181, 181, 181, 0.25);
               border: 1px solid transparent;
               color: #414141;
               font-size: 14px;
               font-family: "TT Norms";
               font-weight: 500;
               position: relative;
               padding-right: 20px;
               .react-tagsinput-remove {
                  position: absolute;
                  right: 5px;
                  top: 50%;
                  margin-top: -5px;
                  line-height: 12px;
                  opacity: 0.5;
                  &:before {
                     font-size: 18px;
                     line-height: 10px;
                  }
               }
               &:hover {
                  .react-tagsinput-remove {
                     opacity: 1;
                  }
               }
            }
         }
      }
      .blocked-help {
         margin-top: 5px;
         font-size: 11px;
         color: #676767;
      }
   }
   .area-disabled {
      opacity: 0.4;
      // position: relative;
   }
   .callout {
      background-color: #fff7d0;
      border-left: 4px solid transparent;
      border-left-color: #ffe564;
      padding: 1rem 1rem 1rem calc(1rem - 4px);
      // margin-left: -1rem;
      margin-bottom: 2rem;
      p {
         margin-bottom: 10px;
      }
      .example {
         font-weight: 600;
         font-size: 16px;
         .chev {
            opacity: 0.5;
         }
         .fix {
            color: rgb(102, 122, 168);
            font-weight: 400;
         }
      }
   }
}
