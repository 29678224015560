@import "app/assets/common/bootstrap-media-mixins";
.feature-details {
   .right-col {
      padding-top: 30px;
   }
   h3 {
      margin-top: 20px;
      margin-bottom: 20px;
   }

   .at-search {
      margin-bottom: 20px;
   }
   .at-count {
      margin-bottom: 10px;
   }
   .at-items {
      background-color: white;
      padding: 0 15px;
      border-radius: 4px;
      .selected-items li {
         font-weight: 600;
         padding: 12px 0 11px 0;
      }
      .no-templates {
         padding: 15px 0;
         .help-text {
            margin-bottom: 10px;
         }
      }
   }
   .kt-portlet__body {
      padding-top: 6px !important;
   }
   .nav-item {
      a {
         span {
            font-size: 16px;
         }
      }
   }

   .nav-tabs.nav-tabs-line .nav-item {
      // margin-bottom: -5px;
      @include media-breakpoint-up(md) {
         margin-right: 25px;
      }
      a {
         i {
            position: relative;
            top: -2px;
            font-size: 14px;
            opacity: 0.5;
         }
         span {
            font-size: 14px;
            font-weight: 500;
            opacity: 0.7;
            transition: all 0.3s;
         }
         &:hover,
         &.active {
            span {
               opacity: 1;
            }
         }
         // .error-count {
         //    display: inline-block;
         //    background-color: rgb(255, 227, 151);
         //    padding: 1px 7px;
         //    color: rgb(163, 139, 73);
         //    border-radius: 5px;
         //    margin-left: 10px;
         //    position: relative;
         //    i {
         //       color: rgb(163, 139, 73);
         //       opacity: 1;
         //       font-size: 10px;
         //    }
         //    .errors {
         //       position: absolute;
         //       top: calc(100% + 5px);
         //       background-color: white;
         //       z-index: 9;
         //       width: 250px;
         //       padding: 15px 15px 15px 30px;
         //       box-shadow: 0 2px 2px #00000012;
         //       border: 1px solid #e6e6e6;
         //       left: -15px;
         //       display: none;
         //       .error {
         //       }
         //    }
         //    &:hover {
         //       .errors {
         //          display: block;
         //       }
         //    }
         // }
      }
   }
   .file-upload {
      max-width: 250px;
      margin-top: 15px;
   }
   .form-check {
      margin-top: 6px;
   }
   .progress {
      margin-top: 30px;
   }
   .upload-options {
      display: flex;
   }
   .loader {
      text-align: center;
   }
   .upload-area {
      display: flex;
      align-items: center;
      .left {
         flex-grow: 1;
      }
      .or {
         padding: 0 15px;
         font-weight: 500;
      }
      .right {
         flex-grow: 1;
         .select-extracted-file {
            border: 1px dashed #b5b5b5;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 20px 30px rgba(0, 69, 117, 0.1);
            padding: 40px 0 60px 0;
            outline: none !important;
            cursor: pointer;
            .icon {
               color: #ffa530;
               width: 28px;
               height: 28px;
               margin: 0 auto;
               i {
                  font-size: 36px;
                  width: 28px;
                  height: 28px;
               }
            }
            .text {
               padding-top: 15px;
               text-align: center;
               font-size: 14px;
               color: #7e7e7e;
               font-weight: 500;
            }
         }
      }
   }
   #feature-tabs-tabpane-configuration {
      label {
         font-weight: 500;
      }
      .pnp-schema {
         font-weight: 500;
         margin-bottom: 20px;
      }
      .customized-alert {
         .alert-icon {
            padding-right: 8px;
         }
         i {
            font-size: 18px;
         }
      }
   }
}
