.recommendation-top-categories-metric-card {
   display: flex;
   flex-direction: column;
   gap: 16px;
   .top-categories-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 12px;
      column-gap: 48px;
      .category-list-item {
         display: flex;
         gap: 24px;
         justify-content: space-between;
         cursor: pointer;
         :first-child {
            user-select: none;
         }
         .category-count {
            margin-left: 16px;
         }
      }
   }
}
