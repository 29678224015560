.animated-loader {
   border: 8px solid #f3f3f3; /* Light grey */
   border-top: 8px solid #3498db; /* Blue */
   border-radius: 50%;
   width: 60px;
   height: 60px;
   animation: spin 2s linear infinite;
   display: inline-block;

   &.al-light {
      border-color: white; /* Light grey */
      border-top-color: #3498db; /* Blue */
   }

   &.al-small {
      border-width: 4px;
      border-top-width: 4px;
      width: 30px;
      height: 30px;
   }

   &.al-xsmall {
      border-width: 4px;
      border-top-width: 4px;
      width: 24px;
      height: 24px;
   }

   &.al-xxsmall {
      border-width: 3px;
      border-top-width: 3px;
      width: 18px;
      height: 18px;
   }

   &.al-large {
   }
}
.animated-loader-wrapper {
   text-align: center;
   .loader-text {
   }
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

.animated-loader-wrapper-square {
   // height: 200px;
   // position: relative;
   z-index: 999;
   .loader-squares {
      .loader {
         height: 128px;
         width: 100%;
         position: relative;
         &.al-large {
            zoom: 1.3;
         }
         &.al-med {
            zoom: 1.0;
         }
         &.al-small {
            zoom: 0.9;
         }
         &.al-xsmall {
            zoom: 0.8;
         }
         &.al-xxsmall {
            zoom: 0.7;
         }
      }
      .loader-text {
         text-align: center;
         padding-top: 20px;
         font-weight: 500;
         font-size: 16px;
      }
      // .loader .l_main{position:absolute;top:50%;left:50%;width:172px;height:128px;margin:0;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}
      .loader .l_main {
         position: absolute;
         top: 50%;
         left: 50%;
         width: 172px;
         height: 128px;
         margin: 0;
         -webkit-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);
      }
      @media (max-width: 550px) {
         .loader {
            -webkit-transform: scale(0.75);
            transform: scale(0.75);
         }
      }
      @media (max-width: 440px) {
         .loader {
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
         }
      }
      .l_square {
         position: relative;
      }
      .l_square:nth-child(1) {
         margin-left: 0px;
      }
      .l_square:nth-child(2) {
         margin-left: 44px;
      }
      .l_square:nth-child(3) {
         margin-left: 88px;
      }
      .l_square:nth-child(4) {
         margin-left: 132px;
      }
      .l_square span {
         position: absolute;
         top: 0px;
         left: 20px;
         height: 36px;
         width: 36px;
         border-radius: 2px;
         background-color: #699FA1;
      }
      .l_square span:nth-child(1) {
         top: 0px;
      }
      .l_square span:nth-child(2) {
         top: 44px;
      }
      .l_square span:nth-child(3) {
         top: 88px;
      }
      .l_square:nth-child(1) span {
         -webkit-animation: animsquare1 2s infinite ease-in;
         animation: animsquare1 2s infinite ease-in;
      }
      .l_square:nth-child(2) span {
         -webkit-animation: animsquare2 2s infinite ease-in;
         animation: animsquare2 2s infinite ease-in;
      }
      .l_square:nth-child(3) span {
         -webkit-animation: animsquare3 2s infinite ease-in;
         animation: animsquare3 2s infinite ease-in;
      }
      .l_square:nth-child(4) span {
         -webkit-animation: animsquare4 2s infinite ease-in;
         animation: animsquare4 2s infinite ease-in;
      }
      .l_square span:nth-child(1) {
         -webkit-animation-delay: 0s;
         animation-delay: 0s;
      }
      .l_square span:nth-child(2) {
         -webkit-animation-delay: 0.15s;
         animation-delay: 0.15s;
      }
      .l_square span:nth-child(3) {
         -webkit-animation-delay: 0.3s;
         animation-delay: 0.3s;
      }
      @-webkit-keyframes animsquare1 {
         0%,
         5%,
         95%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         30%,
         70% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
      @keyframes animsquare1 {
         0%,
         5%,
         95%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         30%,
         70% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
      @-webkit-keyframes animsquare2 {
         0%,
         10%,
         90%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         35%,
         65% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
      @keyframes animsquare2 {
         0%,
         10%,
         90%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         35%,
         65% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
      @-webkit-keyframes animsquare3 {
         0%,
         15%,
         85%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         40%,
         60% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
      @keyframes animsquare3 {
         0%,
         15%,
         85%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         40%,
         60% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
      @-webkit-keyframes animsquare4 {
         0%,
         20%,
         80%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         45%,
         55% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
      @keyframes animsquare4 {
         0%,
         20%,
         80%,
         100% {
            -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
         }
         45%,
         55% {
            -webkit-transform: translate(-40px, 0px) rotate(-90deg);
            transform: translate(-40px, 0px) rotate(-90deg);
         }
      }
   }
}
