.popover-hover {
   display: inline-block;
}

.office-profile-popover {
   // position: relative;
   // padding-top: 10px;
   height: 0px;
   .popover-wrapper {
      // padding-top: 10px;
      position: fixed;
      z-index: 99999;
      top:calc(100% + 5px);
      background: #FFFFFF;
      box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05);
      border:2px solid #dbdbdb;
      border-radius: 4px;
      width:400px;
      text-align: left;
      transition: all 0.3;
      &:before {
         content: "";
         width:100%;
         height: 25px;
         // background-color: yellow;
         position: absolute;
         top:-25px;
         left:0;
      }
      &:after {
         content: "";
         width:100%;
         height: 25px;
         // background-color: yellow;
         position: absolute;
         bottom:-25px;
         left:0;
      }
      .popover-contents {
         .skeleton {
            display: flex;
            padding: 15px;
            display: none;
            &.show-loading {
               display: flex;
            }
            .left {
               .circle {
                  height: 48px;
                  background: rgba(238, 241, 245, 0.75);
                  width: 48px;
                  border-radius: 50%;
               }
            }
            .right {
               flex: 1;
               padding-left: 15px;
               .title {
                  border-radius: 5px;
                  height: 21px;
                  background: rgba(238, 241, 245, 0.75);
                  margin-bottom: 10px;
                  width: 100%;
               }
               .position {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 17px;
                  width: 100%;
               }
            }
         }
         .animated-wave {
            overflow: hidden;
            position: relative;
            &:before {
               content: '';
               display: block;
               position: absolute;
               left: -150px;
               top: 0;
               height: 100%;
               width: 150px;
               background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
               animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1);
               animation-iteration-count: infinite;
           }
           @keyframes load {
               from {
                  left: -150px;
               }
               to   {
                  left: 100%;
               }
            }
         }
         .item-info {

            .top-area {
               padding: 15px 15px;
               display: flex;

               // padding-bottom: 6px;
               .left {
                  .pictures {

                  }
               }
               .right {
                  padding-left: 15px;
                  display: flex;
                  .top-right-left {
                     flex:1;
                     width: 215px;
                     .display-name {
                        font-size:16px;
                        font-weight: 600;
                        margin-bottom: 1px;
                        color:#0F1C25;
                     }
                     .job-title {
                        font-size:13px;
                        font-weight: 500;
                        margin-bottom: 4px;
                        // opacity: 0.75;
                        color:#768A94;
                     }
                  }
                  
                  .actions {
                     .icons {
                        // margin-top: 7px;
                        display: flex;
                        height: 25px;
                        overflow: hidden;
                        transition: all 0.3s;
                        justify-content: right;
                        img {
                           max-width: 20px;
                        }
                        > div {
                           margin-right: 15px;
                           cursor: pointer;
                           opacity: 0.8;
                           transition: all 0.3s;
                           position: relative;
                           top:3px;
                           &:hover {
                              opacity: 1;
                              top:0px;
                           }
                        }
                        .chat {

                        }
                        .email {

                        }
                        .call {

                        }
                     }
                  }
               }
            }
            .view-more-bar {
               // margin: 10px 0;
               background-color: #e7e7e7;
               height: 20px;
               // width:calc(100% + 30px);
               width: 100%;
               position: relative;
               // left:-15px;
               // bottom: -15px;
               border-radius: 0 0 3px 3px;
               text-align: center;
               transition: all 0.3s;
               opacity: 1;
               &:before {
                  content:"";
                  position: absolute;
                  bottom: 20px;
                  z-index: 3;
                  border-radius: 0 0 0 4px;
                  left:0;
                  width:4px;
                  height: 8px;
                  background-color: #ffffff;
                  box-shadow: 0 4px #e7e7e7;
                  transition: all 0.3s;
               }
               &:after {
                  content:"";
                  position: absolute;
                  bottom: 20px;
                  z-index: 3;
                  border-radius: 0 0 4px 0;
                  right:0;
                  width:4px;
                  height: 8px;
                  background-color: #ffffff;
                  box-shadow: 0 4px #e7e7e7;
                  transition: all 0.3s;
               }
               i {
                  color:white;
               }
               &:hover {
                  background-color: #cccccc;
                  cursor: pointer;
                  &:before {
                     box-shadow: 0 4px #cccccc;
                  }
                  &:after {
                     box-shadow: 0 4px #cccccc;
                  }
               }
            }
            .more-info {
               border-top:1px solid #e7e7e7;
               padding: 0px 15px 15px 15px;
               > div {
                  display: flex;
                  margin-bottom: 6px;
                  &:last-child {
                     margin-bottom: 0;
                  }
                  .icon {
                     width:30px;
                     img {
                        max-width: 18px;
                     }
                  }
                  .text {
                     font-weight: 500;
                     font-size: 13px;
                     line-height: 23px;
                     color:black;
                  }
               }
            }

            &.more-open {
               .top-area {
                  // background-color: #E1E7EE;
                  border-radius: 4px 4px 0 0;
                  align-items: center;
                  .right {
                     .actions {
                        height: 0;
                     }
                  }
               }
               .view-more-bar {
                  opacity: 0;
                  height: 0;
                  overflow: hidden;
               }
               .more-info {
                  padding-top: 15px;
               }
            }
         }
      }
   }
}