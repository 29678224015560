.recommendation-card-action-button-section {
   display: flex;
   align-items: center;
   margin-top: 32px;
   justify-content: space-between;

   .secondary-section {
      i {
         padding: 5px 7px;
      }
   }

   .primary-section {
      display: flex;
      align-items: center;
      gap: 8px;

      .orchicon-eye,
      .orchicon-list {
         font-size: 16px;
      }

      .orchestry-thumbs {
         // Done so the tooltip looks better
         height: 36px;
         align-items: center;
      }

      .reopen-group {
         display: flex;
         gap: 8px;
         align-items: center;
      }
   }
}
