.guest-request-workflow-column-action {
   display: flex;
   align-items: center;
   .subtitle-button {
      padding: 0.35rem 0.75rem;
      font-size: 12px;
      &.color-outline-green {
         border-color: #0abb87;
         color: #0abb87;
      }
      &.color-outline-red{
         border-color: #fd397a;
         color: #fd397a;
      }
      &:hover {
         &.color-outline-green{
            border-color: #08976d;
            color: #08976d;
         }
         &.color-outline-red{
            border-color: #fc0758;
            color:#fc0758;
         }
      }
   }
   .comment {
      margin-left: 10px;
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.3s;
      &.required-comment {
         color: #fd397a;
         opacity: 1;
      }
      &:hover {
         opacity: 1;
      }
      i {
         font-size: 18px;
         margin-top: 4px;
      }
   }
}
