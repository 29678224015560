.card-reset-workspace-status {
   .table-list {
      table {
         table-layout: fixed;
         th,
         td {
            &:first-child {
               width: 50%;
            }
            &:nth-child(2),
            &:nth-child(3) {
               width: 25%;
            }
         }
      }
   }
   .reset-title {
      display: flex;
      flex-direction: column;
      width: 100%;
      .sharepoint-link {
         text-overflow: ellipsis;
         overflow: hidden;
         white-space: nowrap;
         a {
            font-size: 11px;
            font-weight: 500;
            opacity: 0.8;
            &:hover {
               // color: black;
               text-decoration: underline !important;
            }
         }
      }
   }
   .center-actions {
      text-align: center;
   }
   .resetting-please-wait {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      .text {
         margin-top: 10px;
      }
   }
   .reset-done {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      a {
         margin-bottom: 10px;
      }
      .text {
         // margin-top: 10px;
         margin-bottom: 10px;
      }
      .btn {
      }
   }
}
