.home-admin-directory {
   &.overflow-fix {
      overflow: hidden;
   }

   .directory {
      background-color: transparent;
      .directory-wrapper {
         background-color: white;
         border-radius: 5px;
         padding: 0 15px;
      }
      .fav-title-area {
         margin-top: 15px;
      }
      .directory-filters {
         background-color: white;
         padding-top: 15px;
      }
      .directory-list-table {
         background-color: white;
      }
      .directory-list-cards {
         background-color: white;
      }
      .directory-favorites {
         background-color: white;
      }
      .directory-paging {
         background-color: white;
         margin-bottom: 15px;
      }
   }
}
