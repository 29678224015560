.Toastify {
   /** Used to define container behavior: width, position: fixed etc... **/
   // https://fkhadra.github.io/react-toastify/how-to-style for reference
   .Toastify__toast-container {
      .beacon-toast {
         i {
            font-weight: 400;
         }
         font-family: var(--mantine-font-family);
         .Toastify__toast-body {
            display: flex;
            align-items: center;
            gap: 20px;
         }
         .Toastify__toast-icon {
            margin: 0;
            width: fit-content;
         }
      }
   }
}
