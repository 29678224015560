@import "app/assets/common/bootstrap-media-mixins";
.lifecycle-guest-details-header {
   margin-bottom: 32px;
   h3 {
      // margin-bottom: 20px;
   }

   .overview-content {
      .top-info {
         background-color: #fcfcfd;
         border-radius: 5px 5px 0 0;
         padding: 20px 25px 20px 25px;
         display: flex;
         align-items: center;
         .name-and-logo {
            display: flex;
            flex: 1;
            .logo {
               position: relative;
               top: -4px;
               .skeleton-logo {
                  .fake-box {
                     border-radius: 50%;
                  }
               }
               // width: 64px;
               padding-right: 25px;
               .profile-picture {
                  // height: 60px;
                  // width: 60px;
                  border: 1px solid #e1e7ee;
                  border-radius: 4px;
                  &.size-lg {
                     border-radius: 50%;
                     &.two-letter {
                        span {
                           font-size: 18px;
                        }
                     }
                  }
                  // &.icon {
                  //    line-height: 48px;
                  //    svg {
                  //       height: 20px;
                  //       width: 20px;
                  //    }
                  // }
               }
            }
            .top-stats {
               margin-top: -7px;
               .ts-left {
                  flex: 1;
                  .ws-title {
                     color: #26323a;
                     font-size: 24px;
                     font-weight: 600;
                     margin-bottom: 8px;
                     display: flex;
                     align-items: center;
                     .nested-title {
                        margin-right: 15px;
                     }
                     .actions {
                        // position: absolute;
                        display: inline;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        right: 13px;
                        top: 18px;
                        color: #8083a3;
                        a {
                           // line-height: 18px;
                           color: #8083a3;
                           i {
                              padding-top: 10px;
                           }
                        }
                     }
                  }
                  .ws-description {
                     color: #718690;
                     font-size: 14px;
                     font-weight: 500;
                  }
               }
               .ts-right {
                  padding-left: 20px;
               }
            }
         }
         .activity {
            .guest-status-box {
               display: flex;
               align-items: center;
               border-radius: 4px;
               padding: 6px 20px 6px 20px;
               .left {
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .guest-status-icon {
                     width: 40px;
                     height: 40px;
                     border-radius: 4px;
                     // background-color: #648021bf;
                     background-color: #3a3a3a8c;
                     text-align: center;
                     line-height: 48px;
                     box-shadow: inset 0px 1px 0px #0000001f;
                     i {
                        font-size: 22px;
                        color: white;
                     }
                  }
               }

               .stats {
                  padding-left: 20px;
                  ul {
                     list-style-type: none;
                     margin: 0;
                     padding: 0;
                     padding-bottom: 6px;
                     li {
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                        font-weight: 500;
                        color: #3d4c56;
                        strong {
                           margin-left: 6px;
                        }
                        &.status-title {
                           font-size: 21px;
                           // line-height: 32px;
                           color: #26323a;
                           font-weight: 500;
                        }
                     }
                  }
               }

               &.guest-status-active {
                  background-color: rgba(147, 191, 45, 0.15);
               }
               &.guest-status-pendingacceptance {
                  background-color: #bce8e3;
               }
               &.guest-status-pendingapproval {
                  background-color: rgba(210, 203, 255, 0.88);
                  // border: 1px solid rgba(210, 203, 255, 0.88);
                  // color: #3a3a3ae9;
               }
               &.guest-status-inactive {
                  background-color: rgba(222, 121, 43, 0.35);
                  // border: 1px solid #aee8bf;
                  //color: #b36909;
               }

               &.guest-status-deleted {
                  background-color: #f3eab1;
                  // border: 1px solid #f3eab1;
                  //color: #827940;
               }
            }
         }
      }
      .metadata {
         flex-grow: 1;
         // margin-top: -7px;
         box-shadow: 0 4px 4px -4px #dedede inset;
         padding: 20px 25px;
         .bottom-stats {
            // margin-top: 24px;
            display: grid;
            // grid-auto-flow: column;
            grid-template-columns: repeat(4, minmax(0, 1fr));

            @include media-breakpoint-down(md) {
               grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            grid-gap: 15px;

            .stat {
               flex: 1;
               .stat-title {
                  color: #718690;
                  font-weight: 500;
                  margin-bottom: 0px;
               }
               .stat-content {
                  color: #26323a;
                  font-weight: 500;
                  margin-bottom: 2px;
                  display: flex;
                  align-items: center;
                  &.profile-stat {
                     display: flex;
                     align-items: center;
                     gap: 0.25rem;
                     margin-top: 0.25rem;
                  }
                  .template-type-icon {
                     img {
                        width: 16px;
                     }
                     margin-right: 8px;
                  }
                  .template-type {
                     flex-grow: 1;
                  }
                  .orch-template-icon {
                     img {
                        width: 16px;
                     }
                     margin-right: 8px;
                  }
                  .orch-template-title {
                     flex-grow: 1;
                  }
                  .created-with-icon {
                     i {
                        font-size: 14px;
                        color: rgba(0, 0, 255, 0.453);
                     }
                     img {
                        width: 16px;
                     }
                     margin-right: 8px;
                  }
                  .created-with-type {
                     flex-grow: 1;
                  }
               }
            }
         }
      }
   }
   .nav-bar-bottom {
      border-top: 1px solid #ecedef;
      padding: 0 10px 0 25px;
      display: flex;
      align-items: center;
      .nav-tabs-line {
         border-bottom: 0px solid transparent;
         margin-bottom: 0;
         flex: 1;
         .nav-link {
            padding: 16px 0;
            font-weight: 500;
         }
         .active {
            border-bottom-width: 2px !important;
            color: #10aca3;
         }
      }
      .actions {
         ul {
            display: flex;
            list-style-type: none;
            margin-bottom: 0;
            > div {
               padding: 3px 10px;
               margin: 0 5px;
               font-size: 13px;
               font-weight: 500;
               display: flex;
               align-items: center;
               border-radius: 3px;
               transition: all 0.3s;
               &:hover {
                  background-color: rgb(240, 240, 240);
                  cursor: pointer;
               }
               i {
                  margin-right: 10px;
                  font-size: 15px;
                  -webkit-font-smoothing: antialiased;
                  display: var(--fa-display, inline-block);
                  font-style: normal;
                  font-variant: normal;
                  line-height: 1;
                  text-rendering: auto;
               }
               span {
               }
               &.azure {
                  i {
                     font-size: 18px;
                  }
               }
            }
         }
      }
   }
}
