.form-footer-imageuploadbox {
   padding: 0.125rem;
   display: flex;
   flex-direction: column;
   position: relative;
   border: 1px dashed #b5b5b5;
   background-color: white;
   width: 124px;
   height: 96px;
   border-radius: 5px;
   &.no-text {
      width: 248px;
      height: 96px;
   }
   cursor: pointer;
   justify-content: center;
   align-items: center;
   .icon {
      color: #ffa530;
      width: 30px !important;
      height: 30px;
      svg {
         width: 28px;
         height: 28px;
      }
      i {
         font-size: 30px;
      }
   }
   .text {
      padding-top: 15px;
      text-align: center;
      font-size: 14px;
      color: #b5b5b5;
   }

   &.has-image {
      cursor: default;
      padding: 0.125rem;
      border: 1px solid #b5b5b5;

      &.no-text {
         width: 248px;
         height: 96px;
      }

      &.has-link {
         border: 1px solid #7dd3d5;
      }

      .image-preview {
         width: 100%;
         height: 100%;

         img {
            object-fit: contain;
            width: 100%;
            height: 100%;
         }
         .delete-icon {
            position: absolute;
            background: #3d4e70;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            bottom: -12px;
            right: -12px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            transition: all 0.5s;
            cursor: pointer;
            &:hover {
               background: rgb(84, 105, 146);
            }
            i {
               font-size: 16px;
               color: white;
            }
            svg {
               width: 20px;
               height: 17px;
               color: white;
            }
         }
         .link-icon {
            position: absolute;
            background: #3d4e70;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            bottom: -12px;
            right: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            transition: all 0.5s;
            cursor: pointer;
            &:hover {
               background: rgb(84, 105, 146);
            }
            i {
               font-size: 16px;
               color: white;
            }
            svg {
               width: 20px;
               height: 17px;
               color: white;
            }
         }
      }
   }
}
