.view-template {
   .view-template-button {
   }
}

.view-template-modal {
   z-index: 1061 !important;
   .file-browser {
      display: flex;
      .files {
         width: 35%;
         background-color: rgb(245, 245, 245);
         border-radius: 4px;
         padding: 10px 20px;
         .actions {
            // display: flex;
            display: none;
            align-items: center;
            margin-bottom: 20px;
            .mode-title {
               font-weight: 500;
               margin-right: 15px;
               // margin-bottom: 4px;
            }
            .view-modes {
               display: flex;
               // margin-bottom: 10px;
               .mode {
                  float: 1;
                  font-size: 12px;
                  font-weight: 500;
                  padding: 5px 10px;
                  background-color: white;
                  // color:white;
                  margin-right: 10px;
                  border-radius: 4px;
               }
            }
         }
         .file-list {
            .file {
               .file-name {
                  display: flex;
                  align-items: center;
                  padding: 2px 0;
                  .icon {
                     width: 22px;
                     min-width: 22px;
                     text-align: center;
                     i {
                        font-size: 17px;
                        opacity: 0.7;
                     }
                  }
                  .title {
                     padding-left: 4px;
                     font-weight: 500;
                     // color:rgb(49, 49, 49);
                     color: rgb(100, 108, 154);
                     cursor: pointer;
                  }
               }
            }
            .folder {
               .folder-name {
                  display: flex;
                  align-items: center;
                  padding: 2px 0;
                  .icon {
                     width: 22px;
                     min-width: 22px;
                     text-align: center;
                     i {
                        font-size: 16px;
                        color: rgb(204, 204, 41);
                     }
                  }
                  .title {
                     padding-left: 4px;
                     font-weight: 500;
                     color: rgb(49, 49, 49);
                  }
               }
               .folder-children {
                  padding-left: 15px;
                  position: relative;
                  &:before {
                     content: "";
                     position: absolute;
                     z-index: 2;
                     left: 8px;
                     top: 0;
                     bottom: 4px;
                     width: 2px;
                     background-color: rgb(228, 228, 228);
                  }
               }
            }
         }
      }
      .preview {
         min-height: 550px;
         width: 65%;
         flex: 1;

         .preview-title {
            height: 30px;
            margin-bottom: 10px;
            display: flex;
            .left {
               flex: 1;
               font-size: 18px;
               font-weight: 500;
               padding: 0 20px;
            }
            .right {
               display: flex;
               align-items: center;
               .action-button {
                  border-radius: 4px;
                  margin-left: 10px;
                  padding: 5px 10px;
                  font-weight: 500;
                  background-color: rgb(219, 219, 219);
               }
            }
         }
         .preview-context {
            min-height: 500px;
            padding: 0 0 0 20px;
            .loading {
               text-align: center;
            }
            .text-file {
               border: 1px solid rgb(228, 228, 228);
               border-radius: 4px;
               padding: 0 0 4px 0;
               height: 500px;

               &.edit-mode {
                  border-color: red;
               }

               .code-wrap {
                  width: 100%;
                  height: 460px;
                  pre {
                     overflow: visible;
                     code {
                        overflow: visible;
                        font-size: 12px;
                        font-family: monospace;
                     }
                  }
               }

               .CodeMirror {
                  height: 498px;
                  //height: 100%;
                  width: 100%;
                  font-size: 12px;
                  font-family: monospace;
               }
            }
            .img-file {
               text-align: center;
               img {
                  max-width: 100%;
                  max-height: 500px;
               }
            }
            &.preview-loading {
               .img-file {
                  display: none;
               }
            }
         }
         [contenteditable] {
            outline: 0px solid transparent;
         }
      }
   }
}

.view-template-modal-backdrop {
   z-index: 1050 !important;
}
