.lifecycle-archivals-list {
   .application-with-icon {
      display: flex;
      align-items: center;
      &.commsite {
         text-wrap: nowrap;
      }
      &.teamsite {
         flex-direction: column;
         align-items: flex-start;
         gap: 1rem;
      }
      .teamsiteviva-label {
         display: flex;
         align-items: center;
      }
      img {
         // width: 18px;
         height: 18px;
         // margin-right: 10px;
      }
      span {
         padding-left: 8px;
      }
   }

   .content-option-with-icon {
      display: flex;
      align-items: center;
      i {
         font-size: 18px;
         opacity: 0.5;
      }
      span {
         padding-left: 8px;
      }
   }
}
