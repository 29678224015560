.recommendation-card-badge-group {
   display: flex;
   align-items: center;
   gap: 8px;

   &.recommendation-card-badge-group-mobile {
      align-items: flex-start;
      flex-direction: column;
   }

   .category-group {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      gap: 4px;
   }

   .category-group-mobile {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
   }

   .product-group {
      display: flex;
      justify-content: space-between;
      gap: 10px;
   }
}
