.card-team {
   // > .row {
   //    margin-left: 6%;
   //    margin-right: 6%;
   // }
   .left-side {
      padding-right: 30px;
      padding-left: 30px;
   }
   .archie-card {
      margin-left: 15px;
   }
   .form-select-box {
      // height: 160px;
   }
}
