@mixin hover {
   @media (hover: hover) {
      &:hover {
         @content;
      }
   }

   @media (hover: none) {
      &:active {
         @content;
      }
   }
}

@mixin light {
   [data-mantine-color-scheme="light"] & {
      @content;
   }
}

.card {
   position: relative;
   border-radius: 8px;
   border-color: var(--mantine-color-gray-2);
   transition: border-color 150ms ease, box-shadow 150ms ease, background-color 150ms ease;

   &[data-padding="md"] {
      padding: 16px 24px;
   }

   &[data-padding="lg"] {
      padding: 24px;
   }

   &[data-checked] {
      border-color: var(--mantine-primary-color-filled);
      box-shadow: var(--mantine-shadow-lg);
      background-color: var(--mantine-color-gray-0);

      &[data-select="reject"] {
         border-color: var(--mantine-color-error-9);
      }
   }

   &[disabled] {
      cursor: not-allowed;

      &[data-checked] {
         background-color: var(--white);
         border-color: var(--mantine-color-gray-2);
         box-shadow: none;
      }
   }

   @include hover {
      @include light {
         &[data-loading="false"] {
            background-color: var(--mantine-color-gray-0);
            border-color: var(--mantine-primary-color-filled);
            box-shadow: var(--mantine-shadow-lg);

            &[data-select="reject"] {
               border-color: var(--mantine-color-error-9);
            }

            &[data-hover="highlight"] {
               background-color: var(--white);
            }

            &[disabled],
            &[disabled][data-checked] {
               background-color: initial;
               border-color: var(--mantine-color-gray-2);
               box-shadow: none;
            }
         }
      }
   }
}
