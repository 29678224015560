.recommendation-resolutions {
   .resolution-title {
      margin-bottom: 16px;
      margin-top: 40px;
   }
   .items {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .metadata {
         display: flex;
      }

      .text {
         display: flex;
         flex-direction: column;
         gap: 8px;
         margin-left: 16px;
      }

      .icon {
         margin-right: 32px;
         width: 28px;
         height: 28px;
      }
   }
}
