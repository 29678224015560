.tenant-details {
   .nav {
      width: 100%;
      ul {
         width: calc(100% - 200px);
      }
   }
   .tenant-status {
      display: flex;
      margin-top: 8px;
      width: 200px;
      justify-content: right;
   }
   .detail-form {
      tr > td:first-child {
         padding-right: 20px;
      }
   }

   .etl-start-time {
      display: flex;
      .etl-start-number {
         width: 25%;
         border-radius: 4px 0 0 4px;
      }
      .etl-date-example {
         display: block;
         width: 75%;
         height: calc(1.5em + 1.3rem + 2px);
         padding: 0.65rem 1rem;
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.5;
         color: #495057;
         background-color: #fff;
         background-clip: padding-box;
         border: 1px solid #e2e5ec;
         border-left: none;
         border-radius: 0 4px 4px 0;
         transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
         background-color: #f1f1f1;
         font-weight: bold;
         cursor: default;
      }
   }

   .kt-portlet__body {
      padding-top: 3px !important;
      .nav-tabs.nav-tabs-line .nav-item {
         margin-right: 25px;
         // margin-bottom: -5px;
         a {
            i {
               position: relative;
               top: -2px;
               font-size: 14px;
               opacity: 0.5;
            }
            span {
               font-size: 14px;
               font-weight: 500;
               opacity: 0.7;
               transition: all 0.3s;
            }
            &:hover,
            &.active {
               span {
                  opacity: 1;
               }
            }
            .error-count {
               display: inline-block;
               background-color: rgb(255, 227, 151);
               padding: 1px 7px;
               color: rgb(163, 139, 73);
               border-radius: 5px;
               margin-left: 10px;
               position: relative;
               i {
                  color: rgb(163, 139, 73);
                  opacity: 1;
                  font-size: 10px;
               }
               .errors {
                  position: absolute;
                  top: calc(100% + 5px);
                  background-color: white;
                  z-index: 9;
                  width: 250px;
                  padding: 15px 15px 15px 30px;
                  box-shadow: 0 2px 2px #00000012;
                  border: 1px solid #e6e6e6;
                  left: -15px;
                  display: none;
                  .error {
                  }
               }
               &:hover {
                  .errors {
                     display: block;
                  }
               }
            }
         }
      }
   }
}
