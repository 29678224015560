.team-information-chrome {
   .chrome-tab-navigation {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: white;
      display: flex;
      border-bottom: 1px solid #ebebeb;
      padding: 0 1.4rem;
      z-index: 100;
      .tab {
         padding: 0.8rem 0.5rem 0 0.5rem;
         margin-left: 0.3rem;
         margin-right: 0.3rem;
         // margin-bottom: -0.1rem;
         cursor: pointer;
         .tab-container {
            padding-bottom: 0.6rem;
            border-bottom: solid 0.3rem transparent;
            span {
               font-size: 14px;
               color: #242424;
            }
         }
         &:hover {
            .tab-container {
               border-bottom-color: #bcbcbc;
            }
         }
         &.selected {
            .tab-container {
               border-bottom: solid 0.3rem #5b5fc7;
               span {
                  font-weight: 500;
               }
            }
         }

         &:first-child {
            padding-left: 0;
            // margin-left: 0;
         }
      }
   }
   .chrome-tab-content-area {
      padding-top: calc(42px);
   }
}
