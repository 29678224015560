@import "app/assets/common/bootstrap-media-mixins";
.template-list {
   .wp-type {
      position: relative;
      padding-left: 35px;
      @include media-breakpoint-down(sm) {
         display: inline-block;
      }
      .icon-holder {
         position: absolute;
         display: block;
         left: 0;
         top: 50%;
         margin-top: -12px;
         img {
            width: 23px;
            height: 22px;
         }
      }
   }
}
