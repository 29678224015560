.step-complete {
   padding-bottom: 100px;
   > .container {
      max-width: 720px;
   }
   .waiting-for-approval {
      margin-top: 50px;
      text-align: center;
      i {
         font-size: 120px;
         margin-bottom: 30px;
         opacity: 0.5;
      }
      .airplane-image {
         width: 145px;
         margin: 20px auto 60px auto;
         img {
            width: 145px;
         }
      }
      h3 {
         font-style: normal;
         font-weight: 400;
         font-size: 32px;
         line-height: 32px;
         color: #26323a;
         span {
            font-weight: 500;
         }
      }
      p {
         margin-top: 25px;
         font-weight: 500;
         font-size: 12px;
         line-height: 17px;
         text-align: center;
         color: #718690;
         margin-bottom: 40px;
      }
   }
   .view-progress {
      padding-top: 60px;
      h3 {
         font-weight: 400 !important;
         font-size: 32px !important;
         line-height: 32px;
         text-align: center;
         color: #26323a;
      }
      h6 {
         font-style: italic;
         font-weight: 500;
         font-size: 12px;
         line-height: 17px;
         text-align: center;
         color: #718690;
      }
      .progress-panel {
         margin: 30px 0 15px 0;
         font-size: 12px;
         font-weight: 500;
         color: #596a73;
      }
      .close-wrapper {
         margin-top: 30px;
         text-align: center;

         .view-directory {
            display: inline-block;
            margin-left: 15px;
            .btn {
               padding: 0.65rem 0.85rem;
               border-radius: 5px;
               font-weight: 600;
               font-size: 12px;
               line-height: 16px;
               display: inline-block;
               color: #596a73;
               border: 1px solid #dde2e7;
               zoom: 1;
            }
         }
      }
      .finished-url {
         text-align: center;
         .back-to-request {
            margin-right: 15px;
         }

         .back-to-request,
         .link-to-new-asset {
            min-width: 145px;
            padding: 0.65rem 0.85rem;
            border-radius: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            display: inline-block;
            color: #596a73;
            border: 1px solid #dde2e7;
            zoom: 1.2;
            margin-bottom: 15px;
         }
      }

      .rate-experience {
         background: rgba(0, 121, 136, 0.05);
         border-radius: 8px;
         text-align: center;
         padding: 15px 0 15px 0;
         margin-top: 60px;

         .question {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            font-feature-settings: "liga" off;
            color: #487084;
            margin-bottom: 15px;
         }
         .emojis {
            display: inline-flex;
            > div {
               font-size: 22px;
               opacity: 0.7;
               cursor: pointer;
               &:hover {
                  opacity: 1;
               }
            }
         }
      }
      .tip {
         text-align: center;
         margin-top: 60px;
         .tip-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #21292d;
            margin-bottom: 10px;
         }
         .tip-text {
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            color: #718690;
         }
      }
   }
   .request-another {
      display: inline-block;
      .btn {
         padding: 0.65rem 0.85rem;
         border-radius: 5px;
         font-weight: 600;
         font-size: 12px;
         line-height: 16px;
         display: inline-block;
         color: #596a73;
         border: 1px solid #dde2e7;
         zoom: 1;
         cursor: pointer;
      }
   }
   .error-message {
      .title {
         font-weight: bold;
         font-size: 15px;
         line-height: 16px;
         color: #26323a;
         padding-right: 15px;
      }
      .message {
         white-space: pre-wrap;
         font-weight: bold;
         color: red;
      }
   }
   .saving-to-server {
      text-align: center;
      margin-top: 50px;
   }
   .close-wrapper {
      //  display: flex;
   }
}
