.recommendation-card-details-section {
   .header {
      align-items: center;
      display: flex;
      gap: 8px;

      .just-launched-badge,
      .new-badge {
         i {
            font-size: 14px;
            line-height: 14px;
         }
      }

      .info-circle {
         i {
            font-size: 14px;
            margin-top: 1px;
            color: #6b7280;
         }
      }
   }

   .description {
      margin-top: 11px;
   }

   .metadata {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-top: 9px;
   }
}
