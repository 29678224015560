.skeleton-loader-portlet {
      .kt-portlet__head {
         padding-top: 15px;
         .kt-portlet__head-label {
            width: 100%;
         }
      }
}
.skeleton-loader-box {

}
.skeleton-loader-animated-wave {
   overflow: hidden;
   position: relative;
   &:before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
      animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1);
      animation-iteration-count: infinite;
  }
  @keyframes load {
      from {
         left: -150px;
      }
      to   {
         left: 100%;
      }
   }
}

.skeleton-loader-common {
   &.fake-table-row {
      display: flex;
      width: 100%;
      margin-top: 15px;
      
      &:first-child {
         margin-top: 0;
      }
   }
   &.fake-table-col {
      // flex:1;
      margin-left: 15px;
      &:first-child {
         margin-left: 0;
      }
   }
   .fake-text-h3 {
      background: rgba(238, 241, 245, 0.75);
      border-radius: 5px;
      height: 30px;
      width: 100%;
   }
   .fake-text-small {
      background: rgba(238, 241, 245, 0.75);
      border-radius: 5px;
      height: 12px;
      width: 100%;
   }
   .fake-box {
      background: rgba(238, 241, 245, 0.75);
      border-radius: 5px;
      min-height: 30px;
      width: 100%;
      position: relative;
      &.outline-only {
         &::after{
            content:"";
            position: absolute;
            border-radius: 3px;
            left:3px;
            top:3px;
            bottom:3px;
            right:3px;
            background-color: white;
            z-index: 1;
         }
      }
   }
   .fake-user-item {
      display: flex;
      // padding: 15px 0px 15px 20px;
      width: 100%;
      align-items: flex-start;
      // border-bottom: 1px solid #EEF1F5;
      > div {
         // padding: 0 0px 0 20px;
      }
      .pic {
         .circle {
            height: 42px;
            width: 42px;
            border-radius:4px;
            background: rgba(238, 241, 245, 0.75);
         }
      }
      .right {
         width:100%;
         margin-left: 15px;

         .title {
            // width:300px;
            width:100%;
            margin-bottom: 10px;
            .title-box {
               background: rgba(238, 241, 245, 0.75);
               border-radius: 5px;
               height: 21px;
            }
         }
         .property {
            margin-bottom: 5px;
            .property-box {
               background: rgba(238, 241, 245, 0.75);
               border-radius: 5px;
               height: 10px;
            }
         }
      }
      
   }
}