@import "app/assets/common/bootstrap-media-mixins";
.associate-template {
    .selected-items {
        li {
            // background-color: white;
            padding: 5px 0 4px 0;
            position: relative;
            line-height: 20px;
            // height: 48px;
            svg {
                color: #BFBFBF;
                width:18px;
                height: 18px;
                position: absolute;
                right:0px;
                top:50%;
                margin-top: -9px;
                cursor: pointer;
                &:hover {
                    color: rgb(160, 160, 160);

                }
            }
        }
    }
    .no-templates {
        padding: 5px 0;
        background-color: white;

        .help-text {
            color: #B5B5B5;
            font-size: 14px;
            line-height: 21px;
            font-style: italic;
            text-align: center;
        }
        .mock-template {
            background-color: #DFDFDF;
            opacity: 0.3;
            height: 25px;
            border-radius: 10px;
            margin: 0 15px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}