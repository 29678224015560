@import "_metronic/_assets/sass/breakpoints";

.lifecycle-report {
   background-color: white;
   border-radius: 4px;

   .lifecycle-report-cols {
      display: flex;
      width: 100%;
      @include kt-mobile {
         display: block;
      }
      .first-column {
         flex-grow: 1;
         position: relative;
         // padding-right: 20px;
         @include kt-mobile {
            // padding: 0px;
            padding-top: 120px;
         }
         .crawl-still-in-progress {
            position: absolute;
            top: 0;
            left: -10px;
            right: -20px;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.9);
            z-index: 10;
            border-radius: 6px;
            // backdrop-filter: blur(5px);
            text-align: center;
            padding-top: 50px;
            i {
               font-size: 50px;
               opacity: 0.7;
               background-color: white;
            }
            .title {
               font-size: 22px;
               font-weight: 600;
               max-width: 500px;
               margin: 0 auto 20px auto;
               background-color: white;
            }
            .desc {
               font-size: 14px;
               font-weight: 500;
               max-width: 500px;
               margin: 0 auto;
               opacity: 0.7;
               background-color: white;
            }
         }
      }
      .second-column {
         min-width: 25%;
         width: 25%;
         padding-left: 30px;
         @include kt-mobile {
            width: 100%;
            padding-left: 0px;
         }
      }
   }
   .lifecycle-report-list-table {
      // padding: 15px;
   }
   .lifecycle-report-filters {
   }
   .managed-metadata-item {
      position: relative;
      i {
         margin-right: 6px;
         display: inline;
      }
   }
   .managed-metadata-list {
      margin-bottom: 0;
      padding-left: 15px;
      list-style-type: none;
      .managed-metadata-item {
         i {
            position: absolute;
            left: -15px;
            top: 5px;
         }
      }
   }
}
.lifecycle-report-modal {
}
.lifecycle-report-modal-backdrop {
   //    filter: blur(8px);
   //   -webkit-filter: blur(8px);
   //   backdrop-filter: blur(7px) ;
   //   background-color: rgba(238, 241, 245, 0.75) !important;
   &.show {
      // opacity: 1 !important;
   }
}
