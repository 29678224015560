@import "app/assets/common/bootstrap-media-mixins";
@import "_metronic/_assets/sass/breakpoints";

.step-details {
   padding-top: 20px;
   border-top: 1px solid #e1e7ee;
   @include media-breakpoint-down(sm) {
      margin-top: 10px;
   }

   .select-geo {
      display: flex;
      flex-direction: column;
   }

   .permission-radios {
      // padding-top: 20px;
      .radio-item {
         margin-bottom: 16px;
         padding: 20px;
         position: relative;
         background: #ffffff;
         border: 1px solid #e1e7ee;
         box-sizing: border-box;
         box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         cursor: pointer;
         transition: all 0.3s;
         .icon {
            vertical-align: top;
            position: absolute;
            left: 20px;
            top: 50%;
            margin-top: -16px;
            width: 32px;
            height: 32px;
            // background: rgba(181, 181, 181, 0.198);
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            display: block;
            transition: all 0.3s;
            i {
               width: 32px;
               height: 32px;
               font-size: 32px;
               text-align: center;
               color: #676767;
            }
         }
         .text {
            padding-left: 50px;
            display: inline-block;
            h6 {
               font-size: 17px;
               font-weight: 600;
               color: #26323a;
               margin-bottom: 0;
            }
            p {
               font-size: 12px;
               line-height: 16px;
               letter-spacing: 0.222727px;
               font-weight: 500;
               color: #718690;
               margin: 0;
            }
         }
         .checkbox {
            position: absolute;
            width: 24px;
            height: 24px;
            right: -1px;
            top: -1px;
            background: #007989;
            border-radius: 0 5px 0 4px;
            color: white;
            text-align: center;
            line-height: 24px;
            font-size: 10px;
            opacity: 0;
            transition: all 0.3s;
         }
         &:hover {
            border: 1px solid #00798980;

            .icon {
               // background: rgba(181, 181, 181, 0.411);
               svg {
                  // color:white;
               }
            }
         }
         &.selected {
            border: 1px solid #007989;
            cursor: default;
            .icon {
               // background-color: #28B976;
               svg {
                  color: white;
               }
            }
            .checkbox {
               opacity: 1;
            }
         }
      }
   }
   .naming-policy-fields-top {
      padding-bottom: 30px;
      h4 {
         font-weight: 600;
         font-size: 22px;
         line-height: 32px;
         color: #26323a;
         margin-bottom: 3 px;
      }
      .bottom-button-container {
         text-align: right;
      }
      .naming-policy-instructions {
         // margin-top: -10px;
         white-space: pre-line;
         .alert-text {
            white-space: pre-line;
         }
      }
   }
   .main-area {
      .naming-policy-instructions {
         margin-bottom: 30px;
         white-space: pre-line;
         .alert-text {
            white-space: pre-line;
         }
      }
   }
   .form-items {
      margin-top: 20px;
      @include media-breakpoint-down(sm) {
         margin-top: 0;
      }
      .form-label {
         font-size: 11px;
         line-height: 16px;
         font-weight: 500;
         letter-spacing: 0.222727px;
         color: #718690;
      }
      .form-check-label {
         // font-weight: 500;
      }

      .form-group {
         position: relative;
         margin-bottom: 1.5rem;
         input {
            &:focus {
               border: 1px solid #28b976;
               box-sizing: border-box;
               box-shadow: 0px 3px 3px rgba(40, 185, 118, 0.269968);
               border-radius: 5px;
            }
         }
         // .helper-text {
         //     font-size: 12px;
         //     line-height: 14px;
         //     letter-spacing: 0.190909px;
         //     color: #676767;
         //     margin-top: 3px;
         // }
         .info-circle {
            position: absolute;
            right: 0;
         }
         .form-control.disabled {
            background-color: rgb(248, 248, 248);
            cursor: not-allowed;
         }
      }
      input,
      textarea,
      select,
      .form-check-label {
         font-size: 12px;
         font-weight: 500;
      }
   }
   .workspace-name-with-validator {
      .workspace-name {
         // border-bottom: 2px solid #E1E7EE;
         // display: flex;
         flex: 1;
         align-items: center;
         transition: all 0.3s;
         // margin-bottom: 30px;
         .name {
            flex: 1;
            border: 2px solid #e1e7ee;
            border-radius: 4px;
            input {
               font-weight: bold;
               font-size: 24px;
               line-height: 24px;
               padding: 0 20px 0 15px;
               border-width: 0;
               &::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
                    &:-ms-input-placeholder, /* Internet Explorer 10-11 */
                    &::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: #9da9ae;
                  font-size: 12px;
                  font-weight: 500;
               }
            }
         }

         &.name-focused {
            .name {
               border-color: #047b8b;
            }
            // border-bottom: 2px solid #047B8B;
         }
      }

      .validator-collapse {
         position: relative;
         // top:-30px;
      }
      .workspace-name-policy-enabled {
         margin-top: 3px;
         .info-tooltip-wrapper {
            opacity: 0.6;
            display: inline-block;
            margin-right: 8px;
            font-weight: 500;
         }
         .naming-policy-preview {
            > span {
               font-size: 12px;
               font-weight: 500;
            }
         }
         &.title-disabled {
            display: flex;
            border: 2px solid #e1e7ee;
            border-radius: 4px;
            padding: 0 20px 0 15px;
            height: 55px;
            align-items: center;
            background-color: #f8f8f8;
            .naming-policy-preview {
               align-items: center;
               span {
                  font-weight: bold;
                  font-size: 24px;
                  line-height: 24px;
                  border-width: 0;
               }
            }
         }
      }
   }

   .validator-collapse {
      .validation-box {
         padding: 0.75rem 0.75rem;
         .items {
            .error-item {
               font-size: 11px;
               font-weight: 600;
            }
         }
      }
   }
   .warnings {
      background: rgba(255, 209, 48, 0.05);
      border: 1px solid #ffd130;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 20px;
      .ScrollbarsCustom .ScrollbarsCustom-TrackY {
         opacity: 0.3;
         .ScrollbarsCustom-Thumb {
            background-color: #927b2a !important;
         }
      }
      .top {
         margin: 16px;
         font-weight: 500;
         font-size: 11px;
         line-height: 16px;
         color: #927b2a;
      }
      .teams {
         .item {
            margin: 0 8px 7px 8px;
            display: flex;
            background: #ffffff;
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            padding: 12px 12px;
            align-items: center;
            transition: all 0.3s;
            cursor: pointer;
            .logo {
            }

            .text {
               flex: 1;
               max-width: calc(100% - 40px);
               //   padding-right: 15px;
               .title {
                  font-weight: bold;
                  font-size: 15px;
                  line-height: 16px;
                  color: #26323a;
                  padding-right: 15px;
               }
               .type-text {
                  margin-top: 4px;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  color: #718690;
                  max-width: calc(100% - 15px);
                  > i {
                     // position: absolute;
                     // bottom: 0;
                     // right: 0;
                     font-size: 10px;
                     margin-right: 4px;
                     color: #007989;
                     opacity: 0.6;
                  }
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
            }
            .type {
               width: 40px;
               height: 40px;
               min-width: 40px;
               background: rgba(225, 231, 238, 0.5);
               border-radius: 8px;
               position: relative;
               img {
                  width: 25px;
                  height: 25px;
                  margin-left: 7px;
                  margin-top: 7px;
               }
            }
            &:hover {
               border: 1px solid rgb(171, 175, 180);
            }
            &.Private {
               &:hover {
                  border: 1px solid #e1e7ee;
                  cursor: default;
               }
            }
         }
      }
      .blocked-words {
         padding: 0 16px 8px 16px;
         color: #927b2a;
         .word {
            background-color: white;
            padding: 3px 10px;
            border-radius: 4px;
            i {
               font-size: 10px;
               opacity: 0.5;
               margin-right: 5px;
            }
            font-weight: 600;
            margin-bottom: 8px;
         }
      }

      &.exact-error {
         background: rgba(255, 48, 48, 0.05);
         border: 1px solid rgb(255, 48, 48);
         .top {
            color: rgb(146, 42, 42);
         }
      }
   }
   .group-name {
      > .form-group {
         margin-bottom: 0;
      }
      .form-control {
         font-size: 12px;
         font-weight: 500;
         height: auto;
         min-height: calc(1.5em + 1.3rem + 2px);
         //color: #718690;
      }
   }

   .image-wrapper {
      display: flex;
      margin-top: 30px;
      @include media-breakpoint-down(sm) {
         display: block;
      }
      .team-image {
         flex: 0;
         width: 75px;
         margin-right: 25px;
         position: relative;
         @include media-breakpoint-down(sm) {
            margin: 0 auto 20px auto;
            width: 200px;
         }
         .imageupload {
            width: 75px;
            height: 75px;
            padding: 15px 0;
            box-shadow: none;
            border-radius: 5px;
            @include media-breakpoint-down(sm) {
               width: 200px;
               height: 200px;
            }
            // background-color: rgb(248, 248, 248);
            .icon {
               // display: none;
               width: 22px;
               height: 22px;
               svg {
                  width: 22px;
                  height: 22px;
               }
            }
            .text {
               padding-top: 10px;
               // line-height: 12px;
               // font-size:11px;
               // font-weight: 500;
               font-size: 11px;
               line-height: 16px;
               font-weight: 500;
               letter-spacing: 0.222727px;
               color: #718690;
            }
            &.has-image {
               padding: 0 !important;
               .image-preview {
                  height: 100%;
                  width: 100%;
                  background-size: cover;
                  background-position: center;
                  border-radius: 4px;
                  img {
                     border-radius: 4px;
                  }
                  .delete-icon {
                     background-color: white;
                     border-radius: 5px;
                     height: 22px;
                     width: 22px;
                     bottom: 5px;
                     right: 5px;
                     svg {
                        color: #3da0a2;
                        height: 12px;
                        margin: 5px 0 0 1px;
                     }
                     i {
                        color: #3da0a2;
                        font-size: 17px;
                        margin: 3px 0 0 2px;
                     }
                  }
               }
            }
         }
         .image-size {
            display: none;
            position: absolute;
            top: 76px;
            width: 100%;
            // top:2px;
            // right:4px;
            text-align: center;
            font-weight: 500;
            font-size: 9px;
            font-style: italic;
            opacity: 0.5;
         }
      }
      .unique-id {
         flex: 1;
      }
   }

   .unique-id {
      .helper-text {
         font-size: 11px;
         font-weight: 400;
         opacity: 1;
         margin-bottom: 10px;
         font-style: italic;
      }
      .site-url {
         justify-content: center;
         align-items: center;
         .form-control {
            flex: 1;
         }
         div.form-control {
            background-color: #f9f9f9;
         }
         .action-edit {
            overflow: hidden;
            transition: all 0.3s;
            width: 0;
            padding: 0 0px;
            cursor: pointer;
            i {
               font-size: 16px;
               color: grey;
            }
            &:hover {
               i {
                  color: rgb(167, 167, 167);
               }
            }
         }
         &.show-edit {
            display: flex;
            .action-edit {
               width: 36px;
               padding: 0 10px;
            }
         }
         .validator-collapse {
            display: block;
         }
      }
      .site-url-validation {
         margin-top: 5px;
         .url-loading {
            // padding-top:10px;
            .validator-collapse {
               display: none;
            }
         }
         .url-validation {
            .url {
               font-size: 11px;
               font-weight: 400;
               opacity: 1;
               word-break: break-all;
            }
            .results {
               // margin-top: 5px;
               font-size: 11px;
               font-weight: 500;
               .is-valid {
                  color: #107c10;
               }
               .is-not-valid {
                  color: rgb(167, 28, 28);
               }
            }
         }
      }
   }
   .description-group {
      margin-top: 2rem;
   }
   .continue-button {
      i {
         font-size: 12px;
         margin-right: 10px;
      }
      // line-height: 18px;
      font-size: 12px;
      cursor: pointer;
      display: inline-block;
      font-weight: 600;
      background: #007989;
      border: 2px solid #007989;
      border-radius: 4px;
      margin-left: 15px;
      padding: 0.65rem 0.85rem 0.65rem 0.85rem;
      color: #ffffff;
      transition: all 0.3s;
      &:not(.disabled):hover {
         background: #0395a8;
         border: 2px solid #0395a8;
      }
      &.disabled {
         background: #9ccbd1;
         border: 2px solid #9ccbd1;
         cursor: default;
      }
   }
   .validation-feedback {
      .validator-collapse {
         display: none;
      }
   }
   form {
      .info-tooltip-wrapper {
         display: block;
      }
   }
   .metadata-fields {
      position: relative;
   }
   .custom-tooltip-wrapper {
      position: relative;
      .custom-tooltip {
         position: absolute;
         // display: none;
         overflow: hidden;
         z-index: 2;
         left: 100%;
         width: 0%;
         opacity: 0;
         box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
         border: 1px solid #eaeaea00;
         border-bottom: 1px solid #d8d8d800;
         margin-left: 2rem !important;
         border-radius: 3px;
         background-color: white;
         // .arrow {
         //    opacity: 0;
         // }
         .body {
            font-weight: 600;
            padding: 0.75rem;
         }
      }
      &:hover {
         .custom-tooltip {
            animation-name: fade-in-tooltip;
            animation-duration: 0.7s;
            display: block;
            box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.15);
            border: 1px solid #eaeaea;
            border-bottom: 1px solid #d8d8d8;
            width: 50%;
            opacity: 1;
         }
      }
      &:not(:hover) {
         // .custom-tooltip {
         //    animation-name: fade-out-tooltip;
         //    animation-duration: 0.3s;
         // }
      }

      @keyframes fade-in-tooltip {
         /* You could think of as "step 1" */
         0% {
            width: 0%;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            border: 1px solid #eaeaea00;
            border-bottom: 1px solid #d8d8d800;
         }
         39% {
            width: 0%;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            border: 1px solid #eaeaea00;
            border-bottom: 1px solid #d8d8d800;
         }
         40% {
            width: 50%;
            opacity: 0;
         }
         /* You could think of as "step 2" */
         100% {
            width: 50%;
            opacity: 1;
         }
      }

      @keyframes fade-out-tooltip {
         /* You could think of as "step 1" */
         0% {
            width: 50%;
            // opacity: 1;
         }
         99% {
            width: 50%;
            opacity: 0;
         }
         100% {
            width: 0%;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            border: 1px solid #eaeaea00;
            border-bottom: 1px solid #d8d8d800;
         }
      }
   }
}
.step-3-tooltip {
   box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.15) !important;
   border: 1px solid #eaeaea !important;
   border-bottom: 1px solid #d8d8d8 !important;
   margin-left: 2rem !important;
   .arrow {
      opacity: 0;
   }
   .popover-body {
      font-weight: 600;
      padding: 1rem;
   }
}
