@import "app/assets/common/bootstrap-media-mixins";

.Toastify {
    z-index: 999;
    .Toastify__toast-container {
        z-index: 1100;
    }
    .Toastify__toast-container--bottom-left {
        left:285px;
        @include media-breakpoint-down(sm) {
            left:10px;
            bottom: 10px;
        }
    }
    .Toastify__toast {
        background-color: white;
        box-shadow: 0px 1px 4px 1px rgba(45, 48, 54, 0.15);
        border-radius: 5px;
        border: 1px solid #0000002b;
        font-weight: 600;
        @include media-breakpoint-up(sm) {
            width:400px;
        }
        @include media-breakpoint-down(sm) {
            max-width:calc(100% - 20px);
        }
        

        // padding: 15px 8px;
        
    }
    .Toastify__close-button {
        line-height: 10px;
    }
    .Toastify__progress-bar {
        height: 3px;
        background: #3da0a2 !important;
    }
    // .Toastify__toast-body {}
}

.kt-aside--minimize {
    .Toastify__toast-container--bottom-left {
        left:90px;
        @include media-breakpoint-down(sm) {
            left:10px;
            bottom: 10px;
        }
    }
}