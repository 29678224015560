.popover  {
    &.dark {
        .arrow {
            &:before {
                // background-color: #000;
                // border-color: #000 !important;
            }
            &:after {
                
            }
        }
        .popover-header {

        }
        .popover-body {
            color: #fff;
            background-color: #26323A;
            // font-family: ProximaNova;
            border:1px solid #26323A;
            border-radius: 4px;
            font-size:13px;
        }
        &.bs-popover-top {
            .arrow {
                &:after {
                    border-top-color: #26323A !important;
                }
            }
        }
        &.bs-popover-right {
            .arrow {
                &:after {
                    border-right-color: #26323A !important;
                }
            }
        }
        &.bs-popover-left {
            .arrow {
                &:after {
                    border-left-color: #26323A !important;
                }
            }
        }
        &.bs-popover-bottom {
            .arrow {
                &:after {
                    border-bottom-color: #26323A !important;
                }
            }
        }
    }

}