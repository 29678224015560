.feature-files {
   .files {
      .file {
         display: flex;
         align-items: center;
         padding: 10px 0;
         border-bottom: rgb(235, 235, 235) 1px solid;
         &:last-child {
            border-bottom: none;
         }
         .file-icon {
            width: 20px;
            font-size: 14px;
            opacity: 0.6;
         }
         .file-name {
            flex: 1;
            font-weight: 500;
            font-size: 16px;
            .select-item {
               color: #5867dd;
               cursor: pointer;
               &:hover {
                  color: #7483f1;
               }
            }
         }
         .file-date {
            width: 150px;
         }
         .download {
            width: 30px;
            text-align: center;
            i {
               font-size: 17px;
               color: rgba(155, 167, 49, 0.664);
            }
         }
         .view-button {
            .view-template-button {
               width: 30px;
               text-align: center;
               cursor: pointer;
               i {
                  font-size: 17px;
                  color: rgba(110, 49, 167, 0.664);
               }
            }
         }
         .apply-feature-button {
            width: 30px;
            text-align: center;
            cursor: pointer;
            i {
               font-size: 17px;
               color: rgba(0, 128, 0, 0.63);
            }
         }
         .file-delete {
            width: 30px;
            text-align: center;
            font-size: 17px;
            cursor: pointer;
         }
      }
   }
   .files-portlet {
      .extract-modal.btn {
         height: 36px;
         margin-top: 10px;
         font-weight: 500;
         i {
            font-size: 12px;
         }
      }
      .kt-portlet__body {
         min-height: 400px;
      }
   }
}

.feature-extraction-modal {
   z-index: 1060 !important;
   .advanced-configuration {
      .lists-to-clone {
         background-color: #e6e6e6;
         border-radius: 4px;
         padding: 15px;
         margin-bottom: 15px;
         .form-group {
            margin-bottom: 5px;
            &:last-child {
               margin-bottom: 0;
            }
         }
         .no-results {
            font-weight: 500;
         }
      }
      .toggle-title {
         font-weight: 500;
         margin-bottom: 10px;
         cursor: pointer;
         i {
            transition: all 0.3s;
            margin-right: 10px;
            transform: rotate(-90deg);
         }
      }
      &.show-advanced-configs {
         i {
            transform: rotate(0);
         }
      }
      .configs {
         background-color: #f7f7f7;
         border-radius: 4px;
         padding: 15px;
         > .form-group {
            margin-bottom: 20px;
         }
         label {
            font-weight: 500;
         }
         .checkbox-description {
            padding-left: 23px;
         }
         .list-col {
            .content {
               background-color: white;
            }
         }
      }
   }
   .action-buttons {
      margin-top: 20px;
      text-align: right;
      .submit-button {
         &:disabled {
            cursor: default;
         }
      }
   }
}

.feature-extraction-modal-backdrop {
   z-index: 1050 !important;
}
