.metadata-details {
   .right-col {
      padding-top: 30px;
   }
   h3 {
      margin-top: 20px;
      margin-bottom: 20px;
   }

   .at-search {
      margin-bottom: 20px;
   }
   .at-count {
      margin-bottom: 10px;
   }
   .at-items {
      background-color: white;
      padding: 0 15px;
      border-radius: 4px;
      .selected-items li {
         font-weight: 600;
         padding: 12px 0 11px 0;
      }
      .no-templates {
         padding: 15px 0;
         .help-text {
            margin-bottom: 10px;
         }
      }
   }
   .text-input-field {
      position: relative;
      .animated-loader {
         position: absolute;
         z-index: 1;
         right: 10px;
         top: 50%;
         margin-top: -9px;
      }
   }
   .form-edit-multiple-values {
      .values {
         background-color: white;
         border: 1px solid #ced4da;
         border-radius: 0.25rem;
         padding: 1.25rem;
         .no-choices {
            padding: 5px 0;
         }
         .item {
            margin-bottom: 5px;
            position: relative;
            .icon {
               display: inline-block;
               width: 17px;
               height: 17px;
               > span {
                  position: relative;
                  top: 1px;
                  display: none;
                  svg {
                     width: 17px;
                     height: 17px;
                  }
               }
               .remove {
                  display: block !important;
                  position: absolute;
                  z-index: 1;
                  right: -0px;
                  top: 15px;
                  margin-top: -8px;
                  cursor: pointer;
                  // opacity: 0.5;
                  svg {
                     color: rgb(241, 107, 107);
                     width: 15px;
                     height: 15px;
                     transition: all 0.5s;
                  }
                  &:hover {
                     svg {
                        color: rgb(219, 67, 67);
                     }
                  }
               }
            }
            input {
               margin-left: 5px;
               display: inline-block;
               background-color: transparent;
               border: 1px solid transparent;
               padding: 5px 5px 5px 5px;
               line-height: 20px;
               width: calc(100% - 50px);
               border-radius: 0.25rem;
               transition: all 0.5s;

               &:focus {
                  background-color: white;
                  border: 1px solid #28b976;
                  outline: none;
               }
               &:hover {
                  border: 1px solid rgb(190, 190, 190);
               }
            }

            // &:hover {
            //     .icon {
            //         .check {
            //             display:none !important;
            //         }
            //         .dot {
            //             display:none !important;
            //         }
            //         .remove {
            //             display: inline-block;
            //             opacity: 0.3;
            //             color:red;
            //         }
            //     }
            // }
         }
      }
      .add {
         margin-top: 15px;
         .button-custom {
            padding: 5px 14px;
            font-size: 13px;
            height: 31px;
            line-height: 23px;
            svg {
               top: 1px;
            }
         }
         .icon {
            display: inline-block;
            svg {
               width: 17px;
               height: 17px;
            }
         }
         .text {
            display: inline-block;
            padding: 5px;
         }
      }
      &.multiple {
         .values {
            .item {
               .icon {
                  .check {
                     display: inline-block;
                     opacity: 0.3;
                  }
               }
            }
         }
      }
      &.single {
         .values {
            .item {
               .icon {
                  .dot {
                     display: inline-block;
                     opacity: 0.3;
                  }
               }
            }
         }
      }
      &.hidethis {
         // display: none;
      }
   }
   .directory-filter {
      &.is-disabled {
         // border: 2px solid #ffb82273 !important;
         // border-radius: 4px;
         // // margin-bottom: 2rem;
         // background-color: rgba(255, 184, 34, 0.06);
         // .alert  {
         //    margin: 0;
         // }
         > div:not(.form-group) {
            color: red;
         }
         .form-group {
            opacity: 0.4;
         }
         .alert-solid-warning {
            border: 2px solid #ffb82273 !important;
         }
      }
   }
   .show-warning {
      border: 2px solid #ffb82273 !important;
      border-radius: 4px;
      margin-bottom: 2rem;
      background-color: rgba(255, 184, 34, 0.06);
      .form-group {
         margin: 0 20px 2rem 20px;
      }
   }
   .alert {
      i {
         color: #b58114;
      }
      .alert-text {
         color: #b58114 !important;
      }
   }
   .naming-policy-help {
      background-color: rgba(255, 184, 34, 0.1);
      border: 1px solid #ffb822;
      border-radius: 4px;
      padding: 1.25rem;
      .text-help {
         font-size: 14px;
         font-weight: 400;
         margin-bottom: 15px;
      }
      .example {
         margin-bottom: 15px;
         .value {
            font-weight: 500;
            font-size: 14px;
         }
         .explain {
            font-size: 12px;
            color: gray;
            background-color: rgba(255, 255, 255, 0.83);
            padding: 4px 8px;
            // border:1px solid rgba(0, 0, 0, 0.371);
            margin-top: 5px;
            border-radius: 3px;

            .highlight {
               color: green;
               font-weight: 500;
            }
            .connector {
               opacity: 0.5;
               margin: 0 7px;
            }
         }
         &:last-child {
            margin-bottom: 0;
         }
      }
   }
}
