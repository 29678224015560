.orchestry-tooltip {
   // font-weight: 400 !important;
   .popover-body {
      font-weight: 500;
      color:#333;
   }
}
.info-circle {
    display: inline-block;
    // border:1px solid #A2A1A1;
    // border-radius: 50%;
    // width: 16px;
    // height: 16px;
    // position: relative;
    svg {
        color: #A2A1A1;
        width: 16px;
        // height: 8px;
        // width: 8px;
        // color: #A2A1A1;
        // position: absolute;
        // left:50%;
        // top: 50%;
        // margin-top: -4px;
        // margin-left: -3px;
    }
    &.question {
        svg {
            // margin-top: -4px;
            // margin-left: -5px;
        }
    }
}