@import "app/assets/common/bootstrap-media-mixins";
@import "app/assets/common/checkboxes.scss";
@import "_metronic/_assets/sass/breakpoints";

.settings-account {
   .form-group {
      margin-bottom: 0;
   }
   .details-message {
      margin-top: 20px;
      font-weight: 400;
      code {
         display: block;
         margin-top: 15px;
         padding: 10px;
         border-radius: 4px;
      }
   }
   .layout-config-v2 {
      .editor-group {
         display: flex;
         flex-direction: column;
         margin-top: 2.5rem;
      }
   }
}
