.root {
   font-weight: 400;
   font-family: var(--mantine-font-family);

   &[data-variant] {
      .label {
         span {
            display: flex;
            align-items: center;
            gap: 4px;
            max-width: 100%;
         }

         margin-bottom: 0;
         font-size: var(--mantine-font-size-xs);
         font-family: var(--mantine-font-family);
         transition: all 0.3s;
         border-radius: 16px;
         padding: 4px 12px;
         user-select: none;
         .iconWrapper {
            display: none;
         }
      }
   }
   &:not([data-variant="light"]):not([data-variant="filled"]) {
      .label {
         background: var(--mantine-color-gray-0);
         color: var(--mantine-color-gray-7);
      }
   }
   &[data-variant="light"] {
      .label {
         &:not([data-checked="true"]) {
            background: var(--mantine-color-gray-0);
            color: var(--mantine-color-gray-7);
         }
      }
   }

   &[data-clickable="false"] {
      .label {
         cursor: default;
      }
      span {
         cursor: default;
      }
   }

   &[data-icon-line-height="12px"] {
      i {
         line-height: 12px;
      }
   }

   &[data-size="sm"] {
      &[data-padding="no-icon"] {
         .label {
            padding: 2px 8px;
         }
      }
      &[data-padding="icon-and-close"] {
         .label {
            padding: 2px 6px 2px 8px;
         }
      }
      &[data-padding="icon-left"] {
         .label {
            padding: 2px 8px 2px 6px;
         }
      }
   }

   &[data-size="md"] {
      &[data-padding="no-icon"] {
         .label {
            padding: 2px 10px;
         }
      }
      &[data-padding="icon-and-close"] {
         .label {
            padding: 2px 8px 2px 10px;
         }
      }
      &[data-padding="icon-left"] {
         .label {
            padding: 2px 8px 2px 10px;
         }
      }
   }

   &[data-size="lg"] {
      &[data-padding="no-icon"] {
         .label {
            padding: 4px 12px;
         }
      }
      &[data-padding="icon-and-close"] {
         .label {
            padding: 4px 10px 4px 12px;
         }
      }
      &[data-padding="icon-left"] {
         .label {
            padding: 4px 10px 4px 12px;
         }
      }
   }

   &[data-size="xl"] {
      &[data-padding="no-icon"] {
         .label {
            font-size: 14px;
            line-height: 14px;
            padding: 4px 12px;
         }
      }
      &[data-padding="icon-and-close"] {
         .label {
            font-size: 14px;
            line-height: 14px;
            padding: 4px 10px 4px 12px;
         }
      }
      &[data-padding="icon-left"] {
         .label {
            font-size: 14px;
            line-height: 14px;
            padding: 4px 10px 4px 12px;
         }
      }
   }

   &[data-size="xxl"] {
      .label {
         font-size: 16px;
         font-weight: 500;
         line-height: 24px;
         padding: 4px 10px 4px 12px;
         height: 30px;
      }
   }

   /*
   * Variants for chips where the background and color are different from the standard mantine colors
   * and we have additional logic for the chip (like data-checked = true)
   */
   &[data-variant="OrchestryTrial"] {
      .label {
         &[data-checked="true"] {
            color: var(--mantine-color-gray-7);
            background: var(--mantine-color-primary-0);
         }
      }
   }

   &[data-variant="filterCount"] {
      .label {
         &[data-checked="true"] {
            color: var(--mantine-color-text-primary-0);
            background: var(--mantine-color-gray-1);
         }
      }
   }

   &[data-variant="clearall"] {
      &:not([data-variant="light"]):not([data-variant="filled"]) {
         .label {
            color: var(--mantine-color-text-tertiary-0);
            font-weight: var(--medium-font-weight);
            background: none;
            text-decoration: underline;
            padding: 0 !important;
         }
      }
   }

   &[data-variant="justlaunched"] {
      &:not([data-variant="light"]):not([data-variant="filled"]) {
         .label {
            background-color: var(--mantine-color-primary-9);
            color: var(--mantine-color-primary-0);
         }
      }
   }
}
