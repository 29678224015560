.lifecycle-report-bulk-action-modal {
   table {
      width: 100%;
      table-layout: fixed;
      th {
         border-bottom: 2px solid rgba(128, 128, 128, 0.05);
         padding: 8px;
         &:first-child {
         }
         &:last-child {
            // width: 30%;
         }
         &.th-actions {
            width: 84px;
         }
         &.th-protected {
            width: 84px;
            text-align: center;
            .info {
               margin-left: 6px;
            }
         }
      }
      td {
         border-bottom: 1px solid rgba(128, 128, 128, 0.05);
         padding: 8px;
         font-weight: 500;
         &:first-child {
            border-right: 1px solid rgba(128, 128, 128, 0.05);
            padding-left: 10px;
         }
         &:last-child {
            border-left: 1px solid rgba(128, 128, 128, 0.05);
            // width: 20%;
         }
      }
      tr {
         &:last-child {
            td {
               border-bottom: none;
            }
         }
         &.cant-force {
            td {
               > div {
                  opacity: 0.7;
               }
               background-color: rgba(128, 128, 128, 0.04);
            }
         }
         &.no-policy {
            td {
               > div {
                  opacity: 0.5;
               }
               background-color: rgba(55, 55, 55, 0.05);
            }
         }
         &.item-to-be-updated {
            td {
               position: relative;
               background-color: rgba(0, 0, 255, 0.06);
               border-bottom: 1px solid #e6e3fd;
               &::before {
                  content: "";
                  position: absolute;
                  top: -1px;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background-color: #e6e3fd;
               }
            }
         }
         &.workspace-ineligible {
            td {
               .dropdowns {
                  opacity: 0.6;
               }
               background-color: rgba(128, 128, 128, 0.06);
            }
         }

         .dropdowns {
            .excluded-from-archival {
               opacity: 0.6;
            }
            .dd-wrapper {
               .header-text {
                  position: absolute;
                  left: 0.65rem;
                  right: 1.65rem;
               }
            }
         }
      }
      .title-area {
         display: flex;
         align-items: center;
         .ta-icon {
            .profile-picture {
               border-radius: 4px;
               height: 20px;
               width: 20px;
            }
            padding-right: 10px;
         }
         .m365-icon {
            img {
               position: relative;
               top: -1px;
               height: 12px;
               width: 12px;
            }
            padding-right: 10px;
         }
         .ta-title {
         }
         .ta-title-and-url {
            .ta-url {
               font-size: 10px;
               opacity: 0.7;
               font-weight: 400;
               display: none;
            }
         }
      }
      .toggle-protection {
         text-align: center;
         &.clone-preview {
            .slider {
               box-shadow: 0px 0px 3px 0px blue;
            }
         }
      }

      .action {
         display: flex;
         align-items: center;
         .a-left {
            line-height: 13px;
            i {
               font-size: 16px;
            }
            i.fa-circle {
               color: rgba(255, 0, 0, 0.652);
            }
            i.fa-check-circle {
               color: rgba(0, 128, 0, 0.761);
            }
            padding-right: 10px;
            padding-left: 5px;
         }
         .a-right {
            .a-title {
               font-weight: 500;
            }
            .a-description {
               font-weight: 400;
               font-size: 11px;
               opacity: 0.7;
            }
         }
      }
      .actions {
         display: flex;
         align-items: center;
         .btn {
            // padding-left:2px;
            // padding-right:2px;
            padding: 3px 7px;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            text-align: center;
            i {
               padding: 0;
            }
         }
         .clone {
            // opacity: 0.5;
            padding-right: 6px;
            .btn {
               background-color: rgba(0, 0, 255, 0.5);
               i {
                  color: white;
                  font-size: 13px;
               }
               &.disabled {
                  cursor: default;
                  opacity: 0.4;
               }
            }
         }
         .undo {
            // opacity: 0.5;
            .btn {
               background-color: rgba(228, 91, 18, 0.5);
               i {
                  color: white;
                  font-size: 13px;
               }
               &.disabled {
                  cursor: default;
                  opacity: 0.4;
               }
            }
         }
      }
      .dropdowns {
         display: flex;
         align-items: center;
         > div:first-child {
            flex: 1;
            margin-right: 11px;
         }
         .btn {
            // padding-left:2px;
            // padding-right:2px;
            padding: 3px 7px;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            text-align: center;
            i {
               padding: 0;
            }
         }

         &.clone-preview {
            // background-color: black;
            .no-selection-allowed,
            .dd-wrapper .dd-header {
               // outline: 2px solid blue;
               box-shadow: 0px 0px 3px 0px blue;
            }
         }
         .no-selection-allowed {
            display: flex;
            align-items: center;
            .no-dd-title {
               flex: 1;
            }
            .no-dd-info {
               position: relative;
               > div {
                  position: relative;
                  right: -5px;
               }
            }
            .info {
            }
         }
      }
   }
   button:disabled {
      cursor: default;
   }
   .completed {
      .completed-box {
         text-align: center;
         padding: 20px 0 50px 0;
         i {
            font-size: 65px;
            opacity: 0.5;
         }
         .done-text {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 10px;
         }
         a {
            font-weight: 500;
         }
      }
   }
   .processing-card {
      padding: 20px 0 50px 0;
      .loader-text {
         // font-size: 20px;
         // font-weight: 500;
      }
   }
}
