.tenant-details-access-code {
   .form-input-with-icon {
      position: relative;
      .icon {
         position: absolute;
         z-index: 1;
         right: 15px;
         top: 50%;
         margin-top: -10px;
         height: 20px;
      }
   }
   .access-code-provided-by {
      margin-top: 10px;
      span {
         font-weight: 500;
      }
   }
}
