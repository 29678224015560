@import "app/assets/common/bootstrap-media-mixins";
.searchBox {
    position: relative;
    input {
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 5px;
        height: 44px;
        padding: 12px 40px 12px 12px;
        outline: none;
        position: relative;
        z-index: 1;
        &::placeholder {
            color: #B5B5B5;
        }
        width:250px;
    }
    .icon-delete,
    .icon-search {
        position: relative;
        vertical-align: top;
        z-index: 2;
        padding: 5px;
        height: 26px;
        width: 26px !important;
        left: -35px;
        top:10px;
        @include media-breakpoint-down(sm) {
            position: absolute;
            top:5px;
            right:0;
            left:auto;
        }
        cursor: pointer;
        display: inline-block;
        svg {
            color: #B5B5B5;
            // position: relative;
            // vertical-align: top;
            // z-index: 2;
            // padding: 5px;
            height: 16px;
            width: 16px !important;
            // left: -35px;
            // top:10px;
            cursor: pointer;
            &:hover {
                color: rgb(150, 150, 150);

            }
        }
    }
    &.alt-style {
        input {
            padding-left: 35px !important;
            border: 1px solid #E1E7EE;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            font-size: 13px !important;
        }
        .icon-search {
            right:auto !important;
            left:5px !important;
            i {
                font-size: 15px;
                color: #9DA9AE;

            }
        }
    }
}