.settings-groups {
   //  h3 {
   //      margin-top: 30px;
   //      margin-bottom: 15px;
   //      font-size: 20px !important;
   //      &:first-child {
   //          margin-top: 0;
   //      }
   //  }
   h4 {
      font-weight: 500;
      color: #26323a;
      font-size: 16px;
   }
   .group-creation-toggle {
      .form-orch-toggle {
         zoom: 1.2;
         padding-bottom: 1rem;
         border-bottom: 1px solid #eaeaea;
      }
      .form-group {
         margin-bottom: 1rem;
      }
      .form-label {
         font-size: 16px;
         margin-bottom: 0;
      }
   }
   .form-group {
      margin-bottom: 1.5rem;
   }
   .form-label {
      // font-size:16px;
      margin-bottom: 0;
   }
   .description {
      font-weight: 500;
      margin-bottom: 5px;
      opacity: 0.8;
   }
   .custom-toggle {
      .description {
         margin-bottom: 10px;
      }
      .form-group {
         margin-bottom: 2rem;
      }
   }
   .form-orch-toggle .form-control.form-toggle-control {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: calc(1rem + 55px);
      border: 0px solid transparent;
      background-color: transparent;
      .form-toggle {
         left: 0;
      }
      span {
         font-weight: 500;
      }
   }
   .table-list {
      // background-color: grey;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      table {
         margin-bottom: 0;
      }
   }

   .group-area {
      //
      //   background-color: whitesmoke;
      padding: 10px;
      padding-bottom: 0px;
      background: rgba(238, 241, 245, 0.75);
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px #e1e7ee40;
      border-radius: 5px;
      // min-height: 100px;
      height: auto !important;
      // min-height: 53px;
      .add-button {
         display: inline-block;
         background-color: #28b976;
         color: white;
         padding: 5px 10px;
         margin-bottom: 10px;
         font-weight: 500;
         border-radius: 3px;
         transition: background-color 0.5s;
         cursor: pointer;
         span {
            margin-left: 7px;
         }
         svg {
            position: relative;
            top: 1px;
         }
         &:hover {
            background-color: rgb(25, 148, 90);
         }
      }
   }
}
