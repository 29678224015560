.recommendation-resolution-skeleton {
   display: flex;
   justify-content: space-between !important;
   align-items: center;

   .left-section {
      display: flex;
      .metadata {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         gap: 15.5px;
         margin-left: 16px;
         .metadata-footer {
            display: flex;
            align-items: center;
         }
      }
   }
}
