.report-column-guest-name {
   display: flex;
   align-items: center;
   .guest-title {
      display: flex;
      align-items: center;
      // margin-bottom: 6px;

      .title-area {
         font-style: normal;
         font-weight: 600;
         font-size: 14px;
         line-height: 20px;
         // color: #26323a;
         padding-right: 10px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         &.no-link {
            color: #242424;
         }
      }
      // .mail {
      //    // flex: 1;
      //    flex: 1;
      //    font-family: TT Norms;
      //    font-style: normal;
      //    font-weight: 400;
      //    font-size: 12px;
      //    // line-height: 20px;
      //    // padding: 17px 15px 20px 0;
      //    color: #718690;
      //    white-space: nowrap;
      //    overflow: hidden;
      //    text-overflow: ellipsis;
      // }
   }
   .mail {
      // flex: 1;
      flex: 1;
      font-family: TT Norms;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      // line-height: 20px;
      // padding: 17px 15px 20px 0;
      color: #718690;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      i {
         margin-right: 5px;
         position: relative;
         top: 1px;
         font-size: 11px;
         opacity: 0.5;
      }
   }
   .status {
      margin-top: 4px;
   }
}
