.guest-review-workflow-insight-report {
   .custom-header {
      display: flex;
      margin-bottom: 25px;
      .title-left {
         flex: 1;
         .top {
            display: flex;
            align-items: center;
            .title {
               color: #007989;
               font-size: 18px;
               font-weight: 600;
            }
            .count {
               background-color: rgba(0, 0, 255, 0.398);
               border-radius: 4px;
               margin-left: 8px;
               font-size: 12px;
               font-weight: 500;
               padding: 3px 10px;
               color: white;
               line-height: 16px;
               height: 21px;
            }
         }
      }
   }
   .custom-footer {
      text-align: right;
      margin-top: 20px;
      // display: flex;
      // align-items: center;
      .info-tooltip-wrapper {
         position: relative;
         top: 5px;
         i {
            color: #fd397a;
            font-size: 20px;
         }
      }
      .skeleton-loader-common {
         // width: 216px;
         display: inline-block;
      }
      .subtitle-button {
         width: 216px;
         &[disabled] {
            cursor: not-allowed;
         }
      }
   }
}

.column-type-workflowreviewactions {
   padding-right: 0 !important;
}

td.column-workflow-review-actions {
   width: 216px;
}
