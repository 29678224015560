.no-results-alt-panel {
   text-align: left;
   margin: auto;
   // margin-top: -50px;
   .alert {
      margin-bottom: 0;
      .icon {
         display: inline-block;
         height: 75px;
         width: 75px;
         vertical-align: middle;
         background-color: rgb(228, 228, 228);
         border-radius: 50%;
         line-height: 72px;
         i {
            vertical-align: middle;
            font-size: 30px;
            color: white;
            // box-shadow: 2px 2px 0px black;
         }
      }
   }
}
