.orchestry-image-dropzone {
   .description {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
   }

   .preview {
      align-items: center;
      display: flex;
      gap: 8px;
   }
}
