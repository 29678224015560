.orchestry-admin-etl-crawl-stats-workspaces {
   .title-button {
      font-weight: 500;
      cursor: pointer;
      color: #56689a;
   }
}
.etl-crawler-etl-stats-workspace-details {
   z-index: 1070 !important;
   > .modal-dialog {
      max-width: calc(100vw - 40px) !important;
      .modal-body {
         background-color: rgb(248, 248, 248);
      }
   }
}
.etl-crawler-etl-stats-workspace-details-bg {
   z-index: 1060 !important;
}

.orchestry-admin-etl-crawl-stats-workspace-details {
   .insights-portlet {
      margin-bottom: -10px;
      margin-left: -10px;
      .insight {
         display: inline-block;
         padding: 4px 10px;
         background-color: rgb(231, 231, 231);
         border-radius: 4px;
         margin-left: 10px;
         margin-bottom: 10px;
         color: black;
         font-weight: 500;
      }
   }
   .history-portlet {
      .crawl-history {
         .crawl-date {
            font-weight: 500;
            margin-bottom: 5px;
         }
         .crawl-plans {
            margin-left: -10px;

            .plan {
               display: inline-block;
               padding: 4px 10px;
               background-color: rgb(231, 231, 231);
               border-radius: 4px;
               margin-left: 10px;
               margin-bottom: 10px;
               color: black;
               font-weight: 500;
            }
         }
      }
   }
}
