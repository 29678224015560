.recommendation-card-history-expanded-skeleton {
   display: flex;
   flex-direction: column;
   gap: 28px;
   justify-content: space-between;
   .timeline-item-skeleton-container {
      display: flex;
      flex-direction: column;
      gap: 18px;
   }
}
