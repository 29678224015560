.orchestry-pie-chart-legend-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
   flex: 1 0 0;
   .orchestry-pie-chart-legend-item {
      width: 100%;
      .orchestry-pie-chart-legend-item-text {
         display: flex;
         justify-content: space-between;
         gap: 24px;
         align-items: center;
         user-select: none;
         cursor: pointer;
         .orchestry-pie-chart-legend-item-count {
            color: var(--mantine-color-text-secondary-0);
            font-weight: var(--medium-font-weight);
         }
      }
   }
}
