.recommendation-share-suggestion {
   .share-text {
      padding: 8px 16px;
      i {
         color: var(--mantine-color-primary-7);
         font-size: 18px;
         margin-right: 10px;
         position: relative;
         &:before {
            top: 3px;
            position: relative;
         }
      }
      span {
         margin-right: 7px;
      }
   }
   .share-link {
      text-decoration: underline;
      cursor: pointer;
   }
}
