.form-check-input {
    // background-color: #fafafa;
    // border: 1px solid #cacece;
	// box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	// padding: 9px;
	// border-radius: 3px;
	// display: inline-block;
    // position: relative;
    
    
    // &:active, 
    // &:checked:active {
    //     box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    // }
}

.white-checkbox-dash {
    display: inline-block;
    position: relative;
    span.box {
        content:"";
        position: absolute;
        display: inline-block;
        left: 0px;
        z-index: 1;
        width:15px;
        height: 15px;
        background-color: white;
        border: 2px solid #28B976;
        // left:-20px;
        top:50%;
        margin-top: -7.5px;
    }
    input[type="checkbox"]{
        opacity: 0;
        margin-right: 10px;
    }
    input:checked + span.box {

    }
    input:checked + span.box:after {
        content: "";
        font-size: 14px;
        position: absolute;
        z-index: 2;
        top: 5px;
        left: 2px;
        background-color: #99a1a7;
        height: 2px;
        width: 7px;
        // transform: rotate(-45deg) scale(.8);
        // border-bottom: solid 3px white;
        // border-left: solid 3px white;
    }
}

.checkbox-green {
    // padding-right:3rem;
    // display: inline-block;
    position: relative;
    .form-check {
        label {
            margin-left: 7px;
            color:#414141;
            cursor: pointer;
        }
        label:before {
            content:"";
            position: absolute;
            z-index: 1;
            width:18px;
            height: 18px;
            // background-color: rgb(66, 223, 149);
            background-color: white;
            border: 1px solid #99a1a7;
            border-radius: 0.25rem;
            left:0;
            top:0px;
        }
        input:checked + label:before {
            content:"";
            position: absolute;
            z-index: 1;
            width:18px;
            height: 18px;
            // background-color: #28B976;
            border-radius: 0.25rem;
            left:0;
            top:0px;
        }
        input:checked + label:after {
            // content: '\2714';
            content:"";
            font-size: 14px;
            position: absolute;
            z-index: 1;
            top: 4px;
            left: 3px;
            color: #c2c2c2;
            transform: rotate(-45deg) scale(.8);
            border-bottom: solid 3px #c2c2c2;
            border-left: solid 3px #c2c2c2;
            background: none;
            height: 8px;
            width: 12px;
        }
        input {
            opacity: 0;
        }
    }
    .info-circle {
        position: relative;
        // top: 4px;
        margin-left: 4px;
        // position: absolute;
        // right:0.75rem;
        // top:1rem;
    }

}

.checkbox-grey-large {
    // padding-right:3rem;
    // display: inline-block;
    position: relative;
    .form-check {
        label {
            margin-left: 7px;
            color:#414141;
            cursor: pointer;
        }
        label:before {
            content:"";
            position: absolute;
            z-index: 1;
            width:22px;
            height: 22px;
            // background-color: rgb(66, 223, 149);
            background-color: white;
            border: 2px solid #efefef;
            border-radius: 0.25rem;
            left:0;
            top:0px;
        }
        input:checked + label:before {
            content:"";
            position: absolute;
            z-index: 1;
            width:22px;
            height: 22px;
            // background-color: #28B976;
            border-radius: 0.25rem;
            left:0;
            top:0px;
            background: #007989;
            border: 2px solid #007989;
        }
        input:checked + label:after {
            // content: '\2714';
            content:"";
            font-size: 14px;
            position: absolute;
            z-index: 1;
            top: 5px;
            left: 5px;
            //color: #c2c2c2;
            transform: rotate(-45deg) scale(.8);
            border-bottom: solid 3px #ffffff;
            border-left: solid 3px #ffffff;
            background: none;
            height: 8px;
            width: 12px;
        }
        input {
            opacity: 0;
        }
    }
    .info-circle {
        position: relative;
        // top: 4px;
        margin-left: 4px;
        // position: absolute;
        // right:0.75rem;
        // top:1rem;
    }

}

.radio-green {
    // padding-right:3rem;
    // display: inline-block;
    position: relative;
    .form-check {
        label {
            margin-left: 7px;
            color:#414141;
            cursor: pointer;
        }
        label:before {
            content:"";
            position: absolute;
            z-index: 1;
            width:18px;
            height: 18px;
            // background-color: rgb(66, 223, 149);
            // border:1px solid #28B976;
            background-color: white;
            border: 1px solid #959696;
            border-radius: 50%;
            left:0;
            top:0px;
        }
        input:checked + label:before {
            content:"";
            position: absolute;
            z-index: 1;
            width:18px;
            height: 18px;
            // background-color: #28B976;
            border-radius: 50%;
            left:0;
            top:0px;
        }
        input:checked + label:after {
            // content: '\2714';
            content: "";
            font-size: 14px;
            position: absolute;
            z-index: 1;
            top: 5px;
            left: 5px;
            // background: white;
            background: #bfc7d7;
            border-radius: 50%;
            height: 8px;
            width: 8px;
        }
        input {
            opacity: 0;
        }
    }
    .info-circle {
        position: relative;
        // top: 4px;
        margin-left: 8px;
        // position: absolute;
        // right:0.75rem;
        // top:1rem;
    }

}