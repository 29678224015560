@keyframes orchestry-progress-bar {
   0% {
      transform: scaleX(1);
   }
   100% {
      transform: scaleX(0);
   }
}

.root {
   &[data-variant="success"] {
      background-color: var(--mantine-color-success-0) !important;
      .section {
         --progress-section-color: var(--mantine-color-success-5) !important;
         &-animated {
            animation: orchestry-progress-bar linear 1 forwards;
         }
      }
   }
   &[data-variant="heartpink"] {
      background-color: var(--mantine-color-pink-0) !important;
      .section {
         --progress-section-color: var(--mantine-color-pink-8) !important;
      }
   }
   &[data-variant="requeuetoast"] {
      background-color: var(--mantine-color-blue-0) !important;
      .section {
         --progress-section-color: var(--mantine-color-blue-6) !important;
      }
   }
}
