.installer-step-partner-association {
   .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 16px;
      color: #222;
      &.select-license-type {
         margin-top: 30px;
      }
   }
   .description {
      margin-bottom: 20px !important;
      font-size: 13px;
      font-weight: 400;
      color: #222;
   }
   .form-group {
      margin-bottom: 0.8rem;
   }
   .partner-settings-consent-control {
      .context-text-box {
         background-color: #f9f9f9;
      }
      .dynamic-scrollbar {
         padding: 0;
      }
      .bottom-error-text {
         display: none;
      }
      .consent-checkbox {
         * {
            font-size: 13px !important;
            font-weight: 400;
            color: #222;
            font-family: "TT Norms", Helvetica, sans-serif !important;
         }
      }
   }
   .partner-description {
      margin-bottom: 10px;
      p {
         font-size: 13px;
         font-weight: 400;
         color: #222;
         font-family: "TT Norms", Helvetica, sans-serif !important;
      }
   }
   .ob-form-group-top {
      margin-top: 10px;
      margin-bottom: 20px;
      > .form-label {
         font-weight: 500;
         color: #26323a;
         font-size: 14px;
      }
      .partner-secret-control {
         * {
            font-family: "TT Norms", Helvetica, sans-serif;
         }
      }
   }
   .ob-form-group-bottom {
      > .form-label {
         font-weight: 500;
         color: #26323a;
         font-size: 14px;
      }
      .partner-settings-consentcontrol {
         * {
            font-family: "TT Norms", Helvetica, sans-serif;
         }
         p {
            font-size: 13px;
         }
      }
   }
}
