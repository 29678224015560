#bulk-reset-protected-tooltip {
   .overlay-paragraph {
      p {
         margin: 0;
      }
      text-align: left;
      font-weight: 500;
      color: #333;
   }
}
.workspace-bulk-reset-modal {
   .modal-body {
      max-height: 480px;
      overflow-y: auto;
   }
   table {
      width: 100%;
      thead th {
         border-bottom: 2px solid rgba(128, 128, 128, 0.05);
         padding: 8px;
         &:first-child {
         }
         &:last-child {
            // width: 30%;
         }
         &.th-actions {
            width: 84px;
         }
         &.th-protected {
            width: 84px;
            text-align: center;
            .info {
               margin-left: 6px;
            }
         }
      }
      td {
         border-bottom: 1px solid rgba(128, 128, 128, 0.05);
         padding: 8px;
         font-weight: 500;
         &:first-child {
            border-right: 1px solid rgba(128, 128, 128, 0.05);
            padding-left: 10px;
         }
         &:last-child {
            border-left: 1px solid rgba(128, 128, 128, 0.05);
            // width: 20%;
         }
      }
      tr {
         &:last-child {
            td {
               border-bottom: none;
            }
         }
         &.cant-force {
            td {
               > div {
                  opacity: 0.7;
               }
               background-color: rgba(128, 128, 128, 0.04);
            }
         }
         &.no-policy {
            td {
               > div {
                  opacity: 0.5;
               }
               background-color: rgba(55, 55, 55, 0.05);
            }
         }
         &.item-to-be-updated {
            td {
               position: relative;
               background-color: rgba(0, 0, 255, 0.06);
               border-bottom: 1px solid #e6e3fd;
               &::before {
                  content: "";
                  position: absolute;
                  top: -1px;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background-color: #e6e3fd;
               }
            }
         }
         &.workspace-ineligible {
            td {
               .dropdowns {
                  opacity: 0.6;
               }
               background-color: rgba(128, 128, 128, 0.06);
            }
         }

         .dropdowns {
            .excluded-from-archival {
               opacity: 0.6;
            }
            .dd-wrapper {
               .header-text {
                  position: absolute;
                  left: 0.65rem;
                  right: 1.65rem;
               }
            }
         }
      }
   }
   .title-area {
      display: flex;
      align-items: center;
      .ta-icon {
         .profile-picture {
            border-radius: 4px;
            height: 20px;
            width: 20px;
         }
         padding-right: 10px;
      }
      .m365-icon {
         img {
            position: relative;
            top: -1px;
            height: 12px;
            width: 12px;
         }
         padding-right: 10px;
      }
      .ta-title {
         max-width: 350px;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }
      .ta-title-and-url {
         .ta-url {
            font-size: 10px;
            opacity: 0.7;
            font-weight: 400;
            display: none;
         }
      }
   }
}
