.app-consent {
   .alert-text {
      p {
         margin-bottom: 0;
      }
   }
   .app-consent-instructions {
      .pim-options {
         margin-top: -10px;
         .click-here-pim {
            color: #56689a;
            cursor: pointer;
         }
      }
      .permission-options {
         margin-top: 30px;
         margin-bottom: 30px;
         .permissions-area {
            display: flex;
            border-bottom: 1px solid #ebebeb;
            margin-bottom: 30px;
            padding-bottom: 30px;
            &:last-child {
               border-bottom: 0px solid transparent;
               margin-bottom: 0;
               padding-bottom: 0;
            }
            .permissions-icon {
               flex-grow: 0;
               min-width: 40px;
               position: relative;
               // zoom: 1.1;
               i {
                  font-size: 24px;
               }
               .orch-logo {
                  position: absolute;
                  z-index: 1;
                  padding: 2px 3px;
                  border-radius: 50%;
                  line-height: 5px;
                  top: 9px;
                  left: 12px;
                  background-color: white;
                  img {
                     width: 18px;
                     position: relative;
                     left: -3px;
                     filter: invert(85%) sepia(14%) saturate(752%) hue-rotate(73deg) brightness(90%) contrast(90%);
                  }
               }
               .lifecycle {
                  position: absolute;
                  z-index: 1;
                  padding: 2px 3px;
                  border-radius: 50%;
                  line-height: 5px;
                  top: 9px;
                  left: 12px;
                  background-color: white;
                  i {
                     font-size: 10px;
                  }
               }
            }
            .permissions-content {
               flex: 1;
               h4 {
                  font-size: 16px;
                  color: black;
                  vertical-align: text-top;
                  .service-pill {
                     font-size: 9px;
                     font-weight: 400;
                     // text-transform: uppercase;
                     display: inline-block;
                     background-color: rgb(169, 169, 169);
                     color: white;
                     padding: 1px 4px 2px 4px;
                     border-radius: 2px;
                     margin-left: 5px;
                     margin-top: -3px;
                  }
               }
               h5 {
                  font-size: 14px;
                  color: rgb(107, 107, 107);
                  margin-bottom: 10px;
                  &.required {
                     color: rgb(186, 99, 99);
                  }
               }

               .completed-task {
                  font-weight: 500;
                  // padding: 5px 15px 5px 15px;
                  // border: 1px solid #646c9a51;
                  border-radius: 4px;
                  margin-top: 10px;
                  line-height: 20px;
                  display: inline-flex;
                  align-items: center;
                  .icon {
                     // display: inline-block;
                     width: 36px;
                     height: 36px;
                     min-width: 36px;
                     max-width: 36px;
                     background-color: #97d29b;
                     border-radius: 4px;
                     text-align: center;
                     display: inline-flex;
                     align-items: center;
                     zoom: 0.8;
                     i {
                        font-size: 24px;
                        color: white;
                        margin: 0 auto;
                     }
                     // i {
                     //    margin-right: 8px;
                     //    font-size: 20px;
                     //    line-height: 10px;
                     //    position: relative;
                     //    top: 4px;
                     // }
                  }
                  span {
                     margin-left: 15px;
                  }
               }
               .text-description {
                  // min-height: 57px;
                  font-weight: 400;
                  margin-bottom: 20px;
               }
               .content-wrap {
                  display: flex;
                  .text-and-button {
                     flex: 1;
                     .ms-login {
                        // margin: auto;
                        // text-align: center;
                        .custom-button {
                           background-image: url("~/public/media/ms-signin-button.png");
                           width: 300px;
                           height: 34px;
                           border: 1px solid rgb(172, 172, 172);
                           cursor: pointer;
                           display: inline-block;
                           color: black;
                           text-align: center;
                           font-family: "Segoe UI", "wf_segoe-ui_normal", "Arial", sans-serif;
                           font-size: 13px;
                           background-size: contain;
                           background-repeat: no-repeat;
                           background-position: left top;
                           line-height: 32px;
                           font-weight: 500;
                           padding-left: 40px;
                           padding-right: 15px;
                           text-align: left;
                           &:hover {
                              border-color: blueviolet;
                           }
                        }
                     }
                  }
                  .permissions-current-delegates {
                     padding-left: 15px;
                     min-width: 40%;
                     .delegates-title {
                        font-weight: 500;
                        font-size: 13px;
                        margin-bottom: 5px;
                     }
                     .principal-item {
                        display: flex;
                        margin-right: 0;
                     }
                     .no-delegates {
                        background-color: rgb(248, 248, 248);
                        padding: 10px;
                        font-weight: 400;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        font-size: 11px;
                     }
                  }
               }

               .install-item {
                  display: inline-flex;
                  align-items: center;
                  // margin-bottom: 10px;
                  font-weight: 500;
                  // padding: 5px 15px 5px 15px;
                  // border: 1px solid #646c9a51;
                  border-radius: 4px;
                  // margin-top: 10px;
                  line-height: 20px;
                  // display: inline-block;
                  .status-icon {
                     width: 36px;
                     height: 36px;
                     min-width: 36px;
                     max-width: 36px;
                     background-color: #97d29b;
                     border-radius: 4px;
                     text-align: center;
                     display: flex;
                     align-items: center;
                     zoom: 0.8;
                     i {
                        font-size: 18px;
                        color: white;
                        margin: 0 auto;
                     }
                  }
                  .right {
                     padding-left: 15px;
                     .title {
                        font-size: 13px;
                        font-weight: 500;
                        // color: black;
                     }
                     .description {
                        font-size: 11px;
                        opacity: 0.75;
                        line-height: 11px;
                     }
                  }
                  .version-highlight {
                     background-color: rgb(186, 186, 247);
                     padding: 1px 3px;
                     font-size: 9px;
                     font-weight: 500;
                     color: white;
                     border-radius: 3px;
                     &.red {
                        background-color: rgb(216, 140, 140);
                     }
                     &.green {
                        background-color: rgb(121, 204, 104);
                     }
                  }
                  &.needs-update {
                     // border: 0px solid transparent;
                     // background-color: #646c9a14;
                     margin-bottom: 20px;
                     .status-icon {
                        // display: none;
                        background-color: #c58f8f;
                     }
                     .right {
                        // padding-left: 0;
                        .description {
                           // line-height: 18px;
                        }
                     }
                  }
                  &.connecting {
                     .status-icon {
                        // display: none;
                        background-color: rgb(233, 181, 85);
                        background-color: white;
                        // padding-left: 7px;
                     }
                     .right {
                        // padding-left: 0;
                        color: rgb(160, 121, 47);
                        .description {
                           // line-height: 18px;
                        }
                     }
                  }
               }
               .install-deploy,
               .install-teams-app,
               .force-update {
                  padding: 0.35rem 0.65rem;
                  font-weight: 500;
                  font-size: 12px;
               }
            }
         }
         .service-app-area {
            position: relative;
            .loader-guy {
               position: absolute;
               left: calc(50% - 30px);
               top: calc(50% - 30px);
               z-index: 1000;
            }
            .disable-area.is-disabled.use-overlay {
               &::before {
                  opacity: 0.8;
               }
            }
         }
      }
   }
}
