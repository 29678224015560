html,
body {
   font-weight: 400;
}
#kt_aside_brand {
   position: relative;
   .logo-tag {
      position: absolute;
      top: 10px;
      left: 22%;
      .logo-tag-inside {
         line-height: 14px;
         font-size: 11px;
         background-color: rgb(117, 125, 242);
         border-radius: 3px;
         padding: 0px 7px 0 5px;
         color: white;
         font-weight: 600;
         border: 1px solid rgba(255, 255, 255, 0.9);
         white-space: nowrap;
      }
   }
}
.kt-aside--minimize {
   #kt_aside_brand {
      .logo-tag {
         top: 46px;
         width: 100%;
         left: 0;
         display: flex;
         justify-content: center;
      }
   }
}
.diable-transform-to-allow-fixed-positioning {
   .kt-grid--animateContent {
      transform: none !important;
   }
}
.kt-user-card {
   .kt-user-card__name {
      .orchestry-display-name {
      }
      .orchestry-upn {
         font-size: 12px;
         opacity: 0.7;
      }
   }
}
.kt-header__topbar-username {
   display: block !important;
   padding-right: 10px !important;
   line-height: 15px;
   .display-name {
      display: block;
      font-weight: 600;
   }
   .tenant-name {
      display: block;
      font-size: 12px;
   }
}
.kt-header__topbar-user {
   .profile-picture-placeholder {
      align-self: center;
      height: 34px;
      width: 34px;
      border-radius: 4px;
      line-height: 34px;
      font-weight: 500;
   }
}

.kt-menu__link[href="/lifecycle/policies/workspacereview"] {
   .kt-menu__link-text {
      &:after {
         content: "New";
         background-color: rgb(226, 226, 143);
         margin-left: 15px;
         padding: 2px 7px;
         color: rgb(142, 142, 73);
         font-size: 9px;
         font-weight: 700;
         text-transform: uppercase;
         border-radius: 3px;
      }
   }
}

#kt_content {
   > .kt-container {
      margin-bottom: 70px;
   }
}

.portlet-bold-text {
   .kt-portlet__body {
      font-weight: 500;
   }
}

.kt-portlet {
   .kt-portlet__head {
      border-bottom: none;
      position: relative;
      &:after {
         content: "";
         position: absolute;
         width: 100%;
         left: 0;
         bottom: -15px;
         height: 15px;
         background-image: linear-gradient(-180deg, #f2f4f5, white);
         // box-shadow: inset 0px 14px 17px -15px #0000001f;
      }
   }
}

.region-badges {
   color: rgb(75, 75, 75);
   > div {
      font-size: 10px;
      i {
         margin-right: 6px;
         width: 10px;
         opacity: 0.7;
      }
   }
}
.kt-notification__custom {
   .signout-button {
      flex: 1;
   }
}

#kt_aside_menu {
   li.display-false {
      display: none;
   }
}

.kt-header__topbar-item--langs {
   .lang-letters {
      background-color: #5967dd;
      border-radius: 3px;
      padding: 3px 5px;
      text-align: center;
      width: 27px;
      height: 27px;
      span {
         text-transform: uppercase;
         font-weight: 600;
         color: white;
         font-size: 10px;
      }
   }
   .kt-header__topbar-icon {
   }
   .dropdown-menu {
      .lang-letters {
         margin-right: 10px;
      }
      .kt-nav__link-text {
         font-weight: 500 !important;
      }
      .kt-nav__link--active {
         .kt-nav__link-text {
            color: #495057 !important;
            font-weight: 600 !important;
         }
      }
   }
}
