.settings-installation-status {
   .license-status {
      margin-top: 16px;
   }
   .buttons {
      .btn {
         margin-right: 10px;
      }
   }
}
