.apply-feature-file {
}

.apply-feature-file-modal {
   z-index: 1060 !important;
   .advanced-configuration {
      .lists-to-clone {
         background-color: #e6e6e6;
         border-radius: 4px;
         padding: 15px;
         margin-bottom: 15px;
         .form-group {
            margin-bottom: 5px;
            &:last-child {
               margin-bottom: 0;
            }
         }
         .no-results {
            font-weight: 500;
         }
      }
      .toggle-title {
         font-weight: 500;
         margin-bottom: 10px;
         cursor: pointer;
         i {
            transition: all 0.3s;
            margin-right: 10px;
            transform: rotate(-90deg);
         }
      }
      &.show-advanced-configs {
         i {
            transform: rotate(0);
         }
      }
      .configs {
         background-color: #f7f7f7;
         border-radius: 4px;
         padding: 15px;
         > .form-group {
            margin-bottom: 20px;
         }
         label {
            font-weight: 500;
         }
         .checkbox-description {
            padding-left: 23px;
         }
         .list-col {
            .content {
               background-color: white;
            }
         }
      }
   }
   .action-buttons {
      margin-top: 20px;
      text-align: right;
      button {
         font-weight: 500;
      }
      .submit-button {
         &:disabled {
            cursor: default;
         }
      }
   }
   .feature-extraction-wrapper {
      .form-office-asset-picker {
         .selected-item {
            flex: 1;
            display: flex;
            > a {
               flex: 1;
               > .wrapper {
                  padding: 4px 15px;
                  padding-left: 55px;
                  display: block;
               }
            }
         }
      }
   }
}

.apply-feature-file-modal-backdrop {
   z-index: 1050 !important;
}
