.team-information-page-users {
   padding: 1.5rem;

   .main-container {
      --main-gap: 3rem;
      display: flex;
      flex-direction: column;
      gap: var(--main-gap);

      //report width override
      .lifecycle-report {
         .lifecycle-report-wrapper {
            padding: 0;
         }
      }

      .insights-report {
         &.no-results {
            display: none;
         }

         .list-of-members {
            position: relative;
            .title-right {
               position: absolute;
               top: -4px;
               right: 0;
               z-index: 1;
            }
         }
         .custom-header {
            display: flex;
            margin-bottom: 25px;
            .title-left {
               flex: 1;
               .top {
                  display: flex;
                  align-items: center;
                  margin-right: 130px;
                  // margin-bottom: 10px;
                  .title {
                     color: #007989;
                     font-size: 18px;
                     font-weight: 500;
                  }
                  .count {
                     background-color: rgba(0, 0, 255, 0.398);
                     border-radius: 4px;
                     margin-left: 8px;
                     font-size: 12px;
                     font-weight: 500;
                     padding: 3px 10px;
                     color: white;
                     line-height: 16px;
                     height: 21px;
                  }
               }
            }
         }
         .first-column {
            // padding-top: 36px;
         }
      }
   }
}
