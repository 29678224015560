@import "app/assets/common/bootstrap-media-mixins";
.guest-management-dashboard {
   position: relative;
   top: -8px;

   .stat-item {
      display: flex;
      .count {
         font-weight: 600;
         font-size: 15px;
      }

      .changed {
         font-weight: 600;
         font-size: 15px;
         min-width: 20px;
         padding-left: 10px;
      }
      .title {
         flex: 1;
         font-size: 15px;
         font-weight: 500;
      }
      .title-width {
         font-size: 15px;
         font-weight: 500;
         min-width: 80px;
      }
      .count-width {
         font-weight: 600;
         font-size: 15px;
         min-width: 80px;
         text-align: right;
         padding-right: 10px;
      }
      .icon {
         .fa-arrow-up {
            color: green;
         }
         .fa-arrow-right {
            color: rgb(14, 10, 248);
         }
         .fa-arrow-down {
            color: rgb(248, 10, 10);
         }
      }
   }

   .new-dashboard {
      .title-row {
         margin-top: 40px;
         &.top {
            margin-top: 0px;
         }
         margin-bottom: 20px;
         display: flex;
         align-items: center;
         @include media-breakpoint-down(sm) {
            display: block;
         }
         .left {
            font-weight: 700;
            font-size: 20px;
            color: #26323a;
            flex: 1;
         }
         .right {
            width: 200px;
            .header-text {
               font-weight: 500;
            }
         }
         &:first-child {
            margin-top: 0;
         }
      }

      .top-area {
         display: flex;
         align-items: center;
         margin-bottom: 20px;
         margin-top: 2px;
         .title-row {
            flex: 1;
            margin-bottom: 0;
         }
         .recommended-actions-button {
            float: right;
            margin: 0;
            padding: 0;
            border: none;
            .text {
               font-weight: 500;
            }
            .fa-screwdriver-wrench {
               margin-top: -2px;
            }
            .fa-chevron-right {
               margin-left: 5px;
               margin-top: 0px;
            }
         }
      }

      .button-custom {
         padding-left: 17px;
         padding-right: 10px;
         font-size: 13px;
      }

      .color-stat-box {
         padding: 20px 20px;
         display: flex;
         align-items: center;
         border-radius: 8px;
         box-shadow: 0px 1px 3px 0px #26262e12;

         // margin-bottom: 20px;
         .icon {
            width: 46px;
            height: 46px;
            border-radius: 6px;
            background-color: white;
            box-shadow: 0px 4px 8px -4px #00798926;
            text-align: center;
            line-height: 46px;
            img {
               width: 22px;
            }
         }
         .content {
            padding-left: 20px;
            .count {
               font-size: 30px;
               font-weight: 700;
            }
            .title {
               font-weight: 500;
               color: #26323a;
            }
         }
      }

      .status-row {
         display: flex;
         @include media-breakpoint-down(sm) {
            display: block;
         }
         > a {
            flex: 1;
            margin: 0 10px;
            @include media-breakpoint-up(sm) {
               &:last-child {
                  margin-right: 0;
               }
               &:first-child {
                  margin-left: 0;
               }
            }
         }

         &:not(:last-child) {
            margin-bottom: 20px;
         }

         .status-box {
            flex: 1;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            // padding: 20px 20px 25px 20px;
            padding: 25px 3px 30px 3px;
            text-align: center;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            position: relative;
            transition: all 0.3s;
            &:hover {
               // outline: 2px solid #d7dee6;
               // box-shadow: 0px 4px 4px rgba(225, 231, 238, 0.15);
            }
            @include media-breakpoint-down(sm) {
               margin-bottom: 20px;
            }
            .link {
               position: absolute;
               top: 6px;
               right: 6px;
               i {
                  font-size: 18px;
                  color: #718690;
                  opacity: 0.8;
               }
            }

            &::before {
               content: "";
               background-color: black;
               position: absolute;
               left: 0px;
               top: 0;
               bottom: 0;
               width: 4px;
               border-radius: 8px 0 0 8px;
               transition: all 0.3s;
            }
            &:hover {
               &::before {
                  width: 10px;
               }
            }

            .icon {
               font-style: normal;
               font-weight: 300;
               display: flex;
               align-items: center;
               text-align: center;
               position: absolute;
               top: 22px;
               left: 13px;
               // margin: -5px 0 0 -5px;
               i {
                  color: #26323a;
                  font-size: 18px;
               }
            }

            .count {
               margin-top: 10px;
               margin-bottom: 10px;
            }

            &.color-one {
               &::before {
                  background-color: #008f87;
               }
               .countwithcolor {
                  color: #008f87;
               }
               .icon i {
                  color: #008f87;
               }
            }
            &.color-two {
               &::before {
                  background-color: #008ba4;
               }
               .countwithcolor {
                  color: #008ba4;
               }
               .icon i {
                  color: #008ba4;
               }
            }
            &.color-three {
               &::before {
                  background-color: #0082b9;
               }
               .countwithcolor {
                  color: #0082b9;
               }
               .icon i {
                  color: #0082b9;
               }
            }
            &.color-four {
               &::before {
                  background-color: #4f74bd;
               }
               .countwithcolor {
                  color: #4f74bd;
               }
               .icon i {
                  color: #4f74bd;
               }
            }
            &.color-five {
               &::before {
                  background-color: #8b60ab;
               }
               .countwithcolor {
                  color: #8b60ab;
               }
               .icon i {
                  color: #8b60ab;
               }
            }

            &.color-six {
               &::before {
                  background-color: #ae4b85;
               }
               .countwithcolor {
                  color: #ae4b85;
               }
               .icon i {
                  color: #ae4b85;
               }
            }
            &.color-seven {
               &::before {
                  background-color: #af6a90;
               }
               .countwithcolor {
                  color: #af6a90;
               }
               .icon i {
                  color: #af6a90;
               }
            }
            &.color-eight {
               &::before {
                  background-color: #79385e;
               }
               .countwithcolor {
                  color: #79385e;
               }
               .icon i {
                  color: #79385e;
               }
            }
            &.color-nine {
               &::before {
                  background-color: #005c67;
               }
               .countwithcolor {
                  color: #005c67;
               }
               .icon i {
                  color: #005c67;
               }
            }
            &.color-ten {
               &::before {
                  background-color: #007988;
               }
               .countwithcolor {
                  color: #007988;
               }
               .icon i {
                  color: #007988;
               }
            }
            &.color-eleven {
               &::before {
                  background-color: #2ea78a;
               }
               .countwithcolor {
                  color: #2ea78a;
               }
               .icon i {
                  color: #2ea78a;
               }
            }
            &.color-twelve {
               &::before {
                  background-color: #5abf86;
               }
               .countwithcolor {
                  color: #5abf86;
               }
               .icon i {
                  color: #5abf86;
               }
            }
            &.color-thirteen {
               &::before {
                  background-color: #8ad47e;
               }
               .countwithcolor {
                  color: #8ad47e;
               }
               .icon i {
                  color: #8ad47e;
               }
            }
            &.color-forteen {
               &::before {
                  background-color: #bfe876;
               }
               .countwithcolor {
                  color: #bfe876;
               }
               .icon i {
                  color: #bfe876;
               }
            }
            &.color-fifteen {
               &::before {
                  background-color: #f9f871;
               }
               .countwithcolor {
                  color: #f9f871;
               }
               .icon i {
                  color: #f9f871;
               }
            }
            .count {
               // margin-top: 20px;
               color: #26323a;
               font-size: 30px;
               font-weight: 700;
               line-height: 30px;
            }

            .monthlycount {
               // margin-top: 20px;
               color: #26323a;
               font-size: 14px;
               font-weight: 500;
               line-height: 30px;
            }

            .text {
               font-weight: 400;
               font-size: 13.5px;
               text-align: center;
               color: #26323a;
            }

            &:hover {
               background-color: rgb(247, 247, 247);
            }

            .info-circle.info {
               position: absolute;
               top: 6px;
               right: 26px;
               i {
                  font-size: 18px;
                  color: #718690;
                  opacity: 0.8;
               }
            }
         }
      }

      .status-row.metrics {
         .status-box {
            &::before {
               display: none;
            }
            .text {
               font-weight: 500;
               font-size: 15px;
               color: #26323a;
            }
         }
      }

      .charts-row {
         // display: flex;
         margin-top: 25px;
         > div {
            @include media-breakpoint-down(sm) {
               margin-bottom: 20px;
            }
         }
         .pie-chart {
            // flex: 2;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 20px 20px 20px 20px;
            text-align: center;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            // margin: 0 10px;
            position: relative;

            .title {
               font-weight: 500;
               font-size: 16px;
               text-align: center;
               color: #26323a;
               margin-bottom: 30px;
            }
            .chart {
               .apexcharts-canvas {
                  margin: 0 auto 20px auto;
               }
            }
         }
         .bar-chart {
            // flex: 5;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 20px 20px 20px 20px;
            text-align: left;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            // margin: 0 10px;
            position: relative;
            .title {
               font-weight: 500;
               font-size: 16px;
               // text-align: center;
               color: #26323a;
            }
         }
      }

      .added-vs-deleted-stat-boxes {
         display: flex;
         flex-direction: column;
         min-height: 100%;
         height: 100%;
         .timeframe-picker {
            margin-bottom: 3px;
         }
         .stat-box {
            // flex: calc(1 - 20px);
            // background-color: #f2f3f8;
            background-color: #f7f7f7;
            padding: 20px;
            border-radius: 6px;
            margin-top: 20px;
            position: relative;
            &:first-child {
               margin-top: 0;
            }
            .stat-number {
               color: #26323a;
               font-size: 20px;
               font-weight: 700;
               line-height: 20px;
            }
            .stat-title {
               font-weight: 500;
               font-size: 14px;
               // text-align: center;
               color: #26323a;
               margin-top: 5px;
            }
            .prior-period {
               font-size: 12px;
               opacity: 0.8;
            }
            .change-stat-box {
               .change-stat {
                  position: absolute;
                  right: 10px;
                  top: 10px;
                  display: flex;
                  font-size: 12px;
                  .icon {
                     margin-right: 5px;
                  }
                  .count {
                     font-weight: 500;
                  }
               }
               &.up {
                  .down {
                     display: none;
                  }
                  .none {
                     display: none;
                  }
                  .change-stat {
                     color: #94bf2d;
                  }
                  .top-row {
                     .icon {
                        color: #94bf2d;
                     }
                  }
               }
               &.down {
                  .up {
                     display: none;
                  }
                  .none {
                     display: none;
                  }
                  .change-stat {
                     color: #d95867;
                  }
                  .top-row {
                     .icon {
                        color: #d95867;
                     }
                  }
               }
               &.none {
                  .change-stat {
                     display: none;
                  }
                  .icon {
                     color: #9eacb2;
                     .down,
                     .up {
                        display: none;
                     }
                  }
               }
            }
         }
      }
      .chart-disclaimer {
         text-align: center;
         margin-top: 25px;
         font-style: italic;
      }
   }

   .crawl-still-in-progress {
      position: absolute;
      top: 0;
      left: -10px;
      right: -20px;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 10;
      border-radius: 6px;
      // backdrop-filter: blur(5px);
      text-align: center;
      padding-top: 50px;
      i {
         font-size: 50px;
         opacity: 0.7;
         background-color: white;
      }
      .title {
         font-size: 22px;
         font-weight: 600;
         max-width: 500px;
         margin: 0 auto 20px auto;
         background-color: white;
      }
      .desc {
         font-size: 14px;
         font-weight: 500;
         max-width: 500px;
         margin: 0 auto;
         opacity: 0.7;
         background-color: white;
      }
   }
}

.guest-management-dashboard-content-wrapper {
   background-color: white;
   transition: all 0.3s;
}

.guest-management-dashboard-recommended-deletions {
   margin-right: 15px;
   .export-button {
      display: flex;
      justify-content: flex-start;
      margin-right: 0;
      border-radius: 3.25px;
      align-items: center;
      font-weight: 500;
      .loading {
         margin-right: 10px;
         .animated-loader {
            height: 16px;
            width: 16px;
         }
      }
      .icon {
         margin-right: 6px;

         i {
            font-size: 16px;
         }
      }
      &:hover {
         background-color: #f8f8f8;
         cursor: pointer;
      }
      .text {
         font-weight: 500;
         font-size: 13px;
      }
   }
}

.orchestry-guest-dashboard-line-chart-hover {
   display: flex;
   align-items: center;
   background-color: white;
   padding: 3px 8px;
   border-radius: 4px;
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.194);
   .color-icon {
      width: 15px;
      height: 15px;
      display: block;
      margin-right: 6px;
   }
   .title {
      margin-right: 6px;
      font-size: 13px !important;
      font-weight: 300;
      display: block;
   }
   .value {
      font-size: 13px !important;
      display: block;
      font-weight: 600;
   }
}
