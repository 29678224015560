@import "app/assets/common/bootstrap-media-mixins";
.form-user-group-select {
   &.hidden {
      display: none;
   }

   .user-select-dropdown-wrapper {
      .link {
         display: inline-block;
      }
      &.inline-button {
         display: none;
      }
   }
   .inline-add-user {
      border: 1px dashed #fd397a7b;
      background-color: rgba(255, 255, 255, 0.282);
      cursor: pointer;
      border-radius: 5px;
      vertical-align: top;
      display: inline-flex;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;
      align-items: center;
      .inline-icon {
         width: 24px;
         height: 24px;
         border-radius: 50%;
         border: 1px dashed #fd397a7b;
         // background-color: lightgray;
         display: inline-block;
         vertical-align: top;
         position: relative;
         margin-left: 8px;
         text-align: center;
         i {
            text-align: center;
            margin: 0 auto;
            line-height: 22px;
            opacity: 0.3;
            transition: all 0.3s;
            font-size: 12px;
         }
      }
      .inline-text {
         padding: 9px 9px 8px 9px;
         flex: 1 1;
         font-size: 13px;
         font-weight: 500;
         vertical-align: top;
         color: #26323a85;
         transition: all 0.3s;
         min-width: 115px;
      }
      &:hover {
         i {
            opacity: 1;
         }
         .inline-text {
            color: #26323ad7;
         }
      }
   }
   .form-label {
      color: #414141;
   }
   .add {
      margin-top: -1px;
      margin-left: 6px;
      vertical-align: top;
      height: 18px;
      display: inline-block;
      width: 18px;
      line-height: 18px;
      text-align: center;
      background-color: transparent;
      line-height: 25px;
      cursor: pointer;
      svg {
         color: #08976d;
         width: 16px;
         height: 16px;
      }
   }
   .add-button {
      display: inline-block;
      background-color: #28b976;
      color: white;
      padding: 5px 10px;
      margin-bottom: 10px;
      font-weight: 500;
      border-radius: 3px;
      transition: background-color 0.5s;
      cursor: pointer;
      span {
         margin-left: 7px;
      }
      svg {
         position: relative;
         top: 1px;
      }
      &:hover {
         background-color: rgb(25, 148, 90);
      }
   }

   .group-content {
      @include media-breakpoint-down(md) {
         margin-bottom: 10px;
      }
   }

   .group-area {
      //
      //   background-color: whitesmoke;
      padding: 10px;
      padding-bottom: 0px;
      background: rgba(238, 241, 245, 0.75);
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px #e1e7ee40;
      border-radius: 5px;
      // min-height: 100px;
      height: auto !important;
      // min-height: 53px;
      .no-results {
         opacity: 0.5;
         font-style: italic;
         margin-bottom: 6px;
         padding-top: 8px;
      }

      .pic {
         margin-left: 9px;
      }
   }
}
