.channel-details {
   .right-col {
      padding-top: 30px;
   }
   h3 {
      margin-top: 20px;
      margin-bottom: 20px;
   }

   .at-search {
      margin-bottom: 20px;
   }
   .at-count {
      margin-bottom: 10px;
   }
   .at-items {
      background-color: #f7f7f7;
      padding: 0 15px;
      border-radius: 4px;
      .selected-items li {
         font-weight: 600;
         padding: 12px 0 11px 0;
      }
      .no-templates {
         padding: 15px 0;
         .help-text {
            margin-bottom: 10px;
         }
      }
   }
}
