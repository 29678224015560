@import "_metronic/_assets/sass/breakpoints";

.lifecycle-report-paging {
   display: flex;
   margin-top: 25px;
   margin-bottom: 25px;
   transition: all 0.3s;
   align-items: center;
   justify-content: center;
   justify-items: center;

   @include kt-mobile {
      padding: 0 20px;
   }

   .left {
      .pages-per-result {
         display: flex;
         span {
            padding: 9px 12px;
            color: #26323a;
            font-weight: 500;
            @include kt-mobile {
               display: none;
            }
         }
         .form-control {
            width: 75px;
            height: 35px;
            padding: 0.7rem;
            font-weight: 500;
         }
      }
   }
   .middle {
      flex: 1;
      text-align: center;
      .total-count {
         margin: 0 auto;
         display: inline-block;
         font-weight: 500;
      }
   }
   .right {
      display: flex;
      .button-prev {
         border: 1px solid #e1e7ee;
         border-radius: 5px;
         width: 84px;
      }
      .buttons-paging {
         display: flex;
         margin: 0 10px;

         > span {
            > div {
               border-top: 1px solid #e1e7ee;
               border-left: 1px solid #e1e7ee;
               border-bottom: 1px solid #e1e7ee;
               // width: 0;
               // overflow: hidden;
               animation: width-open 0.3s ease;
               @keyframes width-open {
                  0% {
                     opacity: 0;
                     //  width:0;
                     //  margin: 0;
                  }
                  100% {
                     opacity: 1;
                     //  width:45px;
                     //  margin: 0 10px;
                  }
               }
            }
            &:first-child {
               > div {
                  border-radius: 5px 0 0 5px;
               }
            }
            &:last-child {
               > div {
                  border-radius: 0 5px 5px 0;
                  border-right: 1px solid #e1e7ee;
               }
            }
            &.only-one {
               border-radius: 5px;
            }
         }
      }
      .button-next {
         border: 1px solid #e1e7ee;
         border-radius: 5px;
         width: 84px;
      }

      .button-wrapper {
         width: 84px;
         overflow: hidden;
         transition: all 0.3s;
         &.hidden {
            width: 0;
         }
      }

      .button-prev,
      .button-next,
      .buttons-paging .page-number {
         padding: 9px 15px;
         background: #ffffff;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         font-weight: 500;
         cursor: pointer;
         transition: all 0.3s;
         text-align: center;
         &:hover {
            background-color: rgb(248, 248, 248);
         }
      }
      .page-number.current-number {
         cursor: default;
         background: #f0f2f6;
         &:hover {
            background-color: #f0f2f6;
         }
      }
   }

   &.dir-loading {
      opacity: 0.4;
   }
}
