@import "app/assets/common/bootstrap-media-mixins";
.request-details-header {
   .progress-report {
      .task-title {
         font-weight: 500;
      }
      .sub-task-title {
         font-weight: 500;
      }
   }
   .kt-widget__media {
      .preview-image {
         width: 80px;
         height: 80px;
         border-radius: 4px;
         background-position: center center;
         background-size: cover;
         box-shadow: inset 0 0 1px 1px #0000001f;
      }
      .kt-media--circle {
         span {
            border-radius: 4px;
         }
      }
   }
   @include media-breakpoint-down(sm) {
      .kt-widget.kt-widget--user-profile-3 .kt-widget__top {
         display: block;
         .kt-widget__media {
            margin-top: 0;
            margin-bottom: 20px;
            text-align: center;
            .preview-image {
               width: 150px;
               height: 150px;
               margin: 0 auto;
            }
            .kt-media {
               margin: 0 auto;
               span {
                  width: 150px;
                  height: 150px;
                  margin: 0 auto;
               }
            }
         }
         .kt-widget__content {
            margin-left: 0;
            .form-group {
               margin-bottom: 20px;
            }
         }
      }
   }

   .icon-sp-type {
      display: inline-block;
      margin-right: 6px;
      width: 20px;
      img {
         height: 18px;
         width: 18px;
      }
   }
   .sensitivity-type {
      display: inline-block;
      margin-right: 6px;
      width: 20px;
      i {
         font-size: 18px;
      }
   }
   .ws-permission-type {
      display: inline-block;
      margin-right: 6px;
      width: 20px;

      svg {
         height: 18px;
         width: 18px;
      }
   }
   .form-group-template {
      .rd-title {
         label {
            font-size: 15px;
         }
      }
      .rd-value {
         a,
         label {
            font-size: 15px;
         }
      }
   }

   .site-url-validation {
      margin-top: 5px;
      // margin-bottom: 10px;
      .url-loading {
         // padding-top:10px;
         .validator-collapse {
            display: none;
         }
      }
      .url-validation {
         .url {
            font-size: 11px;
            font-weight: 400;
            opacity: 0.7;
         }
         .results {
            // margin-top: 5px;
            font-size: 11px;
            font-weight: 500;
            .is-valid {
               color: #107c10;
            }
            .is-not-valid {
               color: rgb(167, 28, 28);
            }
         }
      }
   }
   &.edit-mode-on {
      .kt-widget__username {
         input {
            font-size: 1.3rem;
            color: #48465b;
            font-weight: 500;
         }
      }
      .form-group {
         margin-bottom: 10px;
      }
      .edit-mode-locked {
         border: 1px solid #d6d6d6;
         border-radius: 4px;
         display: block;
         width: 100%;
         height: calc(1.5em + 1.3rem + 2px);
         padding: 0.65rem 1rem;
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.5;
         color: #495057;
         background-color: #f3f3f3;
         background-clip: padding-box;
      }
   }
}
