.form-imageuploadbox {
   outline: none !important;
   .imageupload {
      border: 1px dashed #b5b5b5;
      background-color: white;
      box-shadow: 0px 20px 30px rgba(0, 69, 117, 0.1);
      padding: 50px 0;
      outline: none !important;
      cursor: pointer;
      .icon {
         color: #ffa530;
         width: 30px !important;
         height: 30px;
         margin: 0 auto;
         svg {
            width: 28px;
            height: 28px;
         }
         i {
            font-size: 30px;
         }
      }
      .text {
         padding-top: 15px;
         text-align: center;
         font-size: 14px;
         color: #b5b5b5;
      }

      &.has-image {
         cursor: default;
         padding: 0;
         border: 1px solid #b5b5b5;

         .image-preview {
            position: relative;
            height: 100%;
            width: 100%;
            // height: 300px;
            // width: 100%;
            // background-size: cover;
            // background-position: center center;
            div {
               height: 168px;
            }
            img {
               width: 100%;
               height: 100%;
            }
            .delete-icon {
               position: absolute;
               background: #3d4e70;
               border-radius: 50%;
               width: 36px;
               height: 36px;
               bottom: 10px;
               right: 10px;
               box-sizing: content-box;
               transition: all 0.5s;
               cursor: pointer;
               &:hover {
                  background: rgb(84, 105, 146);
               }
               i {
                  font-size: 24px;
                  color: white;
                  margin: 6px 0 0 5.5px;
               }
               svg {
                  width: 20px;
                  height: 17px;
                  color: white;
                  margin: 9px 0 0 8px;
               }
            }
            &.is-png {
               img {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  z-index: 100;
               }

               .checkerboard {
                  position: absolute;
                  border-radius: inherit;
                  inset: 0;
                  z-index: 0;
                  height: 100%;
                  width: 100%;
                  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAHiSURBVFhH7ZhbbsIwEEWx8wIEEnth/xtA/WIBLIEfVAgNTjiJLRWhVMq4g5TSHLmueWR6fWcycmp2u4+ZDvV2u/UrY0xd18z+pRAbflRomiasNFCTpcskS8I7y6q7ocm7JzG2S/WjJmuEfYsgysWgHE6LSZaESZYENVl9fSu++6vJ+kPnLYJHxn+prHhUZE0niGHo++RjjtUtbmwh7mF8k2XZ9Xq11t5ut66LJd2arbNz0ZgRwTgn7Tf9WSvL8nA4nE6nNE2TJLtcLnmeo4zn2PCNYThXrddr3Hrc/ZDRz3K5RBMasqwgOubhWOecDK46Ho+WXyo455gXi0VVVViFT8A74a8NBqvwu82lCkmSpGlOysBb5UstfDwYrGJWk1WWX6QMTQjyVUVmw2cS5vM5hrX/WglvDMV//3k/TWP2+z3RSCIzsvCPWVpexrSbiWgQ/ZAuSor7sSgK3GpDW+sLTgRXsbGIJHJJz1WYTqlSVQRFDVZF3IbgS1Ottp7Av7CS03ofliNjkiXh/WXRz6Qt8Ef+WRJpjGElp22qYanNePoWodSivcqtXzLJkjDJkoAsndEdAz+ZeT7wz0isu6Om7/5DBw9znL2JqAMPFJvNhgPg+XxGI0dy2g8vmUVwxF2tVnf4dNuRsQsz5AAAAABJRU5ErkJggg==");
                  background-size: 3px 3px;
                  background-repeat: repeat;
               }
            }
         }
      }
   }
}
