.directory-config {
   .form-group {
      margin-bottom: 10px;
      &:last-child {
         margin-bottom: 0;
      }
   }
   .select {
      option {
         font-feature-settings: "liga", "clig off" !important;
         font-variant-ligatures: no-common-ligatures !important;
      }
   }
   .indexing-portlet {
      p {
         font-weight: 400;
         color: black;
      }
      .import-newly-created {
         label {
            font-weight: 500;
         }
      }
      .progress-fullbar {
         margin-top: 15px;
         font-weight: 500;
      }
      .btn-light {
         border: 1px solid rgb(228, 228, 228);
         cursor: default;
      }
   }
   .display-options {
      p {
         font-weight: 400;
         color: black;
      }
   }
   .column-choices {
      .report-column-setting {
         margin-bottom: 10px;
         padding-bottom: 10px;
         border-bottom: 1px solid #ebedf2;
         &:last-child {
            margin-bottom: 0;
            border-bottom: 0px solid transparent;
         }
         .title {
            font-weight: 600;
            padding-bottom: 5px;
         }
         .checks {
            padding-left: 0px;
         }
         .form-group {
            margin-bottom: 0 !important;
         }
      }
   }
}
