@import "app/assets/common/bootstrap-media-mixins";

$container-gap: 1.25rem;

.step-card-guest-request-policy-execution {
   .form-group {
      margin: 0;
   }
   .middle {
      font-weight: 500;
      color: #718690;
      font-size: 13px;

      .main-container {
         &.show {
            display: flex;
            flex-direction: column;
            gap: 25px;
         }
      }

      p {
         margin: 0;
      }

      .require-approvals-container,
      .policy-approval-container,
      .escalate-to-container,
      .if-no-action-container,
      .webhook-container {
         padding: 25px;
         border: 1px solid rgb(225, 231, 238);
         border-radius: 5px;
      }

      .policy-approval-container {
         h4 {
            margin: 0;
         }
         .user-select-row {
            margin-bottom: 20px;
         }
         .editor-workspace {
            color: #646c9a;
            p {
               font-weight: 400;
            }
         }
      }

      .span-label {
         color: #414141;
         font-size: 14px;
         font-weight: 500;
      }

      label {
         font-size: 14px;
      }

      .description-text {
         color: #646c9a;
         font-size: 13px;
         .alert-content {
            margin: auto;
         }
      }

      .escalate-to-container {
         padding-left: 35px;
         .col {
            padding: 0;
         }
      }

      .policy-approval-container,
      .webhook-container,
      .require-approvals-container {
         display: flex;
         flex-direction: column;
         gap: $container-gap;
      }

      .if-no-action-container {
         display: flex;
         flex-direction: column;
      }

      .retry-cadence-container {
         display: flex;
         flex-direction: column;
         .row:nth-child(3) {
            margin-bottom: 15px;
         }
      }

      .retry-container {
         display: flex;
         gap: 1.5rem;
         align-items: center;
         input[type="number"] {
            flex-basis: 100px;
         }
         .label {
            margin: 0;
         }
      }

      .webhook-container {
         p {
            color: #718690;
            margin-bottom: 13px;
         }
      }

      .execution-not-required {
         display: flex;
         flex-direction: column;
         gap: $container-gap;
         .description-text {
            display: flex;
            align-items: center;
            gap: 1rem;
            p {
               font-size: 14px;
               margin: 0;
            }
            .notification-icon {
               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 22px;
               min-width: 64px;
               min-height: 64px;
               background-color: #d7e9ec;
               border-radius: 5px;
               i {
                  background-color: white;
                  font-size: 32px;
                  color: #007989;
                  border-radius: 50%;
               }
            }
         }
         //border settings for subcontainers
      }
   }
}
