.principal-item-guest {
   background: #ffffff;
   border: 1px solid #dde2e7;
   box-sizing: border-box;
   border-radius: 5px;
   // height: 33px;
   vertical-align: top;
   display: inline-flex;
   // padding-right: 14px;
   margin-right: 10px;
   margin-bottom: 10px;
   position: relative;
   align-items: center;
   .pic {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgb(211, 211, 211);
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin-left: 8px;
      // margin-top:3px;
      // margin-left:3px;
      // border:1px solid #D5D1D2;
      > div {
         border-radius: 50%;
         font-size: 11px;
      }
      > img {
         width: 100%;
         height: 100%;
         border-radius: 50%;
      }
      .principal-type {
         position: absolute;
         bottom: -1px;
         right: -5px;
         width: 12px;
         height: 12px;
         background-color: #0abb87;
         font-size: 9px;
         color: white;
         line-height: 12.5px;
         font-weight: 500;
         margin: 0 auto;
         text-align: center;
         border-radius: 50%;
         border: 1px solid white;
         box-sizing: content-box;
         z-index: 93;
         &.type-group {
            background-color: rgb(121, 121, 231);
         }
         &.type-securitygroup {
            background-color: rgb(237, 105, 105);
            // font-size:8px;
         }
         &.type-distributiongroup {
            background-color: rgb(230, 182, 110);
            // font-size:8px;
         }
      }
   }
   > span {
      padding: 9px;
      flex: 1;
      // line-height: 33px;
      font-size: 13px;
      font-weight: 500;
      vertical-align: top;
      // margin-left:8px;
      color: #26323a;
   }

   .guest-note {
      margin-left: 3px;
      font-weight: 500;
      opacity: 0.6;
      // font-size: 11px;
   }

   .locked {
      padding: 9px 12px 9px 4px;
      font-size: 10px;
   }

   .remove {
      // display: none;
      // background-color: white;
      // position: absolute;
      // z-index: 1;
      // top:-1px;
      // right:0;
      // // right: -20px;
      // width: 33px;
      // height: 33px;
      // text-align: center;
      // line-height: 34px;
      // line-height: 31px;
      // border-radius: 0 16px 16px 0;
      // border-top: 1px solid #E0E0E0;
      border-left: 1px solid #e0e0e0;
      // border-bottom: 1px solid #E0E0E0;
      padding: 9px 12px;
      cursor: pointer;
      > svg {
         height: 12px;
         width: 12px;
         opacity: 0.5;
      }
   }
   &:hover {
      .remove {
         // display: block;
         > svg {
            color: red;
         }
      }
   }

   &.no-border {
      border: none;
      .pic {
         margin-left: 0;
      }
      > span {
         padding-top: 0;
         padding-bottom: 0;
      }
   }
   &.size-sm {
      .pic {
         width: 20px;
         height: 20px;
         .profile-picture {
            width: 20px;
            height: 20px;
            &.two-letter {
               line-height: 20px;
               font-size: 8px;
               font-weight: 500;
            }
         }
         > span {
            font-size: 12px !important;
         }
      }
   }
}

.principal-item-guest-pic {
   .pic {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgb(211, 211, 211);
      display: inline-block;
      vertical-align: top;
      position: relative;
      // margin-left: 8px;
      // margin-top:3px;
      // margin-left:3px;
      // border:1px solid #D5D1D2;
      > div {
         border-radius: 50%;
         font-size: 11px;
      }
      > img {
         width: 100%;
         height: 100%;
         border-radius: 50%;
      }
   }
}
