.tenant-utilization {
   .mantine-DonutChart-root {
      min-height: 248px;
      min-width: 300px;
   }

   .mantine-DonutChart-label {
      font-size: 20px;
      font-weight: 500;

      //Hack to transform text so it is centered in half donut chart
      transform: translate(0, -30px);
   }

   // Below classes are a hack because even though only 50% of donut chart is filled, it still takes up the whole space
   svg {
      position: relative;
      top: 20%;
   }

   .donut-container {
      .mantine-Skeleton-root {
         position: relative;
      }

      overflow: hidden;
   }
}
