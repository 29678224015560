.teams-app-config {
   .teams-app-customization {
      .outline-upload {
         display: flex;
         flex-direction: column;
         gap: 0.5rem;
         $width: 32px;
         $height: 32px;

         .form-imageuploadbox {
            width: $width;
            height: $height;
            .imageupload {
               padding: 0;
               width: $width;
               height: $height;
               .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  i {
                     font-size: 25px;
                  }
               }

               .image-preview {
                  width: $width;
                  height: $height;
                  border-radius: inherit;
                  .delete-icon {
                     z-index: 101;
                     bottom: -20px;
                     right: -20px;
                     transform: scale(0.5);
                  }
               }
            }
         }
      }
      .colour-upload {
         display: flex;
         flex-direction: column;
         gap: 0.5rem;
         $width: 192px;
         $height: 192px;
         .form-imageuploadbox {
            width: $width;
            height: $height;
            .imageupload {
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               width: $width;
               height: $height;

               .image-preview {
                  width: $width;
                  height: $height;
                  border-radius: inherit;
                  .delete-icon {
                     z-index: 101;
                     bottom: -15px;
                     right: -15px;
                  }
                  .checkerboard {
                     background-size: 32px 32px;
                  }
               }
            }
         }
      }
   }
}
