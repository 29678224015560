@import "app/assets/common/bootstrap-media-mixins";

.editor-workspace {
   width: 100%;

   .layout-config {
      display: flex;
      align-items: center;
      gap: 2.5rem;
      background-color: #646c9a;
      padding: 0.75rem 0.75rem;
      padding-left: 1.5rem;
      .label {
         font-size: 14px;
         color: azure;
      }
      .dropDown {
         width: 140px;
      }
   }

   .toolbar {
      // margin-top: 25px;
      border-top: 1px #e5e5e5 solid;
      border-left: 1px #e5e5e5 solid;
      border-right: 1px #e5e5e5 solid;
      border-radius: 6px 6px 0 0;
   }
   .editor {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      gap: 1.5rem;
      &.border {
         border-left: 1px #e5e5e5 solid;
         border-right: 1px #e5e5e5 solid;
         border-bottom: 1px #e5e5e5 solid;
         border-bottom-left-radius: 6px;
         border-bottom-right-radius: 6px;
      }
      padding: 1rem;
      &.no-text {
         justify-content: center;
         align-items: center;
      }
   }
   .imageUpload {
      // flex: 0 0 20%;
      align-self: center;
      &.hidden {
         display: none;
      }
   }
   .editable {
      width: 100%;
      box-sizing: border-box;
      cursor: text;
      &.hidden {
         display: none;
      }
      p {
         word-break: break-word;
      }
   }
}
