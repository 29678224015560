@import "app/assets/common/bootstrap-media-mixins";
.documents-modal {
    .modal-dialog {
        max-width: 1000px;
    }
    .row-top {
        position: relative;
        padding:30px 20px 0 20px;
        
        @include media-breakpoint-up(md) {
            // height: 100px;
            // padding-top: 15px;
        }
        @include media-breakpoint-between(xs, sm) {
            // padding-top: 15px;
        }
        h4 {
            // font-size: 20px;
            // line-height: 23px;
            // letter-spacing: 0.318182px;
            // color: #2D3036;
            font-weight: bold;
            font-size: 20px;
            line-height: 32px;
            font-feature-settings: 'liga' off;
            color: #26323A;
            @include media-breakpoint-between(xs, sm) {
                margin-bottom: 20px;
            }
        }
        .upload-new {
            
            cursor: pointer;
            @include media-breakpoint-up(md) {
                position: absolute;
                right:40px;
                top:27%;
            }
            @include media-breakpoint-between(xs, sm) {
                // position: absolute;
                // right:40px;
                
                // top:27%;
            }
            svg {
                color:#FFA530;
                margin-right: 10px;
                width: 20px;
                height: 17px;
            }
            span {
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                letter-spacing: 0.254545px;
                color: #676767;
                font-weight: 600;
            }
            &:hover {
                svg {
                    color:rgb(223, 145, 43);
                }
                span {
                    color: #505050;
                }
            }
        }
        .actions {
            display: flex;
            .search {
                flex: 1;
                .searchBox {
                    // padding-top: 5px;
                    input {
                        width: 100%;
                        padding: 8px 40px 8px 12px;
                        height: 36px;
                        font-size:14px;
                        font-feature-settings: "liga", "clig off" !important;
                        font-variant-ligatures: none !important;
                        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: #9DA9AE;
                            font-weight: 500;
                            font-size:12px;
                            opacity: 1; /* Firefox */
                        }
                        
                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: #9DA9AE;
                            font-size:12px;
                            font-weight: 500;
                        }
                    
                        &::-ms-input-placeholder { /* Microsoft Edge */
                            color: #9DA9AE;
                            font-size:12px;
                            font-weight: 500;
                        }
                    }
                    .icon-search {
                        position: absolute;
                        left:auto;
                        right:8px;
                        top:2px;
                    }
                }
                margin-right: 10px;
            }
            .nav-items {
                flex: 1;
                display: flex;
                > div {
                    flex: 1;
                    background: #FFFFFF;
                    padding: 0.65rem 0.45rem;
                    border: 1px solid #E1E7EE;
                    box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
                    font-weight: 600;
                    font-size:12px;
                    text-align: center;
                    background-color: #F6F8F9;
                    cursor: pointer;
                    transition: all 0.3s;
                    &.suggested {
                        border-radius: 5px 0px 0px 5px;
                        margin-right: 3px;
                    }
                    &.all {
                        border-radius: 0px 5px 5px 0px;

                    }
                    &.selected {
                        background-color: white;
                        border-color:#44b544;
                        color:#44b544;
                        cursor: default;
                    }
                }
            }
        }
    }
    .row-navigation {
        .tabbed-navigation {
            padding: 15px 20px 0 20px !important;
            
            @include media-breakpoint-up(md) {
                // position: absolute;
                // bottom: 0;
                // left:0px;
            }
            @include media-breakpoint-between(xs, sm) {
                display: block;
                margin: 10px 0 0 0;
            }
            .nav {
                .nav-item {
                    margin: 0;
                    margin-right: 30px;
                    a.nav-link {
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.286364px;
                        font-weight: 600;
                        color: #414141;
                        border-bottom-width:2px;
                        // padding: 10px 30px !important;
                        padding-bottom: 12px;
                        transition: all 0.3s;
                        @include media-breakpoint-between(xs, sm) {
                            padding: 10px 0px !important;
                            margin-right: 20px;
                        }
                        &.active {
                            border-bottom-color:#007989;
                        }
                    }

                }
            }
            
        }
    }
    .row-middle {
        background: rgba(255, 255, 255, 0.95);
        border-top:1px solid #E1E7EE;
        min-height: 450px;
        .loader {
           margin: 50px auto;
        }
        .ScrollbarsCustom  {
            min-height: 450px;
            .ScrollbarsCustom-TrackY {
                width: 7px !important;
                // height: 100% !important;
                // top:0 !important;
                right: -10px !important;
                opacity: 0;
                transition: all 0.3s;
                background-color: transparent !important;
                .ScrollbarsCustom-ThumbY {
                    background-color: #D9D9DC !important;
                }
            }
            &:hover {
                .ScrollbarsCustom-TrackY {
                    opacity: 1;
                }
            }
        }
        .no-documents {
            // background-color: white;
            display: block;
            // opacity: 0.3;
            color:black;
            // padding: ;
        }
        .no-categories {
            padding: 60px 15px 60px 15px;
            text-align: center;
        }
        .document-list {
            min-height: 150px;
            .no-preview {
                display: none;
            }
            
            .no-results {
                padding: 60px 15px 0 15px;
                text-align: center;
            }
            .document-wrapper {
                position: relative;
                padding-top: 40px;
                
                @include media-breakpoint-up(md) {
                    width:800px;
                    left:50%;
                    margin-left: -400px;
                }
                @include media-breakpoint-between(xs, sm) {
                    // margin:0 10px;
                    overflow-x: hidden;
                    width: 100%;
                }
                .cat-title {
                    margin: 20px 0 10px 0;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0.222727px;
                    color: #676767;
                }
                ul {
                    @include media-breakpoint-between(xs, sm) {
                        width: calc(100% + 50px);
                    }
                    li {
                        vertical-align: top;
                        @include media-breakpoint-up(md) {
                            width:160px;
                            display: inline-block;
                            margin-right: 40px;
                            margin-bottom: 30px;
                        }
                        @include media-breakpoint-between(xs, sm) {
                            width:145px;
                            display: inline-block;
                            margin-right: 20px;
                            margin-left: 2px;
                            // margin-left: 10px;
                            margin-bottom: 15px;
                            vertical-align: top;
                        }
                        cursor: pointer;

                        input {
                            position: absolute;
                            opacity: 0;
                            font-feature-settings: "liga", "clig off" !important;
                            font-variant-ligatures: none !important;
                        }
                        .document-thumb {
                            
                            
                        }
                        .document-template-preview {
                            .preview {
                                width:100%;
                                height: 200px;
                                background-color: white;
                                transition: all 0.2s;
                                border: 2px solid #D7DEE5;
                                border-radius: 4px;
                                img {
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .file-name {
                                font-size: 14px;
                                font-weight: 600;
                                color: #21292D;
                            }
                            .file-type-icon-width-text {
                                font-size:13px;
                                font-weight: 500;
                                margin-top: 0;
                                
                                i {
                                    font-size:16px;
                                    // display: none;
                                }
                                span {
                                    color: #718690 !important;
                                    line-height: 20px;
                                }
                                .doc-icon  {
                                    // display: none;
                                    zoom: 0.9;
                                    position: relative;
                                    top:-1px;
                                }
                            }
                            .large-preview-icon {
                                > div {
                                    height: 100%;
                                    .doc-icon {
                                        background-color: white;
                                        border-radius: 5px;
                                        height: 100%;
                                        padding: 55px 0;
                                    }
                                }
                            }
                        }
                        .text {
                            margin-top: 3px;
                            font-size:16px;
                            font-weight: 600;
                        }
                        .document-type {
                            margin-top: 3px;
                            .doc-type-icon {
                                max-width: 20px;
                                max-height: 20px;
                                > div {
                                    display: inline-block;
                                }
                                .doc-icon {
                                    display: inline-block;
                                    margin-right: 5px;
                                }
                                img {
                                    max-width: 18px;
                                    max-height: 18px;
                                    margin-right: 8px;
                                }
                            }
                            .doc-type-name {
                                font-size: 14px;
                                line-height: 16px;
                                letter-spacing: 0.222727px;
                                color: #676767;
                                font-weight: 600;
                            }
                        }
                        &.selected .preview {
                            //outline:2px solid #3DA0A2;
                            box-shadow: 0px 0 0px 3px #3DA0A2;
                        }

                        &.is-already-ass {
                            cursor: default;
                            opacity: 0.5;
                        }

                    }
                }
            }
        }

        .tab-content {
            background-color: transparent;
            // min-height: 450px;
        }
        .loading-box {
            text-align: center;
            padding: 50px 0;
        }
    }
    .row-bottom {
        padding: 10px 5px;
        margin: 0 -2px 8px -2px;
        background: rgba(238, 241, 245, 0.75);
        border: 1px solid #E1E7EE;
        box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
        backdrop-filter: blur(50px);
        /* Note: backdrop-filter has minimal browser support */
        
        border-radius: 5px;
        > .col-6 {
            &:first-child {
                padding-right: 0;
            }
            &:last-child {
                padding-left: 0;
                padding-right: 10px;
            }
        }
        .checkbox-col {
            display: flex;
            align-items: center;
         .select-infobox {
            margin-right: 15px;
               // font-family: ProximaNova;
               font-size: 12px;
               line-height: 21px;
               letter-spacing: 0.222727px;
               color: #414141;
               font-weight: 500;
               
               
               position: relative;
               
               @include media-breakpoint-up(md) {
                  height: 20px;
                  // top:50%;
                  // margin-top:-10px;
               }
               @include media-breakpoint-between(xs, sm) {

               }
               .template-selected {
                  @include media-breakpoint-up(md) {
                     margin-right: 20px;
                  }
                  @include media-breakpoint-down(md) {
                     display: block;
                     margin-bottom: 8px;
                  }
               }
               label {
                  cursor: pointer;
                  input {}
               }
               .box {
                  border: 1px solid #DDE2E7;
                  border-radius: 3px;
                  width: 17px;
                  height: 17px;
                  margin-top: -9.5px;
                  &:after {
                     top:6px;
                     left:3px;
                     width: 9px;
                  }
               }
         }
         }
        .actions {
            // position: absolute;
            // bottom: 22px;
            // right:22px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            .cancel {
                display: inline-block;
                color: #596A73;
                padding: 0.65rem 0.85rem;
                border-radius: 4px;
                border:2px solid #EDF0F2;
                cursor: pointer;
                transition: all 0.3s;
                background-color: white;
                &:hover {
                    border:2px solid rgb(218, 218, 218);
                }
            }
            .save {
                i {
                    font-size: 12px;
                    margin-right: 10px;
                }
                cursor: pointer;
                display: inline-block;
                font-weight: 500;
                background: #007989;
                border:2px solid #007989;
                border-radius: 4px;
                margin-left: 15px;
                padding: 0.65rem 0.85rem;
                color: #ffffff;
                transition: all 0.3s;
                &:not(.disabled):hover {
                    background: #0395a8;
                    border:2px solid #0395a8;
                }
                &.disabled {
                    background: #9CCBD1;
                    border:2px solid #9CCBD1;
                }
                .count {
                    margin: 0 2px;
                }
            }
        }
    }
}
.upload-modal {
    .row-top { 
        background-color:  #F1F1F4;
        padding: 20px 20px;
        height: 70px;
    }
    .kt-portlet {
        box-shadow: none;
        position: relative;
        
    }
    .documentTemplate-details  {
        .float-actions {
            position: absolute;
            top: -54px;
            right: 20px;
        }
    }
}