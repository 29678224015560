.health-check-pdf-page {
   & * {
      font-family: var(--mantine-font-family) !important;
   }

   -webkit-print-color-adjust: exact;
   print-color-adjust: exact;
   width: 1440px;
   margin: 0 auto; /* Center the content horizontally */

   #orchestry-usage-section,
   #workspaces-section,
   #benchmarks-section,
   #sharing-links-created-section,
   #storage-analysis-section,
   #teams-engagement-section,
   #leaderboard-section,
   #recommendations-section,
   #savings-section,
   #beacon-savings-section,
   #additions-annotations,
   #additions-custom-recommendations,
   #health-check-footer,
   [id*="-annotation"] {
      display: block;
      page-break-inside: avoid;
   }
}
