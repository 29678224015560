.lifecycle-guest-details-tab-overview {
   .show-report {
      // display: block;
   }
   .hide-report {
      display: none;
   }
   .lifecycle-report {
      .lifecycle-report-wrapper {
         padding: 15px;
         .loading-skeleton {
            padding: 0;
         }
         .principals-list .list .principal-item-pic .pic {
            border: 2px solid white;
         }
      }
   }
}
