@import "app/assets/common/bootstrap-media-mixins";
.circle-button {
    display: inline-block;
    background-color: rgba(61, 78, 112, 0.11);
    border-radius: 50%;
    width: 44px;
    height: 44px;
    vertical-align: top;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
        background-color: rgba(61, 78, 112, 0.20);
    }
    svg {
        height: 19px;
        width: 19px !important;
        margin: 13px;
        color:#3D4E70;

    }
    @include media-breakpoint-down(md) {
        zoom:0.9;
        width: 40px;
        height: 40px;
        svg {
            height: 15px;
            width: 15px !important;
            margin: 13px;
            color:#3D4E70;
        }
    }
}
.button-custom {
    
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    padding: 9px 21px;
    vertical-align: top;
    transition: all 0.5s;
    cursor: pointer;

    svg {
        transition: all 0.5s;
    }
    @include media-breakpoint-down(md) {
        zoom:0.9;
        padding: 7px 20px;
        height: 36px;
    }
    @include media-breakpoint-down(md) {
        zoom:0.9;
        padding: 7px 20px;
        height: 36px;
    }

    &:hover {
        text-decoration: none;
    }
    svg {
        margin-right: 7px;
        height: 14px;
        position: relative;
        top:1px;
    }
    &.b-orange {
        background: #d3902c;
        color:white;
        &:hover {
            background: rgb(237, 166, 59);
            color:white;
        }
        svg {
        }
    }
    &.b-grey {
        background: #ECEEF1;
        color:#414141;
        &:hover {
            background: rgb(211, 210, 210);
        }
        svg {
        }
    }
    &.b-dark-grey {
        background: rgba(181, 181, 181, 0.25);
        color:#414141;
        &:hover {
            background: rgb(211, 210, 210);
        }
        svg {
        }
    }
    &.b-green {
        background:#3DA0A2;
        color:white;
        &:hover {
            background:#53cdd0;
        }
        svg {
        }
    }
    &.b-green-alt {
        background: #28B976;
        color:white;
        &:hover {
            background:rgb(30, 149, 94);
        }
        svg {
        }
    }
    &.b-green-alt-outline {
        background:transparent;
        border:2px solid #28B976;
        color:#28B976;
        vertical-align: top;
        span {
            position: relative;
            top:-2px;
        }
        svg {
            color:#28B976;
            position: relative;
            top:-2px;
        }
        &:hover {
            background:#28B976;
            color: white;
            svg {
                color: white;
            }
        }

    }

    &.disabled {
        background: #ECEEF1 !important;
        cursor: default;
        > span {
            opacity: 0.5;
        }
        &:hover {
            background-color: #ECEEF1 !important;
        }
    }
}

.btn {
    &.btn-approve {

        background-color: rgb(18, 168, 98);
        color:white;
        &:hover {
            background-color: rgb(20, 180, 106);
        }
    } 
    &.btn-archive {

      background-color: rgb(18, 168, 98);
      color:white;
      &:hover {
          background-color: rgb(20, 180, 106);
      }
  }     
    &.btn-decline {
        background-color:#e06060;
        color:white;
        &:hover {
            background-color:#ee6a6a;
        }
    }
    &.btn-extend {
      background-color:#6097e0;
      color:white;
      &:hover {
          background-color:#5185c9;
      }
  }
   &.btn-delete {
      background-color:#df9797;
      color:white;
      &:hover {
         background-color:#d77d7d;
      }
   }

    &.size-small {
        padding: 0.35rem 0.75rem;
    }
}