@import "app/assets/common/bootstrap-media-mixins";
.popover.group-picker {
   background-color: white;
   border: 1px solid #28b976;

   &.bs-popover-right-start {
      min-width: 300px;
      margin-top: -14px;
      margin-left: 10px;
      .arrow {
         &:before {
            position: absolute;
            top: -2px;
            right: 11px;
            content: "";
            width: 10px;
            height: 10px;
            background-color: white;
            border-top: 1px solid #28b976;
            border-left: 1px solid #28b976;
            transform: rotate(-45deg);
         }
      }
   }
}

.group-content {
   @include media-breakpoint-down(sm) {
      background-color: white;
      border: 1px solid #28b976;
      border-radius: 5px;
   }
   .people-header {
      position: relative;
      padding: 10px;

      input {
         width: 100%;
         border: 0px solid transparent;
         outline: none;
         &:focus {
            border: 0px solid transparent;
            outline: none;
            @include media-breakpoint-down(sm) {
               box-shadow: none;
            }
         }
      }
      .icon {
         position: absolute;
         top: 50%;
         margin-top: -8px;
         right: 10px;
         svg {
            color: #b5b5b5;
            width: 17px;
            height: 17px;
         }
      }
   }
   .people-list {
      position: relative;
      // padding-top: 5px;
      &:before {
         content: "";
         position: absolute;
         top: 1px;
         left: 10px;
         right: 10px;
         height: 1px;
         border-top: 1px solid #ececec;
      }
      //@include media-breakpoint-up(md) {
      &.hide-bar {
         &:before {
            display: none;
         }
      }
      //}

      ul {
         margin-bottom: 0;
         .suggested-members {
            font-size: 12px;
            line-height: 17px;
            letter-spacing: -0.009091px;
            color: #676767;
            padding: 8px 8px 5px 8px;
         }
         li {
            padding: 5px 8px;
            // height: 34px;
            line-height: 33px;
            word-wrap: none;

            &:hover {
               cursor: pointer;
               background: rgba(61, 160, 162, 0.1);
            }
            &.selected {
               background: rgba(61, 160, 162, 0.1);
               cursor: default;
            }
            .pic {
               width: 25px;
               height: 25px;

               // background-color: rgb(211, 211, 211);
               display: inline-block;
               vertical-align: top;
               // position: relative;
               margin-top: 5px;
               margin-left: 3px;
               // border:1px solid #D5D1D2;
               margin-right: 10px;
               > div {
                  border-radius: 50%;
                  font-size: 11px;
                  font-weight: 600;
               }
               > img {
                  border-radius: 50%;
                  width: 25px;
                  height: 25px;
                  vertical-align: top;
               }
            }
            .name {
               display: inline-block;
               font-weight: 600;
            }
            .guest-note {
               margin-left: 3px;
               font-weight: 500;
               opacity: 0.6;
            }
            .job-title {
               margin-left: 13px;
               display: inline-block;
            }
            &.no-results {
               padding-top: 5px;
               text-align: center;
            }
            &.loader {
               padding-top: 14px;
               text-align: center;
            }
         }
      }
   }
}
