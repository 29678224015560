.team-information-title {
   color: #26323a;
   font-size: 24px;
   font-weight: 600;
   display: flex;
   align-items: center;
   .nested-title {
      margin-right: 15px;
      line-height: 24px;
   }
   .actions {
      display: inline;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      right: 13px;
      top: 10px;
      color: #8083a3;
      a {
         color: #8083a3;
         i {
            padding-top: 10px;
         }
      }
   }
}
