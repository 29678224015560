.recommendation-card-resolve-modal {
   font-family: var(--mantine-font-family);
   color: var(--mantine-color-text-secondary-0);
   font-size: var(--mantine-font-size-sm);
   .header {
      display: flex;
      align-items: center;
      .title {
         margin-left: 15px;
      }
   }
   .resolve-steps {
      position: relative;
      .video {
         margin-top: 20px;
         > * {
            border-radius: 8px;
            display: block;
            max-width: 100%;
         }
      }
      .steps {
         margin-top: 20px;
         a {
            color: var(--mantine-color-primary-9);
         }
         ul {
            padding-left: 20px;
            li {
               margin-bottom: 12px;
            }
         }
      }
      .link {
         margin-top: 20px;
      }
   }
   .description {
      margin-top: 10px;
   }

   .bottom-buttons {
      margin-top: 30px;
      display: flex;
      gap: 12px;
      justify-content: flex-end;
   }
   .thanks {
      padding: 15px;
      .orchestry-featured-icon {
         margin-bottom: 16px;
      }
   }
}
