.lifecycle-policy-assignments {
   .lifecycle {
      // padding: 24px;
      margin-bottom: 32px;
      h3 {
         // margin-bottom: 20px;
      }
      .lifecycle-policies {
         .status {
            .numbers {
               // margin: 16px;
               padding: 12px 20px;
               border-radius: 6px;
               background-color: #fde5e5;
               .big-number {
                  display: flex;
                  align-items: center;
                  padding-bottom: 12px;
                  margin-bottom: 12px;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                  .icon {
                     width: 55px;
                     .icon-square {
                        width: 40px;
                        height: 40px;
                        border-radius: 4px;
                        // background-color: white;
                        background-color: #648021bf;
                        text-align: center;
                        line-height: 48px;
                        box-shadow: inset 0px 1px 0px #0000001f;
                        i {
                           font-size: 22px;
                           color: white;
                        }
                        .loader {
                           padding-top: 4px;
                           .animated-loader {
                              border-top-color: transparent !important;
                              // margin-top: 2px;
                           }
                        }
                     }
                  }
                  .stats {
                     font-weight: 500;
                     flex: 1;
                     // padding-left: 15px;
                     .title {
                        color: #9f2727;
                        font-size: 14px;
                        line-height: 16px;
                        margin-bottom: 0px;
                     }
                     .number {
                        font-size: 26px;
                        line-height: 32px;
                        color: #26323a;
                     }
                  }
                  .date {
                  }
               }
               &.status-extended {
                  background-color: #bce8e3;
                  // color: #4b8a85;
                  .big-number {
                     .icon {
                        .icon-square {
                           background-color: #4b8a85;
                           i {
                              // color: #648021;
                           }
                        }
                     }
                     .stats {
                        .title {
                           color: #4b8a85;
                        }
                     }
                  }
               }
               &.status-active {
                  background-color: rgba(147, 191, 45, 0.15);
                  .big-number {
                     .icon {
                        .icon-square {
                           background-color: #648021bf;
                           i {
                              // color: #648021;
                           }
                        }
                     }
                     .stats {
                        .title {
                           color: #648021;
                        }
                     }
                  }
               }
               &.status-inactive {
                  // background-color: rgba(147, 191, 45, 0.15);
                  background-color: #e4cfb5;
                  .big-number {
                     .icon {
                        .icon-square {
                           // background-color: #648021bf;
                           background-color: #b36909;
                           i {
                              // color: #648021;
                           }
                        }
                     }
                     .stats {
                        .title {
                           // color: #648021;
                           color: #b36909;
                        }
                     }
                  }
               }
               &.status-pendingapproval {
                  background-color: rgba(210, 203, 255, 0.88);
                  .big-number {
                     .icon {
                        .icon-square {
                           background-color: rgba(135, 125, 201, 0.88);
                        }
                     }
                  }
               }
               &.status-deleted {
                  background-color: #f3eab1;
                  .big-number {
                     .icon {
                        .icon-square {
                           background-color: #d8cc7c;
                        }
                     }
                  }
               }
               &.status-failed {
                  background-color: #f5000030;
                  .big-number {
                     .icon {
                        .icon-square {
                           background-color: #d54949;
                        }
                     }
                  }
               }
               &.status-done {
                  background-color: rgba(7, 109, 44, 0.2);
                  .big-number {
                     .icon {
                        .icon-square {
                           background-color: #8ca391;
                           i {
                              // color: #8ca391;
                           }
                        }
                     }
                     .stats {
                        .title {
                           color: #065f1c;
                        }
                     }
                  }
               }

               &.status-done,
               &.status-failed {
                  .big-number {
                     border-bottom: none;
                     padding-bottom: 0;
                     margin-bottom: 0;
                  }
               }

               .small-number {
                  // padding-left: 60px;
                  margin-bottom: 4px;
                  &:last-child {
                     margin-bottom: 0;
                  }
                  .stats {
                     font-weight: 500;
                     display: flex;
                     align-items: center;
                     .number {
                        color: #26323a;
                        margin-right: 8px;
                        font-weight: 600;
                        width: 40px;
                        background-color: white;
                        text-align: center;
                        border-radius: 3px;
                        font-size: 11px;
                        box-shadow: inset 0px 1px 0px #0000001f;
                        padding: 1px 0;
                     }
                     .title {
                        font-size: 12px;
                        font-weight: 500;
                        color: #26323a;
                     }
                  }
               }
            }
            .archival-action,
            .last-activity-date {
               margin: 12px 0px;
               font-weight: 400;
               color: #718690;
               display: flex;
               // width: 100%;
               text-transform: uppercase;
               .left-side {
                  flex: 1;
                  text-align: left;
               }
               .right-side {
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  text-align: right;
                  text-transform: none;
               }
            }
            .archival-action {
               margin-top: -8px;
               .right-side {
                  text-transform: none;
               }
            }
         }
         .pending-stats {
            padding: 20px 0px 0 0;
            // margin: 0 4px;
            margin-top: 20px;
            border-top: 1px solid #e1e7ee;
            .p-stat {
               display: flex;
               align-items: center;
               margin-bottom: 6px;
               &:last-child {
                  margin-bottom: 0;
               }
               .num {
                  height: 20px;
                  width: 32px;
                  border-radius: 3px;
                  background-color: rgba(128, 128, 128, 0.1);
                  text-align: center;
                  font-weight: 600;
                  i {
                     font-size: 11px;
                  }
               }
               .text {
                  padding-left: 8px;
                  font-weight: 500;
               }
            }
         }
      }
   }
}

.lifecycle-force-archival-confirm-modal {
   z-index: 1070 !important;
   .confim-text {
      .icon {
         text-align: center;
         font-size: 80px;
      }
      .text {
         font-weight: 500;
      }
   }
}

.lifecycle-force-archival-confirm-modal-bg {
   z-index: 1060 !important;
}
