.orchestry-admin-tool-database-browser {
   .queryBuilder {
      * {
         font-feature-settings: "liga", "clig off" !important;
         font-variant-ligatures: no-common-ligatures !important;
      }
      button {
         user-select: none;
         font-size: 1rem;
         line-height: 1.5;
         border-radius: 0.25rem;
         border-width: 1px;
      }
   }
   .smaller-portlet {
      .kt-portlet__head {
         min-height: 30px;
         padding: 0 15px;
         .kt-portlet__head-label {
            h3 {
               font-size: 14px;
            }
         }
      }
      .kt-portlet__body {
         padding: 15px;
         pre {
            margin-bottom: 0;
         }
      }
   }
   .CodeMirror {
      border: 1px solid #eee;
      height: auto;
   }
   .run-button {
      text-align: right;
      margin-top: 15px;
   }
   .results-table {
      background-color: white;
      float: left;
      padding: 10px;
      border-radius: 4px;
      table {
         margin-bottom: 0;
      }
   }
   .disable-area.is-disabled.use-overlay {
      &::before {
         opacity: 1;
      }
      .disable-text {
         left: 50%;
         margin-left: -200px;
         top: 25%;
      }
   }
}
