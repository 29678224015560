.orchestry-admin-failure-stats {
   .left-col {
      // max-width: 49.5%;
   }
   .right-col {
      // max-width: 49.5%;
   }
   .portlet-filters {
      .kt-portlet__body {
         padding: 10px 25px 20px 25px;
      }
      .form-group {
         margin-bottom: 0;
      }
   }
}
