.dynamic-scrollbar {
   position: relative;

   .auto-scroll-indicator {
      position: absolute;
      right: 17px;
      top: 0;
      z-index: 1;
      background-color: #6b6b6b;
      border-radius: 4px;
      padding: 1px 4px;
      span {
         color: rgb(255, 255, 147);
      }
      .icon {
         margin-left: 6px;
      }
   }
}
