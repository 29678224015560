@import "app/assets/common/bootstrap-media-mixins";
.step-permissions {
   padding-top: 40px;
   border-top: 1px solid #e1e7ee;
   @include media-breakpoint-between(lg, xl) {
      // max-width: 800px;
      margin: 0 auto;
      padding-top: 20px;
   }
   @include media-breakpoint-up(xl) {
      // max-width: 1000px;
      margin: 0 auto;
   }
   .users {
      margin-bottom: 40px;
      .adder-top {
         margin-bottom: 20px;
      }
      .title-add {
         display: flex;
         align-items: center;
         .title {
            font-size: 17px;
            font-weight: 700;
            color: #26323a;
            flex: 1;
            .count {
               margin-left: 2px;
               color: #718690;
               font-weight: 600;
               font-size: 16px;
               .num-pad {
                  text-align: center;
                  // min-width: 12px;
                  padding-left: 2px;
                  padding-right: 2px;
                  display: inline-block;
               }
            }
         }
         .link {
            .add-action {
               display: flex;
               align-items: center;
               cursor: pointer;
               //   border: 1px dashed #E0E0E0;
               //    background: rgba(221, 226, 231, 0.15);
               //    border-radius: 4px;
               //    padding: 5px 10px;
               // background: #007989;
               // border: 2px solid #007989;
               // border-radius: 4px;
               // padding: 3px 9px;
               transition: all 0.3s;
               .icon {
                  // background: #007989;
                  // width:20px;
                  height: 20px;
                  display: inline-block;
                  background: #007989;
                  border-radius: 50%;
                  color: white;
                  text-align: center;
                  border-radius: 50%;
                  height: 20px;
                  width: 20px;
                  line-height: 19px;
                  transition: all 0.3s;
                  i {
                     font-size: 10px;
                  }
               }
               .text {
                  flex: 1;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 16px;
                  // color: #007989;
                  color: #007989;
                  margin-left: 6px;
                  transition: all 0.3s;
               }
               &:hover {
                  // background: #0395a8;
                  // border: 2px solid #0395a8;
                  .icon {
                     background: #005f6b;
                  }
                  .text {
                     color: #005f6b;
                  }
               }
            }
         }
         // .add {
         //     margin-top: 10px;
         //     margin-left: 10px;
         //     vertical-align: top;
         //     height: 18px;
         //     display: inline-block;
         //     width: 18px;
         //     line-height: 18px;
         //     text-align: center;
         //     background-color: #28B976;
         //     border-radius: 50%;
         //     cursor: pointer;
         //     svg {
         //         color:white;
         //         height: 12px;
         //         width: 12px;
         //         vertical-align: top;
         //         margin-top: 3px;
         //     }
         // }

         @include media-breakpoint-down(md) {
            margin-bottom: 10px;
         }
      }
      .info {
         margin-top: 5px;
         font-size: 12px;
         line-height: 16px;
         letter-spacing: 0.222727px;
         font-weight: 500;
         color: #718690;
      }
      .list-of-users {
         // margin-top: 20px;
         > .left,
         > .right {
            display: flex;
            flex-direction: column;
         }
      }
      .min-users {
         .portlet-alert {
            margin-top: 20px;
            .alert {
               margin-bottom: 0;
            }
         }
      }
      .user-list {
         // margin-top: 20px;
         padding: 15px;
         background: rgba(238, 241, 245, 0.75);
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         height: 100%;
         min-height: 130px;
         position: relative;

         .portlet-alert {
            width: calc(100% + 32px);
            margin-left: -16px;
            margin-top: -16px;
            .alert {
               border-radius: 4px 4px 0 0;
            }
            &.type-success {
               .alert {
                  border-color: #e1e7ee;
               }
            }
         }
         &.min-warning {
            border-color: #fd397a;
         }

         .user {
            background: #ffffff;
            border: 1px solid #dde2e7;
            box-sizing: border-box;
            border-radius: 5px;
            // height: 33px;
            vertical-align: top;
            display: inline-flex;
            // padding-right: 14px;
            margin-right: 10px;
            margin-bottom: 10px;
            position: relative;
            align-items: center;
            .pic {
               width: 24px;
               height: 24px;
               border-radius: 50%;
               background-color: rgb(211, 211, 211);
               display: inline-block;
               vertical-align: top;
               position: relative;
               margin-left: 8px;
               // margin-top:3px;
               // margin-left:3px;
               // border:1px solid #D5D1D2;
               > div {
                  border-radius: 50%;
                  font-size: 11px;
               }
               > img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
               }
            }
            > span {
               padding: 9px;
               flex: 1;
               // line-height: 33px;
               font-size: 13px;
               font-weight: 500;
               vertical-align: top;
               // margin-left:8px;
               color: #26323a;
            }

            .locked {
               padding: 9px 12px 9px 4px;
               font-size: 10px;
            }

            .remove {
               // display: none;
               // background-color: white;
               // position: absolute;
               // z-index: 1;
               // top:-1px;
               // right:0;
               // // right: -20px;
               // width: 33px;
               // height: 33px;
               // text-align: center;
               // line-height: 34px;
               // line-height: 31px;
               // border-radius: 0 16px 16px 0;
               // border-top: 1px solid #E0E0E0;
               border-left: 1px solid #e0e0e0;
               // border-bottom: 1px solid #E0E0E0;
               padding: 9px 12px;
               cursor: pointer;
               > svg {
                  height: 12px;
                  width: 12px;
                  opacity: 0.5;
               }
            }
            &:hover {
               .remove {
                  // display: block;
                  > svg {
                     color: red;
                  }
               }
            }
         }
         .no-results {
            .no-icon-text {
               position: absolute;
               margin: 0 auto;
               text-align: center;
               width: 120px;
               top: 50%;
               left: 50%;
               margin-left: -60px;
               margin-top: -25px;
               img {
                  margin-bottom: 10px;
               }
               span {
                  display: block;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 16px;
                  text-align: center;
                  color: #718690;
               }
            }
         }
         .principal-item.holder {
            border: 1px dashed #fd397a7b;
            background-color: transparent;
            cursor: pointer;
            transform: all 0.3s;
            &:hover {
               background-color: rgba(255, 255, 255, 0.234);
            }
            // opacity: 0.5;
            // border-color:rgb(223, 223, 223);
            // background-color: rgb(223, 223, 223);
            .pic {
               background-color: transparent;
               border: 1px dashed #d1d1d1;
               .profile-picture {
                  // background-color:white;
                  background-color: transparent;
               }
            }
            span {
               min-width: 115px;
            }
         }
         .default-users-divider {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.494);
            font-size: 11px;
            border-top: 1px solid #e0e0e0;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-top: 10px;
         }
      }
      .left {
         padding-right: 15px;
      }
      .right {
         padding-left: 15px;
         @include media-breakpoint-down(sm) {
            padding-top: 30px;
         }
      }
   }
   .divider-line {
      margin-top: 30px;
      margin-bottom: 20px;
   }

   .multigeo-labels,
   .sensitivity-labels {
      // margin-top: 40px;
      margin-bottom: 20px;
      .title-add {
         padding-bottom: 10px;
         > span {
            font-size: 17px;
            font-weight: 700;
            color: #26323a;
         }
      }
      .permission-radios {
         // padding-top: 20px;
         .radio-item {
            margin-bottom: 16px;
            padding: 20px;
            position: relative;
            background: #ffffff;
            border: 1px solid #e1e7ee;
            box-sizing: border-box;
            box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.3s;
            .icon {
               vertical-align: top;
               position: absolute;
               left: 20px;
               top: 50%;
               margin-top: -16px;
               width: 32px;
               height: 32px;
               // background: rgba(181, 181, 181, 0.198);
               border-radius: 50%;
               text-align: center;
               line-height: 30px;
               display: block;
               transition: all 0.3s;
               i {
                  width: 32px;
                  height: 32px;
                  font-size: 32px;
                  text-align: center;
                  color: #676767;
               }
            }
            .text {
               padding-left: 50px;
               display: inline-block;
               h6 {
                  font-size: 17px;
                  font-weight: 600;
                  color: #26323a;
                  margin-bottom: 0;
               }
               p {
                  font-size: 12px;
                  line-height: 16px;
                  letter-spacing: 0.222727px;
                  font-weight: 500;
                  color: #718690;
                  margin: 0;
               }
            }
            .checkbox {
               position: absolute;
               width: 24px;
               height: 24px;
               right: -1px;
               top: -1px;
               background: #007989;
               border-radius: 0 5px 0 4px;
               color: white;
               text-align: center;
               line-height: 24px;
               font-size: 10px;
               opacity: 0;
               transition: all 0.3s;
            }
            &:hover {
               border: 1px solid #00798980;

               .icon {
                  // background: rgba(181, 181, 181, 0.411);
                  svg {
                     // color:white;
                  }
               }
            }
            &.selected {
               border: 1px solid #007989;
               cursor: default;
               .icon {
                  // background-color: #28B976;
                  svg {
                     color: white;
                  }
               }
               .checkbox {
                  opacity: 1;
               }
            }
         }
      }
      .right {
         @include media-breakpoint-down(sm) {
            padding-top: 30px;
         }
         p {
            margin-top: 15px;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.222727px;
            color: #676767;
         }
      }
   }

   .permissions {
      //   margin-top: 40px;
      .title-add {
         padding-bottom: 10px;
         > span {
            font-size: 17px;
            font-weight: 700;
            color: #26323a;
         }
      }
      .permission-radios {
         // padding-top: 20px;
         .radio-item {
            margin-bottom: 16px;
            padding: 20px;
            position: relative;
            background: #ffffff;
            border: 1px solid #e1e7ee;
            box-sizing: border-box;
            box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.3s;
            .icon {
               vertical-align: top;
               position: absolute;
               left: 20px;
               top: 50%;
               margin-top: -16px;
               width: 32px;
               height: 32px;
               // background: rgba(181, 181, 181, 0.198);
               border-radius: 50%;
               text-align: center;
               line-height: 30px;
               display: block;
               transition: all 0.3s;
               svg {
                  width: 14px;
                  height: 14px;
                  text-align: center;
                  color: #676767;
               }
            }
            .text {
               padding-left: 50px;
               display: inline-block;
               h6 {
                  font-size: 17px;
                  font-weight: 600;
                  color: #26323a;
                  margin-bottom: 0;
               }
               p {
                  font-size: 12px;
                  line-height: 16px;
                  letter-spacing: 0.222727px;
                  font-weight: 500;
                  color: #718690;
                  margin: 0;
               }
            }
            .checkbox {
               position: absolute;
               width: 24px;
               height: 24px;
               right: -1px;
               top: -1px;
               background: #007989;
               border-radius: 0 5px 0 4px;
               color: white;
               text-align: center;
               line-height: 24px;
               font-size: 10px;
               opacity: 0;
               transition: all 0.3s;
            }
            &:hover {
               border: 1px solid #00798980;

               .icon {
                  // background: rgba(181, 181, 181, 0.411);
                  svg {
                     // color:white;
                  }
               }
            }
            &.selected {
               border: 1px solid #007989;
               cursor: default;
               .icon {
                  // background-color: #28B976;
                  svg {
                     color: white;
                  }
               }
               .checkbox {
                  opacity: 1;
               }
            }
         }
      }
      .right {
         @include media-breakpoint-down(sm) {
            padding-top: 30px;
         }
         p {
            margin-top: 15px;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.222727px;
            color: #676767;
         }
      }
   }

   .control-disabled {
      cursor: not-allowed;
      input,
      label {
         cursor: not-allowed;
      }
   }

   .info-disabled {
      color: red;
      font-style: italic;
   }

   .show-in-dir {
      display: flex;
      gap: 13px;
      align-items: center;
      // text-align: right;
      .toggle {
         flex-shrink: 0;
      }
      .title {
         color: #26323a;
         font-size: 12px;
         font-weight: 500;
         &.multi {
            color: #718690;
         }
      }
   }
}
