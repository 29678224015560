@import "app/assets/common/bootstrap-media-mixins";

$container-gap: 20px;

.step-card-guest-request {
   .left-side {
      display: flex;
      flex-direction: column;

      h4 {
         margin: 0;
      }

      .row:not(:last-child) {
         margin-bottom: 25px;
      }

      .form-group {
         margin: 0;
      }

      //border settings for subcontainers
      .guest-request-container,
      .guest-details-container,
      .guest-name-configuration-container,
      .guest-profile-photo-container {
         padding: 25px;
         border: 1px solid rgb(225, 231, 238);
         border-radius: 5px;
      }

      .guest-profile-photo-container,
      .guest-name-configuration-container {
         .row {
            margin: 0;
         }
         .toggle {
            margin-bottom: 26px;
            vertical-align: top;
            > label.form-label {
               font-size: 14px;
               font-weight: 500;
               padding: 1px 0 0 10px;
               margin-bottom: 0;
               // line-height: 20px;
            }
            > * {
               vertical-align: top;
            }
         }
      }

      label {
         font-size: 14px;
      }

      h6 {
         margin: 0;
      }
      .label {
         margin: 0;
      }

      .description-text {
         color: #646c9a;
         font-size: 13px;
      }

      .guest-request-container {
         display: flex;
         flex-direction: column;
         gap: $container-gap;
      }

      .guest-details-container {
         display: flex;
         flex-direction: column;
         gap: $container-gap;
      }

      .guest-name-configuration-container {
         display: flex;
         flex-direction: column;
         gap: $container-gap;
      }

      .guest-profile-photo-container {
         display: flex;
         flex-direction: column;
         gap: $container-gap;
      }

      .guest-profile-container {
         display: flex;
         gap: 0.5rem;
         align-items: center;
         p {
            margin: 0;
         }
      }
   }
}
