@import "app/assets/common/bootstrap-media-mixins";
.features-modal {
   .modal-dialog {
      max-width: 1000px;
      .col-left {
         // background-color: #F1F1F4;
         // padding: 15px;
         padding-left: 0;
         .left-area {
            background: rgba(238, 241, 245, 0.75);
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            padding: 16px;
            margin: 8px;
            height: calc(100% - 16px);
            h4 {
               font-family: "TT Norms";
               font-size: 22px;
               line-height: 20px;
            }
            .searchBox {
               // padding-top: 5px;
               input {
                  width: 100%;
                  padding: 8px 40px 8px 12px;
                  height: 36px;
                  font-size: 14px;
               }
               .icon-search {
                  position: absolute;
                  left: auto;
                  right: 8px;
                  top: 2px;
               }
            }
            .ScrollbarsCustom {
               // .ScrollbarsCustom-TrackY {
               //     width: 7px !important;
               //     height: 100% !important;
               //     top:0 !important;
               //     opacity: 0;
               //     transition: all 0.3s;
               //     background-color: transparent !important;
               //     .ScrollbarsCustom-ThumbY {
               //         background-color: #D9D9DC !important;
               //     }
               // }
               // &:hover {
               //     .ScrollbarsCustom-TrackY {
               //         opacity: 1;
               //     }
               // }
               .ScrollbarsCustom-TrackY {
                  right: -6px !important;
               }
               .ScrollbarsCustom-Content {
                  ul {
                     // width: calc(100% - 10px);
                  }
               }
            }
            .feature-list {
               margin-top: 10px;
               // width: calc(100% + 10px);
               @include media-breakpoint-up(sm) {
                  min-height: 300px;
               }
            }
            ul {
               li {
                  border-radius: 0.25rem;
                  padding: 7px 14px;
                  margin-bottom: 6px;
                  cursor: pointer;
                  transition: all 0.3s;
                  position: relative;
                  background: #ffffff;
                  // border: 1px solid #007989;
                  border: 1px solid #ffffff;
                  box-sizing: border-box;
                  box-shadow: 0px 2px 6px rgba(113, 134, 144, 0.15);
                  border-radius: 5px;

                  &:last-child {
                     margin-bottom: 0;
                  }

                  .icon {
                     width: 30px;
                     height: 30px;
                     background-color: #f4f5f7;
                     border-radius: 50%;
                     display: inline-block;
                     text-align: center;
                     line-height: 28px;
                     svg {
                        position: relative;
                        top: 1px;
                        // margin-right: 10px;
                        width: 18px;
                        transition: all 0.3s;
                     }
                     i {
                        font-size: 18px;
                        line-height: 28px;
                     }
                  }
                  .text {
                     font-size: 12px;
                     line-height: 17px;
                     color: #26323a;
                     font-weight: 600;
                     transition: all 0.3s;
                     margin-left: 10px;
                  }

                  .action {
                     position: absolute;
                     right: 0;
                     right: 12px;
                     top: 50%;
                     margin-top: -12px;
                     border-radius: 50%;
                     width: 24px;
                     height: 24px;
                     border: 1px solid #e1e7ee;
                     box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                     text-align: center;
                     cursor: pointer;
                     &:hover {
                        border: 1px solid rgb(146, 150, 155);
                     }
                     i {
                        font-size: 9px;
                        color: #26323a;
                     }
                     .add {
                        margin-left: 1px;
                     }
                     .check {
                        display: none;
                        color: white;
                     }
                  }

                  &:hover {
                     //background-color: white;
                     //background-color: rgba(61, 160, 162, 0.178);
                     border: 1px solid #d8d8d8;
                  }

                  &.feat-enabled {
                     background-color: white;
                     border: 1px solid #ffffff;

                     cursor: default;
                     // &:after {
                     //     content: "";
                     //     position: absolute;
                     //     z-index: 2;
                     //     top: 50%;
                     //     right: .55rem;
                     //     transform: rotate(-45deg) scale(0.8);
                     //     border-bottom: solid 2px #3DA0A2;
                     //     border-left: solid 2px #3DA0A2;
                     //     background: none;
                     //     height: 5px;
                     //     width: 8px;
                     //     margin-top: -3px;
                     // }
                     .action {
                        background: #007989;
                        border: 1px solid #007989;
                        box-shadow: none;
                        .add {
                           display: none;
                        }
                        .check {
                           display: inline;
                        }
                     }
                  }

                  &.feat-selected {
                     //background-color: #3DA0A2;
                     border: 1px solid #b1b1b1;
                     .icon {
                        background-color: #007989;
                        i,
                        svg {
                           color: white;
                        }
                     }
                     .text {
                        // color: white;
                     }
                  }

                  &.pre-selected {
                     background-color: #ffffffc9;
                     .icon {
                        opacity: 0.7;
                     }
                     .text {
                        opacity: 0.6;
                        // color: white;
                     }
                     .action {
                        background: #00798954;
                        border: 1px solid #0079892c;
                        box-shadow: none;
                        cursor: default;
                        .add {
                           display: none;
                        }
                        .check {
                           display: inline;
                        }
                     }
                  }
               }
            }
         }
      }
      .col-right {
         padding-bottom: 70px;
         position: relative;
         padding-right: 0;
         .right-area {
            margin: 48px 48px 48px 20px;
            min-height: 400px;
            .preview-image {
               width: 100%;
               background-size: cover;
               border: 1px solid #9f9f9f;
               height: 250px;
               max-height: 250px;
               margin-top: 15px;
               opacity: 1;
               transition: opacity, height 0.3s;
               border: 1.5px solid #d7dee6;
               box-shadow: 0px 4px 4px rgba(225, 231, 238, 0.15);
               border-radius: 8px;
               background-position: center center;
               &.hide-preview {
                  height: 0%;
                  opacity: 0;
               }
            }
            .infobox {
               padding: 0 10px;
               h5 {
                  margin-top: 25px;
                  font-weight: bold;
                  font-size: 20px;
                  // line-height: 32px;
                  color: #26323a;
               }
               p {
                  font-size: 12px;
                  line-height: 20px;
                  font-weight: 500;
                  color: #718690;
               }
            }
            .actions {
               position: absolute;
               bottom: 22px;
               right: 22px;
               font-weight: 500;
               font-size: 12px;
               line-height: 16px;
               .cancel {
                  display: inline-block;
                  color: #596a73;
                  padding: 0.65rem 0.85rem;
                  border-radius: 4px;
                  border: 2px solid #edf0f2;
                  cursor: pointer;
                  transition: all 0.3s;
                  &:hover {
                     border: 2px solid rgb(218, 218, 218);
                  }
               }
               .save {
                  i {
                     font-size: 12px;
                     margin-right: 10px;
                  }
                  cursor: pointer;
                  display: inline-block;
                  font-weight: 500;
                  background: #007989;
                  border: 2px solid #007989;
                  border-radius: 4px;
                  margin-left: 15px;
                  padding: 0.65rem 0.85rem;
                  color: #ffffff;
                  transition: all 0.3s;
                  &:not(.disabled):hover {
                     background: #0395a8;
                     border: 2px solid #0395a8;
                  }
                  &.disabled {
                     background: #9ccbd1;
                     border: 2px solid #9ccbd1;
                  }
               }
            }
         }
      }
   }
}
