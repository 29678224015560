.orch-modal  {
   .btn {
      font-weight: 500;
   }
}
.orch-modal-size-md {
   .modal-dialog {
      max-width: 550px;
      
   }
}
