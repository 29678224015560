.form-searchlist {
    position: relative;
    .searchbox {
        background: rgb(241, 241, 241);
        position: relative;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        // padding:8px 6px;
        input {
            width: calc(100%);
            padding: 0.48rem .75rem;
            font-size:14px;
            outline: none !important;
            border-radius: 3px;
            border-color: transparent;
            background-color: white;
            line-height: 24px;
        }
        > div {
            position: absolute;
            right:13px;
            top:50%;
            margin-top: -8px;
            
            &.icon-search {
                opacity: 0.6;
                > svg {
                    height:14px;
                    width: 14px;
                }
            }
            &.icon-delete {
                // display: none;
                opacity: 0.3;
                margin-top: -8px;
                right:14px;
                cursor: pointer;
                > svg {
                    height:14px;
                    width: 14px;
                    
                }
            }
        }
    }
    .dd-list {
        position: absolute;
        z-index: 10;
        top:calc(100% + -0.25rem);
        width: 100%;
        padding-top: 0.25rem;
        background-color: white;
        border: 1px solid #ced4da;
        border-top: 0px solid transparent;
        border-radius: 0 0 .25rem .25rem;
        &:before {
            content:"";
            position: absolute;
            height: 1px;
            left:.75rem;
            right:.75rem;
            background-color:#eaeaea;
        }

        > ul {
            margin-bottom: 0;
            box-shadow:  0px 2px 2px rgba(44, 44, 44, 0.24);
        }

        .dd-list-item {
            padding: 0.75rem .75rem;
            position: relative;
            z-index: 3;
            cursor: pointer;

            > img {
                max-width: 19px;
                max-height: 19px;
                position: relative;
                top:-2px;
                margin-right: 10px;
            }
            > svg {
                max-width: 19px;
                max-height: 19px;
                position: relative;
                // top:-2px;
                margin-right: 10px;
                color:#B5B5B5;
                width: 18px;
            }

            .add-icon {
                position: absolute;
                display: block;
                right:10px;
                top:50%;
                margin-top: -8px;
                z-index: 2;
                width: 18px;
                height: 18px;
                > svg {
                    width: 16px;
                    height: 16px;
                    color: #28B976;
                }
            }

            &:hover {
                background-color: rgba(61, 160, 162, 0.699);
                color:white;
                > svg {
                    color: white;
                }
                .add-icon {
                    svg {
                        color:white;

                    }
                }
            }

            &.dd-selected {
                background-color: #3DA0A2;
                color:white;
                cursor: default;
                > svg {
                    color: white;
                }
            }

            &:last-child {
                border-radius: 0 0 .25em .25em;
            }

            


        }

        &.dark-icons {
            .dd-list-item {
                > svg {
                    color:rgb(44, 44, 44);
                }
                &:hover,
                &.dd-selected {
                    > svg {
                        color: white;
                        //color:rgb(44, 44, 44);
                    }
                }
            }
            
        }
        .no-results {
            padding: 0.75rem .75rem;
            color: rgba(0, 0, 0, 0.486);
        }

    }

    .ScrollbarsCustom  {
        max-height: 300px;
        min-height: 300px;
        .ScrollbarsCustom-Wrapper {
            right:20px;
            .ScrollbarsCustom-Content {
                min-width: calc(100% - 7px) !important;
                width: calc(100% - 7px);

                .dd-list-item  {
                    border-radius: 0 4px 4px 0;
                }
            }
            
        }
        .ScrollbarsCustom-TrackY {
            width: 7px !important;
            // height: 100% !important;
            // top:0 !important;
            opacity: 0;
            transition: all 0.3s;
            right:5px !important;
            background-color: transparent !important;
            .ScrollbarsCustom-ThumbY {
                background-color: #D9D9DC !important;
            }
        }
        &:hover {
            .ScrollbarsCustom-TrackY {
                opacity: 1;
            }
        }
    }

    &.open {
        .searchbox {
            border: 1px solid #3da0a2;
            box-shadow: 0px 3px 3px rgba(61, 160, 162, 0.25);
        }
        .dd-list {
            border-color: #3da0a2;
        }
    }
}