.directory-update-item {
   .admin-menu {
      position: relative;
      .admin-icon {
         font-size: 30px;
         transform: rotate(-90deg);
         line-height: 3px;
         height: 20px;
         width: 21px;
         margin-left: 10px;
         color: rgb(175, 175, 175);
         transition: all 0.3s;
         cursor: pointer;
         border-radius: 3px;
         &:hover {
            color: rgb(102, 102, 102);
            // background-color: white;
         }
         // background-color: blue;
      }
      .admin-menu-dd {
         position: absolute;
         z-index: 99;
         left: 40px;
         top: -25px;
         background-color: white;
         padding: 3px;
         background: #ffffff;
         box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
         border-radius: 4px;
         font-weight: 500;
         opacity: 0;
         overflow: hidden;
         max-height: 0;
         transition: all 0.3s;
         min-width: 175px;
         .admin-menu-item {
            padding: 6px 10px;
            border-radius: 3px;
            background-color: white;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
               background-color: rgb(241, 241, 241);
            }
            i {
               margin-right: 10px;
               width: 13px;
               text-align: center;
            }
         }
      }
      &.open-below {
         .admin-menu-dd {
            left: auto;
            right: 0;
            top: calc(100% + 5px);
         }
      }
      &.menu-open {
         .admin-menu-dd {
            max-height: 130px;
            opacity: 1;
         }
      }
   }
}

.directory-edit-item-modal {
   z-index: 1070 !important;
   .ScrollbarsCustom .ScrollbarsCustom-TrackY {
      right: -6px !important;
   }
   .content {
      .logo {
         text-align: center;
         .profile-picture {
            margin: 0 auto;
            border-radius: 4px;
            img {
            }
         }
      }
      .form-label {
         font-weight: 500;
      }
   }
   .modal-footer {
      .buttons {
         display: flex;
      }
      .save {
         background: #007989;
         border-radius: 4px;
         color: white;
         font-size: 12px;
         font-weight: 500;
         padding: 10px 20px;
         border: 0px solid transparent;
         cursor: pointer;
         transition: all 0.3s;
         i {
            margin-right: 10px;
         }
         &.no-valid {
            cursor: default;
            background: #dadada;
            border-radius: 4px;
            color: rgb(82, 82, 82);
            font-size: 12px;
         }
      }
      .cancel {
         background: #ffffff;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         font-family: TT Norms;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 16px;
         padding: 10px 16px;
         position: relative;
         height: 38px;
         margin-left: 8px;
         transition: all 0.3s;
         margin-right: 10px;
         cursor: pointer;
      }
   }
}
