@import "app/assets/common/bootstrap-media-mixins";
.step-channels {
   padding-top: 40px;
   border-top: 1px solid #e1e7ee;
   @include media-breakpoint-down(sm) {
      padding-top: 20px;
   }
   h4 {
      font-weight: 700;
      font-size: 17px;
      line-height: 19px;
      color: #26323a;
      margin-bottom: 16px;
   }
   .left {
      .channel-name {
         padding-right: 50px;
         @include media-breakpoint-down(sm) {
            padding-right: 0px;
         }
         h4 {
         }
         .channel-input {
            position: relative;
            .form-group {
               display: flex;
               .form-control {
                  flex: 1;
                  font-size: 12px;
                  font-weight: 500;
               }
               .submit-channel {
                  cursor: default;
                  display: inline-block;
                  font-weight: 600;
                  background: #c1c1c1;
                  border: 2px solid #c1c1c1;
                  border-radius: 4px;
                  margin-left: 15px;
                  padding: 0.58rem 1.25rem;
                  color: #ffffff;
                  transition: all 0.3s;
                  svg {
                     // color: #8f8f8f;
                     color: white;
                     width: 10px;
                     height: 10px;
                     // margin-top: -6px;
                     position: relative;
                     top: -1px;
                     right: 5px;
                  }
               }
            }
            &.valid {
               .form-group {
                  .submit-channel {
                     cursor: pointer;
                     background: #007989;
                     border: 2px solid #007989;
                     svg {
                        color: white;
                     }
                  }
               }
            }
            .validator-collapse {
               position: relative;
               top: -25px;
               .validation-box {
                  padding: 0.75rem 0.75rem;
                  .items {
                     .error-item {
                        font-size: 11px;
                        font-weight: 600;
                     }
                  }
               }
            }
         }
      }
      .channel-suggestions {
         margin-top: 50px;
         @include media-breakpoint-down(sm) {
            margin-top: 20px;
         }
         .suggestion-items {
            > span {
               display: inline-block;
            }
            .suggestion {
               background: #ffffff;
               border: 2px solid #e0e0e0;
               box-sizing: border-box;
               border-radius: 5px;
               display: inline-block;
               padding: 10px 13px;
               margin-right: 8px;
               margin-bottom: 10px;
               transition: all 0.2s;
               font-weight: 500;
               position: relative;
               cursor: pointer;
               color: #26323a;
               &:hover {
                  background: #007989;
                  border: 2px solid #007989;
                  color: white;
               }
               .private {
                  position: absolute;
                  bottom: -7px;
                  right: -4px;
                  background-color: #da9393;
                  color: white;
                  font-size: 10px;
                  border-radius: 2px;
                  padding: 0 4px;
               }
            }
         }
         .no-suggestions {
            margin-top: 10px;
            opacity: 0.5;
         }
      }
   }
   .right {
      .team-channels {
         @include media-breakpoint-down(sm) {
            margin-top: 20px;
         }
         .team-mock {
            width: 100%;
            min-height: 300px;
            position: relative;
            border-radius: 10px 10px 10px 10px;
            background: rgba(238, 241, 245, 0.75);
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            @include media-breakpoint-down(sm) {
               min-height: 200px;
            }
            .top-mock-bar {
               background-color: white;
               // height: 50px;
               margin-left: 47px;
               border-radius: 0px 10px 0px 0px;
               box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
               border-bottom: 1px solid #e1e7ee;
               .team-title {
                  // min-height: 40px;
                  // vertical-align: middle;
                  width: 100%;
                  display: flex;
                  padding: 12px;

                  // margin: auto 0;
                  .icon {
                     width: 40px;
                     height: 40px;
                     background: #e5f7f9;
                     text-transform: uppercase;
                     text-align: center;
                     line-height: 40px;
                     color: #0c95a6;
                     font-size: 14px;
                     border-radius: 4px;
                     display: block;
                     font-weight: 700;
                     // position: absolute;
                     // top:20px;
                     // left:20px;
                     &.with-image {
                        .icon-image {
                           width: 40px;
                           height: 40px;
                           background-size: cover;
                           background-position: center center;
                           border-radius: 4px;
                        }
                     }
                  }
                  .title {
                     display: inline-block;
                     vertical-align: middle;
                     margin: auto 0 auto 15px;
                     font-weight: 600;
                     font-size: 14px;
                     line-height: 16px;
                     color: #26323a;
                  }
               }
            }
            .left-mock-bar {
               position: absolute;
               background: url("~/public/media/teams-mock.png");
               background-repeat: no-repeat;
               background-color: #313245;
               background-position: top left;
               width: 48px;
               left: -1px;
               top: -1px;
               bottom: -1px;
               border-radius: 10px 0 0 10px;
               z-index: 50;
            }
            .team-mock-list {
               // position: relative;
               // padding: 20px;
               padding-left: 48px;
               // background-color: white;
               // min-height: 300px;
               // border-radius: 0 0 10px 0;

               @include media-breakpoint-down(sm) {
                  min-height: 200px;
               }

               > ul {
                  margin: 24px;
                  > li {
                     &.channels-loading {
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                        div,
                        span {
                           // dis
                        }
                        span {
                           margin-left: 10px;
                        }
                     }
                     .channel {
                        margin-bottom: 8px;
                        display: flex;
                        > div {
                           background: #ffffff;
                           box-sizing: border-box;
                           padding: 10px 20px;
                           display: inline-block;
                           position: relative;
                           border: 1px solid #dde2e7;
                           box-shadow: inset 0px -2px 2px rgba(221, 226, 231, 0.05);
                           font-weight: 600;
                           color: #26323a;
                        }
                        .title {
                           //flex:1
                           font-weight: 500;
                           border-radius: 5px 0px 0px 5px;
                        }
                        .icon {
                           border-radius: 0px 5px 5px 0px;
                           padding: 10px 15px;
                           margin-left: -1px;
                           cursor: pointer;
                           &.with-lock {
                              padding: 0;
                              background-color: #dde2e7;
                              position: relative;
                           }
                           .info-tooltip-wrapper {
                              display: block;
                              padding: 10px 15px;
                           }
                           .lock {
                              position: relative;
                              bottom: -4px;
                              // position: absolute;
                              // right:0;
                              // top:0;
                              // padding: 13px 8px;
                              height: 100%;
                              display: none;
                              svg {
                                 width: 10px;
                                 height: 10px;
                                 vertical-align: top;
                                 opacity: 0.5;
                              }
                           }
                           .close {
                              position: relative;
                              bottom: -4px;
                              margin-left: -2px;
                              margin-right: -2px;
                              // right:0;
                              // top:0;
                              // padding: 11px 8px;
                              height: 100%;
                              cursor: pointer;
                           }
                           svg {
                              width: 12px;
                              height: 12px;
                              vertical-align: top;
                           }
                        }
                     }
                     &.locked {
                        .channel {
                           // background-color: #E5E5E5;
                           // border: 1px solid rgb(197, 197, 197);

                           .close {
                              display: none;
                           }
                           .lock {
                              display: block;
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
