.recommendation-number-stat-card {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 8px;
   .recommendation-number-stat-footer {
      display: flex;
      align-items: center;
      padding: 8px 0px;
      gap: 4px;

      .positive,
      .negative {
         display: flex;
         gap: 4px;
         align-items: center;
      }
      .positive {
         color: var(--mantine-color-success-6);
      }
      .negative {
         color: var(--mantine-color-error-6);
      }
   }
}
