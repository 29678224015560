.orchestry-admin-request-stats {
   .region-select {
      max-width: 50%;
      margin-bottom: 20px;
   }
   table {
      // th {
      //    /* Something you can count on */
      //    height: 140px;
      //    white-space: nowrap;
      // }
      table-layout: fixed;

      th {
         span {
            word-break: break-word;
            white-space: normal !important;
         }
      }

      // th > div {
      //    transform:
      //         /* Magic Numbers */ translate(25px, 51px) /* 45 is really 360 - 45 */ rotate(315deg);
      //    width: 30px;
      // }
      // th > div > span {
      //    border-bottom: 1px solid #ccc;
      //    padding: 5px 10px;
      // }
      // th,
      // td {
      //    padding: 0 !important;
      // }
   }
}
