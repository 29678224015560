.tenant-list {
   .tenant-title {
      font-weight: 500;
   }
   .tenant-guid {
      font-size: 10px;
   }
}
.orchestry-tenant-list {
   .kt-portlet {
      float: left;
      .kt-portlet__body {
         padding: 0 15px;
      }
   }
}
