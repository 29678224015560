.report-insights-button {
   .button-custom.b-orange {
      font-size: 13px;
      min-width: 150px;
      background-color: #007989;
      color: white;
      &:hover {
         background-color: #026470;
      }
      i {
         font-size: 22px;
         position: relative;
         bottom: -4px;
         line-height: 15px;
         margin-right: 4px;
      }
   }
}
