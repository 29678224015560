.orchestry-tenants-database-list,
.orchestry-tenants-database-list-info-modal {
   .tenant-title {
      font-weight: 500;
   }
   .tenant-guid {
      font-size: 10px;
   }
   div[class^="pill"] {
      border-radius: 3px;
      font-size: 13px;
      line-height: 22px;
      width: 100px;
      margin: 0 auto;
      font-weight: 600;
      color: white;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
      text-align: center;
   }
   .pill-shared {
      background-color: #49cc90;
   }
   .pill-dedicated {
      background-color: #61affe;
   }
   .pill-private {
      background-color: #f93ea4;
   }
   .pill-migrating {
      background-color: #b6bd2d;
   }
   .action-buttons {
      button {
         i {
            margin: 0;
            font-size: 16px !important;
         }
      }
   }
   .estimated-tenant-size {
      font-size: 20px;
      color: black;
      font-weight: 700;
      margin-bottom: 20px;
   }
}

.orchestry-tenants-database-list-info-modal {
   div[class^="pill"] {
      margin-left: 0;
      line-height: 38px;
   }
   .title-row {
      padding-bottom: 0px;
   }
   .subtitle-row {
      padding-bottom: 5px;
   }
   .right-arrow {
      i {
         font-size: 20px;
         line-height: 38px;
         opacity: 0.6;
      }
   }
   .migrate-tenantdb {
      background-color: rgba(128, 128, 128, 0.105);
      padding: 8px 15px 15px 15px;
      border-radius: 4px;
   }
   .migrate-tenantdb-inprogress {
      background-color: rgba(128, 128, 128, 0.105);
      padding: 8px 15px 8px 15px;
      border-radius: 4px;
      position: relative;
      .progress-number {
         position: absolute;
         line-height: 30px;
         width: 100%;
         text-align: center;
         // color: white;
         font-weight: 500;
         // text-shadow: 0px 0px 1px #000;
      }
      .progress-bar-migration {
         height: 30px;
         width: 100%;
         background-color: white;
         border-radius: 4px;
         padding: 4px;
      }
      .progress-bar-migration-percent {
         height: 22px;
         width: 100%;
         background-color: rgb(220, 230, 255);
         border-radius: 4px;
      }
   }
}
