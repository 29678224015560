@import "app/assets/common/bootstrap-media-mixins";

$link-row-gap: 1rem;
$left-icon-gap: 1rem;

$colours: (
   purple: rgba(121, 27, 171, 0.2),
   light-purple: rgba(121, 27, 171, 0.1),
   green: rgba(16, 124, 65, 0.2),
   light-green: rgba(16, 124, 65, 0.1),
   dark-green: rgba(17, 102, 105, 0.1),
   light-blue: rgba(15, 99, 179, 0.1),
   dark-gray: #656e77,
   teams-purple: #464eb8
);

.workspace-links {
   &.kt-portlet .kt-portlet__body {
      padding: 6px 0 0 0;
   }

   @include media-breakpoint-down(xs) {
      &.kt-portlet {
         border-radius: 10px;
         border: 1px solid #e1e1e1;
         box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
      }

      &.kt-portlet .kt-portlet__head {
         display: none;
      }

      &.kt-portlet .kt-portlet__body {
         padding: 6px 15px 15px 15px;
      }

      .workspace-links-container-common {
         .fake-box {
            width: 100%;
            min-width: 200px !important;
         }
         padding: 0 !important;
         .verticle-section-title {
            display: inline !important;
            font-size: 18px;
            font-family: "TT Norms";
            font-weight: 500;
            color: #232323;
         }
      }
   }

   .workspace-links-container-common {
      @function getColour($key) {
         $colour: map-get($colours, $key);
         @return $colour;
      }

      .verticle-section-title {
         display: none;
      }

      .left-icon {
         min-width: 32px;
         min-height: 32px;
         font-size: 16px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 4px;
         img {
            max-width: 32px;
            max-height: 32px;
         }
         &.green {
            background: getColour(green);
         }
         &.purple {
            background: getColour(purple);
         }
         &.light-green {
            background: getColour(light-green);
         }
         &.dark-green {
            background: getColour(dark-green);
            color: #048a8e;
         }
         &.light-blue {
            background: getColour(light-blue);
            color: #1580d8;
         }
         &.teams-purple {
            color: getColour(teams-purple);
            background: #edeef8;
         }
      }

      .font-setting {
         font-weight: 500;
         font-size: 14px;
         color: #111118;
      }

      padding: 21px;
      display: flex;
      flex-direction: column;
      gap: 21px;
      .title {
         font-size: 20px;
         font-weight: 700;
      }

      .skeletons {
         display: flex;
         flex-direction: column;
         gap: 1.5rem;
      }

      .links {
         display: flex;
         flex-direction: column;
         gap: $link-row-gap;

         .single-link-item {
            @extend left-icon !optional;
            @extend .font-setting !optional;

            display: flex;
            border: 1px solid #eaedf0;
            border-radius: 6px;
            align-items: center;
            padding: 6px 8px;
            gap: $left-icon-gap;
            user-select: none;
            position: relative;
            transition: all 0.3s;

            &:hover {
               background-color: #f5f5f5;
               border: 1px solrgb(220, 223, 226) df0;
            }

            .count-text {
               color: #7f8995;
               font-weight: 500;
               font-size: 14px;
            }

            .text {
               width: 100%;
               display: flex;
               align-items: center;
               justify-content: space-between;
               .link {
                  position: absolute;
                  z-index: 1;
                  inset: 0;
               }
               i {
                  color: getColour(dark-gray);
               }
            }
         }

         .multi-link-item {
            @extend left-icon !optional;
            @extend .font-setting !optional;

            display: flex;
            flex-direction: column;
            font-weight: 500;
            font-size: 14px;
            border: 1px solid #eaedf0;
            border-radius: 6px;
            padding: 8px;
            overflow: hidden;
            max-height: fit-content;
            user-select: none;

            &.is-closed {
               &:hover {
                  background-color: #f5f5f5;
                  border: 1px solrgb(220, 223, 226) df0;
               }
            }

            .body-items {
               display: grid;
               grid-template-rows: max-content;

               .item-container:nth-last-child(1) {
                  .list-separator {
                     height: 0;
                  }
               }

               &.more-than-5 {
                  grid-template-areas:
                     "item"
                     "item"
                     "item"
                     "item"
                     "item"
                     "pagination";
                  grid-template-rows: repeat(5, 1fr) 0.5fr;
                  .item-container:nth-last-child(2) {
                     .list-separator {
                        height: 0;
                     }
                  }
               }

               transition: height 500ms ease, margin-top 10ms ease;
               max-height: 0px;
               background: white;
               border-radius: 6px;
               position: relative;

               i {
                  color: getColour(dark-gray);
               }

               .item-container {
                  position: relative;

                  .list-separator {
                     border: 0;
                     height: 1px;
                     background: #eaedf0;
                     width: 100%;
                     margin: 0px 0px;
                  }

                  .item {
                     display: flex;
                     padding: 12px;
                     justify-content: space-between;
                     align-items: center;
                     transition: all 0.3s;
                     &:hover {
                        background-color: #f5f5f5;
                        border: 1px solrgb(220, 223, 226) df0;
                     }
                     .item-text {
                        i {
                           margin-right: 6px;
                        }
                        .link-text {
                           display: flex;
                           align-items: center;
                           color: getColour(dark-gray);
                        }
                        a {
                           position: absolute;
                           inset: 0;
                        }
                     }
                  }
               }

               //height transitions
               transition: max-height 300ms ease-in-out, min-height 300ms ease-in-out, margin-top 300ms ease-in-out;
               &.show-enter-active {
                  max-height: 0px;
               }

               &.show-enter-done {
                  max-height: 320px;
                  margin-top: 8px;
               }

               &.show-exit-done {
                  max-height: 0px;
                  margin-top: 0px;
               }

               //scrolling transitions
               $transform-timer: 500ms;
               $opacity-timer: 500ms;
               &.to-the-right {
                  .item-container:nth-child(-n + 5) {
                     transform: translateX(-0.25%);
                     opacity: 0.5;
                  }
               }

               &.to-the-right-active {
                  .item-container:nth-child(-n + 5) {
                     transform: translateX(0);
                     opacity: 1;
                     transition: transform $transform-timer ease, opacity $opacity-timer ease;
                  }
               }

               &.to-the-right-done {
                  .item-container:nth-child(-n + 5) {
                     transform: translateX(0);
                  }
               }

               &.to-the-left {
                  .item-container:nth-child(-n + 5) {
                     opacity: 0.8;
                     transform: translateX(0.25%);
                  }
               }

               &.to-the-left-active {
                  .item-container:nth-child(-n + 5) {
                     opacity: 1;
                     transform: translateX(0);
                     transition: transform $transform-timer ease, opacity $opacity-timer ease;
                  }
               }

               &.to-the-left-done {
                  .item-container:nth-child(1n + 5) {
                     transform: translateX(0);
                  }
               }

               .pagination {
                  grid-area: pagination;
                  .paging {
                     width: 100%;
                     margin: 10px 5px;
                     $height: 8px;
                     $width: 8px;
                     z-index: 1;

                     a {
                        padding: 0;
                        text-align: center;
                        vertical-align: middle;
                        height: $height;
                        width: $width;
                     }

                     .kt-pagination .kt-pagination__links {
                        padding: 0;
                        padding-left: 8px;
                     }

                     .kt-pagination .kt-pagination__links li {
                        min-width: $width;
                        min-height: $height;
                        cursor: pointer;
                        display: inline-block;
                     }
                     .kt-pagination__link--next {
                        margin-left: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        &:hover {
                           color: white;
                        }
                     }
                     .kt-pagination__link--prev {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        &.disabled {
                           opacity: 0.3;
                           pointer-events: none;
                        }
                     }
                     .kt-pagination__toolbar {
                        select {
                           padding-left: 2px !important;
                        }
                     }
                     .kt-pagination__link--active {
                        color: #ffffff;
                     }
                  }
               }
            }

            &.is-open {
               .body-items {
                  margin-top: 8px;
               }
               &.light-purple {
                  background: getColour(light-purple);
                  border: 1px solid #eaedf0;
               }
               &.light-green {
                  background: getColour(light-green);
                  border: 1px solid #eaedf0;
               }
            }

            .text {
               width: 100%;
               display: flex;
               justify-content: space-between;
            }

            .count-text {
               color: #7f8995;
               font-weight: 500;
               font-size: 14px;
            }

            .link-item-body {
               cursor: pointer;
               display: flex;
               align-items: center;
               gap: $link-row-gap;
               .body-text {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  i {
                     color: getColour(dark-gray);
                  }
               }
            }
         }
      }
   }
}
