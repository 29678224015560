//
// Aside Brand
//

.kt-aside__brand {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: row;
   padding: 0 kt-get($kt-page-padding, desktop);
   height: kt-get($kt-header-config, base, desktop, default, height);

   .kt-aside__brand-logo {
      display: flex;
      justify-content: flex-begin;

      img {
         max-height: 40px;
         .kt-aside--minimize & {
            display: none;
         }
      }
   }

   .kt-aside__brand-tools {
      display: flex;
      justify-content: flex-end;

      .kt-aside__brand-aside-toggler {
         display: inline-block;
         padding: 0;
         @include kt-input-reset();

         > span {
            display: inline-block;

            &:nth-child(2) {
               display: none;
            }
         }

         svg {
            height: 26px;
            width: 26px;
            margin-right: -2px;
         }
      }
   }
}

@include kt-desktop {
   .kt-aside__brand {
      transition: kt-get($kt-aside-config, base, minimize, transition);

      // Header fixed & aside static mode
      .kt-header--fixed.kt-aside--static & {
         position: fixed;
         top: 0;
         left: 0;
         width: kt-get($kt-aside-config, base, default, width);
         z-index: kt-get($kt-header-config, base, desktop, fixed, zindex);
      }

      // Aside minimize mode
      .kt-header--fixed.kt-aside--minimize & {
         justify-content: center;

         .kt-aside__brand-tools {
            justify-content: center;

            .kt-aside__brand-aside-toggler {
               > span {
                  &:nth-child(1) {
                     display: none;
                  }

                  &:nth-child(2) {
                     display: inline-block;
                  }
               }
            }
         }
      }

      // Aside minimize, header fixed & aside static mode
      .kt-header--fixed.kt-aside--static.kt-aside--minimize & {
         transition: kt-get($kt-aside-config, base, minimize, transition);
         width: kt-get($kt-aside-config, base, minimize, width);
      }
   }
}

@include kt-tablet-and-mobile {
   .kt-aside__brand {
      display: none;
   }
}
