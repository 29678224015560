.orchestry-color-label {
   display: flex;
   gap: 8px;
   align-items: center;
   .color {
      border-radius: 50%;
      width: 13px;
      height: 13px;
   }
}
