.form-image-upload-gallery {
    .gallery {
        // margin-bottom: 15px;
        vertical-align: top;
        width: calc(100% + 20px);
        margin-left: -10px;
        .image-wrapper {
            width:50%;
            display: inline-block;
            vertical-align: top;
            padding: 0 10px 20px 10px;
            position: relative;
            .image-preview {
                background-position: center center;
                background-size: cover;
                height: 200px;
                width:100%;
                border-radius: 5px;
                border:1px solid rgb(228, 228, 228);
            }
            .delete {
                position: absolute;
                bottom:30px;
                right:20px;
                background-color: white;
            }
        }
    }
    .image-upload-button {
        outline: none !important;
        .imageupload  {
            display: inline-block;
        }
    }

}