.policy-card {
   .label {
      color: #414141;
      cursor: default;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
   }
   .form-group {
      .form-label {
         font-size: 14px;
      }
   }
   .card-border {
      border: 1px solid #e1e7ee;
      border-radius: 5px;
      padding: 30px;
   }
   h4 {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 20px;
      color: #26323a;
   }
   h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #26323a;
      margin-bottom: 16px;
   }
   hr {
      margin: 24px 0;
   }
   p.text {
      color: #718690;
      font-size: 13px;
      font-weight: 400;
   }
   //<div className="custom-icon-check"><i className="la la-check"></i></div>
   .custom-icon-check {
      width: 32px;
      height: 32px;
      background-color: #007989;
      border-radius: 50%;
      i {
         color: white;
         font-size: 18px;
         line-height: 32px;
      }
   }
   // <div className="custom-icon-execute"><i className="flaticon2-refresh"></i></div>
   .custom-icon-execute {
      width: 32px;
      height: 32px;
      position: relative;
      // background-color: #007989;s
      // border-radius: 50%;
      &::before {
         content: "";
         width: 8px;
         height: 8px;
         border-radius: 50%;
         z-index: 1;
         position: absolute;
         left: 50%;
         top: 50%;
         margin-top: -4px;
         margin-left: -4px;
         background-color: #007989;
      }
      i {
         // color:white;
         // font-size: 18px;
         font-size: 32px;
         line-height: 32px;
      }
   }
   .custom-icon-office-logo {
      width: 48px;
      height: 48px;
      position: relative;
      img {
         width: 48px;
      }
   }
   .form-buttons {
      margin-top: 45px;
   }
   .checkbox-blue {
      // padding-right:3rem;
      // display: inline-block;
      padding-bottom: 5px;
      margin-bottom: 0;
      position: relative;
      .form-check {
         label {
            margin-left: 12px;
            color: #414141;
            cursor: pointer;
            font-size: 14px;
         }
         label:before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 18px;
            height: 18px;
            // background-color: rgb(66, 223, 149);
            background-color: white;
            border: 1px solid #e1e7ee;
            border-radius: 0.25rem;
            left: 0;
            top: 0px;
         }
         input:checked + label:before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 18px;
            height: 18px;
            background-color: #007989;
            border-color: #007989;
            border-radius: 0.25rem;
            left: 0;
            top: 0px;
         }
         input:checked + label:after {
            // content: '\2714';
            content: "";
            font-size: 14px;
            position: absolute;
            z-index: 1;
            top: 4px;
            left: 3px;
            color: #c2c2c2;
            transform: rotate(-45deg) scale(0.8);
            border-bottom: solid 3px white;
            border-left: solid 3px white;
            background: none;
            height: 8px;
            width: 12px;
         }
         input {
            opacity: 0;
         }
      }
      .info-circle {
         position: relative;
         // top: 4px;
         margin-left: 4px;
         // position: absolute;
         // right:0.75rem;
         // top:1rem;
      }
   }
   .card-info {
      border: 1px solid #e1e7ee;
      border-radius: 5px;
      .title {
         padding: 10px 20px;
         font-weight: 500;
         color: #26323a;
         font-size: 13px;
         border-bottom: 1px solid #e1e7ee;
      }
      .content {
         padding: 10px 20px;
         font-weight: 500;
         color: #718690;
         font-size: 13px;
      }
   }
}
