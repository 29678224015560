.chrome-trial-information {
   vertical-align: top;
   border-radius: 4px;
   // margin: 5px;
   margin-right: 10px;
   height: 32px;
   // padding-left: 12px;
   padding-right: 10px;
   transition: 0.2s;
   position: relative;
   background-color: #78c578;

   // &:after {
   //     content: "";
   //     top:5px;
   //     bottom: 5px;
   //     right: -15px;
   //     position: absolute;
   //     width: 1px;
   //     background-color: rgb(235, 235, 235);
   // }
   cursor: pointer;
   &:hover {
      //  background-color: rgb(238, 238, 238);
   }
   .num {
      vertical-align: top;
      display: inline-block;
      padding: 5px 0px;
      font-size: 13px;
      color: #78c578;
      background-color: white;
      font-weight: bold;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      margin-top: 6px;
      margin-right: 8px;
      margin-left: 6px;
      //  min-width: 34px;
      //  height: 34px;
      text-align: center;
      line-height: 12px;
   }
   .days-left {
      vertical-align: top;
      display: inline-block;
      //  padding-top: 8px;
      .text {
         font-size: 13px;
         font-weight: 500;
         line-height: 33px;
         color: white;
      }
      .expirey-date {
         font-size: 11px;
         font-weight: 500;
         opacity: 0.5;
      }
   }
}
