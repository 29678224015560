.recommendation-card-view-details-modal {
   display: flex;
   flex-direction: column;
   gap: 32px;
   font-size: var(--mantine-font-size-sm);
   font-family: var(--mantine-font-family);
   .recommended-action {
      display: flex;
      gap: 8px;
      flex-direction: column;
      .recommended-action-contents {
         display: flex;
         gap: 8px;
         flex-direction: column;
         position: relative;
         .action-list {
            color: var(--mantine-color-text-secondary-0);
            ul {
               padding: 0;
               margin: 0;
               list-style: none;
               li {
                  display: flex;
                  align-items: flex-start;
                  margin-bottom: 12px;
                  &:before {
                     font-family: orchestry-icon-font;
                     content: var(--orchicon-check-verified-01);
                     color: var(--mantine-color-primary-7);
                     font-size: 16px;
                     margin-right: 8px;
                  }
               }
            }
         }
         .why {
            color: var(--mantine-color-text-secondary-0);
            ul {
               padding: 0;
               margin: 0;
               list-style: none;
               li {
                  display: flex;
                  align-items: flex-start;
                  margin-bottom: 12px;
                  &:before {
                     font-family: orchestry-icon-font;
                     content: var(--orchicon-info-circle);
                     color: var(--mantine-color-gray-9);
                     font-size: 16px;
                     margin-right: 8px;
                  }
               }
            }
         }
      }
   }

   .useful {
      .orchestry-thumbs {
         width: fit-content;
      }
   }

   .resources {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .resources-header {
         display: flex;
         flex-direction: column;
         gap: 8px;
      }
   }

   .bottom-buttons {
      display: flex;
      justify-content: flex-end;
      color: var(--mantine-color-text-tertiary-0);
   }
}
