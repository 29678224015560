.column-type-reportguestdaysinactive {
   text-align: left;
   .days-inactive {
      text-align: left;
      display: inline-flex;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: 500;
      //height: 30px;
      border-radius: 4px;
      background-color: rgb(237, 237, 237);

      &.days-60 {
         color: #b31919;
         background-color: rgba(238, 0, 0, 0.15);
      }
      &.days-30 {
         color: #ab7612;
         background-color: rgba(250, 179, 47, 0.15);
      }
   }
}
