@import "app/assets/common/bootstrap-media-mixins";

@include media-breakpoint-down(xs) {
   .team-information-custom-metadata {
      .metadata-content {
         .metadata-container {
            gap: 4px !important;
         }
      }
   }
}

.team-information-custom-metadata {
   .metadata-content {
      // padding-top: 25px;
      .metadata-title {
         // border-bottom: 1px solid #e1e7ee;
         color: #26323a;
         font-size: 14px;
         font-weight: 600;
         margin-bottom: 10px;
         display: flex;
         gap: 0.5rem;
         .metadata-item-edit {
            opacity: 0.7;
            transition: all 0.3s;
            color: blue;
            &:hover {
               opacity: 1;
            }
            i {
               width: 12px;
               height: 12px;
               cursor: pointer;
               font-size: 13px;
            }
         }
      }
      .container {
         margin: 0;
         padding: 0;
         .meta-row {
            margin-bottom: 1rem;
         }
      }
      .metadata-loader {
         display: grid;
         grid-template-columns: repeat(3, minmax(min-content, 1fr));
         gap: 1rem;
      }

      .metadata-container {
         // padding-top: 8px;
         display: grid;
         grid-template-columns: repeat(3, minmax(min-content, 1fr));
         gap: 1rem;
         color: #26323a;
         .metadata-item-title {
            color: #718690;
            font-weight: 500;
            display: flex;
            span {
               margin-right: 8px;
            }
            .metadata-item-edit {
               opacity: 0.5;
               transition: all 0.3s;
               color: blue;
               &:hover {
                  opacity: 1;
               }
               i {
                  width: 12px;
                  height: 12px;
                  cursor: pointer;
               }
            }
         }
         .metadata-item {
            display: flex;
            flex-direction: column;
            .metadata-item-value {
               padding: 0 5px 0 0;
               color: #26323a;
               font-weight: 500;
               align-items: center;

               .links-group {
                  .link {
                     display: flex;
                     gap: 6px;
                     color: rgba(0, 121, 137, 0.5);
                     align-items: center;
                  }
               }
            }
         }
         .managed-metadata-list {
            list-style: none;
            padding: 0;
            margin-bottom: 0;
         }
      }
   }
}

.team-information-custom-metadata-edit-modal {
   z-index: 1070 !important;
   .ScrollbarsCustom .ScrollbarsCustom-TrackY {
      right: -6px !important;
   }
   .content {
      .logo {
         text-align: center;
         .profile-picture {
            margin: 0 auto;
            border-radius: 4px;
            img {
            }
         }
      }
      .form-label {
         font-weight: 500;
      }
      .metadata-item {
         .metadata-field-tooltip {
            display: none;
         }
      }
   }
   .modal-footer {
      .buttons {
         display: flex;
      }
      .save {
         background: #007989;
         border-radius: 4px;
         color: white;
         font-size: 12px;
         font-weight: 500;
         padding: 10px 20px;
         border: 0px solid transparent;
         cursor: pointer;
         transition: all 0.3s;
         i {
            margin-right: 10px;
         }
         &.no-valid {
            cursor: default;
            background: #dadada;
            border-radius: 4px;
            color: rgb(82, 82, 82);
            font-size: 12px;
         }
      }
      .cancel {
         background: #ffffff;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         font-family: TT Norms;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 16px;
         padding: 10px 16px;
         position: relative;
         height: 38px;
         margin-left: 8px;
         transition: all 0.3s;
         margin-right: 10px;
         cursor: pointer;
      }
   }
}
