
h1.ob {
   font-size: 40px;
   line-height: 42px;
   font-weight: bold;
}
h2.ob {
   font-size: 24px;
   line-height: 32px;
   font-weight: bold;
}
h3.ob {
   font-size: 20px;
   line-height: 24px;
   font-weight: bold;
}
h4.ob {
   font-size: 16px;
   line-height: 20px;
   font-weight: 500;
}
h5.ob {
   font-size: 14px;
   line-height: 20px;
   font-weight: bold;
}

.ob-form-group {
   color: #26323A;
   font-weight: 500;
   font-size: 14px !important;
   label {
      font-size: 14px !important;
      font-weight: 500;
   }
   input {
      background: #FFFFFF;
      border: 1px solid #E1E7EE;
      box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      &:focus {
         background: #FFFFFF;
         border: 1px solid #005A66;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
      }
   }
}
p.ob {
   font-size:14px;
   font-weight: 500;
   // color:#718690;
 }