@import "_metronic/_assets/sass/breakpoints";

.team-guest-review-workflow {
   background-color: white;
   padding: 20px 20px;

   .admin-options,
   .kt-portlet__head {
      border: 1px solid rgba(0, 0, 0, 0.1);

      // border-top:0px solid transparent;
   }
   .kt-portlet__body {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top: 0p solid transparent !important;
      border-radius: 0 0 4px 4px !important;
      // border-top:0px solid transparent;
   }

   .kt-portlet__head {
      &:after {
         display: none;
      }
   }

   .stats-box {
      .kt-portlet {
         border: 1px solid rgba(0, 0, 0, 0.1);
      }
   }

   .guest-request-workflow-insight-report {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
   }
   // @include kt-mobile {
   //    padding: 15px;
   //    display: block;
   // // display: none;
   // }
}
