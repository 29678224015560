.root {
   &[data-variant] {
      background-color: var(--mantine-color-primary-0);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 1000px;
      .placeholder {
         background-color: var(--mantine-color-primary-0);
         font-size: 12px;
         font-weight: var(--medium-font-weight);
      }
   }

   &[data-variant="icon"] {
      .image {
         width: 14px;
         height: 14px;
      }
   }

   &[data-variant="40px"] {
      .image {
         width: 40px;
         height: 40px;
      }
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;

      .placeholder {
         font-size: 14px;
         line-height: 14px;
         color: var(--mantine-color-primary-6);
      }
   }

   &[data-variant="48px"] {
      .image {
         width: 48px;
         height: 48px;
      }
      min-width: 48px;
      min-height: 48px;
      width: 48px;
      height: 48px;

      .placeholder {
         font-size: 14px;
         line-height: 14px;
         color: var(--mantine-color-primary-6);
      }
   }

   &[data-variant="20px"] {
      .image {
         width: 20px;
         height: 20px;
      }
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;

      .placeholder {
         font-size: 7px;
         line-height: 7px;
      }
   }

   &[data-variant="16px"] {
      .image {
         width: 16px;
         height: 16px;
      }
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
      align-items: center;
      margin: 2px 0;
      .placeholder {
         font-size: 7px;
         line-height: 7px;
      }
   }

   &[data-shape="square"] {
      border-radius: 4px !important;
   }
}
