@import "app/assets/common/bootstrap-media-mixins";
.dropdown-associated-popover {
   @include media-breakpoint-down(sm) {
      &:after {
         display: none;
      }
   }
   &.popover {
      width: 370px;
      max-width: 370px;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      // border: 1px solid rgb(119, 235, 181);
      box-sizing: border-box;
      box-shadow: 0px 3px 4px rgba(45, 48, 54, 0.288134);
      border-radius: 0px 0px 5px 5px;
      padding: 5px 15px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.222727px;
      color: #676767;
      //removed in react 18 update
      // left: 5px !important;
      // margin-left: 19px !important;
      @include media-breakpoint-down(sm) {
         width: auto !important;
         left: 10px !important;
         right: 20px !important;
         max-width: auto !important;
         // box-shadow: none !important;
         margin-top: 10px;
         // transform: translateX(0px);
      }
      &.bs-popover-bottom {
         &::after {
            position: absolute;
            top: -8px;
            right: 12px;
            content: "";
            width: 15px;
            height: 15px;
            background-color: white;
            border-top: 1px solid #e2e2e2;
            border-left: 1px solid #e2e2e2;
            transform: rotate(45deg);
         }
      }
      &.bs-popover-top {
         div.arrow {
            display: none;
         }
         &:after {
            position: absolute;
            bottom: -8px;
            right: 12px;
            content: "";
            width: 15px;
            height: 15px;
            background-color: white;
            border-bottom: 1px solid #e2e2e2;
            border-right: 1px solid #e2e2e2;
            transform: rotate(45deg);
            box-shadow: 3px 3px 4px rgba(45, 48, 54, 0.288134);
         }
      }
   }
   .popover-header {
      background-color: transparent;
      padding: 5px 0 10px 0;
      font-size: 14px;
   }
   .popover-body {
      padding: 0 0 10px 0;
      color: #414141;
      .selected-items {
         padding-top: 5px;
         margin-bottom: 15px;
         li {
            font-weight: 600;
            padding: 10px 0;
         }
      }
      h6 {
         border-bottom: 1px solid #ebebeb;
         font-size: 14px;
         font-weight: 400;
         padding-bottom: 10px;
         margin-bottom: 15px;
         color: #676767;
      }
      .form-searchlist .dd-list {
         position: relative;
         top: -3px;
      }
      .no-templates {
         padding: 20px 0 30px;
      }
   }
}
.dropdown-associated-button {
   &.btn-primary {
      background-color: transparent !important;
      color: black !important;
      border: #e2e2e2 solid 0px;
      padding: 10px;
      font-size: 14px;
      outline: none !important;
      @include media-breakpoint-down(sm) {
         padding: 0;
      }
      &:focus,
      &:active,
      &:hover {
         // background-color: rgba(0, 0, 0, 0.096) !important;
         outline: none !important;
         box-shadow: none !important;
      }
      svg {
         margin-left: 10px;
         color: #b5b5b5;
      }
   }
}
