.team-link {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%;
    .loading-link {
        position: absolute;
        height:40px;
        width: 40px;
        border-radius: 5px;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.383);
        text-align: center;
        opacity: 0;
        transition: all 0.3s;
        &.is-visible {
            opacity: 1;

        }
        .animated-loader {
            vertical-align: top;
            padding: 10px;
            // border-radius: 5px;
            position: relative;
            top:calc(50% - 12px);
        }
    }
}