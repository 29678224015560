@import "app/assets/common/bootstrap-media-mixins";

.principal-modal-backdrop {
    // backdrop-filter: blur(10px);
    // background-color: rgba(238, 241, 245, 0.75) !important;
    // opacity: 1 !important;
	// animation-iteration-count: 1;
	// animation-timing-function: ease-in;
    // animation-duration: 0.3s;
    // &.show {
    //     animation-name: fadein;
    // }
}
@keyframes fadein {
    0% {
        //backdrop-filter: blur(0px);
        opacity: 0;
    //background-color: white !important;
    }
    100% {
        //background-color: black !important;
    opacity: 1;
    //backdrop-filter: blur(10px);
    }
}
.principal-search-modal {
    font-family: 'TT Norms';
    font-variant-ligatures: none;
    > .modal-dialog {
      margin: 6rem auto;
      max-width: 400px;
    }
    .popover.people-picker {
        background-color:white;
        border: 1px solid #28B976;
        min-width: 300px;
        margin-top:-14px;
        margin-left: 10px;
        .arrow {
            &:before{
                position: absolute;
                top: -2px;
                right: 11px;
                content: "";
                width: 10px;
                height: 10px;
                background-color: white;
                border-top: 1px solid #28B976;
                border-left: 1px solid #28B976;
                transform: rotate(-45deg);
            }
        }
        
    }
    .people-content {
        @include media-breakpoint-down(sm) {
            background-color:white;
            border: 1px solid #28B976;
            border-radius: 5px;
        }
        .people-header {
            position: relative;
            padding: 24px 24px 12px 24px;
            span {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                color: #26323A;
            }
            .close {
                right:12px;
                top:10px;
                position: absolute;
                cursor: pointer;
                i {
                    font-size: 14px;
                }
            }
        }
        .people-search {
            position: relative;
            padding:10px;
            border-bottom:1px solid #ECECEC;
            padding: 0px 24px 20px 24px;
            .search-box {
                display: flex;
                align-items: center;
                border: 1px solid #E1E7EE;
                box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                border-radius: 5px;
                input {
                    width:100%;
                    border:0 solid transparent;
                    // border:0px solid transparent;
                    outline:none;
                    background: #FFFFFF;
                    padding: 5px 12px 5px 12px;
                    flex:1;
                    border-radius: 4px;
                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #9DA9AE;
                        font-weight: 500;
                        font-size:12px;
                        opacity: 1; /* Firefox */
                    }
                    
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #9DA9AE;
                        font-size:12px;
                        font-weight: 500;
                    }
                
                    &::-ms-input-placeholder { /* Microsoft Edge */
                        color: #9DA9AE;
                        font-size:12px;
                        font-weight: 500;
                    }
                    &:focus {
                        //border:0px solid transparent;
                        outline:none;
                    }
                }
                .icon {
                    // position: absolute;
                    // top:50%;
                    // margin-top: -8px;
                    // right:10px;
                    padding: 1px 10px 0 10px;
                    i {
                        font-size:15px;
                        color:#9DA9AE;
                        line-height: 17px;
                    }
                    svg {
                        color: #B5B5B5;
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }
        .people-list {
            position: relative;
            padding: 18px 8px 24px 8px;
            // padding-top: 5px;
            &:before {
                // display: none;
            }
            ul {
                margin-bottom: 0;
                .suggested-members {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: -0.009091px;
                    color: #718690;
                    padding: 0 16px;
                    margin-bottom: 10px;
                }
                .ScrollbarsCustom-TrackY {
                    right:0 !important;
                    // width:3px !important;
                    top:0;
                    height: 100%;
                }
                li {
                    padding: 12px 16px;
                    // height: 34px;
                    line-height: 20px;
                    word-wrap: none;
                    position: relative;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    transition: all 0.3s;
                    cursor: pointer;
                    &:hover,
                    &.hover-selected {
                        background: rgba(61, 160, 162, 0.1);
                        //cursor: default;
                    }
                    .pic {
                        width:32px;
                        height: 32px;
                        
                        // background-color: rgb(211, 211, 211);
                        display: inline-block;
                        vertical-align: top;
                        // position: relative;
                        margin-top:0px;
                        margin-left:0px;
                        // border:1px solid #D5D1D2;
                        margin-right: 15px;
                        > div {
                            border-radius: 50%;
                            font-size:11px;
                            font-weight: 600;
                        }
                        > img {
                            border-radius: 50%;
                            width:32px;
                            height: 32px;
                            vertical-align: top;
                        }
                        .profile-picture-placeholder {
                            width:32px;
                            height: 32px;
                            line-height: 33px;
                            font-size: 13px;
                        }
                    }
                    .info {
                        flex:1;

                        .name {
                            display: block;
                            font-size:14px;
                            font-weight: 500;
                            color:#26323A;
                        }
                        .guest-note {
                           margin-left: 3px;
                           font-weight: 500;
                           opacity: 0.6;
                        }
                        .job-title {
                            margin-left: 0px;
                            display: block;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color:#718690;
                        }
                    }
                    &.no-results{
                        padding-top: 5px;
                        text-align: center;
                    }
                    &.loader {
                        padding-top: 14px;
                        text-align: center;
                    }
                    .principal-type {
                        position: absolute;
                        left: 38px;
                        top: 34px;
                        width: 15px;
                        height: 15px;
                        background-color: #0abb87;
                        font-size: 10px;
                        font-weight: 600;
                        color: white;
                        line-height: 14px;
                        margin: 0 auto;
                        text-align: center;
                        border-radius: 50%;
                        border:1px solid white;
                        box-sizing: content-box;
                        z-index: 93;
                        &.type-group {
                            background-color: rgb(121, 121, 231);
                        }
                        &.type-securitygroup {
                           background-color: rgb(237, 105, 105);
                           // font-size:8px;
                        }
                        &.type-distributiongroup {
                           background-color: rgb(230, 182, 110);
                           // font-size:8px;
                        }
                    }
                    .action {
                        width:24px;
                        height: 24px;
                        border: 1px solid #E1E7EE;
                        box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                        border-radius: 50%;
                        text-align: center;
                        line-height: 23px;
                        .add {
                            font-size:9px;
                        }
                        .check {
                            font-size:10px;
                            display: none;
                            color:white;
                        }
                        .locked {
                           font-size:9px;
                           display: none;
                           color:white;
                           position: relative;
                           top:-1px;
                        }
                    }
                    &.selected {
                        .action {
                           border-color: #007989;
                           box-shadow: none;
                           background-color: #007989;
                           line-height: 22px;
                           .add {
                               display: none;
                           }
                           .check {
                               display: inline;
                           }
                        }
                    }
                    &.locked {
                        .action {
                           border-color: #007989;
                           box-shadow: none;
                           background-color: #007989;
                           line-height: 22px;
                           .add {
                              display: none;
                           }
                           .check {
                              display: none;
                           }
                           .locked {
                              display: inline;
                           }
                        }
                    }
                }
            }
        }
    }
}