$max-width: 20ch;

.check-container {
   font-size: 12px;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax($max-width, 1fr));
   align-items: center;
   grid-column-gap: 2rem;
   grid-row-gap: 1rem;
   .array-item {
      flex: 0 0 19%;
      display: flex;
      align-items: center;
      .label {
         max-width: $max-width;
         text-overflow: ellipsis;
         overflow: hidden;
         white-space: nowrap;
      }
   }

   .checkbox-container {
      display: flex;
      position: relative;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      flex-direction: row-reverse;

      &:hover > .box {
         box-shadow: 1px 1px 2px lightgray;
      }
      .box {
         transition: box-shadow 0.08s ease-in-out;
         display: flex;
         border: 1px solid darkgray;
         border-radius: 2px;
         width: 12px;
         height: 12px;
         align-items: center;
         justify-content: center;
         &.checked {
            border: 1px solid transparent;
            border-radius: 2px;
            background: #3da0a2;
         }
         .check-mark {
            color: white;
            width: 8px;
            height: 8px;
         }
         &:hover {
            box-shadow: 1px 1px 2px lightgray;
         }
      }

      .label {
         &:hover ~ .box {
            box-shadow: 1px 1px 2px lightgray;
         }
         max-width: 16ch;
         overflow: hidden;
         text-overflow: ellipsis;
         user-select: none;
      }

      &.default {
         cursor: not-allowed;

         &:hover > .box,
         .box:hover,
         .label:hover ~ .box {
            box-shadow: none;
         }

         .box {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;
            background: rgba(61, 160, 162, 0.7);
         }

         .dash {
            line-height: 0;
            margin: 0 auto;
            font-size: 18px;
            font-weight: 700;
            color: rgba(50, 50, 50, 0.5);
            text-align: center;
         }
      }
      .click-div {
         position: absolute;
         width: 100%;
         inset: 0;
         z-index: 100;
      }
   }
}
