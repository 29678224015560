.form-select-box {
   //display: flex;
   display: grid;
   //   overflow: hidden;
   grid-auto-flow: column;
   grid-column-gap: 20px;
   //   grid-column-gap: 5px;
   //   grid-row-gap: 5px;
   //   align-items: center;
   //   width: 100%;
   //   justify-items: center;
   &.fsb-cols-1 {
      grid-template-columns: repeat(1, 1fr);
      display: block;
   }
   &.fsb-cols-2 {
      grid-template-columns: repeat(2, 1fr);
   }
   &.fsb-cols-3 {
      grid-template-columns: repeat(3, 1fr);
   }
   &.fsb-cols-4 {
      grid-template-columns: repeat(4, 1fr);
   }
   .select-box-item {
      flex: 1;

      height: 100%;
      min-height: 100%;
      border: 1px solid #e1e7ee;
      border-radius: 5px;
      padding: 25px;
      cursor: pointer;
      position: relative;
      transition: all 0.3s;
      // align-items: center;
      // justify-content: center;
      .select-box-checkbox {
         position: absolute;
         width: 24px;
         height: 24px;
         right: -1px;
         top: -1px;
         background: #007989;
         border-radius: 0 5px 0 4px;
         color: white;
         text-align: center;
         line-height: 24px;
         font-size: 10px;
         opacity: 0;
         transition: all 0.3s;
      }

      .select-box-tooltip {
         position: absolute;
         top: 6px;
         left: 12px;
         width: 24px;
         height: 24px;
         font-size: 16px;
         .warning-tooltip {
            font-weight: 500;
            color: #333;
         }
      }

      &.disabled {
         opacity: 0.6;
         cursor: not-allowed;
         user-select: none;
      }

      &:hover {
         border: 1px solid #b6b7b8;
      }
      &.selected {
         border: 1px solid #007989;
         .select-box-checkbox {
            opacity: 1;
         }
      }

      .select-box-wrapper {
         // align-self: center;
         height: 100%;
         flex: 1;
         position: relative;
         .select-box-icon {
            width: 32px;
            height: 32px;
            margin: 0 auto;
            margin-bottom: 24px;
            font-size: 32px;
         }
         .select-box-icon-jsx {
            width: 48px;
            height: 48px;
            margin: 0 auto;
            margin-bottom: 20px;
         }
         .select-box-image {
            width: 32px;
            height: 32px;
            margin: 0 auto;
            margin-bottom: 24px;
         }
         .select-box-title {
            font-size: 14px;
            line-height: 16px;
            color: #26323a;
            font-weight: 600;
         }
         .select-box-subtitle {
            margin-top: 6px;
            font-size: 12px;
            line-height: 16px;
            color: #626c72;
            font-weight: 500;
         }
         .select-box-description {
            margin-top: 15px;
            display: block;
            font-weight: 400;
            text-align: justify;
         }
      }
   }

   &.style-normal {
      // align-items: center;
      justify-content: center;
      text-align: center;

      .select-box-item {
         // margin-right: 20px;

         &:last-child {
            margin-right: 0;
         }
      }
   }

   &.style-minimal {
      // align-items: center;
      justify-content: center;
      text-align: center;

      .select-box-item {
         // margin-right: 20px;
         padding: 8px 15px;
         .select-box-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            .select-box-icon {
               margin-bottom: 0;
               height: 20px;
               width: 24px;
               line-height: 12px;
               padding-right: 15px;
               i {
                  font-size: 20px;
               }
            }
         }
         &:last-child {
            margin-right: 0;
         }
      }
   }

   &.style-check-list {
      align-items: left;
      justify-content: center;
      flex-direction: column;
      .select-box-item {
         margin-bottom: 10px;
         .select-box-checkbox {
            opacity: 0 !important;
         }
         &:last-child {
            margin-bottom: 0;
         }

         .select-box-wrapper {
            display: flex;
            .left-icon-image {
               background-color: #d7e9ec;
               border-radius: 5px;
               height: 48px;
               width: 48px;
            }
            .mid-text {
               flex: 1;
               align-self: center;
               padding-left: 16px;
               .select-box-title {
                  font-size: 16px;
               }
            }
            .right-text {
               align-self: center;
               .checkbox {
                  // padding-right:3rem;
                  // display: inline-block;
                  margin-bottom: 5px;
                  position: relative;
                  .form-check {
                     label {
                        margin-left: 12px;
                        color: #414141;
                        cursor: pointer;
                        font-size: 14px;
                     }
                     label:before {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        width: 22px;
                        height: 22px;
                        // background-color: rgb(66, 223, 149);
                        background-color: white;
                        border: 1px solid #e1e7ee;
                        border-radius: 0.25rem;
                        left: 0;
                        top: 0px;
                     }
                     input:checked + label:before {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        width: 22px;
                        height: 22px;
                        background-color: #007989;
                        border-color: #007989;
                        border-radius: 0.25rem;
                        left: 0;
                        top: 0px;
                     }
                     input:checked + label:after {
                        // content: '\2714';
                        content: "";
                        font-size: 14px;
                        position: absolute;
                        z-index: 1;
                        top: 6px;
                        left: 5px;
                        color: #c2c2c2;
                        transform: rotate(-45deg) scale(0.8);
                        border-bottom: solid 3px white;
                        border-left: solid 3px white;
                        background: none;
                        height: 8px;
                        width: 12px;
                     }
                     input {
                        opacity: 0;
                     }
                  }
                  .info-circle {
                     position: relative;
                     // top: 4px;
                     margin-left: 4px;
                     // position: absolute;
                     // right:0.75rem;
                     // top:1rem;
                  }
               }
            }
         }
      }
   }
}
