.installer-step-terms {
   .scroll-area {
      padding: 15px 5px 15px 15px;
      border-radius: 5px;
      background-color: #f9f9f9;
      .scroll-inner-wrapper {
         padding-right: 10px;
         font-weight: 400;
         color: rgb(65, 65, 65);
      }
   }
   .checkbox-green {
      margin-top: 4px;
      margin-bottom: 40px;
      text-align: right;
      .form-check {
         display: inline-block;
      }
      label {
         font-weight: bold;
      }
   }
   ol {
      counter-reset: item;
      li {
         display: block;
      }
      li:before {
         content: counters(item, ".") " ";
         counter-increment: item;
      }
   }
   .read-notice {
      margin-top: 15px;
      text-align: right;
      .please-read {
         font-size: 13px;
         font-weight: 500;
         color: rgb(194, 78, 78);
         font-style: italic;
      }
   }
   .bottom-not-reached {
      * {
         cursor: not-allowed !important;
      }
   }
}
