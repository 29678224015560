@import "app/assets/common/bootstrap-media-mixins";
.step-customize-workspace {
   @include media-breakpoint-between(lg, xl) {
      // max-width: 800px;
      margin: 0 auto;
   }
   @include media-breakpoint-up(xl) {
      // max-width: 1000px;
      margin: 0 auto;
   }
   .your-workspace {
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 8px;
      padding: 6px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
         display: block;
      }

      .selected-workspace-thumb {
         background: #007989;
         box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 8px;
         display: flex;
         flex: 0 250px;
         align-content: flex-start;

         .title {
            flex: 1;
            align-self: center;
            font-size: 13px;
            font-weight: 500;
            color: #ffffff;
            padding-left: 25px;
            // @include media-breakpoint-down(sm) {
            //     font-size:21px;
            // }

            @include media-breakpoint-down(sm) {
               padding-left: 12px;
            }
         }
         .preview-image {
            flex: 0 128px;
            margin: 6px;
            width: 128px;
            height: 64px;
            border: 0px solid #b5b5b5;
            //box-shadow: 0px 5px 6px rgba(88, 88, 88, 0.38);
            border-radius: 8px;
            //margin-bottom: 15px;
            position: relative;
            background-color: white;
            // @include media-breakpoint-down(sm) {
            //     height: 110px;
            // }
            .missing-image {
               position: absolute;
               width: 30px;
               height: 30px;
               opacity: 0.3;
               top: 50%;
               margin-top: -20px;
               left: 50%;
               margin-left: -20px;
               svg {
                  width: 40px;
                  height: 40px;
               }
            }
            > .image {
               height: 100%;
               width: 100%;
               background-size: cover;
               background-position: center;
               border-radius: 7px;
            }
         }
      }
      .workspace-title {
         flex: 1;
         padding-left: 20px;
         @include media-breakpoint-down(sm) {
            padding: 10px;
         }
         .form-dropdown {
            .dd-header {
               font-size: 16px;
               font-weight: 600;
               border: 0px solid transparent;
               background-color: transparent;
               padding: 0;
               height: auto;
            }
            .dd-list {
               border-top: 1px solid #ced4da;
               border-radius: 0.25rem;
               top: calc(100% + 0.25rem);
               &:before {
                  display: none;
               }
            }
         }
         .description {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.222727px;
            color: #414141;
            font-weight: 500;
            // margin-bottom: 10px;
            .editor {
               padding: 0;
            }
         }
      }
      // @include media-breakpoint-down(sm) {
      //     margin-bottom: 30px;
      // }
   }
   .features,
   .doc-templates {
      padding-top: 40px;
      // .ScrollbarsCustom {
      //     @include media-breakpoint-up(md) {
      //         max-height: calc(100vh - 220px) !important;
      //         min-height: calc(100vh - 220px) !important;
      //     }
      //     @include media-breakpoint-down(sm) {
      //         max-height: auto !important;
      //         min-height: auto !important;
      //         .ScrollbarsCustom-Wrapper {
      //             overflow: visible !important;
      //             position: relative !important;

      //             .ScrollbarsCustom-Scroller {
      //                 position: relative !important;
      //             }
      //         }
      //     }
      //     .ScrollbarsCustom-Wrapper {
      //         right:0;
      //     }
      // }

      .section {
         // min-height: 220px;
         @include media-breakpoint-down(sm) {
            min-height: 100px;
            margin-bottom: 20px;
         }
         > .title {
            margin-bottom: 15px;
            vertical-align: top;
            position: relative;
            font-size: 16px;
            vertical-align: top;
            > * {
               vertical-align: top;
               display: inline-block;
            }
            .text {
               vertical-align: top;
               display: inline-block;
               font-weight: bold;
               color: #26323a;
               // @include media-breakpoint-down(sm) {
               //     font-size:21px;
               // }
            }
            .bull {
               font-weight: 600;
               color: #a3b0b7;
               margin-left: 8px;
               margin-right: 8px;
               line-height: 21px;
            }
            .count {
               font-weight: 500;
               color: #a3b0b7;
            }
         }
         > .items {
            > span {
               display: inline-block;
               vertical-align: top;
               .add-item {
                  display: inline-block;
                  border: 1px dashed #e0e0e0;
                  background: rgba(221, 226, 231, 0.15);
                  box-sizing: border-box;
                  border-radius: 4px;
                  width: 120px;
                  height: 120px;
                  position: relative;
                  margin-right: 20px;
                  text-align: center;
                  cursor: pointer;
                  transition: all 0.3s;
                  &:hover {
                     border: 1px dashed rgb(184, 184, 184);
                     background: rgba(221, 226, 231, 0.35);
                  }
                  .icon-holder {
                     margin-top: 28px;
                     // margin-left: 10px;
                     vertical-align: top;
                     height: 28px;
                     display: inline-block;
                     width: 28px;
                     line-height: 28px;
                     text-align: center;
                     background-color: #007989;
                     border-radius: 50%;
                     cursor: pointer;
                     svg {
                        color: white;
                        height: 12px;
                        width: 12px;
                     }
                  }
                  span {
                     color: #718690;
                     font-size: 12px;
                     font-weight: 600;
                     position: absolute;
                     line-height: 16px;
                     bottom: 16px;
                     left: 16px;
                     right: 16px;
                     min-height: 30px;
                     text-align: center;
                  }
               }
            }
            .item {
               display: inline-block;
               border: 1px solid #e0e0e0;
               box-sizing: border-box;
               border-radius: 4px;
               width: 120px;
               height: 120px;
               position: relative;
               margin-right: 20px;
               margin-bottom: 20px;
               vertical-align: top;
               text-align: center;
               > span {
                  display: block;
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  top: 0;
               }
               .icon.feats {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  margin: 0 auto;
                  margin-top: 23px;
                  // margin-left: 16px;
                  text-align: center;
                  line-height: 60px;
                  // background: #F4F5F7;
                  svg {
                     width: 22px;
                     height: 22px;
                     color: #487084;
                  }
                  img {
                     width: 25px;
                     height: 23px;
                  }
                  i {
                     font-size: 36px;
                  }
               }
               .icon.docs {
                  // width:36px;
                  // height: 36px;
                  // border-radius: 50%;
                  padding-top: 16px;
                  // margin-left: 16px;
                  margin: 0 auto;
                  text-align: center;
                  // text-align: center;
                  line-height: 45px;
                  // background: #F4F5F7;
                  svg {
                     max-width: 32px;
                     max-width: 32px;
                     color: #487084;
                  }
                  img {
                     max-width: 32px;
                     max-width: 32px;
                  }
                  .type-icon {
                     line-height: 60px;
                     i {
                        font-size: 30px;
                     }
                  }
               }
               .locked {
                  position: absolute;
                  top: -1px;
                  right: -1px;
                  width: 21px;
                  height: 21px;
                  border-radius: 0 4px 0 4px;
                  background-color: #a7b2b8;
                  text-align: center;
                  line-height: 17px;
                  svg {
                     width: 11px;
                     height: 10px;

                     // opacity: 0.4;
                     color: white;
                  }
               }
               .remove {
                  position: absolute;
                  top: 0px;
                  right: 5px;
                  display: none;
                  cursor: pointer;
                  svg {
                     width: 12px;
                     height: 12px;
                     opacity: 0.4;
                  }
               }
               .title {
                  position: absolute;
                  line-height: 16px;
                  bottom: 16px;
                  left: 8px;
                  right: 8px;
                  min-height: 33px;
                  // background-color: red;
                  // overflow: hidden;
                  color: #676767;
                  font-size: 12px;
                  font-weight: 500;
               }

               &.can-be-removed {
                  background: white;

                  .locked {
                     display: none;
                  }
                  .remove {
                     display: block;
                  }
               }
            }
         }
      }
   }
}
