.orchestry-tasks-filter-selector-button {
   i {
      font-size: var(--mantine-font-size-sm);
      line-height: var(--mantine-line-height-sm);
      font-weight: 400;
      text-align: center;
   }
   &:hover {
      .filter-count {
         color: var(--mantine-color-primary-9);
         transition: color 300ms ease-out;
      }
   }
}

.orchestry-tasks-filter-selector-content {
   & * {
      font-family: var(--mantine-font-family) !important;
   }

   display: flex;
   flex-direction: column;
   padding: 24px;
   gap: 24px;
   width: 386px !important; //required to override mantine width rem calc

   .footer {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
   }
}
