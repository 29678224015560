.settings-categories {
   //  h3 {
   //      margin-top: 30px;
   //      margin-bottom: 15px;
   //      font-size: 20px !important;
   //      &:first-child {
   //          margin-top: 0;
   //      }
   //  }

   .blocked-words-form {
      p {
         margin-bottom: 0;
      }
      .blocked-words {
         .react-tagsinput {
            padding-top: 10px;
            padding-left: 10px;
            padding-bottom: 5px;
            padding-right: 10px;
            transition: all 0.3s;
            border-radius: 5px;
            border: 1px solid #ced4da;
            &.react-tagsinput--focused {
               border: 1px solid #28b976;
               box-sizing: border-box;
               box-shadow: 0px 3px 3px rgba(40, 185, 118, 0.269968);
               border-radius: 5px;
            }
            .react-tagsinput-input {
               font-family: "TT Norms";
               font-size: 14px;
               font-weight: 500;
            }
            .react-tagsinput-tag {
               background: rgba(181, 181, 181, 0.25);
               border: 1px solid transparent;
               color: #414141;
               font-size: 14px;
               font-family: "TT Norms";
               font-weight: 500;
               position: relative;
               padding-right: 20px;
               .react-tagsinput-remove {
                  position: absolute;
                  right: 5px;
                  top: 50%;
                  margin-top: -5px;
                  line-height: 12px;
                  opacity: 0.5;
                  &:before {
                     font-size: 18px;
                     line-height: 10px;
                  }
               }
               &:hover {
                  .react-tagsinput-remove {
                     opacity: 1;
                  }
               }
            }
         }
      }
      .blocked-help {
         margin-top: 5px;
         font-size: 11px;
         color: #676767;
      }
   }

   .callout {
      background-color: #fff7d0;
      border-left: 4px solid transparent;
      border-left-color: #ffe564;
      padding: 1rem 1rem 1rem calc(1rem - 4px);
      // margin-left: -1rem;
      margin-bottom: 2rem;
      p {
         margin-bottom: 10px;
      }
      .example {
         font-weight: 600;
         font-size: 16px;
         .chev {
            opacity: 0.5;
         }
         .fix {
            color: rgb(102, 122, 168);
            font-weight: 400;
         }
      }
   }
   .modal-controls {
      padding-left: 20px;
      padding-bottom: 20px;
   }
}
