.recommended-actions {
   // margin: 10px 20px 0 8px;
   // min-height: 30vw;
   .recommended-actions-header {
      text-align: left;
      padding-bottom: 10px;
      h4 {
         // font-weight: 700;
         // font-size: 27px;
         // line-height: 32px;
         // color: #26323a;
         // border-bottom: 1px solid #e3e7ee;
         font-weight: bold;
         font-size: 20px;
         // line-height: 32px;
         font-feature-settings: "liga" off;
         color: #26323a;
      }
   }
   .recommended-actions-body {
      .card {
         // width: 430px;
         // margin-bottom: 10px;
         flex-direction: row;
         background-color: #f1f5fe;
         border-color: #78cecd;
         border-width: 2px;
         border-radius: 4px;
         margin-bottom: 20px;
         &:last-child {
            margin-bottom: 0;
         }

         .card-title {
            font-weight: bolder;
            color: #000000;
         }

         .icon-container {
            color: #c0ffc0;
            margin: 15px 15px 0 15px;
         }

         .icon-bg {
            color: #007988;
         }

         .fa-trash-can-xmark {
            color: #ffffff;
            margin-left: 8px;
            margin-top: -1px;
         }

         .card-body {
            color: #6a7da4;

            .btn {
               margin-top: 10px;
               width: 100%;
               color: #f1f5fe;
               background-color: #6a7da4;
               border-color: #dde2ee;
               border-radius: 8px;
               transition: 0.3s all;

               &:hover {
                  background-color: #47597d !important;
                  // color: #6a7da4 !important;
               }
            }
            .btn:not(:last-child) {
               margin-right: 5px;
            }
         }
      }
   }
}

.recommended-actions-modal {
   .modal-content {
      border-radius: 5px !important;
      // height: 284px;
      width: 480px;
   }

   .modal-header {
      position: absolute;
      z-index: 1;
      right: 0;
      border-bottom-width: 0;
      .close {
         &:before {
            font-size: 1.8rem;
         }
      }
   }
}
