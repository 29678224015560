.doc-icon {
    .type-image {

    }
    .type-icon {
        font-size:45px;
    }
    &.size-22 {
        
    }
}