.request-status {
   display: inline-block;
   position: relative;
   vertical-align: top;
   white-space: nowrap;
   .status-icon {
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      svg[data-icon="spinner"] {
         animation: spin 3s linear infinite;
      }
   }
   .status-text {
      vertical-align: top;
      display: inline-block;
   }
   &.background-box {
      border-radius: 12px;
      height: 24px;
      padding: 0 15px;
      position: relative;
      z-index: 1;
      &:before {
         content: "";
         position: absolute;
         border-radius: 11px;
         height: 24px;
         width: 100%;
         left: 0;
         top: 0;
         z-index: 2;
         background-color: rgba(255, 255, 255, 0.822);
      }
      .status-text {
         position: relative;
         z-index: 3;
         // color:white;
         font-weight: 500;
         font-size: 11px;
         line-height: 24px;
      }
   }
}
