.orchestry-admin-job-browser {
   margin-bottom: -120px;
   .portlet-filters {
      .kt-portlet__body {
         padding: 10px 25px 20px 25px;
      }
      .form-group {
         margin-bottom: 0;
      }
   }
   .kt-portlet__head {
      min-height: 20px !important;
      .job-header {
         display: flex;
         width: 100%;
         padding: 10px 0;
         font-weight: 600;
         font-size: 14px;
         .job-tenant {
            flex: 1;
            padding-right: 20px;
         }

         .job-name {
            flex: 2;
            padding-right: 20px;
         }
         .job-status {
            width: 100px;
         }
         .job-time-durration {
            width: 150px;
         }
         .job-time-ago {
            width: 100px;
         }
         .job-time-date {
            width: 125px;
         }
      }
      &::after {
         background-image: linear-gradient(-180deg, #f2f4f5, #ffffff00) !important;
      }
   }
   .job-list {
      position: relative;
      .kt-portlet__body {
         padding: 15px;
      }

      .jobs {
         .job-item {
            // margin: 5px 0;
            padding: 5px 15px 5px 25px;
            border-bottom: 1px solid rgb(220, 220, 220);
            // background-color: white;
            transition: all 0.3s;
            &:hover {
               background-color: rgb(237, 249, 255);
               cursor: pointer;
            }

            .job-wrapper {
               display: flex;
               font-weight: 500;
               color: rgb(56, 56, 56);
               align-items: center;
               .job-tenant {
                  flex: 1;
                  word-break: break-all;
                  padding-right: 20px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
               .job-queue {
                  flex: 0.5;
                  padding-right: 20px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
               .job-name {
                  flex: 2;
                  word-break: break-all;
                  padding-right: 20px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
               .job-status {
                  width: 100px;
               }
               .job-time-durration {
                  width: 150px;
               }
               .job-time-ago {
                  width: 100px;
               }
               .job-time-date {
                  width: 125px;
                  font-size: 11px;
                  align-items: center;
               }
            }
         }
      }
      &.loading-data {
         .job-item {
            opacity: 0.3;
         }
      }
   }
   .hangfire-iframe {
      iframe {
         width: 100%;
         border: 0px solid transparent;
         height: 100%;
      }
   }
}

.orchestry-admin-job-browser-modal {
   .modal-dialog {
      max-width: 95%;
      .modal-header {
         padding: 10px 1.25rem;
         line-height: 14px;
         .close {
            padding: 0.85rem;
         }
         .modal-title {
            font-size: 14px !important;
            line-height: 14px;
         }
      }
      .modal-body {
         padding: 0;
      }
      iframe {
         width: 100%;
         border: 0px solid transparent;
      }
   }
}
