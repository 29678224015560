@import "_metronic/_assets/sass/breakpoints";

.directory-list-cards {
   .loading-skeleton {
      .header {
         background: rgba(238, 241, 245, 0.75);
         height: 45px;
         border-radius: 5px;
      }
      .items {
         display: flex;
         flex-wrap: wrap;
         flex-direction: row;
         overflow: hidden;
         justify-content: space-between;
         position: relative;
         .skel-card {
            font-family: TT Norms;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            margin-bottom: 30px;
            border-color: #dde2e7;
            display: flex;
            flex-direction: column;
            width: 31%;
            transition: all 0.3s;
            background: rgba(238, 241, 245, 0.75);
            border-radius: 4px;

            > div {
               padding: 20px 20px 120px 20px;
            }
            .information {
               display: flex;
               .pic {
                  .circle {
                     height: 42px;
                     width: 42px;
                     border-radius: 4px;
                     background-color: white;
                  }
               }
               .title {
                  // width:300px;
                  flex: 1;
                  padding-left: 20px;
                  .title-box {
                     margin-top: 12px;
                     background-color: white;
                     border-radius: 5px;
                     height: 21px;
                  }
               }
            }
            .property {
               flex: 1;
               .property-box {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 21px;
               }
            }
         }
      }
      .animated-wave {
         overflow: hidden;
         position: relative;
         &:before {
            content: "";
            display: block;
            position: absolute;
            left: -150px;
            top: 0;
            height: 100%;
            width: 150px;
            background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
            animation: load 2s cubic-bezier(0.4, 0, 0.2, 1);
            animation-iteration-count: infinite;
         }
         @keyframes load {
            from {
               left: -150px;
            }
            to {
               left: 100%;
            }
         }
      }
   }
   .card-items {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      overflow: hidden;
      justify-content: space-between;
      position: relative;

      .card {
         font-family: TT Norms;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         margin-bottom: 30px;
         border-color: #dde2e7;
         display: flex;
         flex-direction: column;
         width: 31%;
         transition: all 0.3s;

         @include kt-mobile {
            width: 100%;
            margin: 20px;
         }

         .card-top {
            padding: 24px 12px 0 24px;
            display: flex;
            width: 100%;
            // height: 66px;
            align-items: center;

            &.center-align-off {
               align-items: flex-start;
            }

            .card-logo {
               width: 55px;
               .profile-icon {
                  .profile-picture {
                     background: #ffffff;
                     border: 1px solid #e1e7ee;
                     width: 40px;
                     height: 40px;
                     box-sizing: content-box;
                     background-size: cover;
                     background-position: center center;
                     border-radius: 4px;
                     line-height: 44px;
                     .img {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                     }
                  }
               }
            }
            .card-title {
               flex: 1;
               margin: 0;
               padding-right: 10px;
               // max-width: 100%;
               // white-space: nowrap;
               // overflow: hidden;
               // text-overflow: ellipsis;

               // padding-bottom: 10px;
               span {
                  font-family: TT Norms;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 20px;
                  color: #56689a;
               }
            }
            .card-menu {
               display: flex;
               // align-items: center;
               padding-top: 12px;
               .fav-star {
                  padding: 0 0px;
                  cursor: pointer;
                  .star-on {
                     display: none;
                     color: #fab32f;
                  }
                  .star-off {
                     display: block;
                     color: #cad4df;
                  }
                  &.on {
                     display: block;
                     .star-on {
                        display: block;
                     }
                     .star-off {
                        display: none;
                     }
                  }
                  .star-icon {
                     i {
                        font-size: 20px;
                     }
                  }
               }
               .dots-button {
                  width: 36px;
                  height: 36px;
                  // position: absolute;
                  // top: 7px;
                  // right: 9px;
                  line-height: 15px;
                  text-align: center;
                  span {
                     line-height: 0;
                     font-size: 26px;
                     color: #718690;
                  }
               }
            }
         }
         .card-details {
            margin: 12px 24px;

            .card-title {
               max-width: 100%;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;

               // padding-right: 15px;
               span {
                  font-family: TT Norms;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 20px;
                  color: #26323a;
               }
            }
            .card-type {
               display: flex;
               font-size: 14px;
               display: flex;
               align-items: center;
               .cat-icon {
                  width: 23px;
                  height: 22px;
                  margin-right: 10px;
                  img {
                     max-width: 100%;
                  }
               }
               .cat-title {
                  .cat-title-text {
                     font-weight: 500;
                     font-size: 12px;
                  }
                  .cat-title-privacy {
                     font-size: 12px;
                     opacity: 0.6;
                  }
               }
               .users {
                  text-align: right;
                  flex: 1;
               }
            }
            .card-description {
               max-width: 200px;
               color: #718690;
               font-weight: 500;
               font-size: 12px;
               margin-top: 15px;
               // margin: 15px 0;
            }
            .card-users {
               display: flex;
               .owner {
                  width: 32px;
                  height: 32px;
                  > span > .profile-picture {
                     width: 32px;
                     height: 32px;
                     line-height: 32px;
                  }
               }
               .member-count {
                  margin-left: 8px;
                  width: 32px;
                  height: 32px;
                  line-height: 32px;
                  background: #d7e9ec;
                  border-radius: 50%;
                  text-align: center;
                  font-size: 11px;
               }
            }
         }
         .card-bottom {
            .card-show-properties {
               border-top: 1px solid #f1f1f1;
               padding: 12px 24px;
               flex: 1;
               cursor: pointer;
               div {
                  color: #56689a;
               }
               i {
                  margin-right: 5px;
                  transform: rotate(-90deg);
                  transition: all 0.3s;
               }
            }
            &.bot-open {
               .card-show-properties {
                  i {
                     transform: rotate(0);
                  }
               }
            }
            .card-properties {
               // background-color: #E1E7EE;
               padding: 12px 24px;
               .property {
                  margin-bottom: 20px;
                  .title {
                     font-weight: 400;
                     color: #718690;
                  }
                  .value {
                     color: black;

                     .links-group {
                        width: 100%;
                        .link {
                           width: 100%;
                           display: flex;
                           gap: 6px;
                           color: rgba(0, 121, 137, 0.5);
                           align-items: center;
                        }
                     }
                  }
                  &:last-child {
                     margin-bottom: 0;
                  }
               }
            }
         }
      }
      .card-no-results {
         // margin-top: 50px;
         width: 100%;
         text-align: center;
         .no-results-panel {
            margin: 60px auto;
         }
      }
   }
   &.loading-from-server {
      &:before {
         content: " ";
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         z-index: 99;
      }
      .card {
         opacity: 0.5;
      }
   }
}
