.richText,
.richText h1 {
   color: var(--mantine-color-text-secondary-0);
   font-size: 14px;
}

.richText h1 {
   font-size: 24px;
   font-weight: 500;
}
