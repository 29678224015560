.recommendation-resolutions-number-stat-skeleton {
   display: flex;
   flex-direction: column;
   border: 1px var(--border) solid !important;

   .top-section {
      display: flex;
      gap: 8px;
      align-items: center;
   }

   .middle-section {
      margin-top: 31px;
   }

   .bottom-section {
      display: flex;
      align-items: center;
      margin-top: 15.75px;
   }
}
