@use "~partials/breakpoint-variables" as bp;

.root {
   //I had to do this otherwise this variable would override my .content width
   --modal-size-xl: 900px !important;
   --modal-size-md: 400px !important;
   --modal-size-lg: 544px !important;

   &[data-variant] {
      .overlay {
         z-index: 1001;
      }
      .inner {
         z-index: 1001;
      }
   }

   .content {
      .body {
         padding: 24px;
      }
   }

   &[data-size="md"] {
      .content {
         .body {
            padding: 24px;
         }
      }
   }

   &[data-size="lg"] {
      .content {
         .body {
            padding: 32px;
         }
      }
   }

   &[data-size="xl"] {
      .content {
         .body {
            padding: 24px !important;
         }
      }
   }

   &[data-variant="orchestry"] {
      .content {
         border: 4px solid white;

         &::-webkit-scrollbar {
            background-color: transparent;
            width: 8px;
         }

         &::-webkit-scrollbar-thumb {
            background: var(--mantine-color-gray-3);
            border-radius: 10px;
         }
         .body {
            padding: calc(var(--_pt, var(--mb-padding, var(--mantine-spacing-md))) * 2);
         }
      }
   }

   &[data-size="xl"],
   &[data-variant="orchestry"] {
      .content {
         border: none;
      }
   }

   &[data-variant="recnotavailable"] {
      .header {
         display: flex;
         justify-content: center;
         align-items: center;
      }
      .body {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 16px;
         p {
            text-align: center;
         }
      }

      .overlay {
         z-index: 1000;
         height: 100vh;
         margin-top: -25px;
         background-color: rgba(128, 128, 128, 0.05);
         backdrop-filter: blur(5px);
      }
   }

   &[data-variant="add-user-modal"] {
      .inner {
         top: 25%;
      }
   }
}
