.directory-view-members {
   .members-button {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      height: 40px;
      width: 40px;
      line-height: 38px;
      background-color: white;
      border-radius: 4px;
      transition: all 0.3s;
      img {
         opacity: 0.8;
      }
      &:hover {
         background-color: #56689a1f;
         img {
            opacity: 1;
         }
      }
   }
}

.directory-view-members-modal {
   h2 {
      font-size: 16px;
      font-weight: 500;
      .count {
         color: #00798965;
         margin-left: 5px;
         font-size: 13px;
         font-weight: 600;
         position: relative;
         top: -1px;
         .number {
            color: #007989be;
            margin: 0 2px;
         }
      }
   }
   .loading-skeleton {
      .header {
         width: 75%;
         height: 25px;
         border-radius: 4px;
         background: rgba(238, 241, 245, 0.75);
         margin-bottom: 10px;
         margin-top: 15px;
      }
      .items {
         .item {
            display: flex;
            padding: 15px 20px 15px 10px;
            border-radius: 5px;
            width: 100%;
            align-items: center;
            // border-bottom: 1px solid #EEF1F5;
            // margin-bottom: 10px;
            background-color: #ffffffd4;
            > div {
               // padding: 0 0px 0 20px;
            }
            .pic {
               .circle {
                  height: 42px;
                  width: 42px;
                  border-radius: 50%;
                  background: rgba(238, 241, 245, 0.75);
               }
            }
            .title {
               margin-left: 15px;
               // width:300px;
               flex: 1;
               .title-box {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 21px;
               }
            }
            .property {
               flex: 1;
               .property-box {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 21px;
               }
            }
         }
      }
      .animated-wave {
         overflow: hidden;
         position: relative;
         &:before {
            content: "";
            display: block;
            position: absolute;
            left: -150px;
            top: 0;
            height: 100%;
            width: 150px;
            background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
            animation: load 2s cubic-bezier(0.4, 0, 0.2, 1);
            animation-iteration-count: infinite;
         }
         @keyframes load {
            from {
               left: -150px;
            }
            to {
               left: 100%;
            }
         }
      }
   }
   .modal-body {
      transition: all 0.3s;
   }
   .list-of-members {
      .list {
         background: #f2f4f7;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         padding: 8px;
         transition: all 0.3s;
         .no-users {
            font-weight: 500;
            padding: 10px;
         }
         .popover-hover {
            display: block;
            margin-bottom: 8px;

            &:last-child {
               margin-bottom: 0;
            }

            .user {
               background: #ffffff;
               border: 1px solid #dde2e7;
               box-shadow: inset 0px -2px 2px rgba(221, 226, 231, 0.05);
               border-radius: 4px;
               display: flex;
               position: relative;
               padding: 8px;
               align-items: center;
               .pp {
                  .profile-picture-placeholder {
                  }
               }
               .info {
                  padding-left: 15px;
                  flex: 1;
                  .name {
                     font-weight: 600;
                  }
                  .email {
                     font-size: 11px;
                     font-weight: 500;
                     opacity: 0.7;
                  }
               }
            }
         }
      }
      margin-bottom: 20px;
      &:last-child {
         margin-bottom: 0;
      }
   }

   .modal-footer {
      .buttons {
         display: flex;
      }
      .cancel {
         background: #ffffff;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         font-family: TT Norms;
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 16px;
         padding: 10px 16px;
         position: relative;
         height: 38px;
         margin-left: 8px;
         transition: all 0.3s;
         margin-right: 10px;
         cursor: pointer;
      }
   }
}
