.metadata-field {
   position: relative;
   .form-edit-choice-single .radios,
   .form-edit-choice-multiple .checkboxes {
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      padding: 0.75rem 0.75rem;

      .form-check {
         margin-bottom: 8px;
         &:last-child {
            margin-bottom: 0;
         }
      }
   }
   .metadata-field-tooltip {
      position: absolute;
      // display: none;
      overflow: hidden;
      z-index: 2;
      left: 100%;
      width: 50%;
      opacity: 0;
         box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
         border: 1px solid #eaeaea00;
         border-bottom: 1px solid #d8d8d800;
      margin-left: 2rem !important;
      border-radius: 3px;
      background-color: white;
      // .arrow {
      //    opacity: 0;
      // }
      .body {
         font-weight: 600;
         padding: 0.75rem;
      }
   }
   &:hover {
      .metadata-field-tooltip {
         animation-name: fade-in-tooltip;
         animation-duration: 0.7s;
         display: block;
         box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.15);
         border: 1px solid #eaeaea;
         border-bottom: 1px solid #d8d8d8;
         width: 50%;
         opacity: 1;
      }
   }
   @keyframes fade-in-tooltip {
      /* You could think of as "step 1" */
      0% {
         width: 0%;
         box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
         border: 1px solid #eaeaea00;
         border-bottom: 1px solid #d8d8d800;
      }
      39% {
         width: 0%;
         box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
         border: 1px solid #eaeaea00;
         border-bottom: 1px solid #d8d8d800;
      }
      40% {
         width: 50%;
         opacity: 0;
      }
      /* You could think of as "step 2" */
      100% {
         width: 50%;
         opacity: 1;
      }
   }
}
