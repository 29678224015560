.no-results-panel {
   text-align: center;
   margin: auto;
   .icon {
      display: inline-block;
      height: 75px;
      width: 75px;
      vertical-align: middle;
      background-color: rgb(228, 228, 228);
      border-radius: 50%;
      line-height: 72px;
      i {
         vertical-align: middle;
         font-size: 30px;
         color: white;
         // box-shadow: 2px 2px 0px black;
      }
   }
   .no-results-text {
      margin-top: 15px;
      font-weight: bold;
      font-size: 18px;
      color: black;
      opacity: 0.9;
   }
   .please-try {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 600;
      opacity: 0.5;
      color: black;
   }
}
