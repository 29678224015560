.delete-confirm-modal {
    .loading-wrapper {
        padding: 50px;
        text-align: center;
    }
    .group-reponse {
        margin-bottom: 25px;
        &:first-child {
            margin-bottom: 0;
        }
        .group-title {
            font-weight: 600;
            font-size:1.1rem;
            margin-bottom: 10px;
            &.title-priority-2 {
                color:red;
            }
            &.title-priority-1 {
                color:orange;
            }
            &.title-priority-0 {
                color:yellow;
            }
        }
        .group-message {
            margin-bottom: 10px;
        }
        ul.group-items {
            li.item {
                position: relative;
                padding-left: 15px;
                i {
                    position: absolute;
                    left:0;
                    top:50%;
                    margin-top: -7px;
                }
                .link {

                }
            }
        }
    }
}