.not-subscribed {
   img {
      margin: 0 auto;
      text-align: center;
      display: block;
   }
   width: 400px;
   margin: 150px auto;
   text-align: center;
   .title {
      margin-top: 20px;
      font-size: 22px;
      margin-bottom: 15px;
      text-align: center;
   }
   .sub-title {
      margin-bottom: 15px;
   }
}
