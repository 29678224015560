.lifecycle-stats {
   .top-area {
      height: 100px;
      margin-bottom: -100px;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;
      align-items: center;
      .icon {
         width: 48px;
         height: 48px;
         border-radius: 6px;
         background-color: rgba(215, 233, 236, 0.5);
         line-height: 54px;
         text-align: center;
         margin-right: 16px;
         i {
            color: #007989;
            font-size: 20px;
         }
      }
      .right-side {
         .count {
            font-size: 24px;
            color: #26323a;
            font-weight: 500;
            text-align: right;
         }
         .title {
            color: #718690;
            font-size: 14px;
            font-weight: 500;
            text-align: right;
         }
         .description {
            color: #7186909c;
            font-size: 11px;
            font-weight: 500;
            text-align: right;
         }
      }
   }
   .stats-box {
      height: 150px;
      margin: 0 0px;
      position: relative;
      // overflow: hidden;
      border-radius: 4px;
      svg {
         border-radius: 4px;
      }
      .empty-chart {
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         height: 52px;
         background-color: #f9f9f9;
         .line {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
         }
      }
   }
}

.single-stat-slice-tooltip {
   background: white;
   padding: 9px 12px;
   border: 1px solid #ccc;
   border-radius: 4px;
   box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.159);
   .slice-point {
      // display: flex;
      text-align: left;
      .title {
         // width:100px;
         font-weight: 500;
         // flex:1;
         margin-bottom: 5px;
         .over-last {
            // margin-top: 20px;
            color: #718690;
            font-size: 11px;
         }
      }
      .count {
         font-weight: 600;
         font-size: 20px;
         line-height: 20px;
         padding: 5px;
         background-color: gray;
         border-radius: 4px;
         color: white;
         // width: 50px;
         text-align: center;
      }
      .date {
         margin-top: 5px;
         font-weight: 400;
         // width: 50px;
         text-align: center;
      }
   }
}
