@import "app/assets/common/bootstrap-media-mixins";
.lifecycle-details {
   // background-color: white;
   // border-radius: 5px;
   // padding: 0 40px 40px 40px;
   h3 {
      color: #26323a;
      margin-bottom: 20px;
   }

   .tab-content {
      background-color: transparent;
      > div {
         .button-custom {
            margin-top: 20px;
         }
      }
      .loading-skeleton {
         padding: 15px;
         .header {
            background: rgba(238, 241, 245, 0.75);
            height: 45px;
            border-radius: 5px;
         }
         .items {
            .item {
               display: flex;
               padding: 15px 0px 15px 20px;
               width: 100%;
               align-items: center;
               border-bottom: 1px solid #eef1f5;
               > div {
                  padding: 0 0px 0 20px;
               }
               .pic {
                  .circle {
                     height: 42px;
                     width: 42px;
                     border-radius: 4px;
                     background: rgba(238, 241, 245, 0.75);
                  }
               }
               .title {
                  width: 300px;
                  .title-box {
                     background: rgba(238, 241, 245, 0.75);
                     border-radius: 5px;
                     height: 21px;
                  }
               }
               .property {
                  flex: 1;
                  .property-box {
                     background: rgba(238, 241, 245, 0.75);
                     border-radius: 5px;
                     height: 21px;
                  }
               }
            }
         }
         .animated-wave {
            overflow: hidden;
            position: relative;
            &:before {
               content: "";
               display: block;
               position: absolute;
               left: -150px;
               top: 0;
               height: 100%;
               width: 150px;
               background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
               animation: load 2s cubic-bezier(0.4, 0, 0.2, 1);
               animation-iteration-count: infinite;
            }
            @keyframes load {
               from {
                  left: -150px;
               }
               to {
                  left: 100%;
               }
            }
         }
      }
   }

   .nav-portlet {
      .kt-portlet__body {
         padding: 0 25px;
         .nav-tabs.nav-tabs-line {
            margin-bottom: 0;
            .nav-item {
               @include media-breakpoint-up(md) {
                  margin-right: 25px;
               }
               a {
                  i {
                     position: relative;
                     top: -2px;
                     font-size: 14px;
                     opacity: 0.5;
                  }
                  span {
                     font-size: 14px;
                     font-weight: 500;
                     opacity: 0.7;
                     transition: all 0.3s;
                  }
                  &:hover,
                  &.active {
                     span {
                        opacity: 1;
                     }
                  }
               }
            }
         }
      }
   }

   .box-with-border {
      background-color: white;
      border: 1px solid #e1e7ee;
      border-radius: 5px;
   }
}
