.form-button {
   padding: 10px 24px;
   display: inline-flex;
   border-radius: 5px;
   transition: all 0.3s;
   cursor: pointer;
   align-items: center;
   &.not-clickable {
      cursor: default;
   }
   &:hover {
   }
   .icon {
      margin-right: 10px;
      i {
         font-size: 18px;
      }
   }
   .text {
      font-weight: 500;
      font-size: 12px;
   }
   &.theme-white {
      border: 1px solid #e1e7ee;
      &:hover:not(.not-clickable) {
         border: 1px solid #c5c5c5;
         background-color: #ffffff;
      }
      .icon {
         color: #26323a;
      }
      .text {
         color: #26323a;
      }
   }
   &.theme-gray {
      border: 1px solid #d8d8d8;
      background-color: #e9e9e9;
      &:hover:not(.not-clickable) {
         background-color: #e9e9e9;
      }
      .icon,
      .text {
         color: #878787;
      }
   }
   &.theme-blue {
      background-color: #007989;
      &:hover:not(.not-clickable) {
         background-color: #005a66;
      }
      .icon,
      .text {
         color: white;
      }
   }
   &.size-sm {
      padding: 5px 12px;
   }
}
.form-buttons {
   display: flex;
   align-items: center;
   justify-content: center;
   > .form-button {
      margin-right: 12px;
      &:last-child {
         margin-right: 0;
      }
   }
   &.style-left-with-margin-bottom {
      justify-content: flex-start;
      margin-bottom: 20px;
   }
}
