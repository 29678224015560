.principal-item-not-found {
   background-color: rgb(255, 233, 233);
   .pic {
      background-color: transparent;
      padding-left: 5px;
      .profile-picture {
         background-color: transparent;
         i {
            color: rgb(180, 67, 67);
         }
      }
   }
   > span {
      color: rgb(180, 67, 67);
      font-style: italic;
   }
}
