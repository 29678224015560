.step-card-name {
   // > .row {
   //    margin-left: 6%;
   //    margin-right: 6%;
   // }
   .left-side {
      padding-right: 30px;
      padding-left: 30px;
   }

   .select-box-icon-jsx {
      .custom-icon-office-logo {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 2rem;
      }
   }

   .form-select-box {
   }
   .application-description {
      margin-bottom: 10px;
      font-weight: 400;
      font-style: italic;
   }
}
