.lifecycle-history {
   .history-creation-item {
      .kt-widget4__item {
         align-items: flex-start;
      }

      .user {
         font-weight: 400;
      }
      .action {
      }
      .user-pic {
         position: relative;
         margin-top: 5px;
         // width:32.5px;
         // height:32.5px;
         background-color: rgb(243, 243, 243);
         border-radius: 3px;
         img {
            background-color: white;
         }
         .badge {
            position: absolute;
            top: -4px;
            right: -4px;
            // background-color: rgb(4, 100, 255);
            // height: 16px;
            // width: 16px;
            // padding: 0;
            border: 1px solid white;
            // border-radius: 50%;
            // line-height: 11px;
            > * {
               color: white;
               height: 8px;
               width: 8px;
               // line-height: 9px;
               // margin: auto;
            }
            .kt-media--circle {
               border-radius: 4px;
               > span {
                  border-radius: 4px;
               }
            }
         }
         .kt-media--circle {
            border-radius: 4px;
            span {
               border-radius: 4px;
            }
         }
      }
      .feedback {
         background-color: whitesmoke;
         padding: 5px 10px;
         margin: 5px 0;
         position: relative;
         border-radius: 0 4px 4px 4px;
         &:before {
            content: "";
            position: absolute;
            left: -5px;
            top: -1px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid whitesmoke;
            transform: rotate(45deg);
         }
      }
      .kt-widget4__text {
         opacity: 1;
         font-size: 12px !important;
         font-weight: 400;
      }
      .kt-widget4 .kt-widget4__item {
         align-items: flex-start;
      }
   }
   .policy-history-items {
      .ph-item {
         background-color: white;
         border-radius: 5px;
         // padding: 24px;
         margin-bottom: 1rem;
         padding-bottom: 1rem;
         position: relative;
         border-bottom: 1px dashed #ebedf2;
         .chev-down {
            position: absolute;
            bottom: -11px;
            width: 40px;
            background-color: white;
            left: calc(50% - 20px);
            text-align: center;
         }
         &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
            .chev-down {
               display: none;
            }
         }
         .ph-top {
            display: flex;
            align-items: center;
            .ph-action-icon {
               // width: 56px;
               padding-right: 1rem;
               .icon {
                  width: 33px;
                  height: 33px;
                  border-radius: 4px;
                  text-align: center;
                  i {
                     line-height: 35px;
                     font-size: 16px;
                  }
                  &.icon-green {
                     background-color: rgba(10, 187, 134, 0.15);
                     i {
                        color: #0abb87;
                     }
                  }
                  &.icon-blue {
                     background-color: rgba(85, 120, 235, 0.15);
                     i {
                        color: #5578eb;
                     }
                  }
                  &.icon-purple {
                     background-color: #eb55e615;
                     i {
                        color: #eb55e6;
                     }
                  }
                  &.icon-yellow {
                     background-color: #d0cb4117;
                     i {
                        color: #d0cb41;
                     }
                  }
                  &.icon-red {
                     background-color: rgba(235, 85, 85, 0.15);
                     i {
                        color: #eb5555;
                     }
                  }
               }
            }
            .right {
               .action-title {
                  // color: #26323a;
                  color: #595d6e;
                  font-weight: 600;
                  font-size: 1rem;
               }
               .user-and-date {
                  font-size: 12px !important;
                  font-weight: 400;
                  .userspan {
                     padding-left: 4px;
                     .principal-item {
                        margin: 0;
                        > span {
                           padding-left: 4px;
                           padding-right: 0px;
                           font-size: 12px;
                        }
                        .pic {
                           height: 13px;
                           width: 13px;
                           margin-top: 1px;
                           .profile-picture {
                              height: 13px;
                              width: 13px;
                           }
                           .two-letter {
                              span {
                                 line-height: 13px;
                                 vertical-align: top;
                                 font-size: 7px;
                                 font-weight: 700;
                              }
                           }
                        }
                     }
                  }
               }
               .date {
                  color: #718690;
                  font-weight: 400;
                  font-size: 12px;
               }
               .completed-by {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  .left {
                     // flex:1;
                     color: #718690;
                     font-weight: 500;
                     padding-right: 15px;
                  }
                  .right {
                     flex: 1;
                     text-align: left;
                     .principal-item {
                        margin: 0;
                     }
                  }
               }
            }
         }
         .ph-action {
            // margin-top: 20px;
            margin-left: calc(33px + 1rem);
            > div:first-child {
               margin-top: 1rem;
            }
            .property-with-name {
               margin-bottom: 10px;
               .title {
                  font-size: 10px;
                  color: #718690;
                  font-weight: 500;
               }
               .property {
                  color: #26323a;
                  font-size: 13px;
                  line-height: 22px;
                  font-weight: 500;
                  .emails {
                     padding-top: 10px;
                  }
               }
            }

            .property-change {
               display: flex;
               align-items: center;
               margin-top: 5px;
               margin-bottom: 12px;
               .from {
                  display: inline-flex;
                  // padding: 5px 15px;
                  font-size: 13px;
                  font-weight: 600;
                  // height: 30px;
                  border-radius: 4px;
                  // color: #3a3a3ae9;
                  color: #5a6ba2;
               }
               i {
                  padding: 0 10px;
                  opacity: 0.7;
                  font-size: 9px;
               }
               .to {
                  display: inline-flex;
                  // padding: 5px 15px;
                  font-size: 13px;
                  font-weight: 600;
                  // height: 30px;
                  border-radius: 4px;
                  // color: #3a3a3ae9;
                  color: #57887a;
               }
            }

            // .property-change {
            //    display: flex;
            //    align-items: center;
            //    margin-top: 5px;
            //    margin-bottom: 12px;
            //    .from {
            //       display: inline-flex;
            //       padding: 5px 15px;
            //       font-size: 13px;
            //       font-weight: 500;
            //       height: 30px;
            //       border-radius: 4px;
            //       color: #3a3a3ae9;
            //       background-color: rgb(255, 203, 203);
            //    }
            //    i {
            //       padding: 0 10px;
            //    }
            //    .to {
            //       display: inline-flex;
            //       padding: 5px 15px;
            //       font-size: 13px;
            //       font-weight: 500;
            //       height: 30px;
            //       border-radius: 4px;
            //       color: #3a3a3ae9;
            //       background-color: rgb(191, 225, 191);
            //    }
            // }

            .action-policy-name-with-completed-user {
               border: 1px solid #e1e7ee;
               border-radius: 4px;
               padding: 10px;
               padding-bottom: 0;
               .policy-name {
                  color: #26323a;
                  font-size: 13px;
                  line-height: 22px;
                  font-weight: 600;
                  margin-bottom: 12px;
               }
               .completed-by {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  .left {
                     // flex:1;
                     color: #718690;
                     font-weight: 500;
                     padding-right: 15px;
                  }
                  .right {
                     flex: 1;
                     text-align: left;
                     .principal-item {
                        margin: 0;
                     }
                  }
               }
            }
         }
      }
   }
   .portlet-type-renewal,
   .portlet-type-archival {
      // box-shadow: none;
      .kt-portlet__head {
         &:after {
            // background: none;
         }
      }
      .kt-portlet__body {
         // background-color: #dee0e5;
      }
   }
   .no-results {
      background-color: rgba(255, 255, 255, 0.528);
      font-weight: 400;
      padding: 20px 10px;
      border-radius: 4px;
      text-align: center;
      i {
         font-size: 30px;
         margin-bottom: 10px;
         opacity: 0.3;
      }
   }
}
