.template-tab-live-template {
   .form-group {
      margin-bottom: 0.5rem;
      .form-check {
         // display: inline;
      }
   }
   .living-template-container {
      margin-top: 20px;
   }
   .sub-option {
      padding: 0px 0px 0px 7px;
      margin: 5px 0 5px 7px;
      border-left: 2px solid #efefef;
   }
   h5 {
      margin-top: 20px;
      margin-bottom: 10px;
      color: rgb(54, 54, 54);
   }
   .lists-to-clone {
      background-color: rgb(240, 240, 240);
      border-radius: 5px;
      padding: 10px 10px 1px 10px;
   }
   .sharepoint-url {
      padding-bottom: 10px;
      a {
         display: inline-block;
         background-color: rgb(238, 238, 238);
         padding: 3px 10px;
         border-radius: 3px;
         &:hover {
            background-color: rgb(228, 228, 228);
         }
         img {
            width: 15px;
            margin-right: 7px;
         }
         span {
            font-size: 11px;
            font-weight: 500;
         }
      }
   }
}
