@use "~partials/breakpoint-variables" as bp;

.recommendation-header-skeletons {
   grid-template-columns: calc(1fr / 3) calc(1fr / 6) calc(1fr / 6) calc(1fr / 3);
   display: grid;
   grid-gap: 8px;
   gap: 8px;
   width: 100%;

   @media (max-width: bp.$orch-breakpoint-desktop) {
      grid-template-columns: 1fr 1fr;
   }
}
