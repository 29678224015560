.card-nightly-guest-data-export {
   .kt-portlet__head {
      align-items: center !important;
   }
   .description {
      font-weight: 500;
      margin-bottom: 15px;
   }
   .form-orch-toggle {
      margin-bottom: 0;
   }
}
