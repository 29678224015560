.lifecycle-approval {
   margin-top: 20px;
   border-top: 1px solid #e1e7ee;
   padding-top: 20px;
   .alert {
      margin-bottom: 15px;
   }
   .instructions {
      background-color: rgba(0, 121, 137, 0.08);
      border-radius: 4px;
      padding: 5px 10px;
      font-weight: 500;
      margin-bottom: 15px;
      color: #26323a;
      white-space: pre-wrap;
   }
   .comment {
      margin-bottom: 15px;
      span {
         font-weight: 500;
         color: #718690;
      }
      .reject-required-comment {
         font-size: 12px;
         font-weight: 400;
         color: #d16e6e;
      }
   }
   .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .animated-loader {
         zoom: 0.9;
         margin-right: 0.5rem;
      }
      i {
         position: relative;
         bottom: -1px;
      }
   }
}

.lifecycle-approval-render-box {
   font-weight: 400;
}
.lifecycle-approval-modal {
   .modal-content {
      > h3 {
         margin: 15px 25px;
      }

      .kt-portlet {
         margin-bottom: 0px;

         .row-actions {
            // margin-top: 20px;
            .btn-block {
               i {
                  font-size: 13px;
               }
            }
         }
      }
      .confirmation-box {
         margin: 10px 25px 20px 25px;
         position: relative;
         .confirm {
            margin-bottom: 10px;
         }
         .btn {
         }
         .archive,
         .reject {
            display: block;
            width: calc(100% - 100px);
         }
         .cancel {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 85px;
            background-color: white;
         }
      }
   }
}
