.approval-render-box {
   font-weight: 400;
   .reject-required-comment {
      font-size: 12px;
      font-weight: 400;
      color: #d16e6e;
   }
}
.approval-modal {
   .modal-content {
      > h3 {
         margin: 15px 25px;
      }

      .kt-portlet {
         margin-bottom: 0px;

         .row-actions {
            // margin-top: 20px;
            .btn-block {
               i {
                  font-size: 13px;
               }
            }
         }
      }
      .confirmation-box {
         margin: 10px 25px 20px 25px;
         position: relative;
         .confirm {
            margin-bottom: 10px;
         }
         .action-wrapper {
            width: calc(100% - 100px);
         }
         .btn {
         }
         .approve,
         .reject {
            display: block;     
         }
         .cancel {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 85px;
            background-color: white;
         }
      }
   }
}
