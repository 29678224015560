.subtitle-button {
   font-weight: 500 !important;
   &.color-orange {
      background: #eda334 !important;
      color: white !important;
      border-color: #eda334 !important;
      &:hover {
         background: #dd9831 !important;
      }
   }
   &.color-brandblue {
      // background: #007989 !important;
      background: #007989 !important;

      color: white !important;
      border-color: #007989 !important;
      &:hover {
         background: #00626f !important;
      }
      &.btn-sm {
         padding: 0.4rem 0.9rem;
      }
   }
   &.color-outline-gray {
      // background: #007989 !important;
      background: #ffffff !important;

      color: #596a73 !important;
      border-color: #dde2e7 !important;
      &:hover {
         border-color: #00626f !important;
         color: #00626f !important;
      }
      &.btn-sm {
         padding: 0.4rem 0.9rem;
      }
   }
   .btn-sm {
      line-height: 15px;
   }
   .animated-loader-shown {
      display: inline-block;
   }
   &:disabled {
      cursor: default;
   }
}
