.login {
   margin: auto;
   text-align: center;
   .orch-logo {
      text-align: center;
      margin: 0 auto 20px auto;

      img {
         width: 150px;
      }
   }
   .custom-button {
      background-image: url("~/public/media/ms-signin-button.png");
      width: 215px;
      height: 40px;
      margin: 20px 0 0px 0px;
      border: none;
      cursor: pointer;
      display: inline-block;
      color: black;
      text-align: center;
      font-family: "Segoe UI", "wf_segoe-ui_normal", "Arial", sans-serif;
      font-size: 15px;
      background-size: cover;
      background-repeat: no-repeat;
      line-height: 40px;
      font-weight: 500;
      padding-left: 25px;
   }

   p {
      margin: 15px auto 0 auto;
      max-width: 200px;
      font-size: 11px;
   }
}
