.request-wizard-permissions-guests {
   margin-top: 20px;
   .user-list-guests {
      // margin-top: 20px;
      padding: 15px;
      background: rgba(238, 241, 245, 0.75);
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      // height: 100%;
      min-height: 130px;
      position: relative;

      .portlet-alert {
         width: calc(100% + 32px);
         margin-left: -16px;
         margin-top: -16px;
         .alert {
            border-radius: 4px 4px 0 0;
         }
         &.type-success {
            .alert {
               border-color: #e1e7ee;
            }
         }
      }
      &.min-warning {
         border-color: #fd397a;
      }

      .user {
         background: #ffffff;
         border: 1px solid #dde2e7;
         box-sizing: border-box;
         border-radius: 5px;
         // height: 33px;
         vertical-align: top;
         display: inline-flex;
         // padding-right: 14px;
         margin-right: 10px;
         margin-bottom: 10px;
         position: relative;
         align-items: center;
         .pic {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgb(211, 211, 211);
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin-left: 8px;
            // margin-top:3px;
            // margin-left:3px;
            // border:1px solid #D5D1D2;
            > div {
               border-radius: 50%;
               font-size: 11px;
            }
            > img {
               width: 100%;
               height: 100%;
               border-radius: 50%;
            }
         }
         > span {
            padding: 9px;
            flex: 1;
            // line-height: 33px;
            font-size: 13px;
            font-weight: 500;
            vertical-align: top;
            // margin-left:8px;
            color: #26323a;
         }

         .locked {
            padding: 9px 12px 9px 4px;
            font-size: 10px;
         }

         .remove {
            // display: none;
            // background-color: white;
            // position: absolute;
            // z-index: 1;
            // top:-1px;
            // right:0;
            // // right: -20px;
            // width: 33px;
            // height: 33px;
            // text-align: center;
            // line-height: 34px;
            // line-height: 31px;
            // border-radius: 0 16px 16px 0;
            // border-top: 1px solid #E0E0E0;
            border-left: 1px solid #e0e0e0;
            // border-bottom: 1px solid #E0E0E0;
            padding: 9px 12px;
            cursor: pointer;
            > svg {
               height: 12px;
               width: 12px;
               opacity: 0.5;
            }
         }
         &:hover {
            .remove {
               // display: block;
               > svg {
                  color: red;
               }
            }
         }
      }
      .no-results {
         .no-icon-text {
            position: absolute;
            margin: 0 auto;
            text-align: center;
            width: 120px;
            top: 50%;
            left: 50%;
            margin-left: -60px;
            margin-top: -25px;
            img {
               margin-bottom: 10px;
            }
            span {
               display: block;
               font-weight: 600;
               font-size: 14px;
               line-height: 16px;
               text-align: center;
               color: #718690;
            }
         }
      }
      .principal-item.holder {
         border: 1px dashed #fd397a7b;
         background-color: transparent;
         cursor: pointer;
         transform: all 0.3s;
         &:hover {
            background-color: rgba(255, 255, 255, 0.234);
         }
         // opacity: 0.5;
         // border-color:rgb(223, 223, 223);
         // background-color: rgb(223, 223, 223);
         .pic {
            background-color: transparent;
            border: 1px dashed #d1d1d1;
            .profile-picture {
               // background-color:white;
               background-color: transparent;
            }
         }
         span {
            min-width: 115px;
         }
      }
      .default-users-divider {
         font-weight: 500;
         color: rgba(0, 0, 0, 0.494);
         font-size: 11px;
         border-top: 1px solid #e0e0e0;
         margin-top: 10px;
         margin-bottom: 10px;
         padding-top: 10px;
      }
   }
}
