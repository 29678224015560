@mixin item-layout {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 0.5rem;
   font-size: 14px;
   i {
      font-size: 14px;
   }
   .text {
      margin: 0;
   }
}

.lifecycle-guests-list {
   .type-item {
      @include item-layout;
   }
   .executiontype-item {
      @include item-layout;
   }
}
