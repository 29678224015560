.form-dropdown {
   position: relative;
   //  outline: 1px solid #b9b9b9;
   .dd-header {
      position: relative;
      padding: 0.65rem 0.65rem;
      z-index: 1;
      display: flex;
      cursor: pointer;
      .dd-header-title {
         flex: 1;
         overflow: hidden;
         .header-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
         img {
            max-width: 19px;
            max-height: 19px;
            position: relative;
            top: -2px;
            margin-right: 10px;
         }
         svg {
            max-width: 19px;
            max-height: 19px;
            position: relative;
            margin-right: 10px;
            color: #b5b5b5;
            width: 18px;
         }
         i {
            margin-right: 10px;
            font-size: 18px;
         }
      }
      .arrow {
         flex: 20px 0;
         svg {
            position: absolute;
            right: 0.75rem;
            top: 50%;
            margin-top: -5px;
            color: #b5b5b5;
            width: 10px;
         }
      }
   }
   .dd-list {
      position: absolute;
      z-index: 10000;
      top: calc(100% + -0.25rem);
      width: 100%;
      padding-top: 0.25rem;
      background-color: white;
      border: 1px solid #ced4da;
      border-top: 0px solid transparent;
      border-radius: 0 0 0.25rem 0.25rem;
      &:before {
         content: "";
         position: absolute;
         height: 1px;
         left: 0.75rem;
         right: 0.75rem;
         background-color: #eaeaea;
      }

      > ul {
         margin-bottom: 0;
         box-shadow: 0px 8px 9px #2c2c2c57;
         border-radius: 4px;
      }

      .dd-list-item {
         padding: 0.75rem;
         position: relative;
         z-index: 3;
         cursor: pointer;
         color: black;
         font-weight: 500;
         text-transform: none;
         &:before {
            content: "";
            position: absolute;
            height: 1px;
            left: 0.75rem;
            right: 0.75rem;
            bottom: 0;
            background-color: #eaeaea;
         }
         &.disabled {
            cursor: default;
            color: lightgray;
            &:hover {
               color: lightgray;
               background-color: white;
            }
            .description {
               color: red;
            }
         }
         &.has-image-or-icon {
            padding: 0.75rem 0.75rem 0.75rem 35px;
            img {
               max-width: 19px;
               max-height: 19px;
               position: absolute;
               top: 50%;
               left: 8px;
               margin-top: -9px;
               // margin-right: 10px;
            }
            svg {
               max-width: 19px;
               max-height: 19px;
               position: absolute;
               top: 50%;
               left: 8px;
               margin-top: -5px;
               color: #b5b5b5;
               width: 18px;
            }
            i {
               position: absolute;
               top: 50%;
               left: 8px;
               margin-top: -9px;
               display: block;
               font-size: 19px;
            }
         }
         &:hover {
            background-color: rgba(61, 160, 162, 0.699);
            color: white;
            &:before {
               display: none;
            }
            svg {
               color: white;
            }
         }

         &.dd-selected {
            background-color: #3da0a2;
            color: white;
            cursor: default;
            &:before {
               display: none;
            }
            svg {
               color: white;
            }
         }

         &:last-child {
            border-radius: 0 0 0.25em 0.25em;
            &:before {
               display: none;
            }
         }

         .description {
            // margin-left: 5px;
            font-weight: 400;
            opacity: 0.7;
            display: block;
            text-transform: none;
         }
      }

      &.dark-icons {
         .dd-list-item {
            svg {
               color: rgb(44, 44, 44);
            }
            &:hover,
            &.dd-selected {
               svg {
                  color: white;
                  //color:rgb(44, 44, 44);
               }
            }
         }
      }
      .no-results {
         padding: 0.75rem 0.75rem;
         color: rgba(0, 0, 0, 0.486);
      }
      .searchbox {
         background: rgb(241, 241, 241);
         position: relative;
         padding: 8px 6px;
         input {
            width: calc(100%);
            padding: 3px 6px 3px 30px;
            font-size: 14px;
            outline: none !important;
            border-radius: 3px;
            border-color: transparent;
            background-color: white;
            line-height: 24px;
         }
         > div {
            position: absolute;
            left: 13px;
            top: 50%;
            margin-top: -8px;

            &.icon-search {
               opacity: 0.6;
               > svg {
                  height: 14px;
                  width: 14px;
               }
            }
            &.icon-delete {
               // display: none;
               opacity: 0.3;
               margin-top: -8px;
               left: 14px;
               cursor: pointer;
               > svg {
                  height: 14px;
                  width: 14px;
               }
            }
         }
      }
   }

   .ScrollbarsCustom {
      .ScrollbarsCustom-Wrapper {
         right: 20px;
         .ScrollbarsCustom-Content {
            min-width: calc(100% - 7px) !important;
            width: calc(100% - 7px);

            .list-unstyled {
               margin-bottom: 0;
            }

            .dd-list-item {
               border-radius: 0 4px 4px 0;
            }
         }
      }
      .ScrollbarsCustom-TrackY {
         width: 7px !important;
         // height: 100% !important;
         // top:0 !important;
         opacity: 0;
         transition: all 0.3s;
         right: 5px !important;
         background-color: transparent !important;
         .ScrollbarsCustom-ThumbY {
            background-color: #d9d9dc !important;
         }
      }
      &:hover {
         .ScrollbarsCustom-TrackY {
            opacity: 1;
         }
      }
   }

   &.has-error:not(.open) {
      .dd-header {
         border: 1px solid red !important;
      }
   }

   &.disabled {
      .dd-header {
         background-color: #f7f8fa;
      }
   }
}
