@import "app/assets/common/bootstrap-media-mixins";

.team-information-vertical-section {
   display: none;
}

@include media-breakpoint-down(xs) {
   .team-information-vertical-section {
      display: flex;
      flex-direction: column;
      .logo-group {
         display: flex;
         align-items: left;
         justify-content: space-between;
         flex-direction: column;
         .team-information-logo {
            padding-right: 0;
            margin-bottom: 20px;

            .profile-picture {
               box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
               border-radius: 8px;
               border: 1px solid #e1e1e1;
               width: 68px;
               height: 68px;
            }
         }

         .team-information-activity {
            margin-bottom: 20px;
         }

         .team-information-activity {
            padding: 14px 20px;
            max-height: fit-content;
            &.status-extended {
               border-radius: 5px;
               border: 1px solid #2f462f;
            }
            &.status-active {
               border-radius: 5px;
               border: 1px solid #879d51;
            }

            &.status-inactive {
               border-radius: 5px;
               border: 1px solid #8f3a15;
            }

            &.status-pendingapproval {
               border-radius: 5px;
               border: 1px solid rgba(148, 130, 204, 0.88);
            }
            &.status-deleted {
               border-radius: 5px;
               border: 1px solid #593a17;
            }
            &.status-failed {
               border-radius: 5px;
               border: 1px solid #553a1a;
            }
            &.status-done {
               border-radius: 5px;
               border: 1px solid #06741d;
            }
         }
      }
      .information-pane {
         border-radius: 10px;
         padding: 8px;
         background: #f4f4f4;
         margin-bottom: 20px;
         .top {
            padding-bottom: 16px;
            border-bottom: 1px solid #bebebe;
            .team-information-description {
               margin-top: 10px;
            }
            .pills {
               display: flex;
               margin-top: 10px;
            }
         }
         .bottom {
            margin-top: 16px;

            .team-information-custom-metadata {
               margin-top: 12px;
            }
         }
      }
   }
}
