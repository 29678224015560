.orchestry-admin-etl-crawl-stats {
   .region-select {
      max-width: 50%;
      margin-bottom: 20px;
   }
   table {
      // th {
      //    /* Something you can count on */
      //    height: 140px;
      //    white-space: nowrap;
      // }
      // table-layout: fixed;

      th {
         span {
            word-break: break-word;
            white-space: normal !important;
            font-size: 11px;
         }
      }

      .tenant-title {
         font-weight: 500;
         cursor: pointer;
         color: #56689a;
      }
      .tenant-guid {
         font-size: 10px;
      }
      // th > div {
      //    transform:
      //         /* Magic Numbers */ translate(25px, 51px) /* 45 is really 360 - 45 */ rotate(315deg);
      //    width: 30px;
      // }
      // th > div > span {
      //    border-bottom: 1px solid #ccc;
      //    padding: 5px 10px;
      // }
      // th,
      // td {
      //    padding: 0 !important;
      // }
      .action-buttons-crawler {
         button {
            i {
               margin-right: 0;
               font-size: 1.1rem !important;
            }
         }
      }
   }
}

.etl-details-modal {
   .modal-xl {
      max-width: calc(100% - 25px);
   }
}

.orchestry-admin-etl-cached-files {
   .table-list-table {
      margin-top: -20px;
   }
   .filename {
      cursor: pointer;
      color: #56689a;
      font-weight: 500;
   }
}
.orchestry-crawler-cached-file-modal {
   z-index: 1070 !important;
   .modal-xl {
      max-width: calc(100% - 25px);
      .modal-body {
         padding: 0;
      }
      .CodeMirror {
         min-height: calc(100vh - 140px);
      }
      .stats {
         ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            background-color: rgb(255, 255, 231);
            border-bottom: 1px solid rgb(165, 165, 56);
            li {
               padding: 5px 10px;
               color: rgb(165, 165, 56);
            }
         }
      }
   }
}
.orchestry-crawler-cached-file-modal-bg {
   z-index: 1060 !important;
}
