@import "_metronic/_assets/sass/breakpoints";

.team-directory {
   background-color: white;

   &.team-directory-sharepoint {
      .directory-wrapper {
         padding: 0;
      }
   }

   &.overflow-fix {
      overflow: hidden;
   }

   header {
      padding: 30px 60px;
      background: #f2f4f7;
      box-shadow: inset 0px 6px 7px rgba(65, 65, 65, 0.08);
      display: flex;

      @include kt-mobile {
         padding: 15px;
         display: block;
      }

      .left {
         display: flex;

         .orch-logo {
            .icon {
               width: 40px;
               height: 40px;
               background-color: white;
               border-radius: 5px;
               vertical-align: middle;
               line-height: 38px;
               text-align: center;
               img {
                  width: 32px;
               }
            }
         }
         .title {
            font-family: TT Norms;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 42px;
            font-feature-settings: "liga" off;
            color: #26323a;
            padding-left: 15px;
            @include kt-mobile {
               font-size: 20px;
            }
         }
      }
      .right {
         flex: 1;
         text-align: right;
         @include kt-mobile {
            display: block;
            display: none;
         }
         button {
            background: #007989;
            border-radius: 4px;
            color: white;
            font-size: 12px;
            font-weight: 500;
            padding: 10px 20px;
            border: 0px solid transparent;
            i {
               font-size: 9px;
               position: relative;
               top: -1px;
               margin-right: 8px;
            }
         }
      }
   }
   .directory-wrapper {
      padding: 0 25px;
      @include kt-mobile {
         padding: 0;
      }
   }
}
