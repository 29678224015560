.report-column-guest-membership-status-new-existing {
   .status-box {
      padding: 3px 12px;
      border-radius: 5px;
      font-weight: 500;
      margin-right: 10px;
      min-width: 75px;
      text-align: center;
      max-width: 175px;

      &.guest-status-existing {
         background-color: #aee8bf;
         color: #557248;
      }

      // &.guest-status-inactive {
      //    background-color: rgba(222, 121, 43, 0.35);
      //    // border: 1px solid #aee8bf;
      //    color: #b36909;
      // }

      // &.guest-status-deleted {
      //    background-color: #f3eab1;
      //    // border: 1px solid #f3eab1;
      //    color: #827940;
      // }

      // &.guest-status-pendingapproval {
      //    background-color: rgba(210, 203, 255, 0.88);
      //    // border: 1px solid rgba(210, 203, 255, 0.88);
      //    color: #3a3a3ae9;
      // }

      &.guest-status-new {
         background-color: #bce8e3;
         color: #4b8a85;
      }
   }
}
