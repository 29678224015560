.overlay-body {
   display: flex;
   align-items: center;
   justify-content: center;
   inset: 0;
   position: absolute;
   box-sizing: content-box;
   height: 100%;
   width: 100%;
   background-color: rgba(0, 0, 0, 0.65);
   color: white;
   z-index: 2;
   border-radius: 50%;

   &.square {
      border-radius: initial;
   }

   .ms-TooltipHost {
      display: grid;
      place-items: center;
   }

   .tooltip-body {
      padding: 0.5rem;
      color: white;
      ul {
         list-style: none;
         padding: 0;
         margin: 0;
      }
   }
}
