@import "app/assets/common/bootstrap-media-mixins";

.team-information-page-details {
   padding: 1.5rem;

   &.team-information-page-details-sharepoint {
      @include media-breakpoint-down(xs) {
         padding: 0 10px 0 2px;
      }
   }
}
