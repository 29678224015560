@import "app/assets/common/bootstrap-media-mixins";

$container-gap: 1.25rem;

.step-card-guest-review-policy-execution {
   //border settings for subcontainers
   .guest-review-container,
   .policy-approval-container,
   .if-no-action-container,
   .escalate-to-container,
   .escalate-quantity-container,
   .webhook-container {
      padding: 25px;
      border: 1px solid rgb(225, 231, 238);
      border-radius: 5px;
   }

   .form-group {
      margin: 0;
   }

   .main-container {
      display: flex;
      flex-direction: column;
      gap: 25px;
   }

   .guest-review-container {
      color: #26323a;
      display: flex;
      flex-direction: column;
      gap: $container-gap;
      .fixed-description {
         color: #26323a;
         display: flex;
         flex-direction: column;
         gap: 0.25rem;
         .interval-text {
            display: flex;
            gap: 0.5rem;
         }
         .label {
            margin: 0;
         }
         p {
            margin: 0;
         }
         i {
            font-size: 18px;
         }
      }
   }

   .policy-approval-container,
   .if-no-action-container {
      display: flex;
      flex-direction: column;
      gap: $container-gap;
      h4 {
         margin: 0;
      }
   }

   .webhook-container {
      display: flex;
      flex-direction: column;
   }

   .escalate-quantity-container {
      display: flex;
      flex-direction: column;
      gap: $container-gap;
      .label {
         margin: 0;
      }
   }

   .retry-cadence-container {
      display: flex;
      flex-direction: column;
      .row:nth-child(3) {
         margin-bottom: 15px;
      }
   }

   .retry-container {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      input[type="number"] {
         flex-basis: 100px;
      }
      .label {
         margin: 0;
      }
   }

   .policy-approval-container,
   .escalate-to-container {
      .form-group {
         margin: 0;
      }
   }

   .webhook-container {
      p {
         color: #718690;
         margin-bottom: 13px;
      }
   }

   .escalate-to-container {
      padding-left: 35px;
      .col {
         padding: 0;
      }
   }

   .recurrence-container {
      display: flex;
      gap: 1.5rem;
      align-items: center;

      .form-dropdown {
         flex-basis: 50%;
      }
   }
}
