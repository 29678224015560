.orchestry-featured-icon {
   display: flex;
   justify-content: center;
   align-items: center;

   .button {
      cursor: pointer;
   }

   &[data-size="xxs"] {
      font-size: 9px;
      width: 16px;
      height: 16px;
   }
   &[data-size="xs"] {
      font-size: 12px;
      width: 24px;
      height: 24px;
   }
   &[data-size="sm"] {
      font-size: 16px;
      min-width: 32px;
      width: 32px;
      height: 32px;
   }
   &[data-size="md"] {
      font-size: 20px;
      width: 40px;
      height: 40px;
   }

   &[data-size="lg"] {
      font-size: 24px;
      width: 48px;
      height: 48px;
      min-width: 48px;
   }

   &[data-size="xl"] {
      font-size: 28px;
      width: 56px;
      height: 56px;
   }

   &[data-variant="no-background"] {
      background-color: transparent !important;
   }

   &[data-variant="no-background-dashed-border"] {
      background-color: transparent !important;
      border: 1px dashed var(--mantine-color-gray-5);
      border-radius: 50%;
   }

   &[data-variant="light-circle"] {
      border-radius: 1000px;
   }

   &[data-variant="double-circle"] {
      border-radius: 1000px;
      position: relative;
      padding: 20px;

      &::before {
         content: "";
         position: absolute;
         border-radius: 1000px;
         z-index: 1;

         width: 70%;
         height: 70%;
      }
      &[data-size="xxs"] {
         padding: 4px;

         &::before {
            width: 80%;
            height: 80%;
         }
      }
      &[data-size="xs"] {
         padding: 4px;

         &::before {
            width: 80%;
            height: 80%;
         }
      }
      &[data-size="sm"] {
         padding: 18px;

         &::before {
            width: 76%;
            height: 76%;
         }
      }
      &[data-size="md"] {
         padding: 24px;

         &::before {
            width: 75%;
            height: 75%;
         }
      }

      &[data-size="lg"] {
         padding: 28px;

         &::before {
            width: 72%;
            height: 72%;
         }
      }

      &[data-size="xl"] {
         padding: 32px;

         &::before {
            width: 70%;
            height: 70%;
         }
      }

      i {
         position: relative;
         font-size: 0.9em;
         z-index: 2;
      }
   }

   &.img-icon {
      img {
         z-index: 3;
      }
   }

   &[data-disabled="true"] {
      color: var(--mantine-color-gray-6) !important;
      background: var(--mantine-color-gray-1) !important;
      &::before {
         background-color: --mantine-color-gray-4;
      }
   }

   // Color variant naming convention is based on the following pattern:
   // [color]-[background]-[::before background-color]
   // Where you add the next specificity level if there is a tie in the previous level
   // eg: see gray-6, gray-6-gray-1-gray-1 and gray-7-gray-1 below
   &[data-color-variant="blue-5"] {
      color: var(--mantine-color-blue-5);
      background: var(--mantine-color-blue-0);
      &::before {
         background-color: var(--mantine-color-blue-1);
      }
   }

   &[data-color-variant="blue-7"] {
      color: var(--mantine-color-blue-7);
      background: var(--mantine-color-blue-0);
      &::before {
         background-color: var(--mantine-color-blue-1);
      }
   }

   &[data-color-variant="blue-8"] {
      color: var(--mantine-color-blue-8);
      background: var(--mantine-color-blue-1);
      &::before {
         background-color: var(--mantine-color-blue-2);
      }
   }

   &[data-color-variant="blue-9"] {
      color: var(--mantine-color-blue-9);
      background: var(--mantine-color-blue-1);
      &::before {
         background-color: var(--mantine-color-blue-2);
      }
   }

   &[data-color-variant="brown-7"] {
      color: var(--mantine-color-brown-7);
      background: var(--mantine-color-brown-0);
      &::before {
         background-color: var(--mantine-color-brown-1);
      }
   }

   &[data-color-variant="brown-8"] {
      color: var(--mantine-color-brown-8);
      background: var(--mantine-color-brown-0);
      &::before {
         background-color: var(--mantine-color-brown-1);
      }
   }

   &[data-color-variant="error-5"] {
      color: var(--mantine-color-error-5);
      background: var(--mantine-color-error-1);
      &::before {
         background-color: var(--mantine-color-error-2);
      }
   }

   &[data-color-variant="error-7"] {
      color: var(--mantine-color-error-7);
      background: var(--mantine-color-error-0);
      &::before {
         background-color: var(--mantine-color-error-1);
      }
   }

   &[data-color-variant="error-8"] {
      color: var(--mantine-color-error-8);
      background: var(--mantine-color-error-1);
      &::before {
         background-color: var(--mantine-color-error-2);
      }
   }

   &[data-color-variant="error-9"] {
      color: var(--mantine-color-error-8);
      background: var(--mantine-color-error-1);
      &::before {
         background-color: var(--mantine-color-error-2);
      }
   }

   &[data-color-variant="white-gray-2"] {
      color: var(--mantine-color-white);
      background: var(--mantine-color-gray-2);
   }

   &[data-color-variant="white-primary-7"] {
      color: var(--mantine-color-white);
      background: var(--mantine-color-primary-7);
   }

   &[data-color-variant="gray-5"] {
      color: var(--mantine-color-gray-5);
      background: var(--mantine-color-gray-1);
   }

   &[data-color-variant="gray-5-transparent"] {
      color: var(--mantine-color-gray-5);
      background: transparent;
   }

   &[data-color-variant="gray-6"] {
      color: var(--mantine-color-gray-6);
      background: var(--mantine-color-gray-1);
      &::before {
         background-color: var(--mantine-color-gray-2);
      }
   }

   &[data-color-variant="gray-6-gray-1-gray-1"] {
      color: var(--mantine-color-gray-6);
      background: var(--mantine-color-gray-1);
      &::before {
         background-color: var(--mantine-color-gray-1);
      }
   }

   &[data-color-variant="gray-7"] {
      color: var(--mantine-color-gray-7);
      background: var(--mantine-color-gray-0);
      &::before {
         background-color: var(--mantine-color-gray-1);
      }
   }

   &[data-color-variant="gray-7-gray-1"] {
      color: var(--mantine-color-gray-7);
      background: var(--mantine-color-gray-1);
      &::before {
         background-color: var(--mantine-color-gray-2);
      }
   }

   &[data-color-variant="gray-8"] {
      color: var(--mantine-color-gray-8);
      background: var(--mantine-color-gray-0);
      &::before {
         background-color: var(--mantine-color-gray-1);
      }
   }

   &[data-color-variant="indigo-9"] {
      color: var(--mantine-color-indigo-9);
      background: var(--mantine-color-indigo-0);
      &::before {
         background-color: var(--mantine-color-indigo-1);
      }
   }

   &[data-color-variant="magenta-7"] {
      color: var(--mantine-color-magenta-7);
      background: var(--mantine-color-magenta-0);
      &::before {
         background-color: var(--mantine-color-magenta-1);
      }
   }

   &[data-color-variant="magenta-8"] {
      color: var(--mantine-color-magenta-8);
      background: var(--mantine-color-magenta-1);
      &::before {
         background-color: var(--mantine-color-magenta-2);
      }
   }

   &[data-color-variant="orange-6"] {
      color: var(--mantine-color-orange-6);
      background: var(--mantine-color-orange-0);
      &::before {
         background-color: var(--mantine-color-orange-1);
      }
   }

   &[data-color-variant="orange-7"] {
      color: var(--mantine-color-orange-7);
      background: var(--mantine-color-orange-0);
      &::before {
         background-color: var(--mantine-color-orange-1);
      }
   }

   &[data-color-variant="orange-8"] {
      color: var(--mantine-color-orange-8);
      background: var(--mantine-color-orange-1);
      &::before {
         background-color: var(--mantine-color-orange-2);
      }
   }

   &[data-color-variant="pink-6"] {
      color: var(--mantine-color-pink-6);
      background: var(--mantine-color-pink-1);
      &::before {
         background-color: var(--mantine-color-pink-2);
      }
   }

   &[data-color-variant="pink-7"] {
      color: var(--mantine-color-pink-7);
      background: var(--mantine-color-pink-0);
      &::before {
         background-color: var(--mantine-color-pink-1);
      }
   }

   &[data-color-variant="pink-8"] {
      color: var(--mantine-color-pink-8);
      background: var(--mantine-color-pink-0);
      &::before {
         background-color: var(--mantine-color-pink-1);
      }
   }

   &[data-color-variant="pink-9"] {
      color: var(--mantine-color-pink-9);
      background: var(--mantine-color-pink-0);
      &::before {
         background-color: var(--mantine-color-pink-1);
      }
   }

   &[data-color-variant="primary-0"] {
      color: var(--mantine-color-primary-0);
      background: var(--mantine-color-primary-7);
   }

   &[data-color-variant="primary-0-primary-8"] {
      color: var(--mantine-color-primary-0);
      background: var(--mantine-color-primary-8);
   }

   &[data-color-variant="primary-0-primary-0"] {
      color: var(--mantine-color-primary-0);
      background: var(--mantine-color-primary-0);
   }

   &[data-color-variant="primary-5"] {
      color: var(--mantine-color-primary-5);
      background: var(--mantine-color-primary-1);
   }

   &[data-color-variant="primary-6"] {
      color: var(--mantine-color-primary-6);
      background: var(--mantine-color-primary-0);
      &::before {
         background-color: var(--mantine-color-primary-1);
      }
   }

   &[data-color-variant="primary-7"] {
      color: var(--mantine-color-primary-7);
      background: var(--mantine-color-primary-0);
      &::before {
         background-color: var(--mantine-color-primary-1);
      }
   }

   &[data-color-variant="primary-8"] {
      color: var(--mantine-color-primary-8);
      background: var(--mantine-color-primary-0);
      &::before {
         background-color: var(--mantine-color-primary-8);
      }
   }

   &[data-color-variant="primary-8-primary-1"] {
      color: var(--mantine-color-primary-8);
      background: var(--mantine-color-primary-0);
      &::before {
         background-color: var(--mantine-color-primary-1);
      }
   }

   &[data-color-variant="primary-9"] {
      color: var(--mantine-color-primary-9);
      background: var(--mantine-color-primary-0);
      &::before {
         background-color: var(--mantine-color-primary-1);
      }
   }

   &[data-color-variant="primary-9-primary-1"] {
      color: var(--mantine-color-primary-9);
      background: var(--mantine-color-primary-1);
      &::before {
         background-color: var(--mantine-color-primary-2);
      }
   }

   &[data-color-variant="primary-9-success-0"] {
      color: var(--mantine-color-primary-9);
      background: var(--mantine-color-success-0);
   }

   &[data-color-variant="purple-7"] {
      color: var(--mantine-color-purple-7);
      background: var(--mantine-color-purple-0);
      &::before {
         background-color: var(--mantine-color-purple-1);
      }
   }

   &[data-color-variant="purple-8"] {
      color: var(--mantine-color-purple-8);
      background: var(--mantine-color-purple-0);
      &::before {
         background-color: var(--mantine-color-purple-1);
      }
   }

   &[data-color-variant="purple-9"] {
      color: var(--mantine-color-purple-9);
      background: var(--mantine-color-purple-0);
      &::before {
         background-color: var(--mantine-color-purple-1);
      }
   }

   &[data-color-variant="success-6"] {
      color: var(--mantine-color-success-6);
      background: var(--mantine-color-success-0);
      &::before {
         background-color: var(--mantine-color-success-1);
      }
   }

   &[data-color-variant="success-7"] {
      color: var(--mantine-color-success-7);
      background: var(--mantine-color-success-0);
      &::before {
         background-color: var(--mantine-color-success-1);
      }
   }

   &[data-color-variant="success-8"] {
      color: var(--mantine-color-success-8);
      background: var(--mantine-color-success-1);
      &::before {
         background-color: var(--mantine-color-success-2);
      }
   }

   &[data-color-variant="warning-7"] {
      color: var(--mantine-color-warning-7) !important;
      background: var(--mantine-color-warning-0);
      &::before {
         background-color: var(--mantine-color-warning-1);
      }
   }

   &[data-color-variant="warning-8"] {
      color: var(--mantine-color-warning-8);
      background: var(--mantine-color-warning-1);
      &::before {
         background-color: var(--mantine-color-warning-2);
      }
   }

   &[data-color-variant="warning-9"] {
      color: var(--mantine-color-warning-9);
      background: var(--mantine-color-warning-1);
      &::before {
         background-color: var(--mantine-color-warning-2);
      }
   }
}
