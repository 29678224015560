@import "app/assets/common/bootstrap-media-mixins";
.form-group-select {
    label {
        color:black;
    }
    .add {
        margin-top: 1px;
        margin-left: 7px;
        vertical-align: top;
        height: 18px;
        display: inline-block;
        width: 18px;
        line-height: 18px;
        text-align: center;
        // background-color: #28B976;
        border-radius: 50%;
        cursor: pointer;
        svg {
            color:#28B976;
            height: 17px;
            width: 17px;
        }
    }
    .add-button {
        display: inline-block;
        background-color:#28B976;
        color:white;
        padding: 5px 10px;
        margin-bottom: 10px;
        font-weight: 500;
        border-radius: 3px;
        transition: background-color 0.5s;
        cursor: pointer;
        span {margin-left: 7px;}
        svg {
            position: relative;
            top:1px;
        }
        &:hover {
            background-color:rgb(25, 148, 90);
        }
    }
    .group-content {
        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }

    .group-area {
        
        padding-bottom: 0px;
        background-color: whitesmoke;
        height: auto !important;
        // min-height: 61px;
        .no-results {
            opacity: 0.5;
            font-style: italic;
            margin-bottom: 22px;
            padding-top:10px;
        }
    }
}