.form-office-asset-picker {
    .selected-value {
        margin-bottom: 20px;
        label {
            font-size: 1rem;
            font-weight: 500;
        }
        .selected-cols {
            position: relative;
            display: flex;
            align-items: center;
            .selected-item {
                background-color: #e5e5ff;
                    border-radius: 5px;
                    display: inline-block;
                position: relative;
                padding-left: 50px;
                
                .selected-icon-type {
                    display: block;
                    position: absolute;
                    top: 50%;
                    margin-top: -25px;
                    bottom: 0;
                    left:0;
                    width: 50px;
                    // background-color: rgba(255, 255, 255, 0.61);
                    padding: 14px 0;
                    text-align: center;
                }
                .wrapper {
                    padding: 10px 15px;
                    display: inline-block;
                    background-color: rgb(183, 183, 238);
                    border-radius: 5px;
                    position: relative;
                    padding-left: 55px;
                    transition: ease-in-out 0.3s;
                    &:hover {
                     background-color: rgb(156, 156, 216);
                    }
                    .kt-media.kt-media--circle {
                        background-color: white;
                    }
                    .selected-title {
                        font-size: 16px;
                        line-height: 28px;
                        color:white;
                        margin-left: 0px;
                    }
                    .profile-picture {
                        margin-top: 2px;
                        position: absolute;
                        left:15px;
                        top:50%;
                        margin-top: -12.5px;
                    }
                    .url {
                        margin-left: 0px;
                        color: #00000094;
                        font-weight: 600;
                        font-size: 12px;
                        /* opacity: 0.7; */
                        margin-top: -4px;
                    }
                }
                
            }
            .actions {
                display: inline-block;
                margin-left: 15px;
                position: relative;
                top:50%;
            }
            .browser-button {

            }
        }
    }
    .browser-button {
        margin-top: 0 !important;
        margin-bottom: 20px;
        padding: 9px 15px;
        i {
            font-size:14px; 
            margin-right: 10px;
            opacity: 0.7;
        }
    }
}
.office-asset-modal {
    .modal-sm {
        max-width: 450px;
        .picker-content {
            .picker-title {
                padding: 15px 25px;
                font-size: 16px;
                font-weight: 600;
                background-color: rgba(0, 0, 0, 0.058823529411764705);
                position: relative;
               .close-button {
                  position: absolute;
                  top:50%;
                  margin-top: -15px;
                  right:10px;
                  padding: 3px 10px;
                  opacity: 0.8;
                  // background-color: yellow;
                  cursor: pointer;
                  &:hover {
                     opacity: 1;
                  }
               }
            }
            .picker-search {
                padding: 10px 25px;
                position: relative;
                background-color: rgba(240, 240, 240, 0.3803921568627451);

                input {
                    width:100%;
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                    border-radius: 5px;
                    height: 36px;
                    padding: 6px 40px 6px 12px;
                    outline: none;
                    position: relative;
                    z-index: 1;
                    // width: 250px;
                }
                .icon-search {
                    position: absolute;
                    vertical-align: top;
                    z-index: 2;
                    padding: 5px;
                    height: 26px;
                    width: 26px !important;
                    right: 33px;
                    top: 50%;
                    margin-top: -15px;
                    font-size: 16px;
                    cursor: pointer;
                    display: inline-block;
                }
            }
            .picker-results {
                // padding: 0px 15px;
                min-height: 300px;
                .picker-item {
                    position: relative;
                    padding: 10px 25px;
                    font-size:14px;
                    border-top: 1px solid rgba(0, 0, 0, 0.05);
                    transition: all 0.3s;
                    .profile-picture {
                        margin-right: 15px;
                        cursor: pointer;
                        &:hover {
                            // outline: 2px solid rgba(0, 0, 255, 0.294);
                            box-shadow: 0 0  2px blue;
                        }
                    }
                    &:first-child {
                        border-top:none;
                    }
                    &:hover {
                        // background-color: rgb(241, 241, 241);
                        // cursor: pointer;
                    }
                    .asset-title {
                        display: inline-block;
                        position: relative;
                        top:2px;
                        cursor: pointer;
                        // line-height: 16px;
                        transition: all 0.3s;
                        &:hover {
                            color: black;
                            // font-weight: 500;
                        }
                    }
                    .checkbox {
                        opacity: 0;
                        position: absolute;
                        right:30px;
                        top:50%;
                        margin-top: -10px;
                        transition: all 0.3s;
                    }
                    .locked {
                        position: absolute;
                        right:60px;
                        top:50%;
                        margin-top: -11px;
                        i {
                            font-size: 11px;
                            opacity: 0.5;
                        }
                    }
                    &.selected {
                        .checkbox {
                            opacity: 1;
                        }
                    }
                    .sharepoint-link {
                        display: block;
                        margin-left: 40px;
                        margin-top: -5px;
                        a {
                            font-size: 11px;
                            font-weight: 500;
                            opacity: 0.8;
                            &:hover {
                                // color: black;
                                text-decoration: underline !important;
                            }
                        }
                    }
                    
                }
                .no-results {
                    padding: 15px 25px;
                    text-align: center;
                    font-weight: 500;
                }
                .loading {
                    padding: 25px;
                    text-align: center;
                }
            }
            .picker-actions {
                background-color:rgba(0, 0, 0, 0.058823529411764705);
                padding: 15px 15px;
                text-align: right;
            }
        }
    }
}


.office-asset-modal-backdrop {
   z-index: 1070 !important;
}
.office-asset-modal {
   z-index: 1080 !important;
}