.generate-new-health-check-page {
   & * {
      font-family: var(--mantine-font-family) !important;
   }

   .combobox-wrapper {
      .mantine-Input-wrapper {
         width: 150px;
      }
   }
}
