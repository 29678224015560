.profile-picture {
   position: relative;
   display: inline-block;
   vertical-align: top;
   width: 25px;
   height: 25px;
   text-align: center;
   line-height: 25px;
   background-color: #d6e3e8;
   border-radius: 50%;
   overflow: hidden;

   &.loader {
      .animated-loader {
         height: 100%;
         width: 100%;
      }
   }
   &.base-64 {
      .img {
         width: calc(100% + 2px);
         height: calc(100% + 2px);
         background-size: cover;
         background-position: center;
         position: relative;
         top: -1px;
         left: -1px;
      }
   }
   &.orch-logo {
      border: 1px solid transparent;
      .img {
         background-size: 60%;
         background-repeat: no-repeat;
         background-color: white;
      }
   }
   &.two-letter {
      font-size: 12px;
   }
   &.icon {
      svg {
         width: 20px;
         height: 18px;
      }
   }
   &.size-xs {
      // width: 36px;
      // height: 36px;
      line-height: 23px;
      &.icon {
         line-height: 20px;
         svg {
            width: 12px;
            height: 12px;
         }
         i {
            font-size: 14px;
            &.fa,
            &.fa-solid {
               font-size: 10px;
            }
         }
      }
   }
   &.size-sm {
      width: 36px;
      height: 36px;
      line-height: 36px;
      &.icon {
         line-height: 40px;
         svg {
            // width: 12px;
            // height: 12px;
         }
         i {
            font-size: 20px;
         }
      }
   }
   &.size-md {
      width: 48px;
      height: 48px;
      line-height: 48px;
   }
   &.size-lg {
      width: 64px;
      height: 64px;
      line-height: 64px;
   }
   &.color-green {
      color: #1bc5bd;
      background-color: #c9f7f5;
   }
   &.color-red {
      color: #f64e60;
      background-color: #ffe2e5;
   }
   &.color-orange {
      color: #f68c4e;
      background-color: #ffefe2;
   }
   &.color-blue {
      color: #3699ff;
      background-color: #e1f0ff;
   }
   &.color-purple {
      color: #d736ff;
      background-color: #fbe1ff;
   }
   &.color-yellow {
      color: #ffa800;
      background-color: #fff4de;
   }
   &.color-gray {
      color: #cecece;
      background-color: #fff4de;
   }

   // &.icon {
   //     background-color: #6264A7;
   //     svg {
   //         color: white;
   //     }
   // }
}
