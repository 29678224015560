.health-checks-settings-page {
   display: flex;
   flex-direction: column;

   .schedule-cadence {
      width: 150px;
   }

   .review-process {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .review-process-dropdown {
         width: 150px;
      }
   }

   .header {
      display: flex;
      justify-content: space-between;
      .action-buttons {
         display: flex;
         gap: 8px;
      }
   }

   & * {
      font-family: var(--mantine-font-family) !important;
   }
}
