.form-fileuploadbox {
    outline: none !important;
    .fileupload {
        border: 1px dashed #B5B5B5;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0px 20px 30px rgba(0, 69, 117, 0.1);
        padding: 40px 15px 60px 15px;
        outline: none !important;
        cursor: pointer;
        .icon {
            color: #FFA530;
            width: 28px;
            height: 28px;
            margin: 0 auto;
            i {
               font-size:36px;
                width: 28px;
                height: 28px;
            }
        }
        .text {
            padding-top: 15px;
            text-align: center;
            font-size: 14px;
            color: #7e7e7e;
            font-weight: 500;
        }

        &.has-file {
            cursor: default;
            padding: 0;
            border: 1px solid #B5B5B5;
            min-height: 100px;
            .file-preview {
                position: relative;
                // height: 300px;
                // width: 100%;
                // background-size: cover;
                // background-position: center center;
                text-align: center;
                padding-top: 35px;
                padding-bottom: 65px;
                .doc-icon {
                    // padding: 50px 30px;
                    // text-align: center;
                    img {
                        max-width: 90px;
                    //width:100%;
                    }
                }
                .icons-actions {
                    position: absolute;
                    height: 36px;
                    bottom: 10px;
                    right: 10px;
                    .delete,
                    .download {
                        border:1px solid rgb(218, 218, 218);
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
