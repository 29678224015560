.group-profile-picture-placeholder {
    display: inline-block;
    vertical-align: top;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 24px;
    background-color: #D6E3E8;
    &.icon {
        background-color: #6264A7;
        svg {
            color: white;
        }
    }
}
