.orchestry-filter-selector-count {
   display: flex;
   justify-content: center;
   align-items: center;
   background: var(--mantine-color-primary-0);
   border-radius: 1000px;
   min-width: 20px;
   width: fit-content;
   height: 20px;

   &[data-variant="gray"] {
      background: var(--mantine-color-gray-1);
   }
}
