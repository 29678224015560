.recommendation-card-control-section {
   display: flex;
   align-items: center;

   &.is-not-expandable {
      .orchestry-icon-label {
         width: fit-content;
      }
   }

   .orchestry-expandable-button {
      margin-left: 6px;
   }

   .orchestry-icon-label {
      margin-left: 94px;
      width: 128px;
   }
}
