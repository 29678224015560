.settings-installation {
   .version-highlight {
      background-color: rgb(186, 186, 247);
      padding: 2px 5px;
      font-size: 11px;
      font-weight: 500;
      color: white;
      border-radius: 3px;
      &.red {
         background-color: rgb(216, 140, 140);
      }
      &.green {
         background-color: rgb(121, 204, 104);
      }
   }
   .install-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .status-icon {
         width: 36px;
         height: 36px;
         min-width: 36px;
         max-width: 36px;
         background-color: rgba(46, 160, 44, 0.5);
         border-radius: 4px;
         text-align: center;
         display: flex;
         align-items: center;
         &.needs-update {
            background-color: #c58f8f;
         }
         i {
            font-size: 18px;
            color: white;
            margin: 0 auto;
         }
      }
      .right {
         padding-left: 15px;
         .title {
            font-size: 17px;
            font-weight: 500;
            color: #646c9a;
         }
         .description {
         }
      }
      .version-highlight {
         background-color: rgb(186, 186, 247);
         padding: 3px 5px;
         font-size: 11px;
         color: white;
         border-radius: 3px;
      }
   }
   .install-item-small {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .status-icon {
         width: 36px;
         height: 24px;
         // background-color: rgba(46, 160, 44, 0.5);
         border-radius: 4px;
         text-align: center;
         display: flex;
         align-items: center;
         i {
            font-size: 16;
            // color:white;
            margin: 0 0 0 auto;
            opacity: 0.7;
         }
      }
      .right {
         padding-left: 15px;
         .title {
            font-size: 13px;
            // font-weight: 600;
            color: black;
         }
         .description {
            font-size: 11px;
            height: 16px;
         }
      }
      .service-pill {
         font-size: 9px;
         font-weight: 400;
         // text-transform: uppercase;
         // display: inline-block
         background-color: rgb(169, 169, 169);
         color: white;
         padding: 3px 4px 4px 4px;
         border-radius: 2px;
         margin-left: 0px;
         position: relative;
         top: -1px;
         margin-left: 5px;
      }
   }
   .install-deploy,
   .install-teams-app,
   .force-update {
      padding: 0.35rem 0.65rem;
      font-weight: 500;
      font-size: 12px;
   }
   .top-left {
      .install-item-small {
         margin-bottom: 20px;
      }
   }
   .wp-item {
      margin-bottom: 30px;
      .step-info {
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         .step-num-icon {
            display: flex;
            .number {
               width: 24px;
               height: 24px;
               border-radius: 4px 0 0 4px;
               background-color: rgba(46, 160, 44, 0.5);
               display: flex;
               align-items: center;
               justify-content: center;
               flex-direction: column; /* <--- I added this */
               color: white;
               font-weight: 500;
               font-size: 14px;
            }
            .icon {
               width: 24px;
               height: 24px;
               border-radius: 0 4px 4px 0;
               display: flex;
               align-items: center;
               border: rgba(46, 160, 44, 0.5) solid 1px;
               i {
                  margin: 0 auto;
                  color: rgba(46, 160, 44, 0.5);
                  font-size: 14px;
               }
            }
         }
         .step-name {
            padding-left: 15px;
            font-size: 17px;
            font-weight: 500;
            color: #646c9a;
         }
      }
      .step-content {
         font-size: 12px;
         padding-left: 63px;
         .instructions {
            ol {
               margin: 10px 0;
               padding-left: 10px;
               li {
                  padding-left: 5px;
                  padding-bottom: 5px;
                  ul {
                     margin-top: 5px;
                     padding-left: 16px;
                     li {
                        padding-left: 0px;
                        font-weight: 400;
                     }
                  }
               }
            }
         }
         .pending-requests {
            margin: 10px 0;
            padding-left: 17px;
            li {
               padding-left: 0px;
               // padding-bottom: 5px;
            }
         }
         .text {
            font-size: 13px;
            color: black;
         }
         .version-number {
            margin-bottom: 10px;
            font-size: 11px;
            i {
               margin: 0 5px;
               opacity: 0.5;
            }
         }
      }

      &.not-done {
         .step-info {
            .step-num-icon {
               .number {
                  background-color: #e06363;
               }
               .icon {
                  border: #e06363 solid 1px;
                  i {
                     color: #e06363;
                  }
               }
            }
         }
      }
      &.undetermined {
         .step-info {
            .step-num-icon {
               .number {
                  background-color: #8e9ae0;
               }
               .icon {
                  border: #8e9ae0 solid 1px;
                  i {
                     color: #8e9ae0;
                     font-size: 12px;
                  }
               }
            }
         }
      }
   }
   .teams-app-status {
      .install-item {
         .status-icon {
            zoom: 0.6;
            &.no {
               background-color: #e06363;
            }
         }
         .title {
            font-weight: 500;
            font-size: 13px;
            color: black;
         }
      }
      .teams-app-install-instructions {
         .teams-app-install-choice {
            .portlet-alert {
               margin-top: 15px;
               margin-bottom: 10px;
            }
            .install-choices {
               margin-top: 10px;

               .click-to-install,
               .manual-install {
                  padding-top: 4px;
                  padding-bottom: 4px;
                  font-weight: 400;
                  // text-align: center;
                  h4 {
                     font-size: 16px;
                     color: black;
                  }
                  .text-description {
                     min-height: 57px;
                  }
                  .install-teams-app {
                     margin-top: 15px;
                  }
               }
               .click-to-install {
                  border-right: 1px solid rgb(216, 216, 216);
                  padding-right: 20px;
               }
               .manual-install {
                  padding-left: 20px;
               }
            }
         }
      }
   }

   .lifecycle-install-instructions {
      .permission-options {
         margin-top: 10px;
         .permissions-area {
            display: flex;
            // &:first-child {
            //    border-bottom: 1px solid #ebebeb;
            //    margin-bottom: 20px;
            //    padding-bottom: 20px;
            // }
            .permissions-icon {
               flex-grow: 0;
               min-width: 40px;
               i {
                  font-size: 24px;
               }
            }
            .ms-login {
               // margin: auto;
               // text-align: center;
               .custom-button {
                  background-image: url("~/public/media/ms-signin-button.png");
                  //  width: 245px;
                  height: 34px;
                  margin: 15px 0 0px 0px;
                  border: 1px solid rgb(172, 172, 172);
                  cursor: pointer;
                  display: inline-block;
                  color: black;
                  text-align: center;
                  font-family: "Segoe UI", "wf_segoe-ui_normal", "Arial", sans-serif;
                  font-size: 13px;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: left top;
                  line-height: 32px;
                  font-weight: 500;
                  padding-left: 40px;
                  padding-right: 15px;
                  text-align: right;
                  &:hover {
                     border-color: blueviolet;
                  }
               }
            }
            .permissions-content {
               h4 {
                  font-size: 16px;
                  color: black;
               }
               h5 {
                  font-size: 14px;
                  color: rgb(107, 107, 107);
                  margin-bottom: 10px;
                  &.required {
                     color: rgb(186, 99, 99);
                  }
               }

               .completed-task {
                  font-weight: 500;
                  padding: 5px 15px 5px 15px;
                  border: 1px solid #646c9a51;
                  border-radius: 4px;
                  margin-top: 10px;
                  line-height: 20px;
                  display: inline-block;
                  i {
                     margin-right: 8px;
                     font-size: 20px;
                     line-height: 10px;
                     position: relative;
                     top: 4px;
                  }
               }
               .text-description {
                  // min-height: 57px;
                  font-weight: 400;
                  margin-bottom: 20px;
               }
               .content-wrap {
                  // display: flex;
                  .text-and-button {
                  }
                  .permissions-current-delegates {
                     // padding-left: 15px;
                     min-width: 50%;
                     .delegates-title {
                        font-weight: 500;
                        font-size: 13px;
                        margin-bottom: 5px;
                     }
                     .principal-item {
                        display: flex;
                        margin-right: 0;
                     }
                     .no-delegates {
                        margin-bottom: 10px;
                        font-size: 11px;
                     }
                  }
               }

               .install-item {
                  display: inline-flex;
                  align-items: center;
                  // margin-bottom: 10px;
                  font-weight: 500;
                  padding: 5px 15px 5px 15px;
                  border: 1px solid #646c9a51;
                  border-radius: 4px;
                  // margin-top: 10px;
                  line-height: 20px;
                  // display: inline-block;
                  .status-icon {
                     width: 36px;
                     height: 36px;
                     min-width: 36px;
                     max-width: 36px;
                     background-color: #646c9a51;
                     border-radius: 4px;
                     text-align: center;
                     display: flex;
                     align-items: center;
                     zoom: 0.6;
                     i {
                        font-size: 18px;
                        color: white;
                        margin: 0 auto;
                     }
                  }
                  .right {
                     padding-left: 15px;
                     .title {
                        font-size: 13px;
                        font-weight: 500;
                        // color: black;
                     }
                     .description {
                        font-size: 11px;
                        opacity: 0.75;
                        line-height: 11px;
                     }
                  }
                  .version-highlight {
                     background-color: rgb(186, 186, 247);
                     padding: 3px 5px;
                     font-size: 11px;
                     color: white;
                     border-radius: 3px;
                  }
                  &.needs-update {
                     border: 0px solid transparent;
                     background-color: #646c9a14;
                     .status-icon {
                        display: none;
                     }
                     .right {
                        padding-left: 0;
                        .description {
                           line-height: 18px;
                        }
                     }
                  }
               }
               .install-deploy,
               .install-teams-app,
               .force-update {
                  padding: 0.35rem 0.65rem;
                  font-weight: 500;
                  font-size: 12px;
               }
            }
         }
      }
      .lifecycle-toggle {
         margin-top: 0px;
         .content-wrap {
            width: 100%;
            flex-grow: 1;
            .form-orch-toggle {
               margin-top: 0px;
               margin-bottom: 0;
               flex-grow: 1;
               .form-toggle-control {
                  margin-bottom: 0;
                  span {
                     font-weight: 500;
                  }
               }
               .form-toggle {
                  zoom: 0.88;
               }
            }
         }
         label.form-toggle-control {
            padding-right: 0;
         }
      }
   }

   .teams-app-skip-install {
      margin-top: 15px;
      .lifecycle-toggle {
         .permissions-area {
            width: 100%;
            .permissions-content {
               flex-grow: 1;
               .form-orch-toggle {
                  // margin-top: 20px;
                  margin-bottom: 0;
                  flex-grow: 1;
                  .form-toggle-control {
                     span {
                        font-weight: 500;
                     }
                  }
                  .form-toggle {
                     zoom: 0.88;
                  }
               }
            }
         }
         label.form-toggle-control {
            padding-right: 0;
         }
      }
   }

   .settings-edu-features-enabled {
      .form-orch-toggle .form-toggle {
         zoom: 0.88;
      }
   }

   .feature-enablement {
      display: flex;
      flex-direction: column;
      // .feature-toggles {
      //    display: flex;
      //    justify-content: flex-start;
      //    margin-top: 0.25rem;
      //    gap: 2.5rem;
      //    .workspace-toggle {
      //       flex-basis: 50%;
      //    }
      //    .guest-toggle {
      //       flex-basis: 50%;
      //    }
      // }
   }
}
