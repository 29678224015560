.tenant-page {
   display: flex;
   flex-direction: column;

   .header {
      display: flex;
      justify-content: space-between;
   }

   & * {
      font-family: var(--mantine-font-family) !important;
   }
}
