.root {
   border-color: var(--border) !important;
   border-radius: 8px !important;
   padding: 10px 14px;

   .toolbar {
      top: 0;
      padding: 12px 16px;
      border-bottom-color: var(--border);

      .control {
         border-color: var(--border);
      }
   }

   .content {
      & > div:first-child {
         padding: 20px 8px;
      }
   }
}
