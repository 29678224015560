@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-LightIt.woff2') format('woff2'),
        url('ProximaNova-LightIt.woff') format('woff'),
        url('ProximaNova-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-RegularIt.woff2') format('woff2'),
        url('ProximaNova-RegularIt.woff') format('woff'),
        url('ProximaNova-RegularIt.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-SemiboldIt.woff2') format('woff2'),
        url('ProximaNova-SemiboldIt.woff') format('woff'),
        url('ProximaNova-SemiboldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-BoldIt.woff2') format('woff2'),
        url('ProximaNova-BoldIt.woff') format('woff'),
        url('ProximaNova-BoldIt.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}


@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Light.woff2') format('woff2'),
        url('ProximaNova-Light.woff') format('woff'),
        url('ProximaNova-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff'),
        url('ProximaNova-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Semibold.woff2') format('woff2'),
        url('ProximaNova-Semibold.woff') format('woff'),
        url('ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff'),
        url('ProximaNova-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Extrabld.woff2') format('woff2'),
        url('ProximaNova-Extrabld.woff') format('woff'),
        url('ProximaNova-Extrabld.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Black.woff2') format('woff2'),
        url('ProximaNova-Black.woff') format('woff'),
        url('ProximaNova-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
