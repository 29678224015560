.column-popout-menu-area {
   .popout-button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #171721;
      font-size: 18px;
      cursor: pointer;
      background: #f5f5fa;
      border-radius: 10px;
      width: 42px;
      height: 32px;
      vertical-align: middle;
   }
}
.column-popout-confirmation-dialog {
   display: flex;
   flex-direction: column;
   gap: 16.25px;
   .buttons {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
   }
}

.column-popout-menu-wrapper {
   .ms-Callout-main {
      padding: 0;
   }
   .list-item-container {
      display: grid;
      grid-auto-columns: auto;
      gap: 0.25rem;
      align-items: center;
      padding: 10px;
      .list-item {
         display: grid;
         place-content: center;
         grid-template-columns: min-content 4fr;
         gap: 1rem;
         font-size: 13px;
         font-weight: 500;
         font-family: TT Norms;
         cursor: pointer;
         user-select: none;
         padding: 3px 8px;
         transition: all 0.3s;
         &:hover {
            background-color: #f5f5fa;
            border-radius: 4px;
         }
         .text {
            display: grid;
            align-content: center;
            a {
               color: inherit;
            }
         }
         .icon {
            display: table-cell;
            place-items: center;
            text-align: left;
            font-size: 16px;
            width: 20px;
         }
         &.disabled {
            opacity: 0.2;
            cursor: initial;
            pointer-events: none;
         }
      }
   }
}

.guest-overview-action-popout-modal {
   .modal-lg {
      @media (min-width: 1024px) {
         max-width: 400px;
      }
   }

   .btn {
      &.btn-primary {
         background-color: #00626f;
      }
   }

   .exclude-group {
      margin-top: 27px;
   }
   .form-orch-toggle {
      label {
         padding-right: 0 !important;
      }
   }
}
