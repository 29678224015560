@import "app/assets/common/bootstrap-media-mixins";
.team-chrome {
   padding: 10px 10px 0px 10px;
   // background-color: #f2f3f8;
   background-color: #f0f0f0;
   @include media-breakpoint-down(sm) {
      padding: 0;
   }
   .chrome-navigation {
      box-shadow: inset 0px -2px 2px rgba(38, 50, 58, 0.02);
      display: flex;
      padding: 0 0px;
      @include media-breakpoint-down(sm) {
         background-color: white;
         height: 50px;
         align-items: center;
      }
      // align-items: center;
      .orchestry-logo {
         @include media-breakpoint-up(sm) {
            padding: 6px 15px 0px 15px;
         }
         // display: none;
         @include media-breakpoint-down(sm) {
            padding: 0px 5px 0px 15px;
         }
         img {
            height: 28px;
            width: 28px;
            opacity: 0.2;
            transition: all 0.3s;
            @include media-breakpoint-down(sm) {
               opacity: 0.5;
            }
         }
         &:hover {
            cursor: pointer;
            img {
               opacity: 0.5;
            }
         }
      }
      .mobile-icon {
         display: none;
         @include media-breakpoint-down(sm) {
            display: block;
            opacity: 0.5;
            // margin-top: 10px;
            i {
               font-size: 18px;
            }
         }
      }
      .tabs {
         a.tab-button {
            padding: 10px 24px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: inline-block;
            color: #718690;
            position: relative;
            transition: all 0.3s;
            &:after {
               position: absolute;
               content: "";
               background-color: #dde2e7;
               width: 1px;
               height: calc(100% - 14px);
               right: -1px;
               top: 7px;
               z-index: 1;
            }
            &:before {
               opacity: 0;
               transition: all 0.3s;
               content: "";
               position: absolute;
               top: 0;
               left: 0;
               height: 2px;
               right: 0;
               background-color: #0079892c;
               border-radius: 2px 2px 0 0;
            }
            &:hover {
               background-color: rgba(255, 255, 255, 0.5);
               box-shadow: inset 0px -2px 2px rgba(38, 50, 58, 0.02);
               color: black;
               &:before {
                  opacity: 1;
               }
            }
            &.active {
               // border-top: 2px solid #007989;
               border-right: 0px solid transparent;
               background-color: white;
               box-shadow: none;
               border-radius: 2px 2px 0 0;
               color: #26323a;
               z-index: 2;
               cursor: default;
               &:before {
                  opacity: 1;
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 2px;
                  right: 0;
                  background-color: #007989;
                  border-radius: 2px 2px 0 0;
               }
               &:after {
                  display: none;
               }
            }
            &:last-child {
               &:after {
                  display: none;
               }
            }
         }
         @include media-breakpoint-down(sm) {
            background-color: white;
            border: 1px solid rgb(186, 186, 186);
            box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.268);
            display: block;
            position: absolute;
            z-index: 10000;
            top: 50px;
            left: -250px;
            transition: all 0.3s;
            width: 240px;
            &.mobile-open {
               left: 10px;

               .tab-button {
                  display: block;
                  border-bottom: 1px solid rgb(211, 211, 211);
                  &:last-child {
                     border-bottom: 0px solid transparent;
                  }
                  &::after {
                     display: none;
                  }
                  &.active {
                     &:before {
                        left: 5px;
                        top: 5px;
                        bottom: 5px;
                        height: auto;
                        width: 10px;
                        border-radius: 3px;
                     }
                  }
               }
            }
         }
      }
      .center-spacer {
         flex: 1;
      }
      .chrome-right-side {
         transition: all 0.3s;
         display: flex;
         height: 32px;
         @include media-breakpoint-down(sm) {
            padding-top: 0px;
            height: 32px;
            padding-right: 11px;
            // .notifications {
            //    i {
            //       position: relative;
            //       top: -2px;
            //    }
            // }

            // .chrome-request-notifications {
            //    .admin-notification {
            //       height: 27px;
            //       .kt-header__topbar-icon {
            //          height: 25px;
            //          i {
            //             position: relative;
            //             top: -2px;
            //          }
            //       }
            //    }
            // }
            .chrome-request-workspace {
               display: none;
            }
         }
      }
   }
   .chrome-content {
      background-color: white;

      .chrome-content-wrapper {
         // padding: 5px 0;

         .request-my {
            padding: 15px 25px;
            .icon-title {
               font-size: 16px;
               color: #26323a;
               font-weight: bold;
            }
            .request-container {
               margin-bottom: 30px;
               &:last-child {
                  margin-bottom: 0;
               }
            }
         }

         .request-wizard {
         }
      }
   }

   &.orchestry-mangement-open {
      // .orchestry-tab {

      //    // border-top: 2px solid #007989;
      //    border-right: 0px solid transparent;
      //    background-color: white !important;
      //    box-shadow: none;
      //    border-radius: 2px 2px 0 0;
      //    color: #26323A !important;
      //    z-index: 2;
      //    cursor: default;
      //    &:before {
      //       opacity: 1 !important;
      //       content:"";
      //       position: absolute;
      //       top:0;
      //       left:0;
      //       height: 2px;
      //       right:0;
      //       background-color: #007989 !important;
      //       border-radius: 2px 2px 0 0;
      //    }
      //    &:after {
      //       display: none;
      //    }

      // }
      .chrome-navigation {
         .chrome-right-side {
            padding-right: 11px;
         }
      }
      .chrome-content {
         background-color: transparent;

         > .chrome-content-wrapper {
            > div {
               > .ScrollbarsCustom {
                  > .ScrollbarsCustom-TrackY {
                     right: 2px !important;
                     height: calc(100% - 70px) !important;
                     top: 60px !important;
                  }
               }
            }
         }
      }
   }
}
