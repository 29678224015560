.root {
   display: flex;
   align-items: center;
   gap: 12px;
   button {
      font-size: var(--mantine-font-size-sm);
      border: 0;
   }
   button:first-of-type,
   button:last-of-type {
      border: 1px solid var(--border);
   }
   button:not(:first-of-type):not(:last-of-type) {
      &:hover {
         background-color: var(--mantine-color-primary-0);
         color: var(--mantine-color-primary-6);
      }
      &[data-active="true"] {
         background-color: var(--mantine-color-primary-0);
         color: var(--mantine-color-primary-6);
         &:hover {
            background-color: var(--mantine-color-primary-1);
            color: var(--mantine-color-primary-6);
         }
      }
   }
}

.control {
   color: var(--mantine-color-text-tertiary-0);
   border: none;
}
