.orchestry-filter-item-groups {
   & * {
      font-family: var(--mantine-font-family) !important;
   }

   display: flex;
   flex-direction: column;
   gap: 8px;

   .filter-items {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      align-items: center;
   }
}
