.principals-list {
   .list {
      display: flex;
      .principal-item-pic {
         margin-right: -10px;
         .pic {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: white;
            border: 3px solid white;
            .profile-picture {
               width: 27px;
               height: 27px;
            }
            &.plus-more {
               width: auto;
               padding: 0 6px;
               border-radius: 16px;
               background-color: rgb(245, 245, 245);
               .profile-picture {
                  width: auto;
                  background-color: transparent;
                  font-weight: 500;
               }
            }
         }
      }
   }
}
