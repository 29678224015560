.orchestry-tab-count {
   display: flex;
   padding: 2px 8px;
   justify-content: center;
   align-items: center;
   background: var(--mantine-color-gray-2);
   border-radius: 16px;
   color: var(--mantine-color-text-secondary);
   font-weight: 400;
   transition: color 200ms ease-in;

   &[data-variant="default"] {
      width: 22px;
      height: 22px;
      font-size: var(--mantine-font-size-xs);
      line-height: var(--mantine-line-height-xs);
   }

   &[data-variant="pills"] {
      width: auto;
      min-width: 22px;
      font-size: 14px;
      line-height: 18px;

      &[data-active="true"] {
         background: var(--mantine-color-primary-8);
         color: var(--white);
      }
   }

   &[data-variant="error-0"] {
      background: var(--mantine-color-error-7) !important;
      height: 20px;
      width: 20px;
      color: var(--mantine-color-error-0) !important;
      font-size: 12px;
      line-height: 18px;
   }
}
