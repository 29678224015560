.orchestry-thumbs {
   display: flex;
   gap: 8px;
   padding: 0 8px;

   .selected {
      &.orchicon-thumbs-up,
      &.orchicon-thumbs-down {
         display: none;
      }

      &.orch-thumbs-up-colored,
      &.orch-thumbs-down-colored {
         display: block;
      }
   }

   img {
      cursor: pointer;
      display: none;
      padding: 3px 0;
      height: 24px;
      width: 18px;
   }

   i {
      cursor: pointer;
      font-size: 18px;
      color: var(--mantine-color-gray-5);
   }
}
