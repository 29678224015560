.recommendation-share-suggestion-modal {
   font-family: var(--mantine-font-family);
   .share-suggestion-description {
      margin-top: 10px;
   }
   .share-suggestion-textarea {
      margin-top: 25px;
      color: var(--mantine-color-text-tertiary-0);
      font-weight: var(--light-font-weight);
      font-size: 14px;
      textarea {
         border-color: var(--mantine-color-gray-3);
      }
   }
   .share-suggestion-buttons {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      > * {
         flex-grow: 1;
      }
      margin-top: 30px;
   }
   .thanks {
      padding: 15px;
      .orchestry-featured-icon {
         margin-bottom: 16px;
      }
   }
}
