@import "_metronic/_assets/sass/breakpoints.scss";

html.static-teams-app {
   .kt-grid--root {
      background-color: transparent;
      // background-color: #F3F2F1;
      #kt_aside_brand {
         display: none;
      }
      #kt_aside {
         @include kt-desktop {
            top: 125px;
            bottom: 15px;
            left: 10px;
         }
         border-radius: 4px;
         #kt_aside_menu {
            border-radius: 4px;
            margin-top: 0;
         }

         @include kt-tablet-and-mobile {
            top: 50px;
         }
      }
      
      @include kt-tablet-and-mobile {
         .kt_header_mobile{
            position: absolute !important;
         }
         .kt-aside-close {
            top: 53px;
            margin-left: -4px;
         }
         .kt-aside-overlay {
            top: 50px;
         }
      }
      #kt_subheader {
         top: 50px;
         left: 10px;
         right: 10px;
         box-shadow: 0px 2px 3px 0px rgba(82, 63, 105, 0.1);
         border-radius: 4px;
         // left:95px;
         // right:35px;
         // border-radius: 4px;
         > .kt-container {
            padding-right: 11px;
            @include kt-tablet-and-mobile {
               padding-left: 60px;
            }
         }
      }
      #kt_wrapper {
         padding-top: 50px;
         padding-left: 63px;
         @include kt-tablet-and-mobile {
            padding: 0;
         }
      }
      #kt_content > .kt-container {
         padding-right: 10px;
         @include kt-tablet-and-mobile {
            padding-left: 0;
         }
      }
   }
   #kt_quick_panel {
      top: 50px;
      bottom: -50px;
      border-radius: 4px;
   }
   .kt-quick-panel-overlay {
      top: 50px;
      border-radius: 4px;
   }
   .ScrollbarsCustom-TrackY {
      z-index: 99;
   }
   .modal-backdrop {
      top: 50px;
      border-radius: 4px;
   }
   .modal {
      top: 50px;
   }

   @include kt-tablet-and-mobile {
      .kt-header-mobile {
         right: auto;
         top: 0px;
         // background-color: yellow;
         background-color: transparent;
         box-shadow: none;
         border: none;
         position: absolute !important;
         .kt-header-mobile__logo {
            display: none;
         }
         .kt-header-mobile__toolbar {
            > button {
               margin: 0;
               display: none;
               &:first-child {
                  display: block;
               }
            }
         }
      }
   }
}
