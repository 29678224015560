.recommendation-card-history {
   .timeline {
      /* compensate for the padding-left below**/
      margin-left: -17px;

      .timeline-items {
         /* Mantine absolutely positions the avatars, so we need to add some extra padding so it doesn't get cropped in overflow scroll*/
         padding-left: 17px;
         padding-top: 2px;

         /* Added so we have bottom padding for line after last element because our View Less button is positioned absolutely*/
         padding-bottom: 24px;

         .timeline-item {
            height: 18px;
            // style the maintine lines with margin so that they look dashed
            &::before {
               height: 10px;
               border: 1px solid var(--border);
               border-radius: 100px;
               margin-top: 34px !important;
            }

            &.timeline-item-expandable {
               // ensures the last element has a line after it because our View Less button is positioned absolutely
               --_line-display: block !important;
            }

            display: flex;
            align-items: center;

            .mantine-Timeline-itemBody {
               margin-top: 10px;
            }

            .mantine-Timeline-itemBullet {
               height: 28px;
            }

            .mantine-Timeline-itemContent {
               align-items: center;
               display: flex;

               .title {
                  align-items: center;
                  margin-left: 8px;
               }
            }
         }
      }

      .expanded-timeline-items {
         max-height: 396px;
         overflow: hidden;
      }

      .infinite-scroll-items {
         /* These need to be set for infinite scroll to work */
         overflow: auto;
      }

      .timeline-items::-webkit-scrollbar {
         background-color: transparent;
         width: 8px;
      }

      .timeline-items::-webkit-scrollbar-thumb {
         background: var(--mantine-color-gray-3);
         border-radius: 10px;
      }
   }
}
