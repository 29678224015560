.settings-installation-feature-enablement {
   .feature-portlet-body {
      .feature-toggles {
         > div {
            margin-top: 25px;
            &:first-child {
               margin-top: 0;
            }
            .portlet-alert {
               .alert {
                  margin-bottom: 10px;
               }
            }
         }
         .viva-instructions {
            a {
               color: #646c9a;
               text-decoration: underline;
               font-weight: 600;
            }
            ul {
               list-style: none;
               padding: 1.5rem;
               margin-bottom: 0;
               .form-group {
                  margin-bottom: 0;
               }
            }
            li:not(:last-child) {
               margin-bottom: 1.5rem;
            }
         }
      }
      .feature-title {
         margin-bottom: 10px;
         font-size: 1.1em;
      }
   }
}
