@import "app/assets/common/bootstrap-media-mixins";

.lifecycle-details-tab-overview {
   .summary-info {
      h3 {
         // margin-bottom: 20px;
      }
      .info-boxes {
         display: flex;
         flex-wrap: wrap;
         width: calc(100% + 24px);
         position: relative;
         left: -24px;
         // top:-24px;
         margin-top: -24px;
         .box {
            border: 1px solid #e1e7ee;
            padding: 16px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            width: calc(33.33% - 24px);
            margin-left: 24px;
            margin-top: 24px;
            .icon-box {
               width: 48px;
               height: 48px;
               border-radius: 6px;
               background-color: rgba(215, 233, 236, 0.5);
               line-height: 54px;
               text-align: center;
               margin-right: 16px;
               i {
                  color: #007989;
                  font-size: 20px;
               }
            }
            .right {
               flex: 1;
               .count {
                  font-size: 24px;
                  color: #26323a;
                  font-weight: 500;
               }
               .title {
                  color: #718690;
                  font-size: 14px;
                  font-weight: 500;
               }
            }
         }
      }
   }
}
