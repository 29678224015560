.orchestry-permission-info-editor {
   .pills {
      display: inline-flex;
      margin-bottom: 20px;
      border-radius: 6px;
      border: 1px solid rgb(216, 216, 216);
      .nav-item {
         width: 100px;
         text-align: center;
         background-color: white;
         padding: 8px;
         font-weight: 600;

         &:first-child {
            border-radius: 5px 0 0 5px;
         }
         &:last-child {
            border-radius: 0 5px 5px 0;
         }
         &:hover {
            background-color: rgb(233, 233, 233);
            cursor: pointer;
         }
         &.selected {
            background-color: #5867dd;
            color: white;
         }
      }
      &.single-item {
         .nav-item {
            border-radius: 5px;
         }
      }
   }
   .value-titles {
      font-weight: 600;
      border-bottom: 1px solid rgb(211, 211, 211);
      padding-bottom: 15px;
   }
   .value-edit {
      border-bottom: 1px solid rgb(235, 235, 235);
      padding: 10px 0;
      > div {
         align-items: center;
      }
      .no-value {
         color: rgb(177, 177, 177);
      }
      &:hover {
         background-color: rgb(239, 239, 255);
         cursor: pointer;
      }
   }
}
