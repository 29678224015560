@import "app/assets/common/bootstrap-media-mixins";
@import "_metronic/_assets/sass/breakpoints";

.principal-guest-picker-modal-backdrop {
   // backdrop-filter: blur(10px);
   // background-color: rgba(238, 241, 245, 0.75) !important;
   // opacity: 1 !important;
   // animation-iteration-count: 1;
   // animation-timing-function: ease-in;
   // animation-duration: 0.3s;
   // &.show {
   //     animation-name: fadein;
   // }
}

.modal-open {
   .guest-picker-confirmation-modal-bg {
      z-index: 1050;
   }
}

.guest-picker-confirmation-modal {
   z-index: 1051;
   .modal-header {
      span {
         font-weight: bold;
         font-size: 16px;
         line-height: 19px;
         color: #26323a;
      }
   }
   .modal-dialog {
      margin: 25vh auto;
   }
   .modal-footer {
      .new-guest-close-footer {
         display: flex;
         justify-content: space-between;
      }
   }
}
.principal-guest-picker-modal {
   font-family: "TT Norms";
   font-variant-ligatures: none;
   > .modal-dialog {
      margin: 6rem auto;
      max-width: 400px;
   }
   .popover.people-picker {
      background-color: white;
      border: 1px solid #28b976;
      min-width: 300px;
      margin-top: -14px;
      margin-left: 10px;
      .arrow {
         &:before {
            position: absolute;
            top: -2px;
            right: 11px;
            content: "";
            width: 10px;
            height: 10px;
            background-color: white;
            border-top: 1px solid #28b976;
            border-left: 1px solid #28b976;
            transform: rotate(-45deg);
         }
      }
   }
   .people-content {
      @include media-breakpoint-down(sm) {
         background-color: white;
         border: 1px solid #28b976;
         border-radius: 5px;
      }
      .people-header {
         position: relative;
         padding: 24px 24px 12px 24px;
         span {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #26323a;
         }
         .close {
            right: 12px;
            top: 10px;
            position: absolute;
            cursor: pointer;
            i {
               font-size: 14px;
            }
         }
      }
      .people-search {
         position: relative;
         padding: 10px;
         border-bottom: 1px solid #ececec;
         padding: 0px 24px 20px 24px;
         .search-box {
            display: flex;
            align-items: center;
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            input {
               width: 100%;
               border: 0 solid transparent;
               // border:0px solid transparent;
               outline: none;
               background: #ffffff;
               padding: 5px 12px 5px 12px;
               flex: 1;
               border-radius: 4px;
               &::placeholder {
                  /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: #9da9ae;
                  font-weight: 500;
                  font-size: 12px;
                  opacity: 1; /* Firefox */
               }

               &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #9da9ae;
                  font-size: 12px;
                  font-weight: 500;
               }

               &::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: #9da9ae;
                  font-size: 12px;
                  font-weight: 500;
               }
               &:focus {
                  //border:0px solid transparent;
                  outline: none;
               }
            }
            .icon {
               // position: absolute;
               // top:50%;
               // margin-top: -8px;
               // right:10px;
               padding: 1px 10px 0 10px;
               i {
                  font-size: 15px;
                  color: #9da9ae;
                  line-height: 17px;
               }
               svg {
                  color: #b5b5b5;
                  width: 17px;
                  height: 17px;
               }
            }
         }
      }
      .people-list {
         position: relative;
         padding: 5px 8px 5px 8px;
         // padding-top: 5px;
         &:before {
            // display: none;
         }
         ul {
            margin-bottom: 0;
            .suggested-members {
               font-size: 12px;
               font-weight: 500;
               line-height: 17px;
               letter-spacing: -0.009091px;
               color: #718690;
               padding: 0 16px;
               margin-bottom: 10px;
            }
            .ScrollbarsCustom-TrackY {
               right: 0 !important;
               // width:3px !important;
               top: 0;
               height: 100%;
            }
            li {
               padding: 12px 16px;
               // height: 34px;
               line-height: 20px;
               word-wrap: none;
               position: relative;
               border-radius: 5px;
               display: flex;
               align-items: center;
               transition: all 0.3s;
               cursor: pointer;
               &:hover,
               &.hover-selected {
                  background: rgba(61, 160, 162, 0.1);
                  //cursor: default;
               }
               .pic {
                  width: 32px;
                  height: 32px;

                  // background-color: rgb(211, 211, 211);
                  display: inline-block;
                  vertical-align: top;
                  // position: relative;
                  margin-top: 0px;
                  margin-left: 0px;
                  // border:1px solid #D5D1D2;
                  margin-right: 15px;
                  > div {
                     border-radius: 50%;
                     font-size: 11px;
                     font-weight: 600;
                  }
                  > img {
                     border-radius: 50%;
                     width: 32px;
                     height: 32px;
                     vertical-align: top;
                  }
                  .profile-picture-placeholder {
                     width: 32px;
                     height: 32px;
                     line-height: 33px;
                     font-size: 13px;
                  }
               }
               .info {
                  flex: 1;

                  .name {
                     display: block;
                     font-size: 14px;
                     font-weight: 500;
                     color: #26323a;
                  }
                  .guest-note {
                     margin-left: 3px;
                     font-weight: 500;
                     opacity: 0.6;
                  }
                  .job-title {
                     margin-left: 0px;
                     display: block;
                     font-weight: 400;
                     font-size: 12px;
                     line-height: 16px;
                     color: #718690;
                  }
               }
               &.no-results {
                  padding-top: 5px;
                  text-align: center;
               }
               &.loader {
                  padding-top: 14px;
                  text-align: center;
               }
               .principal-type {
                  position: absolute;
                  left: 38px;
                  top: 34px;
                  width: 15px;
                  height: 15px;
                  background-color: #0abb87;
                  font-size: 10px;
                  font-weight: 600;
                  color: white;
                  line-height: 14px;
                  margin: 0 auto;
                  text-align: center;
                  border-radius: 50%;
                  border: 1px solid white;
                  box-sizing: content-box;
                  z-index: 93;
                  &.type-group {
                     background-color: rgb(121, 121, 231);
                  }
                  &.type-securitygroup {
                     background-color: rgb(237, 105, 105);
                     // font-size:8px;
                  }
                  &.type-distributiongroup {
                     background-color: rgb(230, 182, 110);
                     // font-size:8px;
                  }
               }
               .action {
                  width: 24px;
                  height: 24px;
                  border: 1px solid #e1e7ee;
                  box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                  border-radius: 50%;
                  text-align: center;
                  line-height: 23px;
                  .add {
                     font-size: 9px;
                  }
                  .check {
                     font-size: 10px;
                     display: none;
                     color: white;
                  }
                  .locked {
                     font-size: 9px;
                     display: none;
                     color: white;
                     position: relative;
                     top: -1px;
                  }
                  .blocked-domain {
                     display: none;
                  }
               }
               &.selected {
                  .action {
                     border-color: #007989;
                     box-shadow: none;
                     background-color: #007989;
                     line-height: 22px;
                     .add {
                        display: none;
                     }
                     .check {
                        display: inline;
                     }
                     .blocked-domain {
                        display: none;
                     }
                  }
               }
               &.locked {
                  .action {
                     border-color: #007989;
                     box-shadow: none;
                     background-color: #007989;
                     line-height: 22px;
                     .add {
                        display: none;
                     }
                     .check {
                        display: none;
                     }
                     .locked {
                        display: inline;
                     }
                     .blocked-domain {
                        display: none;
                     }
                  }
               }
               &.blocked-domain-true {
                  cursor: default;
                  .action {
                     width: auto;
                     border-radius: 3px;
                     background-color: rgb(255, 203, 203);
                     padding: 0px 7px;
                     border: none;
                     // height: 25px;
                     height: 23px;
                     .add {
                        display: none;
                     }
                     .check {
                        display: none;
                     }
                     .locked {
                        display: none;
                     }
                     .blocked-domain {
                        display: inline-block;
                        font-weight: 500;
                        color: #9b1f1f;
                     }
                  }
               }
            }
         }
      }
      .people-description {
         position: relative;
         padding: 0px 24px 12px 24px;
         color: #8b9ca5;
         font-weight: 500;
      }
      .people-form {
         padding: 0px 24px 12px 24px;
         transition: all 0.3s;
         .form-group {
            margin-bottom: 1rem;
            .react-tel-input {
               .form-control {
                  width: 100%;
               }
            }
         }
         .email-box {
            position: relative;
            input {
               padding-right: 40px;
            }
            .status {
               position: absolute;
               right: 11px;
               top: 11px;
               i {
                  font-size: 16px;
                  &.fa-ban {
                     color: rgb(254, 92, 92);
                  }
                  &.fa-check {
                     color: green;
                  }
               }
            }
         }
         .alert-content {
            .search-link {
               color: #56689a;
               cursor: pointer;
            }
         }
         .previous-justification {
            font-size: 11px;
            font-weight: 500;
            cursor: pointer;
            margin-top: 5px;
         }
         .react-tel-input {
            font-family: inherit;
            .form-control {
               font-family: inherit;
               font-size: 13px;
            }
         }
         .company-search {
            position: relative;
            flex: 1;
            margin-bottom: 10px;
            .search-box {
               .search-box-border {
                  display: flex;
                  align-items: center;
                  border: 1px solid #e1e7ee;
                  box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                  transition: all 0.3s ease-in-out;
                  border-radius: 5px;
                  padding-right: 8px;
                  transition: all 0.3s;
                  @include kt-mobile {
                     // display:block;
                     width: calc(100%);
                     background-color: white;
                  }
                  &.disabled {
                     background-color: #f7f8fa;
                  }
                  .search-box-input {
                     width: calc(100% - 10px);
                     outline: none;
                     background: transparent;
                     font-family: TT Norms;
                     font-style: normal;
                     font-weight: 500;
                     font-size: 1rem;
                     line-height: 16px;
                     color: #26323a;
                     padding: 0px 6px;
                     border: 0px solid transparent;
                     border-radius: 6px;
                     transition: all 0.3s;
                     display: flex;
                     align-items: center;
                     // &::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
                     // &:-ms-input-placeholder, /* Internet Explorer 10-11 */
                     // &:-ms-input-placeholder {
                     //    /* Microsoft Edge */
                     //    color: #9da9ae;
                     // }
                     @include kt-mobile {
                        font-size: 14px;
                     }
                     input {
                        width: calc(100% - 10px);
                        border: none;
                        outline: none;
                        height: 36px;
                        background-color: transparent !important;
                        margin-left: 5px;
                        &.hide-input {
                           display: none;
                        }
                     }
                     .tag {
                        background-color: rgb(224, 224, 255);
                        border-radius: 3px;
                        // margin: 3px 0 3px 0;
                        padding: 2px 10px;
                        margin: 6px 0 6px 0;

                        position: relative;
                        padding-right: 35px;
                        // height: 20px;
                        line-height: 20px;
                        .remove {
                           position: absolute;
                           right: 0;
                           top: 0;
                           background-color: rgba(0, 0, 0, 0.1);
                           height: 100%;
                           width: 25px;
                           text-align: center;
                           line-height: 25px;
                           border-radius: 0 3px 3px 0;
                           &:hover {
                              background-color: rgba(0, 0, 0, 0.2);
                              cursor: pointer;
                           }

                           i {
                              font-size: 14px;
                              color: black;
                              opacity: 0.4;
                           }
                        }
                     }
                     .loader {
                        padding: 2px 8px 0 0;
                        position: absolute;
                        right: 0;
                        top: 8px;
                     }
                  }

                  i {
                     -moz-transform: scale(-1, 1);
                     -webkit-transform: scale(-1, 1);
                     -o-transform: scale(-1, 1);
                     -ms-transform: scale(-1, 1);
                     transform: scale(-1, 1);
                     font-size: 20px;
                     color: #9da9ae;
                     position: relative;
                     // line-height: 20px;
                     // height: 20px;
                     z-index: 9;
                     display: inline-block;
                     cursor: pointer;
                     // top: 4px;
                     // left: -27px;
                     &.fa-times-circle {
                        // display: none;
                        font-size: 15px;
                        margin-right: 10px;
                        opacity: 0.5;
                        transition: all 0.2s;
                        cursor: pointer;
                        &:hover {
                           opacity: 1;
                        }
                        // top:2px;
                     }
                  }
               }
            }

            &.search-open {
               .search-box {
                  .search-box-border {
                     border: 1px solid #007989;
                     box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                     border-radius: 5px;
                     width: 100%;
                     // width: calc(100% - 20px);
                     @include kt-mobile {
                        // display:block;
                        width: calc(100%);
                     }
                  }
               }
            }
            &.has-term {
               .search-box {
                  .search-box-border {
                     border: 1px solid #007989;
                     // background-color: rgb(241, 246, 255);
                     // box-shadow: 0px 0px 2px #015a66;
                     border-radius: 5px;
                     input {
                        // background-color: rgb(241, 246, 255);
                     }
                  }
               }
            }
            .no-suggestions {
               position: absolute;
               z-index: 99;
               width: calc(100% - 20px);
               background: #ffffff;
               box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
               border-radius: 4px;
               padding: 12px 16px;
               font-weight: 500;
               display: none;
               font-size: 12px;
               span {
                  font-weight: 600;
                  color: black;
               }
               &.no-suggestions-open {
                  display: block;
               }
            }
            .search-suggestion-list {
               position: absolute;
               z-index: 99;
               width: calc(100%);
               background: #ffffff;
               box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
               border-radius: 4px;
               padding: 8px;
               display: none;
               &.suggestions-open {
                  display: block;
               }
               div {
                  .sug-item {
                     color: #646c9a;
                     display: flex;
                     align-items: center;
                     padding: 5px 10px 5px 8px;
                     border-radius: 3px;
                     cursor: pointer;
                     &:hover {
                        background-color: rgb(243, 243, 243);
                     }
                     &.selected {
                        background-color: rgb(243, 243, 243);
                     }

                     .info {
                        flex: 1;
                        .name {
                           font-weight: 500;

                           strong {
                              color: rgb(36, 36, 36);
                           }
                        }
                     }
                     .type-icon {
                        img {
                           width: 18px;
                        }
                     }
                     .privacy-icon {
                        width: 20px;
                        margin-right: 5px;
                        text-align: center;
                        opacity: 0.5;
                        // display: none;
                        i {
                           font-size: 18px;
                           &.fa-lock {
                              font-size: 12px;
                           }
                           &.fa-users {
                              font-size: 12px;
                           }
                           &.fa-globe-americas {
                              font-size: 12px;
                           }
                        }
                        img {
                           width: 20px;
                        }
                     }
                  }
                  .new-value {
                     background-color: white;
                     border: 1px solid rgb(240, 239, 239);
                     // background-color: rgb(224, 224, 255);
                     border-radius: 3px;
                     // margin: 3px 0 3px 0;
                     padding: 2px 7px;
                     margin: 6px 0 6px 0;
                     margin-left: 10px;

                     position: relative;
                     // padding-right: 35px;
                     // height: 20px;
                     line-height: 20px;
                  }
               }
               .loader {
                  text-align: center;
                  padding: 10px 0;
               }
            }
         }
         .display-name-preview {
            background-color: #46778712;
            padding: 10px;
            border-radius: 4px;
            .description {
               font-weight: 500;
               font-size: 12px;
               opacity: 0.7;
            }
            .preview {
               font-weight: 500;
               margin-top: 10px;
               color: #26323a;
               .principal-item {
                  margin: 0;
               }
            }
         }
      }
      .people-updating-server {
         position: absolute;
         z-index: 1099;
         top: 140px;
         left: 0;
         // background-color: rgba(255, 255, 255, 0.715);
         width: 100%;
         height: calc(100% - 140px);
         .animated-loader-wrapper-square {
            position: relative;
            top: 50%;
            margin-top: -80px;
         }
         display: none;
      }
      &.processing-guest {
         .people-updating-server {
            display: block;
         }
         .people-form {
            opacity: 0.2;
         }
      }

      .people-queue {
         border-top: 1px solid #ecedf2;
         position: relative;
         .ScrollbarsCustom-TrackY {
            right: 0 !important;
            // width:3px !important;
            top: 0;
            height: 100%;
         }

         &.is-expanded {
            border-bottom: 1px solid lightgray;
         }
         .people-queue-banner {
            // background: #fafafa;
            padding: 10px 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // border-bottom: 1px solid #ecedf2;
            transition: 0.3s all;
            cursor: pointer;
            .queue-info {
               user-select: none;
               font-weight: 600;
               font-size: 14px;
               line-height: 19px;
               color: #26323a;
               .queue-info-text {
               }
               .queue-info-count {
                  color: #718690;
                  margin-left: 4px;
               }
            }
         }
         &.open {
            .people-queue-banner {
               background: #fafafa;
            }
         }
         .people-queue-container {
            transition: max-height 300ms ease-in-out;
            padding: 0px 8px;

            &.hidden {
               max-height: 0;
               overflow: hidden;
               .dynamic-scrollbar {
                  transition: max-height 300ms ease-in-out;
                  transition-delay: 100ms;
                  max-height: 0;
               }
            }
            &.visible {
               border-top: 1px solid #ecedf2;
               max-height: 80px;
               transition: max-height 300ms ease-in-out;
               overflow: hidden;
               &.two-or-more {
                  max-height: 140px;
               }
               .dynamic-scrollbar {
                  transition: max-height 300ms ease-in-out;
                  max-height: 140px;
               }
            }

            ul {
               padding: 5px 0px;
               margin-bottom: 0;

               .ScrollbarsCustom-Wrapper {
                  inset: 0 !important;
               }

               li {
                  padding: 12px 16px;
                  margin-right: 10px;
                  word-wrap: none;
                  position: relative;
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  transition: all 0.3s;
                  &:hover {
                     background: rgba(61, 160, 162, 0.1);
                     //cursor: default;
                  }
                  .pic {
                     width: 32px;
                     height: 32px;

                     // background-color: rgb(211, 211, 211);
                     display: inline-block;
                     vertical-align: top;
                     // position: relative;
                     margin-top: 0px;
                     margin-left: 0px;
                     // border:1px solid #D5D1D2;
                     margin-right: 15px;
                     > div {
                        border-radius: 50%;
                        font-size: 11px;
                        font-weight: 600;
                     }
                     > img {
                        border-radius: 50%;
                        width: 32px;
                        height: 32px;
                        vertical-align: top;
                     }
                     .profile-picture-placeholder {
                        width: 32px;
                        height: 32px;
                        line-height: 33px;
                        font-size: 13px;
                     }
                  }
                  .info {
                     flex: 1;
                     .name {
                        display: block;
                        font-size: 14px;
                        font-weight: 500;
                        color: #26323a;
                     }
                     .guest-note {
                        margin-left: 3px;
                        font-weight: 500;
                        opacity: 0.6;
                     }
                     .job-title {
                        margin-left: 0px;
                        display: block;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #718690;
                     }
                  }
                  .principal-type {
                     position: absolute;
                     left: 38px;
                     top: 34px;
                     width: 15px;
                     height: 15px;
                     background-color: #0abb87;
                     font-size: 10px;
                     font-weight: 600;
                     color: white;
                     line-height: 14px;
                     margin: 0 auto;
                     text-align: center;
                     border-radius: 50%;
                     border: 1px solid white;
                     box-sizing: content-box;
                     z-index: 93;
                     &.type-group {
                        background-color: rgb(121, 121, 231);
                     }
                     &.type-securitygroup {
                        background-color: rgb(237, 105, 105);
                        // font-size:8px;
                     }
                     &.type-distributiongroup {
                        background-color: rgb(230, 182, 110);
                        // font-size:8px;
                     }
                  }
                  .remove {
                     cursor: pointer;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     width: 24px;
                     height: 24px;
                     border: 1px solid #e1e7ee;
                     box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                     border-radius: 50%;
                     text-align: center;
                     line-height: 23px;
                     i {
                        font-size: 9px;
                     }
                  }
               }
            }
         }
      }
   }

   .processing-guest {
      .people-content {
         .people-updating-server {
            display: block;
         }
         .people-form {
            opacity: 0.2;
         }
      }
      .modal-footer {
         opacity: 0.5;
      }
   }

   .principal-modal-guests-footer {
      background-color: #f2f5f8;
      .btn-primary {
      }
   }
}

.guest-picker-error-modal-bg {
   z-index: 1060 !important;
}
.guest-picker-error-modal {
   z-index: 1070 !important;
}
