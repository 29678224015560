.timeline-expandable-button {
   position: relative;
   bottom: 24px;

   margin-left: 17px;

   .button-text {
      padding-top: 4px;
   }
}
