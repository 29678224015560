.form-text-list {
   font-weight: 500;
   .values {
      background-color: white;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 0.75rem;
      .no-choices {
         padding: 5px 0;
      }
      .item {
         margin-bottom: 5px;
         position: relative;
         .icon {
            display: inline-block;
            width: 17px;
            height: 17px;
            > span {
               position: relative;
               top: 1px;
               // display: none;
               svg {
                  width: 17px;
                  height: 17px;
               }
            }
            .remove {
               display: block !important;
               position: absolute;
               z-index: 1;
               right: -0px;
               top: 15px;
               margin-top: -8px;
               cursor: pointer;
               opacity: 0.5;
               transition: all 0.3s;
               &:hover {
                  opacity: 1;
               }
               svg {
                  color: rgb(241, 107, 107);
                  width: 15px;
                  height: 15px;
                  transition: all 0.5s;
               }
               &:hover {
                  svg {
                     color: rgb(219, 67, 67);
                  }
               }
            }
         }
         input {
            margin-left: 5px;
            display: inline-block;
            background-color: transparent;
            border: 1px solid transparent;
            padding: 5px 5px 5px 5px;
            line-height: 20px;
            width: calc(100% - 50px);
            border-radius: 0.25rem;
            transition: all 0.5s;

            
            &:hover {
               border: 1px solid rgb(235, 235, 235);
            }
            &:focus {
               background-color: white;
               border: 1px solid #28b976;
               outline: none;
            }
            &.enter-was-pressed {
               //border:1px solid white;
            }
         }

         // &:hover {
         //     .icon {
         //         .check {
         //             display:none !important;
         //         }
         //         .dot {
         //             display:none !important;
         //         }
         //         .remove {
         //             display: inline-block;
         //             opacity: 0.3;
         //             color:red;
         //         }
         //     }
         // }
      }
   }
   .add {
      margin-top: 15px;
      
      .button-custom {
         padding: 5px 14px;
         font-size: 13px;
         height: 31px;
         line-height: 22px;
         svg {
            top: 1px;
         }
         i {
            margin-right:5px;
         }
         &.save-button {
            margin-left:15px;
            opacity: 0;
            transition: all 0.3s;
            &.show-save {
               opacity: 1;
            }
         }
      }
      .icon {
         display: inline-block;
         svg {
            width: 17px;
            height: 17px;
         }
      }
      .text {
         display: inline-block;
         padding: 5px;
      }
      
   }
   &.multiple {
      .values {
         .item {
            .icon {
               .check {
                  display: inline-block;
                  opacity: 0.3;
               }
            }
         }
      }
   }
   &.single {
      .values {
         .item {
            .icon {
               .dot {
                  display: inline-block;
                  opacity: 0.3;
               }
            }
         }
      }
   }
   &.hidethis {
      // display: none;
   }
}