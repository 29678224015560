@import "_metronic/_assets/sass/breakpoints";

.lifecycle-report-filters {
   display: flex;
   padding: 20px 0;
   align-items: center;

   @include kt-mobile {
      // display:block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      flex-wrap: wrap;
      padding-top: 0;
      padding-bottom: 10px;
      box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
      //    &:after {
      //    content:"";
      //    width:100%;
      // }
   }

   .filters-left {
      flex: 1;
      .lifecycle-header {
         // padding-left: 20px;
         padding-right: 20px;
         padding-left: 20px;
         // padding-top: 20px;
         // padding-bottom: 20px;
         .title {
            margin-bottom: 3px;
            // display: flex;
            // align-items:flex-end;
            .text {
               font-weight: 700;
               line-height: 32px;
               font-size: 27px;
               color: #26323a;
               margin-right: 15px;
            }
            .number {
               font-size: 18px;
               font-weight: 600;
               color: gray;
               white-space: nowrap;
            }
         }
         .description,
         .subdescription {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #718690;
         }
         .item-count {
            font-weight: 500;
            font-size: 12px;
            margin-top: 0px;
            .number {
            }
            .text {
            }
         }
      }
   }
   .filters-right {
      min-width: 45%;
      .search {
         justify-content: flex-end;
      }
      .filters-bottom {
         display: flex;
         justify-content: flex-end;
         > div {
            flex: 2;
            &:last-child {
               margin-right: 0;
            }
         }
         .show-archived {
            flex: 1;
            white-space: nowrap;
            > div {
               display: flex;
               &:hover {
                  background-color: #f8f8f8;
                  cursor: pointer;
               }
               .icon {
                  width: 24px;
                  font-size: 15px;
               }
            }
         }
         .show-deleted {
            .show-deleted-view-hidden {
               display: flex;
               justify-content: flex-start;
               margin-right: 0;
               .loading {
                  margin-right: 10px;
                  .animated-loader {
                     height: 16px;
                     width: 16px;
                  }
               }
               .icon {
                  margin-right: 10px;

                  i {
                     font-size: 16px;
                  }
               }
               &:hover {
                  background-color: #f8f8f8;
                  cursor: pointer;
               }
            }
         }
         .export-report {
            .export-button {
               display: flex;
               justify-content: flex-start;
               margin-right: 0;
               margin-right: 16px;
               .loading {
                  margin-right: 10px;
                  .animated-loader {
                     height: 16px;
                     width: 16px;
                  }
               }
               .icon {
                  margin-right: 10px;

                  i {
                     font-size: 16px;
                  }
               }
               &:hover {
                  background-color: #f8f8f8;
                  cursor: pointer;
               }
            }
         }
      }
   }

   .search {
      position: relative;
      flex: 1;
      margin-bottom: 10px;
      @include kt-mobile {
         // display:block;
         flex-basis: 100%;
         margin-bottom: 10px;
         padding: 10px 20px;
         background-color: #188381;
      }
      .search-box {
         .search-box-border {
            display: flex;
            align-items: center;
            // width:226px;
            border: 1px solid #e1e7ee;
            box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            transition: all 0.3s ease-in-out;
            border-radius: 5px;
            padding-right: 8px;
            transition: all 0.3s;
            @include kt-mobile {
               // display:block;
               width: calc(100%);
               background-color: white;
            }
            input {
               outline: none;
               background: #ffffff;
               font-family: TT Norms;
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               line-height: 16px;
               color: #26323a;
               padding: 10px 16px;
               border: 0px solid transparent;
               border-radius: 6px;
               transition: all 0.3s;
               flex: 1;
               &::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
               &:-ms-input-placeholder, /* Internet Explorer 10-11 */
               &:-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: #9da9ae;
               }
               @include kt-mobile {
                  font-size: 14px;
               }
            }
            .loader {
               padding: 2px 8px 0 0;
            }
            i {
               -moz-transform: scale(-1, 1);
               -webkit-transform: scale(-1, 1);
               -o-transform: scale(-1, 1);
               -ms-transform: scale(-1, 1);
               transform: scale(-1, 1);
               font-size: 20px;
               color: #9da9ae;
               position: relative;
               // line-height: 20px;
               // height: 20px;
               z-index: 9;
               display: inline-block;
               cursor: pointer;
               // top: 4px;
               // left: -27px;
               &.fa-times-circle {
                  // display: none;
                  font-size: 15px;
                  margin-right: 10px;
                  opacity: 0.5;
                  transition: all 0.2s;
                  cursor: pointer;
                  &:hover {
                     opacity: 1;
                  }
                  // top:2px;
               }
            }
         }
      }

      &.search-open {
         .search-box {
            .search-box-border {
               border: 1px solid #007989;
               box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
               border-radius: 5px;
               width: 100%;
               // width: calc(100% - 20px);
               @include kt-mobile {
                  // display:block;
                  width: calc(100%);
               }
            }
         }
      }
      &.has-term {
         .search-box {
            .search-box-border {
               border: 1px solid #007989;
               background-color: rgb(241, 246, 255);
               // box-shadow: 0px 0px 2px #015a66;
               border-radius: 5px;
               input {
                  background-color: rgb(241, 246, 255);
               }
            }
         }
      }
      .no-suggestions {
         position: absolute;
         z-index: 99;
         width: calc(100% - 20px);
         background: #ffffff;
         box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
         border-radius: 4px;
         padding: 12px 16px;
         font-weight: 500;
         display: none;
         font-size: 12px;
         span {
            font-weight: 600;
            color: black;
         }
         &.no-suggestions-open {
            display: block;
         }
      }
      .search-suggestion-list {
         position: absolute;
         z-index: 99;
         width: calc(100% - 20px);
         background: #ffffff;
         box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
         border-radius: 4px;
         padding: 8px;
         display: none;
         &.suggestions-open {
            display: block;
         }
         div {
            .sug-item {
               color: #646c9a;
               display: flex;
               align-items: center;
               padding: 8px 10px 8px 5px;
               border-radius: 3px;
               cursor: pointer;
               &:hover {
                  background-color: rgb(243, 243, 243);
               }
               &.selected {
                  background-color: rgb(243, 243, 243);
               }
               .pic {
                  padding-right: 15px;
                  position: relative;
                  .archived-icon {
                     position: absolute;
                     right: 11px;
                     bottom: 0px;
                     width: 13px;
                     height: 13px;
                     background-color: #f3eab1;
                     border-radius: 2px;
                     box-shadow: 0 1px 1px #0000002b;
                     text-align: center;
                     i {
                        position: relative;
                        top: -3px;
                        text-align: center;
                        color: #8f864e;
                        line-height: 0px;
                        font-size: 11px;
                        margin: auto;
                        opacity: 1;
                     }
                  }
               }
               .info {
                  flex: 1;
                  padding-right: 0.125rem;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  .name {
                     font-weight: 500;
                     strong {
                        color: black;
                     }
                  }
                  .mail {
                     font-weight: 500;
                     text-align: right;
                     strong {
                        color: black;
                     }
                  }
                  .sharepoint-link {
                     a {
                        font-size: 11px;
                        font-weight: 500;
                        opacity: 0.8;
                        &:hover {
                           // color: black;
                           text-decoration: underline !important;
                        }
                     }
                  }
               }

               .type-icon {
                  img {
                     width: 18px;
                  }
               }
               .privacy-icon {
                  width: 20px;
                  margin-right: 5px;
                  text-align: center;
                  opacity: 0.5;
                  // display: none;
                  i {
                     font-size: 18px;
                     &.fa-lock {
                        font-size: 12px;
                     }
                     &.fa-users {
                        font-size: 12px;
                     }
                     &.fa-globe-americas {
                        font-size: 12px;
                     }
                  }
                  img {
                     width: 20px;
                  }
               }
            }
         }
         .loader {
            text-align: center;
            padding: 10px 0;
         }
      }
   }
   .custom-button {
      background: #ffffff;
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
      // border-radius: 5px;
      font-family: TT Norms;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding: 10px 16px;
      position: relative;
      height: 38px;
      margin-right: 12px;
      span {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
      @include kt-mobile {
         // display:block;
         margin-left: 0;
         font-size: 14px;
      }
   }
   .admin {
      .admin-view-hidden {
         transition: all 0.3s;
         cursor: pointer;

         i {
            font-size: 18px;
            line-height: 0;
            position: relative;
            top: 3px;
            margin-right: 6px;
         }
         &:hover {
            background-color: rgb(248, 248, 248);
         }
      }
   }
   .filters {
      position: relative;
      @include kt-mobile {
         // display:block;
         flex-basis: 50%;
         padding-left: 20px;
         padding-right: 10px;
         position: static;
      }
      .filter-button {
         display: flex;
         align-items: center;
         cursor: pointer;
         transition: all 0.3s;
         .filter-icon {
            width: 26px;
            padding-right: 10px;
         }
         .filter-text {
         }
         &:hover {
            background-color: rgb(248, 248, 248);
         }
         .filter-count {
            padding-left: 10px;
            max-width: 0;
            overflow: hidden;
            transition: all 0.5s;
            > div {
               background-color: #7272f1;
               color: white;
               font-weight: 500;
               text-align: center;
               padding: 1px 6px;
               border-radius: 3px;
               font-size: 11px;
               display: flex;
               height: 22px;
               align-items: center;
               transition: all 0.3s;
               .clear-filter {
                  margin-left: 10px;
                  font-size: 10px;
                  opacity: 0.5;
                  &:hover {
                     opacity: 1;
                  }
               }
            }
            &.filter-count-shown {
               max-width: 60px;
            }
         }
      }
      .filter-dropdown {
         min-width: 425px;
         position: absolute;
         background-color: white;
         z-index: 99;
         background: #ffffff;
         box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
         border-radius: 4px;
         padding: 8px;
         right: -125px;
         top: calc(100% + 10px);

         @include kt-mobile {
            padding-top: 20px;
            left: 0;
            top: calc(100%);
            border-top: 1px solid rgb(218, 218, 218);

            // width: 100vw;
            width: 100%;
            border-radius: 0 0 4px 4px;
            // position: fixed;
            // top:200px;

            .ScrollbarsCustom {
               max-height: calc(100vh - 300px) !important;
               min-height: calc(100vh - 300px) !important;
            }
         }

         .form-items {
            padding: 0 8px;
            @include kt-mobile {
               padding: 0 15px;
            }
            .form-group {
               margin-bottom: 12px;
               .form-label {
                  font-family: TT Norms;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14px;
                  color: #718690;
                  margin-bottom: 10px;
               }
               .btn-group {
                  width: 100%;
               }
               .form-check {
                  margin-bottom: 10px;
                  &:last-child {
                     margin-bottom: 0px;
                  }
                  label {
                     font-weight: 500;
                     margin-left: 12px;
                  }
                  input:checked + label:before {
                     width: 20px;
                     height: 20px;
                     background-color: #007989;
                     border-color: #007989;
                  }
                  label:before {
                     width: 20px;
                     height: 20px;
                  }
                  input:checked + label:after {
                     content: "";
                     font-size: 14px;
                     position: absolute;
                     z-index: 1;
                     top: 4px;
                     left: 4px;
                     color: #ffffff;
                     transform: rotate(-45deg) scale(0.8);
                     border-bottom: solid 3px #ffffff;
                     border-left: solid 3px #ffffff;
                     background: none;
                     height: 8px;
                     width: 12px;
                  }
               }
            }
            .type-group {
               display: flex;
               > * {
                  width: 33%;
                  text-align: center;
               }
               align-items: center;
               .type-item {
                  padding: 10px 3px 5px 3px;
                  margin: 0;
                  background-color: white;
                  border: 1px solid #e1e7ee;
                  border-radius: 0;
                  word-break: break-all;
                  box-shadow: none;
                  cursor: pointer;
                  transition: all 0.3s;
                  height: auto;
                  min-height: 44px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &:hover {
                     background: #f9fafa;
                  }
                  .image {
                     text-align: center;
                     img {
                        display: inline-block;
                        position: relative;
                        height: 20px;
                        margin: auto 0;
                        margin-bottom: 2px;
                     }
                  }
                  .words {
                  }

                  span {
                     vertical-align: middle;
                  }
                  &:first-child {
                     border-radius: 5px 0 0 5px;
                  }
                  &:first-child,
                  &:nth-child(2) {
                     width: calc(33% + 1px);
                     margin-right: -1px;
                  }
                  &:last-child {
                     border-radius: 0 5px 5px 0;
                  }
                  &.selected {
                     background: #9da2bfc2;
                     border-color: #9da2bf;
                     color: white;
                     // background: #F2F4F7;
                     &:hover {
                        background: #9da2bfc2 !important;
                        // background: #F2F4F7;
                     }
                  }
               }
            }
            .user-dropdown {
               display: block;
               width: 100%;
               height: calc(1.5em + 1.3rem + 2px);
               // padding: 0.65rem 1rem;
               font-size: 1rem;
               font-weight: 400;
               line-height: 1.5;
               color: #495057;
               background-color: #fff;
               background-clip: padding-box;
               border: 1px solid #e2e5ec;
               border-radius: 4px;
               transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
               position: relative;
               cursor: pointer;
               .please-select {
                  padding: 0.65rem 1rem;
                  font-size: 12px;
                  font-weight: 500;
               }
               .user-item {
                  margin: 3px 0 0 10px;
                  font-size: 12px;
                  font-weight: 500;
                  span {
                     font-size: 12px;
                     line-height: 31px;
                  }
                  .pic {
                     span {
                        line-height: 25px;
                        font-size: 10px;
                     }
                  }
               }
               > i.arrow {
                  position: absolute;
                  right: 15px;
                  top: 50%;
                  margin-top: -6px;
                  transition: all 0.3s;
                  color: #a3b4bc;
               }
               &:hover {
                  border: 1px solid #cfd1d8;
               }
               &.is-open {
                  border: 1px solid #007989;
                  > i.arrow {
                     transform: rotate(180deg);
                  }
               }
               .clear {
                  position: absolute;
                  right: 40px;
                  top: 50%;
                  margin-top: -8px;
                  font-weight: 500;
                  // opacity: 0.5;
                  font-size: 16px;
                  opacity: 0.3;
                  color: red;
                  &:hover {
                     opacity: 0.7;
                  }
               }
            }
            .date-title {
               font-size: 11px;
               font-weight: 500;
            }
         }
         .footer {
            margin-top: 20px;
            background: #f4f5f7;
            border-radius: 4px;
            padding: 8px;
            display: flex;
            align-items: center;
            align-content: center;
            .clear {
               color: #007989;
               font-size: 12px;
               font-weight: 500;
               flex: 1;
               // padding-left: 10px;
               .clear-button {
                  padding: 8px 10px;
                  display: inline-block;
                  border-radius: 4px;
                  cursor: pointer;
                  transition: all 0.3s;
                  &:hover {
                     background-color: white;
                  }
               }
            }
            .filters-loading {
               padding: 5px 5px 0 0;
            }
            .apply {
               button {
                  background: #007989;
                  border-radius: 4px;
                  color: white;
                  font-size: 12px;
                  font-weight: 500;
                  padding: 8px 12px;
                  border: 0px solid transparent;
                  &:hover {
                     background-color: #00626e;
                  }
               }
            }
         }
      }
      .custom-filter-button {
         width: 17px;
         height: 17px;
         display: inline-block;

         vertical-align: top;
         position: absolute;
         top: 12px;
         margin-right: 10px;

         > div {
            position: absolute;
            height: 1px;
            left: 0;
            right: 0;
            background-color: #26323a;
            &.line1 {
               top: 0;
            }
            &.line2 {
               top: 4px;
               left: 2px;
               right: 2px;
            }
            &.line3 {
               top: 8px;
               left: 4px;
               right: 4px;
            }
            &.line4 {
               top: 12px;
               left: 6px;
               right: 6px;
            }
         }
      }
      .metadata-input {
         .mananged-metadata-picker .input-picker .picker-input-box .picker-input-wrapper .selected-items {
            padding: 4px 10px 7px 10px;
            min-height: 39px;
         }
      }
   }
   .sort {
      position: relative;
      @include kt-mobile {
         // display:block;
         flex-basis: 50%;
         padding-right: 20px;
         padding-left: 10px;
      }
      .sort-button {
         padding-left: 30px;
         cursor: pointer;
         transition: all 0.3s;
         &:hover {
            background-color: rgb(248, 248, 248);
         }
         .direction {
            position: absolute;
            font-size: 23px;
            top: 7px;
            left: 5px;
            // margin-top:-3px;
            .up {
               display: block;
               position: absolute;
            }
            .down {
               display: block;
               position: absolute;
            }
            &:hover {
               background-color: blue;
            }
         }
         span {
         }
      }
      .sort-dropdown {
         min-width: 200px;
         position: absolute;
         background-color: white;
         z-index: 99;
         background: #ffffff;
         box-shadow: 0px 2.3245px 3.70812px rgba(33, 41, 45, 0.05), 0px 5.58609px 8.91114px rgba(33, 41, 45, 0.07), 0px 10.5181px 24px rgba(33, 41, 45, 0.1);
         border-radius: 4px;
         padding: 8px;
         left: calc(50%);
         margin-left: -50%;
         top: calc(100% - -5px);
         .sort-item {
            font-weight: 500;
            padding: 10px;
            transition: all 0.3s;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
               background-color: rgb(243, 243, 243);
            }
         }
      }
   }

   &.fixed {
      @include kt-mobile {
         position: fixed;
         background-color: white;
         z-index: 999;
         // top:0;
         zoom: 1;
         // padding-top: 20px !important;
         backface-visibility: hidden;
         .search {
            .search-box {
               .search-box-border {
                  width: calc(100% - 40px);
               }
            }
         }
      }
   }
}

.lifecycle-report-filters-nav-buttons {
   display: flex;
   .dashboard-button {
      padding-right: 15px;
      .button-custom {
         // background-color: #007989;
         min-width: 150px;
         color: white;
         font-style: normal;
         font-weight: 500;
         font-size: 13px;
         line-height: 22px;
         display: block;
         padding: 9px;
         i {
            font-size: 15px;
            position: relative;
            bottom: -1px;
            line-height: 15px;
            margin-right: 4px;
            padding-right: 5px;
         }
         &:hover {
            // background-color: #026470;
         }
      }
   }
   .lifecycle-view-button {
      // margin-left: 8px;
      flex: 1;
      // @include kt-mobile {
      //    display: none;
      // }
      .view-selector {
         min-width: 150px;

         .button-custom {
            background-color: #007989;
            color: white;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 22px;
            display: block;
            padding: 9px;
            i {
               font-size: 22px;
               position: relative;
               bottom: -4px;
               line-height: 15px;
               margin-right: 4px;
            }
            &:hover {
               background-color: #026470;
            }
         }

         .dd-wrapper {
            .dd-header {
               border: 1px solid transparent;
               outline: none;
               background-color: #007989;
               padding: 0.65rem 0.85rem;
               position: relative;
               top: -1px;
               .dd-header-title {
                  .header-text {
                     color: white;
                     font-style: normal;
                     font-weight: 500;
                     font-size: 12px;
                     line-height: 22px;
                  }
               }
            }
            .dd-list {
               width: calc(100% + 2px);
               left: -1px;
            }
         }
         // display: flex;
         // .list {
         //    cursor: pointer;
         //    padding: 10px 15px;
         //    border-radius: 5px 0 0 5px;
         //    margin-right: -1px;
         //    .list-icon {
         //       top:1px;
         //       width:20px;
         //       height:20px;
         //       position: relative;
         //       margin-bottom: -3px;
         //       .top-bar {
         //          position: absolute;
         //          top:0;
         //          background-color: #007989;
         //          height: 26%;
         //          width: 100%;
         //          border-radius: 2px;
         //          transition: all 0.3s;
         //       }
         //       .middle-bar {
         //          background-color: #007989;
         //          position: absolute;
         //          top: 38%;
         //          height: 11%;
         //          width: 100%;
         //          border-radius: 1px;
         //          transition: all 0.3s;
         //       }
         //       .bottom-bar {
         //          position: absolute;
         //          top:66%;
         //          background-color: #007989;
         //          height: 11%;
         //          width: 100%;
         //          border-radius: 1px;
         //          transition: all 0.3s;
         //       }
         //    }
         // }
         // .cards {
         //    cursor: pointer;
         //    border-radius: 0 5px 5px 0;
         //    margin-left: 0;
         //    i {
         //       font-size:18px;
         //       color: #007989;
         //       transition: all 0.3s;
         //    }
         // }
         // > * {
         //    transition: all 0.3s;
         // }
         // > .selected {
         //    cursor: default;
         //    background: #F0F2F6;
         //    i {
         //       color:#9DA9AE !important;
         //    }
         //    .list-icon > * {
         //       background-color: #9DA9AE !important;
         //    }
         // }
      }
   }
}
