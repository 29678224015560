.card-report-metadata-cols {
   .form-group {
      margin-bottom: 5px;
   }
   .report-column-setting {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ebedf2;
      &:last-child {
         margin-bottom: 0;
         border-bottom: 0px solid transparent;
      }
      .title {
         font-weight: 600;
         padding-bottom: 10px;
      }
      .checks {
         padding-left: 0px;
      }
   }
}
