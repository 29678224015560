//I couldn't get the mantine theme to work with the module version of the component, so I am just setting it in the classnames
.orchestry-date-picker-input__day {
   font-family: var(--mantine-font-family);
   &:not([data-selected]) {
      color: var(--mantine-color-gray-7);
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
   }

   &[data-in-range] {
      font-family: var(--mantine-font-family);
      color: var(--mantine-color-primary-7) !important;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      &[data-selected] {
         font-family: var(--mantine-font-family);
         color: var(--mantine-primary-color-contrast) !important;
         font-weight: 400;
         font-size: 14px;
         line-height: 20px;
      }
   }
}

.orchestry-date-picker-input__weekday {
   font-family: var(--mantine-font-family);
   color: var(--mantine-color-gray-7);
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   text-align: center;
}

.orchestry-date-picker-input__calendar-header {
   font-family: var(--mantine-font-family);
   color: var(--mantine-color-primary-7);
}

.orchestry-date-picker-input {
   font-size: 12px;
   line-height: 16px;
   font-family: var(--mantine-font-family);
   color: var(--mantine-color-gray-7);
}
