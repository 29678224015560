.tree-wrapper {
   display: flex;
   .navigation {
      max-width: 350px;
      min-width: 350px;
      width: 350px;
      > .kt-portlet__body {
         padding-right: 10px;
      }
   }
   .properties-window {
      margin-left: 20px;
      flex: 1;
      .property-item {
         display: flex;
         margin-bottom: 5px;
         border-bottom: 1px solid rgb(230, 230, 230);
         &:last-child {
            border-bottom: none;
         }
         .property-key {
            width: 200px;
            min-width: 200px;
            font-size: 14px;
            font-weight: 500;
            word-wrap: break-word;
            padding-right: 15px;
         }
         .property-value {
            font-size: 14px;
            word-wrap: break-word;
            .string {
               word-wrap: break-word;
            }
         }
      }
   }
}

.tree {
   .node-root {
      position: relative;
      // left
   }
   .node-wrapper {
      .loading {
         position: absolute;
         left: -20px;
         top: 4px;
         background-color: white;
         .animated-loader {
            width: 16px;
            height: 16px;
            margin-left: 20px;
         }
      }
      .node {
         padding: 3px 0 3px 0px;
         display: flex;
         align-items: center;
         .node-expand-icon {
            // position: absolute;
            // left: -7px;
            height: 20px;
            width: 15px;
            text-align: center;
            top: 0px;
            line-height: 26px;
            opacity: 0.4;
            transition: 0.3s;
            cursor: pointer;
         }
         .node-icon-image {
            // display: inline-block;
            width: 24px;
            text-align: center;
            margin-right: 4px;
            opacity: 0.8;
            .profile-picture {
               position: relative;
               top: -1px;
               .kt-media.kt-media--xs span {
                  width: 18px;
                  height: 18px;
                  line-height: 16px;
               }
            }
            i {
               font-size: 12px;
            }
         }
         .node-title {
            // display: inline-block;
            padding-left: 4px;
            color: black;
            font-weight: 500;
            &.has-object {
               color:rgb(77, 77, 160);
               cursor: pointer;
            }
         }
      }

      &.open {
         > .node {
            .node-expand-icon {
               top: 3px;
               left: -6px;
               transform: rotate(90deg);
            }
         }
      }

      > ul {
         > li {
            margin-left: 17px;
            position: relative;
            &:before {
               content: "";
               opacity: 0.1;
               left: 0;
               width: 1px;
               background-color: grey;
               position: absolute;
               left: -12px;
               height: calc(100% + 3px);
               top: -3px;
            }
            &:last-child {
               &:before {
                  height: 14px;
               }
               &:after {
                  // content: "";
                  // opacity: 0.1;
                  // position: absolute;
                  // background-color: grey;
                  // left: -12px;
                  // height: 1px;
                  // width: 3px;
                  // top: 11px;
               }
            }
         }
      }
   }
}
