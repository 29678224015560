@import "app/assets/common/bootstrap-media-mixins";

.orch-modal {
   &.bulk-remove-guest-modal {
      .btn {
         &.btn-primary {
            background-color: #00626f;
         }
      }
   }
   &.bulk-resend-invite-guest-modal {
      .btn {
         &.btn-primary {
            background-color: #00626f;
         }
      }
   }
}

.lifecycle-details-users {
   .kt-portlet {
      .kt-portlet__body {
         padding-top: 15px;
      }
   }
   .main-container {
      --main-gap: 3rem;
      display: flex;
      flex-direction: column;
      gap: var(--main-gap);

      //report width override
      .lifecycle-report {
         .lifecycle-report-wrapper {
            padding: 0;
         }
      }

      .lifecycle-report {
         margin-top: 20px;
      }
      .insights-report {
         &.no-results {
            display: none;
         }
         .custom-header {
            display: flex;
            margin-bottom: 15px;
            .title-left {
               flex: 1;
               .top {
                  display: flex;
                  align-items: center;
                  // margin-bottom: 10px;
                  .title {
                     color: #007989;
                     font-size: 18px;
                     font-weight: 500;
                  }
                  h2 {
                     margin-bottom: 15px;
                  }
                  .count {
                     background-color: rgba(0, 0, 255, 0.398);
                     border-radius: 4px;
                     margin-left: 8px;
                     margin-top: -10px;
                     font-size: 12px;
                     font-weight: 500;
                     padding: 3px 10px;
                     color: white;
                     line-height: 16px;
                     height: 21px;
                  }
               }
            }
         }
      }
   }

   .already-archived {
      text-align: center;
      margin: 40px 0;
      i {
         font-size: 56px;
      }
      div {
         &:last-child {
            font-size: 15px;
            font-weight: 400;
         }
      }
   }
   h2 {
      flex: 1;
      font-size: 18px;
      font-weight: 500;
      width: 200px;
      margin: 0;
      .count {
         background-color: rgba(0, 0, 255, 0.398);
         border-radius: 4px;
         margin-left: 8px;
         font-size: 12px;
         font-weight: 500;
         padding: 3px 10px;
         // color: #d2d2d2;
         .number {
            color: white;
         }
      }
   }
   .list-of-guests-container {
      padding-bottom: 50px;
   }
   .list-of-members {
      position: relative;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.1);
      display: flex;
      flex-direction: column;
      &:last-child {
         margin-bottom: 0;
         padding-bottom: 0;
         border-bottom: none;
      }

      .see-more-container {
         color: #5867dd;
         position: absolute;
         transition: bottom 500ms ease-in-out;
         bottom: -35px;
         &.open {
            bottom: -40px;
         }
         width: 100%;
         z-index: 1;
         .see-more {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 28px;
            margin-top: 25px;
            text-align: center;
            .see-more-text {
               z-index: 1;
               background: white;
               &:hover {
                  color: #646c9a;
                  text-shadow: 0.1px 0.1px 0.1px #646c9a;
               }
               cursor: pointer;
               user-select: none;
               display: flex;
               align-items: center;
               gap: 0.5rem;
               i {
                  transition: transform 250ms ease;
                  font-size: 16px;
               }
               font-size: 14px;
               padding: 0 10px;
            }
            .see-more-line {
               position: absolute;
               width: 100%;
               z-index: 0;
               hr {
                  border-top: 1px solid rgba(228, 230, 232, 0.85);
               }
            }
         }
      }

      .title {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 15px;
         .form-buttons {
            margin: 0;
         }
         @media screen and (max-width: 400px) {
            .form-buttons {
               .form-button {
                  .icon {
                     margin: 0;
                  }
                  .text {
                     display: none;
                  }
               }
            }
         }
      }
      .list {
         margin-top: 10px;
         grid-gap: 20px;
         display: grid;
         grid-template-columns: repeat(5, minmax(0, 1fr));

         overflow: hidden;
         max-height: 90px;

         &.five-or-less {
            overflow: auto;
            max-height: fit-content;
         }

         @media screen and (max-width: 1900px) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
         }
         @media screen and (max-width: 1700px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            overflow: hidden;
         }
         @media screen and (max-width: 1300px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
         }
         @media screen and (max-width: 900px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
         }

         transition: max-height 500ms ease-out;
         grid-template-rows: minmax(80px, 1fr);

         &.more-than-20 {
            grid-template-rows: repeat(4, minmax(80px, 1fr));
         }

         //list show/hide animations
         &.show-enter-active {
            max-height: 90px;
         }

         &.show-enter-done {
            max-height: 780px;
         }

         &.show-exit-active {
            max-height: 780px;
         }

         &.show-exit-done {
            max-height: 90px;
         }

         //left right list animations
         $transform-timer: 500ms;
         $opacity-timer: 500ms;
         &.to-the-right-enter {
            transform: translateX(-0.25%);
            opacity: 0.5;
         }

         &.to-the-right-enter-active {
            transform: translateX(0);
            opacity: 1;
            transition: transform $transform-timer ease, opacity $opacity-timer ease;
         }

         &.to-the-right-enter-done {
            transform: translateX(0);
         }

         &.to-the-right-exit {
            display: none;
         }

         &.to-the-left-enter {
            opacity: 0.8;
            transform: translateX(0.25%);
         }

         &.to-the-left-enter-active {
            opacity: 1;
            transform: translateX(0);
            transition: transform $transform-timer ease, opacity $opacity-timer ease;
         }

         &.to-the-left-enter-done {
            transform: translateX(0);
         }

         &.to-the-left-exit {
            display: none;
         }

         .no-users {
            font-weight: 500;
            padding: 10px;
            background-color: rgb(250, 250, 250);
            border: 1px solid rgba(0, 0, 0, 0.13);
            border-radius: 5px;
         }
         .user-container {
            // min-width: 295px;
            // min-height: 140px;
            display: flex;
            align-items: center;

            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            position: relative;
            border: 1px solid rgba(228, 230, 232, 0.85);
            border-radius: 4px;
            padding: 10px 15px;

            .profile-image {
               position: relative;
               // margin-bottom: 10px;
               .graph-presence {
                  position: absolute;
                  top: 33px;
                  right: 0px;
               }
            }

            .right-side {
               display: flex;
               align-items: center;
               flex: 1;
               min-width: 0;
               .info {
                  flex: 1;
                  width: 100%;
                  padding-left: 15px;
                  min-width: 0;
                  .info-container {
                     display: flex;
                     flex-direction: column;
                     // align-items: center;
                     color: #242424;
                     font-size: 12px;
                     .display-name {
                        color: #242424;
                        font-weight: 600;
                        font-size: 14px;
                     }
                     .job-title {
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // white-space: nowrap;
                        max-width: 100%;
                     }
                     .department {
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // white-space: nowrap;
                        max-width: 100%;
                     }
                     .ellipsis {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                     }
                  }
               }
               .actions {
                  margin-top: -4px 0;
                  padding-left: 10px;
                  // position: absolute;
                  font-size: 12px;
                  // display: flex;
                  // gap: 8px;
                  // right: 13px;
                  // top: 16px;
                  color: #8083a3;
                  .action {
                     white-space: nowrap;
                     .icon {
                        margin-left: 10px;
                        font-size: 14px;
                     }
                  }
                  a {
                     color: #8083a3;
                  }
               }
            }
         }
      }

      .paging {
         position: relative;
         margin-top: 20px;
         $height: 30px;
         $width: 30px;
         z-index: 2;

         .kt-pagination .kt-pagination__links li {
            min-width: $width;
            min-height: $height;
            cursor: pointer;
         }
         .kt-pagination__link--next {
            height: $height;
            cursor: pointer;
            width: $width;
            &:hover {
               color: white;
            }
         }
         .kt-pagination__link--prev {
            height: $height;
            cursor: pointer;
            width: $width;
            &:hover {
               color: white;
            }
         }
         .kt-pagination__toolbar {
            select {
               padding-left: 2px !important;
            }
         }
         .kt-pagination__link--active {
            color: #ffffff;
         }
      }
      .no-results {
         .lifecycle-report-list-table {
            display: none;
         }
      }
   }
}
