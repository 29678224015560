.workspace-review-wizard-page {
   .workspace-review-wizard-page-header {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 32px;
      background-color: var(--mantine-color-gray-0);
   }
}
