@mixin light {
   [data-mantine-color-scheme="light"] & {
      @content;
   }
}

.indicator {
   height: 24px;
   min-height: 24px;
   width: 24px;
   min-width: 24px;
   border-color: var(--mantine-color-gray-3);
   border-width: 1.5px;

   &[data-checked] {
      border-color: transparent;
      background-color: var(--mantine-color-primary-0);

      &[data-select="accept"] {
         background-color: var(--mantine-color-success-8);

         .icon {
            color: var(--mantine-color-primary-0);
         }
      }

      &[data-select="reject"] {
         background-color: var(--mantine-color-error-9);

         .icon {
            color: var(--white);
         }
      }

      .icon {
         color: var(--mantine-color-primary-7);
      }
   }

   @include light {
      &[data-disabled] {
         &[data-checked] {
            background-color: var(--mantine-color-gray-1);

            .icon {
               color: var(--mantine-color-gray-3);
            }
         }
      }
   }

   .icon {
      height: 13px;
      width: 13px;
   }
}
