.orchestry_menu__item {
   &:hover {
      background-color: var(--mantine-color-primary-0);

      p {
         color: var(--mantine-color-primary-7) !important;
      }
   }
   &:hover * {
      color: var(--mantine-color-primary-7);
   }
}
