.classification-config {
   .name-tr {
      width: 25%;
   }
   .icon-tr {
      width: 70px;
   }
   .action-tr {
      width: 90px;
   }
   .default-toggle-tr {
      width: 90px;
   }
   .default-toggle-td {
      text-align: center;
      // padding-top: 0.85rem;
   }
   .icon-selector {
      text-align: center;

      .icon-span {
         font-size: 18px;
         text-align: center;
      }
      .form-dropdown {
         width: 60px;
         .dd-header-title {
            .header-text {
               display: none;
            }
         }
         &.open {
            .dd-list {
               padding-top: 0;
               width: 300px;
               border-radius: 0 4px 0 0;
               .searchbox {
                  border-radius: 0 4px 0 0;
               }
            }
         }
      }
   }
   .table-list-table {
      tbody {
         tr {
            td {
               height: 58px;
            }
         }
      }
      td {
         position: relative;
         .validator-collapse {
            position: absolute;
            z-index: 2;
            top: 71%;
         }
         // vertical-align: top !important;
      }
   }
}

.sensitivity-labels-config {
   .settings-sensitivity-toggle {
      width: 400px;
      label {
         padding-right: 0 !important;
      }
   }
   .sync-check,
   .alert-not-matching {
      padding: 0;
   }
   .label-toggle {
      width: 100px;
      white-space: break-spaces !important;
   }
   .icon-tr {
      width: 70px;
   }
   .icon-selector {
      .form-dropdown {
         width: 60px;
         .dd-header-title {
            .header-text {
               display: none;
            }
         }
         &.open {
            .dd-list {
               padding-top: 0;
               width: 300px;
               border-radius: 0 4px 0 0;
               .searchbox {
                  border-radius: 0 4px 0 0;
               }
            }
         }
      }
   }
}
