.fadein-2s {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@mixin fadein($seconds) {
    .fadein-#{$seconds} {
        -webkit-animation: fadein $seconds; /* Safari, Chrome and Opera > 12.1 */
           -moz-animation: fadein $seconds; /* Firefox < 16 */
            -ms-animation: fadein $seconds; /* Internet Explorer */
             -o-animation: fadein $seconds; /* Opera < 12.1 */
                animation: fadein $seconds;
    }
    
    @keyframes fadein-#{$seconds} {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    
    /* Firefox < 16 */
    @-moz-keyframes fadein-#{$seconds} {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein-#{$seconds} {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    
    /* Internet Explorer */
    @-ms-keyframes fadein-#{$seconds} {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    
    /* Opera < 12.1 */
    @-o-keyframes fadein-#{$seconds} {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
}

@include fadein(1s);
@include fadein(500ms);


@keyframes fadeinskel {
   from { opacity: 0.3; }
   to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeinskel {
   from { opacity: 0.3; }
   to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinskel {
   from { opacity: 0.3; }
   to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeinskel {
   from { opacity: 0.3; }
   to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeinskel {
   from { opacity: 0.3; }
   to   { opacity: 1; }
}

@mixin fadeinskel($seconds) {
   .fadeinskel-#{$seconds} {
       -webkit-animation: fadeinskel $seconds; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadeinskel $seconds; /* Firefox < 16 */
           -ms-animation: fadeinskel $seconds; /* Internet Explorer */
            -o-animation: fadeinskel $seconds; /* Opera < 12.1 */
               animation: fadeinskel $seconds;
   }
   
   @keyframes fadeinskel-#{$seconds} {
       from { opacity: 0.3; }
       to   { opacity: 1; }
   }
   
   /* Firefox < 16 */
   @-moz-keyframes fadeinskel-#{$seconds} {
       from { opacity: 0.3; }
       to   { opacity: 1; }
   }
   
   /* Safari, Chrome and Opera > 12.1 */
   @-webkit-keyframes fadeinskel-#{$seconds} {
       from { opacity: 0.3; }
       to   { opacity: 1; }
   }
   
   /* Internet Explorer */
   @-ms-keyframes fadeinskel-#{$seconds} {
       from { opacity: 0.3; }
       to   { opacity: 1; }
   }
   
   /* Opera < 12.1 */
   @-o-keyframes fadeinskel-#{$seconds} {
       from { opacity: 0.3; }
       to   { opacity: 1; }
   }
}

@include fadeinskel(1s);
@include fadeinskel(500ms);