@import "app/assets/common/bootstrap-media-mixins";

@include media-breakpoint-down(xs) {
   .team-information-standard-section {
      display: none;
   }
}

.team-information-standard-section {
   .top-info {
      display: flex;
      align-items: center;
      .name-and-logo {
         display: flex;
         flex: 1;
         .top-stats {
            display: flex;
            align-items: center;
            .ts-left {
               flex: 1;
               display: flex;
               flex-direction: column;

               .ws-property-bar {
                  margin-top: 10px;
                  display: flex;
               }
            }
            .ts-right {
               padding-left: 20px;
            }
         }
      }
   }
   .bottom-portlet-body {
      box-shadow: 0 4px 4px -4px rgb(222, 222, 222) inset;
      border-radius: 0 0 4px 4px !important;
      border-top: 1px solid rgb(232, 232, 232);
      background-color: #f8f8f854;
   }
   .bottom-stats-area {
      .bottom-stats-col {
         &:first-child {
            border-right: 1px solid #ebebeb;
         }
         &:last-child {
            padding-left: 20px;
         }
      }
   }
}
