.recommendation-card-skeleton {
   border: 1px solid var(--border) !important;
   cursor: pointer;
   display: flex;
   justify-content: space-between;
   flex-direction: row !important;
   height: 146px;

   .left-section {
      display: flex;
      flex-direction: column;
      .badge-section {
         align-items: center;
         margin-top: 23px;
         display: flex;
      }
   }

   .right-section {
      display: flex;
      align-items: center;
   }
}
