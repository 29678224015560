.recommendation-top-categories-skeleton {
   display: flex;
   flex-direction: column;
   border: 1px var(--border) solid !important;

   .sections {
      display: flex;
      gap: 48px;
      margin-top: 19.75px;

      .left-section,
      .right-section {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         gap: 8px;
         width: 100%;

         .group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .category {
               display: flex;
               align-items: center;
            }
         }
      }
   }
}
