/* The switch - the box around the slider */

.form-orch-toggle {
   .form-control.form-toggle-control {
      height: calc(1.5em + 1.4rem + 2px);
      padding: 0.75rem 7rem 0.75rem 0.75rem;
      height: auto;
      position: relative;
      padding-left: calc(1rem + 70px);
      span {
         font-weight: 400;
      }
      .form-toggle {
         position: absolute;
         left: 1rem;
         top: 50%;
         margin-top: -11.5px;
      }
   }
   &.no-border {
      .form-control.form-toggle-control {
         padding-top: 0;
         padding-bottom: 0;
         margin-left: -1rem;
      }
      .form-control {
         border: 0px solid transparent;
      }
   }
   &.strong-text {
      .form-control.form-toggle-control {
         span {
            font-weight: 500;
         }
      }
   }
   &.no-margin-bottom {
      margin-bottom: 0;
      .form-control {
         margin-bottom: 0;
      }
   }
}
