.recommendations-list-no-results {
   display: inline-flex;
   justify-content: center;
   width: 100%;
   .no-results-body {
      .orchestry-featured-icon {
         margin-bottom: 20px;
      }
      p:nth-child(2) {
         margin-bottom: 8px;
      }
      p:nth-child(3) {
         margin-bottom: 32px;
      }

      display: flex;
      justify-items: center;
      align-items: center;
      flex-direction: column;
      margin: 72px 300px;
      p {
         text-align: center;
      }
      .buttons {
         display: flex;
         justify-content: center;
         gap: 8px;
      }
   }
}
