.root {
   transition: all 0.5s ease;

   &[data-variant="no-border"] {
      border: none;
      box-shadow: none;
   }

   &[data-variant="shadow-sm"] {
      border: none;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
   }

   &[data-variant="shadow-md"],
   &[data-variant="shadow"],
   &[data-variant="shadow-border"] {
      border: none;
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
   }

   //Mantine's default card in index.css seems to be overriding anything we put in .root, so we have to
   //use this default variant to override it.
   &[data-variant="default"],
   &[data-variant="hover"],
   &[data-variant="selected"],
   &[data-variant="shadow-border"],
   &[data-padding-size="lg"] {
      border-radius: 8px;
      border: 1px solid var(--border);
      overflow: hidden;
      padding: 16px 24px;
   }

   &[data-padding-size="lg"] {
      padding: 24px 32px;
   }

   &[data-variant="stats-card"],
   &[data-variant="stats-card-link"],
   &[data-variant="selected"] {
      padding: 16px 24px;
   }

   &[data-variant="stats-card"],
   &[data-variant="stats-card-link"],
   &[data-variant="stats-card-hover"] {
      border: 1px solid var(--Border, #e5e7eb);
   }

   &[data-variant="stats-card-hover"] {
      &:hover {
         border: 1px solid var(--mantine-color-primary-4, #99c9cf);
      }
   }

   &[data-variant="stats-card-link"] {
      user-select: none;
      cursor: pointer;
      &:hover {
         border: 1px solid var(--mantine-color-primary-4, #99c9cf);
      }
   }
   &[data-variant="stats-card-selected"] {
      user-select: none;
      border: 1px solid var(--mantine-color-primary-9, #1a8694);
   }
}
