.configuration-tab {
   .form-group {
      label {
         font-size: 15px;
         font-weight: 500;
         color: #4e4e4e;
      }
      .form-check {
         label {
            font-size: 14px;
            margin-left: 15px;
         }
      }
      .form-dropdown {
         .dd-list-item,
         .form-control {
            font-size: 14px;
         }
      }
   }
   .bounding-box {
      background-color: rgb(243, 243, 243);
      padding: 15px 15px 1px 15px;
      border-radius: 5px;
      .form-group {
         margin-bottom: 1em;
         label {
            font-size: 13px;
            font-weight: 500;
            color: #4e4e4e;
         }
      }
   }
}
