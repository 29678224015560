.lifecycle-wizard {
   .lifecycle-wizard-wrapper {
      background-color: white;
      padding: 25px;
      border-radius: 5px;

      .w-header {
         display: flex;
         position: relative;

         // border-bottom: 1px solid #e1e7ee;
         // padding-bottom: 20px;
         // margin-bottom: 35px;
         position: relative;

         .step-block {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            position: relative;
            .icon {
               height: 32px;
               width: 32px;
               border: 1px solid #e1e7ee;
               border-radius: 50%;
               margin-bottom: 8px;
               position: relative;
               transition: all 0.3s;
               i {
                  opacity: 0;
               }
            }
            .text {
               font-weight: 500;
               font-size: 12px;
               color: #718690;
            }
            .errors-counter {
               position: absolute;
               z-index: 2;
               top: -4px;
               left: calc(50% + 6px);

               // zoom: 0.8;
               .form-validator-rollup {
                  margin: 0;
                  font-weight: bold;
                  line-height: 14px;
                  i {
                     display: none;
                  }
                  span {
                     font-size: 11px;
                  }
               }
            }
            .dotted-lines {
               display: flex;
               position: absolute;
               width: calc(100% - 50px);
               left: calc(50% + 25px);
               top: 5px;
               .dotted-line {
                  flex: 1;
                  display: flex;
                  .a-dot {
                     flex: 1;
                     text-align: center;
                     align-items: center;
                     justify-content: center;
                     &::after {
                        content: "";
                        display: inline-block;
                        width: 4px;
                        border-radius: 50%;
                        height: 4px;
                        background-color: #e1e7ee;
                     }
                  }
               }
            }
            &.selected {
               .icon {
                  background-color: #d7e9ec;
                  &:after {
                     content: "";
                     width: 8px;
                     height: 8px;
                     background-color: #007989;
                     border-radius: 50%;
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     margin-left: -4px;
                     margin-top: -4px;
                  }
               }
            }
            &.complete {
               .icon {
                  background-color: #007989;
                  border: none;
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  cursor: pointer;
                  i {
                     // display: block;
                     opacity: 1;
                     color: white;
                     text-align: center;
                     align-self: center;
                  }
               }
            }
            &.never-been-current {
               .errors-counter {
                  display: none;
               }
            }
         }
      }

      .w-body {
         margin-top: 40px;
         padding-left: 6%;
         padding-right: 6%;
         .cards {
            .step-card {
               display: none;
               &.selected {
                  display: block;
               }
            }
         }
         .bottom-buttons {
            margin-top: 50px;
         }
      }
   }
}
