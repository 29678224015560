.form-webhook {
   .sub-title {
      color: #414141;
      cursor: default;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 5px;
   }
   .test-response {
      margin-top: 15px;
   }
   button {
      border: 0px solid transparent;
      background-color: #0abb87;
      border-color: #0abb87;
      color: white;
      font-weight: 500;
      border-radius: 4px;
      padding: 4px 10px;
      margin-bottom: 5px;
      margin-top: 10px;
      &.clipboard-button {
         i {
            margin-right: 5px;
         }
      }
   }
   .wh-example-wrapper {
      padding-top: 0px;
      > .sub-title {
         margin-top: 30px;
      }
      > button {
         margin-top: 5px;
         margin-bottom: 10px;
      }
   }
   .webhook-example {
      background-color: #f5f5f5;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
      code {
         background-color: #f5f5f5;
         .key {
            color: rgb(65, 65, 160);
         }
      }
   }
   .url-input {
      button {
         display: flex;
         justify-content: center;
         i {
            margin-right: 6px;
            font-size: 16px;
            position: relative;
            top: 1px;
         }
         .animated-loader {
            margin-right: 6px;
            height: 16px;
            width: 16px;
         }
      }
   }
   .webhook-area {
      margin-bottom: 35px;
   }

   .client-secret-area {
      margin-bottom: 25px;
      .form-group {
         margin-bottom: 0;
      }
      .client-secret-enabled {
         p {
            font-weight: 400;
         }
      }
   }
}
