.recommendation-tabs-skeletons {
   display: flex;
   gap: 8px;

   .tab {
      align-items: center;
      display: flex;
      padding: 12px;
   }
}
