.lifecycle-logs {
   .log-name {
      font-weight: 500;
      cursor: pointer;
   }
   .kt-portlet__body {
      // padding: 0px 16px 25px 16px !important;
      .table-list {
         margin: -25px -10px -25px -10px;
      }
      table {
         th {
            font-weight: 500 !important;
         }
      }
   }
}
