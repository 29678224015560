.root {
   font-size: 16px;
}

.list {
   &[data-variant="pills"] {
      display: flex;
      gap: 8px;
      &::before {
         height: 0;
         border: none;
      }
   }
}

.tab {
   transition: all 0.3s;

   &[data-variant="pills"] {
      border: none;
      padding: 8px 12px;
      border-radius: 48px;
      color: var(--mantine-color-gray-7);
      font-size: var(--mantine-font-size-sm);
      line-height: var(--mantine-line-height-sm);
      background-color: var(--mantine-color-gray-0);
      &:hover {
         background-color: var(--mantine-color-gray-1);
      }
      &[data-active="true"] {
         &[data-isdeactivated="false"] {
            transition: all 200ms ease-in;
            color: var(--white);
            background-color: var(--mantine-color-primary-8);
            &:hover {
               background-color: var(--mantine-color-primary-7);
            }
            div {
               color: var(--mantine-color-primary-9);
            }
         }
      }
   }

   &[data-variant="default"] {
      border-radius: 0;
      color: var(--mantine-color-gray-5);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;

      &[data-active="true"] {
         background-color: var(--mantine-color-primary-0);
         color: var(--mantine-color-primary-9);
         font-weight: 500;
      }
   }
}

.tabLabel {
   display: inline-flex;
   align-items: center;
   gap: 4px;
}
