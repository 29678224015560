.directory-list-table {
   // overflow-x: scroll;
   .ScrollbarsCustom {
      transition: all 0.3s;
   }
   .loading-skeleton {
      .header {
         background: rgba(238, 241, 245, 0.75);
         height: 45px;
         border-radius: 5px;
      }
      .items {
         .item {
            display: flex;
            padding: 15px 0px 15px 20px;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #eef1f5;
            > div {
               padding: 0 0px 0 20px;
            }
            .pic {
               .circle {
                  height: 42px;
                  width: 42px;
                  border-radius: 4px;
                  background: rgba(238, 241, 245, 0.75);
               }
            }
            .title {
               width: 300px;
               .title-box {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 21px;
               }
            }
            .property {
               flex: 1;
               .property-box {
                  background: rgba(238, 241, 245, 0.75);
                  border-radius: 5px;
                  height: 21px;
               }
            }
         }
      }
      .animated-wave {
         overflow: hidden;
         position: relative;
         &:before {
            content: "";
            display: block;
            position: absolute;
            left: -150px;
            top: 0;
            height: 100%;
            width: 150px;
            background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
            animation: load 2s cubic-bezier(0.4, 0, 0.2, 1);
            animation-iteration-count: infinite;
         }
         @keyframes load {
            from {
               left: -150px;
            }
            to {
               left: 100%;
            }
         }
      }
   }
   table {
      // width: 100%;
      // table-layout: fixed;
      min-width: 100%;
      border-collapse: inherit !important;
      border-spacing: 0px;
      transition: all 0.2s;
      position: relative;

      thead {
         tr {
            td {
               padding: 0;
               margin: 0;
               // width: 100%;
               background: rgba(238, 241, 245, 0.75);
               border-top: 1px solid #e1e7ee;
               border-bottom: 1px solid #e1e7ee;

               > div {
                  // box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
                  padding: 12px 12px 12px 0;
                  font-weight: 500;
                  vertical-align: top;
                  position: relative;
                  > span {
                     vertical-align: top;
                     color: #26323a;
                     cursor: pointer;
                  }
                  .sort {
                     opacity: 0.3;
                     margin-left: 8px;
                     vertical-align: middle;
                     position: relative;
                     width: 12px;
                     transition: all 0.3s;
                     position: absolute;
                     // right:0;
                     top: 50%;
                     height: 0;
                     display: inline-block;

                     .asc {
                        position: absolute;
                        // top:-18px;
                        top: -12px;
                        line-height: 10px;
                        color: #9da9ae;
                        &:hover {
                           color: rgb(127, 138, 143);
                        }
                     }
                     .desc {
                        position: absolute;
                        // bottom:-18px;
                        bottom: -10px;
                        line-height: 10px;
                        color: #9da9ae;
                        &:hover {
                           color: rgb(127, 138, 143);
                        }
                     }
                     &.current-sort {
                        opacity: 1;
                        .asc,
                        .desc {
                           color: #b8c3c7;

                           &.current-direction {
                              color: rgb(114, 114, 241);
                           }
                        }
                     }
                  }
                  &:hover {
                     .sort {
                        opacity: 1;
                     }
                  }
               }

               &.sort-disabled {
                  > div > span {
                     cursor: default;
                  }
               }

               &:first-child {
                  // > div {
                  border-radius: 5px 0 0 5px;
                  border-left: 1px solid #e1e7ee;
                  // }
               }
               &:last-child {
                  // > div {
                  border-radius: 0px 5px 5px 0px;
                  border-right: 1px solid #e1e7ee;
                  // }
               }
               &.column-user {
                  text-align: center;
               }
               &.column-guest-name,
               &.column-workspace-name {
                  max-width: 300px;
                  min-width: 250px;
                  // position: relative;
                  // width: 250px;
                  padding-left: 40px;
                  > div {
                     display: inline-block;
                  }
                  .pin-first-column-wrap {
                     width: 0px;
                     position: relative;
                     // position: absolute;
                     // left:0;
                     // top: 0;
                     // height: 100%;
                     // display: table-cell;
                     .pin-first-column {
                        display: block;
                        position: relative;
                        left: -20px;
                        // top: 23px;
                        // margin-top: -10px;
                        cursor: pointer;
                        transition: all 0.3s;
                        i {
                           font-size: 19px;
                           color: #cbd5e0;
                           transform: rotate(-135deg);
                           transition: all 0.3s;
                           &:hover {
                              color: rgb(105, 105, 175);
                           }
                        }
                     }
                  }
               }
               &.column-description {
                  min-width: 200px;
                  transition: all 0.3s;
                  > div {
                     padding-left: 0;
                  }
               }
               &.column-custom-link {
                  min-width: 200px;
                  transition: all 0.3s;
                  > div {
                     padding-left: 0;
                  }
               }
               &.column-user {
                  text-align: center;
                  min-width: 100px;
                  width: 100px;
                  > div {
                     // width:100px;
                  }
               }
               &.column-template-type {
                  min-width: 170px;
                  width: 170px;
                  > div {
                     padding-left: 0;
                  }
               }
               &.column-text {
                  min-width: 170px;
                  width: 170px;
                  > div {
                     padding-left: 0;
                  }
               }
               &.column-privacy {
                  text-align: center;
                  min-width: 90px;
                  width: 90px;
                  > div {
                     // width:100px;
                  }
               }
               &.column-custom-date {
                  min-width: 100px;
               }
               &.column-custom-choice-single {
                  min-width: 150px;
               }
               &.column-custom-choice-multiple {
                  min-width: 150px;
               }
               &.column-custom-text-single {
                  min-width: 200px;
               }
               &.column-custom-text-multiple {
                  min-width: 300px;
               }
               &.column-custom-managed-metadata-single,
               &.column-custom-managed-metadata-multiple {
                  min-width: 150px;
               }
            }
         }
      }
      tbody {
         tr {
            &:hover {
               background: rgba(244, 245, 247, 0.5);
               border-radius: 4px;
            }
            td {
               border-bottom: 1px solid #eef1f5;
               vertical-align: middle;
               font-family: TT Norms;
               font-style: normal;
               font-weight: 400;
               font-size: 12px;
               line-height: 20px;
               padding: 15px 15px 15px 0;
               color: #718690;
               a {
                  color: #56689a;
               }
               &.column-type-workspacename {
                  > div {
                     display: flex;
                     align-items: center;
                     .fav-star {
                        padding: 0 10px;
                        .star-on {
                           display: none;
                           color: #fab32f;
                        }
                        .star-off {
                           display: block;
                           color: #cad4df;
                        }
                        &.on {
                           display: block;
                           .star-on {
                              display: block;
                           }
                           .star-off {
                              display: none;
                           }
                        }
                        .star-icon {
                           i {
                              cursor: pointer;
                              font-size: 20px;
                           }
                        }
                     }

                     .profile-icon {
                        display: block;
                        position: relative;
                        .profile-picture {
                           background: #ffffff;
                           border: 1px solid #e1e7ee;
                           width: 40px;
                           height: 40px;
                           box-sizing: content-box;
                           background-size: cover;
                           background-position: center center;
                           border-radius: 4px;
                           line-height: 44px;
                           .img {
                              top: 0;
                              left: 0;
                              width: 100%;
                              height: 100%;
                           }
                        }
                        .hidden-workspace-icon {
                           position: absolute;
                           right: -3px;
                           bottom: -1px;
                           width: 19px;
                           height: 19px;
                           background-color: #e1e7ee;
                           border-radius: 50%;
                           i {
                              width: 19px;
                              text-align: center;
                              font-size: 12px;
                              opacity: 0.5;
                           }
                        }
                     }
                     .name-wrapper {
                        margin-left: 12px;
                        width: 200px;
                        word-spacing: nowrap;
                        padding-right: 15px;
                        flex: 1;
                        span {
                           font-family: TT Norms;
                           font-style: normal;
                           font-weight: bold;
                           font-size: 14px;
                           line-height: 20px;
                           color: #56689a;
                        }
                     }
                  }
               }
               &.column-type-text {
                  max-width: 200px;
                  p {
                     margin: 0;
                  }
               }
               &.column-type-description {
                  max-width: 200px;
                  color: #718690;
                  font-weight: 400;
                  transition: all 0.3s;
                  p {
                     margin: 0;
                     margin-right: 15px;
                  }
               }
               &.column-type-templatetype {
                  font-size: 14px;
                  width: 185px;
                  // padding-right:0;
                  > div {
                     display: flex;
                     align-items: center;
                     .cat-icon {
                        margin-right: 10px;
                        width: 20px;
                        min-width: 20px;
                        img {
                           max-width: 100%;
                        }
                     }
                     .cat-title {
                        .cat-title-text {
                           font-weight: 500;
                           font-size: 12px;
                        }
                        .cat-title-privacy {
                           font-size: 12px;
                           opacity: 0.6;
                        }
                     }
                  }
               }
               &.column-type-user {
                  text-align: center;
                  // padding: 0;
                  width: 100px;
                  > div > span > .profile-picture {
                     width: 32px;
                     height: 32px;
                     &.icon {
                        line-height: 36px;
                        svg {
                           width: 13px;
                        }
                     }
                     &.two-letter {
                        &.size-xs {
                           line-height: 32px;
                        }
                     }
                  }
               }
               &.column-type-privacy {
                  text-align: center;
                  padding: 0;
                  img {
                     // width: 23px;
                  }
               }

               &.column-type-customlink {
                  max-width: 200px;
                  .links-group {
                     width: 100%;
                     .link {
                        width: 100%;
                        display: flex;
                        gap: 6px;
                        color: rgba(0, 121, 137, 0.5);
                        align-items: center;
                     }
                  }
               }
            }

            &.no-results {
               > td {
                  padding: 60px 0;
               }
            }
         }
      }

      &.loading-from-server {
         opacity: 0.6;
         &:before {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 99;
         }
      }
   }

   &.fixed-first-column {
      position: relative;
      &:after {
         content: "";
         position: absolute;
         width: 20px;
         top: 0;
         // bottom:0;
         height: 100%;
         right: 0;
         box-shadow: inset -13px 0px 11px -18px #00000030;
      }
      table {
         thead {
            tr {
               position: relative;
               transform: scale(1);
               padding-left: 340px;
               &:before {
                  content: "";
                  padding-left: 340px;
                  padding-left: 340px;
                  display: table-cell;
                  background: rgba(238, 241, 245, 0.75);
                  border-top: 1px solid #e1e7ee;
                  border-bottom: 1px solid #e1e7ee;
               }
               td:first-child {
                  width: 340px;
                  background-color: #f2f5f8;
                  left: 0;
                  max-width: 340px;
                  position: absolute;
                  z-index: 3;
                  top: 0px;
                  bottom: 0px;
                  display: flex;
                  align-items: center;
                  // background-color: white;
                  &:before {
                     // content:"";
                     position: absolute;
                     right: 0px;
                     top: -1px;
                     bottom: 0;
                     width: 1px;
                     // background-color: black;
                     box-shadow: 1px 0 0 0 #0c0c0c12;
                     // box-shadow:inset 1px 0 7px -7px #26323A;
                  }
                  &:after {
                     content: "";
                     position: absolute;
                     right: -15px;
                     top: 0;
                     bottom: 0;
                     width: 15px;
                     // box-shadow: inset 13px 0px 7px -7px #26323a14;
                     box-shadow: inset 13px 0px 11px -18px #00000030;
                  }
                  // background-color: yellow;
                  padding-left: 40px;
                  > div {
                     // height: 100%;

                     padding-left: 0;
                     .sort {
                        position: relative;
                        top: auto;
                        margin-top: 3px;
                     }
                  }
                  .pin-first-column {
                     // left:10px;
                     // top: 23px;
                     // margin-top: -10px;
                     i {
                        transform: rotate(-90deg);
                        color: rgb(105, 105, 175);
                        // transform: rotate(-135deg);
                     }
                  }
               }
               td:nth-child(2) {
                  // background: red;
                  padding-left: 15px;
                  width: auto;
               }
               td {
                  &.column-description {
                     min-width: 260px;
                     > div {
                        padding-left: 0;
                     }
                  }
                  &.column-custom-link {
                     min-width: 260px;
                     > div {
                        padding-left: 0;
                     }
                  }
               }
            }
         }
         tbody {
            tr {
               position: relative;
               transform: scale(1);
               padding-left: 340px;
               &:before {
                  content: "";
                  padding-left: 340px;
                  width: 340px;
                  display: table-cell;
                  border-bottom: 1px solid #eef1f5;
               }
               td:first-child {
                  max-width: 340px;
                  width: 340px;
                  position: absolute;
                  z-index: 3;
                  top: 0px;
                  left: 0;
                  bottom: 0px;
                  background-color: white;
                  > div {
                     height: 100%;
                  }
                  &:before {
                     // content:"";
                     position: absolute;
                     right: 0px;
                     top: -1px;
                     bottom: 0;
                     width: 1px;
                     // background-color: black;
                     box-shadow: 1px 0 0 0 #0c0c0c12;
                     // box-shadow:inset 1px 0 7px -7px #26323A;
                  }
                  &:after {
                     content: "";
                     position: absolute;
                     right: -15px;
                     top: 0;
                     bottom: 0;
                     width: 15px;
                     // box-shadow: inset 13px 0px 10px -7px #26323a14;
                     box-shadow: inset 13px 0px 11px -18px #00000030;
                  }
               }
               td:nth-child(2) {
                  // background: red;
                  padding-left: 15px;
                  // margin-left: 360px;
                  min-width: 260px;
                  max-width: 260px;
                  > div {
                     padding-left: 0;
                  }
               }
               td {
                  &.column-description {
                  }
                  &.column-custom-link {
                  }
               }
            }
         }
      }
   }
}
