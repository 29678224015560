.dropdown {
   &[data-variant="selected-value"] {
      width: max-content;
      cursor: pointer;
   }
}

.option {
   transition: all 0.3s;
   font-family: var(--mantine-font-family);
   font-weight: var(--light-font-weight);
   color: var(--mantine-color-gray-9);
   width: 100%;

   &:hover {
      background-color: var(--mantine-color-primary-0);
      color: var(--mantine-color-primary-7);
   }

   &[data-variant="selected-value"] {
      display: flex;

      &:hover {
         background-color: var(--mantine-color-gray-0);
         color: var(--mantine-color-gray-9);
      }

      &[data-combobox-active="true"] {
         display: flex;
         justify-content: space-between;
         align-items: center;
         gap: 8px;
         background-color: var(--mantine-color-primary-0);
         i {
            color: var(--mantine-color-primary-6);
         }
      }
   }
}
