.root {
   &[data-disabled="true"] {
      color: var(--mantine-color-gray-6) !important;
   }

   &[data-color-variant="error"] {
      color: var(--mantine-color-error-6);
      font-size: var(--input-error-size);
      font-weight: 300;
   }

   &[data-color-variant="urgent"] {
      color: var(--mantine-color-error-8);
   }

   &[data-color-variant="important"] {
      color: var(--mantine-color-warning-8);
   }

   &[data-color-variant="bestpractice"] {
      color: var(--mantine-color-blue-8);
   }

   &[data-color-variant="resolvedtotal"] {
      color: var(--mantine-color-pink-6);
   }

   &[data-color-variant="new"] {
      color: var(--mantine-color-success-6);
   }

   &[data-color-variant="count"] {
      color: var(--mantine-color-success-6);
   }

   &[data-color-variant="text-secondary"] {
      color: var(--mantine-color-text-secondary-0);
   }

   &[data-color-variant="security"] {
      color: var(--mantine-color-pink-8);
   }

   &[data-color-variant="architecture"] {
      color: var(--mantine-color-purple-8);
   }

   &[data-color-variant="adoption"] {
      color: var(--mantine-color-orange-8);
   }

   &[data-color-variant="costsaving"] {
      color: var(--mantine-color-magenta-8);
   }

   &[data-color-variant="guests"] {
      color: var(--mantine-color-blue-8);
   }

   &[data-color-variant="communications"] {
      color: var(--mantine-color-success-8);
   }

   &[data-color-variant="show-all"] {
      color: var(--mantine-color-primary-9);
   }

   &[data-color-variant="governance"] {
      color: var(--mantine-color-brown-8);
   }

   &[data-color-variant="storage"] {
      color: var(--mantine-color-success-8);
   }

   &[data-color-variant="sprawl"] {
      color: var(--mantine-color-primary-8);
   }
}
