.cosmosdb-setup {
   .options-area {
      display: flex;
      // margin-bottom: 20px;
      // align-items: center;
      &.add-custom-cosmos {
         margin-bottom: 25px;
         padding-bottom: 25px;
         border-bottom: 1px solid #ebedf2;
      }
      .left {
         min-width: 48px;
         img {
            width: 35px;
         }
      }
      .right {
         padding-left: 15px;
         .server-type {
            background-color: rgb(213, 213, 255);
            color: rgb(68, 68, 68);
            font-weight: 500;
            display: inline-block;
            padding: 2px 10px;
            border-radius: 3px;
            margin-bottom: 8px;
         }
         .text {
            color:rgb(39, 39, 39);
            font-weight: 400;
         }
         .setup-custom {
            margin-top: 10px;
            // color: #007989 !important;
            // font-weight: 600;
            > button {
               margin-left: 0 !important;
            }
            .subtitle-button {
               margin-left: 0 !important;
               font-size: 0.875rem !important;
            }
         }
      }
   }

   .wp-item {
      margin-bottom: 30px;
      .step-info {
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         .step-num-icon {
            display: flex;
            .number {
               width: 24px;
               height: 24px;
               border-radius: 4px 0 0 4px;
               background-color: rgba(46, 160, 44, 0.5);
               display: flex;
               align-items: center;
               justify-content: center;
               flex-direction: column; /* <--- I added this */
               color: white;
               font-weight: 500;
               font-size: 14px;
            }
            .icon {
               width: 24px;
               height: 24px;
               border-radius: 0 4px 4px 0;
               display: flex;
               align-items: center;
               border: rgba(46, 160, 44, 0.5) solid 1px;
               i {
                  margin: 0 auto;
                  color: rgba(46, 160, 44, 0.5);
                  font-size: 14px;
                  &.icon-failed {
                     display: none;
                  }
                  &.icon-undetermined {
                     display: none;
                  }
               }
            }
         }
         .step-name {
            padding-left: 15px;
            font-size: 15px;
            font-weight: 500;
            color: #646c9a;
         }
         
      }
      .step-content {
         font-size: 12px;
         padding-left: 63px;
         .sub-step-name {
            // padding-left: 15px;
            font-size: 13px;
            font-weight: 500;
            color: #474a5a;
         }

         .text {
            font-size: 13px;
            color: black;
            margin-bottom: 10px;
         }
         .subtitle-button {
            margin-left: 0 !important;
            font-size: 0.875rem !important;
         }

         .worked {
            i {
               font-size: 14px;
               color:rgba(46, 160, 44, 0.5);
            }
            span {
               margin-left: 10px;
               font-weight: 500;
               font-size: 14px;
            }
         }
         
      }

      &.failed {
         .step-info {
            .step-num-icon {
               .number {
                  background-color: #e06363;
               }
               .icon {
                  border: #e06363 solid 1px;
                  i {
                     color: #e06363;
                     &.icon-success {
                        display: none;
                     }
                     &.icon-failed {
                        display: block;
                     }
                  }
               }
            }
         }
      }
      &.undetermined {
         .step-info {
            .step-num-icon {
               .number {
                  background-color: #8e9ae0;
               }
               .icon {
                  border: #8e9ae0 solid 1px;
                  i {
                     color: #8e9ae0;
                     font-size: 12px;
                     &.icon-success {
                        display: none;
                     }
                     &.icon-undetermined {
                        display: block;
                     }
                  }
               }
            }
         }
      }
   }
}