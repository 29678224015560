@import "app/assets/common/bootstrap-media-mixins";
.lifecycle-workspace-details {
   margin-bottom: 32px;
   h3 {
      // margin-bottom: 20px;
   }

   .overview-content {
      .metadata-content {
         padding-top: 25px;
         .metadata-title {
            border-bottom: 1px solid #e1e7ee;
            color: #26323a;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
            display: flex;
            gap: 1.5rem;
            .metadata-item-edit {
               i {
                  width: 12px;
                  height: 12px;
                  cursor: pointer;
               }
            }
         }
         .container {
            margin: 0;
            padding: 0;
            .meta-row {
               margin-bottom: 1rem;
            }
         }
         .metadata-loader {
            display: grid;
            grid-template-columns: repeat(3, minmax(min-content, 1fr));
            gap: 1rem;
         }

         .metadata-container {
            padding-top: 8px;
            display: grid;
            grid-template-columns: repeat(3, minmax(min-content, 1fr));
            gap: 1rem;
            color: #26323a;
            .metadata-item-title {
               color: #718690;
               font-weight: 500;
            }
            .metadata-item {
               display: flex;
               flex-direction: column;
               .metadata-item-value {
                  padding: 0 5px 0 0;
                  color: #26323a;
                  font-weight: 500;
                  align-items: center;

                  .links-group {
                     .link {
                        display: flex;
                        gap: 6px;
                        color: rgba(0, 121, 137, 0.5);
                        align-items: center;
                     }
                  }
               }
            }
            .managed-metadata-list {
               list-style: none;
               padding: 0;
            }
         }
      }
      display: flex;
      .left {
         width: 64px;
         padding-right: 40px;
         .profile-picture {
            height: 40px;
            width: 40px;
            border: 1px solid #e1e7ee;
            border-radius: 4px;
            &.icon {
               line-height: 48px;
               svg {
                  height: 20px;
                  width: 20px;
               }
            }
         }
         .ws-activity {
            margin-top: 10px;
            .ws-activity-items {
               // zoom: 0.94;
               zoom: 1;
               display: inline-flex;
               .activity-icon {
                  padding: 6px 5px;
                  background: #6767679e;
                  line-height: 12px;

                  &:last-child {
                     border-radius: 0 5px 5px 0;
                     padding-right: 10px;
                  }
                  &:first-child {
                     border-radius: 5px 0 0 5px;
                     padding-left: 10px;
                  }
                  &:only-child {
                     border-radius: 5px;
                  }
                  i {
                     // font-size: 15px;
                     // color: #676767;
                     font-size: 15px;
                     color: #6767678a;
                  }

                  &.days-60,
                  &.days-30 {
                     i {
                        opacity: 0.4;
                     }
                  }
               }
            }
         }
      }
      .right {
         flex-grow: 1;
         margin-top: -7px;
         .tags {
            .tag {
               border: 1px solid #94bf2d;
               background-color: rgba(148, 191, 45, 0.1);
               color: #94bf2d;
               padding: 4px 14px 4px 14px;
               border-radius: 6px;
               margin-right: 5px;
               margin-top: 20px;
               margin-bottom: 0px;
               display: inline-block;
               font-weight: 400;
               &.inactive {
                  border: 1px solid #699fa1;
                  background-color: #699fa135;
                  color: #699fa1;
               }
            }
         }
         .top-stats {
            display: flex;
            .ts-left {
               flex: 1;
               .ws-title {
                  color: #26323a;
                  font-size: 24px;
                  font-weight: 600;
                  margin-bottom: 8px;
                  .link-span {
                     margin-left: 15px;
                     position: relative;
                     top: -2px;
                     padding: 2px;
                     cursor: pointer;
                     i {
                        font-size: 14px;
                        color: #8e9fa7;
                     }
                  }
               }
               .ws-description {
                  color: #718690;
                  font-size: 14px;
                  font-weight: 500;
               }
            }
            .ts-right {
               padding-left: 20px;
            }
         }

         .days-inactive {
            border: 1px solid #699fa1;
            background-color: #699fa135;
            color: #699fa1;
            padding: 4px 14px 4px 14px;
            border-radius: 6px;
            margin-right: 5px;
            margin-top: 20px;
            margin-bottom: 0px;
            display: inline-block;
            font-weight: 400;

            display: inline-flex;
            align-items: center;
            margin-top: 10px;
            // margin-bottom: 10px;
            .title {
               font-size: 14px;
               line-height: 16px;
               margin-bottom: 0px;
            }
            .number {
               font-size: 14px;
               // line-height: 32px;
               // color: #26323a;
               margin-left: 5px;
               font-weight: 500;
            }
         }

         .bottom-stats {
            margin-top: 24px;
            display: grid;
            // grid-auto-flow: column;
            grid-template-columns: repeat(4, minmax(0, 1fr));

            @include media-breakpoint-down(md) {
               grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            grid-gap: 15px;

            .stat {
               flex: 1;
               .stat-title {
                  color: #718690;
                  font-weight: 500;
                  margin-bottom: 0px;
               }
               .stat-content {
                  color: #26323a;
                  font-weight: 500;
                  margin-bottom: 2px;
                  display: flex;
                  align-items: center;
                  &.profile-stat {
                     display: flex;
                     align-items: center;
                     gap: 0.25rem;
                     margin-top: 0.25rem;
                  }
                  .template-type-icon {
                     img {
                        width: 16px;
                     }
                     margin-right: 8px;
                  }
                  .workspace-type-icon {
                     img {
                        width: 16px;
                        height: 16px;
                     }
                     margin-right: 8px;
                  }
                  .template-type {
                     flex-grow: 1;
                  }
                  .orch-template-icon {
                     img {
                        width: 16px;
                     }
                     margin-right: 8px;
                  }
                  .orch-template-title {
                     flex-grow: 1;
                  }
                  .created-with-icon {
                     i {
                        font-size: 14px;
                        color: rgba(0, 0, 255, 0.453);
                     }
                     img {
                        width: 16px;
                     }
                     margin-right: 8px;
                  }
                  .created-with-type {
                     flex-grow: 1;
                  }
               }
            }
         }

         .bottom-sharing-settings {
            padding-top: 25px;
            .sharing-title {
               color: #26323a;
               font-size: 14px;
               font-weight: 500;
               margin-bottom: 5px;
            }
            .sharing-content {
               display: grid;
               grid-template-columns: repeat(3, minmax(min-content, 1fr));
               grid-gap: 15px;
               padding-top: 8px;
               border-top: 1px solid #e1e7ee;
               .sharing-item {
                  padding: 5px 0;
                  .sharing-item-name {
                     color: #718690;
                     font-weight: 500;
                  }
                  .sharing-item-value {
                     padding: 0 5px 0 0;
                     color: #26323a;
                     font-weight: 500;
                     display: flex;
                     // height: 24px;
                     align-items: center;
                     i {
                        margin-right: 5px;
                        font-size: 16px;
                     }
                     .sharing-checkbox {
                        display: inline-block;
                        border: 1px solid #26323a;
                        height: 16px;
                        text-align: center;
                        width: 16px;
                        line-height: 14px;
                        margin-right: 5px;
                        border-radius: 2px;
                        i {
                           margin-right: 0;
                           font-size: 10px;
                           line-height: 12px;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
