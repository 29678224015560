.libraryfeature-details {
   .right-col {
      padding-top: 30px;
   }
   h3 {
      margin-top: 20px;
      margin-bottom: 20px;
   }

   .at-search {
      margin-bottom: 20px;
   }
   .at-count {
      margin-bottom: 10px;
   }
   .at-items {
      background-color: white;
      padding: 0 15px;
      border-radius: 4px;
      .selected-items li {
         font-weight: 600;
         padding: 12px 0 11px 0;
      }
      .no-templates {
         padding: 15px 0;
         .help-text {
            margin-bottom: 10px;
         }
      }
   }
   .kt-portlet__body {
      padding-top: 6px !important;
   }
   .nav-item {
      a {
         span {
            font-size: 16px;
         }
      }
   }
   .file-upload {
      max-width: 250px;
      margin-top: 15px;
   }
   .form-check {
      margin-top: 6px;
   }
   .loader {
      text-align: center;
   }
   .upload-area {
      display: flex;
      align-items: center;
      .left {
         flex-grow: 1;
      }
      .or {
         padding: 0 15px;
         font-weight: 500;
      }
      .right {
         flex-grow: 1;
         .select-extracted-file {
            border: 1px dashed #b5b5b5;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 20px 30px rgba(0, 69, 117, 0.1);
            padding: 40px 0 60px 0;
            outline: none !important;
            cursor: pointer;
            .icon {
               color: #ffa530;
               width: 28px;
               height: 28px;
               margin: 0 auto;
               i {
                  font-size: 36px;
                  width: 28px;
                  height: 28px;
               }
            }
            .text {
               padding-top: 15px;
               text-align: center;
               font-size: 14px;
               color: #7e7e7e;
               font-weight: 500;
            }
         }
      }
   }
   #feature-tabs-tabpane-configuration {
      label {
         font-weight: 500;
         margin-bottom: 20px;
      }
   }
}
