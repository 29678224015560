.upload-template-modal {
   z-index: 1060 !important;
   .advanced-configuration {
      .lists-to-clone {
         background-color: #e6e6e6;
         border-radius: 4px;
         padding: 15px;
         margin-bottom: 15px;
         .form-group {
            margin-bottom: 5px;
            &:last-child {
               margin-bottom: 0;
            }
         }
         .no-results {
            font-weight: 500;
         }
      }
      .toggle-title {
         font-weight: 500;
         margin-bottom: 10px;
         cursor: pointer;
         i {
            transition: all 0.3s;
            margin-right: 10px;
            transform: rotate(-90deg);
         }
      }
      &.show-advanced-configs {
         i {
            transform: rotate(0);
         }
      }
      .configs {
         background-color: #f7f7f7;
         border-radius: 4px;
         padding: 15px;
         > .form-group {
            margin-bottom: 20px;
         }
         label {
            font-weight: 500;
         }
         .checkbox-description {
            padding-left: 23px;
         }
         .list-col {
            .content {
               background-color: white;
            }
         }
      }
   }
   .instructions {
      font-weight: 500;
   }
   .pnp-schema {
      font-weight: 500;
   }
   .action-buttons {
      margin-top: 20px;
      text-align: right;
      .submit-button {
         &:disabled {
            cursor: default;
         }
      }
   }
}

.upload-template-modal-backdrop {
   z-index: 1050 !important;
}
