.disable-area {
   position: relative;
   &.is-disabled {
      cursor: not-allowed;
      &.cursor-loading {
         cursor: progress;
      }
      &.cursor-wait {
         cursor: wait;
      }
      .disable-text {
         background-color: rgba(255, 184, 34, 0.1);
         color: #da9a12;
         font-weight: 500;
         font-size: 11px;
         padding: 1px 4px;
         border: 1px solid rgba(121, 121, 46, 0.138);
         border-radius: 4px;
         margin-bottom: 3px;
         display: inline-block;
      }
      .form-check {
         position: relative;
         &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: -3px;
            background-color: white;
            z-index: 999;
            opacity: 0.7;
         }
      }
      .form-orch-toggle {
         position: relative;
         &:before {
            content: "";
            position: absolute;
            top: -3px;
            left: 0;
            right: 0;
            bottom: -3px;
            background-color: white;
            z-index: 999;
            opacity: 0.7;
         }
      }
      &.use-overlay {
         &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            z-index: 999;
            opacity: 0.4;
         }
      }
   }
}
