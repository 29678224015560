@import "app/assets/common/bootstrap-media-mixins";
.step-confirmation {
   padding-top: 40px;
   border-top: 1px solid #e1e7ee;
   @include media-breakpoint-down(sm) {
      margin: 0 0px;
      padding-top: 20px;
   }
   .confirmation-button-area {
      padding-bottom: 40px;
      @include media-breakpoint-down(sm) {
         padding-bottom: 30px;
      }
      &.bottom {
         padding-top: 30px;
      }
   }
   .area-details {
      vertical-align: top;
      .workspace-title {
         vertical-align: top;
         padding-bottom: 28px;
         @include media-breakpoint-down(sm) {
            padding-bottom: 10px;
         }
         .text {
            vertical-align: top;
            font-weight: 600;
            font-size: 17px;
            line-height: 19px;
            color: #26323a;
            margin-right: 15px;
         }
         .edit-button {
            top: -1px;
         }
      }
      .workspace-details {
         display: flex;
         @include media-breakpoint-down(sm) {
            display: block;
         }
         .logo {
            width: 56px;
            height: 56px;
            @include media-breakpoint-down(sm) {
               margin: 0 auto;
               height: 200px;
               width: 200px;
            }
            .image {
               width: 56px;
               height: 56px;
               background-size: cover;
               border-radius: 5px;
               background-position: center;
               @include media-breakpoint-down(sm) {
                  height: 200px;
                  width: 200px;
               }
            }
         }
         .type {
            .icon {
               width: 56px;
               height: 56px;
               background: rgba(123, 131, 235, 0.15);
               border-radius: 11.2px;
               line-height: 56px;
               text-align: center;
               img {
                  width: 24px;
                  height: 24px;
               }
            }
         }
         .details {
            padding-left: 24px;
            @include media-breakpoint-down(sm) {
               padding-left: 0;
               margin-top: 20px;
            }
            h2 {
               font-weight: 700;
               font-size: 32px;
               line-height: 32px;
               font-feature-settings: "liga" off;
               color: #26323a;
            }
            p {
               //font-style: normal;
               font-weight: 600;
               font-size: 14px;
               line-height: 20px;
               color: #718690;
            }
         }
      }
   }
   .edit-button {
      display: inline-block;
      font-weight: 600;
      font-size: 12px;
      color: #007989;
      display: inline-flex;
      align-items: center;
      position: relative;
      top: -1px;
      cursor: pointer;
      span {
         flex: 1;
         line-height: 20px;
      }
      img {
         margin-right: 4px;
      }
      &:hover {
         color: #015762;
         img {
            color: #015762;
         }
      }
   }
   .section-title {
      vertical-align: top;
      font-weight: 700;
      font-size: 17px;
      line-height: 19px;
      font-feature-settings: "liga" off;
      color: #26323a;
      .text {
         vertical-align: top;
         margin-right: 20px;
      }
   }
   .item-well {
      min-height: 100%;
      background: rgba(238, 241, 245, 0.75);
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      .items {
         padding: 16px 8px 8px 16px;

         .item {
            background: #ffffff;
            border: 1px solid #dde2e7;
            box-shadow: inset 0px -2px 2px rgba(221, 226, 231, 0.05);
            border-radius: 4px;
            display: inline-flex;
            align-items: center;
            padding: 6px 8px;
            width: calc(50% - 8px);
            margin-right: 8px;
            margin-bottom: 8px;
            min-height: 50px;
            .icon {
               width: 36px;
               height: 36px;
               border-radius: 50%;
               text-align: center;
               background: #f4f5f7;
               mix-blend-mode: normal;
               align-items: center;
               display: flex;
               justify-content: center;
               flex-shrink: 0;
               margin-right: 12px;
               svg {
                  // line-height: 36px;
                  width: 15px;
                  height: 15px;
               }
               .doc-icon {
                  // line-height: 36px;
                  img {
                     max-width: 20px;
                     max-height: 20px;
                  }
                  img[src*="svg"] {
                     height: 20px;
                     width: 20px;
                  }
               }
               i {
                  font-size: 20px;
               }
            }
            .title {
               font-weight: 500;
               font-size: 12px;
               line-height: 16px;
               color: #26323a;
            }
         }
         .list-empty {
            padding-bottom: 8px;
         }
      }
      &.no-cols {
         .items {
            .item {
               width: auto;
               padding: 6px 20px;
               min-height: 40px;
            }
         }
      }
   }
   .geography-text,
   .sensitivity-text,
   .permission-text,
   .following-text {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #718690;
      .geography-type,
      .sensitivity-type,
      .permission-type {
         color: #007989;
      }
   }
   .user-list {
      // margin-top: 20px;
      padding: 15px 15px 6px 15px;
      background: rgba(238, 241, 245, 0.75);
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      height: 100%;
      // min-height: 260px;
      position: relative;
      .user {
         background: #ffffff;
         border: 1px solid #dde2e7;
         box-sizing: border-box;
         border-radius: 5px;
         // height: 33px;
         vertical-align: top;
         display: inline-flex;
         // padding-right: 14px;
         margin-right: 10px;
         margin-bottom: 10px;
         position: relative;
         align-items: center;
         .pic {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgb(211, 211, 211);
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin-left: 8px;
            // margin-top:3px;
            // margin-left:3px;
            // border:1px solid #D5D1D2;
            > div {
               border-radius: 50%;
               font-size: 11px;
            }
            > img {
               width: 100%;
               height: 100%;
               border-radius: 50%;
            }
         }
         > span {
            padding: 9px;
            flex: 1;
            // line-height: 33px;
            font-size: 13px;
            font-weight: 600;
            vertical-align: top;
            // margin-left:8px;
            color: #26323a;
         }

         .remove {
            display: none;
            // background-color: white;
            // position: absolute;
            // z-index: 1;
            // top:-1px;
            // right:0;
            // // right: -20px;
            // width: 33px;
            // height: 33px;
            // text-align: center;
            // line-height: 34px;
            // line-height: 31px;
            // border-radius: 0 16px 16px 0;
            // border-top: 1px solid #E0E0E0;
            border-left: 1px solid #e0e0e0;
            // border-bottom: 1px solid #E0E0E0;
            padding: 9px 12px;
            cursor: pointer;
            > svg {
               height: 12px;
               width: 12px;
               opacity: 0.5;
            }
         }
         &:hover {
            .remove {
               // display: block;
               > svg {
                  color: red;
               }
            }
         }
      }
      .no-results {
         padding-bottom: 10px;
         .no-icon-text {
            position: absolute;
            margin: 0 auto;
            text-align: center;
            width: 120px;
            top: 50%;
            left: 50%;
            margin-left: -60px;
            margin-top: -25px;
            img {
               margin-bottom: 10px;
            }
            span {
               display: block;
               font-weight: 600;
               font-size: 14px;
               line-height: 16px;
               text-align: center;
               color: #718690;
            }
         }
      }
   }
   .channels,
   .permissions,
   .feats-and-temps {
      padding-top: 40px;
      .section-title {
         margin-bottom: 15px;
      }
   }

   .permissions.row {
      .section-title {
         margin-bottom: 0;
      }
   }
   .permissions-info {
      align-items: flex-end;
      @include media-breakpoint-down(sm) {
         display: block;
      }
   }
   .permissions-details-left {
      padding-bottom: 6px;
      justify-content: end;
      @include media-breakpoint-down(sm) {
         display: none;
      }
   }
   .permissions-details-right {
      text-align: right;
      padding-bottom: 6px;
      @include media-breakpoint-down(sm) {
         text-align: left;
      }
      .sensitivity-text,
      .permission-text {
         margin-left: 10px;
         @include media-breakpoint-down(sm) {
            margin-left: 0;
         }
         //   display: inline-block;
      }
   }
   // .area-customize-workspace {
   //     padding-bottom: 30px;
   //     .section {
   //         // min-height: 220px;
   //         @include media-breakpoint-down(sm) {
   //             // min-height: 100px;
   //             margin-bottom: 20px;
   //         }
   //         > .title {
   //             margin-bottom: 10px;
   //             vertical-align: top;
   //             position: relative;
   //             .text {
   //                 vertical-align: top;
   //                 display: inline-block;

   //                 font-size:24px;
   //                 font-weight: 600;
   //                 color: #414141;
   //                 @include media-breakpoint-down(sm) {
   //                     font-size:21px;
   //                 }
   //             }
   //             .add {
   //                 margin-top: 10px;
   //                 margin-left: 10px;
   //                 vertical-align: top;
   //                 height: 18px;
   //                 display: inline-block;
   //                 width: 18px;
   //                 line-height: 18px;
   //                 text-align: center;
   //                 background-color: #28B976;
   //                 border-radius: 50%;
   //                 cursor: pointer;
   //                 svg {
   //                     color:white;
   //                     height: 12px;
   //                     width: 12px;
   //                 }
   //             }
   //         }
   //         > .items {

   //             .item {
   //                 display: inline-block;
   //                 background:#f9f9f9;
   //                 border: 1px solid #E0E0E0;
   //                 box-sizing: border-box;
   //                 border-radius: 4px;
   //                 width:86px;
   //                 height: 86px;
   //                 position: relative;
   //                 margin-right: 10px;
   //                 margin-bottom: 10px;
   //                 > span {
   //                     display: block;
   //                     height: 100%;
   //                     width:100%;
   //                     position: absolute;
   //                     top:0;
   //                 }
   //                 .icon {
   //                     width:30px;
   //                     height: 30px;
   //                     margin-top: 10px;
   //                     margin-left: 10px;
   //                     svg {
   //                         width:25px;
   //                         height: 23px;
   //                         color: #303030;
   //                     }
   //                     img {
   //                         width:25px;
   //                         height: 23px;
   //                     }
   //                 }
   //                 .locked {
   //                     position: absolute;
   //                     top:0px;
   //                     right:8px;
   //                     svg {
   //                         width:8px;
   //                         height: 8px;
   //                         opacity: 0.4;
   //                     }
   //                 }
   //                 .remove {
   //                     position: absolute;
   //                     top:0px;
   //                     right:5px;
   //                     display: none;
   //                     cursor: pointer;
   //                     svg {
   //                         width:12px;
   //                         height: 12px;
   //                         opacity: 0.4;
   //                     }
   //                 }
   //                 .title {
   //                     position: absolute;
   //                     line-height: 16px;
   //                     bottom: 10px;
   //                     left:10px;
   //                     right:10px;
   //                     height: 30px;
   //                     // background-color: red;
   //                     overflow: hidden;
   //                     color:#676767;
   //                     font-size:13px;
   //                 }

   //                 &.can-be-removed {
   //                     background: white;

   //                     .locked {
   //                         display: none;
   //                     }
   //                     .remove {
   //                         display: block;
   //                     }
   //                 }
   //             }
   //         }
   //     }
   // }

   // .area-channels {
   //     padding-bottom: 30px;
   //     padding-top: 5px;
   //     @include media-breakpoint-down(sm) {
   //         padding-top:15px;
   //     }
   //     .item {
   //         background-color:white;
   //         display: inline-block;
   //         padding: 10px 12px;
   //         border: 1px solid #E0E0E0;
   //         box-sizing: border-box;
   //         border-radius: 5px;
   //         margin-right: 10px;
   //         .title {
   //             margin: 0;
   //         }
   //     }
   // }

   // .area-permissions {
   //     padding-bottom: 20px;
   //     @include media-breakpoint-down(sm) {
   //         padding-top:15px;
   //     }
   //     .user-list {
   //         padding-top: 5px;
   //         .user {
   //             background: #FFFFFF;
   //             border: 1px solid #E0E0E0;
   //             box-sizing: border-box;
   //             border-radius: 17px;
   //             height: 33px;
   //             vertical-align: top;
   //             display: inline-block;
   //             // padding-right: 14px;
   //             padding-right: 15px;
   //             margin-right: 10px;
   //             margin-bottom: 10px;
   //             position: relative;
   //             .pic {
   //                 width:25px;
   //                 height: 25px;
   //                 border-radius: 50%;
   //                 background-color: rgb(211, 211, 211);
   //                 display: inline-block;
   //                 vertical-align: top;
   //                 position: relative;
   //                 margin-top:3px;
   //                 margin-left:3px;
   //                 // border:1px solid #D5D1D2;
   //                 > div {
   //                     border-radius: 50%;
   //                     font-size:11px;
   //                 }
   //                 > img {
   //                     width: 100%;
   //                     height: 100%;
   //                     border-radius: 50%;
   //                 }
   //             }
   //             > span {
   //                 line-height: 33px;
   //                 font-size: 14px;
   //                 vertical-align: top;
   //                 margin-left:8px;
   //             }

   //             .remove {
   //                 // display: none;
   //                 // background-color: white;
   //                 position: absolute;
   //                 z-index: 1;
   //                 top:-1px;
   //                 right:0;
   //                 // right: -20px;
   //                 width: 33px;
   //                 height: 33px;
   //                 text-align: center;
   //                 line-height: 34px;
   //                 // line-height: 31px;
   //                 // border-radius: 0 16px 16px 0;
   //                 // border-top: 1px solid #E0E0E0;
   //                 // border-right: 1px solid #E0E0E0;
   //                 // border-bottom: 1px solid #E0E0E0;
   //                 cursor: pointer;
   //                 > svg {
   //                     height: 12px;
   //                     width: 12px;
   //                     opacity: 0.5;
   //                 }
   //             }
   //             &:hover {
   //                 .remove {
   //                     // display: block;
   //                     > svg {
   //                         color:red;
   //                     }
   //                 }
   //             }
   //         }
   //     }
   //     .permission-text {
   //         margin-bottom: 10px;
   //         .permission-type {
   //             color:#28B976;
   //             font-weight: 600;
   //             svg {
   //                 margin-right: 6px;
   //                 margin-left: 5px;
   //             }
   //         }
   //         .external-access {
   //             font-weight: 600;
   //         }
   //     }
   // }

   // .title {
   //     margin-bottom: 10px;
   //     vertical-align: top;
   //     position: relative;
   //     margin-top: 25px;
   //     @include media-breakpoint-down(sm) {
   //         margin-top: 25px;
   //     }
   //     .text {
   //         vertical-align: top;
   //         display: inline-block;

   //         font-size:24px;
   //         font-weight: 600;
   //         color: #414141;
   //         @include media-breakpoint-down(sm) {
   //             font-size:21px;
   //         }
   //     }
   //     .button-custom {
   //         position: absolute;
   //         top:0;
   //         right:0;
   //     }
   // }

   // .left .title .button-custom {
   //     @include media-breakpoint-up(sm) {
   //         // display:none;
   //     }
   // }
}
