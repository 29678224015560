.installer-step-configure {
   .form-user-group-select .user-select-dropdown-wrapper .link {
      display: block;
   }
   .import-newly-created {
      font-weight: 400;
      color: black;
      label {
         font-weight: 500;
      }
   }
}
