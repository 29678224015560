.archival-policy-selector {
   .policies {
      display: flex;
      flex-direction: column;
      .policy-item {
         padding: 20px 0px;
         // margin: 0 4px;
         border-top: 1px solid #e1e7ee;
         display: flex;
         &:last-child {
            padding-bottom: 0;
         }
         > div {
            display: flex;
            align-items: center;
            // cursor: pointer;
         }
         .left {
            width: 44px;
            .icon {
               width: 32px;
               height: 32px;
               text-align: center;
               border-radius: 4px;
               background: #26a7b8;
               color: white;
               transition: all 0.3s;
               i {
                  font-size: 20px;
                  line-height: 32px;

                  &.orchicon-workspace-review {
                     font-size: 20px;
                     margin-left: 0px;
                  }

                  &.fa-light {
                     font-size: 15px;
                     margin-left: 2px;
                  }
                  &.fa-box-archive {
                     margin-left: 0px;
                  }
                  &.fas {
                     font-size: 15px;
                  }
               }
            }
         }
         .right {
            flex: 1;
            .text-top {
               color: #26323a;
               font-size: 14px;
               font-weight: 500;
            }
            .text-bottom {
               .pname {
                  color: #718690;
                  font-size: 12px;
                  font-weight: 400;
                  margin-right: 10px;
               }
               .days {
                  i {
                     font-size: 10px;
                     color: rgb(179, 179, 34);
                  }
                  &.override {
                     i {
                        color: #5967dd;
                     }
                  }
                  font-weight: 400;
                  font-size: 10px;
                  .threshold-days {
                     &.override {
                        color: #5967dd;
                        font-weight: 600;
                     }
                  }
               }
               &.warning {
                  background-color: rgba(255, 0, 0, 0.09);
                  border-radius: 4px;
                  font-weight: 400;
                  padding: 5px 5px;
                  font-size: 11px;
               }
               .protected {
                  display: flex;
                  opacity: 0.8;
                  align-items: center;
                  i {
                     font-size: 11px;
                     opacity: 0.6;
                  }
                  .text {
                     flex: 1;
                     font-weight: 500;
                     margin-left: 5px;
                     font-size: 11px;
                     // line-height: 18px;
                  }
               }
            }
            .edit-select {
               .select-item {
                  margin-bottom: 10px;
                  &:last-child {
                     margin-bottom: 0;
                  }
                  .select-title {
                     font-weight: 500;
                     color: #26323a;
                  }
               }
               .form-buttons {
                  padding-top: 10px;
                  justify-content: flex-end;
               }
            }
         }
         .actions {
            padding-left: 15px;
            display: flex;
            .subtitle-button {
               font-size: 13px;
               padding: 5px 10px 5px 7px;
            }
            .action {
               .icon {
                  opacity: 0.7;
                  transition: all 0.3s;
                  i {
                     font-size: 20px;
                     color: #718690;
                  }
               }
               &:hover {
                  .icon {
                     opacity: 1;
                     color: #3da0a2;
                     background: #f0f3ff;
                  }
               }
            }
            .force-modal {
               display: flex;
               align-items: center;
               justify-content: space-between;
            }
         }
         .exists {
            // &:hover {
            //    .left {
            //       .icon {
            //          background-color: #546d79;
            //       }
            //    }
            //    .actions {
            //       .action {
            //          .icon {
            //             opacity: 1;
            //          }
            //       }
            //    }
            // }
         }
         .add {
            opacity: 0.75;
            transition: all 0.3s;
            &:hover {
               opacity: 1;
               .action {
                  .icon {
                     color: #3da0a2;
                     background: #f0f3ff;
                  }
               }
            }
            .left {
               .icon {
                  // background: #EDA334;
                  // color: white;
               }
            }
         }
         .open,
         .no-available-policies {
            opacity: 1;
            cursor: default;
            .actions {
               // display: none;
            }
         }
         .no-available-policies {
            .left {
               .icon {
                  background-color: rgb(243, 185, 108);
               }
            }
         }
      }
   }
}

.archival-policy-settings-modal,
.guest-request-policy-settings-modal,
.guest-review-policy-settings-modal {
   .modal-lg {
      @media (min-width: 1024px) {
         max-width: 400px;
      }
   }
   .exclude-group {
      margin-top: 27px;
   }
   .form-orch-toggle {
      label {
         padding-right: 0 !important;
      }
   }

   .form-button:has(.orchicon-workspace-review) {
      padding-top: 3px;
      padding-bottom: 3px;
   }

   .orchicon-workspace-review {
      font-size: 22px !important;
   }
}

.lifecycle-force-archival-confirm-modal,
.lifecycle-force-review-confirm-modal {
   z-index: 1070 !important;
   .confim-text {
      .icon {
         text-align: center;
         font-size: 80px;
         .orchicon-workspace-review {
            margin-left: -4px;
         }
      }
      .text {
         font-weight: 500;
      }
   }
   .lifecycle-force-review-confirm-buttons {
      display: flex;
      .filler {
         background-color: blue;
      }
      .skeleton-loader-common {
         margin-left: 10px;
      }
   }
}

.lifecycle-force-review-confirm-modal-bg,
.lifecycle-force-archival-confirm-modal-bg {
   z-index: 1060 !important;
}
