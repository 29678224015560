.root {
   .input {
      cursor: pointer;
      border-radius: 4px;
   }

   .label {
      margin: 0;
   }

   .icon {
      width: 10px;
      height: 10px;
   }

   &[data-checked="false"] {
      .input {
         border: var(--mantine-color-gray-3) 1px solid !important;
      }
   }
   &[data-checked="true"] {
      .input {
         border: var(--mantine-color-primary-7) 1px solid !important;
      }
   }
}
