@import "app/assets/common/bootstrap-media-mixins";
.lifecycle-dashboard {
   position: relative;

   .stat-item {
      display: flex;
      .count {
         font-weight: 600;
         font-size: 15px;
      }

      .changed {
         font-weight: 600;
         font-size: 15px;
         min-width: 20px;
         padding-left: 10px;
      }
      .title {
         flex: 1;
         font-size: 15px;
         font-weight: 500;
      }
      .title-width {
         font-size: 15px;
         font-weight: 500;
         min-width: 80px;
      }
      .count-width {
         font-weight: 600;
         font-size: 15px;
         min-width: 80px;
         text-align: right;
         padding-right: 10px;
      }
      .icon {
         .fa-arrow-up {
            color: green;
         }
         .fa-arrow-right {
            color: rgb(14, 10, 248);
         }
         .fa-arrow-down {
            color: rgb(248, 10, 10);
         }
      }
   }
   .workspace-listing {
      .stat-item {
         margin-bottom: 5px;
         padding-bottom: 5px;
         border-bottom: 1px solid rgba(0, 0, 0, 0.104);
         &:last-child {
            margin: 0;
            padding: 0;
            border-bottom: none;
         }
      }
   }
   .crawl-still-in-progress {
      position: absolute;
      top: 0;
      left: -10px;
      right: -20px;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 10;
      border-radius: 6px;
      // backdrop-filter: blur(5px);
      text-align: center;
      padding-top: 50px;
      i {
         font-size: 50px;
         opacity: 0.7;
         background-color: white;
      }
      .title {
         font-size: 22px;
         font-weight: 600;
         max-width: 500px;
         margin: 0 auto 20px auto;
         background-color: white;
      }
      .desc {
         font-size: 14px;
         font-weight: 500;
         max-width: 500px;
         margin: 0 auto;
         opacity: 0.7;
         background-color: white;
      }
   }
   .dashboard-box {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: calc(100% - 15px);
      margin-bottom: 15px;
      .bottom-grow {
         flex-grow: 1;
      }
   }
   .workspace-counts {
      background-color: white;
      border-radius: 4px;
      padding: 0 10px;

      .count-item {
         position: relative;
         overflow: hidden;
         padding: 10px;
         border-bottom: 1px solid rgba(0, 0, 0, 0.04);
         // margin-bottom: 10px;
         &:last-child {
            margin-bottom: 0;
            border-bottom: none;
         }
         .count-item-wrapper {
            display: flex;
            align-items: center;
            // width: 100%;
            .content {
               flex: 1;
               .count {
                  font-size: 26px;
                  font-weight: 600;
                  color: rgba(0, 0, 0, 0.83);
                  line-height: 35px;
               }
               .title {
                  font-size: 14px;
                  font-weight: 500;
               }
            }
            .background-icon {
               // position: absolute;
               // right:15px;
               // top:0px;
               opacity: 0.5;
               i {
                  font-size: 30px;
               }
            }
         }
      }
   }
   .workspace-total-count {
      .top {
         background-color: #5670f9;
         border-radius: 4px 4px 0 0;
         padding: 10px 20px;
         display: flex;
         .content {
            flex: 1;
            .count {
               font-size: 26px;
               font-weight: 600;
               color: white;
               line-height: 35px;
            }
            .title {
               color: white;
               font-size: 14px;
               font-weight: 500;
               opacity: 0.7;
            }
         }
         .background-icon {
            // position: absolute;
            // right:15px;
            // top:0px;
            opacity: 0.5;
            svg {
               height: 60px;
               width: 60px;
               > g > * {
                  fill: white;
               }
            }
         }
      }

      .bottom {
         // background-color: rgba(0, 0, 255, 0.05);
         background-color: white;
         border-radius: 0 0 4px 4px;
         padding: 10px;
         min-height: 150px;
         flex-grow: 1;
         .apexcharts-canvas {
            // height: 100px !important;
            margin: 0 auto;
            svg {
               // width: 100%;
               // height: 100px !important;
            }
         }

         .last-text {
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            margin-top: 10px;
            // margin-top: -20px;
         }
      }
   }
   .workspace-privacy {
      background-color: #fbf5e0;
      border-radius: 4px;
      padding: 0 10px;
      .wrapper {
         display: flex;
         align-items: center;
         .content {
            flex: 1;
            padding: 15px 10px;
            .main-title {
               display: flex;
               padding: 0 0 10px 0;
               .title {
                  font-size: 20px;
                  font-weight: 600;
                  color: #3f4254;
                  flex: 1;
                  opacity: 0.7;
               }
            }
            .stats {
               display: flex;
               .item {
                  flex: 1;
                  justify-content: center;
                  .count {
                     font-size: 26px;
                     font-weight: 600;
                     color: #3f4254;
                     line-height: 35px;
                  }
                  .title {
                     color: black;
                     font-size: 14px;
                     font-weight: 500;
                     opacity: 0.5;
                  }
               }
            }
         }
         .logo {
            svg {
               width: 60px;
               height: 60px;
               > g > * {
                  fill: #ffa800 !important;
               }
            }
         }
      }
   }
   .workspace-guests {
      background-color: #5a9691;
      border-radius: 4px;
      padding: 0 10px;
      .wrapper {
         display: flex;
         align-items: center;
         .content {
            flex: 1;
            padding: 15px 10px;
            .main-title {
               display: flex;
               padding: 0 0 10px 0;
               .title {
                  font-size: 20px;
                  font-weight: 600;
                  color: #3f4254;
                  flex: 1;
                  opacity: 0.7;
               }
            }
            .stats {
               display: flex;
               .item {
                  flex: 1;
                  justify-content: center;
                  .count {
                     font-size: 26px;
                     font-weight: 600;
                     color: #3f4254;
                     line-height: 35px;
                  }
                  .title {
                     color: black;
                     font-size: 14px;
                     font-weight: 500;
                     opacity: 0.5;
                  }
               }
            }
         }
         .logo {
            svg {
               width: 60px;
               height: 60px;
               > g > * {
                  fill: #ffa800 !important;
               }
            }
         }
      }
   }
   .workspace-inactivity {
      background-color: #d02e3e3b;
      border-radius: 4px;
      padding: 0 10px;
      .wrapper {
         display: flex;
         align-items: center;
         .content {
            flex: 1;
            padding: 15px 10px;
            .main-title {
               display: flex;
               padding: 0 0 10px 0;
               .title {
                  font-size: 20px;
                  font-weight: 600;
                  color: #3f4254;
                  flex: 1;
                  opacity: 0.7;
               }
            }
            .stats {
               display: flex;
               .item {
                  flex: 1;
                  justify-content: center;
                  .count {
                     font-size: 26px;
                     font-weight: 600;
                     color: #3f4254;
                     line-height: 35px;
                  }
                  .title {
                     color: black;
                     font-size: 14px;
                     font-weight: 500;
                     opacity: 0.5;
                  }
               }
            }
         }
         .logo {
            svg {
               width: 60px;
               height: 60px;
               > g > * {
                  fill: #ffa800 !important;
               }
            }
         }
      }
   }
   .date-range-selector {
      display: flex;
      align-items: center;
      margin: 20px 20px 0 20px;
      .text {
         flex: 1;
         font-weight: 500;
      }
      .the-dropdown {
         min-width: 50%;
      }
   }

   .new-dashboard {
      .top-stats-row {
         .top-half {
            height: 50%;
            padding-bottom: 10px;
         }
         .bottom-half {
            padding-top: 10px;
            height: 50%;
         }
         .inactive-workspaces-box {
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 20px;
            text-align: center;
            // height: 230px;
            height: calc(100%);
            box-shadow: 0px 1px 3px 0px #26262e12;
            position: relative;
            transition: all 0.3s;
            &:hover {
               background-color: rgb(247, 247, 247);
            }
            .link {
               position: absolute;
               top: 6px;
               right: 6px;
               i {
                  font-size: 18px;
                  color: #718690;
                  opacity: 0.8;
               }
            }
            > .title {
               font-weight: 500;
               font-size: 16px;
               text-align: center;
               color: #26323a;
            }
            .count {
               margin-top: 20px;
               color: #26323a;
               font-size: 40px;
               font-weight: 700;
            }
            .text {
               font-size: 14px;
               color: #718690;
            }
         }
         .count-title-description-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 20px;
            text-align: center;
            // height: 230px;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            position: relative;
            transition: all 0.3s;
            .link {
               position: absolute;
               top: 6px;
               right: 6px;
               i {
                  font-size: 18px;
                  color: #718690;
                  opacity: 0.8;
               }
            }
            .count {
               // margin-top: 20px;
               color: #26323a;
               font-size: 30px;
               font-weight: 700;
               line-height: 30px;
            }
            .title {
               font-weight: 500;
               font-size: 16px;
               text-align: center;
               color: #26323a;
               margin-bottom: 10px;
            }
            .text {
               margin-top: 10px;
               color: #718690;
            }

            &.alt-style {
            }
         }
      }
      .insights-box {
         background-color: white;
         border: 1px solid #e8e8f0;
         border-radius: 8px;
         box-shadow: 0px 1px 3px 0px #26262e12;
         margin-bottom: 36px;
         .top-bars {
            display: flex;
            .bar {
               background: #e8e8f0;
               height: 5px;
               flex: 1;
               border-right: 2px solid white;
               &:first-child {
                  border-radius: 8px 0 0 0;
               }
               &:last-child {
                  border-radius: 0 8px 0 0;
                  border-right: 0px solid transparent;
               }
               &.selected {
                  background: linear-gradient(270deg, #3da0a2 -304.02%, #94bf2d 100%);
               }
            }
         }
         .title-area {
            display: flex;
            align-items: center;
            .title {
               font-weight: 500;
               color: #26323a;
               font-size: 16px;
               padding: 10px 20px;
               flex: 1;
            }
            .navigation {
               display: flex;
               padding: 10px 20px;
               align-items: center;
               .next-button {
               }
               .page-number {
                  margin-left: 20px;
                  font-size: 16px;
                  font-weight: 500;
               }
            }
         }
         .insight-content {
            background: #3da0a21a;
            border-radius: 0 0 7px 7px;
            display: flex;
            align-items: center;
            padding: 15px 20px;
            .logo {
               width: 46px;
               height: 46px;
               border-radius: 6px;
               background-color: white;
               box-shadow: 0px 4px 8px -4px #00798926;
               text-align: center;
               i {
                  font-size: 18px;
                  color: #3da0a2;
                  line-height: 46px;
               }
            }
            .insight {
               flex: 1;
               padding-left: 20px;
               .title {
                  color: #26323a;
                  font-weight: 700;
               }
               .description {
                  color: #26323acc;
                  font-weight: 500;
               }
            }
            .see-report-button {
            }
         }
      }
      .color-stat-box {
         padding: 20px 20px;
         display: flex;
         align-items: center;
         border-radius: 8px;
         box-shadow: 0px 1px 3px 0px #26262e12;

         // margin-bottom: 20px;
         .icon {
            width: 46px;
            height: 46px;
            border-radius: 6px;
            background-color: white;
            box-shadow: 0px 4px 8px -4px #00798926;
            text-align: center;
            line-height: 46px;
            img {
               width: 22px;
            }
         }
         .content {
            padding-left: 20px;
            .count {
               font-size: 30px;
               font-weight: 700;
            }
            .title {
               font-weight: 500;
               color: #26323a;
            }
         }
         &.teams {
            background: #3da0a21a;
            margin-bottom: 20px;
            .count {
               color: #318385;
            }
         }
         &.team-sites {
            background: #1a85c11a;
            margin-bottom: 20px;
            .count {
               color: #1b6995;
            }
         }
         &.comm-sites {
            background: #94bf2d1a;
            margin-bottom: 20px;
            .count {
               color: #709220;
            }
         }
         &.viva-communities {
            background: rgba(26, 133, 193, 0.1019607843);
            .count {
               color: rgb(26 133 193);
            }
         }
      }
      .privacy-box {
         border: 1px solid #e8e8f0;
         border-radius: 8px;
         padding: 20px;
         // height: 230px;
         box-shadow: 0px 1px 3px 0px #26262e12;
         height: calc(100%);

         > .title {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: #26323a;
         }
         .sides {
            display: flex;
            margin-top: 20px;
            .left {
               flex: 1;
               text-align: center;
            }
            .right {
               flex: 1;
               text-align: center;
            }
            .icon {
               width: 46px;
               height: 46px;
               border-radius: 50%;
               background: #298d8f40;
               margin: 0 auto;
               line-height: 46px;
            }
            .title {
               margin: 15px 0 0 0;
               color: #718690;
               font-size: 14px;
               font-weight: 500;
            }
            .count {
               color: #26323a;
               font-size: 24px;
               font-weight: 700;
            }
         }
      }
      .workspaces-box {
         border: 1px solid #e8e8f0;
         border-radius: 8px;
         padding: 20px;
         text-align: center;
         // height: 230px;
         height: calc(100%);
         box-shadow: 0px 1px 3px 0px #26262e12;
         position: relative;
         transition: all 0.3s;
         &:hover {
            background-color: rgb(247, 247, 247);
         }
         .link {
            position: absolute;
            top: 6px;
            right: 6px;
            i {
               font-size: 18px;
               color: #718690;
               opacity: 0.8;
            }
         }
         > .title {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: #26323a;
         }
         .count {
            margin-top: 20px;
            color: #26323a;
            font-size: 40px;
            font-weight: 700;
         }
         .percent {
            color: #39c783;
            font-weight: 700;
            font-size: 15px;
         }
         .last-days {
            margin-top: 20px;
            color: #718690;
         }
      }

      .title-row {
         margin-top: 40px;
         margin-bottom: 20px;
         display: flex;
         align-items: center;
         @include media-breakpoint-down(sm) {
            display: block;
         }
         .left {
            font-weight: 700;
            font-size: 20px;
            color: #26323a;
            flex: 1;
         }
         .right {
            width: 200px;
            .header-text {
               font-weight: 500;
            }
         }
      }
      .status-row {
         display: flex;
         @include media-breakpoint-down(sm) {
            display: block;
         }
         > a {
            flex: 1;
            margin: 0 10px;
            @include media-breakpoint-up(sm) {
               &:last-child {
                  margin-right: 0;
               }
               &:first-child {
                  margin-left: 0;
               }
            }
         }
         .status-box {
            flex: 1;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 30px 20px 25px 20px;
            text-align: center;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            position: relative;
            transition: all 0.3s;
            &:hover {
               // outline: 2px solid #d7dee6;
               // box-shadow: 0px 4px 4px rgba(225, 231, 238, 0.15);
            }
            @include media-breakpoint-down(sm) {
               margin-bottom: 20px;
            }
            .link {
               position: absolute;
               top: 6px;
               right: 6px;
               i {
                  font-size: 18px;
                  color: #718690;
                  opacity: 0.8;
               }
            }

            &::before {
               content: "";
               background-color: black;
               position: absolute;
               left: 0px;
               top: 0;
               bottom: 0;
               width: 4px;
               border-radius: 8px 0 0 8px;
               transition: all 0.3s;
            }
            &:hover {
               &::before {
                  width: 10px;
               }
            }
            &.color-renewed {
               &::before {
                  background-color: #4b8a85;
               }
            }
            &.color-active {
               &::before {
                  background-color: #648021bf;
               }
            }
            &.color-inactive {
               &::before {
                  background-color: #b36909;
               }
            }
            &.color-pending {
               &::before {
                  background-color: rgba(135, 125, 201, 0.88);
               }
            }
            &.color-archived {
               &::before {
                  background-color: #d8cc7c;
               }
            }

            .count {
               // margin-top: 20px;
               color: #26323a;
               font-size: 30px;
               font-weight: 700;
               line-height: 30px;
            }
            .text {
               font-weight: 400;
               font-size: 14px;
               text-align: center;
               color: #26323a;
               margin-top: 15px;
            }

            &:hover {
               background-color: rgb(247, 247, 247);
            }
         }
      }
      .workspace-overview-row {
         display: flex;
         @include media-breakpoint-down(sm) {
            display: block;
         }
         .overview-box {
            flex: 1;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 30px 20px 25px 20px;
            text-align: center;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            margin: 0 10px;
            position: relative;
            @include media-breakpoint-down(sm) {
               margin: 0;
               margin-bottom: 20px;
            }
            @include media-breakpoint-up(sm) {
               &:last-child {
                  margin-right: 0;
               }
               &:first-child {
                  margin-left: 0;
               }
            }
            .change-stat {
               position: absolute;
               right: 10px;
               top: 10px;
               display: flex;
               font-size: 14px;
               .icon {
                  margin-right: 5px;
               }
               .count {
                  font-weight: 500;
               }
            }
            .top-row {
               display: flex;
               justify-content: center;
               align-items: center;
               .icon {
                  font-size: 26px;
                  margin-right: 15px;
               }
               .count {
                  color: #26323a;
                  font-size: 36px;
                  font-weight: 700;
                  line-height: 30px;
               }
            }
            .text {
               font-weight: 500;
               font-size: 18px;
               text-align: center;
               color: #26323a;
               margin-top: 5px;
            }
            .sub-text {
            }
            &.up {
               .down {
                  display: none;
               }
               .none {
                  display: none;
               }
               .change-stat {
                  color: #94bf2d;
               }
               .top-row {
                  .icon {
                     color: #94bf2d;
                  }
               }
            }
            &.down {
               .up {
                  display: none;
               }
               .none {
                  display: none;
               }
               .change-stat {
                  color: #d95867;
               }
               .top-row {
                  .icon {
                     color: #d95867;
                  }
               }
            }
            &.none {
               .change-stat {
                  display: none;
               }
               .icon {
                  color: #9eacb2;
                  .down,
                  .up {
                     display: none;
                  }
               }
            }
         }
      }
      .charts-row {
         // display: flex;
         margin-top: 25px;
         > div {
            @include media-breakpoint-down(sm) {
               margin-bottom: 20px;
            }
         }
         .pie-chart {
            // flex: 2;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 20px 20px 20px 20px;
            text-align: center;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            // margin: 0 10px;
            position: relative;

            .title {
               font-weight: 500;
               font-size: 16px;
               text-align: center;
               color: #26323a;
               margin-bottom: 30px;
            }
            .chart {
               .apexcharts-canvas {
                  margin: 0 auto 20px auto;
               }
            }
         }
         .bar-chart {
            // flex: 5;
            border: 1px solid #e8e8f0;
            border-radius: 8px;
            padding: 20px 20px 20px 20px;
            text-align: left;
            box-shadow: 0px 1px 3px 0px #26262e12;
            height: calc(100%);
            // margin: 0 10px;
            position: relative;
            .title {
               font-weight: 500;
               font-size: 16px;
               // text-align: center;
               color: #26323a;
            }
            .desc {
            }
         }
      }
      .workspace-list {
         border: 1px solid #e8e8f0;
         border-radius: 8px;
         padding: 20px 20px 20px 20px;
         // text-align: center;
         box-shadow: 0px 1px 3px 0px #26262e12;
         height: calc(100%);
         // margin: 0 10px;
         position: relative;
         margin-top: 25px;
         > .title {
            font-weight: 500;
            font-size: 16px;
            // text-align: center;
            color: #26323a;
            margin-bottom: 15px;
         }
         ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
               padding: 0;
               display: flex;
               align-items: center;
               margin-bottom: 15px;
               max-width: 100%;
               &:last-child {
                  margin-bottom: 0;
               }
               .icon {
                  border: 1px solid #b3b3b3;
                  border-radius: 6px;
                  width: 30px;
                  height: 30px;
                  min-width: 30px;
                  overflow: hidden;
                  .round-icon {
                     // width: 30px;
                     // height: 30px;
                     border-radius: 50%;
                     text-align: center;
                     img {
                        max-height: 28px;
                     }
                     i {
                        line-height: 28px;
                     }
                  }
               }
               .content {
                  flex: 1;
                  padding-left: 10px;
                  min-width: 0;
                  .title {
                     font-size: 13px;
                     font-weight: 700;
                     color: #26323a;
                     display: block;
                     white-space: nowrap;
                     width: 100%;
                     min-width: 0;
                     overflow: hidden;
                     text-overflow: ellipsis;
                  }
                  .url {
                     color: #718690;
                     font-size: 11px;
                     font-style: italic;
                     display: block;
                     display: none;
                     white-space: nowrap;
                     width: 100%;
                     min-width: 0;

                     overflow: hidden;
                     text-overflow: ellipsis;
                  }
               }
               .link-icon {
                  padding-left: 10px;
                  .link {
                     i {
                        font-size: 18px;
                        color: #718690;
                        opacity: 0.8;
                     }
                  }
               }
            }
         }
      }
   }
}

.lifecycle-dashboard-content-wrapper {
   background-color: white;
   transition: all 0.3s;
}

.lifecycle-dashboard-export-report {
   margin-right: 15px;
   .export-button {
      display: flex;
      justify-content: flex-start;
      margin-right: 0;
      align-items: center;
      font-size: 13px;
      // margin-top: 0 !important;
      // margin-bottom: 0 !important;
      // height: 38px !important;
      // padding: 9px 21px !important;
      .loading {
         margin-right: 10px;
         .animated-loader {
            height: 16px;
            width: 16px;
         }
      }
      .icon {
         margin-right: 6px;

         i {
            font-size: 16px;
            // position: relative;
            // top: -3px;
         }
      }
      &:hover {
         background-color: #f8f8f8;
         cursor: pointer;
      }
      .text {
         font-weight: 500;
         font-size: 13px;
      }
   }
}

.orchestry-lifecycle-dashboard-bar-chart-hover {
   display: flex;
   align-items: center;
   background-color: white;
   padding: 5px;
   box-shadow: 1px 1px 1px lightgrey;
   .color-icon {
      width: 15px;
      height: 15px;
      display: block;
      margin-right: 6px;
   }
   .title {
      margin-right: 6px;
      font-size: 13px !important;
      font-weight: 300;
      display: block;
   }
   .value {
      font-size: 13px !important;
      display: block;
      font-weight: 600;
   }
}
