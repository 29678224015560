.save-progression {
    display: inline-block;
    position: relative;
    .children {
        display: inline-block;
    }
    .dropdown {
        position: absolute;
        width: 300px;
        background-color: white;
        z-index: 2000;
        border:1px solid rgb(192, 192, 192);
        border-radius:4px;
        right:0;
        top:calc(100% + 4px);
        box-shadow: 0px 2px 6px #0000001f;
        &:before {
            content: "";
            position: absolute;
            width:10px;
            height: 10px;
            border-left:1px solid rgb(192, 192, 192);
            border-top:1px solid rgb(192, 192, 192);
            //background-color: rgb(183, 236, 199);
            background-color: white;
            top:-6px;
            transform: rotate(45deg);
            right:25px;
        }
        ul {
            list-style: none;
            margin: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            li {
                position: relative;
                list-style: none;
                //background-color: rgb(183, 236, 199);
                background-color: white;
                border-radius: 5px;
                padding: 6px 15px 6px 0;
                // text-align: center;
                .icon {
                    position: absolute;
                    left:-30px;
                    .animated-loader {
                        vertical-align: top;
                        margin-right: 10px;
                    }
                    .dash {
                        position: absolute;
                        left:10px;
                        margin-top: -3px;
                    }
                    i {
                        margin-left: 4px;
                    }
                }
                .text { 
                    display: inline-block;
                    vertical-align: top;
                    font-weight: 600;
                    font-size:14px;
                    line-height: 18px;
                }
                vertical-align: top;
            }
        }
    }
}