.team-information-pill {
   background-color: hsla(0, 0%, 71%, 0.1);
   padding: 1px 6px;
   border-radius: 5px;

   margin-right: 10px;

   .nested-property {
      color: hsla(0, 0%, 40.4%, 0.82);
      font-weight: 500;
      font-size: 12px;
      i {
         margin-right: 7px;
         opacity: 0.8;
      }
   }
}
