@import "_metronic/_assets/sass/breakpoints";

.directory {
   background-color: white;
   .directory-wrapper {
      display: flex;
      width: 100%;
      @include kt-mobile {
         display: block;
      }
      .first-column {
         flex-grow: 1;
         position: relative;
         // padding-right: 20px;
         @include kt-mobile {
            // padding: 0px;
            padding-top: 120px;
         }
      }
      .second-column {
         min-width: 25%;
         width: 25%;
         padding-left: 30px;

         &.full-width {
            width: 100%;
            padding-left: 0;
         }

         @include kt-mobile {
            width: 100%;
            padding-left: 0px;
         }
      }
   }
   .directory-list-table {
      // padding: 15px;
   }
   .directory-filters {
   }
   .managed-metadata-item {
      position: relative;
      i {
         margin-right: 6px;
         display: inline;
      }
   }
   .managed-metadata-list {
      margin-bottom: 0;
      padding-left: 15px;
      list-style-type: none;
      .managed-metadata-item {
         i {
            position: absolute;
            left: -15px;
            top: 5px;
         }
      }
   }
}
.directory-modal {
}
.directory-modal-backdrop {
   //    filter: blur(8px);
   //   -webkit-filter: blur(8px);
   //   backdrop-filter: blur(7px) ;
   //   background-color: rgba(238, 241, 245, 0.75) !important;
   &.show {
      // opacity: 1 !important;
   }
}
