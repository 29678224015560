.template-tabs {
   .details-tab {
      .image-size {
         font-weight: 500;
         font-size: 11px;
         font-style: italic;
         opacity: 0.5;
         margin-top: 15px;
         text-align: center;
         width: 75%;
      }
      .imageupload.has-image {
         border-radius: 4px;
      }
      .subheader {
         padding-bottom: 15px;
         font-weight: 500;
         color: #414141;
      }

      .label-description {
         margin-bottom: 5px;
         font-weight: 400;
         font-style: italic;
         opacity: 0.8;
      }
      .right-col {
         margin-left: 2rem;
         .image-uploads {
            display: flex;
            gap: 1rem;
            flex-direction: column;
            .template-image {
               display: flex;
               flex-direction: column;
               .form-imageuploadbox {
                  width: 75%;
               }
            }
            .default-workspace-logo {
               display: flex;
               flex-direction: column;
               .form-imageuploadbox {
                  width: 38%;
                  justify-self: center;
                  .imageupload {
                     display: flex;
                     flex-direction: column;
                     align-content: center;
                     justify-content: center;
                     &.has-image {
                        flex-direction: row;
                     }
                     .delete-icon {
                        bottom: 5px;
                        right: 5px;
                        svg {
                           width: 22px;
                           height: 22px;
                        }
                     }
                  }
               }
               .image-size {
                  font-weight: 500;
                  font-size: 11px;
                  font-style: italic;
                  opacity: 0.5;
                  margin-top: 15px;
                  width: 38%;
               }
            }
         }
      }
      .form-label {
         .add-document-category {
            display: inline;
            color: #0abb87;
            cursor: pointer;
            margin-top: 10px;
         }
      }
      .default-permissions-form-group {
         .portlet-alert {
            .alert {
               margin-bottom: 10px;
            }
         }
      }
      .team-information-tab-toggle {
         display: flex;
         gap: 1rem;
         margin-top: 16px;
         transform: scale(0.8);
         transform-origin: 0% 0%;
         .form-orch-toggle {
            margin: 0;
         }

         &.hide-control {
            display: none;
         }
      }
      .description-note {
         font-weight: 400;
         font-size: 12px;
      }
      .more-info-container {
         display: flex;
         flex-direction: column;
         gap: 0.75rem;
         .form-orch-toggle {
            margin: 0;
         }
         .more-info-fields {
            #link-label {
               margin-top: 15px;
            }
         }
      }
   }
}
