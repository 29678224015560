@import "app/assets/common/bootstrap-media-mixins";
.request-queue {
   .request-container {
      padding-top: 25px;
      &:first-child {
         padding-top: 0;
      }
      .request-title {
         display: flex;
         .col-header {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #718690;
         }
      }
      .loading-skeleton {
         .items {
            .item {
               display: flex;
               padding: 5px 20px 5px 0px;
               border-radius: 5px;
               width: 100%;
               align-items: center;
               // border-bottom: 1px solid #EEF1F5;
               margin-bottom: 5px;
               background-color: #ffffffd4;
               > div {
                  // padding: 0 0px 0 20px;
               }
               .pic {
                  .circle {
                     height: 36px;
                     width: 36px;
                     border-radius: 4px;
                     background: rgba(238, 241, 245, 0.75);
                  }
               }
               .title {
                  margin-left: 25px;
                  // width:300px;
                  padding-right: 20px;
                  flex: 1;
                  .title-box {
                     background: rgba(238, 241, 245, 0.75);
                     border-radius: 5px;
                     height: 21px;
                  }
               }
               .property {
                  flex: 1;
                  padding-right: 20px;
                  .property-box {
                     background: rgba(238, 241, 245, 0.75);
                     border-radius: 5px;
                     height: 21px;
                  }
               }
            }
         }
         .animated-wave {
            overflow: hidden;
            position: relative;
            &:before {
               content: "";
               display: block;
               position: absolute;
               left: -150px;
               top: 0;
               height: 100%;
               width: 150px;
               background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
               animation: load 2s cubic-bezier(0.4, 0, 0.2, 1);
               animation-iteration-count: infinite;
            }
            @keyframes load {
               from {
                  left: -150px;
               }
               to {
                  left: 100%;
               }
            }
         }
      }
      .request-list {
         .no-results {
            // background: #F2F4F7;
            border: 2px solid #f2f4f7;
            // border: 1px solid #E1E7EE;
            // box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
            border-radius: 5px;
            transition: all 0.3s;
            padding: 20px 20px;
            font-weight: 500;
         }
      }
      .request-title,
      .request-item {
         display: flex;
         align-items: center;
         padding: 10px 0;

         .icon {
            width: 40px;
            .profile-picture {
               border: 1px solid #e1e7ee;
               border-radius: 4px;
            }
            // .preview-image {
            //    height: 40px;
            //    width: 40px;
            //    border-radius: 50%;
            // }
            // .preview-circle {
            //    height: 40px;
            //    width: 40px;
            //    border-radius: 50%;
            // }
         }
         .title {
            flex: 1;
            .content {
               font-style: normal;
               font-weight: bold;
               font-size: 14px;
               line-height: 20px;
               color: #26323a;
               padding-left: 20px;
               .team-link,
               a {
                  color: #56689a;
               }
            }
         }
         .icon-title {
            flex: 1;
         }
         .template {
            width: 120px;
            display: flex;
            .t-icon {
               margin-right: 10px;
            }
            .t-text {
               font-weight: 500;
               font-size: 12px;
               line-height: 20px;
               color: #26323a;
            }
         }
         .request-date {
            width: 120px;
            span {
               color: #718690;
               font-weight: 500;
            }
         }
         .status {
            padding: 0 20px;
            .wrapper {
               width: 140px;
               background-color: rgb(213, 213, 213);
               border-radius: 2px;
               position: relative;
               .total {
                  position: absolute;
                  z-index: 2;
                  top: 1px;
                  right: 4px;
                  font-size: 9px;
                  font-weight: 500;
                  opacity: 0;
               }
            }
            .bar {
               background-color: #3da0a2;
               height: 15px;
               border-radius: 2px;
               position: relative;
               z-index: 3;
               .complete {
                  position: absolute;
                  z-index: 4;
                  top: 1px;
                  right: 4px;
                  font-size: 9px;
                  font-weight: 400;
                  color: white;
                  opacity: 0;
               }
            }
            &:hover {
               .total,
               .complete {
                  opacity: 0.6;
               }
            }
         }
         .link {
            width: 120px;
            text-align: center;
            .request-status {
               opacity: 0.8;
               .status-icon {
                  svg {
                     width: 21px;
                     height: 21px;
                  }
               }
               .status-text {
                  display: none;
               }
            }
            .open {
               background: #ffffff;
               border: 1px solid #e1e7ee;
               box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
               border-radius: 5px;
               font-weight: 500;
               height: 32px;
               color: #26323a;
               font-size: 12px;
               line-height: 22px;
               padding: 4px 10px;
               text-align: center;
               transition: all 0.3s;
               &:hover {
                  background-color: rgba(128, 128, 128, 0.041);
               }
            }
         }
      }
      .request-item {
         border-bottom: 1px solid #f3f3f3;
         &:last-child {
            border-bottom: 0px solid transparent;
         }
      }
   }
}
