.guest-common-add {
}
.guest-common-add-modal {
   .loader {
      margin-top: 10px;
   }
   .loader-saving-new-guests {
      font-size: 14px;
      padding: 15px;
      text-align: center;
   }
   .user-list {
      padding: 15px;
      padding-bottom: 5px;
      background: rgba(238, 241, 245, 0.75);
      border: 1px solid #e1e7ee;
      box-shadow: inset 0 2px 2px rgb(225 231 238 / 25%);
      border-radius: 5px;
      position: relative;
   }
}
