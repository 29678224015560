.recommendation-settings-page {
   display: flex;
   flex-direction: column;

   .header {
      display: flex;
      justify-content: space-between;
      .action-buttons {
         display: flex;
         gap: 8px;
      }
   }

   & * {
      font-family: var(--mantine-font-family) !important;
   }
}
