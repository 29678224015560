.wrapper {
   &[data-variant="selected-value"] {
      min-width: 80px;
      cursor: pointer;
      border: 1px solid var(--Border, #e5e7eb);
      background: var(--White, #fff);
      border-radius: 8px;
      div[data-position="right"] {
         color: var(--mantine-color-gray-9);
         i {
            padding: 12px;
         }
      }
   }
}

.input {
   &[data-variant="selected-value"] {
      color: var(--mantine-color-text-tertiary-0);
      font-weight: var(--light-font-weight);
      border: none;
   }
}

.section {
   &[data-variant="selected-value"] {
      color: var(--mantine-gray-9);
   }
}
