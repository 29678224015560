.recommendations-list {
   .list-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      background-color: var(--background---table-header);
      border-radius: 8px;
      color: var(--mantine-color-text-tertiary-0);
      margin-top: 16px;
      padding: 8px;

      .recommendation-label {
         margin-left: 24px;
      }

      .right-section {
         display: flex;
         align-items: center;

         .source-section {
            margin-right: 86px;
         }

         .priority-section {
            align-items: center;
            display: flex;
            margin-right: 120px;
            cursor: pointer;
            gap: 4px;

            .orchicon-sort-desc {
               font-size: 16px;
            }
         }
      }
   }

   .card-list {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      height: 100% !important;

      [data-viewport-type="window"] {
         position: initial !important;
      }

      [data-index]:not(:last-child) {
         margin-bottom: 8px;
      }

      // Teams
      .card-list-item:not(:last-child) {
         margin-bottom: 8px;
      }
   }
}
