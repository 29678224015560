.status-container {
   width: 16px;
   height: 16px;
   font-size: 16px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: white;

   &.white {
      border: 1.5px solid #606060;
      color: #606060;
      font-weight: 500;
      font-size: 10px;
   }
   &.green {
      color: #6bb700;
   }

   &.red {
      color: #c4314b;
   }

   &.purple {
      color: #b4009e;
      border: 1.5px solid #b4009e;
      font-weight: 500;
      font-size: 10px;
   }

   &.yellow {
      color: #ffaa44;
   }

   &.gray {
      color: #606060;
   }

   &.outline {
   }
}
