.lifecycle-testing {
   .td-actions {
      .button-custom {
         height: auto;
         padding: 1px 6px;
         margin-left: 5px;
      }
   }
   .tenant-overrides {
      display: flex;
      .date-picker {
         flex: 1;
         margin-bottom: 0;
         position: relative;
         // width: 200px;
         .clear-date {
            cursor: pointer;
            font-size: 12px;
            margin-top: 5px;
         }
         .date-loading {
            position: absolute;
            top: 37px;
            left: 15px;
            background-color: white;
            width: 80%;
         }
      }
      .form-button {
         flex: 1.4;
         margin-top: 25px;
         margin-left: 15px;
         padding: 9px 20px;
      }
   }
   .tenant-with-override {
      display: flex;
      align-items: center;
      padding: 3px 5px;
      border-bottom: 1px solid #e6e6e6;
      &:hover {
         cursor: pointer;
      }
      &:last-child {
         border-bottom: none;
      }
      .load {
         font-size: 19px;
         margin-right: 10px;
         color: #5967dd;
      }
      .ten {
         font-size: 14px;
         font-weight: 500;
      }
   }
}
.lifecycle-test-modal {
   .activity-date-picker {
      display: flex;
      .left {
      }
      .right {
         padding-left: 20px;
         .days-inactive {
            background-color: rgb(240, 240, 240);
            font-weight: 500;
         }
      }
   }
   .trigger-table {
      width: 100%;
      font-weight: 400;
      // border:1px solid gray;
      margin-top: 20px;
      > tr {
         > th {
            padding: 5px 10px;
         }
         > td {
            border-top: 1px solid rgb(223, 223, 223);
            padding: 5px 10px;
            .highlight {
               background-color: rgb(136, 136, 248);
               padding: 2px 5px;
               border-radius: 3px;
               color: white;
            }
            i {
               font-size: 10px;
               color: rgb(179, 179, 34);
            }
         }
         > td:first-child,
         > th:first-child {
            width: 150px;
            padding-left: 15px;
            padding-right: 15px;
            border-right: 1px solid rgb(223, 223, 223);
         }
         > td:last-child,
         > th:last-child {
            text-align: center;
         }
      }
   }
}
