.user-item {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 17px;
    height: 33px;
    vertical-align: top;
    display: inline-block;
    // padding-right: 14px;
    padding-right: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    .pic {
        width:25px;
        height: 25px;
        border-radius: 50%;
        background-color: rgb(211, 211, 211);
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-top:3px;
        margin-left:3px;
        // border:1px solid #D5D1D2;
        > div {
            border-radius: 50%;
            font-size:11px;
        }
        > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            vertical-align: top;
        }
        
        .principal-type {
            position: absolute;
            display: block;
            bottom: -1px;
            right: -5px;
            width: 12px;
            height: 12px;
            background-color: #0abb87;
            font-size: 9px;
            color: white;
            line-height: 11px;
            margin: 0 auto;
            text-align: center;
            border-radius: 50%;
            border:1px solid white;
            box-sizing: content-box;

            // position: absolute;
            // left: 28px;
            // bottom: 7px;
            // width: 12px;
            // height: 12px;
            // background-color: #0abb87;
            // font-size: 9px;
            // color: white;
            // line-height: 11px;
            // margin: 0 auto;
            // text-align: center;
            // border-radius: 50%;
            // border: 1px solid white;
            // box-sizing: content-box;

            z-index: 93;
            &.type-group {
                background-color: rgb(121, 121, 231);
            }
        }
    }
    > span {
        line-height: 33px;
        font-size: 14px;
        vertical-align: top;
        margin-left:8px;
    }

    .remove {
        // display: none;
        // background-color: white;
        position: absolute;
        z-index: 1;
        top:-1px;
        right:0;
        // right: -20px;
        width: 33px;
        height: 33px;
        text-align: center;
        line-height: 34px;
        // line-height: 31px;
        // border-radius: 0 16px 16px 0;
        // border-top: 1px solid #E0E0E0;
        // border-right: 1px solid #E0E0E0;
        // border-bottom: 1px solid #E0E0E0;
        cursor: pointer;
        > svg {
            height: 12px;
            width: 12px;
            opacity: 0.5;
        }
    }

    &.hide-remove {
        padding-right: 10px;
    }
    &:hover {
        .remove {
            // display: block;
            > svg {
                color:red;
            }
        }
    }
    &.no-border {
        border: 0px solid transparent;
        background-color: transparent;
        margin: 0;
    }
    &.size-sm {
        height: 27px;
        .pic {
            width: 18px;
            height: 18px;
            margin-top: 5px;
            .profile-picture-placeholder {
                width: 18px;
                height: 18px;
                span {
                    line-height: 20px;
                    font-size: 10px;
                    vertical-align: top;
                }
            }
        }
        span {
            font-size: 13px;
            line-height: 28px;
        }
    }
}