@import "app/assets/common/bootstrap-media-mixins";
.people-picker {
   background-color: white;
   border: 1px solid #28b976;
   min-width: 300px;
   margin-top: -14px;
   margin-left: 10px;
   @include media-breakpoint-down(sm) {
      border: 0px solid transparent;
      margin-top: -5px;
   }
   .arrow {
      &:before {
         position: absolute;
         top: -2px;
         right: 11px;
         content: "";
         width: 10px;
         height: 10px;
         background-color: white;
         border-top: 1px solid #28b976;
         border-left: 1px solid #28b976;
         transform: rotate(-45deg);
      }
   }

   .people-content {
      @include media-breakpoint-down(sm) {
         background-color: white;
         border: 1px solid #b6f8d9;
         border-radius: 5px;
         padding: 10px;
      }
      .people-header {
         position: relative;
         padding: 10px;
         input {
            width: 100%;
            border: 0px solid transparent;
            outline: none;
            &:focus {
               border: 0px solid transparent;
               outline: none;
            }
         }
         .icon {
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 10px;
            svg {
               color: #b5b5b5;
               width: 17px;
               height: 17px;
            }
         }
      }
      .people-list {
         position: relative;
         // padding-top: 5px;
         &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 10px;
            right: 10px;
            height: 1px;
            border-top: 1px solid #ececec;
         }
         ul {
            margin-bottom: 0;
            .suggested-members {
               font-size: 12px;
               line-height: 17px;
               letter-spacing: -0.009091px;
               color: #676767;
               padding: 8px 8px 5px 8px;
            }
            li {
               padding: 5px 8px;
               // height: 34px;
               line-height: 33px;
               word-wrap: none;
               position: relative;
               display: flex;

               &:hover {
                  cursor: pointer;
                  background: rgba(61, 160, 162, 0.1);
               }
               &.selected {
                  background: rgba(61, 160, 162, 0.1);
                  cursor: default;
               }
               .pic {
                  width: 25px;
                  height: 25px;

                  // background-color: rgb(211, 211, 211);
                  display: inline-block;
                  vertical-align: top;
                  // position: relative;
                  margin-top: 5px;
                  margin-left: 3px;
                  // border:1px solid #D5D1D2;
                  margin-right: 15px;
                  > div {
                     border-radius: 50%;
                     font-size: 11px;
                     font-weight: 600;
                  }
                  > img {
                     border-radius: 50%;
                     width: 25px;
                     height: 25px;
                     vertical-align: top;
                  }
               }
               .name {
                  display: inline-block;
                  font-weight: 500;
                  white-space: nowrap;
               }
               .guest-note {
                  margin-left: 3px;
                  font-weight: 500;
                  opacity: 0.6;
               }
               .job-title {
                  margin-left: 13px;
                  display: inline-block;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
               }
               &.no-results {
                  padding-top: 5px;
                  text-align: center;
               }
               &.loader {
                  padding-top: 14px;
                  text-align: center;
               }
               .principal-type {
                  position: absolute;
                  // top: 6px;
                  left: 28px;
                  top: 23px;
                  //right: -4px;
                  width: 11px;
                  height: 11px;
                  background-color: #0abb87;
                  font-size: 9px;
                  color: white;
                  line-height: 11px;
                  margin: 0 auto;
                  text-align: center;
                  border-radius: 50%;
                  border: 1px solid white;
                  box-sizing: content-box;
                  z-index: 93;
                  &.type-group {
                     background-color: rgb(121, 121, 231);
                  }
               }
            }
         }
      }
   }
   &.theme-dropdown {
      min-width: 288px;
      border-color: transparent;
      margin: 0;
      margin-top: 5px;
      padding: 12px;
      border-radius: 5px;
      @include media-breakpoint-down(sm) {
         padding: 0px;
      }
      .arrow {
         display: none;
      }
      .people-header {
         background: #ffffff;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         border-radius: 5px;
         padding: 8px 16px;
         margin-bottom: 5px;
         input {
            margin: 0;
            border-width: 0;
            font-weight: 500;
            font-size: 13px;
         }
         .icon {
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 10px;
            i {
               color: #b5b5b5;
               width: 17px;
               height: 17px;
               font-size: 17px;
               -moz-transform: scale(-1, 1);
               -webkit-transform: scale(-1, 1);
               -o-transform: scale(-1, 1);
               -ms-transform: scale(-1, 1);
               transform: scale(-1, 1);
            }
         }
      }
      .people-list {
         &:before {
            display: none;
         }
         ul li {
            transition: all 0.3s;
            font-size: 14px;
            &:hover {
               border-radius: 5px;
            }
            .pic {
               width: 32px;
               margin-top: 0;
               height: 32px;
               img {
                  width: 32px;
                  height: 32px;
               }
               .profile-picture {
                  width: 32px;
                  height: 32px;
                  line-height: 32px;
               }
            }
            &.loader {
               padding-top: 14px;
               text-align: center;
               display: block;
            }
            &.no-results {
               display: block;
               font-size: 12px;
               font-weight: 500;
               text-align: center;
               &:hover {
                  background-color: transparent;
                  cursor: default;
               }
            }
         }
      }
   }
}
