.orchestry-localization-editor {
   .lang-pills {
      display: inline-flex;
      margin-bottom: 20px;
      border-radius: 6px;
      border: 1px solid rgb(216, 216, 216);
      .nav-item {
         width: 100px;
         text-align: center;
         background-color: white;
         padding: 8px;
         font-weight: 600;

         &:first-child {
            border-radius: 5px 0 0 5px;
         }
         &:last-child {
            border-radius: 0 5px 5px 0;
         }
         &:hover {
            background-color: rgb(233, 233, 233);
            cursor: pointer;
         }
         &.selected {
            background-color: #5867dd;
            color: white;
         }
      }
      &.single-item {
         .nav-item {
            border-radius: 5px;
         }
      }
   }
   .value-titles {
      font-weight: 600;
      border-bottom: 1px solid rgb(211, 211, 211);
      padding-bottom: 15px;
   }
   .value-edit {
      border-bottom: 1px solid rgb(235, 235, 235);
      padding: 10px 0;
      > div {
         align-items: center;
      }
      .no-value {
         color: rgb(177, 177, 177);
      }
      &:hover {
         background-color: rgb(239, 239, 255);
         cursor: pointer;
      }
   }
   .lang-stats {
      display: flex;
      .lang-status {
         width: 20px;
         height: 20px;
         background-color: rgb(211, 211, 211);
         border-radius: 3px;
         text-align: center;
         line-height: 20px;
         font-weight: 500;
         font-size: 9px;
         text-transform: uppercase;
         margin-right: 5px;
         color: #333;
         cursor: default;
         &.lang-green {
            background-color: rgb(163, 228, 163);
         }
         &.lang-red {
            background-color: rgb(218, 218, 255);
         }
      }
   }
}

.orchestry-localization-editor-users {
   .local-editors {
      padding-bottom: 30px;
      .result-user-item {
         display: flex;
         padding: 4px 10px;
         border-bottom: 1px solid rgb(209, 209, 209);
         align-items: center;
         &.header {
            background-color: rgb(240, 240, 240);
            font-weight: 500;
         }
         &:last-child {
            border-bottom: none;
         }
         .name {
            width: 25%;
            overflow-wrap: break-word;
            font-weight: 500;
            .tenant {
               font-size: 10px;
               overflow-wrap: break-word;
               opacity: 0.75;
            }
         }
         .upn {
            width: 40%;
            overflow-wrap: break-word;
            padding: 0 1%;
         }
         .lang-stats {
            width: 25%;
            display: flex;

            .lang-status {
               width: 20px;
               height: 20px;
               background-color: rgb(211, 211, 211);
               border-radius: 3px;
               text-align: center;
               line-height: 20px;
               font-weight: 500;
               font-size: 9px;
               text-transform: uppercase;
               margin-right: 5px;
               color: #333;
               cursor: pointer;
               &.lang-green {
                  background-color: rgb(163, 228, 163);
                  &:hover {
                     background-color: rgb(147, 202, 147);
                     cursor: pointer;
                  }
               }
               &.lang-red {
                  background-color: rgb(232, 232, 232);
                  &:hover {
                     background-color: rgb(214, 214, 214);
                     cursor: pointer;
                  }
               }
            }
         }
      }
   }
}

.orchestry-localization-editor-users-search-bg {
   z-index: 1051 !important;
}
.orchestry-localization-editor-users-search {
   z-index: 1052 !important;
   .user-search-row {
      display: flex;
      > button {
         margin-left: 15px;
      }
   }
   .user-search-results {
      padding-top: 15px;
      .start-search {
         padding: 0 0 15px 0;
         font-weight: 500;
      }
      .result-user-item {
         display: flex;
         padding: 4px 10px;
         border-bottom: 1px solid rgb(209, 209, 209);
         align-items: center;
         &:hover:not(.header) {
            background-color: rgb(233, 233, 255);
            cursor: pointer;
         }
         &.header {
            background-color: rgb(240, 240, 240);
            font-weight: 500;
         }
         &:last-child {
            border-bottom: none;
         }
         .name {
            width: 33%;
            overflow-wrap: break-word;
            font-weight: 500;
         }
         .upn {
            width: 31%;
            overflow-wrap: break-word;
            padding: 0 1%;
         }
         .tenant {
            width: 33%;
            overflow-wrap: break-word;
         }
      }
   }
}
