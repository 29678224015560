.root {
   &[data-variant="blue"] {
      .input {
         color: var(--mantine-color-blue-8);
         background-color: var(--mantine-color-blue-0);
         border-color: var(--mantine-color-blue-0);
      }

      i {
         color: var(--mantine-color-blue-8);
      }
   }
}
