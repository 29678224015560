.recommendation-card {
   & * {
      font-family: var(--mantine-font-family) !important;
   }
   border: 1px solid var(--border) !important;
   display: flex;
   flex-direction: column;
   flex: 1;

   &.is-expandable {
      cursor: pointer;
   }

   .top-section {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
   }
}
