.automation-policy-configurator-page {
   & * {
      font-family: var(--mantine-font-family) !important;
   }

   min-height: 500px;
   label {
      margin-bottom: 0;
   }
}
