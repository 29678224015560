.orchestry-localization-cache-busting {
   ul {
      list-style: none;
      margin: 10px 0 20px 0;
      padding: 0;
   }
   li {
      display: flex;
      border-bottom: 1px solid rgb(225, 225, 225);
      font-size: 13px;
      font-weight: 500;
      padding: 5px 0;
      align-items: center;
      &:last-child {
         border-bottom: none;
      }
      .bust-status {
         width: 35px;
         color: rgb(186, 186, 255);
         text-align: center;
         .pending {
            color: rgb(230, 119, 230);
         }
         .processing {
            color: rgb(252, 205, 118);
         }
         .done {
            color: rgb(84, 165, 84);
         }
      }
      .title {
         width: calc(40% - 35px);
      }
      .url {
         width: calc(60% - 45px);
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         font-size: 12px;
         opacity: 0.7;
      }
      .action {
         width: 45px;
         display: flex;
         > * {
            padding: 0 3px;
            cursor: pointer;
            &:hover {
               background-color: rgb(231, 231, 255);
               border-radius: 3px;
            }
         }
         i {
            font-size: 12px;
         }
         .edit {
            i {
               color: rgb(98, 177, 98);
            }
         }
         .delete {
            i {
               color: rgb(175, 115, 115);
            }
         }
      }
   }
}

.orchestry-localization-editor-add-server-bg {
   z-index: 1051 !important;
}
.orchestry-localization-editor-add-server {
   z-index: 1052 !important;
}
