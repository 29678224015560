.request-details-history {
   .kt-widget4__item {
      align-items: flex-start;
   }

   .user {
      font-weight: 400;
   }
   .action {
   }
   .user-pic {
      position: relative;
      margin-top: 5px;
      .badge {
         position: absolute;
         top: -4px;
         right: -4px;
         // background-color: rgb(4, 100, 255);
         // height: 16px;
         // width: 16px;
         // padding: 0;
         border: 1px solid white;
         // border-radius: 50%;
         // line-height: 11px;
         z-index: 100;
         > * {
            color: white;
            height: 8px;
            width: 8px;
            // line-height: 9px;
            // margin: auto;
         }
      }
   }
   .feedback {
      background-color: whitesmoke;
      padding: 5px 10px;
      margin: 5px 0;
      position: relative;
      border-radius: 0 4px 4px 4px;
      &:before {
         content: "";
         position: absolute;
         left: -5px;
         top: -1px;
         width: 0;
         height: 0;
         border-left: 5px solid transparent;
         border-right: 5px solid transparent;
         border-bottom: 5px solid whitesmoke;
         transform: rotate(45deg);
      }
   }
   .kt-widget4__text {
      opacity: 0.6;
      font-size: 12px !important;
   }
   .kt-widget4 .kt-widget4__item {
      align-items: flex-start;
   }
}
