.orchestry-trial-information {
   vertical-align: top;
   border-radius: 3px;
   margin: 5px;
   margin-right: 25px;
   padding-left: 12px;
   padding-right: 12px;
   transition: 0.2s;
   position: relative;

   &:after {
      content: "";
      top: 5px;
      bottom: 5px;
      right: -15px;
      position: absolute;
      width: 1px;
      background-color: rgb(235, 235, 235);
   }
   cursor: pointer;
   &:hover {
      background-color: rgb(238, 238, 238);
   }
   .num {
      vertical-align: top;
      display: inline-block;
      padding: 5px 0px;
      font-size: 17px;
      color: white;
      background-color: #78c578;
      font-weight: 600;
      border-radius: 4px;
      margin-top: 10px;
      margin-right: 13px;
      min-width: 34px;
      height: 34px;
      text-align: center;
      line-height: 25px;
   }
   .days-left {
      vertical-align: top;
      display: inline-block;
      padding-top: 8px;
      .text {
         font-size: 15px;
         font-weight: 600;
      }
      .expirey-date {
         font-size: 11px;
         font-weight: 500;
         opacity: 0.5;
      }
   }
}
