.root {
   &[data-variant="wizard"] {
      .steps {
         .step {
            .stepIcon {
               background-color: transparent;
               border-color: transparent;
               color: transparent;
            }
            .stepCompletedIcon {
               background-color: transparent;
               border-color: transparent;
               color: transparent;
            }
         }
      }
   }
}
