@import "app/assets/common/bootstrap-media-mixins";

.channels-modal {
    .col-left {
        background-color: #F1F1F4;
        padding: 15px;
        h4 {
            font-family: 'TT Norms';
            font-size: 22px;
            line-height: 20px;
        }
        .searchBox {
            // padding-top: 5px;
            input {
                width: 100%;
                padding: 8px 40px 8px 12px;
                height: 36px;
                font-size:14px
            }
            .icon-search {
                position: absolute;
                left:auto;
                right:8px;
                top:2px;
            }
        }
        .ScrollbarsCustom  {
            min-height: 300px;
            .ScrollbarsCustom-TrackY {
                width: 7px !important;
                height: 100% !important;
                top:0 !important;
                opacity: 0;
                transition: all 0.3s;
                background-color: transparent !important;
                .ScrollbarsCustom-ThumbY {
                    background-color: #D9D9DC !important;
                }
            }
            &:hover {
                .ScrollbarsCustom-TrackY {
                    opacity: 1;
                }
            }
        }
        .channel-list {
            margin-top: 10px;
            width: calc(100% + 10px);
        }
        ul {
            li {
                border-radius: 0.25rem;
                padding:7px 14px;
                margin-bottom: 4px;
                cursor: pointer;
                transition: all 0.3s;
                position: relative;

                &:last-child {
                    margin-bottom: 0;
                }

                .icon {
                    svg {
                        position: relative;
                        top:1px;
                        margin-right: 10px;
                        width: 18px;
                        transition: all 0.3s;
                    }
                }
                .text {
                    font-size: 12px;
                    line-height: 17px;
                    color: #414141;
                    transition: all 0.3s;
                }
                &:hover {
                    background-color: rgba(61, 160, 162, 0.178);
                }

                &.chan-enabled {
                    background-color: #DFE9EC;
                    cursor: default;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        right: .55rem;
                        transform: rotate(-45deg) scale(0.8);
                        border-bottom: solid 2px #3DA0A2;
                        border-left: solid 2px #3DA0A2;
                        background: none;
                        height: 5px;
                        width: 8px;
                        margin-top: -3px;
                    }
                }

                &.chan-selected {
                    background-color: #3DA0A2;
                    .icon {
                        svg {
                            color: white;
                        }
                    }
                    .text {
                        color: white;
                    }
                }
            }
        }
    }
    .col-right {
        padding-bottom: 50px;
        position: relative;
        .preview-image {
            width: 100%;
            background-size: cover;
            border:1px solid #9F9F9F;
            height: 50%;
            margin-top: 15px;
            opacity: 1;
            transition: opacity, height 0.3s;
            &.hide-preview {
                height: 0%;
                opacity: 0;
            }
        }
        .infobox {
            h5 {
                font-size: 18px;
                line-height: 21px;
                color: #414141;
                font-weight: 400;
                margin-top: 15px;
            }
            p {
                font-size: 14px;
                line-height: 19px;
                color: #676767;
            }
        }
        .actions {
            position: absolute;
            bottom: 22px;
            right:22px;
            .save {
                margin-left: 15px;
            }
        }
    }
}