.request-details-users {
   .profile-picture-placeholder {
      // width: 30px;
      // height: 30px;
      // border-radius: 50%;
      // line-height: 30px;
      // font-weight: 600;
   }
   .kt-widget31__content {
      width: auto !important;
      .kt-widget31__info {
      }
   }
   .title-add {
      display: flex !important;
      align-items: center;
      margin-bottom: 10px !important;
      .title {
         //  font-size:17px;
         //  font-weight: 700;
         font-size: 1.2rem;
         font-weight: 500;
         color: #26323a;
         flex: 1;
         .count {
            margin-left: 2px;
            color: #718690;
            font-weight: 500;
            font-size: 1.2rem;

            //   font-size:16px;
            .num-pad {
               text-align: center;
               // min-width: 12px;
               padding-left: 2px;
               padding-right: 2px;
               display: inline-block;
            }
         }
      }
      .link {
         // flex:1;
         .add-action {
            display: flex;
            align-items: center;
            cursor: pointer;
            //   border: 1px dashed #E0E0E0;
            //    background: rgba(221, 226, 231, 0.15);
            //    border-radius: 4px;
            //    padding: 5px 10px;
            background: #007989;
            border: 2px solid #007989;
            border-radius: 4px;
            padding: 3px 9px;
            transition: all 0.3s;
            .icon {
               // background: #007989;
               // width:20px;
               height: 20px;
               display: inline-block;
               color: white;
               text-align: center;
               border-radius: 50%;
               line-height: 22px;
               i {
                  font-size: 15px;
               }
            }
            .text {
               flex: 1;
               font-weight: 500;
               font-size: 12px;
               line-height: 16px;
               // color: #007989;
               color: white;
               margin-left: 6px;
            }
            &:hover {
               background: #0395a8;
               border: 2px solid #0395a8;
               .icon {
                  //  background: #005f6b;
               }
               .text {
                  //  color: #005f6b;
               }
            }
         }
      }
   }
   .default-users-divider {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.494);
      font-size: 11px;
      border-top: 1px solid #e0e0e0;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 10px;
   }
   .user-content {
      position: relative;
      min-height: 80px;
      background-color: rgb(242, 242, 242);
      border-radius: 4px;
      padding: 10px !important;
   }
   .no-results {
      .no-icon-text {
         position: absolute;
         margin: 0 auto;
         text-align: center;
         width: 120px;
         top: 50%;
         left: 50%;
         margin-left: -60px;
         margin-top: -25px;
         img {
            margin-bottom: 10px;
            max-width: 25px;
         }
         span {
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #718690;
         }
      }
   }
   .kt-separator.kt-separator--space-lg {
      margin: 2rem 0;
   }
   &.edit-mode-off {
      .link {
         display: none;
      }
      .principal-item {
         .locked,
         .remove {
            display: none;
         }
      }
   }
}
