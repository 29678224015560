@import "app/assets/common/bootstrap-media-mixins";
.step-card-policy-execution {
   .card-border {
      @include media-breakpoint-up(xl) {
         // padding-left: 150px;
         // padding-right: 150px;
      }
   }

   .left-side {
      padding-right: 30px;
   }
   .form-select-box {
      .select-box-icon-jsx {
         margin-bottom: 0 !important;
      }
   }
   .vertical-property-value {
      .label {
         color: #414141;
         cursor: default;
         font-size: 14px;
         font-weight: 500;
         margin-bottom: 10px;
      }
   }
   .approvers-specific-users {
      padding: 20px 0;
   }
   .property-row {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 15px;
      .left-side {
         width: 80px;

         // margin-left: 12px;
         color: #414141;
         cursor: default;
         font-size: 14px;
         font-weight: 500;
      }
      > div:last-child {
         flex: 1;
      }
      &.permissions {
         margin-left: 28px;
      }
      &.notification-unit {
         .left-side {
            width: 200px;
         }
      }
      &.approvers {
         .left-side {
            width: 110px;
         }
      }
      &.extension {
         .left-side {
            width: 282px;
         }
      }
      &.escalate-to {
         .left-side {
            width: 110px;
            padding-top: 39px;
         }
         .right-side {
            .form-group {
               margin-bottom: 0;
            }
         }
         .far-right {
            padding-left: 15px;
            padding-top: 39px;
         }
      }
      &.perform-archival-action {
         color: #414141;
         cursor: default;
         font-size: 14px;
         font-weight: 500;
         padding-top: 0;
         .after {
         }
         .number {
            width: 100px;
            margin: 0 10px;
         }
         .units {
            width: 145px;
            margin: 0 10px;
         }
         .perform {
         }
         .rule {
            margin: 0 0 0 10px;
         }
      }
   }
   .sub-title {
      color: #414141;
      cursor: default;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 5px;
   }
   .try-for-amount-of-times {
      display: flex;
      // justify-content: center;
      align-items: center;
      color: #414141;
      font-size: 14px;
      font-weight: 500;
      input {
         width: 100px;
         height: 30px;
         margin: 0 10px;
         padding: 0.45rem 0.45rem;
      }
   }

   .card-info {
      .content.approvers {
         padding: 20px !important;
      }
   }

   .team-annoucement {
      zoom: 0.85;
      margin-top: 15px;
      .form-control.form-toggle-control {
         margin-left: 0rem !important;
      }
   }
   .no-action-taken {
      .user-picker {
         .form-group {
            margin-bottom: 0;
         }
      }
      .form-select-box {
         display: block;
         margin-bottom: 30px;
         &:last-child {
            margin-bottom: 0;
         }
         .select-box-item {
            // padding-bottom: 0;
            cursor: default;
         }
      }
   }
}
