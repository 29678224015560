.ScrollbarsCustom  {
    max-height: 300px;
    min-height: 300px;
    .ScrollbarsCustom-Wrapper {
        // right:20px;
        .ScrollbarsCustom-Content {
            // min-width: calc(100% - 7px) !important;
            // width: calc(100% - 7px);

            .dd-list-item  {
                border-radius: 0 4px 4px 0;
            }
        }
        
    }
    .ScrollbarsCustom-TrackY { 
         width: 7px !important;
         // height: 100% !important;
         // top:0 !important;
         opacity: 0.4;
         transition: all 0.3s;
         // right:5px !important;
         right:5px !important;
         background-color: transparent !important;
         .ScrollbarsCustom-ThumbY {
               background-color: #D9D9DC !important;
         }
         &:hover {
            opacity: 1;
         }
    }
    .ScrollbarsCustom-TrackX { 
      height: 7px !important;
      // height: 100% !important;
      // top:0 !important;
      opacity: 0.4;
      transition: all 0.3s;
      // right:5px !important;
      background-color: transparent !important;
      .ScrollbarsCustom-ThumbX {
          background-color: #D9D9DC !important;
      }
      &:hover {
         opacity: 1;
      }
   }
   // &:hover {
   //    .ScrollbarsCustom-TrackY {
   //       opacity: 1;
   //    }
   //    .ScrollbarsCustom-TrackX {
   //       opacity: 1;
   //    }
   // }
}