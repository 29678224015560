.settings-lifecycle {
   .lifecycle-install-instructions {
      .permission-options {
         margin-top: 10px;
         .permissions-area {
            display: flex;
            &:first-child {
               border-bottom: 1px solid #ebebeb;
               margin-bottom: 20px;
               padding-bottom: 20px;
            }
            .permissions-icon {
               flex-grow: 0;
               min-width: 40px;
               i {
                  font-size: 24px;
               }
            }
            .ms-login {
               // margin: auto;
               // text-align: center;
               .custom-button {
                  background-image: url("~/public/media/ms-signin-button.png");
                  //  width: 245px;
                  height: 34px;
                  margin: 15px 0 0px 0px;
                  border: 1px solid rgb(172, 172, 172);
                  cursor: pointer;
                  display: inline-block;
                  color: black;
                  text-align: center;
                  font-family: "Segoe UI", "wf_segoe-ui_normal", "Arial", sans-serif;
                  font-size: 13px;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: left top;
                  line-height: 32px;
                  font-weight: 500;
                  padding-left: 40px;
                  padding-right: 15px;
                  text-align: right;
                  &:hover {
                     border-color: blueviolet;
                  }
               }
            }
            .permissions-content {
               h4 {
                  font-size: 16px;
                  color: black;
               }
               h5 {
                  font-size: 14px;
                  color: rgb(107, 107, 107);
                  margin-bottom: 10px;
                  &.required {
                     color: rgb(186, 99, 99);
                  }
               }

               .completed-task {
                  font-weight: 500;
                  padding: 5px 15px 5px 15px;
                  border: 1px solid #646c9a51;
                  border-radius: 4px;
                  margin-top: 10px;
                  line-height: 20px;
                  display: inline-block;
                  i {
                     margin-right: 8px;
                     font-size: 20px;
                     line-height: 10px;
                     position: relative;
                     top: 4px;
                  }
               }
               .text-description {
                  // min-height: 57px;
                  font-weight: 400;
               }
               .content-wrap {
                  display: flex;
                  .text-and-button {
                  }
                  .permissions-current-delegates {
                     padding-left: 15px;
                     min-width: 50%;
                     .delegates-title {
                        font-weight: 500;
                        font-size: 13px;
                        margin-bottom: 5px;
                     }
                     .principal-item {
                        display: flex;
                        margin-right: 0;
                     }
                     .no-delegates {
                        margin-bottom: 10px;
                        font-size: 11px;
                     }
                  }
               }

               .install-item {
                  display: inline-flex;
                  align-items: center;
                  margin-bottom: 10px;
                  font-weight: 500;
                  padding: 5px 15px 5px 15px;
                  border: 1px solid #646c9a51;
                  border-radius: 4px;
                  margin-top: 10px;
                  line-height: 20px;
                  // display: inline-block;
                  .status-icon {
                     width: 36px;
                     height: 36px;
                     min-width: 36px;
                     max-width: 36px;
                     background-color: #646c9a51;
                     border-radius: 4px;
                     text-align: center;
                     display: flex;
                     align-items: center;
                     zoom: 0.6;
                     i {
                        font-size: 18px;
                        color: white;
                        margin: 0 auto;
                     }
                  }
                  .right {
                     padding-left: 15px;
                     .title {
                        font-size: 13px;
                        font-weight: 500;
                        // color: black;
                     }
                     .description {
                        font-size: 11px;
                        opacity: 0.75;
                        line-height: 11px;
                     }
                  }
                  .version-highlight {
                     background-color: rgb(186, 186, 247);
                     padding: 3px 5px;
                     font-size: 11px;
                     color: white;
                     border-radius: 3px;
                  }
                  &.needs-update {
                     border: 0px solid transparent;
                     background-color: #646c9a14;
                     .status-icon {
                        display: none;
                     }
                     .right {
                        padding-left: 0;
                        .description {
                           line-height: 18px;
                        }
                     }
                  }
               }
               .install-deploy,
               .install-teams-app,
               .force-update {
                  padding: 0.35rem 0.65rem;
                  font-weight: 500;
                  font-size: 12px;
               }
            }
         }
      }
   }
}
