.naming-policy-preview {
   .prefix {
      color: purple;
   }
   .suffix {
      color: purple;
   }
   .final-value {
      color: green;
   }
}
