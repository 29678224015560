@keyframes orchestry-progress-bar {
   0% {
      transform: scaleX(1);
   }
   100% {
      transform: scaleX(0);
   }
}

.orchestry-toast-notification-body {
   display: flex;
   flex-direction: column;
   gap: 16px;
   .bar-container {
      position: relative;
      overflow: hidden;
      height: 8px;
      border-radius: 4px;

      &[data-variant="success"] {
         background-color: var(--mantine-color-success-0);
      }

      &[data-variant="heartpink"] {
         background-color: var(--mantine-color-pink-0);
      }

      &[data-variant="requeuetoast"] {
         background-color: var(--mantine-color-blue-0);
      }

      .bar {
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         animation: orchestry-progress-bar linear 1 forwards;

         &[data-variant="success"] {
            background-color: var(--mantine-color-success-5);
         }

         &[data-variant="error"] {
            background-color: var(--mantine-color-error-5);
         }

         &[data-variant="heartpink"] {
            background-color: var(--mantine-color-pink-8);
         }
         &[data-variant="requeuetoast"] {
            background-color: var(--mantine-color-blue-6);
         }
         &[data-variant="processing"] {
            background-color: var(--mantine-color-purple-7);
         }
      }
   }
}
