.lifecycle-report-choose-list {
   padding: 20px 40px 0 40px;
   min-height: 60vw;
   .choose-header {
      text-align: center;
      padding-bottom: 30px;
      h4 {
         font-weight: 700;
         font-size: 27px;
         line-height: 32px;
         color: #26323a;
      }
      p {
         font-size: 16px;
         font-weight: 500;
         margin-bottom: 5px;
      }
      .last-crawl-date {
         font-weight: 400;
         color: #3da0a2;
      }
      .search-box-border {
         margin-top: 10px;
         display: inline-flex;
         align-items: center;
         min-width: 300px;
         border: 1px solid #e1e7ee;
         box-shadow: inset 0px 2px 2px rgba(225, 231, 238, 0.25);
         transition: all 0.3s ease-in-out;
         border-radius: 5px;
         padding-right: 8px;
         margin-bottom: 10px;
         input {
            outline: none;
            background: #ffffff;
            font-family: TT Norms;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #26323a;
            padding: 10px 16px;
            border: 0px solid transparent;
            border-radius: 6px;
            flex: 1;
            &::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
            &:-ms-input-placeholder, /* Internet Explorer 10-11 */
            &:-ms-input-placeholder {
               /* Microsoft Edge */
               color: #9da9ae;
            }
         }
         .loader {
            padding: 2px 8px 0 0;
         }
         i {
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
            font-size: 20px;
            color: #9da9ae;
            position: relative;
            // line-height: 20px;
            // height: 20px;
            z-index: 9;
            display: inline-block;
            cursor: pointer;
            // top: 4px;
            // left: -27px;
            &.fa-times-circle {
               // display: none;
               font-size: 15px;
               margin-right: 10px;
               opacity: 0.5;
               transition: all 0.2s;
               cursor: pointer;
               &:hover {
                  opacity: 1;
               }
               // top:2px;
            }
         }
      }
   }
   .choose-body {
      .choose-group {
         margin-bottom: 30px;
         .choose-sub-title {
            font-size: 20px;
            font-weight: 500;
            // margin-left: 17px;
            padding-bottom: 10px;
         }
         .choose-items {
            display: grid;
            // grid-auto-flow: column;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 15px;

            .choose-item {
               position: relative;
               padding: 15px;
               width: 100%;
               min-height: 100px;
               cursor: pointer;
               border: 2px solid transparent;
               transition: all 0.3s;
               border-radius: 8px;
               background-color: white;
               border: 2px solid #ecf0f3;
               &:hover {
                  border: 2px solid #d7dee6;
                  box-shadow: 0px 4px 4px rgba(225, 231, 238, 15%);
               }
               &.selected {
                  border: 2px solid #007989;
                  cursor: default;
               }

               h6 {
                  font-size: 16px;
                  font-weight: 600;
                  color: #414141;
                  // padding: 0 15px;
               }
               p {
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 19px;
                  letter-spacing: 0.222727px;
                  color: #718690;
                  margin-bottom: 0px;
                  // max-height: 80px;
                  overflow: hidden;
                  // padding: 0 15px;
               }
               .image {
               }
            }
         }
      }
   }
}
.insights-modals {
   .modal-header {
      position: absolute;
      z-index: 1;
      right: 0;
      border-bottom-width: 0;
      .close {
         &:before {
            font-size: 1.8rem;
         }
      }
   }
}
