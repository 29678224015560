@import "_metronic/_assets/sass/breakpoints";

.chrome-language {
   display: flex;
   margin-right: 10px;

   &.in-sharepoint {
      .kt-header__topbar-wrapper {
         //Used so it doesn't show overtop of modals
         z-index: 1000;
      }
   }

   .kt-header__topbar-wrapper {
      background: #ffffff;
      border: 1px solid #e1e7ee;
      box-shadow: inset 0px -2px 2px rgba(225, 231, 238, 0.25);
      border-radius: 5px;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      // margin-right: 10px;
      cursor: pointer;
      transition: all 0.3s;
      position: relative;
      z-index: 2000;

      .kt-header__topbar-icon {
         // margin-top: -2px;
      }
      &:hover {
         border: 1px solid #007989;
      }
      svg {
         width: 20px;
         height: 20px;
         margin-top: -3px;
      }
      .lang-letters {
         width: 100%;
         height: 100%;
         padding: 0;
         line-height: 29px;
      }
   }
}
