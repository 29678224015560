.orchestry-alert {
   border-radius: 8px;

   &[data-variant="primary"] {
      background-color: var(--mantine-color-primary-0);
      border: solid 1px var(--mantine-color-primary-8);
   }

   &[data-variant="warning"] {
      background-color: var(--mantine-color-warning-0);
      border: solid 1px var(--mantine-color-warning-8);
   }

   &.link {
      cursor: pointer;
   }
}
